import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { QuestionBoxFooter } from '.';
import {
  HeightAnswer,
  WeightAnswer,
  CheckBoxAnswer,
  RadioButtonAnswer,
  TextBoxAnswer,
} from './answer-types';
import _, { get } from 'lodash';
import classnames from 'classnames';
import { compose, withStateHandlers, lifecycle } from 'recompose';

const styles = () => ({
  root: {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '20px',
    border: '7px solid #08492A',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '400px',
    width: '750px',
    position: 'relative',
  },
  contentArea: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: '1',
    color: 'black',
  },
  questionText: {
    fontSize: '1.75rem',
    fontWeight: '400',
    textAlign: 'center',
    marginBottom: '8px',
  },
  space: {
    marginBottom: '16px',
  },
  avatar: {
    position: 'absolute',
    top: '-30px',
    left: '10px',
    height: '90px',
    transform: 'rotate(-15deg)',
  },
});

const QuestionBox = ({
  classes,
  avatar,
  questions,
  currentAnswers,
  adjustedOnNextButtonClick,
  prevQuestion,
  updateAnswers,
  nextButtonText,
  noPrevButton,
  markAsValid,
  updateTextAnswers,
  textAnswers,
  updateAnswersForSubmission,
  isLoading,
  isSkippable,
}) => (
  <Grid item container className={classes.root}>
    {questions.map((question, idx) => {
      const secondaryText = get(question, 'secondaryText');
      return (
        <Grid key={idx} item container className={classes.contentArea}>
          {avatar && (
            <img
              className={classes.avatar}
              src={require(`../../../../images/${avatar}`)}
            />
          )}
          {secondaryText && (
            <div className={classes.questionText}>{secondaryText}</div>
          )}
          <div
            className={classnames(classes.questionText, {
              [classes.space]: questions.length === 1,
            })}
          >
            {get(question, 'question.en', '[[ Error getting question text.]]')}
          </div>

          {question.id === 'HNA-HEIGHT' ? (
            <HeightAnswer
              questionId={get(question, 'id')}
              updateAnswers={updateAnswers}
              currentAnswers={currentAnswers}
              markAsValid={markAsValid}
            />
          ) : question.id === 'HNA-WEIGHT' ? (
            <WeightAnswer
              questionId={get(question, 'id')}
              updateAnswers={updateAnswers}
              currentAnswers={currentAnswers}
              markAsValid={markAsValid}
            />
          ) : question.type === 'RADIO_BUTTONS' ? (
            <RadioButtonAnswer
              question={question}
              currentAnswers={get(currentAnswers, get(question, 'id'))}
              updateAnswers={updateAnswers}
              markAsValid={markAsValid}
            />
          ) : question.type === 'CHECKBOXES' ? (
            <CheckBoxAnswer
              question={question}
              currentAnswers={get(currentAnswers, get(question, 'id'))}
              updateAnswers={updateAnswers}
              updateAnswersForSubmission={updateAnswersForSubmission}
              markAsValid={markAsValid}
            />
          ) : question.type === 'TEXTBOX' ? (
            <TextBoxAnswer
              question={question}
              currentAnswers={get(currentAnswers, get(question, 'id'))}
              updateAnswers={updateAnswers}
              markAsValid={markAsValid}
              updateTextAnswer={updateTextAnswers}
              textAnswer={textAnswers[get(question, 'id', '')]}
            />
          ) : (
            <div>error</div>
          )}
        </Grid>
      );
    })}

    <QuestionBoxFooter
      isLoading={isLoading}
      nextButtonText={nextButtonText}
      nextButtonOnClick={adjustedOnNextButtonClick}
      prevButtonOnClick={prevQuestion}
      noPrevButton={noPrevButton}
      isSkippable={isSkippable}
      whyText={_(questions)
        .filter((question) => question.helperText !== undefined)
        .map((question) => get(question, 'helperText'))
        .value()}
    />
  </Grid>
);

QuestionBox.propTypes = {
  avatar: PropTypes.string,
  questions: PropTypes.array.isRequired,
  currentAnswers: PropTypes.object,
  prevQuestion: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  updateAnswers: PropTypes.func.isRequired,
  nextButtonText: PropTypes.string.isRequired,
  noPrevButton: PropTypes.bool.isRequired,
  markAsValid: PropTypes.func.isRequired,
  adjustedOnNextButtonClick: PropTypes.func.isRequired,
  updateTextAnswers: PropTypes.func.isRequired,
  textAnswers: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateAnswersForSubmission: PropTypes.func.isRequired,
  isSkippable: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withStateHandlers(
    ({ questions, currentAnswers }) => {
      const textAnswers = _.mapValues(_.keyBy(questions, 'id'), (question) =>
        _.get(currentAnswers, `[${question.id}][0].answer`, '')
      );
      return {
        textAnswers,
        answersForSubmission: {},
      };
    },
    {
      setTextAnswers: () => (textAnswers) => ({ textAnswers }),
      setAnswersForSubmission: () => (answersForSubmission) => ({
        answersForSubmission,
      }),
      updateTextAnswers: ({ answersForSubmission }, { markAsValid }) => (
        event,
        questionId
      ) => {
        const textAnswers = {
          ...textAnswers,
          [questionId]: _.get(event, 'target.value', ''),
        };
        markAsValid(questionId, true);
        return {
          textAnswers,
          answersForSubmission: {
            ...answersForSubmission,
            [questionId]: {
              answers: [textAnswers[questionId]],
              questionType: 'TEXTBOX',
            },
          },
        };
      },
      updateAnswersForSubmission: ({ answersForSubmission }) => (
        questionId,
        questionType,
        selectedAnswers
      ) => ({
        answersForSubmission: {
          ...answersForSubmission,
          [questionId]: { answers: selectedAnswers, questionType },
        },
      }),
      adjustedOnNextButtonClick: (
        { answersForSubmission },
        { nextQuestion, questions, updateAnswersOnNext }
      ) => () => {
        const answersToSubmitOnNext = _.pick(
          answersForSubmission,
          _.map(questions, (question) => question.id)
        );

        if (_.size(answersToSubmitOnNext) > 0)
          updateAnswersOnNext(answersToSubmitOnNext);
        else nextQuestion();
      },
    }
  ),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (
        _.get(this.props, 'questions[0].id') !==
        _.get(nextProps, 'questions[0].id')
      ) {
        this.props.setAnswersForSubmission({});
        this.props.setTextAnswers(
          _.mapValues(_.keyBy(nextProps.questions, 'id'), (question) =>
            _.get(nextProps.currentAnswers, `[${question.id}][0].answer`, '')
          )
        );
      }
    },
  })
)(QuestionBox);
