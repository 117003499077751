import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClinicalTrackingMarkup from './ClinicalTrackingMarkup';

class ClinicalTracking extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      expanded: false,
      detailHeight: 0,
    };

    this._toggleDetails = this._toggleDetails.bind(this);
    this._onHeightReady = this._onHeightReady.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.carePlan.subject !== nextProps.carePlan.subject ||
      nextProps.action.type !== nextProps.activeHistory
    ) {
      this.setState({
        expanded: false,
      });
    }
  }

  render() {
    return (
      <ClinicalTrackingMarkup
        {...this.props}
        {...this.state}
        toggleDetails={this._toggleDetails}
      />
    );
  }

  _toggleDetails() {
    this.setState(
      {
        expanded: !this.state.expanded,
      },
      this._onHeightReady()
    );

    if (this.props.setActiveHistory) this.props.setActiveHistory();
  }

  _onHeightReady() {
    setTimeout(() => {
      const detailHeight = _.get(
        document.getElementById('clinicalContent'),
        'offsetHeight',
        0
      );
      this.setState({
        detailHeight,
      });
    });
  }
}

ClinicalTracking.propTypes = {
  isCheckpointMode: PropTypes.bool,
  iconClass: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  bubbleColor: PropTypes.string.isRequired,
  bubbleFilled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  carePlan: PropTypes.object,
  index: PropTypes.number,
  badge: PropTypes.oneOf(['verified', 'alert']),
  module: PropTypes.object,
  activeHistory: PropTypes.string,
  setActiveHistory: PropTypes.func,
  action: PropTypes.shape({ type: PropTypes.string }),
};

ClinicalTracking.defaultProps = {
  isCheckpointMode: false,
};

export default connect()(ClinicalTracking);
