import { Features } from '@healthmine/greyhound-core/src/constants';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';

export const buttonStyle = {
  ...GlobalStyles.regular,
  backgroundColor: Colors.dark,
  color: Colors.light,
  width: '185px',
  height: '37px',
  borderRadius: '4px',
  fontSize: '16px',
  userSelect: 'auto',
};

export const unsubscribeButtonStyle = {
  backgroundColor: Colors.white,
  borderColor: Colors.newRed,
  color: Colors.newRed,

  ':hover': {
    backgroundColor: Colors.hexToRgba(Colors.newRed, 0.85),
    color: Colors.white,
  },

  ':active': {
    backgroundColor: Colors.hexToRgba(Colors.newRed, 1),
    color: Colors.white,
  },
};

export default () => {
  const isLeapFrogUi = AppConfig.validateFeature(Features.LEAP_FROG_UI);

  return {
    pageRoot: {
      ...GlobalStyles.regular,
      fontSize: '16px',
      overflowY: 'auto',
      position: 'relative',
      color: Colors.light,
      backgroundColor: isLeapFrogUi ? 'transparent' : Colors.light,
      overflow: 'hidden',
    },

    pageRootContent: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      width: 'inherit',
      overflowX: 'hidden',
      overflowY: 'auto',
    },

    root: {
      width: isLeapFrogUi ? 'initial' : '1000px',
      maxWidth: '100%',
      color: Colors.darkText,
      backgroundColor: Colors.light,
      padding: isLeapFrogUi ? '30px' : '10px 10px 10px 200px',
      marginTop: '10px',
      boxSizing: 'border-box',
      borderRadius: isLeapFrogUi ? '6px' : '0',
      border: isLeapFrogUi ? `1px solid ${Colors.mediumGray3}` : 'none',

      '@media screen and (max-width: 760px) and (min-width: 481px)': {
        padding: '10px 10px 10px 100px',
      },

      '@media screen and (max-width: 480px)': {
        padding: '10px 40px',
      },
    },

    headerContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },

    headerIconContainer: {
      marginRight: '20px',
      marginTop: '20px',
    },

    headerIcon: {
      fontSize: '35px',
      color: Colors.white,
      backgroundColor: Colors.bodyText,
      borderRadius: '50%',
      padding: '20px',
    },

    headerText: {
      ...GlobalStyles.extraLight,
      fontSize: '24px',
      margin: '0 0 5px',
      color: Colors.black,
    },

    subHeaderText: {
      ...GlobalStyles.regular,
      margin: '10px 0 20px',
      color: Colors.black,
      maxWidth: '520px',
      fontSize: '16px',
    },

    contentContainer: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
      boxSizing: 'initial',
      maxWidth: '615px',
    },

    section: {
      ...GlobalStyles.regular,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginTop: '30px',
    },

    sectionContentContainer: {
      marginTop: '10px',
    },

    sectionLabel: {
      fontSize: '16px',
      fontWeight: '600',
    },

    sectionValue: {
      marginTop: 0,
    },

    checkboxContainer: {
      marginTop: '-10px',
    },

    checkboxControlContainer: {
      flexDirection: 'row',
    },

    checkboxLabel: {
      cursor: 'pointer',
      position: 'relative',
      top: '2px',
    },

    checkboxDescription: {
      color: 'rgb(124, 136, 149)',
      marginLeft: '48px',
      marginTop: 0,
    },
  };
};
