import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, withProps } from 'recompose';
import { pick, tail } from 'lodash/fp';
import DrawerActions from '../../../../actions/DrawerActions';

export default compose(
  connect(pick(['drawer']), (dispatch) => ({
    actions: bindActionCreators(DrawerActions, dispatch),
  })),
  withProps(({ actions, drawer }) => ({
    drawer: {
      stack: tail(drawer.stack),
      back: actions.backDrawer,
      close: actions.closeDrawer,
      open: actions.openDrawer,
    },
  }))
);
