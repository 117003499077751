import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const styles = (theme) => ({
  root: {
    marginBottom: '40px',
  },
  header: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  titleCTAWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  sectionCTA: {
    marginLeft: '10px',
    fontWeight: '500',
  },
  rightMenuItems: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '17px',
    color: theme.palette.secondary.main,
  },
  iconWrapper: {
    marginLeft: '10px',
    cursor: 'pointer',
    transition: theme.globalStyles.transition1,
    '&:hover': {
      color: theme.palette.common.beefy,
    },
  },
  selectedMenuItem: {
    color: theme.palette.common.beefy,
  },
});

const PageSection = ({
  title,
  sectionCTA,
  rightMenuItems,
  children,
  classes,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.titleCTAWrapper}>
          {title && <Typography type="h5">{title}</Typography>}
          {_.get(sectionCTA, 'text') && (
            <a className={classes.sectionCTA} onClick={sectionCTA.onClick}>
              <Typography type="link">{sectionCTA.text}</Typography>
            </a>
          )}
        </div>
        <div className={classes.rightMenuItems}>
          {_.map(rightMenuItems, (item, idx) => {
            return (
              <a
                onClick={item.onClick}
                className={`${classes.iconWrapper} ${
                  item.selected ? classes.selectedMenuItem : ''
                }`}
                key={`icon-${idx}`}
              >
                <i className={item.iconClass} />
              </a>
            );
          })}
        </div>
      </div>
      {children}
    </div>
  );
};

PageSection.propTypes = {
  title: PropTypes.string,
  sectionCTA: PropTypes.object,
  rightMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      iconClass: PropTypes.string,
      onClick: PropTypes.func,
      selected: PropTypes.bool,
    })
  ),
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(PageSection);
