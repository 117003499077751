import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium from 'radium';
import _ from 'lodash';
import ViewDocumentMarkup from './ViewDocumentMarkup';

class ViewDocument extends React.Component {
  render() {
    const { isSignedIn } = this.props;

    const getProp = (propName) =>
      _.get(
        this,
        `props.${propName}`,
        _.get(
          this,
          `props.location.query.${propName}`,
          'thin-0073_documents_files_paper_text_archive_copy'
        )
      );

    const iconClass = getProp('iconClass');

    const title = getProp('title');

    const documentFile = getProp('documentFile');

    const documentFileValue = _.isFunction(documentFile)
      ? documentFile()
      : documentFile;

    return (
      <ViewDocumentMarkup
        isSignedIn={isSignedIn}
        iconClass={iconClass}
        title={title}
        documentFile={documentFileValue}
        hideContainer={this.props.hideContainer}
      />
    );
  }
}

ViewDocument.propTypes = {
  iconClass: PropTypes.string,
  title: PropTypes.string,
  documentFile: PropTypes.string,
  hideContainer: PropTypes.bool,

  location: PropTypes.shape({
    query: PropTypes.shape({
      iconClass: PropTypes.string,
      title: PropTypes.string,
      documentFile: PropTypes.string,
    }),
  }),

  isSignedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isSignedIn: !!state.signIn.accessToken && !state.signIn.appLoadFailed,
  };
}

export default connect(mapStateToProps, null)(Radium(ViewDocument));
