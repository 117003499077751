import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { flow, getOr, map } from 'lodash/fp';
import { connect } from 'react-redux';
import List, { ListHeader, ListContent } from '../../../../common/list/List';
import HealthAction from './actions/HealthAction';
import PrescribedTrackerAction from './actions/PrescribedTrackerAction';
import {
  getMaxAmount,
  selectIsMaxedOut,
} from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import MaxEarnLingo from './MaxEarnLingo';

const getConditions = flow([getOr([], 'evidence.conditions'), map('name')]);

const HealthActionList = ({
  healthActions,
  remainingActionCount,
  title,
  trackers,
  iconClass,
  color,
  openDrawer,
  maxedOut,
  maxAmount,
  showMaxEarn,
}) => {
  return (
    <List>
      <ListHeader
        iconClass={iconClass}
        color={color}
        title={title}
        remainingCount={remainingActionCount}
        remainingCountNote={`action${
          remainingActionCount !== 1 ? 's' : ''
        } remaining`}
        isMute={remainingActionCount < 1}
      />
      {showMaxEarn && <MaxEarnLingo maxAmount={maxAmount} />}
      <ListContent>
        {healthActions.map((healthAction, idx) => {
          const {
            id,
            title,
            completed,
            lastCompletedOn,
            incentiveAmount,
            wasRewarded,
            incentiveEarningTextOverride,
          } = healthAction;

          return (
            <HealthAction
              key={`action_${idx}`}
              healthActionId={id}
              title={title}
              completed={completed}
              completionDate={lastCompletedOn}
              conditions={getConditions(healthAction)}
              incentiveAmount={incentiveAmount}
              wasRewarded={wasRewarded}
              openDrawer={openDrawer}
              maxedOut={maxedOut}
              incentiveEarningTextOverride={incentiveEarningTextOverride}
            />
          );
        })}
        {trackers.map((tracker, idx) => (
          <PrescribedTrackerAction key={`tracker_${idx}`} tracker={tracker} />
        ))}
      </ListContent>
    </List>
  );
};

HealthActionList.propTypes = {
  healthActions: PropTypes.array.isRequired,
  remainingActionCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  openDrawer: PropTypes.func,
  trackers: PropTypes.array,
  maxedOut: PropTypes.bool.isRequired,
  maxAmount: PropTypes.number,
  showMaxEarn: PropTypes.bool.isRequired,
};

export default compose(
  connect((state) => ({
    maxAmount: getMaxAmount(state),
    maxedOut: selectIsMaxedOut(state),
  })),
  withProps(({ title, maxAmount }) => ({
    showMaxEarn: title === 'Preventive Actions' && !!maxAmount,
  }))
)(HealthActionList);
