import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import {
  DrawerButton,
  DrawerSection,
  WarningDrawerContent,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';
import CarePlanActions from '@healthmine/greyhound-core/src/actions/CarePlanActions';
import { closeDrawer } from '../../../../../actions/DrawerActions';

const StopTrackingDrawer = ({ stopTracking }) => {
  return (
    <WarningDrawerContent title="Whoa, there!">
      <DrawerSection grow noBorder>
        <Typography>
          <p>
            By pressing "Confirm", you will remove this tracker from your Health
            Overview. You will no longer earn points for tracking. This does not
            disconnect your device.
          </p>
          <p>
            To disconnect your device, open up your settings and remove your
            device through Human API integration. If you are connected through
            Apple HealthKit Integration, you must disconnect your device there.
          </p>
          <p>
            To add the tracker back at a later time, simply click "Manage
            trackers" on your Health Overview.
          </p>
        </Typography>
      </DrawerSection>
      <DrawerButton text="Confirm" onClick={stopTracking} />
    </WarningDrawerContent>
  );
};

StopTrackingDrawer.propTypes = {
  stopTracking: PropTypes.func.isRequired,
};

export default compose(
  pure,
  connect(
    (state) => state,
    (dispatch) => ({
      actions: bindActionCreators(
        {
          closeDrawer,
          disableMyTracking: CarePlanActions.disableMyTracking,
        },
        dispatch
      ),
    })
  ),
  withHandlers({
    stopTracking: ({ tracker, actions }) => () => {
      actions.disableMyTracking(tracker.type).then(actions.closeDrawer);
    },
  })
)(StopTrackingDrawer);
