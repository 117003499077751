import React from 'react';
import PropTypes from 'prop-types';
import flattenProp from 'recompose/flattenProp';
import compose from 'recompose/compose';
import BubbleTitleMarkup from '../../common/BubbleTitleMarkup';
import { Subjects } from '@healthmine/greyhound-core/src/constants/CarePlan';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import get from 'lodash/get';
import moment from 'moment';

const getMeasurementColor = (measurement) => {
  const today = moment().format('Y-MM-DD');

  const measurementValue = get(measurement, 'value');

  const defined =
    measurement &&
    measurementValue !== '0/0' &&
    get(measurement, 'date', today) === today;

  return Colors.measurement[defined ? 'defined' : 'missing'];
};

const CarePlanBubble = ({
  subject,
  iconClass,
  status,
  checkpoint,
  dailyStatus,
  name,
  incentiveCurrency,
  tutorial,
  measure,
  calloutDetail,
  callToAction,
  activateTracking,
  selectCarePlan,
}) => {
  const isPreventive = subject === Subjects.PREVENTIVE;

  const bubbleFilled =
    isPreventive || status !== AppConstants.PlanStatus.ASSIGNED;

  const badge = checkpoint
    ? 'starred'
    : dailyStatus === AppConstants.TrackingStatus.COMPLETE
      ? 'verified'
      : dailyStatus === AppConstants.TrackingStatus.ALERT
        ? 'alert'
        : null;

  const titleSuperscript = !checkpoint
    ? ''
    : incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS
      ? 'Care Plan Check-In'
      : 'Care Plan Ended';

  const minHeight = checkpoint ? '50px' : '35px';

  const maxHeight = checkpoint ? 'auto' : '18px';

  const measureColor = isPreventive
    ? Colors.bodyText
    : getMeasurementColor(measure);

  const styleOverrides = {
    title: {
      minHeight,
      maxHeight,
      overflow: 'hidden',
    },
    measureContainer: {
      marginTop: '-5px',
      color: measureColor,
    },
    callToAction: {
      marginTop: '0px',
    },
  };

  const iconColor = bubbleFilled ? Colors.white : Colors.lightGreen;

  const bubbleColor = isPreventive ? Colors.pink : Colors.lightGreen;

  const showTutorial = tutorial && isPreventive;

  return (
    <BubbleTitleMarkup
      iconClass={iconClass}
      iconColor={iconColor}
      bubbleColor={bubbleColor}
      bubbleFilled={bubbleFilled}
      showTutorial={showTutorial}
      calloutDetail={calloutDetail}
      badge={badge}
      titleSuperscript={titleSuperscript}
      title={name}
      measure={measure}
      callToAction={callToAction}
      activateTracking={activateTracking}
      onBubbleClick={selectCarePlan}
      onTitleClick={selectCarePlan}
      styleOverrides={styleOverrides}
    />
  );
};

CarePlanBubble.propTypes = {
  carePlan: PropTypes.object.isRequired,
  tutorial: PropTypes.bool.isRequired,
  calloutDetail: PropTypes.object.isRequired,
  incentiveCurrency: PropTypes.string.isRequired,
  measure: PropTypes.object,
  callToAction: PropTypes.object,
  activateTracking: PropTypes.func,
  selectCarePlan: PropTypes.func,
  subject: PropTypes.string,
  iconClass: PropTypes.string,
  status: PropTypes.string,
  checkpoint: PropTypes.bool,
  dailyStatus: PropTypes.string,
  name: PropTypes.string,
};

export default compose(flattenProp('carePlan'))(CarePlanBubble);
