import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import Modal from 'react-modal';
import Button from '../common/Button';

const ChangePasswordMarkup = ({
  password,
  newPassword,
  confirmNewPassword,
  editPassword,
  toggleEditPassword,
  updatePassword,
  updateNewPassword,
  updateConfirmNewPassword,
  savePassword,
  isFormValid,
  isActionComplete,
}) => {
  const styles = {
    formField: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '90px',
    },
    formInput: {
      flex: '2 0 66.66%',
      position: 'relative',
    },
    error: {
      color: 'red',
      fontSize: '16px',
      display: 'block',
      position: 'absolute',
      top: '45px',
    },
    button: {
      float: 'right',
      fontSize: '16px',
      minWidth: '200px',
      marginTop: '30px',
      padding: '8px 20px',
    },
    modal: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },
      content: {
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: Colors.white,
        WebkitOverflowScrolling: 'touch',
        outline: 'none',
        padding: '50px 80px 30px',
      },
    },
    closebutton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },
    modalLabel: {
      display: 'block',
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.navyBlue,
      padding: '15px 0',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },
    modalNote: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.contentText,
      padding: '10px 0',
    },
    modalFormLabel: {
      flex: '1 0 33.34%',
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.bodyText,
    },
    modalInput: {
      fontSize: '16px',
      lineHeight: '13px',
    },
    modalMessage: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.contentText,
      margin: 0,
    },
    buttonMini: {
      marginTop: '20px',
      minWidth: '100px',
      float: 'left',
    },
  };

  return (
    <Modal
      contentLabel="ChangePasswordModal"
      isOpen={!!editPassword}
      onRequestClose={toggleEditPassword}
      style={styles.modal}
    >
      <div>
        <a onClick={toggleEditPassword} style={styles.closebutton}>
          <i className="thin-0153_delete_exit_remove_close" />
        </a>
      </div>
      <div style={[GlobalStyles.light, styles.modalContent]}>
        {isActionComplete ? (
          <div>
            <p style={styles.modalMessage}>
              Your password has been changed successfully!
            </p>
            <p style={styles.modalMessage}>
              Use updated password the next time you sign in.
            </p>
          </div>
        ) : (
          <div>
            <label style={styles.modalLabel}>Change Your Password</label>
            <p style={styles.modalNote}>
              To change your password, enter your current password and a new
              password.
            </p>

            <div style={styles.formField}>
              <label style={styles.modalFormLabel}>Current Password:</label>
              <div style={styles.formInput}>
                <input
                  type="password"
                  className="inputMaterial"
                  style={styles.modalInput}
                  placeholder="Enter your current password."
                  value={password.value}
                  onChange={updatePassword}
                />
                {password.error && (
                  <span style={styles.error}>{password.error}</span>
                )}
              </div>
            </div>

            <div style={styles.formField}>
              <label style={styles.modalFormLabel}>New Password:</label>
              <div style={styles.formInput}>
                <input
                  type="password"
                  className="inputMaterial"
                  style={styles.modalInput}
                  placeholder="Enter your new password."
                  value={newPassword.value}
                  onChange={updateNewPassword}
                />
                {newPassword.error && (
                  <span style={styles.error}>{newPassword.error}</span>
                )}
              </div>
            </div>

            <div style={styles.formField}>
              <label style={styles.modalFormLabel}>Verify New Password:</label>
              <div style={styles.formInput}>
                <input
                  type="password"
                  className="inputMaterial"
                  style={styles.modalInput}
                  placeholder="Verify your new password."
                  value={confirmNewPassword.value}
                  onChange={updateConfirmNewPassword}
                />
                {confirmNewPassword.error && (
                  <span style={styles.error}>{confirmNewPassword.error}</span>
                )}
              </div>
            </div>

            <Button
              text="Change Password"
              style={styles.button}
              disabled={!isFormValid}
              onClick={isFormValid ? savePassword : null}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

ChangePasswordMarkup.propTypes = {
  password: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string,
  }),
  newPassword: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string,
  }),
  confirmNewPassword: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string,
  }),
  editPassword: PropTypes.bool.isRequired,
  toggleEditPassword: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateNewPassword: PropTypes.func.isRequired,
  updateConfirmNewPassword: PropTypes.func.isRequired,
  savePassword: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool,
  isActionComplete: PropTypes.bool,
};

export default Radium(ChangePasswordMarkup);
