import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStringForLocale } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { TrackingData } from '@healthmine/greyhound-core/src/modules';
import BPQuestionMarkup from './BPQuestionMarkup';

class BPQuestion extends React.Component {
  constructor(props) {
    super(props);

    const bpAnswers = this.props.answers.filter(
      (answers) => answers.id === 'HNA_BP'
    )[0];

    const canSkip = bpAnswers && bpAnswers.answers[0] == 'NA';

    const bpValues = canSkip
      ? [undefined, undefined]
      : bpAnswers.answers[0] && bpAnswers.answers[0].split('/');

    const systolicBp =
      bpValues && bpValues[0] !== 'undefined' ? bpValues[0] : '';

    const diastolicBp =
      bpValues && bpValues[1] !== 'undefined' ? bpValues[1] : '';

    const answer = `${systolicBp}/${diastolicBp}`;

    const bplastTaken = this.props.answers.filter(
      (answers) => answers.id === 'HNA_BP.LAST.DATE.TAKEN'
    )[0];

    const bplastTakenAnswer =
      bplastTaken !== undefined && bplastTaken.answers !== undefined && !canSkip
        ? bplastTaken.answers[0]
        : undefined;

    const isValid = this._isValid(answer, canSkip);

    this.state = {
      systolicBp,
      diastolicBp,
      isValid,
      validationError: '',
      bplastTakenAnswer,
      canSkip,
    };

    this._setBloodPressure = this._setBloodPressure.bind(this);
    this._setDate = this._setDate.bind(this);
    this._skipQuestion = this._skipQuestion.bind(this);
  }

  componentDidMount() {
    const {
      systolicBp,
      diastolicBp,
      isValid,
      bplastTakenAnswer,
      canSkip,
    } = this.state;

    const answer = `${systolicBp}/${diastolicBp}`;

    if (!canSkip && (!isValid || !bplastTakenAnswer)) {
      this.props.onQuestionAnswered(this.props.questions[0].id, answer, false);
    }
  }

  render() {
    return (
      <BPQuestionMarkup
        {...this.state}
        {...this.props}
        setBloodPressure={this._setBloodPressure}
        setDate={this._setDate}
        skipQuestion={this._skipQuestion}
      />
    );
  }

  _setBloodPressure(value) {
    this.setState(value, () => {
      if (value.systolicBp || value.diastolicBp) {
        const {
          systolicBp,
          diastolicBp,
          bplastTakenAnswer,
          canSkip,
        } = this.state;

        const answer = `${systolicBp}/${diastolicBp}`;

        const canSkipOption =
          !canSkip && (systolicBp !== undefined || diastolicBp !== undefined)
            ? !canSkip
            : canSkip;

        const isBPValid = this._isValid(answer, !canSkipOption);

        const validationError =
          !isBPValid && systolicBp && diastolicBp
            ? this._getValidationError()
            : '';

        const isBPDateValid = !!bplastTakenAnswer;

        const isValid = isBPValid && isBPDateValid;

        if (canSkipOption) {
          this.props.onQuestionAnswered(
            this.props.questions[0].id,
            answer,
            isValid
          );
        }

        this.setState({
          isValid: isBPValid,
          validationError,
          canSkip: !canSkipOption,
        });
      }
    });
  }

  _setDate(value, isValid) {
    this.setState({ bplastTakenAnswer: value }, () => {
      this.props.onQuestionAnswered(this.props.questions[1].id, value, isValid);
    });
  }

  _isValid(value, canSkip) {
    if (!value) return false;

    let isValid =
      canSkip ||
      TrackingData.isValidMeasurement(value, {
        type: AppConstants.ModuleType.BLOOD_PRESSURE,
      });

    return isValid;
  }

  _getValidationError() {
    const errorMessages = getStringForLocale(
      'errorMessages',
      this.props.hraLanguageCode
    );
    return errorMessages[
      TrackingData.getErrorConstant({
        type: AppConstants.ModuleType.BLOOD_PRESSURE,
      })
    ];
  }

  _skipQuestion() {
    const {
      canSkip,
      systolicBp,
      diastolicBp,
      bplastTakenAnswer,
      validationError,
    } = this.state;

    this.setState({
      canSkip: !canSkip,
      systolicBp: !canSkip ? undefined : systolicBp,
      diastolicBp: !canSkip ? undefined : diastolicBp,
      bplastTakenAnswer: !canSkip ? undefined : bplastTakenAnswer,
      isValid: !canSkip,
      validationError: !canSkip ? '' : validationError,
    });

    if (canSkip) {
      this.props.onQuestionAnswered(
        this.props.questions[0].id,
        undefined,
        false
      );
      this.props.onQuestionAnswered(
        this.props.questions[1].id,
        undefined,
        false
      );
    } else {
      this.props.onQuestionAnswered(this.props.questions[0].id, 'NA', true);
      this.props.skipQuestion(this.props.questions[1].id);
    }
  }
}

BPQuestion.propTypes = {
  value: PropTypes.array,
  onQuestionAnswered: PropTypes.func.isRequired,
  skipQuestion: PropTypes.func,
  questionNumber: PropTypes.number,
  questionCount: PropTypes.number,
  questions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  answers: PropTypes.array,
  hraLanguageCode: PropTypes.string,
};

export default connect(null, null)(BPQuestion);
