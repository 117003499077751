import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CheckoutSummaryMarkup from './CheckoutSummaryMarkup';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import { shoppingCartSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import { selectAvailablePoints } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import { withDrawer } from '../../../common/drawer2';
import { registerIncentivesDrawerRoute } from '../../../common/drawer2/DrawerRouteMapping';
import { compose } from 'recompose';
import ShoppingCart from './ShoppingCart';
import OrderFailureDrawer from './OrderFailureDrawer';
import OrderSummary from './OrderSummary';

class CheckoutSummary extends React.Component {
  constructor(props) {
    super(...arguments);
    const { address } = props;

    this.state = {
      editAddress: false,
      address,
      addressError: '',
      serviceFailed: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.editAddress) this.setState({ address: nextProps.address });
  }

  render() {
    const { shoppingCartItems, name, emailAddress, points } = this.props;

    const { address } = this.state;

    return (
      <CheckoutSummaryMarkup
        shoppingCartItems={shoppingCartItems}
        editAddress={this._toggleAddress}
        changeAddress={this._changeAddress}
        isEditable={this.state.editAddress}
        address={address}
        name={name}
        emailAddress={emailAddress}
        saveAddress={this._saveAddress}
        addressError={this.state.addressError}
        points={points}
        placeOrder={this._placeOrder}
        closeCart={this._closeCart}
        editCart={this._editCart}
      />
    );
  }

  _saveAddress = () => {
    const address = this.state.address || {};

    if (!address.streetAddr1 || !address.city || !address.zip) {
      this.setState({
        addressError: `${!address.streetAddr1 ? '"Street address line 1"' : ''}
          ${!address.streetAddr1 && (!address.city || !address.zip) ? ', ' : ''}
          ${!address.city ? '"City"' : ''}
          ${!address.city && !address.zip ? ', ' : ''}
          ${!address.zip ? '"ZIP Code" ' : ''}
          can not be empty.`,
      });
    } else {
      this.props.actions.updateAddressLocally(address);
      this.setState({ editAddress: false, addressError: '' });
    }
  };

  _openOrderFailureDrawer = (serviceFailed) => {
    const { drawer } = this.props;
    drawer.close();
    drawer.open(OrderFailureDrawer, {
      serviceFailed,
      closeHandler: () => drawer.close(),
    });
  };

  _placeOrder = (availablePoints) => {
    if (availablePoints < 0) {
      this._openOrderFailureDrawer(false);
      return;
    }

    const { address, shoppingCartItems, drawer } = this.props;
    this.props.actions
      .redeemRewards(address, shoppingCartItems)
      .then(() => {
        drawer.close();
        drawer.open(OrderSummary, null, { large: true });
      })
      .catch(() => {
        this._openOrderFailureDrawer(true);
      });
  };

  _toggleAddress = () => {
    const editAddress = !this.state.editAddress;
    if (editAddress === false) {
      this.setState({ address: this.props.address, addressError: '' });
    }
    return this.setState({ editAddress });
  };

  _changeAddress = (event) => {
    const currAddress = this.state.address;

    const address = { ...currAddress, [event.target.id]: event.target.value };
    this.setState({ address });
  };

  _closeCart = () => {
    const { drawer } = this.props;
    drawer.close();
  };

  _editCart = () => {
    const { drawer } = this.props;
    drawer.close();
    drawer.open(ShoppingCart, null, { large: true });
  };
}

CheckoutSummary.propTypes = {
  actions: PropTypes.object.isRequired,
  shoppingCartItems: PropTypes.array,
  address: PropTypes.object,
  name: PropTypes.string,
  emailAddress: PropTypes.string,
  points: PropTypes.number,
  drawer: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const shoppingCartItems = shoppingCartSelector(state);
  const rewardPreference = state.rewards.preference;
  const address = rewardPreference && rewardPreference;
  const name = `${state.userInfo.firstName} ${state.userInfo.lastName}`;
  const emailAddress = state.userInfo.emailAddress;
  const points = selectAvailablePoints(state);

  return { shoppingCartItems, address, name, emailAddress, points };
}

function mapDispatchToProps(dispatch) {
  const {
    removeFromCart,
    getRewards,
    getRewardsPreference,
    updateAddressLocally,
    redeemRewards,
  } = RewardsActions;

  return {
    actions: bindActionCreators(
      {
        removeFromCart,
        getRewards,
        getRewardsPreference,
        updateAddressLocally,
        redeemRewards,
      },
      dispatch
    ),
  };
}

const composedCheckoutSummary = compose(
  withDrawer,
  connect(mapStateToProps, mapDispatchToProps)
)(CheckoutSummary);

registerIncentivesDrawerRoute('shopping-cart', {
  component: composedCheckoutSummary,
  isLarge: true,
});

export default composedCheckoutSummary;
