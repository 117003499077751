import React from 'react';
import PropTypes from 'prop-types';
import InfoPopupMarkup from './InfoPopupMarkup';

class InfoPopup extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      displayPopup: false,
    };
  }

  render() {
    const { children, styles } = this.props;

    return (
      <InfoPopupMarkup
        displayPopup={this.state.displayPopup}
        onHoverStart={this._onHoverStart}
        onHoverEnd={this._onHoverEnd}
        styles={styles}
      >
        {children}
      </InfoPopupMarkup>
    );
  }

  _onHoverStart = () => {
    this.setState({
      displayPopup: true,
    });
  };

  _onHoverEnd = () => {
    this.setState({
      displayPopup: false,
    });
  };
}

InfoPopup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.nodes),
    PropTypes.node,
  ]).isRequired,

  styles: PropTypes.object,
};

InfoPopup.defaultProps = {
  styles: {},
};

export default InfoPopup;
