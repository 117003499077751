import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import ModuleCardMarkup from './ModuleCardMarkup';

class ModuleCard extends React.Component {
  render = () => {
    const {
      horizontalPlacementIndex,
      tracking,
      isHeader,
      styleOverrides,
      carePlan,
    } = this.props;

    const isCompleted = carePlan.status === AppConstants.PlanStatus.COMPLETED;

    const title = this._getTitle();

    const subTitle = this._getSubTitle();

    const summaryStatement = this._getSummaryStatement();

    return (
      <ModuleCardMarkup
        horizontalPlacementIndex={horizontalPlacementIndex}
        tracking={tracking}
        isCompleted={isCompleted}
        title={title}
        subTitle={subTitle}
        summaryStatement={summaryStatement}
        isHeader={isHeader}
        styleOverrides={styleOverrides}
        areDetailsVisible={
          this.props.activeHistory === this.props.tracking.type
        }
        onToggleDetails={this._onToggleDetails}
      />
    );
  };

  _onToggleDetails = () => {
    if (this.props.setActiveHistory) {
      this.props.setActiveHistory();
    }
  };

  _getTitle = () => {
    const { tracking } = this.props;

    switch (tracking.type) {
      case AppConstants.ModuleType.EDUCATION:
        return 'Education';

      case AppConstants.ModuleType.CLINICAL:
        return 'Clinical';

      default:
        return tracking.type;
    }
  };

  _getSubTitle = () => {
    const { tracking } = this.props;

    switch (tracking.type) {
      case AppConstants.ModuleType.EDUCATION:
        return 'Complete assigned education';

      case AppConstants.ModuleType.CLINICAL:
        return 'Perform clinical actions';

      default:
        return `Track your ${tracking.type.toLowerCase()} 2x per week`;
    }
  };

  _getSummaryStatement = () => {
    const { carePlan, tracking } = this.props;

    const { education } = carePlan;

    const isCompleted = carePlan.status === AppConstants.PlanStatus.COMPLETED;

    const completeActionsCount = carePlan.myClinicalActions.filter(
      (action) => action.completions.length > 0
    ).length;

    let statement = '';

    switch (tracking.type) {
      case AppConstants.ModuleType.EDUCATION:
        statement = `You completed ${education.completeCount} of the ${
          education.assignedCount
        } assigned education content in your care plan.`;
        break;

      case AppConstants.ModuleType.CLINICAL:
        statement = `You completed ${completeActionsCount} of the ${
          carePlan.myClinicalActions.length
        } clinical actions in your care plan.`;
        break;

      default:
        if (tracking.summary) {
          const startDate = moment(carePlan.startDate);

          const endDate = moment(carePlan.endDate);

          const weekCount = endDate.diff(startDate, 'weeks');

          const weeklyAverage = weekCount
            ? tracking.summary.count / weekCount
            : 0;

          statement = `On average, you tracked your ${
            tracking.text.split(' ')[1]
          } ${weeklyAverage.toFixed(
            1
          )}x per week over the duration of your care plan.`;
        }

        break;
    }

    if (statement && isCompleted) {
      statement = `Well done! ${statement}`;
    }

    return statement;
  };
}

ModuleCard.propTypes = {
  isHeader: PropTypes.bool,
  horizontalPlacementIndex: PropTypes.number.isRequired,
  carePlan: PropTypes.object,
  tracking: PropTypes.object,
  activeHistory: PropTypes.string,
  detailsLabel: PropTypes.string,
  setActiveHistory: PropTypes.func,
  onRenderDetails: PropTypes.func,
  styleOverrides: PropTypes.object,
};

ModuleCard.defaultProps = {
  isHeader: false,
  onRenderDetails: () => null,
  styleOverrides: {},
};

export default ModuleCard;
