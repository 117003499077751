import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Modal from 'react-modal';
import Button from '../../common/Button';
import moment from 'moment';

const ProductModalMarkup = ({
  product,
  points,
  isEntering,
  successfullyEntered,
  isOpen,
  closeHandler,
  quantity,
  quantityOptions,
  selectQuantity,
  enterSweepstake,
}) => {
  const RadiumLink = Radium(Link);

  const styles = {
    customStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },
      content: {
        minWidth: '500px',
        minHeight: '200px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: '#fff',
        borderRadius: '15px',
        outline: 'none',
        padding: '50px',
      },
    },

    closebutton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    modalContent: {},

    modalBody: {
      display: 'flex',
    },

    product: {
      width: '168px',
      display: 'flex',
      flexDirection: 'column',
      marginRight: '30px',
    },

    image: {
      maxWidth: '168px',
      maxHeight: '105px',
    },

    productLabel: {
      color: Colors.black,
      fontSize: '16px',
      marginTop: '20px',
      fontWeight: 600,
      cursor: 'pointer',
    },

    description: {
      color: Colors.contentText,
      fontSize: '16px',
      margin: '2px 0 10px',
    },

    closes: {
      margin: 0,
    },

    enterProduct: {
      width: '100%',
    },

    quantityWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '25px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      marginBottom: '20px',
    },

    quantityLabel: {
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.mediumGray2,
      marginBottom: '5px',
    },

    select: {
      display: 'inline-block',
      width: '80px',
      fontSize: '16px',
      height: '34px',
      color: Colors.lightGray3,
      borderRadius: '5px',
      paddingLeft: '5px',
    },

    quantityFooter: {
      textAlign: 'right',
    },

    footerLabel: {
      margin: '0 0 15px',
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.dark,
    },

    button: {
      minWidth: '180px',
      fontSize: '16px',
      padding: '8px 20px',
    },

    modalFooter: {
      marginTop: '10px',
    },

    termsText: {
      color: Colors.contentText,
      fontSize: '16px',
      margin: '20px 0 0',
    },

    termsLink: {
      color: Colors.linkBlue,
      textDecoration: 'none',
      ':hover': {
        color: Colors.dark,
      },
      ':active': {
        color: Colors.dark,
      },
    },
  };

  return (
    <Modal
      contentLabel={'sweepstakesProduct'}
      isOpen={isOpen}
      style={styles.customStyles}
    >
      <a onClick={() => closeHandler()} style={styles.closebutton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>
      <div style={styles.modalContent}>
        <div style={styles.modalBody}>
          <div style={styles.product}>
            <img
              style={styles.image}
              src={`${product.imageUrl}`}
              alt={`${product.sweepstakeName} Card`}
              title={`${product.sweepstakeName} Card`}
            />
            <label style={styles.productLabel}>{product.sweepstakeName}</label>
            <p style={styles.description}>{product.description}</p>
            <p style={styles.closes}>
              Closes: {moment(product.closeDate).format('MM/DD/YYYY')}
            </p>
          </div>

          <div style={styles.enterProduct}>
            <div style={styles.quantityWrapper}>
              {successfullyEntered && (
                <p style={styles.description}>
                  Good luck on your Sweepstakes entry!
                </p>
              )}

              <label style={styles.quantityLabel}>Quantity</label>

              {!successfullyEntered ? (
                quantityOptions.length ? (
                  <select
                    style={styles.select}
                    value={quantity}
                    onChange={selectQuantity}
                  >
                    {quantityOptions.map((q) => (
                      <option key={q} value={q}>
                        {q}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span style={styles.description}>
                    You do not have sufficient balance to Enter Sweepstakes.
                  </span>
                )
              ) : (
                <span style={styles.description}>{quantity}</span>
              )}
            </div>
            <div style={styles.quantityFooter}>
              <p style={styles.footerLabel}>
                Card Total: {quantity * product.entryCost}
              </p>
              <p style={styles.footerLabel}>
                Your Balance:{' '}
                {successfullyEntered
                  ? points
                  : points - quantity * product.entryCost}
              </p>

              {!successfullyEntered && (
                <div>
                  <Button
                    text="Enter"
                    style={styles.button}
                    disabled={!quantityOptions.length || isEntering}
                    onClick={enterSweepstake}
                  />
                  <p style={styles.description}>
                    Odds will vary based on the number of entries
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {!successfullyEntered && (
          <div style={styles.modalFooter}>
            <p style={styles.termsText}>
              By clicking "Enter", you agree to our
              <RadiumLink
                to="/incentive-terms"
                target="_blank"
                style={styles.termsLink}
              >
                {' '}
                Terms.
              </RadiumLink>
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

ProductModalMarkup.propTypes = {
  product: PropTypes.object.isRequired,
  points: PropTypes.number.isRequired,
  isEntering: PropTypes.bool,
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  quantityOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectQuantity: PropTypes.func.isRequired,
  enterSweepstake: PropTypes.func.isRequired,
  successfullyEntered: PropTypes.bool,
};

ProductModalMarkup.defaultProps = {
  isOpen: false,
};

export default Radium(ProductModalMarkup);
