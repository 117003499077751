import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import _ from 'lodash';
import ModuleTracking from './cards/ModuleTracking';
import EducationTracking from './cards/EducationTracking';
import ClinicalTracking from './cards/ClinicalTracking';
import CheckpointModuleCard from './cards/checkpoint/ModuleCard';
import { Flex } from 'reflexbox';

class Modules extends React.Component {
  constructor() {
    super(...arguments);
    this._setActiveHistory = this._setActiveHistory.bind(this);

    this.state = {
      activeHistory: '',
    };
  }
  render() {
    const { carePlan, secondaryTracking } = this.props;

    const { checkpoint: isCheckpointMode } = carePlan;

    const tracking = _(secondaryTracking)
      .reject((action) => /^COACHING:/.test(action.type))
      .uniqBy('type')
      .value();

    const { activeHistory } = this.state;

    const clinicalActions = carePlan.myClinicalActions;

    const completedActions =
      clinicalActions &&
      clinicalActions.filter((action) => action.completions.length > 0).length;

    const totalActions = clinicalActions && clinicalActions.length;

    const clinicalUI =
      clinicalActions.length > 0 ? (
        <ClinicalTracking
          isCheckpointMode={isCheckpointMode}
          clinicalActions={clinicalActions}
          iconClass={'thin-0825_stetoscope_doctor_hospital_ill'}
          iconColor={Colors.white}
          bubbleColor={Colors.lightGreen}
          bubbleFilled
          title="Clinical"
          carePlan={carePlan}
          index={0}
          measure={{
            label: `${completedActions} / ${totalActions}`,
            value: `${completedActions} / ${totalActions}`,
          }}
          key={AppConstants.ModuleType.CLINICAL}
          action={{ type: AppConstants.ModuleType.CLINICAL }}
          activeHistory={activeHistory}
          setActiveHistory={() =>
            this._setActiveHistory(AppConstants.ModuleType.CLINICAL)
          }
        />
      ) : null;

    const educationAction = carePlan.education;

    const { assignedCount, completeCount, education } = educationAction;

    const firstUnreadEducation = _(education)
      .filter((edu) => !edu.completionDate)
      .head();

    const educationUI = assignedCount ? (
      isCheckpointMode ? (
        <CheckpointModuleCard
          key={AppConstants.ModuleType.EDUCATION}
          horizontalPlacementIndex={1000}
          carePlan={carePlan}
          tracking={{ type: AppConstants.ModuleType.EDUCATION }}
          displayedDetailsModuleType={activeHistory}
        />
      ) : (
        <EducationTracking
          iconColor={Colors.white}
          bubbleColor={Colors.navyBlue}
          bubbleFilled
          title="Education"
          description={_.get(educationAction, 'text', '')}
          measure={{
            label: `${completeCount} / ${assignedCount}`,
            value: `${completeCount} / ${assignedCount}`,
          }}
          callToAction={
            firstUnreadEducation
              ? {
                  text: 'Read Article Now',
                  link: `/pha/${firstUnreadEducation.contentId}`,
                }
              : null
          }
        />
      )
    ) : null;

    const moduleUI = tracking.map((tracking, index) => {
      index = clinicalUI ? index + 1 : index;
      return isCheckpointMode ? (
        <CheckpointModuleCard
          key={tracking.type}
          horizontalPlacementIndex={index}
          carePlan={carePlan}
          tracking={tracking}
          index={index}
          activeHistory={activeHistory}
          setActiveHistory={() => this._setActiveHistory(tracking.type)}
        />
      ) : (
        <ModuleTracking
          key={tracking.type}
          index={index}
          carePlan={carePlan}
          tracking={tracking}
          activeHistory={activeHistory}
          setActiveHistory={() => this._setActiveHistory(tracking.type)}
        />
      );
    });

    return (
      <Flex wrap>
        {clinicalUI}
        {moduleUI}
        {educationUI}
      </Flex>
    );
  }

  _setActiveHistory(moduleType) {
    this.setState({
      activeHistory: this.state.activeHistory !== moduleType ? moduleType : '',
    });
  }
}

Modules.propTypes = {
  carePlan: PropTypes.object,
  secondaryTracking: PropTypes.array,
  setActiveHistory: PropTypes.func,
};

export default Radium(Modules);
