import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Header from './Header';
import Modules from './Modules';
import { Flex } from 'reflexbox';

const CarePlanTrackingMarkup = ({
  carePlan,
  primaryTracking,
  secondaryTracking,
  goalStatement,
}) => {
  return (
    <Flex column>
      <Header
        carePlan={carePlan}
        goalStatement={goalStatement}
        primaryTracking={primaryTracking}
      />

      <Modules carePlan={carePlan} secondaryTracking={secondaryTracking} />
    </Flex>
  );
};

CarePlanTrackingMarkup.propTypes = {
  carePlan: PropTypes.object,
  primaryTracking: PropTypes.object,
  secondaryTracking: PropTypes.arrayOf(PropTypes.object),
  goalStatement: PropTypes.object,
};

export default Radium(CarePlanTrackingMarkup);
