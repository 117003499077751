import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import ReactModal from 'react-modal';

const Modal = ({ onCancel, children, title }) => {
  const styles = {
    modalStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },

      content: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '250px',
        minHeight: '150px',
        right: 'initial',
        bottom: 'initial',
        maxWidth: '400px',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: Colors.white,
        outline: 'none',
        WebkitOverflowScrolling: 'touch',
      },
    },

    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },
    title: {
      paddingTop: '10px',
      color: Colors.navyBlue,
      fontSize: '24px',
    },
  };

  return (
    <ReactModal
      isOpen
      contentLabel="LeavingOurSiteModal"
      onRequestClose={onCancel}
      style={styles.modalStyles}
    >
      <a onClick={onCancel} style={styles.closeButton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>
      <div style={[styles.title]}>{title}</div>
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Radium(Modal);
