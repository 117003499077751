import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { StyleRoot } from 'radium';
import { withStyles } from '@material-ui/core';
import Button from '../common/Button';
import TextArea from '../common/questionnaire/TextArea';
import styles, { buttonStyle } from './SubscriptionsPage.styles';

const SubscriptionsPageMarkup = ({
  classes,
  isValidRequest,
  commentText,
  commentSubmitted,
  updateCommentText,
  onCreateComment,
}) => (
  <StyleRoot className={classnames('index', classes.pageRoot)}>
    <div className={classes.pageRootContent}>
      <div className={classes.root}>
        {isValidRequest ? (
          <div className={classes.headerContainer}>
            <span>
              <h2 className={classes.headerText}>Unsubscribe Successful</h2>

              <h3 className={classes.subHeaderText}>
                Please note, we will still send you important administrative
                emails such as password resets.
              </h3>
            </span>
          </div>
        ) : (
          <div className={classes.contentContainer}>
            <div className={classes.section}>
              <span className={classes.sectionLabel}>
                Invalid Subscription Management Link
              </span>

              <div className={classes.sectionContentContainer}>
                <p className={classes.sectionValue}>
                  Unfortunately, it appears that the subscription management
                  link that you are using is invalid. Please be sure that you
                  are using the link exactly as it is provided in the email.
                </p>
                <p className={classes.sectionValue}>
                  If you believe that the link is correct and you are still
                  seeing this error, please contact customer support for
                  assistance.
                </p>
              </div>
            </div>
          </div>
        )}
        {isValidRequest && !commentSubmitted ? (
          <div className={classes.contentContainer}>
            <div className={classes.section}>
              <span className={classes.sectionLabel}>
                Comments (<em>Optional</em>)
              </span>

              <div className={classes.sectionContentContainer}>
                <p className={classes.sectionValue}>
                  Please let us know what we can do to improve your experience:
                  <TextArea
                    value={commentText}
                    onValueChanged={updateCommentText}
                  />
                </p>

                <Button
                  disabled={!commentText.length}
                  style={buttonStyle}
                  text="Submit"
                  onClick={onCreateComment}
                />
              </div>
            </div>
          </div>
        ) : isValidRequest && commentSubmitted ? (
          <div className={classes.contentContainer}>
            <div className={classes.section}>
              <span className={classes.sectionLabel}>Feedback Received</span>

              <div className={classes.sectionContentContainer}>
                <p className={classes.sectionValue}>
                  Thank you for your feedback - it is very much appreciated.
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  </StyleRoot>
);

SubscriptionsPageMarkup.propTypes = {
  disclaimerHtml: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isValidRequest: PropTypes.bool.isRequired,
  emailAddress: PropTypes.string,
  commentText: PropTypes.string.isRequired,
  commentSubmitted: PropTypes.bool.isRequired,
  updateCommentText: PropTypes.func.isRequired,
  onCreateComment: PropTypes.func.isRequired,
};

export default withStyles(styles)(SubscriptionsPageMarkup);
