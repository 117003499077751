import _ from 'lodash';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
// import InfoPopup from '../../../../../components/common/InfoPopup';
import Button from '../../../common/Button';
import { DrawerContent } from '../../../common/drawer2';

const RewardProductDrawerMarkup = ({
  product,
  points,
  enterSuccess,
  closeHandler,
  quantity,
  denominations,
  deliveryOptions,
  onQuantitySelected,
  onDeliveryMethodSelected,
  onDenominationSelected,
  addReward,
  deliveryMethod,
  denomination,
  isAutomated,
  goToCheckout,
}) => {
  const styles = {
    closeButton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    drawerContent: {
      padding: '20px',
    },

    drawerBody: {
      display: 'flex',
    },

    product: {
      width: '168px',
      display: 'flex',
      flexDirection: 'column',
      marginRight: '30px',
    },

    image: {
      maxWidth: '168px',
      maxHeight: '105px',
    },

    productLabel: {
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: 400,
      cursor: 'pointer',
      paddingTop: '20px',
    },

    description: {
      color: Colors.contentText,
      fontSize: '16px',
      lineHeight: 1.2,
      margin: '10px 0px 2px 0px',
      width: '100%',
    },

    configurationContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },

    optionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '25px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      marginBottom: enterSuccess ? '50px' : '20px',
      marginRight: '-25px',
    },

    select: {
      width: '80px',
      fontSize: '16px',
      height: '34px',
      color: Colors.lightGray3,
      borderRadius: '2px',
      padding: '0 3px',
    },

    dropdownSelectionContainer: {
      marginRight: '30%',
    },

    quantityFooter: {
      textAlign: 'right',
    },

    footerLabel: {
      margin: '0 5px 12px',
      fontSize: '16px',
      fontWeight: 400,
      color: Colors.mediumGray2,
    },

    button: {
      minWidth: '210px',
      fontSize: '16px',
      fontWeight: '400',
      marginBottom: '0px',
      marginTop: '5px',
      padding: '10px 20px',
    },
    disabled: {
      backgroundColor: Colors.disabled,
      color: Colors.darkGray,
    },

    drawerFooter: {
      marginTop: '10px',
    },

    contentHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '16px',
      fontWeight: 400,
      color: Colors.bodyText,
      marginBottom: '10px',
    },

    contentSelect: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    column: {
      flex: 1,
    },

    staticConfigurationValue: {
      fontSize: '16px',
      fontWeight: 300,
      color: Colors.bodyText,
    },

    actionLabel: {
      color: Colors.linkBlue,
      fontSize: '16px',
      fontWeight: '500',
      paddingRight: '20px',
      cursor: 'pointer',
    },

    deliveryMethodContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    deliveryMethodInfoPopup: {
      icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14px',
        height: '14px',
        lineHeight: '14px',
        fontSize: '16px',
        color: Colors.white,
        backgroundColor: Colors.dark,
      },

      popupContainer: {
        left: '18px',
        right: 'initial',
      },

      popupContent: {
        borderRadius: '0 10px 10px 10px',
        backgroundColor: Colors.lightenColor(Colors.lightGray, 2),
        padding: '10px 20px',
      },

      popupStem: {
        left: 0,
        right: 'initial',
        top: '-19px',
        borderTop: '20px solid transparent',
        borderLeft: `15px solid ${Colors.lightenColor(Colors.lightGray, 2)}`,
        borderBottom: '0 solid transparent',
        borderRight: '0 solid transparent',
      },
    },

    deliveryMethodInfoPopupContent: {
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: 400,
      width: '240px',
    },

    deliveryMethodOption: {
      margin: '3px 0px',
      fontSize: '16px',
      cursor: 'pointer',
    },

    deliveryMethodOptionRadioButton: {
      marginRight: '10px',
    },

    completedFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingTop: '25px',
    },

    cancelDescription: {
      color: Colors.contentText,
      fontSize: '16px',
    },
  };

  const { setCurrencyLabel } = RewardsConstants;

  const showDisclaimer = !enterSuccess && !isAutomated;

  const disclaimerText = showDisclaimer
    ? product.variations && product.variations.length >= 2
      ? deliveryMethod === RewardsConstants.RewardDeliveryForm.DIGITAL
        ? _.find(product.variations, [
            'deliveryForm',
            RewardsConstants.RewardDeliveryForm.DIGITAL,
          ]).description
        : _.find(product.variations, [
            'deliveryForm',
            RewardsConstants.RewardDeliveryForm.PHYSICAL,
          ]).description
      : product.description
    : '';

  const quantityOptions = _.range(1, 5).map((quantity) => ({
    label: quantity,
    value: quantity,
  }));

  const denominationOptions = _.map(denominations, (denomination) => ({
    label: setCurrencyLabel(denomination),
    value: denomination,
  }));

  return (
    <DrawerContent title={product.name}>
      <div style={[GlobalStyles.regular, styles.drawerContent]}>
        <div style={styles.drawerBody}>
          <div style={styles.product}>
            <img
              style={styles.image}
              src={product.imageUrl}
              alt={`${product.name} gift card`}
              title={`${product.name} gift card`}
            />

            <label style={styles.productLabel}>{product.name}</label>
          </div>

          {isAutomated ? (
            <div style={styles.cancelDescription}>
              You are currently signed up for Automated Rewards. In order to
              sign up for other rewards, you will need to cancel.
            </div>
          ) : (
            <div style={styles.configurationContainer}>
              <div style={styles.optionsContainer}>
                {enterSuccess && (
                  <p style={styles.staticConfigurationValue}>
                    Item has been added to your cart!
                  </p>
                )}

                <div style={styles.contentHeader}>
                  <label style={styles.column}>Denomination</label>
                  <label style={styles.column}>Quantity</label>
                  <label style={styles.column}>
                    Delivery Method
                    {/* <InfoPopup styles={styles.deliveryMethodInfoPopup}>
                      <div style={styles.deliveryMethodInfoPopupContent}>
                        {deliveryOptions.length > 1
                          ? `Select email delivery for the quickest access to your rewards (same day delivery).
                          Check your Message Center on the Healthy Rewards portal within 12 hours for your e-code(s) and redemption instructions.
                          If you choose standard mail, please allow 7-10 business days for delivery of your gift card(s).`
                          : deliveryOptions[0].value ===
                            RewardsConstants.RewardDeliveryForm.DIGITAL
                            ? 'Check your Message Center on the Healthy Rewards portal within 12 hours for your e-code(s) and redemption instructions.'
                            : 'Please allow for 7-10 business days for delivery of your gift card(s).'}
                      </div>
                    </InfoPopup> */}
                  </label>
                </div>

                {!enterSuccess ? (
                  <div style={styles.contentSelect}>
                    <div style={styles.column}>
                      {denominationOptions.length > 1 ? (
                        <div style={styles.dropdownSelectionContainer}>
                          <Select
                            searchable={false}
                            value={denomination}
                            options={denominationOptions}
                            onChange={onDenominationSelected}
                          />
                        </div>
                      ) : (
                        <label style={styles.staticConfigurationValue}>
                          {denominationOptions[0].label}
                        </label>
                      )}
                    </div>

                    <div style={styles.column}>
                      <div style={styles.dropdownSelectionContainer}>
                        <Select
                          searchable={false}
                          value={quantity}
                          options={quantityOptions}
                          onChange={onQuantitySelected}
                        />
                      </div>
                    </div>

                    <div style={styles.column}>
                      {deliveryOptions.length > 1 ? (
                        <div style={styles.deliveryMethodContainer}>
                          {deliveryOptions.map((option) => (
                            <label
                              key={option.value}
                              style={[
                                GlobalStyles.light,
                                styles.deliveryMethodOption,
                              ]}
                            >
                              <input
                                type="radio"
                                name="DeliveryMethod"
                                value={option.value}
                                onChange={onDeliveryMethodSelected}
                                style={styles.deliveryMethodOptionRadioButton}
                                checked={deliveryMethod === option.value}
                              />
                              {option.label}
                            </label>
                          ))}
                        </div>
                      ) : (
                        <label style={styles.staticConfigurationValue}>
                          {deliveryOptions[0].label}
                        </label>
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={styles.contentSelect}>
                    <span
                      style={[styles.column, styles.staticConfigurationValue]}
                    >
                      {setCurrencyLabel(denomination)}
                    </span>
                    <span
                      style={[styles.column, styles.staticConfigurationValue]}
                    >
                      {quantity}
                    </span>
                    <span
                      style={[styles.column, styles.staticConfigurationValue]}
                    >
                      {deliveryMethod ===
                      RewardsConstants.RewardDeliveryForm.DIGITAL
                        ? 'Email'
                        : 'Standard Mail'}
                    </span>
                  </div>
                )}
              </div>

              <div style={styles.quantityFooter}>
                <p style={styles.footerLabel}>
                  Card Total: {setCurrencyLabel(quantity * denomination)}
                </p>

                <p style={styles.footerLabel}>
                  Your Balance:{' '}
                  {setCurrencyLabel(points - quantity * denomination)}
                </p>

                {!enterSuccess ? (
                  <Button
                    text="Add to Cart"
                    style={
                      enterSuccess ||
                      !(denomination && quantity && deliveryMethod)
                        ? [styles.button, styles.disabled]
                        : styles.button
                    }
                    disabled={
                      enterSuccess ||
                      !(denomination && quantity && deliveryMethod)
                    }
                    onClick={addReward}
                  />
                ) : (
                  <div style={styles.completedFooter}>
                    <div
                      style={styles.actionLabel}
                      onClick={() => closeHandler()}
                    >
                      Continue Shopping
                    </div>

                    <Button
                      text="Go to Checkout"
                      style={styles.button}
                      // onClick={() => hashHistory.push('/rewards/shopping-cart')}
                      onClick={goToCheckout}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {showDisclaimer && (
          <div style={styles.drawerFooter}>
            <label style={styles.productLabel}>Disclaimer</label>
            {/* eslint-disable react/no-danger */}
            <p
              style={styles.description}
              dangerouslySetInnerHTML={{ __html: disclaimerText }}
            />
            {/* eslint-enable react/no-danger */}
          </div>
        )}
      </div>
    </DrawerContent>
  );
};

RewardProductDrawerMarkup.propTypes = {
  product: PropTypes.object.isRequired,
  points: PropTypes.number.isRequired,
  enterSuccess: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  isAutomated: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  deliveryMethod: PropTypes.string,
  denomination: PropTypes.number,
  denominations: PropTypes.arrayOf(PropTypes.number).isRequired,
  onQuantitySelected: PropTypes.func.isRequired,
  onDeliveryMethodSelected: PropTypes.func.isRequired,
  onDenominationSelected: PropTypes.func.isRequired,
  addReward: PropTypes.func.isRequired,
  goToCheckout: PropTypes.func.isRequired,

  deliveryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

RewardProductDrawerMarkup.defaultProps = {
  isOpen: false,
};

export default Radium(RewardProductDrawerMarkup);
