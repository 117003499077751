import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hashHistory } from 'react-router';
import { createSelector } from 'reselect';
import {
  CoachingActions,
  NudgeActions,
} from '@healthmine/greyhound-core/src/actions';
import NudgeConstants from '@healthmine/greyhound-core/src/constants/App';
import { nudgeActionSelector } from '@healthmine/greyhound-core/src/selectors/NudgeSelector';
import CarePlans from '@healthmine/greyhound-core/src/modules/CarePlans';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import WhatsNextMarkup from './WhatsNextMarkup';

class WhatsNext extends React.Component {
  componentWillMount() {
    this.props.actions.refreshNudgeActions(5);
  }

  render() {
    const { nudgeActions, tutorial } = this.props;

    return (
      <WhatsNextMarkup
        actions={nudgeActions}
        onActionActivated={this._onActionActivated}
        tutorial={tutorial}
      />
    );
  }

  _onActionActivated = (action) => {
    const { actionRoute, actionId, outboundLink } = action;

    switch (actionRoute) {
      case NudgeConstants.NudgeActionRoutes.BIOMETRIC_SCREENING:
        hashHistory.push('/biometric-screening');
        break;

      case NudgeConstants.NudgeActionRoutes.CARE_PLAN_BUILD:
        hashHistory.push(
          `/care-plan-build/${CarePlans.goalSubjectToUrl(actionId)}`
        );
        break;

      case NudgeConstants.NudgeActionRoutes.CLINICAL:
        hashHistory.push(`/care-plan/${CarePlans.goalSubjectToUrl(actionId)}`);
        break;

      case NudgeConstants.NudgeActionRoutes.COACHING:
        this.props.actions.startSsoProchange(
          () => window.open(outboundLink),
          actionId
        );
        break;

      case NudgeConstants.NudgeActionRoutes.EDUCATION:
        hashHistory.push(`/pha/${actionId}`);
        break;

      case NudgeConstants.NudgeActionRoutes.HRA:
        hashHistory.push('/hra');
        break;

      case NudgeConstants.NudgeActionRoutes.PROGRAM_INFORMATION:
        hashHistory.push('/plan-information');
        break;

      case NudgeConstants.NudgeActionRoutes.SHOP:
        hashHistory.push(
          `/${AppConfig.effectiveConfig.incentiveType.toLowerCase()}/shop`
        );
        break;
    }
  };
}

WhatsNext.propTypes = {
  tutorial: PropTypes.object,
  nudgeActions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      actionRoute: PropTypes.string.isRequired,
      actionId: PropTypes.string.isRequired,
      isActionable: PropTypes.bool.isRequired,
      userPlanId: PropTypes.number,
      outboundLink: PropTypes.string,
      dueDate: PropTypes.string,
    })
  ).isRequired,

  actions: PropTypes.shape({
    startSsoProchange: PropTypes.func.isRequired,
    refreshNudgeActions: PropTypes.func.isRequired,
  }).isRequired,
};

const tutorialSelector = (state) => state.tutorial;

const propSelector = createSelector(
  tutorialSelector,
  nudgeActionSelector,
  (tutorial, nudgeActions) => ({
    tutorial,
    /*****************************************************************************
     *  Even though we explicitly request a maximum of 5, coaching-specific
     *  actions can be dynamically added "ex post facto" and, therefore, cause the
     *  total number to exceed 5. Therefore, we explicitly constrain the number of
     *  actions that can be displayed.
     ****************************************************************************/
    nudgeActions: _.take(nudgeActions, 5),
  })
);

function mapStateToProps(state) {
  return propSelector(state);
}

function mapDispatchToProps(dispatch) {
  const { startSsoProchange } = CoachingActions;

  const { refreshNudgeActions } = NudgeActions;

  return {
    actions: bindActionCreators(
      {
        startSsoProchange,
        refreshNudgeActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNext);
