import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { UserInfoActions } from '@healthmine/greyhound-core/src/actions';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import { ErrorConstants } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import SettingsAccountMarkup from './SettingsAccountMarkup';
import _ from 'lodash';

let timer = null;

class SettingsAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editPassword: false,
      formData: {
        mobileNumber: {
          value: props.mobileNumber || '',
          error: '',
        },
      },
      isFormValid: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.emailAddress != this.props.emailAddress) {
      let formData = this.state.formData;
      formData.mobileNumber.value = nextProps.mobileNumber || '';

      this.setState({
        formData,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  render() {
    const { inboundSSOEnabled } = AppConfig.effectiveConfig;

    const {
      emailAddress,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
    } = this.props;

    const {
      editPassword,
      formData,
      isFormValid,
      saveSuccess,
      saveFailed,
    } = this.state;

    const { _toggleEditPassword, _updateMobileNumber, _saveSettings } = this;

    return (
      <SettingsAccountMarkup
        inboundSSOEnabled={inboundSSOEnabled}
        emailAddress={emailAddress}
        mobileNumber={formData.mobileNumber}
        street={`${addressLine1 || ''}${
          addressLine2 ? ', ' : ''
        }${addressLine2 || ''}`}
        city={city}
        state={state}
        zipCode={zipCode}
        editPassword={editPassword}
        toggleEditPassword={_toggleEditPassword}
        updateMobileNumber={_updateMobileNumber}
        saveSettings={_saveSettings}
        isFormValid={isFormValid}
        saveSuccess={saveSuccess}
        saveFailed={saveFailed}
      />
    );
  }

  @autobind
  _toggleEditPassword() {
    this.setState({
      editPassword: !this.state.editPassword,
    });
  }

  @autobind
  _updateMobileNumber(e) {
    let formData = this.state.formData;
    formData.mobileNumber.value = e.target.value || '';

    if (formData.mobileNumber.value.replace(/\D/g, '').length < 10) {
      formData.mobileNumber.error =
        localizedContent.errorMessages[
          ErrorConstants.ErrorType.INVALID_MOBILE_NUMBER
        ];
    } else {
      formData.mobileNumber.error = '';
    }

    this.setState({
      formData,
    });

    this._isFormValid();
  }

  @autobind
  _isFormValid() {
    const formData = this.state.formData;

    const isFormValid = _.every(formData, { error: '' });

    this.setState({
      isFormValid,
    });
  }

  @autobind
  _saveSettings() {
    const isFormValid = this.state.isFormValid;

    const mobileNumber = this.state.formData.mobileNumber;

    if (isFormValid) {
      this.props.actions
        .updateCellPhoneNumber(mobileNumber.value.replace(/\D/g, ''))
        .then(() => this._saveSuccess())
        .catch(() => this._saveFailed());
    }
  }

  @autobind
  _saveSuccess() {
    this.setState({
      isFormValid: false,
      saveSuccess: true,
    });

    timer = setTimeout(() => {
      this.setState({
        saveSuccess: false,
      });
    }, 3000);
  }

  @autobind
  _saveFailed() {
    this.setState({
      saveFailed: true,
    });

    timer = setTimeout(() => {
      this.setState({
        saveFailed: false,
      });
    }, 3000);
  }
}

SettingsAccount.propTypes = {
  actions: PropTypes.shape({
    updateCellPhoneNumber: PropTypes.func.isRequired,
  }),
  emailAddress: PropTypes.string,
  mobileNumber: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
};

function mapStateToProps(reduxState) {
  const {
    emailAddress,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
  } = reduxState.userInfo;

  const mobileNumber = _.get(reduxState, 'userInfo.mobileNumber');

  return {
    emailAddress,
    mobileNumber,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
  };
}

function mapDispatchToProps(dispatch) {
  const { updateCellPhoneNumber } = UserInfoActions;

  return {
    actions: bindActionCreators(
      {
        updateCellPhoneNumber,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsAccount);
