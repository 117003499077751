import React from 'react';
import Radium, { StyleRoot } from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules/';

const Spinner = () => {
  const row = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.light,
    borderRadius: '70px',
    margin: '0px 10px',
    fill: Colors.light,
    backgroundColor: Colors.lighterGray,
  };

  const rotator = Radium.keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(270deg)' },
  });

  const spinner = {
    width: '120px',
    height: '120px',
    viewBox: '25 25 50 50',
    strokeWidth: '6px',
    strokeLincap: 'round',
    animation: 'rotator 1.4s linear infinite',
    animationName: rotator,
  };

  const dash = Radium.keyframes({
    '0%': {
      strokeDashoffset: '187',
      stroke: Colors.lightGreen,
    },
    '25%': {
      stroke: Colors.yellow,
    },
    '50%': {
      strokeDashoffset: '46.75',
      transform: 'rotate(135deg)',
      stroke: Colors.pink,
    },
    '75%': {
      stroke: Colors.purple,
    },
    '100%': {
      strokeDashoffset: '187',
      transform: 'rotate(450deg)',
      stroke: Colors.mediumBlue,
    },
  });

  const path = {
    fill: 'none',
    strokeWidth: '6px',
    strokeLincap: 'round',
    cx: '60px',
    cy: '60px',
    r: '30px',
    strokeDasharray: '187',
    strokeDashoffset: '0',
    transformOrigin: 'center',
    animation: 'dash 1.4s ease-in-out infinite', // 'colors 5.6s ease-in-out infinite',
    animationName: dash,
  };

  return (
    <StyleRoot style={row}>
      <svg style={spinner}>
        <circle style={path} />
      </svg>
    </StyleRoot>
  );
};

export default Radium(Spinner);
