import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Card from '../../../../common/card/Card';
import { withProps, compose } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import Typography from '../../../../common/Typography';
import moment from 'moment';
import classnames from 'classnames';
import { withDrawer } from '../../../../common/drawer2';
import MedTrackerDrawer from '../drawer/MedTrackerDrawer';

const styles = (theme) => ({
  root: {
    width: '237px',
    height: '230px',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.globalStyles.shadow3,
    },
  },
  bubble: {
    height: '60px',
    width: '60px',
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '30px',
  },
  title: {
    marginTop: '10px',
    flexGrow: 1,
  },
  setupText: {
    color: '#99A7B5',
  },
  trackerBoxes: {
    display: 'flex',
  },
  trackerBox: {
    height: '20px',
    width: '20px',
    borderRadius: theme.globalStyles.borderRadius1,
    backgroundColor: theme.palette.common.formulaOne,
    margin: '3px',
  },
  trackerBoxActive: {
    backgroundColor: theme.palette.common.mintJulep,
  },
});
const MedTrackerCard = ({
  classes,
  title,
  iconClass,
  color,
  noneConfigured,
  numTrackedThisWeek,
  daysTrackedThisWeek,
  openMedTrackerDrawer,
}) => (
  <Card customClass={classes.root} column onClick={openMedTrackerDrawer}>
    <div className={classes.bubble} style={{ backgroundColor: color }}>
      <i className={`${iconClass} ${classes.icon}`} />
    </div>
    <div className={classes.title}>{title}</div>
    {noneConfigured ? (
      <Typography secondary>
        Set up your medication tracking and start earning points
      </Typography>
    ) : (
      <>
        <Typography secondary>
          Tracked {numTrackedThisWeek}{' '}
          {`time${numTrackedThisWeek !== 1 ? 's' : ''}`} this week
        </Typography>
        <div className={classes.trackerBoxes}>
          {_.map(daysTrackedThisWeek, (tracked, idx) => (
            <div
              key={`trackerbox-${idx}`}
              className={classnames(classes.trackerBox, {
                [classes.trackerBoxActive]: tracked,
              })}
            />
          ))}
        </div>
      </>
    )}
  </Card>
);

MedTrackerCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  // from withProps
  noneConfigured: PropTypes.bool.isRequired,
  numTrackedThisWeek: PropTypes.number.isRequired,
  daysTrackedThisWeek: PropTypes.arrayOf(PropTypes.bool).isRequired,
  openMedTrackerDrawer: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withDrawer,
  connect((state) => ({
    medicationTracker: _.get(state, 'demo.medications'),
  })),
  withProps(({ medicationTracker, drawer }) => {
    const noneConfigured = _.every(medicationTracker, (med) =>
      _.isEmpty(med.schedule)
    );

    const datesThisWeek = _.times(
      7,
      (offset) =>
        moment()
          .startOf('w')
          .add(offset, 'd')._d
    );
    const daysTrackedThisWeek = _.map(datesThisWeek, (day) =>
      _.some(medicationTracker, (med) =>
        _.find(med.history, (medDay) => medDay.startOf('d').isSame(day, 'd'))
      )
    );

    const numTrackedThisWeek = _.sumBy(daysTrackedThisWeek);

    const openMedTrackerDrawer = () => {
      drawer.open(MedTrackerDrawer, null, {});
    };

    return {
      noneConfigured,
      numTrackedThisWeek,
      daysTrackedThisWeek,
      openMedTrackerDrawer,
    };
  })
)(MedTrackerCard);
