import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const DesignGrid = ({ toggleDev, showGrid }) => {
  const mainContainer = {
    position: 'fixed',
    width: '1500px',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    height: '1200px',
    pointerEvents: 'none',
    zIndex: '10000',
  };

  const columnContainer = {
    width: '75px',
    height: '1200px',
    display: 'inline-flex',
  };

  const marginColumn = {
    borderLeft: `1px solid ${Colors.lightGreen}`,
    borderRight: `1px solid ${Colors.lightGreen}`,
    width: '6px',
    height: '1200px',
  };

  const godMode = {
    color: Colors.pink,
    opacity: 0,
    position: 'fixed',
    fontSize: '32px',
    top: 100,
    left: 50,
    ':hover': {
      color: Colors.darkenColor(Colors.pink, 2),
      opacity: 1,
    },
    ':active': {
      color: Colors.darkenColor(Colors.pink, 4),
      opacity: 1,
    },
  };

  const mainColumn = {
    width: '60px',
    height: '1200px',
  };
  return (
    <div>
      {showGrid ? (
        <div style={mainContainer}>
          {Array.from(new Array(20), (x, i) => (
            <div key={`${i}-col`} style={columnContainer}>
              <span style={[marginColumn, { borderLeft: '0px' }]} />
              <span style={mainColumn} />
              <span style={marginColumn} />
            </div>
          ))}
        </div>
      ) : null}
      <i
        className="thin-0348_thunderbolt"
        style={godMode}
        onClick={toggleDev}
      />
    </div>
  );
};

DesignGrid.propTypes = {
  toggleDev: PropTypes.func.isRequired,
  showGrid: PropTypes.bool.isRequired,
};

export default Radium(DesignGrid);
