import React from 'react';
import Radium, { StyleRoot } from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import Button from '../../components/common/Button';

const ReturnToLoginMarkup = () => {
  const styles = {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '100%',
      color: Colors.darkText,
      backgroundColor: Colors.light,
      padding: '10px',
      textAlign: 'center',
    },

    para: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '500px',
      color: Colors.contentText,
      fontSize: '16px',
    },

    innerPara: {
      margin: '-7px 0px 80px',
    },

    button: {
      paddingLeft: '25px',
      paddingRight: '25px',
      paddingTop: '10px',
      paddingBottom: '10px',
      marginLeft: '-5px',
      marginBottom: '50px',
      width: '152px',
    },

    icon: {
      fontSize: '95px',
    },
  };

  return (
    <StyleRoot style={styles.mainContainer}>
      <div style={{ transform: 'rotate(26deg)', marginLeft: '-33px' }}>
        <i className="thin-0315_email_mail_post_send" style={styles.icon} />
      </div>
      <div style={[GlobalStyles.regular, styles.para]}>
        <p>We have sent you an email to your registered email address.</p>
        <p style={styles.innerPara}>
          The email includes your login information and a link to reset your
          password.
        </p>
      </div>
      <Button
        style={[styles.button, GlobalStyles.regular]}
        text="Return To Login"
        link="/signin"
      />
    </StyleRoot>
  );
};
export default Radium(ReturnToLoginMarkup);
