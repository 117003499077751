import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  textInput: {
    color: Colors.bodyText,
    height: '80px',
    width: '100%',
    border: '1px solid #99A7B5',
    marginTop: '10px',
    fontSize: '16px',
  },
};
