import React from 'react';
import { routerActions } from 'react-router-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';

const userIsNotOnboard = connectedReduxRedirect({
  authenticatedSelector: (state) =>
    state && (state.signIn.impersonationEmail || !state.onBoarding.completed),
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsNotOnboard',
  redirectPath: '/health-overview',
  allowRedirectBack: false,
});

export default userIsNotOnboard((props) =>
  React.cloneElement(props.children, { expanded: true })
);
