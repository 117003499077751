import { compose, withProps, withHandlers } from 'recompose';
import { matchesProperty } from 'lodash/fp';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  Features,
  AppConstants,
  ActivityConstants,
} from '@healthmine/greyhound-core/src/constants';
import {
  withSecureMessages,
  withNewContentViewing,
} from '@healthmine/greyhound-core/src/hocs';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const itemCountDisplayValue = (count) => (count > 99 ? '99+' : count);

export default compose(
  withSecureMessages,
  withNewContentViewing,
  withRouter,
  connect((state) => ({
    cartItemCount: _.get(state, 'rewards.shoppingCart.length', 0),
  })),
  withProps(
    ({ unreadSecureMessageCount, cartItemCount, router, seenNewContent }) => {
      let links = [
        {
          iconClass: 'icon-Work-Briefcase',
          title: 'Member Health Programs',
          route: '/health-programs',
          badgeText: !seenNewContent(
            ActivityConstants.NewContentViewingTypes.MEMBER_HEALTH_PROGRAMS
          )
            ? 'New'
            : '',
        },
        {
          iconClass: 'icon-Home',
          title: 'Home',
          route: '/health-overview',
        },
        {
          iconClass: 'icon-Medical-Clipboard',
          title: 'Health Record',
          route: '/health-record',
        },
        {
          iconClass: 'icon-Open-Book-Reading-2',
          title: 'Health Library',
          route: '/health-library',
        },
        {
          iconClass: 'icon-Message-Inbox',
          title: 'Messages',
          route: '/messages',
          badgeText: itemCountDisplayValue(unreadSecureMessageCount),
        },
        {
          iconClass: 'icon-Info',
          title: 'Help',
          route: '/help',
          drawerOnly: true,
        },
      ];

      if (
        AppConfig.effectiveConfig.incentiveType ===
        AppConstants.IncentiveType.SWEEPSTAKES
      ) {
        links.splice(4, 0, {
          iconClass: 'icon-Star-Circle',
          title: 'Sweepstakes',
          route: '/sweepstakes',
        });
      }

      if (
        AppConfig.effectiveConfig.incentiveType ===
        AppConstants.IncentiveType.REWARDS
      ) {
        links.splice(2, 0, {
          iconClass: 'icon-Cart',
          title: 'Cart',
          route: '/rewards',
          actionOverride: () => router.push('/rewards/shopping-cart'),
          badgeText: itemCountDisplayValue(cartItemCount),
        });
      }

      if (AppConfig.validateFeature(Features.DOCTOR_CHECKLIST)) {
        links.splice(1, 0, {
          iconClass: 'icon-List',
          title: 'Doctor Checklist',
          route: '/doctor-checklist',
        });
      }

      if (AppConfig.validateFeature(Features.NO_HEALTH_RECORD)) {
        links.splice(1, 1);
      }

      if (AppConfig.validateFeature(Features.DEMOFY)) {
        return {
          links: _.concat(links, [
            {
              iconClass: 'icon-Adjust-Levels',
              title: 'Demofy',
              route: '/demofy',
            },
          ]),
        };
      }

      return { links };
    }
  ),
  withHandlers({
    isLinkActive: ({ location }) => (route) =>
      matchesProperty('pathname', route)(location),
  })
);
