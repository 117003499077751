import { get } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { AppBar, Hidden, Grid, withStyles } from '@material-ui/core';
import { AppConfig, Assets } from '@healthmine/greyhound-core/src/modules';
import Typography from '../common/Typography';
import MemberInfo from './MemberInfo';
import { Link } from 'react-router';
import Clickable from '../common/Clickable';
import { NavigationDrawer } from '../navigation';
import { withDrawer } from '../common/drawer2';
import _get from 'lodash/get';
import withLinks from '../navigation/withLinks';

const styles = (theme) => ({
  root: {
    backgroundColor: '#fff',
    boxShadow: theme.globalStyles.shadow1,
    color: theme.palette.secondary.main,
  },
  impersonationOffset: {
    marginTop: '30px',
  },
  innerContainer: {
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: '45px',
      transform: 'none',
    },
  },
  logo: {
    padding: '0 5px',
    maxHeight: '40px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50%',
    },
  },
  icon: {
    fontSize: '24px',
  },
  navigation: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    right: 0,
    top: '50%',
  },
  helpLink: {
    textDecoration: 'none',
    display: 'inline-block',
    marginRight: '20px',
    color: theme.palette.common.planet9,
  },
  activeHelpLink: {
    color: theme.palette.common.beefy,
  },
  helpIcon: {
    fontSize: '40px',
  },
});

const Header = ({ classes, openDrawer, isImpersonating, isLinkActive }) => {
  const { usesSecondaryLogo } = AppConfig.effectiveConfig;

  const logoAlt = `${_get(
    AppConfig,
    'effectiveConfig.clientDisplayName',
    'Health Plan'
  )} Logo`;

  const secondaryAlt = `${_get(
    AppConfig,
    'effectiveConfig.clientDisplayName',
    'Health Plan'
  )} Logo`;

  return (
    <AppBar
      classes={{
        root: classnames([
          classes.root,
          {
            [classes.impersonationOffset]: isImpersonating,
          },
        ]),
      }}
    >
      <div className={classes.innerContainer}>
        <Hidden mdUp>
          <Clickable
            classes={{ root: classes.navigation }}
            onClick={openDrawer}
          >
            <i className={classnames('icon-Menu', classes.icon)} />
          </Clickable>
        </Hidden>
        <Hidden smDown>
          <MemberInfo />
        </Hidden>
        <div className={classes.logoContainer}>
          <img
            className={classes.logo}
            src={Assets.getAssetURL('logo_primary.png')}
            alt={logoAlt}
            title={logoAlt}
          />
          {usesSecondaryLogo && (
            <img
              className={classes.logo}
              src={Assets.getAssetURL('logo_secondary.png')}
              alt={secondaryAlt}
              title={secondaryAlt}
            />
          )}
        </div>
        <Hidden smDown>
          <Link
            className={classnames(classes.helpLink, {
              [classes.activeHelpLink]: isLinkActive('/help'),
            })}
            to="/help"
          >
            <Grid container alignItems="center" spacing={8}>
              <Grid item>
                <i className={classnames('icon-Info', classes.helpIcon)} />
              </Grid>
              <Grid item>
                <Typography type="h3Medium">Help</Typography>
              </Grid>
            </Grid>
          </Link>
        </Hidden>
      </div>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  openDrawer: PropTypes.func.isRequired,
  isImpersonating: PropTypes.bool.isRequired,
  isLinkActive: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withDrawer,
  withLinks,
  connect((state) => ({
    isImpersonating: !!get('signIn.impersonationEmail', state),
  })),
  withHandlers({
    openDrawer: ({ drawer, location }) => () => {
      drawer.open(NavigationDrawer, { location });
    },
  }),
  withStyles(styles)
)(Header);
