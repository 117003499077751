import PropTypes from 'prop-types';
import React from 'react';
import { compose, pure, setPropTypes } from 'recompose';
import Styles from './TextArea.styles';

export default compose(
  setPropTypes({
    value: PropTypes.string,
    onValueChanged: PropTypes.func.isRequired,
  }),

  pure
)(({ value, onValueChanged }) => (
  <textarea
    style={Styles.textInput}
    value={value || ''}
    onChange={(evt) => onValueChanged(evt.target.value)}
  />
));
