import React from 'react';
import PropTypes from 'prop-types';
import { AppConfig, Assets } from '@healthmine/greyhound-core/src/modules';
import Features from '@healthmine/greyhound-core/src/constants/Features';
import _get from 'lodash/get';

const PageHeader = ({ currentPathname }) => {
  const styles = {
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      marginBottom: '30px',
      marginTop: '60px',
    },

    logoImage: {
      display: 'flex',
      alignSelf: 'center',
      maxHeight: '80px',
      maxWidth: '400px',
      marginLeft: '10px',
      marginRight: '10px',
    },
  };

  const { usesSecondaryLogo } = AppConfig.effectiveConfig;

  const showSecondaryLogo =
    usesSecondaryLogo &&
    (!AppConfig.validateFeature(Features.PRODUCT_GROUP_DEMO) ||
      currentPathname !== '/');

  const logoAlt = `${_get(
    AppConfig,
    'effectiveConfig.clientDisplayName',
    'Health Plan'
  )} Logo`;

  const secondaryAlt = `${_get(
    AppConfig,
    'effectiveConfig.clientDisplayName',
    'Health Plan'
  )} Logo`;

  return (
    <div style={styles.logoContainer}>
      <img
        src={Assets.getAssetURL('logo_primary.png')}
        style={styles.logoImage}
        alt={logoAlt}
        title={logoAlt}
      />

      {showSecondaryLogo ? (
        <img
          src={Assets.getAssetURL('logo_secondary.png')}
          style={styles.logoImage}
          alt={secondaryAlt}
          title={secondaryAlt}
        />
      ) : null}
    </div>
  );
};

PageHeader.propTypes = {
  currentPathname: PropTypes.string.isRequired,
};

export default PageHeader;
