import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

const StepNavigationMarkup = ({
  scrollDown,
  scrollUp,
  skip,
  isNextEnabled,
}) => {
  const nav = {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '80px',
    paddingLeft: '100px',
  };

  const icon = {
    display: 'inline-block',
    transform: 'rotate(90deg)',
  };

  const back = {
    textAlign: 'left',
    color: Colors.linkBlue,
    opacity: '.75',
    cursor: 'pointer',
    width: '79px',
  };

  const next = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80px',
    cursor: isNextEnabled ? 'pointer' : 'not-allowed',
    color: isNextEnabled ? Colors.bodyText : Colors.contentText,
  };

  const optional = {
    textAlign: 'right',
    color: Colors.linkBlue,
    opacity: '.75',
    cursor: 'pointer',
    width: '80px',
  };

  const piconRight = (
    <i className="thin-0160_arrow_next_right" style={{ fontSize: '24px' }} />
  );

  return (
    <div style={[nav, GlobalStyles.light]}>
      <span style={back} onClick={scrollUp}>
        Back
      </span>
      <div style={next} onClick={isNextEnabled ? scrollDown : () => {}}>
        <span>Next &nbsp;</span>
        <div style={icon}>{piconRight}</div>
      </div>
      {skip ? (
        <div style={optional}>
          <span onClick={skip}>Skip</span>
        </div>
      ) : null}
    </div>
  );
};

StepNavigationMarkup.propTypes = {
  scrollDown: PropTypes.func, //.isRequired,
  scrollUp: PropTypes.func, //.isRequired,
  skip: PropTypes.func,
  isNextEnabled: PropTypes.bool.isRequired,
};

export default Radium(StepNavigationMarkup);
