import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withStateHandlers, withProps } from 'recompose';
import { withSweepstakes } from '@healthmine/greyhound-core/src/hocs';
import {
  entryLimitText,
  entryLimitNotice,
  pointsAvailableAfterEntry,
} from '@healthmine/greyhound-core/src/constants/Sweepstakes';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
  withDrawer,
  DrawerButton,
} from '../../common/drawer2';
import StatusWidget from '../../common/widgets/StatusWidget';
import { withTheme, Grid } from '@material-ui/core';
import moment from 'moment';
import Navigational from '../../common/icons/Navigational';
import Typography from '../../common/Typography';
import Clickable from '../../common/Clickable';
import _ from 'lodash';

const GiftCardDrawer = ({
  theme,
  currentEntries,
  entryCost,
  name,
  closeDate,
  entryCount,
  add,
  remove,
  projectedPoints,
  submitEntry,
  entryLimit,
  numberOfPrizes,
  canEnter,
}) => {
  return (
    <DrawerContent
      status="neutral"
      title={name}
      subtitle={`closes on ${moment(closeDate).format('MM/DD/YYYY')}`}
    >
      <DrawerSectionHeader title="Summary" />
      <DrawerSection>
        <StatusWidget
          status={numberOfPrizes}
          statusNote={`prize${numberOfPrizes === 1 ? '' : 's'} available`}
          noBar
        />
      </DrawerSection>
      <DrawerSection>
        <StatusWidget
          status={currentEntries}
          statusNote="current entries"
          noBar
        />
      </DrawerSection>
      <DrawerSection>
        <StatusWidget
          status={`${entryCost} pts`}
          statusNote="cost to enter"
          noBar
          color={theme.palette.common.whiskeySour}
        />
      </DrawerSection>
      {canEnter ? (
        <DrawerSection grow>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Clickable onClick={remove}>
                <Navigational iconClass="icon-Minus-Square" />
              </Clickable>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography type="h1">
                    {entryCount > 0 ? entryCount : '- -'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography secondary>
                    {pointsAvailableAfterEntry(projectedPoints)}
                  </Typography>
                </Grid>
                {!_.isNull(entryLimit) && (
                  <Grid item>
                    <Typography secondary>
                      {entryLimitText(entryLimit)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Clickable onClick={add}>
                <Navigational iconClass="icon-Plus-Square" />
              </Clickable>
            </Grid>
          </Grid>
        </DrawerSection>
      ) : (
        <DrawerSection grow>
          <Typography secondary>{entryLimitNotice(entryLimit)}</Typography>
        </DrawerSection>
      )}
      {canEnter && (
        <DrawerButton
          text="Enter"
          disabled={entryCount < 1}
          onClick={submitEntry}
        />
      )}
    </DrawerContent>
  );
};

GiftCardDrawer.propTypes = {
  theme: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  currentEntries: PropTypes.number.isRequired,
  entryCost: PropTypes.number.isRequired,
  closeDate: PropTypes.string,
  entryCount: PropTypes.number,
  add: PropTypes.func,
  remove: PropTypes.func,
  projectedPoints: PropTypes.number,
  id: PropTypes.string.isRequired,
  submitEntry: PropTypes.func.isRequired,
  entryLimit: PropTypes.number.isRequired,
  numberOfPrizes: PropTypes.number.isRequired,
  canEnter: PropTypes.bool.isRequired,
};

export default compose(
  pure,
  withSweepstakes,
  withProps(({ openSweepstakes, sweepstakeId }) => {
    const selectedSweepstake = _.find(openSweepstakes, {
      sweepstakeId,
    });
    return {
      id: _.get(selectedSweepstake, 'sweepstakeId', ''),
      name: _.get(selectedSweepstake, 'sweepstakeName', ''),
      currentEntries: _.get(selectedSweepstake, 'memberEntries', 0),
      closeDate: _.get(selectedSweepstake, 'closeDate', ''),
      entryCost: _.get(selectedSweepstake, 'entryCost', 0),
      entryLimit: _.get(selectedSweepstake, 'entryLimit'),
      numberOfPrizes: _.get(selectedSweepstake, 'numberOfPrizes', 0),
    };
  }),
  withStateHandlers(
    {
      entryCount: 0,
    },
    {
      add: (
        { entryCount },
        { entryCost, availablePoints, currentEntries, entryLimit }
      ) => () => {
        const canEnter =
          _.isNull(entryLimit) ||
          (availablePoints - (entryCount + 1) * entryCost >= 0 &&
            entryCount + 1 + currentEntries <= entryLimit);
        return {
          entryCount: canEnter ? entryCount + 1 : entryCount,
        };
      },
      remove: ({ entryCount }) => () => ({
        entryCount: entryCount - 1 < 0 ? 0 : entryCount - 1,
      }),
    }
  ),
  withDrawer,
  withProps(
    ({
      availablePoints,
      entryCost,
      entryCount,
      enterSweepstake,
      id,
      drawer,
      entryLimit,
      currentEntries,
    }) => ({
      canEnter: _.isNull(entryLimit) || currentEntries < entryLimit,
      projectedPoints: availablePoints - entryCost * entryCount,
      submitEntry: () => {
        if (entryCount < 1) return;

        enterSweepstake({
          sweepstakeId: id,
          count: entryCount,
        });
        drawer.close();
      },
    })
  ),
  withTheme()
)(GiftCardDrawer);
