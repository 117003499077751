import _ from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose, pure, withStateHandlers } from 'recompose';
import CarePlansMarkup from './CarePlansMarkup';
import * as CarePlanSelector from '@healthmine/greyhound-core/src/selectors/CarePlanSelector';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';

const tutorialSelector = (state) => state.tutorial;

const propSelector = createSelector(
  tutorialSelector,
  CarePlanSelector.selectGoalsForOverview,
  (tutorial, selectedCarePlans) => {
    const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

    const carePlans = fiveFiveUI
      ? _.map(
          _.filter(selectedCarePlans, ({ subject }) => {
            return !_.includes(
              [
                'LEARN_ABOUT_HEALTH',
                'ENERGY',
                'HAPPINESS',
                'RELAX',
                'PREGNANCY',
              ],
              subject
            );
          }),
          (carePlan) => ({
            ...carePlan,
            actionCount: carePlan.actions.length,
            bubbleColor:
              carePlan.subject === 'PREVENTIVE' ? '#FF4EAD' : '#6e81f5',
            title: carePlan.name,
            subTitle: carePlan.description,
          })
        )
      : selectedCarePlans;
    return {
      tutorial: _.get(tutorial, 'page') === 'healthOverview',
      carePlans,
    };
  }
);

export default compose(
  pure,
  connect((state) => propSelector(state)),
  withStateHandlers(
    {
      tracking: undefined,
    },
    {
      activateTracking: () => (tracking) => ({
        tracking,
      }),
      closeTracking: () => () => ({
        tracking: undefined,
      }),
    }
  )
)(CarePlansMarkup);
