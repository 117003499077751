export const types = {
  SET_THIRD_PARTY_COOKIE_SUPPORT: 'web/setThirdPartyCookieSupport',
};

function setThirdPartyCookieSupport(supported: boolean) {
  return {
    type: types.SET_THIRD_PARTY_COOKIE_SUPPORT,
    supported,
  };
}

export default {
  setThirdPartyCookieSupport,
};
