import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import moment from 'moment';
import { Grid, withStyles } from '@material-ui/core';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import {
  ListItem,
  ListItemStyles as styles,
} from '../../../../../common/list/List';
import StatusTitle from '../../../../../common/StatusTitle';
import Chips from '../../../../../common/Chips';

const HealthAction = ({
  title,
  completed,
  completionDate,
  conditions,
  classes,
  openDrawer,
  earnText,
}) => {
  return (
    <ListItem onClick={openDrawer}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={9}>
          <StatusTitle
            status={completed ? 'positive' : 'neutral'}
            title={title}
            subtitle={
              completed
                ? `completed on ${moment(completionDate).format('MM/DD/YYYY')}`
                : 'not complete'
            }
            subtitleClass={!completed && classes.notComplete}
          />
        </Grid>
        <Grid item xs={3}>
          <div className={classes.incentiveConditionsWrapper}>
            <div
              className={`${classes.incentive} ${
                !completed ? classes.incentiveToBeEarned : ''
              }`}
            >
              {earnText}
            </div>
            <Chips color="venus" labels={conditions} />
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
};

HealthAction.propTypes = {
  title: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  completionDate: PropTypes.string,
  conditions: PropTypes.array.isRequired,
  incentiveAmount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  openDrawer: PropTypes.func.isRequired,
  healthActionId: PropTypes.number.isRequired,
  wasRewarded: PropTypes.bool.isRequired,
  maxedOut: PropTypes.bool.isRequired,
  incentiveEarningTextOverride: PropTypes.string,
  earnText: PropTypes.string,
};

export default compose(
  pure,
  withProps(
    ({
      healthActionId,
      openDrawer,
      incentiveEarningTextOverride,
      incentiveAmount,
      maxedOut,
      wasRewarded,
    }) => {
      const earnText = !maxedOut
        ? incentiveEarningTextOverride
          ? incentiveEarningTextOverride
          : IncentiveSummary.getIncentiveEarningText(
              wasRewarded,
              incentiveAmount
            )
        : '';

      return {
        earnText,
        openDrawer: () => openDrawer(healthActionId),
      };
    }
  ),
  withStyles(styles)
)(HealthAction);
