import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import OneOffInput from './OneOffInput';
import { toNumber, inRange, round, get, floor } from 'lodash';

const styles = () => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    fontSize: '18px',
    fontWeight: '400',
    position: 'relative',
  },
  errorContainer: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  error: {
    color: 'red',
  },
});

const HeightAnswer = ({
  classes,
  feet,
  inches,
  onFeetChange,
  onInchesChange,
  isValid,
}) => (
  <div className={classes.root}>
    <OneOffInput
      label="ft"
      value={feet || ''}
      placeholder="- -"
      valid={isValid}
      onChange={(e) => onFeetChange(e.target.value)}
    />
    <OneOffInput
      label="in"
      value={inches || ''}
      placeholder="- -"
      valid={isValid}
      onChange={(e) => onInchesChange(e.target.value)}
    />
    {!isValid && (
      <div className={classnames(classes.errorContainer, classes.error)}>
        <h4>Must be between 1' and 8'.</h4>
      </div>
    )}
  </div>
);

HeightAnswer.propTypes = {
  classes: PropTypes.object.isRequired,
  feet: PropTypes.number,
  inches: PropTypes.number,
  onFeetChange: PropTypes.func.isRequired,
  onInchesChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  withHandlers({
    getHeight: () => (feet, inches) => toNumber(feet) * 12 + toNumber(inches),
  }),
  withStateHandlers(
    ({ currentAnswers, questionId }) => {
      const myHeightAnswer = get(currentAnswers, questionId);
      const height = get(myHeightAnswer, '[0].answer', 0);
      const feet = toNumber(floor(height / 12));
      const inches = toNumber(height - feet * 12);
      return {
        feet: feet === 0 ? null : feet,
        inches: inches === 0 ? null : inches,
        isValid: true,
      };
    },
    {
      onFeetChange: (
        { inches },
        { updateAnswers, getHeight, questionId, markAsValid }
      ) => (value) => {
        const feet = round(value < 0 ? null : value);
        const isValid =
          (inRange(feet, 1, 8) && inRange(inches, 0, 12)) ||
          (feet === null && inches === null);
        if (isValid) {
          markAsValid(questionId, isValid);
          updateAnswers(questionId, getHeight(feet, inches), 'TEXTBOX');
        }
        return {
          feet,
          isValid,
        };
      },
      onInchesChange: (
        { feet },
        { updateAnswers, getHeight, questionId, markAsValid }
      ) => (value) => {
        const inches = round(value < 0 ? null : value >= 12 ? 11 : value);
        const isValid =
          (inRange(feet, 1, 8) && inRange(inches, 0, 12)) ||
          (feet === null && inches === null);
        if (isValid) {
          markAsValid(questionId, isValid);
          updateAnswers(questionId, getHeight(feet, inches), 'TEXTBOX');
        }
        return {
          inches,
          isValid,
        };
      },
    }
  )
)(HeightAnswer);
