import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, AppConfig } from '@healthmine/greyhound-core/src/modules';
import Button from '../common/Button';

const { lingo } = AppConfig.effectiveConfig;

const SettingsCoverageMarkup = ({
  memberCoverages,
  selectedMemberCoverageId,
  onMemberCoverageSelected,
  onCommit,
  isCommitting,
}) => {
  const styles = {
    container: {
      padding: '30px',
    },
    card: {
      padding: '25px',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '300px',
      cursor: 'pointer',
    },
    cardBorder: {
      borderBottom: `1px solid ${Colors.lightGray}`,
    },
    name: {
      fontSize: '20px',
      fontWeight: '200',
      color: Colors.darkGray,
      paddingBottom: '5px',
    },
    tagLine: {
      fontSize: '16px',
      color: Colors.lightGray,
    },
    icon: {
      height: '100%',
      width: '100%',
      color: Colors.highlightBlue,
      fontSize: '36px',
    },
    iconWrapper: {
      height: '36px',
      width: '36px',
      marginLeft: '30px',
    },
    button: {
      minWidth: '200px',
      marginBottom: 0,
      padding: '8px 20px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.tagLine}>Select your {lingo.coverageLabel}</div>
      {memberCoverages.map((cov, i) => {
        const { memberCoverageId, coverageName, relationshipLabel } = cov;

        const notLastStyle =
          memberCoverages.length !== i + 1 ? styles.cardBorder : {};

        return (
          <div
            style={[styles.card, notLastStyle]}
            key={i}
            onClick={() => onMemberCoverageSelected(memberCoverageId)}
          >
            <div>
              <div style={styles.name}>{coverageName}</div>
              <div style={styles.tagLine}>{relationshipLabel}</div>
            </div>
            {memberCoverageId === selectedMemberCoverageId && (
              <div style={styles.iconWrapper}>
                <i
                  className="thin-0154_ok_successful_check"
                  style={styles.icon}
                />
              </div>
            )}
          </div>
        );
      })}
      <div>
        <Button
          disabled={isCommitting}
          style={styles.button}
          text="Save"
          onClick={onCommit}
        />
      </div>
    </div>
  );
};

SettingsCoverageMarkup.propTypes = {
  memberCoverages: PropTypes.arrayOf(
    PropTypes.shape({
      memberCoverageId: PropTypes.number.isRequired,
      coverageName: PropTypes.string.isRequired,
      relationshipLabel: PropTypes.string.isRequired,
    })
  ),

  selectedMemberCoverageId: PropTypes.number,
  onMemberCoverageSelected: PropTypes.func.isRequired,
  onCommit: PropTypes.func.isRequired,
  isCommitting: PropTypes.bool.isRequired,
};

export default Radium(SettingsCoverageMarkup);
