import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import classnames from 'classnames';

const styles = () => ({
  inputContainer: {
    display: 'inline-block',
    width: '110px',
    margin: '20px',
    marginBottom: '0',
  },
  input: {
    color: 'black',
    border: 'none',
    borderBottom: '1px solid #f1f1f1',
    textAlign: 'center',
    width: '75px',
    '&::placeholder': {
      fontWeight: 'bold',
    },
    '&:focus': {
      outline: 'none',
    },
    fontSize: '20px',
  },
  label: {
    color: 'black',
    fontSize: '20px',
  },
  inline: {
    display: 'inline-block',
  },
});

const OneOffInput = (inputProps) => {
  const { classes, label, value, valid, placeholder, onChange } = inputProps;
  return (
    <div className={classes.inputContainer}>
      <input
        type="number"
        className={classes.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <Typography
        type="h3"
        className={classnames(classes.inline, classes.label, {
          [classes.error]: !valid,
        })}
      >
        {label}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(OneOffInput);
