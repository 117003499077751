import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, AppConfig } from '@healthmine/greyhound-core/src/modules';
import Modal from 'react-modal';
import Button from './Button';
import { Features } from '@healthmine/greyhound-core/src/constants';

const WelcomeBackModalMarkup = ({ onClose }) => {
  const styles = {
    modalStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1300,
      },

      content: {
        display: 'flex',
        minWidth: '250px',
        minHeight: '250px',
        maxWidth: '300px',
        maxHeight: '250px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: Colors.white,
        outline: 'none',

        WebkitOverflowScrolling: 'touch',
      },
    },

    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '300',
    },

    title: {
      color: Colors.navyBlue,
      fontSize: '24px',
    },

    statement: {
      color: Colors.contentText,
      fontSize: '16px',
      margin: '15px 0 20px',
    },

    continueButton: {
      width: '125px',
      marginTop: '20px',
      fontWeight: 600,
    },
  };

  return (
    <Modal
      isOpen
      contentLabel="WelcomeBackModal"
      onRequestClose={onClose}
      style={styles.modalStyles}
    >
      <a onClick={onClose} style={styles.closeButton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>

      <div style={styles.content}>
        <div style={styles.title}>
          {AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)
            ? 'Feature Unavailable'
            : 'Welcome back!'}
        </div>

        <div style={styles.statement}>
          {AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)
            ? '3rd Party Biometric Screenings Disabled'
            : 'Click OK to continue.'}
        </div>

        <Button text="OK" style={styles.continueButton} onClick={onClose} />
      </div>
    </Modal>
  );
};

WelcomeBackModalMarkup.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Radium(WelcomeBackModalMarkup);
