import { types } from './actions';
import { types as prescriptionTypes } from '@healthmine/greyhound-core/src/actions/PrescriptionActions';
import { setupHistory } from './helpers';

const initialState = {};

const setSessionState = (state) => {
  sessionStorage.setItem('medicationAdherenceState', JSON.stringify(state));
  return state;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case prescriptionTypes.GET_PRESCRIPTION_HISTORY_SUCCESS: {
      const { result, userInfo } = action;
      return setupHistory(result, userInfo);
    }

    case types.MEDICATIONS_UPDATE_SCHEDULE: {
      const { medication, schedule } = action.payload;
      return setSessionState({
        ...state,
        [medication]: {
          ...state[medication],
          schedule,
        },
      });
    }

    case types.MEDICATIONS_UPDATE_HISTORY: {
      const { medication, history } = action.payload;
      return setSessionState({
        ...state,
        [medication]: {
          ...state[medication],
          history,
        },
      });
    }

    default:
      return state;
  }
};
