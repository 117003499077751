import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const AlertCardMarkup = ({
  message,
  actionLabel,
  onActionLabelClicked,
  children,
}) => {
  const styles = {
    root: {
      flex: '0 0 300px',
      minWidth: '300px',
    },

    content: {
      padding: '30px',
      height: '190px',
      maxHeight: '211px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'center',
      alignItems: 'center',
    },

    iconContainer: {
      backgroundColor: Colors.yellow,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '35px',
      height: '35px',
      borderRadius: '50%',
    },

    icon: {
      color: Colors.light,
      fontSize: '30px',
    },

    message: {
      color: Colors.bodyText,
      fontSize: '16px',
      margin: '10px 0 15px',
      width: '100%',
    },

    actionLabel: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.highlightBlue,
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.root}>
      <div style={styles.content}>
        <span style={styles.iconContainer}>
          <span style={styles.icon}>!</span>
        </span>

        <p style={styles.message}>{message}</p>

        <span style={styles.actionLabel} onClick={onActionLabelClicked}>
          {actionLabel}
        </span>

        {children}
      </div>
    </div>
  );
};

AlertCardMarkup.propTypes = {
  message: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  onActionLabelClicked: PropTypes.func.isRequired,

  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Radium(AlertCardMarkup);
