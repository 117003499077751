import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  root: {
    padding: '0 75px',
  },

  sectionWrapper: {
    display: 'flex',
    paddingTop: '30px',
    justifyContent: 'center',
  },

  tableOfContents: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '95px',
    flex: '0 1 185px',
    marginRight: '40px',
  },

  tableOfContentsEntry: {
    color: Colors.lightGray,
    textDecoration: 'none',
    cursor: 'pointer',
    padding: '10px 5px',
    fontSize: '16px',
    display: 'block',
    ':hover': {
      color: Colors.contentText,
    },
  },

  activeEntry: {
    color: Colors.linkBlue,
    fontWeight: '400',
    ':hover': {
      color: Colors.linkBlue,
    },
  },

  stickyTable: {
    paddingTop: '100px',
    flexDirection: 'column',
    minWidth: '95px',
    marginRight: '40px',
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '550px',
  },

  emptyPlanInfo: {
    color: Colors.lightGray,
    fontSize: '16px',
    padding: '15px',
  },
};
