import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps, withHandlers } from 'recompose';
import _ from 'lodash';
import GridWrapper from '../../../../common/GridWrapper';
import CoachingCard from './CoachingCard';
import { withDrawer } from '../../../../common/drawer2';
import CoachingProgram from '../drawer/CoachingProgram';
import CoachingEnroll from '../drawer/CoachingEnroll';
import TrackerCard from '../card/TrackerCard';
import { TrackingStatus } from '@healthmine/greyhound-core/src/constants/Tracking';

const CarePlanCardWrapper = ({ combinedCards }) => {
  return <GridWrapper children={combinedCards} />;
};

CarePlanCardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  drawer: PropTypes.object.isRequired,
  trackers: PropTypes.array.isRequired,
  combinedCards: PropTypes.array.isRequired,
};

export default compose(
  pure,
  withDrawer,
  withHandlers({
    openCoachingEnroll: ({ drawer }) => (programId) =>
      drawer.open(CoachingEnroll, { programId }),
    openCoachingProgram: ({ drawer }) => (programId) =>
      drawer.open(CoachingProgram, { programId }),
  }),
  withProps(
    ({
      openCoachingEnroll,
      openCoachingProgram,
      children,
      trackers,
      coachingPrograms,
    }) => {
      const trackerCards = _.chain(trackers)
        .filter(
          (tracker) =>
            tracker.status === TrackingStatus.ACTIVE ||
            tracker.status === TrackingStatus.AVAILABLE
        )
        .map((tracker) => <TrackerCard key={tracker.type} tracker={tracker} />)
        .value();

      const coachingProgramCards = _.map(
        coachingPrograms,
        ({
          icon,
          isEnrolled,
          programId,
          programName,
          actionLabel,
          availabilityDescription,
          compliantActivityCount,
        }) => {
          const openDrawer = isEnrolled
            ? () => openCoachingProgram(programId)
            : () => openCoachingEnroll(programId);
          return (
            <CoachingCard
              title={programName}
              iconClass={`icon-${icon}`}
              openDrawer={openDrawer}
              compliantActivityCount={compliantActivityCount}
              label={
                _.isUndefined(availabilityDescription)
                  ? actionLabel
                  : availabilityDescription
              }
            />
          );
        }
      );

      return {
        combinedCards: _.concat(
          _.filter(children, (child) => !!child),
          coachingProgramCards,
          trackerCards
        ),
      };
    }
  )
)(CarePlanCardWrapper);
