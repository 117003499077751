import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../common/Typography';

const CheckListHeader = () => {
  return (
    <Grid container direction="column" spacing={16}>
      <Grid item>
        <Typography>
          Your Doctor Checklist helps you remember important topics to discuss
          with your doctor. Print out your list and bring it to your next
          appointment.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CheckListHeader;
