import React from 'react';
import PropTypes from 'prop-types';
import ViewDocument from '../../../common/ViewDocument';
import { DrawerContent } from '../../../common/drawer2';

const OfficialRules = ({ title }) => {
  return (
    <DrawerContent title={title}>
      <ViewDocument documentFile="incentive_terms_en.html" drawerFrame />
    </DrawerContent>
  );
};

OfficialRules.propTypes = {
  title: PropTypes.string.isRequired,
};

export default OfficialRules;
