import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FteActions } from '@healthmine/greyhound-core/src/actions';
import HealthGoalMarkup from '../../components/onboarding/HealthGoalMarkup';
import _ from 'lodash';

class HealthGoalsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }
  render() {
    const { healthGoals, healthGoalsAnswerChoices, onFinishClick } = this.props;

    const { addHealthGoal } = this.props.actions;
    return (
      <HealthGoalMarkup
        healthGoals={healthGoals.goals}
        onClick={addHealthGoal}
        errors={this.state.errors}
        scrollDown={this._continueClickHander}
        scrollUp={this._scrollUp}
        healthGoalsAnswerChoices={healthGoalsAnswerChoices}
        onFinishClick={this._onFinishClick}
        skip={onFinishClick}
      />
    );
  }

  _onFinishClick = () => {
    if (!this._formIsValid()) {
      return;
    }
    const { healthGoals, onFinishClick } = this.props;

    const { postHealthAnswers } = this.props.actions;

    postHealthAnswers([healthGoals.healthAnswer]).then(onFinishClick);
  };

  _scrollUp = () => {
    this.props.scrollUp(this.props.step - 1);
  };

  _scrollDown = () => {
    this.props.scrollDown(this.props.step + 1);
  };

  _formIsValid = () => {
    let formIsValid = true;

    let errors = [];
    if (this.props.healthGoals.isValid != true) {
      errors = [...errors, 'Please select at least one health goal'];
      formIsValid = false;
    }
    this.setState({ errors });
    return formIsValid;
  };

  _continueClickHander = () => {
    if (!this._formIsValid()) {
      return;
    }
    const { healthGoals } = this.props;

    const { postHealthAnswers } = this.props.actions;

    postHealthAnswers([healthGoals.healthAnswer]).then(this._scrollDown);
  };
}

HealthGoalsContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  scrollUp: PropTypes.func.isRequired,
  scrollDown: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  healthGoals: PropTypes.object,
  healthGoalsAnswerChoices: PropTypes.array,
  onFinishClick: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { healthGoals, questionnaire } = state.onBoarding;

  const questions = _.get(questionnaire, 'questions');

  const healthGoalsQuestion = _.find(questions, [
    'id',
    healthGoals.healthAnswer.questionId,
  ]);

  const healthGoalsAnswerChoices = _.get(healthGoalsQuestion, 'answerOptions');

  return {
    healthGoals,
    healthGoalsAnswerChoices,
  };
}

function mapDispatchToProps(dispatch) {
  const { addHealthGoal, postHealthAnswers } = FteActions;
  return {
    actions: bindActionCreators({ addHealthGoal, postHealthAnswers }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(HealthGoalsContainer)
);
