import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withStateHandlers,
} from 'recompose';
import Radium from 'radium';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import { withSubscriptions } from '@healthmine/greyhound-core/src/hocs';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import SubscriptionsPageMarkup from './SubscriptionsPage.markup';

const EMAIL_COMMUNICATION_CODE = 'EMAIL';

export default compose(
  Radium,
  withRouter,
  withSubscriptions,

  withProps((props) => ({
    disclaimerHtml: _get(
      AppConfig,
      'effectiveConfig.lingo.subscriptionDisclaimerHtml'
    ),
    memberId: _get(props, 'location.query.m'),
    emailAddress: _get(props, 'location.query.e'),
    token: _get(props, 'location.query.t'),
    outreachType: _get(props, 'location.query.utm_source'),
    outreachId: _get(props, 'location.query.utm_campaign'),
  })),

  withStateHandlers(
    ({ memberId, emailAddress, token }) => ({
      commentSubmitted: false,
      commentText: '',
      isValidRequest: !!(memberId && emailAddress && token),
    }),

    {
      onCommentSubmitted: () => () => ({ commentSubmitted: true }),
      updateCommentText: () => (commentText) => ({ commentText }),
      markRequestAsInvalid: () => () => ({ isValidRequest: false }),
    }
  ),

  lifecycle({
    componentDidMount() {
      const {
        isValidRequest,
        memberId,
        token,
        outreachType,
        outreachId,
        unsubscribeWithToken,
        markRequestAsInvalid,
      } = this.props;

      if (isValidRequest) {
        const commPreferences = [
          {
            communication_method_cd: EMAIL_COMMUNICATION_CODE,
            is_allowed: false,
            is_preferred: false,
          },
        ];
        const trackUnsubscribe =
          outreachType && outreachId
            ? {
                outreach_type: outreachType,
                outreach_id: outreachId,
              }
            : undefined;

        unsubscribeWithToken(
          memberId,
          token,
          commPreferences,
          trackUnsubscribe
        ).catch(markRequestAsInvalid);
      }
    },
  }),

  withHandlers({
    onCreateComment: ({
      memberId,
      token,
      createSubscriptionCommentWithToken,
      commentText,
      onCommentSubmitted,
    }) => () =>
      createSubscriptionCommentWithToken(memberId, token, commentText).then(
        onCommentSubmitted
      ),
  })
)(SubscriptionsPageMarkup);
