import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Modal from 'react-modal';

const ThirdPartyCookieWarning = ({ onClose }) => {
  const styles = {
    modalStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1300,
      },

      content: {
        display: 'flex',
        minWidth: '300px',
        minHeight: '250px',
        maxWidth: '300px',
        maxHeight: '250px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: Colors.white,
        outline: 'none',
        padding: '40px',

        WebkitOverflowScrolling: 'touch',
      },
    },

    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      fontWeight: '300',
    },

    title: {
      color: Colors.navyBlue,
      fontSize: '24px',
    },

    statement: {
      color: Colors.contentText,
      lineHeight: 1.2,
      fontSize: '16px',
      marginTop: '15px',
    },
  };

  return (
    <Modal
      isOpen
      contentLabel="ThirdPartyCookieWarning"
      onRequestClose={onClose}
      style={styles.modalStyles}
    >
      <a onClick={onClose} style={styles.closeButton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>

      <div style={styles.content}>
        <div style={styles.title}>Oops!</div>

        <div style={styles.statement}>
          Looks like your web browser's cookies are disabled. We use cookies to
          help us offer you the best experience.
        </div>

        <div style={styles.statement}>
          In order for your request to be completed, you must enable web browser
          cookies.
        </div>
      </div>
    </Modal>
  );
};

ThirdPartyCookieWarning.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Radium(ThirdPartyCookieWarning);
