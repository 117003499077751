import { Colors } from '@healthmine/greyhound-core/src/modules';

const newStyles = {
  welcome: {
    textTransform: 'uppercase',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '22px',
  },
  cardWrapper: {
    marginTop: '15px',
    display: 'flex',
  },
  leftCardWrapper: {
    flex: '0 0 33.3333%',
  },
  middleCardWrapper: {
    flex: '0 0 33.3333%',
    display: 'flex',
    justifyContent: 'center',
  },
  rightCardWrapper: {
    flex: '0 0 33.3333%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  employerHero: {
    position: 'relative',
  },
  heroImage: {
    width: '800px',
  },
  heroText: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    paddingLeft: '40px',
    justifyContent: 'center',
    textShadow: '2px 2px rgba(0,0,0,0.2)',
  },
  imageWelcome: {
    fontSize: '36px',
    fontWeight: '500',
    margin: '10px 0',
    textTransform: 'capitalize',
  },
  callToActionText: {
    margin: '10px 0',
    fontSize: '24px',
    fontWeight: '300',
    lineHeight: '27px',
  },
  callToAction: {
    margin: '10px 0',
    padding: '10px 40px',
    border: '1px solid #fff',
    display: 'inline-block',
    fontSize: '18px',
    fontWeight: '300',
  },
};

const oldStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.light,
    backgroundColor: Colors.hexToRgba(Colors.black, 0.9),
    maxWidth: '100%',
    padding: '40px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },

  welcomeBlock: {
    flex: '1 0 33.34%',
  },

  guideBlock: {
    flex: '2 0 66.66%',
    padding: '0 30px',
    boxSizing: 'border-box',
  },

  primaryText: {
    fontSize: '20px',
    margin: 0,
    textTransform: 'capitalize',
  },

  secondaryText: {
    fontSize: '26px',
    margin: '10px 0',
  },

  highlight: {
    fontWeight: '600',
  },

  scaleContainer: {
    position: 'relative',
    paddingTop: '20px',
    paddingBottom: '105px',
  },

  labelContainer: {
    position: 'absolute',
    top: '40px',
    left: '0px',
    right: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px',
  },

  label: {
    width: '140px',
    textAlign: 'center',
    color: Colors.contentText,
    fontSize: '16px',
    fontWeight: 400,
  },

  scaleLine: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: Colors.white,
    margin: '0 80px',
  },

  bubbleContainer: {
    position: 'absolute',
    top: '10px',
    left: '0px',
    right: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 80px',
  },

  bubble: {
    width: '14px',
    height: '14px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.light,
    borderRadius: '50%',
    padding: '2px',
    textAlign: 'center',
    backgroundColor: Colors.black,
    color: Colors.light,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '12px',
  },

  bubbleBlue: {
    borderColor: Colors.steelBlue,
  },

  bubbleGreen: {
    borderColor: Colors.darkGreen,
  },

  bubbleRed: {
    borderColor: Colors.heatherRed,
  },

  icon: {
    display: 'block',
    fontSize: '60px',
    padding: '10px',
    color: Colors.light,
  },
};

export default {
  newStyles,
  oldStyles,
};
