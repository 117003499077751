import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import HeaderMenu from './HeaderMenu';
import ProfileMenuMarkup from './ProfileMenuMarkup';
import { SessionActions } from '../../actions';
import { LightBulb } from '../../styles/uhc-icons';

class ProfileMenu extends React.Component {
  render() {
    return (
      <HeaderMenu
        menuId="ProfileMenu"
        menuType="secondary"
        primaryRoute="/settings/account"
        iconClassName="thin-0699_user_profile_avatar_man_male"
        uhcIcon={LightBulb}
        activeRule={/\/(settings|prescriptions)/}
      >
        <ProfileMenuMarkup onLogout={this._onLogout} />
      </HeaderMenu>
    );
  }

  _onLogout = () => {
    this.props.actions.endSession();
  };
}

ProfileMenu.propTypes = {
  router: PropTypes.object.isRequired,

  actions: PropTypes.shape({
    endSession: PropTypes.func.isRequired,
  }).isRequired,
};

function mapDispatchToProps(dispatch) {
  const { endSession } = SessionActions;

  return {
    actions: bindActionCreators({ endSession }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(withRouter(ProfileMenu));
