import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _ from 'lodash';

const styles = (theme) => ({
  frequencyHeading: {
    color: theme.palette.secondary.main,
    marginBottom: '10px',
  },
  frequencyWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  frequencyBox: {
    height: '20px',
    width: '20px',
    borderRadius: theme.globalStyles.borderRadius1,
    backgroundColor: theme.palette.common.formulaOne,
    margin: '3px',
  },
  trackedBox: {
    backgroundColor: theme.palette.common.mintJulep,
  },
});

const Frequency = ({ classes, heading, frequencies }) => {
  return (
    <div>
      {heading && <div className={classes.frequencyHeading}>{heading}</div>}
      <div className={classes.frequencyWrapper}>
        {_.map(frequencies, (value, i) => {
          const frequencyClass = classnames(classes.frequencyBox, {
            [classes.trackedBox]: value,
          });
          return <div key={i} className={frequencyClass} />;
        })}
      </div>
    </div>
  );
};

Frequency.defaultProps = {
  frequencies: Array.from(new Array(7).keys()).map(() => false),
};

Frequency.propTypes = {
  classes: PropTypes.object.isRequired,
  heading: PropTypes.string,
  frequencies: PropTypes.arrayOf(PropTypes.bool),
};

export default withStyles(styles)(Frequency);
