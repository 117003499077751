import { connect } from 'react-redux';
import { compose, pure, withProps } from 'recompose';
import { withOptOut } from '@healthmine/greyhound-core/src/hocs';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import { createSelector } from 'reselect';
import _ from 'lodash';
import ComplianceStatusMarkup from './ComplianceStatus.markup';
import moment from 'moment';

const COMPLIANT = 'COMPLIANT';
const AT_RISK = 'AT_RISK';
const NON_COMPLIANT = 'NON_COMPLIANT';

const END_OF_LAST_QUARTER = moment()
  .startOf('quarter')
  .subtract(1, 'day')
  .format('YYYY-MM-DD');
const END_OF_RUN_OUT_PERIOD = moment()
  .startOf('quarter')
  .add(1, 'month')
  .format('YYYY-MM-DD');

const titleLingoSuffixFrom = (isOptOutActive, currentComplianceStatus) => {
  if (isOptOutActive) {
    return 'optOut';
  } else if (currentComplianceStatus === COMPLIANT) {
    return 'compliant';
  } else if (currentComplianceStatus === AT_RISK) {
    return 'atRisk';
  } else {
    return 'nonCompliant';
  }
};

const descriptionLingoSuffixFrom = (
  isOptOutActive,
  currentComplianceStatus,
  previousComplianceStatus
) => {
  if (isOptOutActive) {
    return 'optOut';
  } else if (currentComplianceStatus === COMPLIANT) {
    return 'compliant';
  } else if (previousComplianceStatus === NON_COMPLIANT) {
    return 'recurringNonCompliant';
  } else if (currentComplianceStatus === NON_COMPLIANT) {
    return 'newlyNonCompliant';
  } else {
    return 'compliantWarning';
  }
};

const compliance = (state) => state.compliance;

const propSelector = createSelector(
  compliance,

  (compliance) => {
    const currentComplianceStatus = _.get(
      compliance,
      'current.healthActionStatus'
    );
    const previousMeasuredForDate = _.get(
      compliance,
      'previous.measuredForDate'
    );
    const previousMeasuredOnDate = _.get(compliance, 'previous.measuredOnDate');

    /* only get previous compliance status if user has a final measurement
     for that period - otherwise, return compliant */
    const previousComplianceStatus =
      previousMeasuredForDate === END_OF_LAST_QUARTER &&
      previousMeasuredOnDate === END_OF_RUN_OUT_PERIOD
        ? _.get(compliance, 'previous.healthActionStatus', COMPLIANT)
        : COMPLIANT;

    return {
      currentComplianceStatus,
      previousComplianceStatus,
    };
  }
);

export default compose(
  pure,
  withOptOut,
  connect((state) => propSelector(state)),

  withProps(
    ({
      currentComplianceStatus,
      previousComplianceStatus,
      isOptOutActive,
      isOptOutExpiring,
    }) => {
      const titleLingoSuffix = titleLingoSuffixFrom(
        isOptOutActive,
        currentComplianceStatus
      );

      const descriptionLingoSuffix = descriptionLingoSuffixFrom(
        isOptOutActive,
        isOptOutExpiring,
        currentComplianceStatus,
        previousComplianceStatus
      );

      return {
        heading: AppConfig.getLingo('complianceHeading'),

        title: AppConfig.getLingo(`complianceTitle_${titleLingoSuffix}`),

        titleColor:
          isOptOutActive ||
          currentComplianceStatus === NON_COMPLIANT ||
          (previousComplianceStatus === AT_RISK &&
            currentComplianceStatus !== NON_COMPLIANT) ||
          (currentComplianceStatus === AT_RISK &&
            previousComplianceStatus !== COMPLIANT)
            ? Colors.newRed
            : Colors.lime,

        description: AppConfig.getLingo(
          `complianceDesc_${descriptionLingoSuffix}`
        ),
      };
    }
  )
)(ComplianceStatusMarkup);
