import moment from 'moment';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router';
import { HealthRisk } from '@healthmine/greyhound-core/src/constants/prop-types';
import {
  GlobalStyles,
  Colors,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import Page from '../common/Page';
import AppTheme from '@healthmine/greyhound-core/src/modules/app-themes/LeapFrog';

const HealthRiskAssessmentSummaryMarkup = ({
  healthRisks,
  recommendations,
  assessmentDate,
  overallRiskLevel,
}) => {
  const RadiumLink = Radium(Link);

  const styles = {
    page: {
      paddingTop: '20px',
      paddingBottom: '40px',
      paddingLeft: '10%',
      paddingRight: '10%',
    },

    pageTitle: {
      color: Colors.bodyText,
      paddingTop: '20px',
      marginBottom: '25px',
      fontSize: '20px',
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'row',
    },

    icon: {
      display: 'flex',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '50%',
      color: 'white',
      width: '76px',
      height: '76px',
      fontWeight: '100',
      fontSize: '40px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#7770ff',
    },

    descriptionText: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '26px',
      margin: '8px 0px 0px 15px',
    },

    shortTitle: {
      fontSize: '16px',
      fontWeight: '500',
      paddingBottom: '3px',
    },

    titleText: {
      marginBottom: '40px',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.navyBlue,
    },
    dropDown: {
      width: '220px',
    },
    pickerContainer: {
      width: '220px',
      flex: 4.5,
    },
    pickerLabel: {
      flex: 2,
      fontSize: '16px',
      fontWeight: '600',
      color: Colors.bodyText,
    },
    callToActionLabel: {
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.linkBlue,
      ':hover': {
        fontWeight: '600',
      },
    },
    callToAction: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 3,
    },

    summaryLink: {
      marginTop: '40px',
      display: 'flex',
      alignItems: 'center',
    },
    tableHeader: {
      margin: '40px -21px 20px -9px',
      padding: '10px 0px 10px 8px',
      fontSize: '16px',
      fontWeight: '600',
      color: Colors.mediumGray2,
      display: 'flex',
      backgroundColor: '#f3f6f8',
      alignItems: 'center',
    },
    healthAreaColumn: {
      flex: 2,
      flexDirection: 'column',
      paddingRight: '3%',
    },
    descriptionColumn: {
      flex: 5,
      flexDirection: 'column',
    },
    nextStepsColumn: {
      flex: 4,
      flexDirection: 'column',
    },
    tableContent: {
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      lineHeight: '18px',
      color: Colors.mediumGray4,
      borderBottom: '1px solid #f1eeee',
    },
    recommendationHeader: {
      margin: '40px 0px',
      lineHeight: '20px',
      fontSize: '16px',
    },
    recommendationTitle: {
      fontSize: '16px',
      fontWeight: '600',
      color: Colors.mediumGray2,
    },
    recommendationText: {
      color: Colors.mediumGray4,
    },
    recommendationTableHeader: {
      margin: '40px 0px 20px 0px',
      padding: '10px 0px',
      fontWeight: '600',
      color: Colors.mediumGray2,
      fontSize: '16px',
      display: 'flex',
      backgroundColor: '#f3f6f8',
      alignItems: 'center',
    },
    recommendationTableContent: {
      fontSize: '16px',
      display: 'flex',
      color: Colors.mediumGray4,
      padding: '10px 0px',
      lineHeight: '20px',
      borderBottom: '1px solid #f1eeee',
    },
    recommendationHAColumn: {
      flex: 3,
    },
    recommendationDescColumn: {
      flex: 7,
    },
    healthAction: {
      textAlign: 'center',
      paddingRight: '4%',
      marginLeft: '-10px',
    },
    overallRisk: {
      fontSize: '16px',
      lineHeight: '18px',
      marginBottom: '8px',
    },
    overallRiskDescription: {
      color: 'black',
      fontSize: '16px',
    },
  };

  const { lingo } = AppConfig.effectiveConfig;

  const overallRiskMap = {
    low: {
      text: 'Low Overall Risk',
      color: AppTheme.colors.grannySmith,
      description:
        // eslint-disable-next-line max-len
        'Great job, keep up the good work! Your results show little to no increased risk to your health. Please take a moment to review each area and share the good news with your doctor during your next appointment.',
    },
    moderate: {
      text: 'Moderate Overall Risk',
      color: '#fd7e23',
      description:
        // eslint-disable-next-line max-len
        'Your results show some areas could be affecting your health. Please take a moment to review each area and share with your doctor during your next appointment. Taking simple steps today can lead to a healthier tomorrow.',
    },
    high: {
      text: 'High Overall Risk',
      color: AppTheme.colors.cosmopolitan,
      description:
        // eslint-disable-next-line max-len
        'Your results show five or more areas could be affecting your health. Please take a moment to review each area and share with your doctor during your next appointment. Taking simple steps today can lead to a healthier tomorrow.',
    },
    none: {
      text: 'No Overall Risk',
      color: AppTheme.colors.planet9,
      description:
        // eslint-disable-next-line max-len
        'Great job, keep up the good work! Your results show little to no increased risk to your health. Please take a moment to review each area and share the good news with your doctor during your next appointment.',
    },
  };

  return (
    <Page
      title={`${lingo.healthRiskAssessment} Results`}
      icon={'thin-0378_analytics_presentation_statistics_graph'}
      iconStyle={{ backgroundColor: '#7770ff' }}
    >
      <div style={[GlobalStyles.regular, styles.page]}>
        <div style={styles.titleText}>{lingo.healthRiskAssessment} Summary</div>

        {overallRiskLevel && (
          <>
            <div
              style={{
                ...styles.overallRisk,
                color: overallRiskMap[overallRiskLevel].color,
              }}
            >
              {overallRiskMap[overallRiskLevel].text}
            </div>
            <div style={styles.overallRiskDescription}>
              {overallRiskMap[overallRiskLevel].description}
            </div>
          </>
        )}

        <div style={styles.summaryLink}>
          <div style={styles.pickerLabel}>{lingo.assessment} Date:</div>
          <div style={styles.pickerContainer}>
            <span>{moment(assessmentDate).format('YYYY-MM-DD')}</span>
          </div>
          <div style={styles.callToAction}>
            <RadiumLink to="/hra-result" style={styles.callToActionLabel}>
              View Your Answers
            </RadiumLink>
            <RadiumLink to="/hra" style={styles.callToActionLabel}>
              Retake {lingo.hra}
            </RadiumLink>
          </div>
        </div>

        <div style={styles.tableHeader}>
          <div style={styles.healthAreaColumn}>Health Area</div>
          <div style={styles.descriptionColumn}>Description</div>
          <div style={styles.nextStepsColumn}>Next Steps</div>
        </div>

        {healthRisks.map((healthRisk, healthRiskIndex) => (
          <div key={healthRiskIndex} style={styles.tableContent}>
            <div style={styles.healthAreaColumn}>
              <div style={styles.healthAction}>{healthRisk.name}</div>
              <div style={[styles.healthAction, { color: healthRisk.color }]}>
                {healthRisk.level}
              </div>
            </div>
            <div className="markdown-list" style={styles.descriptionColumn}>
              {healthRisk.answerStatement && (
                <ReactMarkdown source={healthRisk.answerStatement} />
              )}

              {healthRisk.description && (
                <ReactMarkdown source={healthRisk.description} />
              )}
            </div>

            {healthRisk.instructions && (
              <div className="markdown-list" style={styles.nextStepsColumn}>
                <ReactMarkdown source={healthRisk.instructions} />
              </div>
            )}
          </div>
        ))}

        <div style={styles.recommendationHeader}>
          <div style={styles.recommendationTitle}>
            Preventive {lingo.healthAction}s Recommendations
          </div>
          <div style={styles.recommendationText}>
            {`Based on your responses to your ${
              lingo.hra
            }, your age, and your gender, we recommend discussing whether the following
  preventive care services are appropriate for you with your doctor.`}
          </div>
        </div>

        <div style={styles.tableHeader}>
          <div style={styles.recommendationHAColumn}>Health Area</div>
          <div style={styles.recommendationDescColumn}>Recommendation</div>
        </div>

        {recommendations &&
          recommendations.map((recommendation, recommendationIndex) => (
            <div
              key={recommendationIndex}
              style={styles.recommendationTableContent}
            >
              <div style={styles.recommendationHAColumn}>
                {recommendation.healthArea}
              </div>
              <div style={styles.recommendationDescColumn}>
                {recommendation.recommendationText}
              </div>
            </div>
          ))}
      </div>
    </Page>
  );
};

HealthRiskAssessmentSummaryMarkup.propTypes = {
  healthRisks: PropTypes.arrayOf(HealthRisk),
  recommendations: PropTypes.array,
  assessmentDate: PropTypes.string,
  overallRiskLevel: PropTypes.oneOf(['none', 'low', 'moderate', 'high']),
};

export default Radium(HealthRiskAssessmentSummaryMarkup);
