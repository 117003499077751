import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

const LinearScaleMarkup = ({
  options,
  value,
  isDisabled,
  onAnswered,
  styles,
}) => {
  const effectiveStyles = {
    container: {
      display: 'flex',
      ...styles.container,
    },

    scaleContainer: {
      flex: 1,
      position: 'relative',
      marginBottom: '20px',
      paddingTop: '18px',
      paddingBottom: '18px',
      borderLeft: `2px solid ${Colors.lightGray2}`,
      borderRight: `2px solid ${Colors.lightGray2}`,
      ...styles.scaleContainer,
    },

    labelContainer: {
      position: 'absolute',
      top: '-15px',
      left: '0px',
      right: '0px',
      display: 'flex',
      justifyContent: 'space-between',
      ...styles.labelContainer,
    },

    label: {
      width: '28px',
      textAlign: 'center',
      color: Colors.mediumGray,
      ...styles.label,
    },

    scaleLine: {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: Colors.black,
      ...styles.scaleLine,
    },

    bubbleContainer: {
      position: 'absolute',
      top: '5px',
      left: '0px',
      right: '0px',
      display: 'flex',
      justifyContent: 'space-around',
      ...styles.bubbleContainer,
    },

    bubble: {
      width: '16px',
      height: '16px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: Colors.lightGray2,
      borderRadius: '50%',
      padding: '5px',
      textAlign: 'center',
      backgroundColor: Colors.light,
      cursor: isDisabled ? 'default' : 'pointer',
      color: isDisabled ? Colors.lightGray : Colors.mediumGray,
      fontSize: '16px',
      lineHeight: '14px',
      ...styles.bubble,

      ':hover': {
        backgroundColor: isDisabled ? Colors.light : Colors.lightGray2,
        color: isDisabled ? Colors.lightGray : Colors.light,
        ...styles.hoverBubble,
      },
    },

    selectedBubble: {
      borderColor: isDisabled ? Colors.lightBlue : Colors.linkBlue,
      backgroundColor: isDisabled ? Colors.lightBlue : Colors.linkBlue,
      color: Colors.light,
      ...styles.selectedBubble,

      ':hover': {
        backgroundColor: isDisabled ? Colors.lightBlue : Colors.linkBlue,
        color: Colors.light,
        ...styles.hoverSelectedBubble,
      },
    },

    activeBubble: {
      ':active': {
        backgroundColor: isDisabled ? Colors.lightBlue : Colors.linkBlue,
        color: Colors.light,
        ...styles.activeBubble,
      },
    },
  };

  return (
    <StyleRoot style={[GlobalStyles.regular, effectiveStyles.container]}>
      <div style={effectiveStyles.scaleContainer}>
        <div style={effectiveStyles.scaleLine}>
          <div style={effectiveStyles.labelContainer}>
            {options.map(
              (option, optionIndex) =>
                option.label ? (
                  <span
                    key={`Label${optionIndex}`}
                    style={effectiveStyles.label}
                  >
                    {option.label}
                  </span>
                ) : null
            )}
          </div>

          <div style={effectiveStyles.bubbleContainer}>
            <div style={{ display: styles.scaleLine ? 'block' : 'none' }} />
            {options.map((option, optionIndex) => (
              <div
                key={`Bubble${optionIndex}`}
                onClick={() => !isDisabled && onAnswered(option.value)}
                style={[
                  GlobalStyles.light,
                  effectiveStyles.bubble,
                  value && value === option.value
                    ? effectiveStyles.selectedBubble
                    : effectiveStyles.activeBubble,
                ]}
              >
                {option.score}
              </div>
            ))}
            <div style={{ display: styles.scaleLine ? 'block' : 'none' }} />
          </div>
        </div>
      </div>
    </StyleRoot>
  );
};

LinearScaleMarkup.propTypes = {
  isDisabled: PropTypes.bool,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      score: PropTypes.number.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,

  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  onAnswered: PropTypes.func.isRequired,

  styles: PropTypes.shape({
    container: PropTypes.object,
    labelContainer: PropTypes.object,
    label: PropTypes.object,
    scaleContainer: PropTypes.object,
    scaleLine: PropTypes.object,
    bubbleContainer: PropTypes.object,
    bubble: PropTypes.object,
    bubbleValue: PropTypes.object,
    selectedBubble: PropTypes.object,
    selectedBubbleValue: PropTypes.object,
    hoverBubble: PropTypes.object,
    hoverBubbleValue: PropTypes.object,
  }),
};

LinearScaleMarkup.defaultProps = {
  isDisabled: false,
  styles: {},
};

export default Radium(LinearScaleMarkup);
