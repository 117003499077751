import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Radium, { StyleRoot } from 'radium';
import { hashHistory } from 'react-router';
import {
  RegistrationActions,
  SignInActions,
} from '@healthmine/greyhound-core/src/actions';
import {
  ErrorConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import CreateProfileMarkup from '../../components/registration/CreateProfileMarkup';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import autobind from 'autobind-decorator';
import { SessionActions } from '../../actions';
import _ from 'lodash';

class RegistrationCreateProfilePage extends React.Component {
  constructor(props) {
    super(props);
    const day = 'day';

    const month = 'month';

    const year = 'year';
    this.state = {
      isPopUpOpen: false,
      day,
      month,
      year,
      errors: [],
      validatedFields: [],
      registrationIsInProgress: false,
    };
  }

  render() {
    const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);
    const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);

    const newStyles = {
      imgContainer: {},
      bgImg: {},
    };

    const oldStyles = {
      imgContainer: {
        fontSize: '16px',
        overflowY: 'auto',
        position: 'relative',
        color: Colors.light,
        backgroundColor: leapFrogUI ? 'none' : Colors.light,
        overflow: 'hidden',
      },
      bgImg: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
      },
    };

    const styles = fiveFiveUI ? newStyles : oldStyles;

    const { registration } = this.props;

    const { form } = registration;

    return (
      <StyleRoot
        style={[styles.imgContainer, GlobalStyles.regular]}
        className="index"
      >
        <div style={styles.bgImg}>
          <CreateProfileMarkup
            formState={form}
            actions={this.props.actions}
            errors={this.state.errors}
            validatedFields={this.state.validatedFields}
            validateField={this._validateField}
            onClickHandler={this._continueClickHander}
            registrationIsInProgress={this.state.registrationIsInProgress}
          />
        </div>
      </StyleRoot>
    );
  }

  @autobind
  _validateField(field) {
    if (this.state.validatedFields.indexOf(field) === -1)
      this.setState({
        validatedFields: [...this.state.validatedFields, field],
      });
  }

  @autobind
  _formIsValid() {
    const {
      registration: { form },
    } = this.props;
    let formIsValid = true;

    let errors = [];

    if (
      form.emailAddress.value === '' ||
      form.emailAddress.value === undefined
    ) {
      formIsValid = false;
      form.emailAddress.isValid = false;

      this.props.actions.updateEmailAddress(
        form.emailAddress.value,
        localizedContent.errorMessages[ErrorConstants.ErrorType.INVALID_EMAIL]
      );
    }

    if (
      form.emailAddressConfirm.value === '' ||
      form.emailAddressConfirm.value === undefined
    ) {
      formIsValid = false;
      form.emailAddressConfirm.isValid = false;

      this.props.actions.updateEmailAddressConfirm(
        form.emailAddressConfirm.value,
        localizedContent.errorMessages[ErrorConstants.ErrorType.INVALID_EMAIL]
      );
    }

    if (form.emailAddress.value !== form.emailAddressConfirm.value) {
      formIsValid = false;
      errors = [
        ...errors,
        localizedContent.errorMessages[
          ErrorConstants.ErrorType.EMAIL_CONFIRMEMAIL_MISMATCH
        ],
      ];
    }

    if (form.password.value === '' || form.password.value === undefined) {
      formIsValid = false;
      form.password.isValid = false;

      this.props.actions.updatePassword(
        form.password.value,
        localizedContent.errorMessages[
          ErrorConstants.ErrorType.INVALID_PASSWORD_FORMAT
        ]
      );
    }

    if (
      form.passwordConfirm.value === '' ||
      form.passwordConfirm.value === undefined
    ) {
      formIsValid = false;
      form.passwordConfirm.isValid = false;

      this.props.actions.updatePasswordConfirm(
        form.passwordConfirm.value,
        localizedContent.errorMessages[
          ErrorConstants.ErrorType.INVALID_PASSWORD_FORMAT
        ]
      );
    }

    if (form.password.value !== form.passwordConfirm.value) {
      formIsValid = false;
      errors = [
        ...errors,
        localizedContent.errorMessages[
          ErrorConstants.ErrorType.PASSWORD_CONFIRMPASSWORD_MISMATCH
        ],
      ];
    }

    this.setState({ errors });
    return formIsValid;
  }

  @autobind
  _continueClickHander() {
    if (this._formIsValid() === false) {
      return;
    }

    this.setState({ registrationIsInProgress: true });

    this.props.actions.verifyUsernameStatus().then(this._verifyUsernameSuccess);
  }

  @autobind
  _verifyUsernameSuccess() {
    const { verifyUsernameStatus } = this.props.registration;

    if (verifyUsernameStatus.didError) {
      this._setError(verifyUsernameStatus.message);
      this.setState({ registrationIsInProgress: false });
    } else {
      const { postRegistration, postSignIn } = this.props.actions;

      const { emailAddress, password } = this.props.registration.form;

      return postRegistration()
        .then(() => {
          return postSignIn(emailAddress.value, password.value, true);
        })
        .then(this._signInSuccessHandler);
    }
  }

  @autobind
  _setError(error) {
    let errors = [error];

    this.setState({ errors });
  }

  _signInSuccessHandler = (result) => {
    if (result) {
      const { signInResult } = result;
      const { signIn } = this.props;

      if (
        signIn.requiresMfaEnrollment &&
        !signIn.requiresMfa &&
        signIn.supportedMfaTypes.length === 1
      ) {
        const mfaType = _.first(signIn.supportedMfaTypes).toLowerCase();
        hashHistory.push(`signin/mfa-enrollment/${mfaType}`);
      }
      // TODO: this is where a member would be navigated to their MFA options if multiple exist
      // else if (signIn.requiresMfaEnrollment && !signIn.requiresMfa)
      // router.push('signin/mfa-enrollment')
      else if (signIn.requiresMfa) {
        hashHistory.push('signin/verify-mfa');
      } else {
        this.props.actions.startSession(JSON.stringify(signInResult));
        return hashHistory.push('health-overview');
      }
    }
  };
}

RegistrationCreateProfilePage.propTypes = {
  actions: PropTypes.object.isRequired,
  registration: PropTypes.object,
  userInfo: PropTypes.object,
  signIn: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    registration: state.registration,
    userInfo: state.userInfo,
    signIn: state.signIn,
  };
}

function mapDispatchToProps(dispatch) {
  const { startSession } = SessionActions;

  const {
    updateEmailAddress,
    updateEmailAddressConfirm,
    updatePhoneNumber,
    updateCellNumber,
    updateSmsOptIn,
    updatePhoneType,
    updateContactPreference,
    updatePassword,
    updatePasswordConfirm,
    postRegistration,
    verifyUsernameStatus,
  } = RegistrationActions;

  const { postSignIn } = SignInActions;

  return {
    actions: bindActionCreators(
      {
        startSession,
        updateEmailAddress,
        updateEmailAddressConfirm,
        updatePhoneNumber,
        updateCellNumber,
        updateSmsOptIn,
        updatePhoneType,
        updateContactPreference,
        updatePassword,
        updatePasswordConfirm,
        postRegistration,
        postSignIn,
        verifyUsernameStatus,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(RegistrationCreateProfilePage)
);
