import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import MyIDMarkup from './MyIDMarkup';

class MyID extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      firstName,
      lastName,
      dateOfBirth,
      groupNumber,
      memberNumber,
    } = this.props;

    const age = moment().diff(dateOfBirth, 'years');

    return (
      <MyIDMarkup
        name={`${firstName} ${lastName}`}
        age={age}
        groupId={groupNumber}
        subscriberId={memberNumber}
      />
    );
  }
}

MyID.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  groupNumber: PropTypes.string,
  memberNumber: PropTypes.string,
};

function mapStateToProps(state) {
  const {
    firstName,
    lastName,
    dateOfBirth,
    groupNumber,
    memberNumber,
  } = state.userInfo;

  return {
    firstName,
    lastName,
    dateOfBirth,
    groupNumber,
    memberNumber,
  };
}

export default connect(mapStateToProps, null)(MyID);
