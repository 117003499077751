import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules/';
import Measurements from '@healthmine/greyhound-core/src/modules/Measurements';
import Modal from 'react-modal';
import TrackingInput from '../../care-plan-tracking/common/TrackingInput';
import WeeklySummary from '../../care-plan-tracking/common/WeeklySummary';
import TrackingGoal from '../../care-plan-tracking/common/TrackingGoal';

const TrackingModal = ({ type, close }) => {
  const measurement = Measurements.MeasurementTypes[type];

  const styles = {
    modalStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },
      content: {
        minWidth: '300px',
        minHeight: '200px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: '#fff',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        outline: 'none',
        padding: '50px',
      },
    },
    closeButton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },
    modalContent: {
      textAlign: 'center',
      padding: '10px',
      boxSizing: 'border-box',
      flex: '0 0 300px',
      minWidth: '300px',
      height: '250px',
      maxHeight: '250px',
      position: 'relative',
    },
    title: {
      fontSize: '16px',
      margin: '10px 0 20px',
      textAlign: 'center',
    },
  };

  return (
    <Modal isOpen contentLabel={''} style={styles.modalStyles}>
      <a onClick={close} style={styles.closeButton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>
      <div style={styles.modalContent}>
        <div style={styles.title}>{measurement.displayName}</div>
        <TrackingInput type={type} sideEffectOnSubmit={close} />
        <WeeklySummary type={type} />
        <TrackingGoal type={type} />
      </div>
    </Modal>
  );
};

TrackingModal.propTypes = {
  type: PropTypes.string,
  close: PropTypes.func,
};

export default Radium(TrackingModal);
