import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { first } from 'lodash';
import AppointmentDetailMarkup from '../../components/biometric-screening/AppointmentDetailMarkup';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  hasBiometricResults,
  selectAppointmentStatus,
  selectSsoUrl,
} from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';
import { hashHistory } from 'react-router';
import { BiometricScreeningActions } from '@healthmine/greyhound-core/src/actions';
import { bindActionCreators } from 'redux';

class AppointmentDetail extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      showWelcomeBackModal: false,
      showThirdPartyCookieWarning: false,
    };
  }

  render() {
    const { showWelcomeBackModal, showThirdPartyCookieWarning } = this.state;

    return (
      <AppointmentDetailMarkup
        appointmentStatus={this.props.appointmentStatus}
        resultStatus={this.props.resultStatus}
        appointment={this.props.appointment}
        onResultsClick={this._onResultsClick}
        onAppointmentCancel={this._onAppointmentCancel}
        onOpenEHS={this._onOpenEHS}
        showWelcomeBackModal={showWelcomeBackModal}
        onWelcomeBackModalClosed={this._onWelcomeBackModalClosed}
        showThirdPartyCookieWarning={showThirdPartyCookieWarning}
        onThirdPartyCookieWarningClosed={this._onThirdPartyCookieWarningClosed}
      />
    );
  }

  _onResultsClick = () => {
    hashHistory.push('/biometric-screening/results');
  };

  _onAppointmentCancel = () => {
    this.props.actions.cancelAppointment().then(this.props.actions.getEvents());
  };

  _onOpenEHS = () => {
    const { ssoUrl, thirdPartyCookiesUnsupported } = this.props;

    if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)) {
      this.setState({
        showWelcomeBackModal: true,
      });
    } else if (thirdPartyCookiesUnsupported) {
      this.setState({
        showThirdPartyCookieWarning: true,
      });
    } else {
      window.open(ssoUrl);

      this.setState({
        showWelcomeBackModal: true,
      });
    }
  };

  _onThirdPartyCookieWarningClosed = () => {
    this.setState({
      showThirdPartyCookieWarning: false,
    });
  };

  _onWelcomeBackModalClosed = () => {
    this.props.actions.getEvents();
    this.setState({
      showWelcomeBackModal: false,
    });
  };
}

AppointmentDetail.propTypes = {
  ssoUrl: PropTypes.string.isRequired,
  thirdPartyCookiesUnsupported: PropTypes.bool.isRequired,
  events: PropTypes.array,
  resultStatus: PropTypes.bool,
  appointmentStatus: PropTypes.string.isRequired,
  appointment: PropTypes.object,

  actions: PropTypes.shape({
    cancelAppointment: PropTypes.func.isRequired,
    getEvents: PropTypes.func.isRequired,
  }),
};

function mapStateToProps(state) {
  const ssoUrl = selectSsoUrl(state);

  const { detected, supported } = state.thirdPartyCookieSupport;

  const thirdPartyCookiesUnsupported = !!(detected && !supported);

  const { events, appointment } = state.biometricScreening;

  const resultStatus = hasBiometricResults(state);

  const appointmentEvent =
    (appointment &&
      events &&
      first(events.filter((event) => event.eventId === appointment.eventId))) ||
    {};

  return {
    ssoUrl,
    events,
    appointmentStatus: selectAppointmentStatus(state),
    resultStatus,
    thirdPartyCookiesUnsupported,
    appointment: appointment
      ? { ...appointment, event: appointmentEvent }
      : undefined,
  };
}

function mapDispatchToProps(dispatch) {
  const { cancelAppointment, getEvents } = BiometricScreeningActions;

  const actionMap = {
    actions: bindActionCreators({ cancelAppointment, getEvents }, dispatch),
  };

  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetail);
