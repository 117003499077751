import React from 'react';
import PropTypes from 'prop-types';
import {
  GlobalStyles,
  Colors,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import Radium from 'radium';
import ShrinkingIcon from '../common/ShrinkingIcon';

const lingo = AppConfig.effectiveConfig.lingo;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleContainer: {
    fontSize: '36px',
    ...GlobalStyles.extraLight,
  },
  textContainer: {
    ...GlobalStyles.regular,
    fontSize: '18px',
    maxWidth: '475px',
    textAlign: 'center',
    marginTop: '20px',
  },
  continueContainer: {
    ...GlobalStyles.semiBold,
    color: '#2196f3',
    fontSize: '24px',
    marginTop: '20px',
    cursor: 'pointer',
  },
};

const onBoardingStatement = () => {
  return { __html: lingo.onboardingProgramWelcomHtml };
};

const HelloUserMarkup = ({ onContinueClick, firstName }) => {
  return (
    <div style={styles.container}>
      <ShrinkingIcon
        style={{ marginTop: '20px' }}
        keyFramesName={'shrinkingIcon'}
        iconColor={Colors.white}
        iconBackgroundColor={'#2196f3'}
        iconClassName={'thin-0704_users_profile_group_couple_man_woman'}
        fixedHeight={190}
        duration={'.7s'}
        finalIconSize={90}
        easeFunction={'linear'}
      />
      <div style={styles.titleContainer}>
        Hi there,{' '}
        {firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()}.
      </div>
      <div
        style={styles.textContainer}
        //eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={onBoardingStatement()}
      />
      <div style={styles.continueContainer} onClick={onContinueClick}>
        Continue
      </div>
    </div>
  );
};

HelloUserMarkup.propTypes = {
  onContinueClick: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
};

export default Radium(HelloUserMarkup);
