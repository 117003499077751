import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';

const SecondaryMenusMarkup = ({ children }) => {
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0px 0px 0px auto',
      boxSizing: 'border-box',
    },
  };

  return <div style={styles.root}>{children}</div>;
};

SecondaryMenusMarkup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Radium(SecondaryMenusMarkup);
