import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const styles = {
  root: {
    minHeight: '75px',
    padding: '12px',
    display: 'flex',
    fontSize: '16px',
    lineHeight: '17px',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
  even: {
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
  title: {
    fontWeight: '500',
    width: '200px',
    paddingRight: '24px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  description: {
    width: '400px',
    flexGrow: '1',
    paddingRight: '24px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  incentiveCTAWrapper: {
    fontSize: '16px',
    fontWeight: '500',
    width: '150px',
    overflow: 'hidden',
  },
  viewEducation: {
    color: '#1e80f0',
    cursor: 'pointer',
  },
};

const EducationAction = ({
  title,
  description,
  incentiveAmount,
  isEven,
  viewEducation,
}) => {
  return (
    <div style={[styles.root, isEven && styles.even]}>
      <div style={styles.title}>{title}</div>
      <div style={styles.description}>{description}</div>
      <div style={styles.incentiveCTAWrapper}>
        <div>Earn {incentiveAmount} points</div>
        <a style={styles.viewEducation} onClick={viewEducation}>
          View
        </a>
      </div>
    </div>
  );
};

EducationAction.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  incentiveAmount: PropTypes.number,
  url: PropTypes.string,
  isEven: PropTypes.bool,
  viewEducation: PropTypes.func,
};

export default Radium(EducationAction);
