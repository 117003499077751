import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import {
  RewardsConstants,
  AppConstants,
} from '@healthmine/greyhound-core/src/constants';
import moment from 'moment';
import PageLinks from '../../common/PageLinks';
import Page from '../../common/Page';
import _ from 'lodash';

const SummaryMarkup = ({
  summary,
  currPage,
  pages,
  total,
  compliancePeriods,
  selectedStrategyId,
  onSelection,
}) => {
  const styles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '75px',
      paddingRight: '75px',
    },

    header: {
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.black,
      paddingBottom: '20px',
    },

    table: {
      textAlign: 'left',
      fontSize: '16px',
      width: '100%',
      borderCollapse: 'collapse',
    },

    th: {
      padding: '10px 0',
      color: Colors.mediumGray2,
      backgroundColor: '#f3f6f8',
      fontSize: '16px',
      textAlign: 'center',
    },

    td: {
      padding: '20px 0',
      color: Colors.contentText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      textAlign: 'center',
    },

    firstColumn: {
      textAlign: 'left',
      padding: '20px 0 20px 10px',
    },

    noContent: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '20px',
      fontWeight: 300,
      paddingLeft: '75px',
      paddingRight: '75px',
    },

    detailsLink: {
      color: Colors.linkBlue,
      textDecoration: 'none',
      textAlign: 'center',
      ':hover': {
        color: Colors.hexToRgba(Colors.dark, 0.5),
      },
      ':active': {
        color: Colors.dark,
      },
    },
    pageLinksWrapper: {
      padding: '30px 0',
    },
    sortLabel: {
      paddingRight: '15px',
      fontSize: '16px',
      color: Colors.mediumGray2,
    },
    select: {
      display: 'inline-block',
      minWidth: '120px',
      fontSize: '16px',
      height: '34px',
      color: Colors.lightGray3,
      borderRadius: '5px',
      paddingLeft: '5px',
    },
    subContent: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px 75px 25px',
      alignItems: 'center',
    },

    captionLabel: {
      color: Colors.mediumGray2,
      fontSize: '16px',
    },
    icon: {
      backgroundColor: '#a4a4a4',
    },
  };

  const { incentiveType } = AppConfig.effectiveConfig;

  const { setCurrencyLabel } = RewardsConstants;

  const isReward = incentiveType === AppConstants.IncentiveType.REWARDS;

  const incentiveText = isReward ? 'Rewards' : 'Points';

  const summaryUI =
    summary && summary.length ? (
      <div style={styles.content}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th
                style={[GlobalStyles.semiBold, styles.th, styles.firstColumn]}
                width="36%"
              >
                Action
              </th>
              <th style={[GlobalStyles.semiBold, styles.th]} width="24%">
                Last Completion Date
              </th>
              <th style={[GlobalStyles.semiBold, styles.th]} width="16%">
                Expiration Date
              </th>
              <th style={[GlobalStyles.semiBold, styles.th]} width="24%">
                Total {incentiveText} Earned
              </th>
            </tr>
          </thead>
          <tbody>
            {summary.map((item, index) => {
              return (
                <tr key={index}>
                  <td style={[styles.td, styles.firstColumn]}>
                    {item.dataType === 'MODULE_DATA' ||
                    item.dataType === 'PLAN_DATA'
                      ? item.name
                      : item.description}
                  </td>
                  <td style={styles.td}>
                    {moment(
                      item.lastCompletedDate || item.lastIssuedDate
                    ).format('MM/DD/YYYY')}
                  </td>
                  <td style={styles.td}>
                    {moment(item.endDate).format('MM/DD/YYYY')}
                  </td>
                  <td style={styles.td}>{setCurrencyLabel(item.amount)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={styles.pageLinksWrapper}>
          <PageLinks
            linkBase={isReward ? '/rewards/summary/' : '/sweepstakes/summary/'}
            currPage={currPage}
            pages={pages}
          />
        </div>
      </div>
    ) : (
      <div style={styles.noContent}>No Summary Available</div>
    );

  return (
    <Page
      title={`${incentiveText} Summary`}
      icon={'thin-0670_diamond_prize_award_jewellery_ring'}
      iconStyle={styles.icon}
    >
      <div style={styles.subContent}>
        <div style={[GlobalStyles.semiBold, styles.captionLabel]}>
          Total {incentiveText} Earned:{' '}
          {isReward
            ? setCurrencyLabel(total)
            : total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </div>
        <div>
          <span style={[GlobalStyles.semiBold, styles.sortLabel]}>
            Plan Year:
          </span>
          <select
            style={styles.select}
            value={selectedStrategyId}
            onChange={onSelection}
          >
            {_.map(compliancePeriods, (period, i) => {
              return (
                <option key={i} value={period.employerIncentiveStrategyId}>
                  {`${moment(period.startDate).format('MM/DD/YYYY')} - ${moment(
                    period.endDate
                  ).format('MM/DD/YYYY')}`}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {summaryUI}
    </Page>
  );
};

SummaryMarkup.propTypes = {
  summary: PropTypes.array.isRequired,
  currPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  total: PropTypes.number,
  options: PropTypes.array,
  compliancePeriods: PropTypes.array,
  selectedStrategyId: PropTypes.number,
  onSelection: PropTypes.func,
};

export default Radium(SummaryMarkup);
