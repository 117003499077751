import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import HeroMarkup from './Hero.markup';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  Features,
  AppConstants,
} from '@healthmine/greyhound-core/src/constants';
import _ from 'lodash';

function mapStateToProps(state) {
  const { firstName } = state.userInfo;

  const biometricHealthAction = _.find(
    state.carePlanGoals.subjects.PREVENTIVE.clinical.actions,
    (action) =>
      action.healthActionCode ===
      AppConstants.HealthActions.COMPLETE_BIOMETRIC_TESTING
  );

  const hasBiometricScreening =
    AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS) &&
    !_.isUndefined(
      _.get(state.myClinicalActions, biometricHealthAction.healthActionId)
    );

  return {
    firstName,
    hasBiometricScreening,
  };
}

export default compose(pure, connect(mapStateToProps))(HeroMarkup);
