import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import moment from 'moment';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import Modal from 'react-modal';
import BottomNavButtons from '../../components/common/BottomNavButtons';
import FloatingLabel from '../common/FloatingLabel';
import InsuranceCardInfoPopup from '../common/InsuranceCardInfoPopup';
import DateComponent from '../common/question/Date';
import { Features } from '@healthmine/greyhound-core/src/constants';

const BasicInfoMarkup = ({
  formState,
  actions,
  onDOBChange,
  errors,
  onClickHandler,
  isPopUpOpen,
  onPopUpChange,
  validateField,
  validatedFields,
}) => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);
  const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);

  const newStyles = {
    mainContainer: {
      fontSize: '16px',
      display: 'flex',
      justifyContent: 'center',
    },

    card: {
      background: '#fff',
      padding: '40px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 6px 0px',
      marginBottom: '24px',
    },

    inputWrapper: {
      clear: 'both',
      marginTop: '10px',
    },

    bottomLinks: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '60px',
      marginBottom: '150px',
    },

    inputContainer: {
      float: 'left',
      marginRight: '10px',
      marginTop: '10px',
      marginBottom: '10px',
      position: 'relative',
    },

    floatRight: {
      color: '#dadfe4',
      width: '330px',
      textAlign: 'right',
      marginTop: '-15px',

      '@media screen and (max-width: 480px)': {
        width: '240px',
      },
    },

    link: {
      color: Colors.linkBlue,
      fontSize: '16px',
      position: 'relative',
      lineHeight: '20px',
    },

    dateWrapper: {
      marginBottom: '20px',
      color: Colors.lightGray3,
    },

    mainHeader: {
      fontSize: '24px',
      margin: '0 0 5px',
      color: Colors.black,
    },

    subHeaderText: {
      margin: '0 0 20px',
      color: Colors.black,
      maxWidth: '520px',
      fontSize: '16px',
    },

    imageSpacing: {
      marginRight: '20px',
      marginTop: '20px',
    },

    iconImage: {
      fontSize: '35px',
      color: Colors.white,
      backgroundColor: Colors.bodyText,
      borderRadius: '50%',
      padding: '20px',
    },

    title: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '30px',
    },

    formContainer: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
    },

    dobLabel: {
      fontSize: '16px',
      marginTop: '20px',
    },

    dateComponent: {
      container: {
        marginTop: '10px',
      },

      day: {
        width: '120px',

        '@media screen and (max-width: 480px)': {
          width: '95px',
        },
      },

      month: {
        width: '90px',

        '@media screen and (max-width: 480px)': {
          width: '60px',
        },
      },

      year: {
        width: '100px',

        '@media screen and (max-width: 480px)': {
          width: '65px',
        },
      },
    },

    helpText: {
      color: 'red',
      fontSize: '16px',
      display: 'block',
      position: 'absolute',
      top: '45px',
    },

    modalContainer: {
      position: 'relative',
    },

    modalContent: {
      position: 'absolute',
      left: '421px',
      top: '85px',
      fontSize: '20px',
      width: '238px',
    },

    customStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
      },

      content: {
        position: 'absolute',
        top: '200px',
        left: '320px',
        right: '320px',
        bottom: '200px',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
      },
    },

    closebutton: {
      float: 'right',
      marginTop: '-15px',
      marginRight: '-15px',
      cursor: 'pointer',
      color: '#fff',
      border: '1px solid #AEAEAE',
      borderRadius: '30px',
      background: '#605F61',
      fontSize: '20px',
      fontWeight: 'bold',
      display: 'inline-block',
      lineHeight: '0px',
      padding: '11px 3px',
    },

    errorMessageWrapper: {
      color: 'red',
      fontWeight: '600',
      fontSize: '16px',
      marginTop: '40px',
    },

    errorMessage: {},
  };

  const oldStyles = {
    mainContainer: {
      width: leapFrogUI ? 'initial' : '1000px',
      maxWidth: '100%',
      color: Colors.darkText,
      backgroundColor: Colors.light,
      padding: leapFrogUI ? '30px' : '10px 10px 10px 200px',
      marginTop: '10px',
      boxSizing: leapFrogUI ? 'initial' : 'border-box',
      borderRadius: leapFrogUI ? '6px' : '0',
      border: leapFrogUI ? `1px solid ${Colors.mediumGray3}` : 'none',

      '@media screen and (max-width: 760px) and (min-width: 481px)': {
        padding: '10px 10px 10px 100px',
      },

      '@media screen and (max-width: 480px)': {
        padding: '10px 40px',
      },
    },

    inputWrapper: {
      clear: 'both',
      marginTop: '10px',
    },

    bottomLinks: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '60px',
      marginBottom: '110px',
    },

    inputContainer: {
      float: 'left',
      marginRight: '10px',
      marginTop: '10px',
      marginBottom: '10px',
      position: 'relative',
    },

    floatRight: {
      color: '#dadfe4',
      width: '330px',
      textAlign: 'right',
      marginTop: '-15px',

      '@media screen and (max-width: 480px)': {
        width: '240px',
      },
    },

    link: {
      color: Colors.linkBlue,
      fontSize: '16px',
      position: 'relative',
      lineHeight: '20px',
    },

    dateWrapper: {
      marginBottom: '20px',
      color: Colors.lightGray3,
    },

    mainHeader: {
      fontSize: '24px',
      margin: '0 0 5px',
      color: Colors.black,
    },

    subHeaderText: {
      margin: '0 0 20px',
      color: Colors.black,
      maxWidth: '520px',
      fontSize: '16px',
    },

    imageSpacing: {
      marginRight: '20px',
      marginTop: '20px',
    },

    iconImage: {
      fontSize: '35px',
      color: Colors.white,
      backgroundColor: Colors.bodyText,
      borderRadius: '50%',
      padding: '20px',
    },

    title: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '30px',
    },

    formContainer: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
    },

    dobLabel: {
      fontSize: '16px',
      marginTop: '20px',
    },

    dateComponent: {
      container: {
        marginTop: '10px',
      },

      day: {
        width: '120px',

        '@media screen and (max-width: 480px)': {
          width: '95px',
        },
      },

      month: {
        width: '90px',

        '@media screen and (max-width: 480px)': {
          width: '60px',
        },
      },

      year: {
        width: '100px',

        '@media screen and (max-width: 480px)': {
          width: '65px',
        },
      },
    },

    helpText: {
      color: 'red',
      fontSize: '16px',
      display: 'block',
      position: 'absolute',
      top: '45px',
    },

    modalContainer: {
      position: 'relative',
    },

    modalContent: {
      position: 'absolute',
      left: '421px',
      top: '85px',
      fontSize: '20px',
      width: '238px',
    },

    customStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
      },

      content: {
        position: 'absolute',
        top: '200px',
        left: '320px',
        right: '320px',
        bottom: '200px',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
      },
    },

    closebutton: {
      float: 'right',
      marginTop: '-15px',
      marginRight: '-15px',
      cursor: 'pointer',
      color: '#fff',
      border: '1px solid #AEAEAE',
      borderRadius: '30px',
      background: '#605F61',
      fontSize: '20px',
      fontWeight: 'bold',
      display: 'inline-block',
      lineHeight: '0px',
      padding: '11px 3px',
    },

    errorMessageWrapper: {
      color: 'red',
      fontWeight: '600',
      fontSize: '16px',
      marginTop: '40px',
    },

    errorMessage: {},
  };

  const styles = fiveFiveUI ? newStyles : oldStyles;

  const { lastName, dateOfBirth, memberNumber, firstName } = formState;

  const formIsValid =
    lastName.isValid &&
    dateOfBirth.isValid &&
    memberNumber.isValid &&
    firstName.isValid;

  const updateLastName = (event) => actions.updateLastName(event.target.value);

  const validateLastName = () => validateField('lastName');

  const updateFirstName = (event) =>
    actions.updateFirstName(event.target.value);

  const validateFirstName = () => validateField('firstName');

  const updateMemberNumber = (event) =>
    actions.updateMemberNumber(event.target.value);

  const validateMemberNumber = () => validateField('memberNumber');

  const insuranceCardExplanationHtml = AppConfig.getLingo(
    'insuranceCardExplanationHtml'
  );

  return (
    <div style={styles.mainContainer}>
      <Modal contentLabel={''} isOpen={isPopUpOpen} style={styles.customStyles}>
        <a onClick={onPopUpChange} style={styles.closebutton} id="boxclose">
          X
        </a>
        <div style={styles.modalContainer}>
          <div>
            <img src="https://www.bcbsnc.com/assets/members/public/images/idcard-sid-new.png" />
          </div>
        </div>
        <div style={styles.modalContent}>
          <div>Your member ID is found on the back of your card.</div>
        </div>
      </Modal>
      <div style={styles.card}>
        <div style={styles.title}>
          <span style={styles.imageSpacing}>
            <i className="thin-0632_security_lock" style={styles.iconImage} />
          </span>
          <span>
            <h2 style={[GlobalStyles.extraLight, styles.mainHeader]}>
              Verify Your Account
            </h2>
            <h3 style={[GlobalStyles.regular, styles.subHeaderText]}>
              Enter your information. All items are kept secure and
              confidential.
            </h3>
          </span>
        </div>
        <div style={styles.formContainer}>
          <div
            style={
              firstName.isValid !== false
                ? styles.inputContainer
                : [styles.inputContainer, { marginBottom: '20px' }]
            }
          >
            <FloatingLabel
              onChange={updateFirstName}
              placeHolder={'First Name'}
              value={firstName.value}
              isValid={
                validatedFields.indexOf('firstName') > -1
                  ? firstName.isValid
                  : null
              }
              onBlur={validateFirstName}
            />
            {firstName.isValid == false &&
            validatedFields.indexOf('firstName') > -1 ? (
              <span style={styles.helpText}>
                First name should not be empty.
              </span>
            ) : null}
          </div>
          <div
            style={
              lastName.isValid !== false
                ? styles.inputContainer
                : [styles.inputContainer, { marginBottom: '20px' }]
            }
          >
            <FloatingLabel
              onChange={updateLastName}
              placeHolder={'Last Name'}
              value={lastName.value}
              isValid={
                validatedFields.indexOf('lastName') > -1
                  ? lastName.isValid
                  : null
              }
              onBlur={validateLastName}
            />
            {lastName.isValid == false &&
            validatedFields.indexOf('lastName') > -1 ? (
              <span style={styles.helpText}>
                Last name should not be empty.
              </span>
            ) : null}
          </div>
          <div>
            <div style={[styles.inputContainer, styles.dateWrapper]}>
              <label style={[GlobalStyles.regular, styles.dobLabel]}>
                Date of Birth
              </label>
              <DateComponent
                minDate={moment().subtract(120, 'years')}
                maxDate={moment().subtract(18, 'years')}
                value={dateOfBirth.value}
                validationError={`Please select date between ${moment()
                  .subtract(120, 'years')
                  .format('MM-DD-YYYY')} and
                  ${moment()
                    .subtract(18, 'years')
                    .format('MM-DD-YYYY')}`}
                fullMonth
                styles={styles.dateComponent}
                onAnswered={(date) => onDOBChange(date)}
              />
            </div>
          </div>
          <div style={styles.inputWrapper}>
            <div
              style={
                memberNumber.isValid !== false
                  ? styles.inputContainer
                  : [styles.inputContainer, { marginBottom: '20px' }]
              }
            >
              <FloatingLabel
                onChange={updateMemberNumber}
                placeHolder={'Member ID'}
                value={memberNumber.value}
                isValid={
                  validatedFields.indexOf('memberNumber') > -1
                    ? memberNumber.isValid
                    : null
                }
                onBlur={validateMemberNumber}
              />
              {memberNumber.isValid == false ? (
                <span style={styles.helpText}>
                  {validatedFields.indexOf('memberNumber') > -1 &&
                    (memberNumber.error
                      ? memberNumber.error
                      : 'Member ID should not be empty.')}
                </span>
              ) : null}
            </div>
          </div>
          <div style={styles.floatRight}>
            <span style={[styles.link, GlobalStyles.regular]}>
              Where is my member ID?
              <InsuranceCardInfoPopup
                explanationHtml={insuranceCardExplanationHtml}
              />
            </span>
          </div>

          {errors.length > 0 && (
            <div style={styles.errorMessageWrapper}>
              {errors.map((val, index) => {
                return (
                  <p
                    key={index}
                    style={[GlobalStyles.regular, styles.errorMessage]}
                  >
                    {val}
                  </p>
                );
              })}
            </div>
          )}

          <div style={styles.bottomLinks}>
            <BottomNavButtons
              backLink="/"
              onClickForw={onClickHandler}
              disabled={!formIsValid}
              forwText={'Verify'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BasicInfoMarkup.propTypes = {
  formState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  validatedFields: PropTypes.array,
  onClickHandler: PropTypes.func,
  onDOBChange: PropTypes.func,
  onPopUpChange: PropTypes.func,
  validateField: PropTypes.func,
  isPopUpOpen: PropTypes.bool,
};

export default Radium(BasicInfoMarkup);
