import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { WithCoverageSelection } from '@healthmine/greyhound-core/src/hocs';
import SettingsCoverageMarkup from './SettingsCoverageMarkup';

export default compose(
  withRouter,

  withHandlers({
    onSelectionCommitted: ({ router }) => () => router.goBack(),
  }),

  WithCoverageSelection
)(SettingsCoverageMarkup);
