import { compose, pure, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import HeroCardMarkup from './HeroCard.markup';
import { CompletionStatus } from '@healthmine/greyhound-core/src/constants/CarePlan';
import { selectBiometricStatus } from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';
import { withRouter } from 'react-router';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import _ from 'lodash';
import moment from 'moment';

const biometricRoute = (biometricStatus) => {
  const baseRoute = '/biometric-screening';
  if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO))
    return `${baseRoute}/events`;

  return biometricStatus === CompletionStatus.STARTED
    ? `${baseRoute}/appointment`
    : biometricStatus === CompletionStatus.COMPLETED
      ? `${baseRoute}/results`
      : baseRoute;
};

export default compose(
  pure,
  withRouter,
  connect((state) => {
    const biometricStatus = selectBiometricStatus(state);

    const preventiveClinicalActions = _.get(
      state,
      'carePlanGoals.subjects.PREVENTIVE.clinical.actions',
      []
    );

    const biometricAction = _.find(
      preventiveClinicalActions,
      {
        healthActionCode: AppConstants.HealthActions.COMPLETE_BIOMETRIC_TESTING,
      },
      {}
    );

    const myAction = _.get(
      state,
      ['myClinicalActions', biometricAction.healthActionId],
      {}
    );

    const completions = _.get(myAction, 'completions', []);

    const lastCompleted = _.get(
      _.head(_.orderBy(completions, 'completionDate', 'desc')),
      'completionDate',
      undefined
    );
    return {
      biometricAction,
      biometricStatus,
      lastCompleted,
    };
  }),
  withProps(({ biometricAction, biometricStatus, lastCompleted }) => {
    const { incentiveType, incentiveCurrency } = AppConfig.effectiveConfig;

    const incentiveAmount = _.get(biometricAction, 'incentiveAmount');

    const isBiometricComplete = biometricStatus === CompletionStatus.COMPLETED;

    const title = isBiometricComplete
      ? 'View Biometric Screening Results'
      : 'Schedule Biometric Screening';

    const completedText =
      isBiometricComplete && lastCompleted
        ? `Completed on ${moment(lastCompleted).format('MM/DD/YYYY')}`
        : '';

    const incentiveText = isBiometricComplete
      ? incentiveType === AppConstants.IncentiveType.NONE
        ? ''
        : incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS
          ? `You earned $${incentiveAmount}!`
          : `You earned ${incentiveAmount} points!`
      : incentiveType === AppConstants.IncentiveType.NONE
        ? ''
        : incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS
          ? `Earn $${incentiveAmount}`
          : `Earn ${incentiveAmount} points`;
    return {
      iconClass: 'thin-0637_fingerprint_scan_security_access',
      title,
      completedText,
      incentiveText,
      actionComplete: isBiometricComplete,
    };
  }),
  withHandlers(() => ({
    onCardClick: ({ router, biometricStatus }) => () =>
      router.push(biometricRoute(biometricStatus)),
  }))
)(HeroCardMarkup);
