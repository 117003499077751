import React, { useReducer, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListHeader from './ListHeader';
import ListFooter from './ListFooter';
import ListContent from './ListContent';
import ListItem from './ListItem';
import ListItemStyles from './ListItem.styles';
import EmptyList from './EmpytList';

const styles = () => ({
  root: {
    marginBottom: '32px',
  },
});

const ListContext = createContext();

const listReducer = (state, action) => {
  switch (action.type) {
    case 'toggle':
      return {
        ...state,
        isOpen: !state.isOpen,
      };

    case 'disable':
      return {
        ...state,
        disabled: action.payload,
      };

    default:
      return state;
  }
};

const List = ({ children, classes, ...props }) => {
  const [state, dispatch] = useReducer(listReducer, {
    isOpen: true,
    disabled: false,
    ...props,
  });

  useEffect(
    () => {
      dispatch({ type: 'disable', payload: props.disabled });
    },
    [props.disabled]
  );

  return (
    <ListContext.Provider value={{ state, dispatch }}>
      <div className={classes.root}>{children}</div>
    </ListContext.Provider>
  );
};

List.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
};

export default withStyles(styles)(List);

export {
  ListContent,
  ListContext,
  ListHeader,
  ListFooter,
  ListItem,
  ListItemStyles,
  EmptyList,
};
