import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import moment from 'moment';
import PageLinks from '../common/PageLinks';
import Page from '../common/Page';

const PrescriptionsMarkup = ({
  prescriptionsHistory,
  currPage,
  pages,
  sortBy,
  sortHistory,
}) => {
  const styles = {
    root: {
      padding: '0 68px 80px',
    },
    container: {
      display: 'flex',
      padding: '40px 0',
      alignItems: 'center',
    },
    marginIcon: {
      width: '40px',
      height: '40px',
      fontSize: '40px',
      padding: '18px',
      color: Colors.light,
      backgroundColor: '#3c4daa',
      borderRadius: '50%',
      marginRight: '15px',
    },
    pageTitle: {
      color: Colors.bodyText,
      fontSize: '28px',
      position: 'relative',
    },
    subTitle: {
      position: 'absolute',
      top: '-15px',
      fontSize: '16px',
      fontWeight: 500,
    },
    header: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.bodyText,
      paddingBottom: '20px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      marginBottom: '30px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    selectWrapper: {
      padding: '0 0 30px 0',
    },
    sortLabel: {
      color: Colors.darkGray,
      fontSize: '16px',
      fontWeight: 700,
      marginRight: '20px',
    },
    select: {
      display: 'inline-block',
      minWidth: '80px',
      fontSize: '16px',
      height: '34px',
      borderRadius: '5px',
      paddingLeft: '5px',
    },
    table: {
      textAlign: 'left',
      fontSize: '16px',
      width: '100%',
      borderCollapse: 'collapse',
    },
    th: {
      padding: '10px 0',
      color: Colors.mediumGray2,
      backgroundColor: '#f3f6f8',
      fontSize: '16px',
      textAlign: 'center',
    },
    td: {
      padding: '20px 0',
      color: Colors.contentText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      textAlign: 'center',
    },
    firstColumn: {
      textAlign: 'left',
      paddingLeft: '10px',
    },
    noHistory: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '30px',
      fontWeight: 200,
    },
    icon: {
      fontSize: '90px',
      padding: '30px',
    },
    pageLinksWrapper: {
      padding: '30px 0',
    },
  };

  return (
    <Page
      title={'Prescriptions'}
      icon={'thin-0820_medicine_drugs_ill_pill'}
      iconStyle={{ backgroundColor: '#3c4daa' }}
    >
      <div style={[styles.root, GlobalStyles.light]}>
        <div style={styles.header}>Prescription History</div>

        {prescriptionsHistory && prescriptionsHistory.length ? (
          <div style={styles.content}>
            <div style={styles.selectWrapper}>
              <span style={styles.sortLabel}>Sort By:</span>
              <select
                style={styles.select}
                value={sortBy}
                onChange={sortHistory}
              >
                <option value="name">Name</option>
                <option value="startDate">Filled On</option>
              </select>
            </div>

            <table style={styles.table}>
              <thead>
                <tr>
                  <th
                    style={[GlobalStyles.bold, styles.th, styles.firstColumn]}
                  >
                    Name
                  </th>
                  <th style={[GlobalStyles.bold, styles.th]}>Filled On</th>
                  <th style={[GlobalStyles.bold, styles.th]}>Days Supply</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionsHistory.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={[styles.td, styles.firstColumn]}>
                        {item.name}
                      </td>
                      <td style={styles.td}>
                        {moment(item.startDate).format('MM/DD/YYYY')}
                      </td>
                      <td style={styles.td}>{item.daysSupply}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div style={styles.pageLinksWrapper}>
              <PageLinks
                linkBase={'/prescriptions/'}
                currPage={currPage}
                pages={pages}
              />
            </div>
          </div>
        ) : (
          <div style={styles.noHistory}>
            No History Available
            <i
              style={styles.icon}
              className="thin-0820_medicine_drugs_ill_pill"
            />
          </div>
        )}
      </div>
    </Page>
  );
};

PrescriptionsMarkup.propTypes = {
  prescriptionsHistory: PropTypes.array.isRequired,
  currPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortHistory: PropTypes.func.isRequired,
};

export default Radium(PrescriptionsMarkup);
