import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import List, { ListHeader, ListContent } from '../../../../common/list/List';
import EducationAction from './actions/EducationAction';

const EducationList = ({
  remainingRecommendedEducation,
  title,
  iconClass,
  color,
  remainingRecommendedEducationCount,
  openDrawer,
  maxDisplayCount,
}) => {
  return (
    <List>
      <ListHeader
        iconClass={iconClass}
        color={color}
        title={title}
        remainingCount={remainingRecommendedEducationCount}
        maxDisplayCount={maxDisplayCount}
        remainingCountNote={`action${
          remainingRecommendedEducationCount !== 1 ? 's' : ''
        } remaining`}
        isMute={remainingRecommendedEducationCount < 1}
      />
      <ListContent>
        {_.map(remainingRecommendedEducation, (education) => {
          return (
            <EducationAction
              key={`education_${education.contentId}`}
              contentId={education.contentId}
              title={education.title}
              incentiveAmount={education.rewardAmount}
              openDrawer={openDrawer}
            />
          );
        })}
      </ListContent>
    </List>
  );
};

EducationList.propTypes = {
  remainingRecommendedEducation: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  remainingRecommendedEducationCount: PropTypes.number.isRequired,
  openDrawer: PropTypes.func,
  maxDisplayCount: PropTypes.number.isRequired,
};

export default EducationList;
