import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    marginTop: '20px',
  },
});

const ListContent = ({ classes, children }) => (
  <Grid
    container
    justify="space-between"
    alignItems="center"
    className={classes.root}
  >
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);

ListContent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default compose(pure, withStyles(styles))(ListContent);
