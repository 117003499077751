import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import HeaderMarkup from './HeaderMarkup';

class Header extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      modalOpen: false,
      activeAccordian: _.get(this, 'props.carePlan.checkpoint', false)
        ? 'points'
        : '',
    };
  }

  render = () => {
    const {
      carePlan,
      moduleCompletionStatus,
      goalStatement,
      primaryTracking,
    } = this.props;

    const { modalOpen, activeAccordian } = this.state;

    return (
      <HeaderMarkup
        carePlan={carePlan}
        moduleCompletionStatus={moduleCompletionStatus}
        goalStatement={goalStatement}
        primaryTracking={primaryTracking}
        modalOpen={modalOpen}
        toggleModal={this._toggleModal}
        activeAccordian={activeAccordian}
        setActiveAccordian={this._setActiveAccordian}
      />
    );
  };

  _toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  _setActiveAccordian = (type) => {
    this.setState({
      activeAccordian: this.state.activeAccordian !== type ? type : '',
    });
  };
}

Header.propTypes = {
  carePlan: PropTypes.object.isRequired,
  moduleCompletionStatus: PropTypes.object,
  goalStatement: PropTypes.object,
  primaryTracking: PropTypes.object,
};

export default Header;
