import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import InfoPopup from '../../common/InfoPopup';

const RecurringEducationModuleMarkup = ({ name }) => {
  const styles = {
    directive: {
      color: Colors.dark,
      margin: '0',
      fontSize: '16px',
    },
    info: {
      height: '24px',
      verticalAlign: 'middle',
      marginLeft: '15px',
      cursor: 'pointer',
    },
    infoPopup: {
      popupContainer: {
        left: '26px',
        right: 'initial',
      },
      popupContent: {
        borderRadius: '0 10px 10px 10px',
      },
      popupStem: {
        left: 0,
        right: 'initial',
        borderTop: '20px solid transparent',
        borderLeft: `15px solid ${Colors.hexToRgba(Colors.lighterGray)}`,
        borderBottom: '0 solid transparent',
        borderRight: '0 solid transparent',
      },
    },
    tooltip: {
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: 400,
      width: '240px',
    },
    label: {
      display: 'block',
      color: Colors.contentText,
      fontSize: '16px',
      margin: '30px 0 20px 0',
    },
    value: {
      display: 'block',
      color: Colors.bodyText,
      fontSize: '18px',
      fontWeight: 600,
      margin: '10px 0',
    },
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.directive}>
        Below is the type of education you will be offered:
        <InfoPopup styles={styles.infoPopup}>
          <div style={styles.tooltip}>
            Depending on the type of education, you will have certain tasks to
            complete.
          </div>
        </InfoPopup>
      </h3>
      <label style={styles.label}>Education Type:</label>
      <label style={styles.value}>{name}</label>
      <div>
        <label style={styles.label}>Duration:</label>
        <label style={styles.value}>Monthly</label>
      </div>
    </div>
  );
};

RecurringEducationModuleMarkup.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Radium(RecurringEducationModuleMarkup);
