import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import PreventiveHealthActionsMarkup from './PreventiveHealthActionsMarkup';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { BiometricScreeningActions } from '@healthmine/greyhound-core/src/actions';
import * as BiometricScreeningSelectors from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';
import { bindActionCreators } from 'redux';

class PreventiveHealthActions extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      details: false,
      detailHeight: 0,
      showWelcomeBackModal: false,
      showThirdPartyCookieWarning: false,
    };

    this._toggleDetails = this._toggleDetails.bind(this);
    this._onHeightReady = this._onHeightReady.bind(this);
    this._onContentClick = this._onContentClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.carePlan.subject !== nextProps.carePlan.subject ||
      nextProps.healthAction.healthActionCode !== nextProps.activeDetails
    ) {
      this.setState({
        details: false,
        showWelcomeBackModal: false,
        showThirdPartyCookieWarning: false,
      });
    }
  }

  render() {
    const { showWelcomeBackModal, showThirdPartyCookieWarning } = this.state;

    return (
      <PreventiveHealthActionsMarkup
        {...this.props}
        {...this.state}
        toggleDetails={this._toggleDetails}
        onHeightReady={this._onHeightReady}
        onContentClick={this._onContentClick}
        showWelcomeBackModal={showWelcomeBackModal}
        onWelcomeBackModalClosed={this._onWelcomeBackModalClosed}
        showThirdPartyCookieWarning={showThirdPartyCookieWarning}
        onThirdPartyCookieWarningClosed={this._onThirdPartyCookieWarningClosed}
      />
    );
  }

  _toggleDetails() {
    this.setState({
      details: !this.state.details,
      showWelcomeBackModal: false,
      showThirdPartyCookieWarning: false,
    });

    if (this.props.setActiveDetails) {
      this.props.setActiveDetails();
    }
  }

  _onHeightReady(detailHeight) {
    this.setState({
      detailHeight,
    });
  }

  _onContentClick(evt) {
    const url = _.get(evt, 'target.href');

    if (/BiometricScreeningSSO/i.test(url)) {
      const { thirdPartyCookiesUnsupported, ssoUrl } = this.props;

      evt.preventDefault();

      if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)) {
        this.setState({
          showWelcomeBackModal: true,
        });
      } else if (thirdPartyCookiesUnsupported) {
        this.setState({
          showThirdPartyCookieWarning: true,
        });
      } else {
        window.open(ssoUrl);

        this.setState({
          showWelcomeBackModal: true,
        });
      }
    }
  }

  _onThirdPartyCookieWarningClosed = () => {
    this.setState({
      showThirdPartyCookieWarning: false,
    });
  };

  _onWelcomeBackModalClosed = () => {
    //refresh appointments from EHS
    this.props.actions.getEvents();

    this.setState({
      showWelcomeBackModal: false,
    });
  };
}

PreventiveHealthActions.propTypes = {
  ssoUrl: PropTypes.string.isRequired,
  thirdPartyCookiesUnsupported: PropTypes.bool.isRequired,

  carePlan: PropTypes.object,
  module: PropTypes.object,
  healthAction: PropTypes.object,
  activeDetails: PropTypes.string,
  setActiveDetails: PropTypes.func,
  actions: PropTypes.shape({
    getEvents: PropTypes.func.isRequired,
  }),
};

function mapStateToProps(state) {
  const ssoUrl = BiometricScreeningSelectors.selectSsoUrl(state);

  const { detected, supported } = state.thirdPartyCookieSupport;

  return {
    ssoUrl,
    thirdPartyCookiesUnsupported: !!(detected && !supported),
  };
}

function mapDispatchToProps(dispatch) {
  const { getEvents } = BiometricScreeningActions;

  return {
    actions: bindActionCreators({ getEvents }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  PreventiveHealthActions
);
