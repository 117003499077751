import { compose, withState, mapProps } from 'recompose';
import { any, flow, get, set } from 'lodash/fp';

const setField = (name, value) => set(['fields', name, 'value'], value);

const setError = (name) => (form) => {
  const field = get(['fields', name], form, {});
  const validate = () => {
    const validations = field.validations || [];
    for (let rule of validations) {
      const error = rule(field.value, field);
      if (error) return error;
    }
  };

  return set(['fields', name, 'error'], validate(), form);
};

const setErrors = (form) =>
  Object.keys(form.fields).reduce((a, c) => setError(c)(a), form);

const setValidity = (form) =>
  set('isValid', !any(get('error'), form.fields), form);

export default (fieldState, onSubmit) =>
  compose(
    withState('form', 'updateForm', (props) => ({
      isValid: true,
      fields: fieldState(props),
    })),
    mapProps((props) => {
      const { form, updateForm } = props;

      props.form.submit = () => {
        const maybeUpdateForm = (form) =>
          form.isValid ? onSubmit(props) : updateForm(form);

        flow([setErrors, setValidity, maybeUpdateForm])(form);
      };
      props.form.updateField = (name, value) => {
        flow([setField(name, value), setError(name), setValidity, updateForm])(
          form
        );
      };

      return props;
    })
  );
