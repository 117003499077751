import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withStateHandlers } from 'recompose';
import DrawerSection from './DrawerSection';
import Typography from '../Typography';
import Grid from '@material-ui/core/Grid';
import Navigational from '../icons/Navigational';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  navigational: {
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
  },
});

const DrawerStepper = ({
  options,
  classes,
  selectedStepIndex,
  next,
  previous,
}) => {
  const selectedStep = options[selectedStepIndex];

  return (
    <DrawerSection>
      <Grid container spacing={0} alignItems="center">
        <Grid className={classes.navigational} item xs={1} onClick={previous}>
          <Navigational iconClass="icon-Left-Chevron-Circle" />
        </Grid>
        <Grid item xs={10}>
          <Typography type="h4Medium" className={classes.title}>
            {selectedStep ? selectedStep.title : 'No Available Data'}
          </Typography>
        </Grid>
        <Grid className={classes.navigational} item xs={1} onClick={next}>
          <Navigational iconClass="icon-Right-Chevron-Circle" />
        </Grid>
      </Grid>
    </DrawerSection>
  );
};

DrawerStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
  selectedStepIndex: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
};

DrawerStepper.defaultProps = {
  selectedStepIndex: 0,
  onChange: () => {},
};

export const DrawerStepperBasic = withStyles(styles)(DrawerStepper);

export default compose(
  pure,
  withStyles(styles),
  withStateHandlers(
    { selectedStepIndex: 0 },
    {
      next: ({ selectedStepIndex }, { options, onChange }) => () => {
        const nextIndex = selectedStepIndex + 1;
        const length = options.length - 1;
        const index = nextIndex > length ? length : nextIndex;
        const nextStep = options[index];

        onChange(nextStep);
        return { selectedStepIndex: index };
      },
      previous: ({ selectedStepIndex }, { options, onChange }) => () => {
        const previousIndex = selectedStepIndex - 1;
        const index = 0 > previousIndex ? 0 : previousIndex;
        const previousStep = options[previousIndex];

        onChange(previousStep);
        return { selectedStepIndex: index };
      },
    }
  )
)(DrawerStepper);
