import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import SourceImages from '@healthmine/greyhound-core/src/modules/SourceImages';
import { Flex, Box } from 'reflexbox';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { selectDataSources } from '@healthmine/greyhound-core/src/selectors/MyDataSelector';

const imageSize = '80px';

const maxSources = 6;

const Sources = ({ sources, sourceSelector }) => {
  const styles = {
    container: {
      height: '300px',
      width: '300px',
    },
    header: {
      fontSize: '16px',
      fontWeight: 600,
    },
    sources: {
      maxHeight: '270px',
      overflow: 'hidden',
    },
    image: {
      width: imageSize,
      height: imageSize,
    },
    missingImage: {
      boxShadow: '0 0 2px 1px #777',
      borderRadius: '20%',
      margin: '4px',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: imageSize,
    },
    label: {
      fontSize: '16px',
      fontWeight: 300,
      fontStyle: 'italic',
    },
    instructions: {
      fontSize: '16px',
      fontWeight: 300,
    },
  };

  const selectedSources = sourceSelector(sources);

  return (
    <Flex
      column
      justify={'space-between'}
      col={4}
      px={2}
      py={1}
      style={styles.container}
    >
      <Box style={styles.header} p={1}>
        Connected With:
      </Box>
      <Flex wrap justify={'center'} style={styles.sources}>
        {selectedSources
          .filter((source) => source.name !== 'manual')
          .slice(0, maxSources)
          .map(({ name }) => {
            const source = name.toUpperCase();

            const displaySource = SourceImages[source];

            return (
              <Box key={`source-${source}`}>
                {displaySource ? (
                  <img
                    src={displaySource.image}
                    style={styles.image}
                    alt={displaySource.label}
                    title={displaySource.label}
                  />
                ) : (
                  <div style={[styles.image, styles.missingImage]}>
                    {source}
                  </div>
                )}
              </Box>
            );
          })}
      </Flex>
      {selectedSources.length > maxSources && (
        <Box style={styles.label} pt={1}>
          And {selectedSources.length - maxSources} more...
        </Box>
      )}
      <Box style={styles.instructions}>
        Click Add or Remove below to manage more!
      </Box>
    </Flex>
  );
};

Sources.propTypes = {
  sources: PropTypes.object,
  sourceSelector: PropTypes.func,
};

export default compose(
  connect((state) => ({ sources: selectDataSources(state) })),
  Radium
)(Sources);
