import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Button from '../common/Button';

const CarePlanDetailsMarkup = ({ carePlan, leftPosition }) => {
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'absolute',
      top: '250px',
      height: '250px',
      boxSizing: 'border-box',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      padding: '15px',
      left: `-${leftPosition}px`,
      right: `${-(598 - leftPosition)}px`,
    },

    content: {
      display: 'flex',
      flexDirection: 'row',
    },

    metrics: {
      flex: 1,
    },

    metric: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '8px',
      fontWeight: '300',
    },

    metricLabel: {
      flex: 3,
      paddingLeft: '50px',
      color: Colors.dark,
    },

    metricValue: {
      flex: 5,
      color: Colors.contentText,
    },

    progressButtonContainer: {
      flex: 1,
      marginTop: 'auto',
    },

    progressButton: {
      backgroundColor: Colors.white,
      color: Colors.dark,
      borderRadius: '3px',
      padding: '10px 15px',
      fontSize: '16px',
      marginLeft: '10px',
      marginBottom: '0px',

      ':hover': {
        backgroundColor: Colors.hexToRgba(Colors.dark, 0.85),
        color: Colors.light,
      },
    },
  };

  const metrics = _getMetrics(carePlan);

  return (
    <div style={styles.root}>
      <div style={styles.content}>
        <div style={styles.metrics}>
          {metrics.map((metric, metricIndex) => (
            <div key={`Metric${metricIndex}`} style={styles.metric}>
              <span style={styles.metricLabel}>{metric.label}:</span>
              <span style={styles.metricValue}>{metric.value}</span>
            </div>
          ))}
        </div>

        <div style={styles.progressButtonContainer}>
          <Button
            text="View Progress"
            link={`/care-plan/${carePlan.userPlanId}`}
            style={styles.progressButton}
          />
        </div>
      </div>
    </div>
  );
};

function _getMetrics(carePlan) {
  const goal = _.get(carePlan, 'goal');

  const goalType = _.get(goal, 'type');

  const goalConfig = _.get(localizedContent, [
    'plans',
    carePlan.planId,
    goalType,
  ]);

  const primaryAction = _.get(carePlan, 'tracking.primaryAction');

  const secondaryActions = _.get(carePlan, 'tracking.secondaryActions', []);

  let metrics = [];

  if (goalConfig) {
    switch (goalType) {
      case AppConstants.GoalType.BOOLEAN:
        metrics = goalConfig
          .filter((goalStep) => goalStep.targetValue)
          .map((goalStep) => ({
            label: goalStep.title,
            value: goalStep.targetValue,
          }));

        break;

      case AppConstants.GoalType.ABSOLUTEGAIN:
      case AppConstants.GoalType.ABSOLUTELOSS: {
        const start = parseInt(_.get(goal, 'start'));

        const end = parseInt(_.get(goal, 'end'));

        if (_.isFinite(start) && _.isFinite(end)) {
          metrics.push({
            label: goalConfig.title,
            value: `${
              goalType === AppConstants.GoalType.ABSOLUTELOSS ? 'Lose' : 'Gain'
            } ${Math.abs(start - end)} ${goalConfig.valueUnit}`,
          });
        }

        break;
      }
    }
  }

  if (
    primaryAction &&
    AppConstants.TrackingModuleTypes.includes(
      _.get(primaryAction, 'module.type')
    )
  ) {
    metrics.push(_trackingActionToMetric(primaryAction.module));
  }

  secondaryActions
    .filter((action) =>
      AppConstants.TrackingModuleTypes.includes(_.get(action, 'module.type'))
    )
    .forEach((action) => metrics.push(_trackingActionToMetric(action.module)));

  return metrics;
}

function _trackingActionToMetric(module) {
  const {
    configuredFrequency,
    description,
    measurementName,
    targetVal,
  } = module;

  const timesLabel = configuredFrequency === 1 ? 'time' : 'times';

  const targetNumber = parseInt(targetVal);

  return {
    label: description,

    value:
      targetNumber > 1
        ? `${targetNumber} ${measurementName}, ${configuredFrequency} ${timesLabel} a week`
        : `Track your ${measurementName} ${configuredFrequency} ${timesLabel} a week`,
  };
}

CarePlanDetailsMarkup.propTypes = {
  carePlan: PropTypes.object.isRequired,
  leftPosition: PropTypes.number.isRequired,
};

export default Radium(CarePlanDetailsMarkup);
