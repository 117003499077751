import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import StickyBox from 'react-sticky-box/dist/cjs';
import { Features } from '@healthmine/greyhound-core/src/constants';

const barWidth = 299;

const offsetTop = 100;

const newStyles = {
  flexContainer: {
    display: 'flex',
    flex: 1,
    width: '400px',
    height: '100%',
  },
  sticky: {
    marginTop: offsetTop,
  },
  root: {
    zIndex: 1000,
    width: '400px',
  },
  body: {
    position: 'relative',
    padding: '40px 0px 40px 40px',
  },
};

const oldStyles = {
  flexContainer: {
    maxWidth: '300px',
    backgroundColor: '#f3f6f8',
    borderLeft: `1px solid ${Colors.gutterGray}`,
  },
  root: {
    width: barWidth,
    maxWidth: barWidth,
    zIndex: 1000,
  },

  body: {
    position: 'relative',
    padding: '40px 50px 20px 20px',
  },

  sticky: {
    marginTop: offsetTop,
  },
};

const RightPane = ({ children }) => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const styles = fiveFiveUI ? newStyles : oldStyles;

  return (
    <div style={styles.flexContainer}>
      <StickyBox offsetTop={offsetTop} offsetBottom={20}>
        <div style={styles.root}>
          <div style={[GlobalStyles.light, styles.body]}>{children}</div>
        </div>
      </StickyBox>
    </div>
  );
};

RightPane.propTypes = {
  isImpersonating: PropTypes.bool,
  sticky: PropTypes.bool,
  children: PropTypes.node,
};

export default Radium(RightPane);
