import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Modal from 'react-modal';
import Button from './Button';

const LeavingOurSiteModal = ({ onOK, onCancel, customStatement }) => {
  const styles = {
    modalStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1300,
      },

      content: {
        display: 'flex',
        textAlign: 'center',
        minWidth: '250px',
        minHeight: '250px',
        right: 'initial',
        bottom: 'initial',
        maxWidth: '400px',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: Colors.white,
        outline: 'none',

        WebkitOverflowScrolling: 'touch',
      },
    },

    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '300',
    },

    title: {
      color: Colors.navyBlue,
      fontSize: '24px',
    },

    statement: {
      color: Colors.contentText,
      fontSize: '16px',
      margin: '15px 0 20px',
    },

    continueButton: {
      marginTop: '20px',
      fontWeight: 600,
    },
  };

  return (
    <Modal
      isOpen
      contentLabel="LeavingOurSiteModal"
      onRequestClose={onCancel}
      style={styles.modalStyles}
    >
      <a onClick={onCancel} style={styles.closeButton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>

      <div style={styles.content}>
        <div style={styles.title}>Continue to our partner's site</div>
        {customStatement ? (
          <div style={styles.statement}>{customStatement}</div>
        ) : (
          <div style={styles.statement}>
            Press <strong>Continue</strong> to navigate there now.
          </div>
        )}

        <div>
          <Button
            text="Continue"
            style={styles.continueButton}
            onClick={onOK}
          />
        </div>
      </div>
    </Modal>
  );
};

LeavingOurSiteModal.propTypes = {
  onOK: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  customStatement: PropTypes.string,
};

export default Radium(LeavingOurSiteModal);
