import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
  },
  node: {
    height: '30px',
    width: '30px',
    backgroundColor: 'green',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:not(:last-child)': {
      marginRight: '8px',
    },
  },
  activeNode: {
    height: '50px',
    width: '50px',
    border: '3px solid white',
  },
  activeFinalNode: {
    height: '50px',
    width: '50px',
    border: `3px solid ${theme.palette.common.whiskeySour}`,
  },
  icon: {
    height: '12px',
  },
  activeIcon: {
    height: '16px',
  },
  divider: {
    height: '2px',
    width: '30px',
    backgroundColor: 'white',
    marginRight: '8px',
  },

  progressTotal: {
    width: '80%',
    height: '20px',
    backgroundColor: '#F1F1F1',
    marginBottom: '16px',
    borderRadius: '4px',
    position: 'relative',
  },
  progress: {
    backgroundColor: '#6EC284',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '20px',
    borderRadius: '4px',
  },
});
const QuestionnaireProgressBar = ({
  classes,
  currentQuestionIndex,
  relevantQuestions,
}) => (
  <div className={classes.root}>
    {_.map(relevantQuestions, (question, idx) => (
      <>
        <div
          className={classnames(classes.node, {
            [classes.activeNode]: idx === currentQuestionIndex,
            [classes.activeFinalNode]:
              idx === currentQuestionIndex &&
              idx === _.size(relevantQuestions) - 1,
          })}
        >
          <img
            className={classnames(classes.icon, {
              [classes.activeIcon]: idx === currentQuestionIndex,
            })}
            src={
              idx < currentQuestionIndex
                ? require('../../../../images/new-fte/icons/check.png')
                : idx === _.size(relevantQuestions) - 1
                  ? require('../../../../images/new-fte/icons/trophy.png')
                  : require('../../../../images/new-fte/icons/locked.png')
            }
          />
        </div>
        {idx !== relevantQuestions.length - 1 && (
          <div className={classes.divider} />
        )}
      </>
    ))}
    {/* <div className={classes.progressTotal}>
      <div
        className={classes.progress}
        style={{ width: `${progress || 100}%` }}
      />
      <div>{currentQuestionIndex}</div>
      <div>{total}</div>
    </div> */}
  </div>
);

QuestionnaireProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  currentQuestionIndex: PropTypes.number.isRequired,
  relevantQuestions: PropTypes.array.isRequired,
};

export default withStyles(styles)(QuestionnaireProgressBar);
