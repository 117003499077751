import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import OrderSummaryMarkup from './OrderSummaryMarkup';
import { shoppingCartSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import { selectAvailablePoints } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import _ from 'lodash';

class OrderSummary extends React.Component {
  componentWillUnmount() {
    this.props.actions.redeemRewardsCompleted();
  }
  render() {
    const {
      shoppingCartItems,
      firstName,
      lastName,
      address,
      points,
      redemptionStatus,
      isAutomated,
      automatedReward,
      emailAddress,
    } = this.props;

    return (
      <OrderSummaryMarkup
        shoppingCartItems={shoppingCartItems}
        firstName={firstName}
        lastName={lastName}
        emailAddress={emailAddress}
        address={address}
        points={points}
        redemptionStatus={redemptionStatus}
        isAutomated={isAutomated}
        automatedReward={automatedReward}
      />
    );
  }
}

OrderSummary.propTypes = {
  shoppingCartItems: PropTypes.array,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  emailAddress: PropTypes.string,
  redemptionStatus: PropTypes.string,
  address: PropTypes.object,
  points: PropTypes.number,
  isAutomated: PropTypes.bool,
  automatedReward: PropTypes.object,
};

OrderSummary.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const shoppingCartItems = shoppingCartSelector(state);
  const rewardPreference = state.rewards.preference;
  const rewards = state.rewards.rewards;
  const address = rewardPreference;
  const firstName = state.userInfo.firstName;
  const lastName = state.userInfo.lastName;
  const emailAddress = state.userInfo.emailAddress;
  const points = selectAvailablePoints(state);
  const redemptionStatus = state.rewards.redemptionStatus;
  const isAutomated =
    rewardPreference &&
    rewardPreference.productId !== null &&
    rewardPreference.productId !== undefined
      ? true
      : false;
  const automatedReward =
    isAutomated === true
      ? _.find(rewards, ['productId', rewardPreference.productId])
      : undefined;

  return {
    shoppingCartItems,
    address,
    firstName,
    lastName,
    points,
    redemptionStatus,
    isAutomated,
    automatedReward,
    emailAddress,
  };
}

function mapDispatchToProps(dispatch) {
  const { redeemRewardsCompleted, getRewardsPreference } = RewardsActions;

  return {
    actions: bindActionCreators(
      { redeemRewardsCompleted, getRewardsPreference },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
