import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { hashHistory } from 'react-router';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { DrawerContent } from '../../../common/drawer2';

const OrderFailureDrawer = ({ closeHandler, serviceFailed }) => {
  const styles = {
    closebutton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    drawerContent: {
      padding: '20px',
    },

    drawerBody: {
      fontSize: '16px',
      color: Colors.contentText,
      lineHeight: '16px',
    },

    actionLabel: {
      color: Colors.linkBlue,
      cursor: 'pointer',
    },

    errorLabel: {
      color: Colors.red,
    },
  };

  return (
    <DrawerContent style={styles.customStyles}>
      <div style={styles.drawerContent}>
        {serviceFailed ? (
          <div style={styles.drawerBody}>
            Whoops! <span style={styles.errorLabel}>Something went wrong.</span>{' '}
            Check back in a few minutes and retry your submission.
          </div>
        ) : (
          <div style={styles.drawerBody}>
            It looks like you{' '}
            <span style={styles.errorLabel}>
              do not have enough money to check out.
            </span>{' '}
            Check out our
            <span
              style={styles.actionLabel}
              onClick={() => {
                closeHandler();
                hashHistory.push('/faq/');
              }}
            >
              {' '}
              FAQ
            </span>{' '}
            page to find out how you can earn more money.
          </div>
        )}
      </div>
    </DrawerContent>
  );
};

OrderFailureDrawer.propTypes = {
  serviceFailed: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
};

OrderFailureDrawer.defaultProps = {
  serviceFailed: false,
};

export default Radium(OrderFailureDrawer);
