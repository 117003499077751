import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import InfoPopup from './InfoPopup';
import { Assets, Colors } from '@healthmine/greyhound-core/src/modules';

const infoPopupStyles = {
  popupContainer: {
    width: '320px',
    '@media screen and (min-width: 481px)': {
      left: '26px',
      right: 'initial',
    },

    '@media screen and (max-width: 480px)': {
      right: '8px',
    },
  },

  popupContent: {
    '@media screen and (min-width: 481px)': {
      borderRadius: '0px 10px 10px 10px',
    },
  },

  popupStem: {
    '@media screen and (min-width: 481px)': {
      left: 0,
      right: 'initial',
      borderTop: '20px solid transparent',
      borderLeft: `15px solid ${Colors.hexToRgba(Colors.lighterGray)}`,
      borderBottom: '0 solid transparent',
      borderRight: '0 solid transparent',
    },
  },
};

const memberNumberToolTipStyle = {
  width: '300px',
  height: '190px',
};

const InsuranceCardInfoPopup = ({ explanationHtml }) => {
  return (
    <InfoPopup styles={infoPopupStyles}>
      {/* eslint-disable react/no-danger */
      explanationHtml && (
        <div
          dangerouslySetInnerHTML={{
            __html: explanationHtml,
          }}
        />
      )
      /* eslint-enable react/no-danger */
      }
      <img
        style={memberNumberToolTipStyle}
        src={Assets.getAssetURL('insurance_card.png')}
      />
    </InfoPopup>
  );
};

InsuranceCardInfoPopup.propTypes = {
  explanationHtml: PropTypes.string,
};

export default Radium(InsuranceCardInfoPopup);
