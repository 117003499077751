import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import {
  Assets,
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import ThirdPartyCookieWarning from '../common/ThirdPartyCookieWarning';
import WelcomeBackModal from '../common/WelcomeBackModal';
import StickyBox from 'react-sticky-box/dist/esnext';
import Scrollchor from 'react-scrollchor';
import Waypoint from 'react-waypoint';
import _ from 'lodash';

const FAQPageMarkup = ({
  faqs,
  footerHtml,
  includeLogo,
  onContentClick,
  showWelcomeBackModal,
  onWelcomeBackModalClosed,
  showThirdPartyCookieWarning,
  onThirdPartyCookieWarningClosed,
  activeCategory,
  setVisibility,
  setAnimating,
}) => {
  const importantNoteHtml = _.get(
    AppConfig,
    'effectiveConfig.lingo.importantNoteHtml'
  );

  const Radiumchor = Radium(Scrollchor);

  const styles = {
    root: {
      borderLeft: `1px solid ${Colors.gutterGray}`,
      padding: '0 10px 20px',
    },
    title: {
      fontSize: '24px',
      fontWeight: 400,
      margin: '15px 0',
      color: Colors.bodyText,
    },
    questionContainer: {
      margin: '20px 0px 20px 0px',
    },
    question: {
      fontSize: '16px',
      fontWeight: 400,
      color: Colors.bodyText,
      marginBottom: '5px',
    },
    para: {
      fontSize: '16px',
      lineHeight: '1.3',
      color: Colors.contentText,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '300px',
    },
    image: {
      maxWidth: '260px',
      margin: '20px 0px 20px 0px',
    },
    callContainer: {
      padding: '10px 0 20px',
    },
    contact: {
      width: '180px',
    },
    tableOfContents: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '95px',
      flex: '0 1 185px',
      marginRight: '40px',
    },
    page: {
      display: 'flex',
      paddingTop: '30px',
      justifyContent: 'center',
    },
    body: {
      maxWidth: '550px',
    },
    category: {
      fontSize: '22px',
      fontWeight: '400',
      color: Colors.bodyText,
      borderBottom: `2px solid ${Colors.gutterGray}`,
      paddingBottom: '15px',
    },
    categoryContainer: {
      marginBottom: '15px',
    },
    tableOfContentsEntry: {
      color: Colors.lightGray,
      textDecoration: 'none',
      cursor: 'pointer',
      padding: '10px 5px',
      fontSize: '16px',
      ':hover': {
        color: Colors.contentText,
      },
      ':active': {
        color: Colors.hexToRgba(Colors.linkBlue, 0.2),
      },
    },
    activeEntry: {
      color: Colors.linkBlue,
      fontWeight: '400',
    },
    stickyTable: {
      paddingTop: '100px',
      flexDirection: 'column',
      minWidth: '95px',
      marginRight: '40px',
    },
    importantNote: {
      color: Colors.lightSlateGray,
      fontSize: '16px',
      lineHeight: '17px',
    },
  };

  return (
    <StyleRoot style={[styles.root, GlobalStyles.light]}>
      {showWelcomeBackModal ? (
        <WelcomeBackModal onClose={onWelcomeBackModalClosed} />
      ) : showThirdPartyCookieWarning ? (
        <ThirdPartyCookieWarning onClose={onThirdPartyCookieWarningClosed} />
      ) : null}
      <div style={styles.page}>
        <div style={styles.tableOfContents}>
          <StickyBox style={styles.stickyTable} topOffset={0}>
            <div>
              {faqs.map(([category], index) => (
                <Radiumchor
                  beforeAnimate={() => setAnimating(true)}
                  afterAnimate={() => setAnimating(false, index)}
                  animate={{ offset: -120 }}
                  to={index === 0 ? '' : `#${category.toLowerCase()}`}
                  key={`${category.toLowerCase()}-nav`}
                  style={[
                    styles.tableOfContentsEntry,
                    index === activeCategory || (!activeCategory && index === 0)
                      ? styles.activeEntry
                      : {},
                  ]}
                >
                  <div style={{ flex: 1 }}>{category}</div>
                </Radiumchor>
              ))}
            </div>
          </StickyBox>
        </div>

        <div style={styles.body}>
          {/* eslint-disable react/no-danger */
          importantNoteHtml && (
            <div
              style={styles.importantNote}
              dangerouslySetInnerHTML={{ __html: importantNoteHtml }}
            />
          )
          /* eslint-enable react/no-danger */
          }
          {faqs.map(([category, questions], index) => (
            <Waypoint
              key={category}
              onEnter={() => {
                setVisibility(index, true);
              }}
              onLeave={() => setVisibility(index, false)}
              topOffset={'50%'}
            >
              <div
                id={`${category.toLowerCase()}`}
                style={styles.categoryContainer}
              >
                <div style={styles.category}>{category}</div>
                {questions.map((question, questionIndex) => (
                  <div
                    key={`${category}-${questionIndex}`}
                    style={styles.questionContainer}
                  >
                    <div style={styles.question}>{question.question}</div>
                    <div style={styles.para} className="answer">
                      {/* eslint-disable react/no-danger */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: AppConfig.replaceS3(question.answer),
                        }}
                        onClick={onContentClick}
                      />
                      {/* eslint-enable react/no-danger */}
                    </div>
                  </div>
                ))}
              </div>
            </Waypoint>
          ))}
          {/* eslint-disable react/no-danger */
          footerHtml && (
            <div dangerouslySetInnerHTML={{ __html: footerHtml }} />
          )
          /* eslint-enable react/no-danger */
          }
          {faqs &&
            Object.keys(faqs).length > 0 &&
            includeLogo && (
              <img
                style={styles.image}
                src={Assets.getAssetURL('logo_primary.png')}
                role="presentation"
              />
            )}
        </div>
      </div>
    </StyleRoot>
  );
};

FAQPageMarkup.propTypes = {
  faqs: PropTypes.array,
  footerHtml: PropTypes.string,
  includeLogo: PropTypes.bool.isRequired,
  contactDetails: PropTypes.object,
  onContentClick: PropTypes.func.isRequired,
  showWelcomeBackModal: PropTypes.bool.isRequired,
  onWelcomeBackModalClosed: PropTypes.func.isRequired,
  showThirdPartyCookieWarning: PropTypes.bool.isRequired,
  onThirdPartyCookieWarningClosed: PropTypes.func.isRequired,
  activeCategory: PropTypes.number,
  setVisibility: PropTypes.func.isRequired,
  setAnimating: PropTypes.func.isRequired,
};

export default Radium(FAQPageMarkup);
