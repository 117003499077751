import _ from 'lodash';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Button from '../common/Button';

const ShoppingCartMenuMarkup = ({
  userPoints,
  shoppingCartItems,
  redemptionStatus,
}) => {
  const RadiumLink = Radium(Link);

  const styles = {
    menuHeader: {
      margin: '0px 15px',
      paddingLeft: '10px',
      paddingBottom: '20px',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.bodyText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      paddingTop: '15px',
    },

    cartItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0px 15px',
      paddingLeft: '15px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },

    cartItemImage: {
      maxHeight: '50px',
    },

    cartItemContent: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      paddingLeft: '20px',
      paddingTop: '15px',
      paddingBottom: '15px',
      overflow: 'hidden',
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '12px',
      color: Colors.bodyText,
    },

    cartItemLabel: {
      lineHeight: 1.3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    cartItemQuantity: {
      padding: '8px 0px 2px',
      fontSize: '16px',
      color: Colors.contentText,
    },

    cartItemPoints: {
      fontSize: '16px',
      paddingTop: '5px',
      fontWeight: '500',
    },

    fullSummaryLink: {
      display: 'block',
      padding: '20px 0 10px',
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      color: Colors.highlightBlue,
      cursor: 'pointer',
      textDecoration: 'none',
    },

    noTransactionsLabel: {
      fontSize: '16px',
      padding: '15px 0px',
      color: Colors.contentText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      textAlign: 'center',
    },

    cartFooter: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.bodyText,
      margin: '15px',
    },

    totals: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '16px',
      lineHeight: '18px',
      marginBottom: '25px',
    },

    totalLabels: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-end',
      paddingRight: '5px',
    },

    totalValues: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },

    emptyCartLabel: {
      textAlign: 'center',
      fontSize: '16px',
      color: Colors.contentText,
    },

    checkoutButton: {
      width: '100%',
    },

    actionLink: {
      display: 'block',
      padding: '20px 0px 0px',
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      color: Colors.highlightBlue,
      cursor: 'pointer',
      textDecoration: 'none',
    },
  };

  const { setCurrencyLabel } = RewardsConstants;

  const isCartEmpty = !!(
    shoppingCartItems.length === 0 || redemptionStatus === 'COMPLETED'
  );

  const total = _.sumBy(
    shoppingCartItems,
    (cartItem) => cartItem.quantity * cartItem.denomination
  );

  return (
    <div>
      <div style={styles.menuHeader}>Rewards Shopping Cart</div>

      {!isCartEmpty &&
        shoppingCartItems.map((shoppingCartItem) => (
          <div
            style={styles.cartItemContainer}
            key={`${shoppingCartItem.productId}${
              shoppingCartItem.denomination
            }${shoppingCartItem.method}`}
          >
            <img
              src={shoppingCartItem.imageUrl}
              style={styles.cartItemImage}
              alt={`${shoppingCartItem.name} Card`}
              title={`${shoppingCartItem.name} Card`}
            />

            <div style={styles.cartItemContent}>
              <span style={styles.cartItemLabel}>
                {`$${shoppingCartItem.denomination} ${shoppingCartItem.name}`}
              </span>

              <span style={styles.cartItemQuantity}>
                Quantity: {shoppingCartItem.quantity}
              </span>
            </div>
          </div>
        ))}

      {!isCartEmpty && (
        <div style={styles.cartFooter}>
          <div style={styles.totals}>
            <div style={styles.totalLabels}>
              <span>Total:</span>
              <span>Your Balance:</span>
            </div>

            <div style={styles.totalValues}>
              <span>{setCurrencyLabel(total)}</span>
              <span>{setCurrencyLabel(userPoints - total)}</span>
            </div>
          </div>

          <RadiumLink to="/rewards/checkout">
            <Button text="Checkout" style={styles.checkoutButton} />
          </RadiumLink>

          <RadiumLink to="/rewards/shop/" style={styles.actionLink}>
            Continue Shopping?
          </RadiumLink>
        </div>
      )}

      {isCartEmpty && (
        <div style={styles.cartFooter}>
          <div style={styles.emptyCartLabel}>No items in shopping cart</div>

          <RadiumLink to="/rewards/shop/" style={styles.actionLink}>
            Start Shopping
          </RadiumLink>
        </div>
      )}
    </div>
  );
};

ShoppingCartMenuMarkup.propTypes = {
  userPoints: PropTypes.number,
  shoppingCartItems: PropTypes.array,
  redemptionStatus: PropTypes.string,
};

export default Radium(ShoppingCartMenuMarkup);
