import React from 'react';
import PropTypes from 'prop-types';
import DrawerSection from './DrawerSection';
import Typography from '../Typography';
import {
  FormControl,
  Input,
  InputLabel,
  withStyles,
  Grid,
} from '@material-ui/core';

const styles = (theme) => ({
  label: {
    position: 'static',
  },
  shrink: {
    transform: 'scale(1)',
  },
  input: {
    fontSize: '18px',
    lineHeight: '22px',
    paddingTop: 0,
    textOverflow: 'ellipsis',
  },
  disabled: {
    color: theme.palette.text.primary,
  },
});

const DrawerInput = ({
  classes,
  disabled,
  error,
  label,
  max,
  min,
  onBlur,
  onChange,
  onSubmit,
  submitText,
  required,
  type,
  value,
  endAdornment,
  autocomplete,
}) => {
  return (
    <DrawerSection>
      <FormControl fullWidth>
        <InputLabel
          htmlFor={label}
          error={!!error}
          title={error || label}
          classes={{ root: classes.label, shrink: classes.shrink }}
        >
          {error || label}
        </InputLabel>
        <Input
          classes={{ input: classes.input, disabled: classes.disabled }}
          disableUnderline
          disabled={disabled}
          error={!!error}
          fullWidth
          id={label}
          max={max}
          min={min}
          multiline={type === 'textarea'}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={label}
          required={required}
          type={type || 'text'}
          value={value === undefined || value === null ? '' : value}
          endAdornment={endAdornment}
          autoComplete={autocomplete || 'on'}
        />
      </FormControl>
      {onSubmit && (
        <Grid container justify="flex-end">
          <Grid item>
            <a style={{ cursor: 'pointer' }} onClick={onSubmit}>
              <Typography secondary>{submitText || 'Submit'}</Typography>
            </a>
          </Grid>
        </Grid>
      )}
    </DrawerSection>
  );
};

DrawerInput.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  label: PropTypes.string,
  min: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  max: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onBlur: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  autocomplete: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endAdornment: PropTypes.object,
};

export default withStyles(styles)(DrawerInput);
