import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  pageTitle: {
    color: Colors.bodyText,
    display: 'flex',
    flexDirection: 'row',
  },

  editBtn: {
    fontSize: '16px',
    color: Colors.linkBlue,
    textDecoration: 'none',
    marginLeft: '30px',
    marginTop: '3px',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    outline: 'none',
    width: 'auto',
  },

  button: {
    width: '180px',
    color: Colors.white,
    backgroundColor: Colors.dark,
    padding: '8px',
    marginBottom: '0px',
    outline: 'none',
    fontSize: '16px',
  },

  icon: {
    display: 'flex',
    borderRadius: '50%',
    color: 'white',
    width: '74px',
    height: '74px',
    fontSize: '38px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.navyBlue,
  },

  titleText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.bodyText,
    fontSize: '26px',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 500,
    color: Colors.mediumGray2,
    paddingBottom: '20px',
    paddingTop: '20px',
    borderBottom: `1px solid ${Colors.gutterGray}`,
  },

  headerLabel: {
    color: Colors.dark,
    fontSize: '16px',
    fontWeight: 500,
  },

  productRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '20px',
  },

  product: {
    width: '168px',
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 0 0',
    cursor: 'pointer',
  },

  description: {
    color: Colors.contentText,
    fontSize: '16px',
    maxWidth: '370px',
    lineHeight: '16px',
  },

  rewardsTitleText: {
    fontSize: '16px',
    fontWeight: 600,
    color: Colors.bodyText,
  },

  pageDescription: {
    display: 'flex',
    paddingTop: '50px',
  },

  automatedRewards: {
    flex: 2.8,
    display: 'flex',
    flexDirection: 'column',
  },

  balance: {
    flex: 7.2,
    display: 'flex',
    flexDirection: 'column',
  },

  rewardImage: {
    width: '85px',
    height: '50px',
  },

  actionLabel: {
    fontSize: '16px',
    fontWeight: '600',
    color: Colors.bodyText,
  },

  noContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '20px',
    fontWeight: 300,
    paddingTop: '40px',
  },

  detailsDate: {
    marginLeft: '-24px',
    lineHeight: '16px',
  },

  productContainer: {
    margin: '30px 0',
  },

  cardImage: {
    width: '60px',
    height: '40px',
  },

  cardInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '15px',
  },

  selectedCardName: {
    fontSize: '16px',
    fontWeight: 500,
    color: Colors.bodyText,
    marginLeft: '15px',
  },

  cardName: {
    marginTop: '-2px',
    fontSize: '16px',
    fontWeight: 500,
    color: Colors.bodyText,
  },

  deliveryMethods: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '6px',
    flexWrap: 'wrap',
    maxWidth: '154px',
  },

  deliveryMethod: {
    textTransform: 'uppercase',
    fontSize: '16px',
    color: Colors.white,
    lineHeight: '16px',
    marginRight: '5px',
    padding: '1px 2px 1px 3px',
    marginBottom: '2px',
  },

  digitalDeliveryMethod: {
    backgroundColor: Colors.lightGreen,
  },

  physicalDeliveryMethod: {
    backgroundColor: Colors.orange,
  },

  deliveryMethodInfoPopup: {
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '14px',
      height: '14px',
      lineHeight: '14px',
      fontSize: '16px',
      color: Colors.white,
      backgroundColor: Colors.dark,
    },

    popupContainer: {
      left: '18px',
      right: 'initial',
    },

    popupContent: {
      borderRadius: '0 10px 10px 10px',
      backgroundColor: Colors.lightenColor(Colors.lightGray, 2),
      padding: '10px 20px',
    },

    popupStem: {
      left: 0,
      right: 'initial',
      top: '-19px',
      borderTop: '20px solid transparent',
      borderLeft: `15px solid ${Colors.lightenColor(Colors.lightGray, 2)}`,
      borderBottom: '0 solid transparent',
      borderRight: '0 solid transparent',
    },
  },

  deliveryMethodInfoPopupContent: {
    color: Colors.bodyText,
    fontSize: '16px',
    fontWeight: 400,
    width: '240px',
  },

  deliveryMethodContainer: {
    margin: '25px 0px',
    display: 'flex',
    flexDirection: 'column',
  },

  deliveryMethodOption: {
    margin: '3px 0px',
    fontSize: '16px',
    cursor: 'pointer',
  },

  deliveryMethodOptionRadioButton: {
    marginRight: '10px',
  },

  staticConfigurationValue: {
    margin: '25px 0px',
    fontSize: '16px',
    fontWeight: 300,
    color: Colors.bodyText,
  },

  smallBubble: {
    borderRadius: '50%',
    marginRight: '20px',
    border: '1px solid #99a7b6',
    width: '20px',
    height: '20px',
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '270px',
    cursor: 'pointer',
  },

  select: {
    display: 'inline-block',
    width: '250px',
    fontSize: '16px',
    height: '34px',
    color: Colors.lightGray3,
    borderRadius: '5px',
    paddingLeft: '5px',
    marginTop: '25px',
    marginBottom: '25px',
  },

  inputLabel: {
    fontSize: '16px',
    fontWeight: 500,
    color: Colors.black,
    width: '140px',
    display: 'inline-block',
    margin: '5px 0px',
  },

  inputValue: {
    fontSize: '16px',
    fontWeight: 200,
    color: Colors.black,
    width: '250px',
    display: 'inline-block',
    margin: '5px 0px',
  },

  inputContainer: {
    margin: '10px 0px',
  },

  editableInput: {
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: `1px solid ${Colors.mediumGray3}`,
    width: '250px',
    marginLeft: '20px',
    height: '30px',
    fontSize: '16px',
    fontWeight: 400,
    color: Colors.black,
    outline: 'none',
  },

  editableLabel: {
    fontSize: '16px',
    fontWeight: 500,
    color: Colors.navyBlue,
    width: '120px',
    display: 'inline-block',
  },

  selectedBubble: {
    backgroundColor: Colors.mediumGray3,
    borderRadius: '50%',
    marginRight: '20px',
    border: '1px solid #99a7b6',
    width: '20px',
    height: '20px',
  },

  footerContent: {
    marginTop: '40px',
  },

  error: {
    color: Colors.red,
    marginBottom: '40px',
  },

  currentReward: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0px 10px',
  },

  modifiedRow: {
    display: 'flex',
  },
  errorLabel: {
    marginLeft: '15px',
    color: Colors.red,
  },
  registrationFooter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
  },
};
