import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { Button, withStyles } from '@material-ui/core';
import DrawerContent from '../DrawerContent';
import Typography from '../../common/Typography';
import { DrawerSection } from '../../common/drawer2';

const styles = (theme) => ({
  content: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.cosmopolitan,
    backgroundImage: `url(${require('../../../../images/background/transition.png')})`,
    backgroundSize: 'cover',
    paddingLeft: '70px',
    paddingRight: '170px',
    paddingTop: '130px',
  },
  list: {
    paddingLeft: '20px',
    '& > li': {
      margin: '10px 0',
    },
  },
  title: {
    marginBottom: '10px',
  },
  description: {
    whiteSpace: 'pre-line',
    marginBottom: '50px',
  },
  buttonContainer: {
    padding: '70px 0',
    textAlign: 'center',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.beefy,
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.beefy,
    },
  },
});

const TransitionDrawer = ({
  classes,
  title,
  description,
  onNext,
  ...props
}) => (
  <DrawerContent {...props} hideStepper onSubmit={onNext}>
    <DrawerSection className={classes.content} grow noPadding noBorder>
      <Typography type="h1" className={classes.title}>
        {title}
      </Typography>
      <Typography type="h4Medium" className={classes.description}>
        {description}
      </Typography>
      <Typography type="h3Medium" center>
        Helpful changes:
      </Typography>
      <ul className={classes.list}>
        <li>
          <Typography type="h4Medium">
            All of your clinical health actions moved into one, easily
            accessible section titled "Condition Management."
          </Typography>
        </li>
        <li>
          <Typography type="h4Medium">
            You can now configure and manage all of your data tracking devices
            directly from the front page of your new Care Plan.
          </Typography>
        </li>
        <li>
          <Typography type="h4Medium">
            All assigned education has moved into one place: Learn About My
            Health Risks.
          </Typography>
        </li>
        <li>
          <Typography type="h4Medium">
            If you had any in-progress Care Plan goals you've received any
            applicable bonus points. Check out your Points Summary for more
            details.
          </Typography>
        </li>
      </ul>
      <Typography type="h4Medium">
        Over the next few screens, you'll discover just how easy it is to manage
        your health and earn more points through your new simplified Care Plan!
      </Typography>
      <div className={classes.buttonContainer}>
        <Button variant="flat" className={classes.button} onClick={onNext}>
          Discover your simplified Care Plan
        </Button>
      </div>
    </DrawerSection>
  </DrawerContent>
);

TransitionDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withProps(() => ({
    color: 'cosmopolitan',
    title: 'Care Plans Simplified',
    /* eslint-disable */
    description:
      'You know the saying, "Less is more..." With that in mind, we took all of your current Care Plans and combined them into one, easy to use Care Plan.\n\n You\'ll find your new Care Plan contains all of your previous health actions, education, data tracking, and everything is thoughtfully structured to help you better manage your health and earn points.',
    /* eslint-enable */
  })),
  withStyles(styles)
)(TransitionDrawer);
