import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { MessageCenterActions } from '@healthmine/greyhound-core/src/actions';
import { MessageCenterConstants } from '@healthmine/greyhound-core/src/constants';
import MessageModalMarkup from './MessageModalMarkup';

class MessageModal extends React.Component {
  componentDidMount() {
    const { message } = this.props;

    if (message.status === MessageCenterConstants.MessageStatus.UNREAD) {
      this._setMessageState(MessageCenterConstants.MessageStatus.READ);
    }
  }

  render() {
    return (
      <MessageModalMarkup
        message={this.props.message}
        closeModal={this._closeModal}
        setMessageState={this._setMessageState}
      />
    );
  }

  _closeModal = () => {
    this.props.onClosed();
  };

  _setMessageState = (state, closeModal = false) => {
    const { message } = this.props;

    this.props.actions
      .updateMessages([
        {
          id: message.id,
          newStatus: state,
        },
      ])
      .then(() => this.props.actions.getMessages())
      .catch(() => this.props.actions.getMessages());

    if (closeModal) {
      this._closeModal();
    }
  };
}

MessageModal.propTypes = {
  message: PropTypes.object.isRequired,
  onClosed: PropTypes.func.isRequired,

  actions: PropTypes.shape({
    updateMessages: PropTypes.func.isRequired,
    getMessages: PropTypes.func.isRequired,
  }),
};

function mapDispatchToProps(dispatch) {
  const { updateMessages, getMessages } = MessageCenterActions;

  return {
    actions: bindActionCreators({ updateMessages, getMessages }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(MessageModal);
