import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules/';

const Button = ({ style, disabledStyle, onClick, link, text, disabled }) => {
  const styles = {
    button: {
      ...GlobalStyles.light,
      color: disabled ? Colors.hexToRgba(Colors.light, 0.7) : Colors.light,
      backgroundColor: disabled
        ? Colors.hexToRgba(Colors.dark, 0.7)
        : Colors.dark,
      fontSize: '16px',
      textAlign: 'center',
      verticalAlign: 'middle',
      cursor: disabled ? 'not-allowed' : 'pointer',
      userSelect: 'none',
      outline: 'none',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: disabled ? Colors.hexToRgba(Colors.dark, 0.7) : Colors.dark,
      borderRadius: '4px',
      marginBottom: '8px',
      padding: '5px 20px',

      ':hover': {
        backgroundColor: Colors.hexToRgba(Colors.dark, 0.85),
      },

      ':active': {
        backgroundColor: Colors.darkenColor(Colors.dark, 1),
      },
    },
  };

  const buttonUI = (
    <button
      type="submit"
      style={[styles.button, style, disabled ? disabledStyle : null]}
      disabled={disabled}
      onClick={link ? null : onClick}
    >
      {text}
    </button>
  );

  if (link) {
    return (
      <Link to={link} onClick={onClick}>
        {buttonUI}
      </Link>
    );
  } else {
    return buttonUI;
  }
};

Button.propTypes = {
  style: PropTypes.object,
  disabledStyle: PropTypes.object,
  onClick: PropTypes.func,
  link: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  style: {},
  text: 'Next',
  disabled: false,
};

export default Radium(Button);
