import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import _ from 'lodash';
import moment from 'moment';
import { DrawerContent, withDrawer } from '../../../common/drawer2';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { connect } from 'react-redux';
import { selectIncentiveTransactionsKeyedByTypeSelector } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import { bindActionCreators } from 'redux';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../../../common/Typography';

const styles = () => ({
  table: {
    textAlign: 'left',
    fontSize: '16px',
    width: '100%',
    borderCollapse: 'collapse',
  },

  th: {
    padding: '10px 5px',
    color: Colors.mediumGray2,
    backgroundColor: '#f3f6f8',
    fontSize: '16px',
    textAlign: 'center',
  },

  td: {
    padding: '20px 5px',
    color: Colors.contentText,
    borderBottom: `1px solid ${Colors.gutterGray}`,
    textAlign: 'center',
  },
});

const RedeemActivityList = ({ classes, title, redeemHistory }) => {
  return (
    <DrawerContent title={title}>
      {redeemHistory.length === 0 ? (
        <Typography type="h4" color="grey" center>
          No redeem activity found...
        </Typography>
      ) : (
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.th} width="30%">
                Rewards
              </th>
              <th className={classes.th} width="20.5%">
                Date
              </th>
              <th className={classes.th} width="14.5%">
                Quantity
              </th>
              <th className={classes.th} width="20%">
                Delivery
              </th>
              <th className={classes.th} width="18%">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {redeemHistory.map((item, index) => (
              <tr key={index}>
                <td className={classes.td}>{item.description}</td>
                <td className={classes.td}>
                  {moment(item.lastIssuedDate).format('MM/DD/YYYY')}
                </td>
                <td className={classes.td}>{item.completedCount}</td>
                <td className={classes.td}>
                  {item.deliveryForm ===
                  RewardsConstants.RewardDeliveryForm.DIGITAL
                    ? 'Email'
                    : item.deliveryForm ===
                      RewardsConstants.RewardDeliveryForm.PHYSICAL
                      ? 'Standard Mail'
                      : null}
                </td>
                <td className={classes.td}>
                  {RewardsConstants.setCurrencyLabel(
                    Math.abs(item.amount || 0)
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </DrawerContent>
  );
};

RedeemActivityList.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  redeemHistory: PropTypes.array,
};

export default compose(
  pure,
  withDrawer,
  connect(
    (state) => {
      const rewardsData = selectIncentiveTransactionsKeyedByTypeSelector(state);
      const redeemHistory = _.get(rewardsData, 'REDEEM', []);
      return {
        redeemHistory: redeemHistory.filter(
          (item) => item.dataType !== 'SWEEPSTAKES_ENTRY_DATA'
        ),
      };
    },
    (dispatch) => {
      const { getRewardsHistory } = RewardsActions;
      return {
        actions: bindActionCreators({ getRewardsHistory }, dispatch),
      };
    }
  ),
  withProps(({ closedSweepstakes }) => {
    const totalEntries = _.sumBy(closedSweepstakes, 'memberEntries');
    const totalWins = _.sumBy(closedSweepstakes, 'memberWins');

    return {
      totalEntries,
      totalWins,
    };
  }),
  withStyles(styles)
)(RedeemActivityList);
