import { compose, pure, withProps } from 'recompose';
import { withRouter } from 'react-router';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import HealthActionList from './list/HealthActionList';
import CarePlanCard from './card/CarePlanCard';
import HealthActionListDrawer from './drawer/HealthActionListDrawer';
import HealthActionDrawerView from './drawer/HealthActionDrawerView';
import BiometricModals from '../biometric-screening/BiometricModals';
import MedicationHealthActionDrawer from './drawer/MedicationHealthAction';
import { withDrawer } from '../../../common/drawer2';
import { TrackingStatus } from '@healthmine/greyhound-core/src/constants/Tracking';
import BiometricScreeningConstants from '@healthmine/greyhound-core/src/constants/BiometricScreening';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import WhatToExpectDrawer from '../biometric-screening/WhatToExpect';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { WithPreventiveActions } from '@healthmine/greyhound-core/src/hocs';
import BiometricResultsDrawer from '../biometric-screening/BiometricResults';

const ScreeningStatus = BiometricScreeningConstants.Status;

const CarePlanItem = ({
  actionCompletionPercent,
  color,
  healthActions,
  iconClass,
  isListView,
  openHealthActionDrawer,
  openHealthActionListDrawer,
  remainingActionCount,
  title,
  whatsNextActionTitle,
  prescribedTrackers,
  hraStatus,
  isBiometricModalOpen,
  hideBiometricModal,
  screeningType,
}) => (
  <div>
    {isListView ? (
      <HealthActionList
        color={color}
        healthActions={healthActions}
        iconClass={iconClass}
        openDrawer={openHealthActionDrawer}
        remainingActionCount={remainingActionCount}
        title={title}
        trackers={prescribedTrackers}
        hraStatus={hraStatus}
      />
    ) : (
      <CarePlanCard
        actionCompletionPercent={actionCompletionPercent}
        color={color}
        iconClass={iconClass}
        openDrawer={openHealthActionListDrawer}
        remainingActionCount={remainingActionCount}
        title={title}
        whatsNextActionTitle={whatsNextActionTitle}
      />
    )}

    <BiometricModals
      screeningType={screeningType}
      showModals={isBiometricModalOpen}
      onClose={hideBiometricModal}
    />
  </div>
);

CarePlanItem.propTypes = {
  actionCompletionPercent: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  healthActions: PropTypes.array.isRequired,
  iconClass: PropTypes.string.isRequired,
  isListView: PropTypes.bool.isRequired,
  openHealthActionDrawer: PropTypes.func.isRequired,
  openHealthActionListDrawer: PropTypes.func.isRequired,
  remainingActionCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  whatsNextActionTitle: PropTypes.string.isRequired,
  trackers: PropTypes.array,
  prescribedTrackers: PropTypes.array,
  hraStatus: PropTypes.string,
  isBiometricModalOpen: PropTypes.bool.isRequired,
  hideBiometricModal: PropTypes.func.isRequired,
  screeningType: PropTypes.number,
};

CarePlanItem.defaultProps = {
  trackers: [],
};

export default compose(
  pure,
  withDrawer,
  withRouter,
  WithPreventiveActions,
  withProps(
    ({
      drawer,
      actionCount,
      completedActionCount,
      healthActions,
      color,
      remainingActionCount,
      title,
      trackers,
      hraStatus,
      biometricScreeningStatus,
    }) => {
      const actionCompletionPercent = completedActionCount / actionCount * 100;

      const { true: completedActions, false: remainingActions } = _.groupBy(
        healthActions,
        'completed'
      );
      const whatsNextActionTitle = _.get(_.head(remainingActions), 'title', '');
      const earnedIncentiveTotal = _.reduce(
        completedActions,
        (sum, action) => sum + action.incentiveAmount,
        0
      );
      const incentiveTotal = _.reduce(
        healthActions,
        (sum, action) => sum + action.incentiveAmount,
        0
      );
      const remainingIncentiveCount = incentiveTotal - earnedIncentiveTotal;
      const incentiveCompletionPercent =
        earnedIncentiveTotal / incentiveTotal * 100;

      const openHealthActionDrawer = (id) => {
        const healthAction = _.find(
          healthActions,
          (action) => action.id === id
        );
        if (
          healthAction.code ===
          AppConstants.HealthActions.COMPLETE_BIOMETRIC_TESTING
        ) {
          if (biometricScreeningStatus === ScreeningStatus.COMPLETED) {
            drawer.open(BiometricResultsDrawer, null, { large: true });
          } else {
            drawer.open(WhatToExpectDrawer);
          }
        } else if (
          healthAction.code ===
            AppConstants.HealthActions.STATIN_THERAPY_COMPLIANCE &&
          AppConfig.validateFeature(Features.MED_ADHERENCE_TRACKER)
        ) {
          drawer.open(MedicationHealthActionDrawer, { medType: 'LOVASTATIN' });
        } else if (
          healthAction.code ===
            AppConstants.HealthActions.ASTHMA_MEDICATION_ADHERENCE &&
          AppConfig.validateFeature(Features.MED_ADHERENCE_TRACKER)
        ) {
          drawer.open(MedicationHealthActionDrawer, { medType: 'QVAR' });
        } else {
          drawer.open(HealthActionDrawerView, { healthAction, hraStatus });
        }
      };

      const prescribedTrackers = _.filter(
        trackers,
        (tracker) => tracker.status === TrackingStatus.PRESCRIBED
      );

      const openHealthActionListDrawer = () => {
        drawer.open(HealthActionListDrawer, {
          title,
          actionCompletionPercent,
          color,
          healthActions,
          incentiveCompletionPercent,
          remainingActionCount,
          remainingIncentiveCount,
          selectHealthAction: openHealthActionDrawer,
          trackers: prescribedTrackers,
          hraStatus,
        });
      };

      return {
        prescribedTrackers,
        actionCompletionPercent,
        openHealthActionDrawer,
        openHealthActionListDrawer,
        whatsNextActionTitle,
      };
    }
  )
)(CarePlanItem);
