import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';
import Typography from '../../common/Typography';
import classnames from 'classnames';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withSweepstakes } from '@healthmine/greyhound-core/src/hocs';
import {
  selectCurrentIncentiveSummary,
  selectAvailablePoints,
} from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import { commaFormat } from '@healthmine/greyhound-core/src/utils';

const Summary = ({
  classes,
  totalPoints,
  availablePoints,
  spent,
  enteredTotal,
  openTotal,
}) => (
  <Grid className={classes.root} container alignItems="center">
    <Grid item xs={12} md="auto">
      <Grid container alignItems="center">
        <Hidden smDown>
          <Grid item>
            <i className={classnames(classes.icon, 'icon-Star-Circle')} />
          </Grid>
        </Hidden>
        <Grid item>
          <Typography type="h3Medium">{totalPoints} Pts</Typography>
          <Typography>Total Earned</Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item className={classes.sectionDivided} xs={12} md="auto">
      <Typography type="h3Medium">{spent} Pts</Typography>
      <Typography>Spent</Typography>
    </Grid>
    <Grid item className={classes.sectionDivided} xs={12} md="auto">
      <Typography type="h3Medium">{availablePoints} Pts</Typography>
      <Typography>Available</Typography>
    </Grid>
    <Grid item className={classes.sectionDivided} xs={12} md="auto">
      <Typography type="h3Medium">
        {enteredTotal} of {openTotal}
      </Typography>
      <Typography>Sweepstakes Entered</Typography>
    </Grid>
  </Grid>
);

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
  totalPoints: PropTypes.string.isRequired,
  availablePoints: PropTypes.string.isRequired,
  spent: PropTypes.string.isRequired,
  enteredTotal: PropTypes.number.isRequired,
  openTotal: PropTypes.number.isRequired,
};

const styles = (theme) => ({
  root: {
    borderRadius: theme.globalStyles.borderRadiusFull,
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.globalStyles.borderRadius1,
    },
    boxShadow: theme.globalStyles.shadow2,
    padding: '20px',
    backgroundColor: '#fff',
    marginBottom: '30px',
  },
  icon: {
    fontSize: '60px',
    marginRight: '20px',
  },
  sectionDivided: {
    borderLeft: `1px solid ${theme.palette.common.twentyCC}`,
    marginLeft: '20px',
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingLeft: 0,
      borderLeft: 'none',
      borderTop: `1px solid ${theme.palette.common.twentyCC}`,
      marginTop: '10px',
      paddingTop: '10px',
    },
  },
});

export default compose(
  withStyles(styles),
  withSweepstakes,
  connect((state) => {
    const availablePoints = selectAvailablePoints(state);
    const pointsSummary = selectCurrentIncentiveSummary(state);
    return {
      availablePoints,
      totalPoints: pointsSummary.totalPoints,
    };
  }),
  withProps(
    ({
      enteredSweepstakes,
      openSweepstakes,
      totalPoints,
      availablePoints,
    }) => ({
      enteredTotal: enteredSweepstakes.length,
      openTotal: openSweepstakes.length,
      totalPoints: commaFormat(totalPoints),
      availablePoints: commaFormat(availablePoints),
      spent: commaFormat(totalPoints - availablePoints),
    })
  )
)(Summary);
