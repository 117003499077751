import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  page: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '50px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflowY: 'hidden',
  },

  primaryText: {
    color: Colors.bodyText,
    fontSize: '29px',
    fontWeight: '200',
    margin: 0,
    padding: 0,
  },

  secondaryText: {
    color: Colors.bodyText,
    fontSize: '16px',
    fontWeight: 'normal',
    margin: '6px 0 15px',
    padding: 0,
  },

  tertiaryText: {
    color: '#99A7B5',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: '200',
    margin: 0,
    padding: 0,
  },

  dashboardLinkButton: {
    marginTop: '70px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    cursor: 'pointer',
  },

  dashboardLinkButtonIcon: {
    color: Colors.highlightBlue,
    fontSize: '22px',
    marginRight: '8px',
  },

  dashboardLinkButtonCaption: {
    fontSize: '20px',
    color: Colors.highlightBlue,
  },
};
