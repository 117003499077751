import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import BottomNav from './BottomNav';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import PickerComponent from '../common/question/Picker';
import { Features } from '@healthmine/greyhound-core/src/constants';

const HowYouFeelMarkup = ({
  medConditions,
  medConditionsAnswerChoices,
  errors,
  scrollDown,
  scrollUp,
  onClick,
  question,
  skip,
}) => {
  const questionStyle = {
    fontSize: '16px',
    fontWeight: 400,
    margin: '50px 0 30px',
  };

  const note = {
    color: Colors.contentText,
    marginLeft: '10px',
  };

  const error = {
    lineHeight: '25px',
    color: Colors.red,
  };

  const emptyBox = {
    paddingBottom: '25px',
  };

  const pickerStyles = {
    textItem: {
      paddingTop: 0,
      paddingBottom: '30px',
      paddingRight: '15px',
    },
    smallBubble: {
      width: '30px',
      height: '30px',
      margin: '0 15px 0 0',
    },
    label: {
      fontSize: '16px',
      minWidth: '120px',
    },
  };

  const medConditionsAnswer = medConditionsAnswerChoices
    ? medConditionsAnswerChoices.map((medCondition) => {
        return {
          label: medCondition.option.en,
          questionAnswerOptionId: medCondition.id,
        };
      })
    : [];

  return (
    <div>
      <h3 style={questionStyle}>
        {question}
        <span style={note}>(Choose all that apply)</span>
      </h3>

      <PickerComponent
        options={medConditionsAnswer.map((option) => {
          return { label: option.label, value: option.questionAnswerOptionId };
        })}
        columns={3}
        onAnswered={(answers) => onClick(0, answers[0])}
        values={medConditions}
        styles={pickerStyles}
      />

      {errors.length ? (
        <span style={[GlobalStyles.regular, error]}>{errors[0]}</span>
      ) : (
        <div style={emptyBox} />
      )}

      <BottomNav
        scrollDown={scrollDown}
        scrollUp={scrollUp}
        skip={
          AppConfig.validateFeature(Features.OPTIONAL_ONBOARDING) ? skip : null
        }
      />
    </div>
  );
};

HowYouFeelMarkup.propTypes = {
  medConditions: PropTypes.array.isRequired,
  medConditionsAnswerChoices: PropTypes.array,
  errors: PropTypes.array.isRequired,
  scrollDown: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  scrollUp: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
};

export default Radium(HowYouFeelMarkup);
