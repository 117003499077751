import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from './Card';
import Typography from '../Typography';
import Chip from '../Chip';

const styles = () => ({
  customCard: {
    width: '237px',
    height: '130px',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '40px',
    position: 'relative',
  },
  titleWrapper: {
    marginTop: '10px',
  },
  badgeIconWrapper: {
    position: 'absolute',
    top: '-15px',
    right: '16px',
  },
  badge: {
    position: 'absolute',
  },
});

const IconTitleCard = ({
  classes,
  onClick,
  iconClass,
  title,
  primary,
  badgeNumber,
}) => {
  return (
    <Card
      customClass={classes.customCard}
      primary={primary}
      column
      onClick={onClick}
    >
      <i className={`${iconClass} ${classes.icon}`}>
        {badgeNumber > 0 ? (
          <div className={classes.badgeIconWrapper}>
            <Chip
              className={classes.badge}
              label={badgeNumber > 99 ? '99+' : badgeNumber}
              color="grannySmith"
            />
          </div>
        ) : null}
      </i>
      <div className={classes.titleWrapper}>
        <Typography type="h3Medium">{title}</Typography>
      </div>
    </Card>
  );
};

IconTitleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  badgeNumber: PropTypes.number,
};

export default withStyles(styles)(IconTitleCard);
