import { Colors } from '@healthmine/greyhound-core/src/modules';

const oldStyles = {
  root: {
    maxWidth: '900px',
  },

  title: {
    paddingTop: '20px',
    paddingLeft: '50px',
    paddingBottom: '20px',
    fontSize: '20px',
    color: Colors.navyBlue,
    backgroundColor: '#f3f6f8',
  },

  carePlans: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
};

const newStyles = {
  root: {
    marginTop: '40px',
  },

  title: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '30px',
    textTransform: 'uppercase',
  },
};

export default {
  oldStyles,
  newStyles,
};
