import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import {
  DrawerSection,
  DrawerSectionHeader,
  DrawerContent,
} from '../../../../common/drawer2';
import StatusWidget from '../../../../common/widgets/StatusWidget';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import EducationAction from './EducationAction';
import withRecommendedEducation from '@healthmine/greyhound-core/src/hocs/WithRecommendedEducation';
import {
  displayMaxCount,
  commaFormat,
} from '@healthmine/greyhound-core/src/utils';

const EducationListDrawer = ({
  title,
  color,
  remainingRecommendedEducationCount: remainingCount,
  completedRecommendedEducationPercent,
  recommendedEducationAvailablePoints,
  incentiveLabel,
  theme,
  remainingRecommendedEducation,
  selectHealthAction,
  recommendedEducationMaxDisplayCount: maxDisplayCount,
}) => {
  return (
    <DrawerContent title={title}>
      <DrawerSection>
        <StatusWidget
          color={color}
          status={displayMaxCount(remainingCount, maxDisplayCount)}
          statusNote={`action${remainingCount !== 1 ? 's' : ''} remaining`}
          percentage={completedRecommendedEducationPercent}
        />
      </DrawerSection>
      <DrawerSection>
        <StatusWidget
          color={theme.palette.common.whiskeySour}
          status={commaFormat(recommendedEducationAvailablePoints)}
          statusNote={`${incentiveLabel} available to earn`}
          percentage={completedRecommendedEducationPercent}
        />
      </DrawerSection>
      <DrawerSectionHeader title="Education Actions" />
      {_.map(_.take(remainingRecommendedEducation, 5), (education) => {
        return (
          <EducationAction
            key={`education_${education.contentId}`}
            selectHealthAction={selectHealthAction}
            education={education}
          />
        );
      })}
    </DrawerContent>
  );
};

EducationListDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  remainingRecommendedEducationCount: PropTypes.number.isRequired,
  completedRecommendedEducationPercent: PropTypes.number.isRequired,
  recommendedEducationAvailablePoints: PropTypes.number.isRequired,
  incentiveLabel: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  remainingRecommendedEducation: PropTypes.array.isRequired,
  selectHealthAction: PropTypes.func.isRequired,
  recommendedEducationMaxDisplayCount: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withRecommendedEducation,
  withProps({
    incentiveLabel: IncentiveSummary.incentiveLabel(),
  }),
  withTheme()
)(EducationListDrawer);
