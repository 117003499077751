import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  modalStyles: {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
      zIndex: 1010,
    },

    content: {
      padding: '0px',
      overflow: 'hidden',
      width: '50%',
      maxWidth: '50%',
      height: '50%',
      maxHeight: '50%',
      right: 'initial',
      bottom: 'initial',
      left: '50%',
      top: '25%',
      transform: 'translate(-50%, -25%)',
      border: 'none',
      backgroundColor: Colors.white,
      outline: 'none',
    },
  },

  agreementHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '45px',
    margin: '0px',
    fontSize: '18px',
  },

  agreementScroller: {
    position: 'absolute',
    top: '45px',
    left: '0px',
    right: '0px',
    bottom: '45px',
    borderTop: `1px solid ${Colors.mediumGray3}`,
    borderBottom: `1px solid ${Colors.mediumGray3}`,
  },

  agreementFrame: {
    width: '100%',
    height: '100%',
  },

  agreementCheckboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '45px',
    maxHeight: '45px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    color: Colors.contentText,
    fontSize: '16px',
  },
};
