import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import {
  EDUCATION_TYPES,
  SUGGESTED_SEARCHES,
} from '@healthmine/greyhound-core/src/constants/Education';
import EducationGroupMarkup from './EducationGroupMarkup';
import SuggestedEducationMarkup from './SuggestedEducationMarkup';
import AssignedEducation from './AssignedEducation';
import Modal from 'react-modal';
import Autosuggest from 'react-autosuggest';
import Page from '../common/Page';

const PersonalHealthAssistantMarkup = ({
  search,
  searchKeyword,
  suggestions,
  type,
  loading,
  searchSize,
  setSearchKeyword,
  searchCurrentKeyword,
  searchForKeyword,
  frameLink,
  contentType,
  setFrameLink,
  resetFrameLink,
  resetSearch,
  expanded,
  toggleExpansion,
  renderSuggestions,
  enableSuggestions,
  assignedEducation,
  hasAssignedEducation,
}) => {
  const styles = {
    root: {
      padding: '0 75px',
    },
    container: {
      width: '350px',
      paddingRight: '75px',
    },
    pageContent: {
      flex: 4,
      paddingLeft: '15px',
    },
    pageTitle: {
      color: Colors.black,
      fontSize: '28px',
    },
    pageSubTitle: {
      color: Colors.black,
      fontSize: '16px',
      marginTop: '20px',
    },
    searchForm: {
      flex: 5,
      display: 'flex',
      alignItems: 'center',
      marginRight: '15px',
      borderBottom: `1px solid ${Colors.contentText}`,
      height: '40px',
      paddingBottom: '2px', //IEFix
    },
    tabContent: {
      color: Colors.mediumGray,
      position: 'relative',
      fontSize: '16px',
    },
    assignedContentWrapper: {
      padding: '20px 0 0',
    },
    listingWrapper: {
      padding: '30px 0',
    },
    searchCounts: {
      marginBottom: '30px',
    },

    planCounts: {
      fontSize: '16px',
      color: Colors.contentText,
      fontWeight: 300,
    },
    planCount: {
      paddingRight: '8px',
    },
    contentType: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.bodyText,
    },
    contentItem: {
      padding: '0 0 15px',
      cursor: 'pointer',
    },
    contentDescription: {
      color: Colors.contentText,
      margin: '5px 0',
    },
    link: {
      color: Colors.black,
      textDecoration: 'none',
    },

    completedEducation: {
      color: Colors.white,
      padding: '2px',
      marginLeft: '8px',
      fontWeight: '600',
      fontSize: '16px',
      backgroundColor: Colors.linkBlue,
      borderRadius: '50%',
    },

    backLink: {
      color: Colors.highlightBlue,
      cursor: 'pointer',
      marginBottom: '30px',
      flex: 1,
    },
    expandLink: {
      fontSize: '16px',
      color: Colors.highlightBlue,
      cursor: 'pointer',
      marginTop: 0,
      marginBottom: '30px',
    },
    searchIcon: {
      height: '22px',
      width: '22px',
      cursor: 'pointer',
      padding: '5px 0',
      color: Colors.contentText,
    },
    customStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },
      content: {
        minWidth: '80%',
        minHeight: '80%',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: Colors.white,
        WebkitOverflowScrolling: 'touch',
        outline: 'none',
        padding: '30px',
      },
    },
    closebuttonBox: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      height: '75px',
      backgroundColor: Colors.light,
    },
    closebutton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },
    modalContent: {
      width: '100%',
      height: '80vh',
    },
    modalContentConversation: {
      width: '118vh',
      height: '90vh',
      margin: '0 auto',
    },
    modalContentVideo: {
      width: '125vh',
      height: '80vh',
      margin: '0 auto',
    },
    frame: {
      border: 'none',
      width: '100%',
      height: '100%',
    },
    autoSuggestTheme: {
      container: {
        alignSelf: 'flex-start',
        flex: 2,
        zIndex: 10,
        marginLeft: '-22px',
        display: 'flex',
        flexDirection: 'column',
      },
      suggestionsList: {
        border: `1px solid ${Colors.gutterGray}`,
        listStyle: 'none',
        backgroundColor: Colors.white,
        padding: '0',
      },
      suggestion: {
        padding: '8px',
        fontSize: '16px',
        color: Colors.contentText,
        backgroundColor: Colors.white,
        cursor: 'pointer',
        display: 'flex',
        ...GlobalStyles.regular,
      },
      suggestionFocused: {
        backgroundColor: Colors.lightGray2,
      },
      input: {
        ...GlobalStyles.regular,
        fontSize: '16px',
        padding: '10px',
        outline: 'none',
        border: '0',
        marginLeft: '22px',
      },
    },
    suggestionText: {
      flex: 1,
    },
    noContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentTitle: {
      fontSize: '26px',
      fontWeight: '300',
      color: Colors.bodyText,
      padding: '100px 0px 50px',
    },
    contentCaption: {
      color: Colors.contentText,
      paddingBottom: '10px',
    },
    contentSuggestion: {
      color: Colors.highlightBlue,
    },
  };

  const subTitle = hasAssignedEducation
    ? 'Assigned Education'
    : 'Suggested Topics';

  const searchPicon = (
    <i className="thin-search" style={{ fontSize: '22px' }} />
  );

  const searchInputProps = {
    value: searchKeyword,
    onChange: (event, params) => {
      const { newValue, method } = params;
      enableSuggestions();
      setSearchKeyword(newValue);
      if (method === 'click') {
        searchForKeyword(newValue);
      }
    },
    placeholder: 'Search Health Library',
    id: 'educationSearch',
    onBlur: () => enableSuggestions(),
    onFocus: () => enableSuggestions(),
  };

  const searchForm = (
    <div style={styles.container}>
      <form
        style={styles.searchForm}
        value={searchKeyword}
        onSubmit={
          searchKeyword ? searchCurrentKeyword : (e) => e.preventDefault()
        }
        noValidate
      >
        <span style={styles.searchIcon}>{searchPicon}</span>
        <Autosuggest
          theme={styles.autoSuggestTheme}
          onSuggestionsFetchRequested={() => true}
          onSuggestionsClearRequested={() => true}
          suggestions={SUGGESTED_SEARCHES}
          shouldRenderSuggestions={() => renderSuggestions}
          focusInputOnSuggestionClick={false}
          getSuggestionValue={(suggestion) => suggestion}
          renderSuggestion={(suggestion) => (
            <span style={styles.suggestionText}>{suggestion}</span>
          )}
          inputProps={searchInputProps}
        />
      </form>
    </div>
  );

  return (
    <Page
      title={'Health Library'}
      icon={'thin-0007_book_reading_read_bookmark'}
      titleChild={searchForm}
    >
      <div style={styles.container} />
      <div style={styles.root}>
        <div style={GlobalStyles.regular}>
          {'search' == type ? (
            <div style={styles.tabContent}>
              {hasAssignedEducation && (
                <div style={styles.backLink}>
                  <span onClick={resetSearch}>
                    &lt; Back to Assigned Education
                  </span>
                </div>
              )}

              {loading ? (
                <div style={styles.listingWrapper}>Loading...</div>
              ) : (
                <div>
                  <p style={styles.searchCounts}>
                    <span style={styles.planCounts}>
                      <span style={styles.planCount}>{searchSize} results</span>
                      {EDUCATION_TYPES.map((type) => {
                        return search[type] && search[type].length ? (
                          <span key={type} style={styles.planCount}>
                            {search[type][0].contentTypeValue} ({
                              search[type].length
                            })
                          </span>
                        ) : null;
                      })}
                    </span>
                  </p>

                  {EDUCATION_TYPES.map(
                    (type) =>
                      search[type] && search[type].length ? (
                        <EducationGroupMarkup
                          key={type}
                          title={search[type][0].contentTypeValue}
                          items={search[type]}
                          expanded={expanded}
                          setFrameLink={setFrameLink}
                          toggleExpansion={toggleExpansion}
                        />
                      ) : null
                  )}
                </div>
              )}
            </div>
          ) : (
            <div style={styles.tabContent}>
              <div style={styles.pageSubTitle}>{subTitle}</div>
              {hasAssignedEducation ? (
                <AssignedEducation
                  assignedEducation={assignedEducation}
                  setFrameLink={setFrameLink}
                  toggleExpansion={toggleExpansion}
                  expanded={expanded}
                />
              ) : (
                <SuggestedEducationMarkup
                  suggestions={suggestions}
                  expanded={expanded}
                  setFrameLink={setFrameLink}
                  toggleExpansion={toggleExpansion}
                />
              )}
            </div>
          )}
        </div>

        {frameLink ? (
          <Modal
            contentLabel={'Education'}
            isOpen={!!frameLink}
            onRequestClose={resetFrameLink}
            style={styles.customStyles}
          >
            <div
              style={
                contentType != 'Videos' && contentType != 'Conversation'
                  ? styles.closebuttonBox
                  : null
              }
            >
              <a onClick={resetFrameLink} style={styles.closebutton}>
                <i className="thin-0153_delete_exit_remove_close" />
              </a>
            </div>
            <div
              style={
                contentType == 'Conversation'
                  ? styles.modalContentConversation
                  : contentType == 'Videos'
                    ? styles.modalContentVideo
                    : styles.modalContent
              }
            >
              <iframe
                style={styles.frame}
                src={frameLink}
                allowFullScreen="true"
              />
            </div>
          </Modal>
        ) : null}
      </div>
    </Page>
  );
};

PersonalHealthAssistantMarkup.propTypes = {
  search: PropTypes.object,
  searchKeyword: PropTypes.string,
  type: PropTypes.oneOf(['assigned', 'search', '']).isRequired,
  loading: PropTypes.bool,
  searchSize: PropTypes.number,
  setSearchKeyword: PropTypes.func.isRequired,
  searchCurrentKeyword: PropTypes.func.isRequired,
  searchForKeyword: PropTypes.func.isRequired,
  frameLink: PropTypes.string,
  contentType: PropTypes.string,
  setFrameLink: PropTypes.func.isRequired,
  resetFrameLink: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  expanded: PropTypes.string,
  toggleExpansion: PropTypes.func.isRequired,
  renderSuggestions: PropTypes.bool,
  enableSuggestions: PropTypes.func.isRequired,
  hasAssignedEducation: PropTypes.bool,
  assignedEducation: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      contentTypes: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          type: PropTypes.oneOf(EDUCATION_TYPES).isRequired,
          length: PropTypes.number.isRequired,
          previewContent: PropTypes.arrayOf(PropTypes.object).isRequired,
          content: PropTypes.arrayOf(PropTypes.object).isRequired,
        })
      ),
    })
  ),
  suggestions: PropTypes.object,
};

export default Radium(PersonalHealthAssistantMarkup);
