import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import PageSection from '../../common/page/PageSection';
import MedicationList from './Medications/MedicationList';
import ScreeningProcedureList from './ScreeningsAndProcedures/ScreeningProcedureList';
import LabList from './Labs/LabList';
import ConditionList from './Conditions/ConditionList';
import PrescriptionList from './PrescriptionList';
import { WithHealthRecord } from '@healthmine/greyhound-core/src/hocs';
import MemberSummary from './MemberSummary/MemberSummary';
import withMemberSummaryData from '@healthmine/greyhound-core/src/hocs/withMemberSummaryData';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import withBlueButton from '../../../../modules/BlueButton/withBlueButton';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { selectBiometricScreeningSummary } from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';
import AccountSwitcher from '../../common/AccountSwitcher';

const BlueButtonImg = require('../../../../images/BlueButton.png');

const styles = () => ({
  bbHolder: {
    textAlign: 'right',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    marginBottom: '16px',
    '& img': {
      height: '80px',
    },
  },
  test_card: {
    padding: '16px',
  },
  highlighted: {
    fontWeight: 'bold',
  },
});

const HealthRecord = ({
  classes,
  prescriptions,
  prescriptionCount,
  fullName,
  memberFields,
  hasNewPHR,
  age,
  startBlueButtonAuth,
  isAuthorizedForBlueButton,
  isBlueButtonEnabled,
  medications,
  screeningsAndProcedures,
  labs,
  conditions,
}) => (
  <PageSection>
    {hasNewPHR &&
      isBlueButtonEnabled &&
      !isAuthorizedForBlueButton &&
      _.get(age, 'value') >= 65 && (
        <div className={classes.bbHolder}>
          <button className={classes.button} onClick={startBlueButtonAuth}>
            <img src={BlueButtonImg} alt="Blue Button" title="Blue Button" />
          </button>
        </div>
      )}
    <AccountSwitcher />
    {hasNewPHR ? (
      <>
        {memberFields.length > 0 && (
          <MemberSummary fullName={fullName} memberFields={memberFields} />
        )}
        <ConditionList conditions={conditions} />
        <MedicationList medications={medications} />
        <LabList labs={labs} />
        <ScreeningProcedureList items={screeningsAndProcedures} />
      </>
    ) : (
      <PrescriptionList
        prescriptions={prescriptions}
        prescriptionCount={prescriptionCount}
      />
    )}
  </PageSection>
);

HealthRecord.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  prescriptions: PropTypes.array.isRequired,
  prescriptionCount: PropTypes.number.isRequired,
  memberFields: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      level: PropTypes.object,
      units: PropTypes.string,
      field: PropTypes.string.isRequired,
      recordedOn: PropTypes.string,
    })
  ),
  fullName: PropTypes.string,
  hasNewPHR: PropTypes.bool.isRequired,
  startBlueButtonAuth: PropTypes.func.isRequired,
  isAuthorizedForBlueButton: PropTypes.bool.isRequired,
  isBlueButtonEnabled: PropTypes.bool.isRequired,
  medications: PropTypes.array.isRequired,
  screeningsAndProcedures: PropTypes.array.isRequired,
  labs: PropTypes.array.isRequired,
  conditions: PropTypes.array.isRequired,
  age: PropTypes.number.isRequired,
};

export default compose(
  pure,
  WithHealthRecord,
  withBlueButton,
  withMemberSummaryData,
  withStyles(styles),
  connect((state, { firstName }) => ({
    conditions: _.get(state, 'conditions'),
    biometricScreenings: selectBiometricScreeningSummary(state),
    provider: _.get(state, ['demo', 'provider', firstName.toLowerCase()]),
  })),
  withProps(({ //age,
    height, weight, bmi, glucose, bloodPressure, cholesterol, blueButtonData, prescriptions, biometricScreenings, provider }) => {
    /**
     * Medications
     */
    const medications = _(_.get(blueButtonData, 'care.pharmacy', []))
      .orderBy('dateOfService', 'desc')
      .map(({ drugName, daysSupply, dateOfService, ndc }) => ({
        name: drugName,
        daysSupply,
        startDate: moment(dateOfService).format('MM/DD/YYYY'),
        drugStrength: '',
        ndc,
      }))
      .concat(prescriptions)
      .sortBy((med) => new Date(med))
      .uniqBy((med) => {
        let id = _.get(med, 'ndc') || _.get(med, 'name');
        return id;
      })
      .value();

    /**
     * Screenings and Procedures
     */
    const screenings =
      _.get(biometricScreenings, 'status') === 'COMPLETED'
        ? {
            date: _.get(biometricScreenings, 'appointment.endTime'),
            display: _.get(biometricScreenings, 'showAsPhysicianScreening')
              ? 'Physician Screening'
              : 'Biometric Screening',
          }
        : [];
    const inpatientProcedures = _.get(blueButtonData, 'care.inpatient');
    const outpatientProcedures = _.get(blueButtonData, 'care.outpatient');
    const screeningsAndProcedures = _(inpatientProcedures)
      .concat(outpatientProcedures)
      .filter((claim) => _.get(claim, 'procedures.length') > 0)
      .flatMap((claim) => _.get(claim, 'procedures'))
      .concat(screenings)
      .orderBy('date', 'desc')
      .value();

    /**
     * Labs
     */
    const labs = _.map(
      _.filter(
        blueButtonData.care.doctor,
        (claim) => _.get(claim, 'labs.length') > 0
      ),
      (claim) => _.pick(claim, ['diagnoses', 'labs'])
    );

    return {
      memberFields: _.filter(
        [
          /*age,*/ provider,
          height,
          weight,
          bmi,
          glucose,
          bloodPressure,
          cholesterol,
        ],
        (field) => !!field
      ),
      hasNewPHR: AppConfig.validateFeature(Features.NEW_PHR),
      medications,
      screeningsAndProcedures,
      labs,
    };
  })
)(HealthRecord);
