import _findIndex from 'lodash/findIndex';
import moment from 'moment';

export const types = {
  MEDICATIONS_UPDATE_SCHEDULE: 'medications/schedule/update',
  MEDICATIONS_UPDATE_HISTORY: 'medications/history/update',
};

const updateSchedule = (medication, schedule) => ({
  type: types.MEDICATIONS_UPDATE_SCHEDULE,
  payload: { medication, schedule },
});

const updateHistory = (medication, history) => ({
  type: types.MEDICATIONS_UPDATE_HISTORY,
  payload: { medication, history },
});

const toggleHistoryDate = (medication, date) => (dispatch, getState) => {
  const history = getState().demo.medications[medication].history;

  const currentDateIndex = _findIndex(history, (historyDate) =>
    historyDate.isSame(date, 'd')
  );

  if (currentDateIndex !== -1) {
    history.splice(currentDateIndex, 1);
  } else {
    history.push(moment(date));
  }

  return dispatch(updateHistory(medication, history));
};

export default { updateSchedule, toggleHistoryDate };
