import { types } from './actions';

const initialState = {
  LOVASTATIN: [
    {
      refillDate: '3/27/19',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
    {
      refillDate: '12/27/18',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
    {
      refillDate: '9/28/18',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
    {
      refillDate: '6/30/18',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
    {
      refillDate: '4/1/18',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
    {
      refillDate: '1/1/18',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
    {
      refillDate: '10/3/17',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
    {
      refillDate: '7/5/17',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
    {
      refillDate: '4/6/17',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
    {
      refillDate: '1/6/17',
      refillSupply: 90,
      refillName: 'Lovastatin (Altoprev)',
      refillPrescriber: 'Dr. Edmund Platt',
      refillAmount: '20mg',
    },
  ],
  QVAR: [
    {
      refillDate: '12/15/18',
      refillSupply: 90,
      refillName: 'Beclometasone dipropionate (Qvar)',
      refillPrescriber: 'Dr. Jack Rosenthal',
      refillAmount: '80mcg',
    },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_REFILL: {
      const { type, refill } = action.payload;

      return {
        ...state,
        [type]: [refill, ...state[type]],
      };
    }

    default:
      return state;
  }
};
