import { connect } from 'react-redux';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import NotConnectedBody from './NotConnectedBody';
import ConnectedBody from './ConnectedBody';

const deviceIsConnected = ({ connected }) => connected;

const mapStateToProps = (state) => {
  return {
    connected: state.deviceCredentials.publicToken,
  };
};

export default compose(
  connect(mapStateToProps),
  branch(
    deviceIsConnected,
    renderComponent(ConnectedBody),
    renderComponent(NotConnectedBody)
  )
)();
