import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Card from '../../../common/card/Card';
import classnames from 'classnames';

const styles = (theme) => ({
  customCard: {
    width: '237px',
    height: '200px',
    textAlign: 'center',
  },
  icon: {
    fontSize: '40px',
  },
  title: {
    marginTop: '10px',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '22px',
    marginBottom: '10px',
  },
  golden: {
    backgroundColor: theme.palette.common.whiskeySour,
    '&:hover': {
      backgroundColor: theme.utils.colorLighten(
        theme.palette.common.whiskeySour
      ),
    },
  },
});

const WhatsNextCard = ({
  classes,
  title,
  completedText,
  completedSubText,
  iconClass,
  incentiveText,
  goldenBackground,
  onClick,
  onSubTextClick,
}) => {
  return (
    <Card
      customClass={classnames(classes.customCard, {
        [classes.golden]: goldenBackground,
      })}
      primary
      onClick={onClick}
    >
      <i className={`${iconClass} ${classes.icon}`} />
      <div className={classes.title}>{_.truncate(title, { length: 60 })}</div>
      <div className={classes.completedText} onClick={onSubTextClick}>
        {completedText}
      </div>
      <div className={classes.completedSubText}>{completedSubText}</div>
      <div className={classes.incentiveAmount}>{incentiveText}</div>
    </Card>
  );
};

WhatsNextCard.propTypes = {
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  completedText: PropTypes.string,
  completedSubText: PropTypes.string,
  incentiveText: PropTypes.string,
  onCardClick: PropTypes.func,
  actionComplete: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onSubTextClick: PropTypes.func,
  goldenBackground: PropTypes.bool,
};

WhatsNextCard.defaultProps = {
  onSubTextClick: _.noop,
};

export default withStyles(styles)(WhatsNextCard);
