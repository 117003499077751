import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure, withProps } from 'recompose';
import { Button, Grid, Modal, withStyles } from '@material-ui/core';
import Typography from '../common/Typography';
import { withDrawer } from '../common/drawer2';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { get } from 'lodash';

const styles = (theme) => ({
  modalRoot: {
    height: '100%',
    display: 'flex',
  },
  root: {
    margin: 'auto',
    backgroundColor: theme.palette.common.beefy,
    backgroundImage: `url(${require('../../../images/background/welcome.png')})`,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    borderRadius: theme.spacing.unit,
    width: theme.spacing.unit * 96,
    minHeight: theme.spacing.unit * 54,
    padding: theme.spacing.unit * 4,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
  },
  section: {
    margin: '20px 0',
  },
  button: {
    margin: '20px 0',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.beefy,
    padding: '10px 140px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.beefy,
    },
  },
  link: {
    cursor: 'pointer',
  },
});

const WelcomeModal = ({
  classes,
  name,
  open,
  onClose,
  isOptional,
  welcomeBodyText,
  welcomeButtonHelpText,
  welcomeButtonText,
  welcomeDisclaimer,
}) => (
  <Modal open={open} onClose={onClose} disableBackdropClick>
    <div className={classes.modalRoot}>
      <Grid container className={classes.root}>
        <Grid item xs={6}>
          <div>
            <div className={classes.section}>
              <Typography type="h1">Welcome {name}!</Typography>
            </div>
            <div className={classes.section}>
              <Typography type="h2">{welcomeBodyText}</Typography>
            </div>
            <div className={classes.section}>
              <Typography type="body">{welcomeButtonHelpText}</Typography>
            </div>
            <div className={classes.section}>
              <Button
                variant="flat"
                className={classes.button}
                onClick={() => onClose()}
              >
                {welcomeButtonText}
              </Button>
            </div>
            {welcomeDisclaimer && (
              <div className={classes.section}>
                <p style={{ fontSize: '16px' }}>{welcomeDisclaimer}</p>
              </div>
            )}
            {isOptional && (
              <Typography
                type="h4"
                className={classes.link}
                onClick={() => onClose(true)}
              >
                Skip
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  </Modal>
);

WelcomeModal.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isOptional: PropTypes.bool,
  welcomeBodyText: PropTypes.string.isRequired,
  welcomeButtonHelpText: PropTypes.string.isRequired,
  welcomeButtonText: PropTypes.string.isRequired,
  welcomeDisclaimer: PropTypes.string,
};

export default compose(
  pure,
  withStyles(styles),
  connect(({ userInfo }) => ({ name: userInfo.firstName })),
  withDrawer,
  withProps(() => {
    const { lingo } = AppConfig.effectiveConfig;
    const welcomeBodyText = get(lingo, 'welcomeModalBodyText', '');
    const welcomeButtonHelpText = get(lingo, 'welcomeModalButtonHelpText', '');
    const welcomeButtonText = get(lingo, 'welcomeModalButtonText', '');
    const welcomeDisclaimer = get(lingo, 'welcomeModalDisclaimer', '');
    return {
      welcomeBodyText,
      welcomeButtonHelpText,
      welcomeButtonText,
      welcomeDisclaimer,
    };
  })
)(WelcomeModal);
