import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { ReactHeight } from 'react-height';
import { CarePlanActionItem } from '@healthmine/greyhound-core/src/constants/prop-types';
import Styles from './ActionItem.styles';

const ActionItemMarkup = ({
  actionItem,
  actionItemIndex,
  areDetailsVisible,
  detailHeight,
  toggleDetails,
  setDetailHeight,
  uhcIcon,
}) => (
  <div
    style={[
      Styles.action,

      areDetailsVisible
        ? {
            ...Styles.actionWithVisibleDetails,
            marginBottom: `${detailHeight + 80}px`,
          }
        : Styles.actionWithHiddenDetails,

      (actionItemIndex + 1) % 3 === 0 ? Styles.lastActionInRow : null,
    ]}
  >
    <div style={Styles.actionInner}>
      <div style={Styles.headerRoot}>
        <div
          style={
            uhcIcon
              ? { ...Styles.iconBubbleBase, ...Styles.uhcBrandedIconBubble }
              : { ...Styles.iconBubbleBase, ...Styles.iconBubbleBackground }
          }
        >
          {uhcIcon ? (
            <img src={uhcIcon} />
          ) : (
            <i className={actionItem.iconClass} style={Styles.icon} />
          )}
          {actionItem.completed && (
            <div style={Styles.completed}>
              <i className="material-icons" style={Styles.completedIcon}>
                done
              </i>
            </div>
          )}
        </div>

        <div style={Styles.content}>
          <div style={Styles.title}>
            {actionItem.completed ? (
              <span
                style={[Styles.titleSuperscript, Styles.completedSuperscript]}
              >
                COMPLETED on {moment(actionItem.completionDate).format('L')}
              </span>
            ) : actionItem.dueDate ? (
              <span style={[Styles.titleSuperscript, Styles.dueSuperscript]}>
                REQUIRED by {moment(actionItem.dueDate).format('L')}
              </span>
            ) : null}
            <span>{actionItem.name}</span>

            {actionItem.requiredCompletionCount > 1 ? (
              <span style={Styles.titleSubscript}>
                {`( ${_.min([
                  actionItem.completionCount,
                  actionItem.requiredCompletionCount,
                ])} of ${actionItem.requiredCompletionCount} completed )`}
              </span>
            ) : null}
          </div>
        </div>
      </div>

      <div style={Styles.actionBody}>
        {actionItem.measurementLabel &&
        actionItem.measurementGoal &&
        actionItem.measuredValue ? (
          <div style={Styles.measurement}>
            <div style={Styles.measurementTitle}>
              {actionItem.measurementLabel}
            </div>
            <div style={Styles.measurementBody}>
              <div style={Styles.valueContainer}>
                <div style={Styles.measurementBodyTitle}>Result:</div>
                <div style={Styles.valueDisplay}>
                  {actionItem.measuredValue}
                </div>
              </div>
              <div style={Styles.rangeContainer}>
                <div style={Styles.measurementBodyTitle}>Healthy Range:</div>
                <div style={Styles.rangeDisplay}>
                  {actionItem.measurementGoal}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={Styles.description}>{actionItem.shortDescription}</div>
        )}
      </div>

      <div style={Styles.bottom}>
        <div style={Styles.linkWrapper} onClick={toggleDetails}>
          <span style={Styles.link}>
            {areDetailsVisible ? 'Hide Details' : 'View Details'}
          </span>
          <i
            style={Styles.detailsIcon}
            className={areDetailsVisible ? 'caret up' : 'caret'}
          />
        </div>
      </div>

      <div
        style={[
          Styles.detailsContent,

          {
            left: `-${(actionItemIndex % 3) * 300}px`,
            right: `${(actionItemIndex % 3) * 300 - 600}px`,
          },

          areDetailsVisible
            ? {
                ...Styles.detailsContentActive,
                minHeight: `${detailHeight}px`,
                bottom: `-${detailHeight + 80}px`,
              }
            : null,
        ]}
      >
        <ReactHeight onHeightReady={setDetailHeight}>
          {/* eslint-disable react/no-danger */}
          <div
            dangerouslySetInnerHTML={{ __html: actionItem.longDescription }}
          />
          {/* eslint-enable react/no-danger */}
        </ReactHeight>
      </div>
    </div>
  </div>
);

ActionItemMarkup.propTypes = {
  actionItem: CarePlanActionItem.isRequired,
  detailHeight: PropTypes.number.isRequired,
  actionItemIndex: PropTypes.number.isRequired,
  areDetailsVisible: PropTypes.bool.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  setDetailHeight: PropTypes.func.isRequired,
  uhcIcon: PropTypes.string.isRequired,
};

export default Radium(ActionItemMarkup);
