import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import AutomatedRewardCancellationMarkup from './AutomatedRewardCancellationMarkup';

class AutomatedRewardCancellation extends React.Component {
  render() {
    return (
      <AutomatedRewardCancellationMarkup
        product={this.props.automatedReward}
        isOpen={this.props.modalOpen}
        closeHandler={this.props.toggleProduct}
        deactivateReward={this._deactivateAutomatedRewards}
      />
    );
  }

  _deactivateAutomatedRewards = () => {
    this.props.actions
      .deleteRewardsPreference(this.props.preferenceId)
      .then(() => {
        this.props.actions.getRewardsPreference();
      });
    this.props.toggleProduct();
  };
}

AutomatedRewardCancellation.propTypes = {
  modalOpen: PropTypes.bool,
  automatedReward: PropTypes.object,
  toggleProduct: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  preferenceId: PropTypes.number,
};

function mapStateToProps(state) {
  const { preferenceId } = state.rewards.preference;

  return {
    preferenceId,
  };
}

function mapDispatchToProps(dispatch) {
  const { deleteRewardsPreference, getRewardsPreference } = RewardsActions;

  return {
    actions: bindActionCreators(
      { deleteRewardsPreference, getRewardsPreference },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AutomatedRewardCancellation
);
