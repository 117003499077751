import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import { withStyles, Grid } from '@material-ui/core';
import { commaFormat } from '@healthmine/greyhound-core/src/utils';
import {
  CoverageSelectors,
  IncentiveSelectors,
} from '@healthmine/greyhound-core/src/selectors';
import withLinks from './withLinks';
import Title from '../common/Title';
import Chip from '../common/Chip';
import AccountProfileDrawerContent from '../common/AccountProfileDrawerContent';
import { DrawerContent, DrawerSection, withDrawer } from '../common/drawer2';

const styles = (theme) => ({
  root: {},
  subtitle: {
    color: theme.palette.secondary.main,
  },
  incentivesIcon: {
    color: theme.palette.common.whiskeySour,
  },
  active: {
    color: theme.palette.common.beefy,
  },
});

const NavigationDrawer = ({
  classes,
  drawer,
  links,
  isLinkActive,
  navigate,
  availablePoints,
  userInfo: { firstName },
  selectedMemberCoverage: { coverageName },
  incentiveSummary: { totalPoints },
}) => {
  return (
    <DrawerContent className={classes.root}>
      <DrawerSection onClick={() => drawer.open(AccountProfileDrawerContent)}>
        <Title
          title={firstName}
          subtitle={coverageName}
          iconClass={'icon-User-Circle'}
          subtitleClass={classes.subtitle}
        />
      </DrawerSection>
      <DrawerSection
        className={classnames({
          [classes.active]: isLinkActive('/sweepstakes'),
        })}
        onClick={() => navigate('/sweepstakes')}
      >
        <Title
          title={`${commaFormat(availablePoints)} pts available`}
          subtitle={`${commaFormat(totalPoints)} pts earned`}
          iconClass={classnames('icon-Star-Circle', classes.incentivesIcon)}
          subtitleClass={classes.subtitle}
        />
      </DrawerSection>
      {links.map(({ title, subtitle, iconClass, route, badgeNumber }, i) => (
        <DrawerSection
          key={i}
          className={classnames({
            [classes.active]: isLinkActive(route),
          })}
          onClick={() => navigate(route)}
        >
          {badgeNumber ? (
            <Grid container alignItems="center" justify="space-between">
              <Grid item xs={8}>
                <Title
                  title={title}
                  subtitle={subtitle}
                  iconClass={iconClass}
                />
              </Grid>
              <Grid item>
                <Chip
                  label={badgeNumber > 99 ? '99+' : badgeNumber}
                  color="grannySmith"
                />
              </Grid>
            </Grid>
          ) : (
            <Title title={title} subtitle={subtitle} iconClass={iconClass} />
          )}
        </DrawerSection>
      ))}
    </DrawerContent>
  );
};

NavigationDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  selectedMemberCoverage: PropTypes.object.isRequired,
  availablePoints: PropTypes.number.isRequired,
  incentiveSummary: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isLinkActive: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      iconClass: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default compose(
  pure,
  connect((state) => ({
    availablePoints: IncentiveSelectors.selectAvailablePoints(state),
    selectedMemberCoverage: CoverageSelectors.selectCurrentCoverage(state),
    incentiveSummary: IncentiveSelectors.selectCurrentIncentiveSummary(state),
    userInfo: state.userInfo,
  })),
  withRouter,
  withDrawer,
  withLinks,
  withHandlers({
    navigate: ({ drawer, router }) => (route) => {
      drawer.close();
      router.push(route);
    },
  }),
  withStyles(styles)
)(NavigationDrawer);
