import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Scroll from 'react-scroll';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import { StepStatus } from '@healthmine/greyhound-core/src/constants/CarePlan';
import StepIcon from './StepIcon';
import StepSummary from './StepSummary';
import StepNavigationMarkup from './StepNavigationMarkup';
import ProgressLineMarkup from '../ProgressLineMarkup';

const ScrollElement = Scroll.Element;

const stepAnimation = {
  overflow: 'hidden',
  transition: 'max-height 2s ease, opacity 0.5s ease-in',
  maxHeight: '0px',
  opacity: 0,
};

const stepExpand = {
  opacity: 1,
  maxHeight: '800px',
  transition: 'max-height 2s ease, opacity 0.5s ease-out',
};

const Step = ({
  children,
  title,
  summaryLines,
  iconClass,
  color,
  last,
  required,
  active,
  status,
  valid,
  configured,
  connected,
  back,
  complete,
  skip,
  scrollId,
}) => {
  const styles = {
    title: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '200px',
    },
    titleText: {
      display: 'inline-block',
      fontSize: '20px',
      textAlign: 'center',
      marginTop: '0px',
      marginBottom: 0,
      color:
        active || status === StepStatus.COMPLETED
          ? Colors.black
          : Colors.contentText,
      ...GlobalStyles.extraLight,
    },
  };

  return (
    <ScrollElement name={scrollId}>
      <div style={styles.title}>
        <StepIcon
          iconClass={iconClass}
          connected={connected}
          status={active ? StepStatus.ACTIVE : status}
          required={required}
          configured={configured}
          color={color}
        />
        <div>
          <h1 style={styles.titleText}>{title}</h1>
          <StepSummary
            active={!active && status === StepStatus.COMPLETED}
            required
            summaryLines={summaryLines}
          />
        </div>
      </div>

      <div style={[stepAnimation, active ? stepExpand : {}]}>
        {children}
        <StepNavigationMarkup
          scrollUp={back}
          scrollDown={complete}
          isNextEnabled={valid}
          skip={!required && !configured ? skip : null}
        />
      </div>
      {last ? null : (
        <ProgressLineMarkup
          success={!active && status === StepStatus.COMPLETED}
        />
      )}
    </ScrollElement>
  );
};

Step.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  summaryLines: PropTypes.arrayOf(PropTypes.string).isRequired,
  iconClass: PropTypes.string.isRequired,
  color: PropTypes.string,
  active: PropTypes.bool.isRequired,
  status: PropTypes.oneOf([
    StepStatus.SKIPPED,
    StepStatus.COMPLETED,
    StepStatus.TODO,
  ]),
  last: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  configured: PropTypes.bool,
  connected: PropTypes.bool,
  valid: PropTypes.bool.isRequired,
  scrollId: PropTypes.string.isRequired,

  back: PropTypes.func,
  complete: PropTypes.func,
  skip: PropTypes.func,

  nextStep: PropTypes.func.isRequired,
  skipStep: PropTypes.func,
  previousStep: PropTypes.func.isRequired,
};

export default compose(
  withState('status', 'setStatus', StepStatus.TODO),
  withHandlers({
    complete: ({ setStatus, nextStep }) => () => {
      setStatus(StepStatus.COMPLETED);
      nextStep();
    },
    skip: ({ setStatus, skipStep, nextStep }) => () => {
      setStatus(StepStatus.SKIPPED);
      if (skipStep) {
        skipStep();
      } else {
        nextStep();
      }
    },
    back: ({ setStatus, previousStep }) => () => {
      setStatus(StepStatus.TODO);
      previousStep();
    },
  }),
  Radium
)(Step);
