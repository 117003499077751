import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import MapMarker from './MapMarker';
import GoogleMap from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import { min, max } from 'lodash';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';

const Map = ({
  mapKey,
  items,
  activeLat,
  activeLng,
  onHover,
  onLeave,
  onClick,
}) => {
  const lats = items.map((item) => item.latitude);

  const lngs = items.map((item) => item.longitude);

  const minLat = min(lats);

  const minLng = min(lngs);

  const maxLat = max(lats);

  const maxLng = max(lngs);

  const size = {
    width: 400,
    height: 480,
  };

  const nwBound = {
    lat: maxLat,
    lng: minLng,
  };

  const seBound = {
    lat: minLat,
    lng: maxLng,
  };

  const bounds = {
    nw: nwBound,
    se: seBound,
  };

  const defaultProps = {
    center: { lat: 39.8282, lng: -98.5795 }, //Middle of U.S.A.
    zoom: 9,
  };

  const { center, zoom } =
    items && items.length
      ? minLat === maxLat && minLng == maxLng
        ? {
            center: { lat: minLat, lng: minLng },
            zoom: 12,
          }
        : fitBounds(bounds, size)
      : defaultProps;

  const key = `${zoom}-${parseInt(center.lat)}-${parseInt(center.lng)}`;

  return (
    <GoogleMap
      key={key}
      center={center}
      defaultZoom={zoom}
      bootstrapURLKeys={{ key: AppConfig.effectiveConfig.googleApiKey }}
    >
      {items.map((item, index) => (
        <MapMarker
          lat={item.latitude}
          lng={item.longitude}
          key={`${item.id}-${mapKey}`}
          value={index}
          item={item}
          activeLat={activeLat}
          activeLng={activeLng}
          onHover={() => onHover(null, item.latitude, item.longitude)}
          onLeave={onLeave}
          onClick={onClick}
        />
      ))}
    </GoogleMap>
  );
};

Map.propTypes = {
  mapKey: PropTypes.string.isRequired,
  items: PropTypes.array,
  activeLat: PropTypes.number,
  activeLng: PropTypes.number,
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
  onClick: PropTypes.func,
};

export default Radium(Map);
