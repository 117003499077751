import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Typography from '../../common/Typography';

const styles = (theme) => ({
  editButton: {
    cursor: 'pointer',
    userSelect: 'none',
    color: theme.palette.common.blueGray,
  },
});

const ItemEdit = ({
  editing,
  deleteItem,
  updateItem,
  editItem,
  classes,
  hideEdit,
}) => {
  return (
    <div className="print-hidden">
      {editing ? (
        <Grid container alignItems="center" spacing={16}>
          <Grid item>
            <a onClick={updateItem} className={classes.editButton}>
              <Grid container alignItems="center" spacing={8}>
                <Grid item>
                  <Typography>Done</Typography>
                </Grid>
                <Grid item>
                  <i className="icon-Checkmark" />
                </Grid>
              </Grid>
            </a>
          </Grid>
          <Grid item>
            <div>|</div>
          </Grid>
          <Grid item>
            <a onClick={deleteItem} className={classes.editButton}>
              <Grid container alignItems="center" spacing={8}>
                <Grid item>
                  <Typography>Delete</Typography>
                </Grid>
                <Grid item>
                  <i className="icon-Trash" />
                </Grid>
              </Grid>
            </a>
          </Grid>
        </Grid>
      ) : (
        !hideEdit && (
          <a onClick={editItem} className={classes.editButton}>
            <Grid container alignItems="center" spacing={8}>
              <Grid item>
                <Typography>Edit</Typography>
              </Grid>
              <Grid item>
                <i className="icon-Pencil" />
              </Grid>
            </Grid>
          </a>
        )
      )}
    </div>
  );
};

ItemEdit.propTypes = {
  editing: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  deleteItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  hideEdit: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ItemEdit);
