import { get } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { Hidden, withStyles } from '@material-ui/core';
import Impersonation from '../../components/Impersonation';
import Header from './header/Header';
import Navigation from './navigation/Navigation';
import Welcome from './introduction/Welcome';

const styles = () => ({
  root: {
    display: 'flex',
    marginTop: '80px',
    padding: '40px',
    flexGrow: '1',
    '@media screen and (min-width: 1625px)': {
      maxWidth: '1225px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  innerContainer: {
    maxWidth: '1000px',
    flexGrow: 1,
  },
});

const Authentication = ({
  children,
  classes,
  location,
  username,
  impersonationEmail,
}) => (
  <div>
    <Impersonation
      username={username}
      impersonationEmail={impersonationEmail}
    />
    <Header location={location} />
    <div className={classes.root}>
      <Hidden smDown>
        <Navigation location={location} />
      </Hidden>
      <div className={classes.innerContainer}>{children}</div>
    </div>
    <Welcome />
  </div>
);

Authentication.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  impersonationEmail: PropTypes.string,
};

export default compose(
  connect((state) => {
    const firstName = get('userInfo.firstName', state);
    const lastName = get('userInfo.lastName', state);
    const impersonationEmail = get('signIn.impersonationEmail', state);

    return {
      username: `${firstName} ${lastName}`,
      impersonationEmail,
    };
  }),
  pure,
  withStyles(styles)
)(Authentication);
