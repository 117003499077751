import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = {
  root: {
    padding: '3px',
    cursor: 'pointer',
    display: 'inline-block',
  },
  underline: {
    textDecoration: 'underline',
  },
};

const Clickable = ({ classes, className, onClick, children, underline }) => {
  return (
    <a
      className={classnames(
        classes.root,
        { [classes.underline]: underline },
        className
      )}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

Clickable.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  underline: PropTypes.bool,
};

export default withStyles(styles)(Clickable);
