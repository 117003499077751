import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../common/Typography';
import GaugeWidget from '../../../common/widgets/GaugeWidget';
import {
  DrawerContent,
  DrawerSection,
  DrawerSelect,
} from '../../../common/drawer2';
const styles = () => ({});
import { WithBiometricReportSelection } from '@healthmine/greyhound-core/src/hocs';
import _ from 'lodash';
import moment from 'moment';
import { registerHealthOverviewDrawerRoute } from '../../../common/drawer2/DrawerRouteMapping';
import BiometricScreeningConstants from '@healthmine/greyhound-core/src/constants/BiometricScreening';
const { MeasurementRiskLevel } = BiometricScreeningConstants;

const ranges = {
  [MeasurementRiskLevel.HIGH]: {
    value: 16.7,
    label: 'High',
    color: 'cosmopolitan',
  },
  [MeasurementRiskLevel.MODERATE]: {
    value: 50,
    label: 'At Risk',
    color: 'mimosa',
  },
  [MeasurementRiskLevel.LOW]: {
    value: 83.3,
    label: 'In Range',
    color: 'grannySmith',
  },
};
const biometricResultsDrawerId = 'biometric-results-drawer';
const printResults = () => {
  const mywindow = window.open('', 'PRINT', 'height=800,width=1200');
  mywindow.document.write(
    Array.prototype.slice
      .call(document.getElementsByTagName('style'))
      .map((styleTag) => styleTag.outerHTML)
      .join('')
  );
  mywindow.document.write(
    '<style> div[class^="withProps-DrawerSection--root"] {max-height: 200px !important; overflow: unset;} @page { margin: 0; } </style>',
    // Get rid of extra white pages when printing
    '<style> html,body {height:5%;}</style>'
  );
  mywindow.document.write(`<h1 align='center'>${document.title}</h1>`);
  mywindow.document.write(
    document.getElementById(biometricResultsDrawerId).innerHTML
  );

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.print();
  mywindow.close();
};

const chartGridCellStyles = {
  height: '140px',
};

const printHeaderAction = {
  color: 'gray',
  onClick: printResults,
  label: <span style={{ fontSize: '25px' }} className="icon-Printer" />,
};
const getResultString = (measure) =>
  measure ? `${measure.result} ${measure.unit}` : '';

const BiometricResultsDrawer = ({
  userInfo,
  reportOptions,
  selectedReport,
  selectedReportIndex,
  onReportSelected,
}) => {
  const age = moment().diff(userInfo.dateOfBirth, 'years');
  return (
    <DrawerContent
      id={biometricResultsDrawerId}
      title="Biometric Screening"
      headerAction={printHeaderAction}
    >
      <DrawerSelect
        options={reportOptions}
        value={selectedReportIndex}
        onChange={(index) => onReportSelected(index)}
        subLabel="Screening Date"
      />
      <DrawerSection>
        <Typography type="h3Medium">
          {userInfo.firstName} {userInfo.lastName}
        </Typography>
      </DrawerSection>
      <DrawerSection>
        <Grid container spacing={16}>
          <Grid item xs={6} sm={4}>
            <Typography>
              <strong>Age: </strong>
              {age}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography>
              <strong>Height: </strong>
              {getResultString(selectedReport.height)}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography>
              <strong>Patient Code: </strong>
              {userInfo.clientMemberId}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography>
              <strong>Gender: </strong>
              {userInfo.gender}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography>
              <strong>Weight: </strong>
              {getResultString(selectedReport.weight)}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography>
              <strong>Fasted: </strong>
              {selectedReport.fasted ? 'Yes' : 'No'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography>
              <strong>Drawn Date: </strong>
              {moment.utc(selectedReport.serviceDate).format('L')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography>
              <strong>Report Date: </strong>
              {moment.utc(selectedReport.createdDate).format('L')}
            </Typography>
          </Grid>
        </Grid>
      </DrawerSection>
      {_.map(selectedReport.results, (result) => {
        const range = ranges[result.riskLevel];

        return (
          <DrawerSection key={result.cptCode}>
            <Grid container justify="space-between">
              <Grid item xs={6} sm={4}>
                <Typography type="h3">{result.description}</Typography>
                <Typography type="h5" color={range.color}>
                  {range.label}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} style={chartGridCellStyles}>
                <GaugeWidget
                  width={150}
                  height={140}
                  percent={range.value}
                  label={getResultString(result)}
                  animateGauge={false}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography color="blueGray">
                  {_.get(result, 'screeningDescription')}
                </Typography>
              </Grid>
            </Grid>
          </DrawerSection>
        );
      })}
    </DrawerContent>
  );
};

BiometricResultsDrawer.defaultProps = {
  selectedReport: {
    results: [],
  },
};

BiometricResultsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  reportOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  selectedReport: PropTypes.object,
  selectedReportIndex: PropTypes.number,
  onReportSelected: PropTypes.func.isRequired,
};

const ComposedBiometricResultsDrawer = compose(
  pure,
  withStyles(styles),
  WithBiometricReportSelection
)(BiometricResultsDrawer);

registerHealthOverviewDrawerRoute('biometric-screening/reports', {
  component: ComposedBiometricResultsDrawer,
  isLarge: true,
});

export default ComposedBiometricResultsDrawer;
