import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Typography from './Typography';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import _startsWith from 'lodash/startsWith';

const Link = (link) => (
  <a
    href={link.href}
    target={_startsWith(link.href, '/#/') ? '_self' : '_blank'}
  >
    {link.children}
  </a>
);

const MarkdownDescription = ({ description, type }) => (
  <Typography type={type}>
    <ReactMarkdown
      source={AppConfig.replaceS3(description.replace(/\\n/g, '\n'))}
      renderers={{ link: Link }}
    />
  </Typography>
);

MarkdownDescription.propTypes = {
  description: PropTypes.string.isRequired,
  type: PropTypes.string,
};

MarkdownDescription.defaultProps = {
  type: 'body',
};

export default MarkdownDescription;
