import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Modal from 'react-modal';
import { hashHistory } from 'react-router';
import _ from 'lodash';

const DetailsMarkup = ({ prod, winners }) => {
  const imageSrc = `${prod.ImageUrl}`;

  const styles = {
    root: {
      paddingTop: '20px',
      paddingBottom: '40px',
    },
    customStyles: {
      overlay: {
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
        overflow: 'auto',
      },
      content: {
        minWidth: '500px',
        minHeight: '200px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '10%',
        transform: 'translate(-50%, 0)',
        border: 'none',
        background: '#fff',
        borderRadius: '15px',
        outline: 'none',
        padding: '50px',
      },
    },

    closeButton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '82px',
      paddingRight: '82px',
    },

    header: {
      paddingBottom: '20px',
    },

    backLink: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.linkBlue,
      textDecoration: 'none',
      ':hover': {
        color: Colors.dark,
      },
      ':active': {
        color: Colors.dark,
      },
    },

    info: {
      display: 'flex',
      padding: '15px 0',
    },

    image: {
      height: '75px',
    },

    details: {
      paddingLeft: '15px',
      fontWeight: 500,
    },

    title: {
      color: Colors.black,
      fontSize: '16px',
    },

    desc: {
      color: Colors.contentText,
      fontSize: '16px',
      margin: '5px 0',
    },

    winnerLabel: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.mediumGray2,
      paddingTop: '20px',
      paddingBottom: '20px',
    },

    winnerList: {
      display: 'flex',
      flexWrap: 'wrap',
    },

    winnerEntry: {
      flex: '1 1 25%',
      maxWidth: '25%',
      color: Colors.contentText,
      fontSize: '16px',
      padding: '10px 0',
    },
  };

  return (
    <Modal
      isOpen
      onRequestClose={() => hashHistory.goBack()}
      contentLabel={''}
      style={styles.customStyles}
    >
      <a onClick={() => hashHistory.goBack()} style={styles.closeButton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>
      <div style={styles.info}>
        <img
          src={imageSrc}
          style={styles.image}
          alt={`${prod.sweepstakeName} Card`}
          title={`${prod.sweepstakeName} Card`}
        />
        <div style={styles.details}>
          <label style={styles.title}>{prod.sweepstakeName}</label>
          <p style={styles.desc}>{prod.description}</p>
        </div>
      </div>

      <div style={styles.winnerLabel}>Winners</div>
      {winners ? (
        <div style={styles.winnerList}>
          {_.map(winners.sort(), (winner, index) => (
            <span key={index} style={styles.winnerEntry}>
              {winner}
            </span>
          ))}
        </div>
      ) : (
        <div style={styles.winnerList}>
          <span style={styles.winnerEntry}>No Winners</span>
        </div>
      )}
    </Modal>
  );
};

DetailsMarkup.propTypes = {
  prod: PropTypes.object.isRequired,
  winners: PropTypes.object.isRequired,
};

export default Radium(DetailsMarkup);
