import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';

const styles = (theme) => ({
  group: {
    marginTop: '20px',
  },
  title: {
    color: theme.palette.common.blueGray,
    marginBottom: '3px',
  },
  lines: {
    borderTop: `2px dashed ${theme.palette.common.blueGray}`,
    borderBottom: `2px dashed ${theme.palette.common.blueGray}`,
    height: '46px',
  },
});

const MyNotes = ({ title, classes, printOnly }) => {
  return (
    <div className={classnames(classes.group, { 'print-only': printOnly })}>
      <div className={classes.title}>{title}</div>
      <div className={classes.lines} />
    </div>
  );
};

export default withStyles(styles)(MyNotes);

MyNotes.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  printOnly: PropTypes.bool,
};

MyNotes.defaultProps = {
  title: 'My notes:',
  printOnly: true,
};
