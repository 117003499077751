import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import { LightBulb, Lock } from '../../styles/uhc-icons';

const ProfileMenuMarkup = ({ onLogout }) => {
  const styles = {
    menuHeader: {
      margin: '0px 15px',
      paddingLeft: '10px',
      paddingBottom: '20px',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.bodyText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      paddingTop: '15px',
    },

    menuOptionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '10px',
    },

    menuOptionContainer: {
      ':hover': {
        backgroundColor: Colors.gutterGray,
      },
    },

    menuOption: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '16px',
      textDecoration: 'none',
      padding: '8px 35px',
      cursor: 'pointer',
    },

    menuOptionIcon: {
      marginLeft: '-10px',
      marginRight: '10px',
      fontSize: '110%',
    },

    menuOptionUhcIcon: {
      marginLeft: '-10px',
      marginRight: '10px',
      width: '30px',
    },
  };

  const showIdCard = AppConfig.validateFeature(Features.ID_CARD);

  const usesConnectedDevices = !AppConfig.validateFeature(
    Features.NO_CONNECTED_DEVICES
  );

  const uhcBranded = AppConfig.validateFeature(Features.UHC_BRANDED);

  return (
    <div>
      <div style={styles.menuHeader}>Profile</div>

      <div style={styles.menuOptionsContainer}>
        <div key="AccountLink" style={styles.menuOptionContainer}>
          <Link to="/settings/account" style={styles.menuOption}>
            {uhcBranded ? (
              <img src={LightBulb} style={styles.menuOptionUhcIcon} />
            ) : (
              <i
                className="thin-0701_user_profile_avatar_man_male"
                style={styles.menuOptionIcon}
              />
            )}
            Account
          </Link>
        </div>

        {usesConnectedDevices && (
          <div key="DevicesLink" style={styles.menuOptionContainer}>
            <Link to="/settings/devices" style={styles.menuOption}>
              <i
                className="thin-0260_link_url_chain_hyperlink"
                style={styles.menuOptionIcon}
              />
              Devices
            </Link>
          </div>
        )}

        {showIdCard && (
          <div key="IDCardLink" style={styles.menuOptionContainer}>
            <Link to="/id/" style={styles.menuOption}>
              <i
                className="thin-0715_contact_archive_identity_card_photo_user_profile"
                style={styles.menuOptionIcon}
              />
              My I.D.
            </Link>
          </div>
        )}

        {!AppConfig.validateFeature(Features.NO_PRESCRIPTION) && (
          <div key="PrescriptionsLink" style={styles.menuOptionContainer}>
            <Link to="/prescriptions/" style={styles.menuOption}>
              <i
                className="thin-0820_medicine_drugs_ill_pill"
                style={styles.menuOptionIcon}
              />
              Prescriptions
            </Link>
          </div>
        )}

        {AppConfig.validateFeature(Features.LOGOUT) && (
          <div key="LogoutLink" style={styles.menuOptionContainer}>
            <Link onClick={onLogout} style={styles.menuOption}>
              {uhcBranded ? (
                <img src={Lock} style={styles.menuOptionUhcIcon} />
              ) : (
                <i
                  className="thin-0040_exit_logout_door_emergency_outside"
                  style={styles.menuOptionIcon}
                />
              )}
              Logout
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

ProfileMenuMarkup.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default Radium(ProfileMenuMarkup);
