import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import {
  DrawerButton,
  DrawerSection,
  WarningDrawerContent,
  withDrawer,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';
import { Grid } from '@material-ui/core';

const HraLeave = ({ drawer }) => {
  return (
    <WarningDrawerContent>
      <DrawerSection grow noBorder>
        <Grid container direction="column" spacing={40} alignItems="center">
          <Grid item>
            <Typography type="h1">
              <i className="icon-Sad-Face" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography type="h1">Leave Health Risk Assessment</Typography>
          </Grid>
          <Grid item>
            <Typography type="h2">
              By pressing “Confirm” you are leaving the health risk assessment.
              If you are sure this is what you want to do, then press “Confirm”
              and your progress will be saved so you can finish later. Otherwise
              you can select “Nevermind” in the top left corner.
            </Typography>
          </Grid>
        </Grid>
      </DrawerSection>
      <DrawerButton text="Confirm" onClick={() => drawer.close()} />
    </WarningDrawerContent>
  );
};

HraLeave.propTypes = {
  drawer: PropTypes.object.isRequired,
};

export default compose(pure, withDrawer)(HraLeave);
