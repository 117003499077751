import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Title from './Title';

const statusIcons = {
  positive: 'icon-Checkmark',
  neutral: 'icon-Checkmark',
  negative: 'icon-X',
};

const styles = (theme) => ({
  icon: {
    fontSize: '30px',
  },
  positive: {
    color: theme.palette.common.grannySmith,
  },
  neutral: {
    color: theme.palette.secondary.main,
  },
  negative: {
    color: theme.palette.common.cosmopolitan,
  },
  neutralIcon: {
    color: theme.utils.colorLighten(theme.palette.secondary.main, 0.4),
  },
  incentivesToEarn: {
    color: theme.palette.common.whiskeySour,
  },
});

const StatusTitle = ({
  iconClass,
  title,
  subtitle,
  titleClass,
  subtitleClass,
}) => {
  return (
    <Title
      iconClass={iconClass}
      title={title}
      subtitle={subtitle}
      titleClass={titleClass}
      subtitleClass={subtitleClass}
    />
  );
};

StatusTitle.propTypes = {
  status: PropTypes.oneOf(['positive', 'neutral', 'negative']).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  subtitleClass: PropTypes.string,
  incentivesToEarn: PropTypes.bool,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(
    ({ classes, status, subtitleClass, titleClass, incentivesToEarn }) => {
      const icon = _.get(statusIcons, status, '');
      return {
        iconClass: classnames(
          icon,
          classes[status],
          { [classes.neutralIcon]: status === 'neutral' },
          classes.icon
        ),
        titleClass,
        subtitleClass: classnames(
          classes[status],
          { [classes.incentivesToEarn]: incentivesToEarn },
          subtitleClass
        ),
      };
    }
  )
)(StatusTitle);
