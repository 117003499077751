import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import {
  GlobalStyles,
  Colors,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import BubbleTitleMarkup from '../common/BubbleTitleMarkup';
import { hashHistory } from 'react-router';
import IncentiveSummary from './incentives/IncentiveSummary';
import OffsetCloseButton from '../common/OffsetCloseButton';

const PreventiveHeaderMarkup = ({
  carePlan,
  goalStatement,
  measure,
  selectedAlert,
  pointsOpen,
  togglePoints,
}) => {
  const styles = {
    root: {
      display: 'flex',
      borderTop: `1px solid ${Colors.gutterGray}`,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      position: 'relative',
    },
    section: {
      flex: '0.3334 1 0%',
      boxSizing: 'border-box',
      minWidth: '300px',
      width: '300px',
    },
    shaded: {
      background: 'linear-gradient(#fff, #eee)',
    },
    content: {
      padding: '24px 10px 15px 50px',
      height: '250px',
      maxHeight: '211px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    contentCenter: {
      height: '100%',
      display: 'flex',
      padding: '0px 50px 0px',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentLast: {
      padding: '50px',
      textAlign: 'center',
    },
    dark: {
      fontWeight: 600,
    },
    completed: {
      color: Colors.measurement.missing,
      marginTop: '10px',
      fontSize: '18px',
    },
    contentContainer: {
      display: 'flex',
      marginLeft: '20px',
    },
    measureLabel: {
      fontSize: '55px',
      color: Colors.measurement.defined,
    },
    bubbleTitleStyles: {
      title: {
        fontSize: '17px',
        fontWeight: 600,
      },
      content: {
        marginTop: '16px',
      },
    },
    alertIcon: {
      color: Colors.light,
      backgroundColor: '#dfd13a',
      display: 'inline-block',
      width: '35px',
      height: '38px',
      paddingRight: '3px',
      textAlign: 'center',
      fontSize: '35px',
      lineHeight: '35px',
      borderRadius: '50%',
    },
    alertText: {
      color: Colors.mediumBlue,
      fontSize: '16px',
      margin: '10px 0 15px',
    },
    alertButton: {
      fontSize: '16px',
      fontWeight: 500,
      color: '#359ef3',
      cursor: 'pointer',
      ':hover': {
        fontWeight: '600',
      },
    },
    sectionBackground: {
      height: '25px',
      backgroundColor: '#f3f6f8',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },
    measureUi: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    pointToggle: {
      color: Colors.contentText,
      cursor: 'pointer',
      textAlign: 'right',
      display: 'flex',
      alignSelf: 'flex-end',
      paddingRight: '20px',
    },
    pointText: {
      fontSize: '16px',
    },
    pointCaret: {
      position: 'relative',
      top: 0,
      right: 0,
    },
    borderBlocker: {
      height: '1px',
      width: '320px',
      bottom: '1px',
      position: 'relative',
      background: Colors.white,
    },
  };

  const incentiveLabel =
    AppConfig.effectiveConfig.incentiveType ===
    AppConstants.IncentiveType.SWEEPSTAKES
      ? 'Points'
      : 'Rewards';

  let measureUI = null;

  if (measure) {
    measureUI = <div>{measure.label}</div>;
  }

  return (
    <StyleRoot style={GlobalStyles.regular}>
      <div style={styles.root}>
        <div style={styles.section}>
          <div style={styles.content}>
            <BubbleTitleMarkup
              iconClass={carePlan.iconClass}
              iconColor={Colors.white}
              bubbleColor={Colors.pink}
              bubbleFilled
              title={carePlan.name}
              styleOverrides={styles.bubbleTitleStyles}
            />

            {goalStatement && goalStatement.markup}

            <div style={styles.pointToggle} onClick={togglePoints}>
              <span style={styles.pointText}>How To Earn {incentiveLabel}</span>
              <i
                className={pointsOpen ? 'caret up' : 'caret'}
                style={styles.pointCaret}
              />
            </div>
          </div>
          <OffsetCloseButton />
        </div>

        <div style={styles.section}>
          <div style={styles.contentCenter}>
            <div style={[GlobalStyles.light, styles.measureUi]}>
              <div style={styles.measureLabel}>
                {measure ? measureUI : null}
              </div>
              <span style={styles.completed}>{'completed'}</span>
            </div>
          </div>
        </div>

        <div style={styles.section}>
          {selectedAlert ? (
            <div style={styles.contentLast}>
              <span style={styles.alertIcon}>!</span>
              <p style={styles.alertText}>{selectedAlert.description}</p>

              <span
                style={styles.alertButton}
                onClick={() => hashHistory.push(selectedAlert.linkRoute)}
              >
                {selectedAlert.linkText}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {pointsOpen && <div style={styles.borderBlocker} />}
      {pointsOpen && (
        <IncentiveSummary
          carePlan={carePlan}
          title={carePlan.shortName}
          concise
        />
      )}
      <div style={styles.sectionBackground} />
    </StyleRoot>
  );
};

PreventiveHeaderMarkup.propTypes = {
  carePlan: PropTypes.object,
  goalStatement: PropTypes.object,
  selectedAlert: PropTypes.object,
  measure: PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  }),
  pointsOpen: PropTypes.bool,
  togglePoints: PropTypes.func,
};

export default Radium(PreventiveHeaderMarkup);
