import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { QuestionBoxFooter } from '.';
import { compose } from 'recompose';
import _ from 'lodash';

const styles = () => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '20px',
    border: '7px solid #08492A',
    fontFamily: 'Rubik',
    padding: '24px',
    zIndex: '2',
    color: '#4B4B4B',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '400px',
    width: '750px',
  },
  incompleteText: {
    color: '#387394',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '38px',
    textAlign: 'center',
  },
  paragraph: {
    fontSize: '16px',
    marginBottom: '12px',
    textAlign: 'center',
    fontWeight: '300',
    flexGrow: '1',
  },
  button: {
    backgroundColor: '#FEB600',
    fontSize: '26px',
    color: 'white',
    borderRadius: '8px',
    padding: '14px 16px',
    textTransform: 'uppercase',
    fontWeight: '500',
    fontFamily: 'Rubik',
    '&:hover': {
      backgroundColor: '#FEB600',
    },
  },
  buttonIconLeft: {
    fontSize: '18px',
    marginRight: '16px',
  },
  doneButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '36px',
    fontSize: '20px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonIconRight: {
    marginLeft: '16px',
    fontSize: '12px',
  },
  doneButtonText: {
    textTransform: 'uppercase',
    paddingBottom: '4px',
    borderBottom: '2px solid white',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    height: '80px',
    marginBottom: '8px',
  },
  bigIcon: {
    height: '150px',
  },
});

const VerifyQuestionnaireBox = ({
  classes,
  isIncomplete,
  onBack,
  onComplete,
  text,
}) => (
  <Grid
    item
    container
    direction="column"
    className={classnames(classes.root, {
      [classes.incompleteText]: isIncomplete,
    })}
  >
    <Grid item className={classes.iconContainer}>
      <img
        className={classnames(classes.icon, {
          [classes.bigIcon]: !isIncomplete,
        })}
        src={require(isIncomplete
          ? '../../../../images/new-fte/icons/alert-circled.png'
          : '../../../../images/new-fte/icons/big-trophy.png')}
        role="presentation"
      />
    </Grid>
    <Grid item className={classnames(classes.centered, classes.title)}>
      {isIncomplete
        ? _.get(text, 'incomplete.title')
        : _.get(text, 'complete.title')}
    </Grid>
    <Grid item className={classnames(classes.centered, classes.paragraph)}>
      {isIncomplete ? (
        <p>{_.get(text, 'incomplete.description')}</p>
      ) : (
        <p>{_.get(text, 'complete.description')}</p>
      )}
    </Grid>
    <QuestionBoxFooter
      nextButtonOnClick={onComplete}
      nextButtonText={isIncomplete ? 'done' : 'finish'}
      prevButtonOnClick={onBack}
      noPrevButton={false}
      nextButtonColorOverride={isIncomplete ? '#eb5e65' : '#FEB600'}
      whyText={[]}
    />
  </Grid>
);

VerifyQuestionnaireBox.propTypes = {
  isIncomplete: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  text: PropTypes.object,
};

export default compose(withStyles(styles))(VerifyQuestionnaireBox);
