import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import { withDemoOverrides } from '@healthmine/greyhound-core/src/hocs';
import styles from './Demofy.styles';

const DemofyCompliance = ({ complianceOverrides, override }) => (
  <div style={styles.root}>
    <div style={styles.title}>Compliance withDemoOverrides</div>
    <a style={styles.link} onClick={() => override()}>
      COMPLIANCE_NO_MOCK
    </a>
    {complianceOverrides.map((x) => (
      <a key={x} style={styles.link} onClick={() => override(x)}>
        {x}
      </a>
    ))}
  </div>
);

DemofyCompliance.propTypes = {
  override: PropTypes.func.isRequired,
  complianceOverrides: PropTypes.array.isRequired,
};

export default compose(
  pure,
  withRouter,
  withDemoOverrides,
  withHandlers({
    override: ({ demoCompliance, router }) => (type) => {
      demoCompliance(type).then(() => router.push('/health-overview'));
    },
  })
)(DemofyCompliance);
