import React from 'react';
import PropTypes from 'prop-types';
import List, { ListHeader, ListContent } from '../../../common/list2/List';
import ConditionItem from './ConditionItem';
import { compose, withProps } from 'recompose';
import _ from 'lodash';

const ConditionList = ({ conditions, conditionCount }) => {
  return (
    <List disabled={!conditionCount}>
      <ListHeader
        title="Conditions"
        color="#FFBE00"
        subtitle={conditionCount !== 0 ? `${conditionCount} total` : undefined}
      />
      <ListContent
        isEmpty={!conditionCount}
        emptyMessage="No conditions found."
      >
        {conditions.map(({ name }, key) => (
          <ConditionItem key={`condition-${key}`} name={name} />
        ))}
      </ListContent>
    </List>
  );
};

ConditionList.propTypes = {
  conditions: PropTypes.array.isRequired,
  conditionCount: PropTypes.number.isRequired,
};

export default compose(
  withProps(({ conditions }) => ({
    conditionCount: _.get(conditions, 'length', 0),
  }))
)(ConditionList);
