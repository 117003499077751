import React from 'react';
import { routerActions } from 'react-router-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import SignInPage from '../containers/sign-in/SignInPage';

const ssoIsNotEnabled = connectedReduxRedirect({
  authenticatedSelector: (config) =>
    AppConfig.effectiveConfig !== null && !config.inboundSSOEnabled,
  authenticatingSelector: (state) =>
    state.signIn.appLoading || state.signIn.appLoadFailed,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'SsoIsNotEnabled',
  redirectPath: '/sso-error',
  allowRedirectBack: false,
  AuthenticatingComponent: SignInPage,
});

export default ssoIsNotEnabled((props) => React.cloneElement(props.children));
