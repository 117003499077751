import React from 'react';
import { connect } from 'react-redux';
import SettingsDevicesMarkup from './SettingsDevicesMarkup';

class SettingsDevices extends React.Component {
  constructor(props) {
    super(...props);
  }

  render() {
    return <SettingsDevicesMarkup />;
  }
}

export default connect(null, null)(SettingsDevices);
