import React from 'react';
import PropTypes from 'prop-types';
import { Subjects } from '@healthmine/greyhound-core/src/constants/CarePlan';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import Assigned from './body/Assigned';
import Preventive from './body/Preventive';
import InProgress from './body/InProgress';
import Radium from 'radium';

const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: '30px',
    wordBreak: 'break-word',
  },
};

const CarePlanBody = ({ carePlan, selectCarePlan, activateTracking }) => {
  const ActionBody =
    carePlan.status === AppConstants.PlanStatus.ASSIGNED
      ? Assigned
      : carePlan.subject === Subjects.PREVENTIVE
        ? Preventive
        : InProgress;

  return (
    <div style={styles.actions}>
      <ActionBody
        carePlan={carePlan}
        selectCarePlan={selectCarePlan}
        activateTracking={activateTracking}
      />
    </div>
  );
};

CarePlanBody.propTypes = {
  carePlan: PropTypes.shape({
    subject: PropTypes.string,
    status: PropTypes.string,
    primaryAction: PropTypes.object,
    actions: PropTypes.array,
    checkPoint: PropTypes.bool,
    biometricScreening: PropTypes.bool,
  }),
  selectCarePlan: PropTypes.func,
  activateTracking: PropTypes.func,
};

export default Radium(CarePlanBody);
