import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Styles from './UnifiedCarePlanTracking.styles';
import OffsetCloseButton from '../common/OffsetCloseButton';
import HealthAction from './HealthAction';
import { scroller } from 'react-scroll';
import _ from 'lodash';

const scrollToAction = (actionId) => {
  setTimeout(
    () =>
      scroller.scrollTo(`ActionId-${actionId}`, {
        duration: 1000,
        offset: -110,
        smooth: true,
        isDynamic: true,
      }),
    100
  );
};

class UnifiedCarePlanTrackingMarkup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openedActionId: undefined,
    };
  }
  componentDidMount() {
    const { routeParams } = this.props;
    if (!_.isUndefined(routeParams.actionId)) {
      scrollToAction(routeParams.actionId);
      this.setOpenedActionId(routeParams.actionId);
    }
  }
  componentWillReceiveProps(nextProps) {
    const { routeParams } = nextProps;

    const nextActionId = nextProps.routeParams.actionId;

    const previousActionId = this.props.routeParams.actionId;

    if (!_.isUndefined(nextActionId) && nextActionId !== previousActionId) {
      scrollToAction(routeParams.actionId);
      this.setOpenedActionId(routeParams.actionId);
    }
  }
  render() {
    const { carePlan, carePlanActions } = this.props;

    return (
      <div style={Styles.root}>
        <div style={Styles.header}>
          <div style={Styles.headerMainInfo}>
            <div
              style={[
                { ...Styles.bubble, backgroundColor: carePlan.bubbleColor },
              ]}
            >
              <i className={carePlan.iconClass} style={Styles.carePlanIcon} />
            </div>
            <div style={Styles.carePlanTitle}>
              <div style={Styles.carePlanName}>{carePlan.title}</div>
              <div style={Styles.memo}>{carePlan.subTitle}</div>
            </div>
          </div>
          <div style={Styles.headerStats}>
            {carePlan.actionCount > 0 && (
              <div style={Styles.completeCount}>
                <div style={Styles.completeCountNumber}>
                  {carePlan.completedCount}/{carePlan.actionCount}
                </div>
                <div style={Styles.completeCountMemo}>Complete</div>
              </div>
            )}
          </div>
          <OffsetCloseButton
            stylesOverride={{ left: 'calc(50% + 200px)', top: '155px' }}
          />
        </div>
        <div style={Styles.actionList}>
          <div style={Styles.actionListTitle}>Required Actions</div>
          {carePlanActions.map((action, idx) => {
            return (
              <HealthAction
                openedActionId={this.state.openedActionId}
                healthActionId={_.toString(action.healthActionId)}
                scrollId={`ActionId-${action.healthActionId}`}
                key={`${carePlan.subject}_action_${idx}`}
                isEven={(idx + 1) % 2 === 0}
                title={action.title}
                description={action.description}
                longDescription={action.longDescription}
                nextDue={action.nextDue}
                completed={action.completed}
                lastCompleted={action.lastCompleted}
                schedulingContactList={action.schedulingContactList}
              />
            );
          })}
        </div>
      </div>
    );
  }

  setOpenedActionId = (actionId) => {
    this.setState({
      openedActionId: actionId,
    });
  };
}

UnifiedCarePlanTrackingMarkup.propTypes = {
  carePlan: PropTypes.object,
  planSubTitle: PropTypes.string,
  carePlanActions: PropTypes.array,
  routeParams: PropTypes.object,
};

export default Radium(UnifiedCarePlanTrackingMarkup);
