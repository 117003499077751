import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import TrackingModal from '../../health-overview/care-plans/TrackingModal';
import AlertCardMarkup from './AlertCardMarkup';

class AlertCard extends React.Component {
  render = () => {
    const message = this._getAlertMessage();

    const actionLabel = this._getActionLabel();

    const trackingModal = this._renderTrackingModal();

    return (
      <AlertCardMarkup
        message={message}
        actionLabel={actionLabel}
        onActionLabelClicked={this._onActionLabelClicked}
      >
        {trackingModal}
      </AlertCardMarkup>
    );
  };

  _getAlertMessage = () => {
    const moduleType = _.get(this, 'props.action.type');

    let alertMessage = '';

    if (/^COACHING:/.test(moduleType)) {
      alertMessage = _.get(this, 'props.action.alertText');
    } else {
      switch (moduleType) {
        case AppConstants.ModuleType.BLOOD_PRESSURE:
          alertMessage = 'logged your blood pressure';
          break;

        case AppConstants.ModuleType.EDUCATION:
          alertMessage = 'checked out any educational content';
          break;

        case AppConstants.ModuleType.STEPS:
          alertMessage = 'logged your steps';
          break;

        case AppConstants.ModuleType.WORKOUTS:
          alertMessage = 'logged your calories burned';
          break;

        case AppConstants.ModuleType.GLUCOSE:
          alertMessage = 'logged your blood glucose level';
          break;

        case AppConstants.ModuleType.NUTRITION:
          alertMessage = 'logged your calories consumed';
          break;

        case AppConstants.ModuleType.SLEEP:
          alertMessage = 'logged your sleep';
          break;

        case AppConstants.ModuleType.WEIGHT:
          alertMessage = 'logged your weight';
          break;
      }

      if (alertMessage) {
        alertMessage = `You haven't ${alertMessage} in over a week. Stay on track with your commitment and continue earning points.`;
      }
    }

    return alertMessage;
  };

  _getActionLabel = () => {
    const moduleType = _.get(this, 'props.action.type');

    let actionText = _.get(this, 'props.action.text');

    if (AppConstants.TrackingModuleTypes.includes(moduleType)) {
      actionText = `${actionText} now`;
    }

    return _.startCase(actionText);
  };

  _renderTrackingModal = () => {
    const { action, modalOpen, onToggleTrackingModal } = this.props;

    const type = _.get(action, 'type');

    if (AppConstants.TrackingModuleTypes.includes(type)) {
      return (
        modalOpen && <TrackingModal type={type} close={onToggleTrackingModal} />
      );
    } else {
      return null;
    }
  };

  _onActionLabelClicked = () => {
    const action = _.get(this, 'props.action');

    const moduleType = _.get(action, 'type');

    const isTrackingModuleType = AppConstants.TrackingModuleTypes.includes(
      moduleType
    );

    const isEducationModuleType = AppConstants.EducationModuleTypes.includes(
      moduleType
    );

    if (isTrackingModuleType) {
      this.props.onToggleTrackingModal();
    } else if (isEducationModuleType) {
      this.props.router.push(`/pha/${action.contentId}`);
    } else if (/^COACHING:/.test(moduleType)) {
      _.invoke(action, 'action', this.props.dispatch, window.open);
    }
  };
}

AlertCard.propTypes = {
  action: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  onToggleTrackingModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,

  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default compose(connect(null, mapDispatchToProps), withRouter)(
  AlertCard
);
