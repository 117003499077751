import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import Typography from '../../../common/Typography';
import { ListItem, ListItemStyles } from '../../../common/list2/List';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  ...ListItemStyles,
  labTitle: {
    marginBottom: '2px',
    fontSize: '16px',
  },
});

const LabItem = ({ classes, labType, dateOfService }) => {
  return (
    <ListItem>
      <Typography type="h3Medium" inline className={classes.labTitle}>
        {labType}
      </Typography>
      <Typography type="h4" color="blueGray">
        completed on {dateOfService}
      </Typography>
    </ListItem>
  );
};

LabItem.propTypes = {
  classes: PropTypes.object.isRequired,
  labType: PropTypes.string.isRequired,
  dateOfService: PropTypes.string.isRequired,
};

export default compose(pure, withStyles(styles))(LabItem);
