import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { CarePlanActions } from '@healthmine/greyhound-core/src/actions';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import CarePlans from '@healthmine/greyhound-core/src/modules/CarePlans';
import SummaryCardMarkup from './SummaryCardMarkup';

class SummaryCard extends React.Component {
  render() {
    const {
      carePlan,
      isIncentiveSummaryVisible,
      onToggleIncentiveSummary,
      styleOverrides,
    } = this.props;

    const isCarePlanCompleted =
      carePlan.status === AppConstants.PlanStatus.COMPLETED;

    const pointsProfile = CarePlans.getPointsProfile(carePlan);

    const pointPotential = CarePlans.getPointPotentialFromPointsProfile(
      pointsProfile
    );

    const pointsEarned = CarePlans.getPointsEarnedFromPointsProfile(
      pointsProfile
    );

    return (
      <SummaryCardMarkup
        primaryAction={carePlan.primaryAction}
        pointPotential={pointPotential}
        pointsEarned={pointsEarned}
        goalAchieved={isCarePlanCompleted}
        onCreateCheckpoint={this._onCreateCheckpoint}
        isIncentiveSummaryVisible={isIncentiveSummaryVisible}
        onToggleIncentiveSummary={onToggleIncentiveSummary}
        styleOverrides={styleOverrides}
      />
    );
  }

  _onCreateCheckpoint = () => {
    const { carePlan } = this.props;

    this.props.actions.checkinOnMyGoal(carePlan.subject);

    this.props.router.goBack();
  };
}

SummaryCard.propTypes = {
  carePlan: PropTypes.object.isRequired,
  isIncentiveSummaryVisible: PropTypes.bool.isRequired,
  onToggleIncentiveSummary: PropTypes.func.isRequired,
  styleOverrides: PropTypes.object,

  actions: PropTypes.shape({
    checkinOnMyGoal: PropTypes.func.isRequired,
  }).isRequired,

  router: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

SummaryCard.defaultProps = {
  styleOverrides: {},
};

function mapDispatchToProps(dispatch) {
  const { checkinOnMyGoal } = CarePlanActions;

  return {
    actions: bindActionCreators({ checkinOnMyGoal }, dispatch),
  };
}

export default compose(connect(null, mapDispatchToProps), withRouter)(
  SummaryCard
);
