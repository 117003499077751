import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, pure, withProps } from 'recompose';
import { FirstTimeExperienceConstants } from '@healthmine/greyhound-core/src/constants';
import { withStyles } from '@material-ui/core';
import DrawerContent from '../DrawerContent';
import Title from '../../common/Title';
import { DrawerSection, DrawerSectionHeader } from '../../common/drawer2';

const styles = (theme) => ({
  subTitle: {
    color: theme.palette.common.whiskeySour,
  },
  star: {
    color: theme.palette.common.whiskeySour,
  },
});

const { title, description, items } = FirstTimeExperienceConstants.earnPoints;

const PointsDrawer = ({ classes, items, onNext, ...props }) => (
  <DrawerContent {...props} onSubmit={onNext}>
    <DrawerSectionHeader title="How To Earn Points" />
    {items.map(({ title, subTitle }, i) => (
      <DrawerSection key={i}>
        <Title
          title={title}
          subtitle={subTitle}
          subtitleClass={classes.subTitle}
          iconClass={classnames('icon-Star-Circle', classes.star)}
        />
      </DrawerSection>
    ))}
  </DrawerContent>
);

PointsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(() => ({
    color: 'whiskeySour',
    items,
    hero: { title, description, background: 'points' },
  }))
)(PointsDrawer);
