import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import Modal from 'react-modal';
import Button from '../../common/Button';

const AutomatedRewardCancellationMarkup = ({
  product,
  isOpen,
  closeHandler,
  deactivateReward,
}) => {
  const styles = {
    customStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },
      content: {
        minWidth: '510px',
        minHight: '165px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: '#fff',
        borderRadius: '15px',
        outline: 'none',
        padding: '45px 60px 30px',
      },
    },

    closebutton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    modalContent: {
      display: 'flex',
      flexDirection: 'column',
    },

    modalBody: {
      display: 'flex',
    },

    product: {
      width: '190px',
      display: 'flex',
      flexDirection: 'column',
    },

    image: {
      maxWidth: '120px',
      maxHeight: '80px',
      padding: '10px 0px',
    },

    productLabel: {
      color: Colors.black,
      fontSize: '16px',
      fontWeight: 500,
      cursor: 'pointer',
    },

    description: {
      color: Colors.contentText,
      fontSize: '16px',
      margin: '2px 0 10px',
    },

    enterProduct: {
      width: '100%',
    },

    button: {
      width: '190px',
      fontSize: '16px',
      padding: '10px 20px',
      marginBottom: '0px',
    },

    deactivationText: {
      color: Colors.contentText,
      fontSize: '16px',
      width: '320px',
      marginLeft: '30px',
      lineHeight: '18px',
    },

    headerText: {
      fontSize: '16px',
      fontWeight: '500',
    },

    footerContent: {
      display: 'flex',
      width: '100%',
      marginTop: '10px',
      justifyContent: 'flex-end',
    },
  };

  return (
    <Modal isOpen={isOpen} contentLabel={''} style={styles.customStyles}>
      <a onClick={() => closeHandler()} style={styles.closebutton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>
      <div style={styles.modalContent}>
        <div style={styles.modalBody}>
          <div style={[GlobalStyles.regular, styles.product]}>
            <label style={styles.headerText}>Current Automated Reward:</label>
            <img
              style={styles.image}
              src={product.imageUrl}
              alt={`${product.name} Card`}
              title={`${product.name} Card`}
            />
            <label style={styles.productLabel} />
          </div>
          <div style={[GlobalStyles.regular, styles.deactivationText]}>
            Are you sure you want to deactivate your Automated Rewards? Once you
            confirm, you will have to manually select the cards you want to
            redeem with your current balance.
            <p>
              If you want to reactivate your Automated Rewards, just follow the
              link.
            </p>
          </div>
        </div>
        <div style={styles.footerContent}>
          <Button
            text="Deactivate"
            style={[GlobalStyles.regular, styles.button]}
            onClick={deactivateReward}
          />
        </div>
      </div>
    </Modal>
  );
};

AutomatedRewardCancellationMarkup.propTypes = {
  product: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  deactivateReward: PropTypes.func.isRequired,
};

AutomatedRewardCancellationMarkup.defaultProps = {
  isOpen: false,
  product: {},
};

export default Radium(AutomatedRewardCancellationMarkup);
