import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const SweepstakeCard = ({ sweepstake, toggleProduct }) => {
  const styles = {
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.mediumGray2,
      paddingBottom: '10px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },
    productRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    product: {
      width: '168px',
      display: 'flex',
      flexDirection: 'column',
      padding: '50px 0 0',
      cursor: 'pointer',
    },

    image: {
      maxWidth: '168px',
      maxHeight: '105px',
    },

    imageOverlay: {
      position: 'absolute',
      width: '168px',
      height: '105px',
      background: 'rgba(255,255,255,0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0,
      ':hover': {
        opacity: 1,
      },
    },

    overlayIcon: {
      fontSize: '28px',
    },

    productLabel: {
      color: Colors.black,
      fontSize: '16px',
      marginTop: '10px',
      fontWeight: 600,
      cursor: 'pointer',
    },

    description: {
      color: Colors.contentText,
      fontSize: '16px',
      margin: '2px 0 10px',
    },

    points: {
      margin: 0,
      fontWeight: 600,
    },
  };

  return (
    <div
      onClick={() => toggleProduct(sweepstake.sweepstakeId)}
      style={styles.product}
    >
      <img
        style={styles.image}
        src={`${sweepstake.imageUrl}`}
        alt={sweepstake.sweepstakeName}
        title={sweepstake.sweepstakeName}
      />
      <div key={sweepstake.sweepstakeId} style={styles.imageOverlay}>
        <i className="thin-0033_search_find_zoom" style={styles.overlayIcon} />
      </div>
      <label style={styles.productLabel}>{sweepstake.sweepstakeName}</label>
      <p style={styles.description}>{sweepstake.description}</p>
      <p style={styles.points}>Points: {sweepstake.entryCost}</p>
    </div>
  );
};

SweepstakeCard.propTypes = {
  sweepstake: PropTypes.object,
  toggleProduct: PropTypes.func,
};

export default Radium(SweepstakeCard);
