import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Assets } from '@healthmine/greyhound-core/src/modules';

const styles = () => ({
  frame: {
    width: '100%',
  },
  inDrawerFrame: {
    flexGrow: 1,
  },
});

const ViewDocument = ({ classes, documentFile, drawerFrame }) => {
  return (
    <iframe
      className={classNames(classes.frame, {
        [classes.inDrawerFrame]: drawerFrame,
      })}
      src={
        /^http/.test(documentFile)
          ? documentFile
          : Assets.getAssetURL(documentFile)
      }
      frameBorder="0"
    />
  );
};

ViewDocument.propTypes = {
  classes: PropTypes.object.isRequired,
  documentFile: PropTypes.string.isRequired,
  drawerFrame: PropTypes.bool,
};

export default withStyles(styles)(ViewDocument);
