import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FteActions } from '@healthmine/greyhound-core/src/actions';
import AboutYourselfMarkup from '../../components/onboarding/AboutYourselfMarkup';

class AboutYourselfContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      heightFt: '',
      heightIn: '',
    };
  }
  componentWillMount() {
    this._setLocalHeight(this.props.height.healthAnswer.textAnswers[0]);
  }
  render() {
    const { weight } = this.props;

    const { heightIn, heightFt, errors } = this.state;
    return (
      <AboutYourselfMarkup
        heightFt={heightFt}
        heightIn={heightIn}
        errors={errors}
        weight={weight.healthAnswer.textAnswers[0]}
        updateHeightFt={this._updateHeightFt}
        updateHeightIn={this._updateHeightIn}
        heightInOnBlur={this._heightInOnBlur}
        updateWeight={this._updateWeight}
        scrollDown={this._continueClickHander}
        scrollUp={this._scrollUp}
        skip={this._scrollDown}
      />
    );
  }

  _scrollUp = () => {
    this.props.scrollUp(this.props.step - 1);
  };

  _scrollDown = () => {
    this.props.scrollDown(this.props.step + 1);
  };

  _setLocalHeight = (height) => {
    if (height !== undefined) {
      height = parseInt(height);

      let heightFt = Math.floor(height / 12);

      let heightIn = Math.floor(height % 12);

      this.setState({ heightFt });
      this.setState({ heightIn });
    }
  };

  _updateHeightFt = (e) => {
    const heightFt = parseInt(e.target.value);

    const heightIn = parseInt(this.state.heightIn);

    const inches = heightIn ? heightIn : 0;
    if (e.target.value != '' && !isNaN(heightFt)) {
      this.setState({ heightFt });
      this.props.actions.updateHeight(
        !isNaN(heightFt)
          ? heightFt * 12 + inches
          : !isNaN(heightIn)
            ? heightIn
            : 0
      );
    } else {
      //update inches because feet is NaN
      this.props.actions.updateHeight(inches);
      this.setState({ heightFt: '', heightIn: inches });
    }
  };

  _updateHeightIn = (e) => {
    const heightIn = parseInt(e.target.value);

    const heightFt = parseInt(this.state.heightFt);

    const feet = heightFt ? heightFt : 0;
    if (e.target.value != '' && !isNaN(heightIn)) {
      this.setState({ heightIn });
      this.props.actions.updateHeight(
        !isNaN(heightIn) ? heightIn + feet * 12 : feet
      );
    } else {
      //update feet because inches is NaN
      this.props.actions.updateHeight(feet * 12);
      this.setState({ heightIn: '', heightFt: feet });
    }
  };

  _heightInOnBlur = (e) => {
    if (e.target.value == '') {
      this.setState({ heightIn: 0 });
    }
  };

  _updateWeight = (e) => {
    const typedValue = parseInt(e.target.value);
    this.props.actions.updateWeight(typedValue ? typedValue : 0);
  };

  _optionIsSelected = () => {
    let isSelected = true;

    let errors = [];
    if (this.props.height.isValid !== true) {
      errors = [...errors, 'Please enter a height between 1 - 8 ft.'];
      isSelected = false;
    }
    if (this.props.weight.isValid !== true) {
      errors = [...errors, 'Please enter a weight between 40 - 700 lbs.'];
      isSelected = false;
    }
    this.setState({ errors });
    return isSelected;
  };

  _continueClickHander = () => {
    if (this._optionIsSelected() == false) {
      return;
    }
    const { height, weight } = this.props;

    const { postHealthAnswers } = this.props.actions;
    /* TODO: Check this format of postHealthAnswers */
    /* TODO: Implement postHealthAnswers to other subsections */
    postHealthAnswers([height.healthAnswer, weight.healthAnswer]).then(
      this._scrollDown
    );
  };
}

AboutYourselfContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  scrollDown: PropTypes.func.isRequired,
  scrollUp: PropTypes.func.isRequired,
  vis: PropTypes.bool,
  colorful: PropTypes.bool,
  step: PropTypes.number.isRequired,
  height: PropTypes.object,
  weight: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    height: state.onBoarding.height,
    weight: state.onBoarding.weight,
  };
}

function mapDispatchToProps(dispatch) {
  const { updateHeight, updateWeight, postHealthAnswers } = FteActions;
  return {
    actions: bindActionCreators(
      { updateHeight, updateWeight, postHealthAnswers },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(AboutYourselfContainer)
);
