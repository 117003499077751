import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  AppConfig,
  Assets,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import Page from '../common/Page';
import { Phone } from '../../styles/uhc-icons';

const ViewDocumentMarkup = ({
  isSignedIn,
  iconClass,
  title,
  documentFile,
  hideContainer,
}) => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);
  const uhcBranded = AppConfig.validateFeature(Features.UHC_BRANDED);

  const uhcIcon = uhcBranded && title === 'Contact Us' ? Phone : '';

  const styles = {
    publicRoot: {
      fontSize: '16px',
      overflowY: 'auto',
      position: 'relative',
      color: Colors.light,
      backgroundColor: fiveFiveUI ? 'transparent' : Colors.light,
      overflow: 'hidden',
    },

    publicContentContainer: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      width: 'inherit',
      overflowX: 'hidden',
      overflowY: 'auto',
    },

    publicContentContainerInner: {
      width: '800px',
      maxWidth: '100%',
      color: Colors.darkText,
      backgroundColor: Colors.light,
      borderRadius: fiveFiveUI ? '4px' : '0px',
      boxShadow: fiveFiveUI ? 'rgba(0, 0, 0, 0.2) 0px 2px 6px 0px' : 'none',
      marginBottom: fiveFiveUI ? '20px' : '0px',
    },

    contentContainer: {
      padding: fiveFiveUI ? '40px' : '0 68px 20px',

      '@media screen and (max-width: 760px)': {
        padding: '0 20px 20px',
      },
    },

    icon: {
      backgroundColor: uhcIcon
        ? Colors.white
        : isSignedIn
          ? Colors.contentText
          : Colors.bodyText,
    },

    content: {
      height: isSignedIn ? 'calc(100vh - 250px)' : 'calc(100vh - 313px)',
      width: '100%',
    },
  };

  const document = (
    <iframe
      src={
        /^http/.test(documentFile)
          ? documentFile
          : Assets.getAssetURL(documentFile)
      }
      frameBorder="0"
      style={styles.content}
    />
  );

  const contentUI = (
    <div style={[styles.contentContainer, GlobalStyles.light]}>{document}</div>
  );

  const wrappedContent = hideContainer ? (
    contentUI
  ) : (
    <Page
      title={title}
      icon={iconClass}
      iconStyle={styles.icon}
      uhcIcon={uhcIcon}
    >
      {contentUI}
    </Page>
  );

  return isSignedIn ? (
    wrappedContent
  ) : (
    <div style={[styles.publicRoot, GlobalStyles.light]} className="index">
      <div style={styles.publicContentContainer}>
        <div style={styles.publicContentContainerInner}>{contentUI}</div>
      </div>
    </div>
  );
};

ViewDocumentMarkup.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  documentFile: PropTypes.string.isRequired,
  hideContainer: PropTypes.bool,
};

ViewDocumentMarkup.defaultProps = {
  hideContainer: false,
};

export default Radium(ViewDocumentMarkup);
