import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import HeaderMarkup from './HeaderMarkup';
import HeaderMenu from './HeaderMenu';
import HealthOverviewMenuMarkup from './HealthOverviewMenuMarkup';
import LogoMarkup from './LogoMarkup';
import PrimaryMenusMarkup from './PrimaryMenusMarkup';
import SecondaryMenusMarkup from './SecondaryMenusMarkup';
import IncentiveHistoryMenu from './IncentiveHistoryMenu';
import ShoppingCartMenu from './ShoppingCartMenu';
import MessagesMenu from './MessagesMenu';
import InformationMenu from './InformationMenu';
import ProfileMenu from './ProfileMenu';
import { Care, Document } from '../../styles/uhc-icons';

class Header extends React.Component {
  render() {
    const { isImpersonating, isAuthenticated, tutorial } = this.props;

    const { minimal } = this.props;

    const { incentiveType } = AppConfig.effectiveConfig;

    const canShowCart = incentiveType === AppConstants.IncentiveType.REWARDS;

    const usesConnectedDevices = !AppConfig.validateFeature(
      Features.NO_CONNECTED_DEVICES
    );

    const usesHealthLibrary = !AppConfig.validateFeature(
      Features.NO_HEALTH_LIBRARY
    );

    const usesIncentives = incentiveType !== AppConstants.IncentiveType.NONE;

    const usesPlanInfo = AppConfig.validateFeature(Features.PLAN_INFORMATION);

    const incentiveRoute =
      incentiveType === AppConstants.IncentiveType.REWARDS
        ? 'rewards'
        : 'sweepstakes';

    return isAuthenticated ? (
      <HeaderMarkup isImpersonating={isImpersonating} tutorial={tutorial}>
        <LogoMarkup enableLink={!minimal} />

        {!minimal
          ? [
              <PrimaryMenusMarkup key="PrimaryMenus">
                <HeaderMenu
                  menuId="HealthOverviewMenu"
                  menuType="primary"
                  primaryRoute="/health-overview"
                  iconClassName="thin-0720_user_location_position"
                  uhcIcon={Care}
                  title={'Health\nOverview'}
                  activeRule={
                    /\/(health-overview|care-plan|hra|biometric-screening)/i
                  }
                  tutorial={tutorial}
                >
                  {usesConnectedDevices && <HealthOverviewMenuMarkup />}
                </HeaderMenu>

                {usesPlanInfo && (
                  <HeaderMenu
                    menuId="PlanInformationMenu"
                    menuType="primary"
                    primaryRoute="/plan-information"
                    iconClassName="thin-0063_text_line_view"
                    uhcIcon={Document}
                    title={'Program\nInformation'}
                  />
                )}

                {usesHealthLibrary && (
                  <HeaderMenu
                    menuId="HealthLibraryMenu"
                    menuType="primary"
                    primaryRoute="/pha"
                    iconClassName="thin-0007_book_reading_read_bookmark"
                    title={'Health\nLibrary'}
                  />
                )}

                {usesIncentives && (
                  <HeaderMenu
                    menuId="IncentivesMenu"
                    menuType="primary"
                    primaryRoute={`/${incentiveRoute}/shop/`}
                    iconClassName="thin-0670_diamond_prize_award_jewellery_ring"
                    title={
                      incentiveType === AppConstants.IncentiveType.REWARDS
                        ? 'Rewards'
                        : 'Sweepstakes'
                    }
                    activeRule={
                      new RegExp(`/${incentiveRoute}(?!/summary)`, 'i')
                    }
                  />
                )}
                {AppConfig.validateFeature(Features.DEMOFY) && (
                  <HeaderMenu
                    menuId="Demofy"
                    menuType="primary"
                    primaryRoute="/demofy"
                    iconClassName="thin-0162_on_off_switch_toggle_settings_preferences"
                    title="Demofy"
                  />
                )}
              </PrimaryMenusMarkup>,

              <SecondaryMenusMarkup key="SecondaryMenus">
                {usesIncentives && <IncentiveHistoryMenu />}
                {canShowCart && <ShoppingCartMenu />}
                <MessagesMenu />
                <InformationMenu />
                <ProfileMenu />
              </SecondaryMenusMarkup>,
            ]
          : null}
      </HeaderMarkup>
    ) : null;
  }
}

Header.propTypes = {
  isImpersonating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  tutorial: PropTypes.object,
  minimal: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.signIn.accessToken,
    isImpersonating: !!state.signIn.impersonationEmail,
    tutorial: state.tutorial,
  };
}

export default connect(mapStateToProps)(Header);
