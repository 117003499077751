import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Colors, AppConfig } from '@healthmine/greyhound-core/src/modules';
import { compose, withProps } from 'recompose';
import _get from 'lodash/get';

const styles = () => ({
  root: {
    padding: '20px',
  },
  para: {
    color: Colors.contentText,
    margin: '30px 0px 60px 0px',
    fontSize: '16px',
  },
  paragraph: {
    display: 'flex',
    fontSize: '16px',
    color: Colors.contentText,
    flexDirection: 'column',
    width: '625px',
    justifyContent: 'center',
    paddingLeft: '0px',
    paddingTop: '30px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    color: Colors.navyBlue,
    paddingBottom: '20px',
    marginBottom: '10px',
    width: '100%',
    borderBottom: `1px solid ${Colors.gutterGray}`,
  },
  table: {
    textAlign: 'left',
    display: 'flex',
    fontSize: '16px',
    width: '100%',
    padding: '20px 0px',
  },
  itemImage: {
    width: '120px',
  },
  description: {
    marginLeft: '15px',
    width: '450px',
  },
  label: {
    fontSize: '16px',
    fontWeight: 600,
  },
});

const OrderConfirmation = ({
  classes,
  name,
  emailAddress,
  address,
  automatedReward,
  autoRewardsContact,
}) => (
  <div className={classes.root}>
    <div className={classes.paragraph}>
      <p>
        {`${name}, thank you for setting up your automated gift card. You should be receiving a secure message shortly to confirm your order.`}
      </p>
      <p>{autoRewardsContact}</p>
      <p>Thank you again for your order!</p>
    </div>
    <div className={classes.content}>
      <div>
        <span className={classes.tableHeader}>Deliver to:</span>
        <div className={classes.para}>
          {emailAddress}
          <br />
          {address &&
            `${address.streetAddr1} ${
              address.streetAddr2 !== null ? address.streetAddr2 : ''
            }`}
          <br />
          {address && `${address.city}, ${address.state} ${address.zip}`}
        </div>
      </div>
      <div className={classes.tableHeader}>Items:</div>
      <div className={classes.table}>
        <span>
          <img
            src={automatedReward.imageUrl}
            className={classes.itemImage}
            alt={`${automatedReward.name} Card`}
            title={`${automatedReward.name} Card`}
          />
        </span>
        <span className={classes.description}>
          <div className={classes.label}>{automatedReward.name}</div>
        </span>
      </div>
    </div>
  </div>
);

OrderConfirmation.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  automatedReward: PropTypes.object.isRequired,
  autoRewardsContact: PropTypes.string.isRequired,
};

export default compose(
  withProps(() => ({
    autoRewardsContact: _get(
      AppConfig,
      'effectiveConfig.lingo.autoRewardsContact',
      ''
    ),
  })),
  withStyles(styles)
)(OrderConfirmation);
