import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import moment from 'moment';
import { Flex } from 'reflexbox';

const Header = ({
  currentWeek,
  week,
  minDate,
  maxDate,
  nextWeek,
  prevWeek,
}) => {
  const styles = {
    tableHeader: {
      borderBottom: `1px solid ${Colors.black}`,
    },
    tableLabel: {
      fontSize: '16px',
      fontWeight: 600,
    },
    tableArrow: {
      fontSize: '20px',
      cursor: 'pointer',
    },
  };

  return (
    <Flex
      justify={'space-between'}
      align={'center'}
      px={1}
      pb={1}
      style={styles.tableHeader}
    >
      <div
        style={[
          styles.tableArrow,
          week <=
          moment(minDate)
            .startOf('w')
            .diff(moment(maxDate), 'w')
            ? { visibility: 'hidden' }
            : {},
        ]}
        onClick={prevWeek}
      >
        <i className="thin-0159_arrow_back_left" />
      </div>
      <div style={styles.tableLabel}>Week of {currentWeek.format('ll')}</div>
      <div
        style={[styles.tableArrow, week === 0 ? { visibility: 'hidden' } : {}]}
        onClick={nextWeek}
      >
        <i className="thin-0160_arrow_next_right" />
      </div>
    </Flex>
  );
};

Header.propTypes = {
  currentWeek: PropTypes.object,
  week: PropTypes.number,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  nextWeek: PropTypes.func,
  prevWeek: PropTypes.func,
};

export default Radium(Header);
