import React from 'react';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import ConnectDevice from '../common/ConnectDevice';

const SettingsDevicesMarkup = () => {
  const styles = {
    container: {
      padding: '0 75px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    image: {
      maxWidth: '100%',
    },
    para: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
      margin: '0 0 10px',
      color: Colors.bodyText,
      maxWidth: '750px',
      ...GlobalStyles.light,
    },
    button: {
      minWidth: '200px',
      marginTop: '30px',
      padding: '8px 20px',
      ...GlobalStyles.light,
    },
  };

  return (
    <div style={styles.container}>
      <img
        style={styles.image}
        src={require('../../images/ConnectDeviceSources.png')}
        alt="Connected Device Options"
        title="Connected Device Options"
      />
      <p style={styles.para}>
        Connecting a device not only allows us to better configure your health,
        but also measure specific weaknesses and strengths that you struggle
        with. We will take the data that is provided by Human API and can
        integrate your Steps, Calories, Weight, Blood Pressure, and A1C to name
        a few.
      </p>
      <ConnectDevice
        style={styles.button}
        itemText="Connect or Remove a Device"
      />
    </div>
  );
};

export default Radium(SettingsDevicesMarkup);
