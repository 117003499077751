import React from 'react';
import { withStyles, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  DrawerSection,
  DrawerSectionHeader,
  DrawerButton,
  withDrawer,
} from '../../../../common/drawer2';
import Typography from '../../../../common/Typography';
import { compose, withStateHandlers, withProps } from 'recompose';
import _ from 'lodash';
import MedicationTrackerActions from '../../../../../../modules/demo/medications/actions.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const styles = (theme) => ({
  dayRow: {
    height: '62px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    borderBottom: `1px solid ${theme.palette.common.formulaOne}`,
  },
});

const MedConfigureDrawer = ({
  classes,
  medication,
  schedule,
  toggleDay,
  saveSchedule,
}) => (
  <DrawerContent title={medication.name}>
    <DrawerSection>
      <Typography>
        Help us understand how often you take this medication by clicking on the
        checkbox beside each day of the week.
      </Typography>
    </DrawerSection>
    <DrawerSectionHeader title="Frequency" />
    {_.map(schedule, (checked, day) => (
      <div key={`weekday-${day}`}>
        <div className={classes.dayRow}>
          <Typography>{_.upperFirst(day)}</Typography>
          <Checkbox checked={checked} onChange={() => toggleDay(day)} />
        </div>
      </div>
    ))}
    <DrawerSection grow noPadding />
    <DrawerButton
      onClick={saveSchedule}
      text="Save"
      disabled={_.every(schedule, (day) => !day)}
    />
  </DrawerContent>
);

MedConfigureDrawer.propTypes = {
  medication: PropTypes.object.isRequired,
  // from withStyles
  classes: PropTypes.object.isRequired,
  // from withStateHandlers
  schedule: PropTypes.object.isRequired,
  toggleDay: PropTypes.func.isRequired,
  // from withProps
  saveSchedule: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withDrawer,
  connect(null, (dispatch) => {
    const { updateSchedule } = MedicationTrackerActions;
    return {
      actions: bindActionCreators(
        {
          updateSchedule,
        },
        dispatch
      ),
    };
  }),
  withStateHandlers(
    ({ medication }) => ({
      schedule: {
        sunday: _.get(medication, 'schedule.sunday', false),
        monday: _.get(medication, 'schedule.monday', false),
        tuesday: _.get(medication, 'schedule.tuesday', false),
        wednesday: _.get(medication, 'schedule.wednesday', false),
        thursday: _.get(medication, 'schedule.thursday', false),
        friday: _.get(medication, 'schedule.friday', false),
        saturday: _.get(medication, 'schedule.saturday', false),
      },
    }),
    {
      toggleDay: ({ schedule }) => (day) => ({
        schedule: {
          ...schedule,
          [day]: !schedule[day],
        },
      }),
    }
  ),
  withProps(({ actions, drawer, medication, schedule }) => ({
    saveSchedule: () => {
      const { updateSchedule } = actions;
      updateSchedule(medication.name, schedule);
      drawer.back();
    },
  }))
)(MedConfigureDrawer);
