import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import List, { ListHeader, ListContent } from '../../common/list/List';
import PrescriptionItem from './PrescriptionItem';

const PrescriptionList = ({ theme, prescriptions, prescriptionCount }) => {
  return (
    <List>
      <ListHeader
        iconClass="icon-Medicine-Pills"
        title="Prescriptions"
        color={theme.palette.primary.main}
        subtext={
          prescriptionCount === 0 ? 'You have no prescriptions' : undefined
        }
        remainingCount={prescriptionCount}
        remainingCountNote="Prescriptions"
        noBubbleIcon
        isMute={prescriptionCount < 1}
      />
      <ListContent>
        {prescriptions.map(
          ({ name, startDate, drugStrength, daysSupply }, idx) => (
            <PrescriptionItem
              key={idx}
              name={name}
              startDate={startDate}
              drugStrength={drugStrength}
              daysSupply={daysSupply}
            />
          )
        )}
      </ListContent>
    </List>
  );
};

PrescriptionList.propTypes = {
  theme: PropTypes.object.isRequired,
  prescriptions: PropTypes.array.isRequired,
  prescriptionCount: PropTypes.number.isRequired,
};

export default withTheme()(PrescriptionList);
