import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules/';

class PickerMarkup extends React.Component {
  constructor(props) {
    super(props);

    //The state and functions are only used for the
    //individual element hover actions to avoid using external css

    this.state = {
      isActive: false,
    };

    this._onMouseOver = this._onMouseOver.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
  }

  render() {
    const {
      text,
      selected,
      onAnswered,
      spacing,
      labelOrientation,
      styles,
      readOnly,
    } = this.props;

    const { isActive } = this.state;

    const _styles = {
      container: {
        width: 'inhert',
        display: 'flex',
        justifyContent: 'flex-start',
        ...styles.container,
      },
      textItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'auto',
        color: '#ddd',
        paddingTop: spacing === 'normal' ? '30px' : '15px',
        paddingRight: '30px',
        cursor: 'pointer',
        ...styles.textItem,
      },
      largeBubble: {
        borderRadius: '50%',
        margin: '0px 12px 0px 0px',
        border: '2px solid #99a7b6',
        backgroundColor: 'transparent',
        width: '85px',
        height: '85px',
        ...styles.largeBubble,
      },
      smallBubble: {
        borderRadius: '50%',
        margin: '0px 20px 0px 0px',
        border: '1px solid #99a7b6',
        width: '20px',
        height: '20px',
        ...styles.smallBubble,
      },
      label: {
        flexBasis: '100%',
        flexShrink: '999',
        fontSize: '16px',
        fontWeight: 400,
        paddingTop: '0px',
        borderRadius: '10px',
        color: '#a4aeb9',
        minWidth: '200px',
        wordWrap: 'break-word',
        ...styles.label,
      },
      selectedIcon: {
        backgroundColor: Colors.mediumGray3,
        ...styles.selectedIcon,
      },
      innerLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        fontSize: '16px',
        color: '#a4aeb9',
        wordWrap: 'break-word',
        fontWeight: 400,
        ...styles.innerLabel,
      },
      selectedLabel: {
        color: '#fff',
        fontWeight: 400,
        ...styles.selectedLabel,
      },
      labelActive: {
        color: Colors.black,
        fontWeight: '400',
        ...styles.labelActive,
      },
      hoverBubble: {
        border: `1px solid ${Colors.lightGray2}`,
        backgroundColor: Colors.lightGray2,
        ...styles.hoverBubble,
      },
      hoverLabel: {
        ...styles.hoverLabel,
      },
    };

    return (
      <div style={_styles.container}>
        <div
          className="option"
          style={_styles.textItem}
          onClick={!readOnly ? onAnswered : undefined}
          onMouseOver={!readOnly ? this._onMouseOver : undefined}
          onMouseLeave={!readOnly ? this._onMouseLeave : undefined}
        >
          <div
            className={readOnly ? 'option-icon' : 'option-icon active'}
            style={[
              labelOrientation === 'in'
                ? _styles.largeBubble
                : _styles.smallBubble,
              selected
                ? _styles.selectedIcon
                : isActive
                  ? _styles.hoverBubble
                  : null,
            ]}
          >
            {labelOrientation === 'in' ? (
              <span
                className="option-text"
                style={[
                  _styles.innerLabel,
                  GlobalStyles.regular,
                  selected ? _styles.selectedLabel : null,
                ]}
              >
                {text}
              </span>
            ) : null}
          </div>
          {labelOrientation === 'out' ? (
            <span
              key={`text${text}`}
              className="option-text"
              style={[
                _styles.label,
                GlobalStyles.regular,
                selected ? _styles.labelActive : null,
              ]}
            >
              {text}
            </span>
          ) : null}
        </div>
      </div>
    );
  }

  _onMouseOver() {
    this.setState({
      isActive: true,
    });
  }

  _onMouseLeave() {
    this.setState({
      isActive: false,
    });
  }
}

PickerMarkup.propTypes = {
  text: PropTypes.string,
  spacing: PropTypes.string,
  onAnswered: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  labelOrientation: PropTypes.string,
  styles: PropTypes.object,
  readOnly: PropTypes.bool,
};

PickerMarkup.defaultProps = {
  styles: {},
  labelOrientation: 'out',
  readOnly: false,
};

export default Radium(PickerMarkup);
