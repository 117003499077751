import React from 'react';
import _get from 'lodash/get';
import SessionTimeoutPageMarkup from './SessionTimeoutPageMarkup';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';

class SessionTimeoutPage extends React.Component {
  componentWillMount = () => {
    this._previousBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#F5F5F5';
  };

  componentWillUnmount = () => {
    document.body.style.backgroundColor = this._previousBackgroundColor;
  };

  render = () => {
    const loginUrl = _get(AppConfig, 'effectiveConfig.logoutRedirect', '/#');
    return <SessionTimeoutPageMarkup loginUrl={loginUrl} />;
  };
}

export default SessionTimeoutPage;
