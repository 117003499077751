import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withStateHandlers, withProps } from 'recompose';
import PageSection from '../../../common/page/PageSection';
import CarePlanItem from './CarePlanItem';
import CoachingProgramList from './list/CoachingProgramList';
import Education from './Education';
import GoalTrackingList from './list/GoalTrackingList';
import CarePlanSectionWrapper from './CarePlanSectionWrapper';
import { withTheme } from '@material-ui/core';
import {
  withTrackers,
  WithPreventiveActions,
  WithChronicActions,
  WithCoachingPrograms,
} from '@healthmine/greyhound-core/src/hocs';
import { withDrawer } from '../../../common/drawer2';
import ManageTrackers from './drawer/ManageTrackers';
import withDemoImmunizations from './withDemoImmunizations';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import MedTrackerCard from './card/MedTrackerCard';

const ViewTypes = {
  CARD: 'CARD',
  LIST: 'LIST',
};

const CarePlan = ({
  rightMenuItems,
  isListView,
  allTrackers,
  manage,
  theme,
  preventiveActionCount,
  preventiveActions,
  completedPreventiveActionCount,
  remainingPreventiveActionCount,
  chronicActions,
  chronicActionCount,
  completedChronicActionCount,
  remainingChronicActionCount,
  coachingPrograms,
  hraStatus,
  hasConditionManagement,
  hasTrackers,
  assignedEducation,
}) => {
  return (
    <div>
      <PageSection
        title="My Care Plan"
        sectionCTA={manage}
        rightMenuItems={rightMenuItems}
      >
        <CarePlanSectionWrapper
          isListView={isListView}
          trackers={hasTrackers ? allTrackers : []}
          coachingPrograms={coachingPrograms}
        >
          <CarePlanItem
            title="Preventive Actions"
            iconClass="icon-Clipboard-List"
            color={theme.palette.common.notBubbleGum}
            healthActions={preventiveActions}
            actionCount={preventiveActionCount}
            completedActionCount={completedPreventiveActionCount}
            remainingActionCount={remainingPreventiveActionCount}
            isListView={isListView}
            hraStatus={hraStatus}
          />
          {hasConditionManagement && (
            <CarePlanItem
              title="Condition Management"
              iconClass="icon-Hands-Heart"
              color={theme.palette.common.venus}
              healthActions={chronicActions}
              actionCount={chronicActionCount}
              completedActionCount={completedChronicActionCount}
              remainingActionCount={remainingChronicActionCount}
              isListView={isListView}
              trackers={allTrackers}
            />
          )}
          {assignedEducation && <Education isListView={isListView} />}
          {isListView &&
            AppConfig.validateFeature(Features.COACHING) && (
              <CoachingProgramList coachingPrograms={coachingPrograms} />
            )}
          {isListView &&
            hasTrackers && <GoalTrackingList trackers={allTrackers} />}

          {!isListView &&
            AppConfig.validateFeature(Features.MED_ADHERENCE_TRACKER) && (
              <MedTrackerCard
                title="Track My Medications"
                iconClass="icon-Medicine-Pills"
                color={theme.palette.common.mimosa}
              />
            )}
        </CarePlanSectionWrapper>
      </PageSection>
    </div>
  );
};

CarePlan.propTypes = {
  assignedEducation: PropTypes.bool.isRequired,
  rightMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      iconClass: PropTypes.string,
      onClick: PropTypes.func,
      selected: PropTypes.bool,
    })
  ),
  isListView: PropTypes.bool.isRequired,
  allTrackers: PropTypes.array.isRequired,
  manage: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  preventiveActionCount: PropTypes.number.isRequired,
  preventiveActions: PropTypes.array.isRequired,
  completedPreventiveActionCount: PropTypes.number.isRequired,
  remainingPreventiveActionCount: PropTypes.number.isRequired,
  chronicActions: PropTypes.array.isRequired,
  chronicActionCount: PropTypes.number.isRequired,
  completedChronicActionCount: PropTypes.number.isRequired,
  remainingChronicActionCount: PropTypes.number.isRequired,
  coachingPrograms: PropTypes.array,
  hraStatus: PropTypes.string,
  hasConditionManagement: PropTypes.bool,
  hasTrackers: PropTypes.bool,
};

export default compose(
  pure,
  withDrawer,
  withTrackers,
  withStateHandlers(
    () => ({
      view: AppConfig.validateFeature(Features.HEALTH_OVERVIEW_CARD_VIEW)
        ? ViewTypes.CARD
        : ViewTypes.LIST,
    }),
    {
      selectCardView: () => () => ({
        view: ViewTypes.CARD,
      }),
      selectListView: () => () => ({
        view: ViewTypes.LIST,
      }),
    }
  ),
  withProps(({ drawer, view, selectCardView, selectListView }) => {
    const hasTrackers = !AppConfig.validateFeature(Features.NO_TRACKERS);
    const isListView = view === ViewTypes.LIST;
    const hasListView = AppConfig.validateFeature(
      Features.HEALTH_OVERVIEW_LIST_VIEW
    );
    const hasCardView = AppConfig.validateFeature(
      Features.HEALTH_OVERVIEW_CARD_VIEW
    );
    const assignedEducation = !AppConfig.validateFeature(
      Features.NO_ASSIGNED_EDUCATION
    );

    return {
      assignedEducation,
      hasConditionManagement: !AppConfig.validateFeature(
        Features.NO_CONDITION_MANAGEMENT
      ),
      hasTrackers,
      isListView,
      rightMenuItems:
        hasCardView && hasListView
          ? [
              {
                iconClass: 'icon-Apps',
                onClick: selectCardView,
                selected: !isListView,
              },
              {
                iconClass: 'icon-Rows',
                onClick: selectListView,
                selected: isListView,
              },
            ]
          : [],
      manage: hasTrackers
        ? {
            text: 'Manage Tracker Cards',
            onClick: () => drawer.open(ManageTrackers),
          }
        : {},
    };
  }),
  withTheme(),
  WithPreventiveActions,
  WithChronicActions,
  WithCoachingPrograms,
  withDemoImmunizations
)(CarePlan);
