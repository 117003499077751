import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  pure,
  withHandlers,
  withProps,
  withStateHandlers,
} from 'recompose';
import moment from 'moment';
import _get from 'lodash/fp/get';
import _isUndefined from 'lodash/fp/isUndefined';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { commaFormat } from '@healthmine/greyhound-core/src/utils';
import BiometricScreeningConstants from '@healthmine/greyhound-core/src/constants/BiometricScreening';
import WhatsNextCard from './WhatsNextCard';
import WhatToExpectDrawer from '../biometric-screening/WhatToExpect';
import BiometricResultsDrawer from '../biometric-screening/BiometricResults';
import { withDrawer, DrawerNavigation } from '../../../common/drawer2';
import styles from '../../../common/list/ListItem.styles';
import {
  WithBiometricReportSelection,
  WithPreventiveActions,
} from '@healthmine/greyhound-core/src/hocs';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import BiometricModals from '../biometric-screening/BiometricModals';

const Status = BiometricScreeningConstants.Status;

const getIncentiveEarningText = (
  isComplete,
  incentiveAmount,
  incentiveCurrency
) => {
  const amount = commaFormat(incentiveAmount);
  return isComplete
    ? incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS
      ? `You earned $${amount}!`
      : `You earned ${amount} pts!`
    : incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS
      ? `Earn $${amount}+`
      : `Earn ${amount}+ pts`;
};

const BiometricScreening = ({
  onMainCardClick,
  onSubTextClick,
  title,
  completedText,
  incentiveText,
  iconClass,
  isDrawerNav,
  classes,
  biometricScreeningStatus,
  isBiometricModalOpen,
  hideBiometricModal,
  screeningType,
}) => (
  <div>
    {isDrawerNav ? (
      <DrawerNavigation
        onClick={onMainCardClick}
        status={
          biometricScreeningStatus === Status.COMPLETED ? 'positive' : 'neutral'
        }
        title={title}
        subtitle={incentiveText}
        subtitleClass={classnames(
          classes.incentive,
          biometricScreeningStatus === Status.COMPLETED
            ? classes.complete
            : classes.incentiveToBeEarned
        )}
      />
    ) : (
      <>
        <WhatsNextCard
          title={title}
          iconClass={iconClass}
          completedText={completedText}
          incentiveText={incentiveText}
          onClick={onMainCardClick}
          onSubTextClick={onSubTextClick}
        />

        <BiometricModals
          screeningType={screeningType}
          showModals={isBiometricModalOpen}
          onClose={hideBiometricModal}
        />
      </>
    )}
  </div>
);

BiometricScreening.propTypes = {
  onMainCardClick: PropTypes.func.isRequired,
  onSubTextClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  completedText: PropTypes.string,
  incentiveText: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  incentiveAmount: PropTypes.number.isRequired,
  isDrawerNav: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  biometricScreeningStatus: PropTypes.string.isRequired,
  isBiometricModalOpen: PropTypes.bool.isRequired,
  hideBiometricModal: PropTypes.func.isRequired,
  screeningType: PropTypes.number,
};

export default compose(
  pure,
  withDrawer,
  WithBiometricReportSelection,
  WithPreventiveActions,

  withStateHandlers(
    {
      isBiometricModalOpen: false,
      screeningType: undefined,
    },

    {
      showBiometricModal: () => (screeningType) => ({
        isBiometricModalOpen: true,
        screeningType,
      }),

      hideBiometricModal: () => () => ({
        isBiometricModalOpen: false,
        screeningType: undefined,
      }),
    }
  ),

  withProps(
    ({
      reportOptions,
      biometricScreeningStatus,
      biometricScreeningAppointment,
      biometricScreeningBannerCardDisplay,
      incentiveAmount,
      biometricMessage,
    }) => {
      const { incentiveCurrency } = AppConfig.effectiveConfig;

      let cardProps = {};

      if (biometricScreeningStatus === Status.COMPLETED) {
        cardProps = {
          title: 'View Biometric Screening Results',
          completedText: _get('[0].label', reportOptions),
          incentiveText: getIncentiveEarningText(
            true,
            incentiveAmount,
            incentiveCurrency
          ),
          showResults: true,
        };
      } else if (
        biometricScreeningStatus === Status.UNSCHEDULED ||
        [12, 13].includes(biometricMessage)
      ) {
        cardProps = {
          title: biometricScreeningBannerCardDisplay,
          completedText: undefined,
          incentiveText: getIncentiveEarningText(
            false,
            incentiveAmount,
            incentiveCurrency
          ),
          //  I'm unconditionally setting this to 0 given that BlueKC does not
          //  want to deep-link members to specific eHS scheduling screens. If
          //  they change their minds (again), then we'll need to revisit.
          //
          //  Because the SAML token excludes this attribute now, it's effectively
          //  ignored, but I'd rather that the code not give the appearance of
          //  dynamic behavior.
          mainScreeningType: 0,
          subTextScreeningType: undefined,
        };
      } else if (biometricScreeningStatus === Status.SCHEDULED) {
        const startTime = _get('startTime', biometricScreeningAppointment);

        cardProps = {
          title: 'Upcoming Biometric Screening:',
          completedText: startTime
            ? moment(startTime).format('M/D/YYYY hh:mma')
            : undefined,
          incentiveText: 'For location information, click here.',
        };
      } else if (biometricScreeningStatus === Status.PENDING_RESULTS) {
        cardProps = {
          title: 'Evaluating Biometric Screening Results',
          incentiveText: getIncentiveEarningText(
            false,
            incentiveAmount,
            incentiveCurrency
          ),
          showDrawer: true,
        };
      }

      if ([7, 8].includes(biometricMessage)) {
        cardProps.title = 'Submit Physician Screening Form';
      }

      return cardProps;
    }
  ),

  withHandlers({
    onMainCardClick: ({
      drawer,
      showDrawer,
      showResults,
      mainScreeningType,
      showBiometricModal,
    }) => () => {
      if (showResults) {
        drawer.open(BiometricResultsDrawer, null, { large: true });
      } else if (showDrawer) {
        drawer.open(WhatToExpectDrawer);
      } else {
        showBiometricModal(mainScreeningType);
      }
    },

    onSubTextClick: ({ subTextScreeningType, showBiometricModal }) => (evt) => {
      evt.stopPropagation();

      if (!_isUndefined(subTextScreeningType)) {
        showBiometricModal(subTextScreeningType);
      }

      return false;
    },
  }),

  withStyles(styles)
)(BiometricScreening);
