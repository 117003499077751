import React from 'react';
import PropTypes from 'prop-types';
import QuestionMarkup from './QuestionMarkup';

class Question extends React.Component {
  render() {
    const { children, ...remainingProps } = this.props;

    return <QuestionMarkup {...remainingProps}>{children}</QuestionMarkup>;
  }
}

Question.propTypes = {
  currentQuestionNumber: PropTypes.number.isRequired,

  questionCount: PropTypes.number.isRequired,

  questionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,

  instructionText: PropTypes.string,

  styles: PropTypes.shape({
    container: PropTypes.object,
    progress: PropTypes.object,
    questionTextContainer: PropTypes.object,
    questionText: PropTypes.object,
    instructionText: PropTypes.object,
    questionContainer: PropTypes.object,
  }),

  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  subQuestion: PropTypes.bool,
};

export default Question;
