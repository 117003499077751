import React from 'react';
import PropTypes from 'prop-types';
import Concise from './Concise';
import Detailed from './Detailed';

const IncentiveSummary = ({
  carePlan,
  moduleCompletionStatus,
  title,
  concise,
}) => {
  const IncentiveSummaryMarkup = concise ? Concise : Detailed;

  return (
    <IncentiveSummaryMarkup
      carePlan={carePlan}
      moduleCompletionStatus={moduleCompletionStatus}
      title={title}
    />
  );
};

IncentiveSummary.propTypes = {
  carePlan: PropTypes.object,
  moduleCompletionStatus: PropTypes.object,
  title: PropTypes.string,
  concise: PropTypes.bool,
  coachingState: PropTypes.object,
};

export default IncentiveSummary;
