import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Card from '../../common/card/Card';
import Typography from '../../common/Typography';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const styles = (theme) => ({
  customCard: {
    padding: '4px',
    width: '237px',
  },
  image: {
    height: '150px',
    width: '100%',
    background: '#ccc',
    borderTopLeftRadius: theme.globalStyles.borderRadius1,
    borderTopRightRadius: theme.globalStyles.borderRadius1,
  },
  cardBody: {
    padding: '20px',
  },
  label: {
    padding: '0px 4px',
    color: 'white',
    fontSize: '16px',
    borderRadius: '4px',
    '&:not(:last-child)': {
      marginRight: '2px',
    },
  },
  digitalLabel: {
    backgroundColor: Colors.lightGreen,
  },
  physicalLabel: {
    backgroundColor: Colors.orange,
  },
});

const GiftCardCard = ({
  classes,
  title,
  imageUrl,
  isDigital,
  isPhysical,
  selectGiftCard,
  priceRange,
}) => {
  return (
    <Card customClass={classes.customCard} onClick={selectGiftCard}>
      <img
        className={classes.image}
        src={imageUrl}
        alt={`${title} gift card`}
        title={`${title} gift card`}
      />
      <div className={classes.cardBody}>
        <Grid container direction="column" spacing={8}>
          <Grid item>
            <Typography type="h4Medium">{title}</Typography>
          </Grid>
          <Grid item>
            {isDigital && (
              <Typography
                inline
                className={classnames(classes.label, classes.digitalLabel)}
              >
                EMAIL
              </Typography>
            )}
            {isPhysical && (
              <Typography
                inline
                className={classnames(classes.label, classes.physicalLabel)}
              >
                STANDARD MAIL
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography>Cost Range: {priceRange}</Typography>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

GiftCardCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDigital: PropTypes.bool.isRequired,
  isPhysical: PropTypes.bool.isRequired,
  selectGiftCard: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  priceRange: PropTypes.string.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ onClick, productId }) => {
    return {
      selectGiftCard: () => onClick(productId),
    };
  })
)(GiftCardCard);
