import React from 'react';
import { withStyles, Grid, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerNavigation,
} from '../../../../common/drawer2';
import Typography from '../../../../common/Typography';
import classnames from 'classnames';
import moment from 'moment';
import { compose, withStateHandlers, withProps } from 'recompose';
import _ from 'lodash';
import { connect } from 'react-redux';

const styles = (theme) => ({
  weekOf: {
    display: 'flex',
    padding: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  weekOfIcon: {
    cursor: 'pointer',
  },
  checkboxRow: {
    display: 'flex',
    height: '80px',
    padding: '20px 16px 20px 28px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.common.formulaOne}`,
    transition: 'background-color 400ms ease-in-out',
  },
  disabledRow: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    color: 'rgba(1,1,1,0.3)',
  },
  secondaryText: {
    color: theme.palette.common.blueGray,
  },
  taken: {
    color: theme.palette.common.grannySmith,
  },
});

const CustomCheckbox = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.common.grannySmith,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const MedDetailDrawer = ({
  classes,
  onConfigureMedication,
  onDateCheck,
  medicationName,
  weekOf,
  isPresent,
  changeWeekOfBy,
  datesThisWeekWithValues,
  medication,
}) => (
  <DrawerContent title={medicationName}>
    <DrawerSectionHeader title="Edit" />
    <DrawerNavigation
      subtitle="Update medication frequency"
      subtitleClass={classes.secondaryText}
      onClick={() => onConfigureMedication(medication)}
    />
    <Grid container className={classes.weekOf}>
      <Grid item xs={1}>
        <Typography secondary type="h2">
          <i
            className={classnames(
              classes.weekOfIcon,
              'icon-Left-Chevron-Circle'
            )}
            onClick={() => changeWeekOfBy(-1)}
          />
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography center>Week of {weekOf.format('MM/DD/YYYY')}</Typography>
      </Grid>
      <Grid item xs={1}>
        {!isPresent && (
          <Typography secondary type="h2">
            <i
              className={classnames(
                classes.weekOfIcon,
                'icon-Right-Chevron-Circle'
              )}
              onClick={() => changeWeekOfBy(1)}
            />
          </Typography>
        )}
      </Grid>
    </Grid>
    {_.map(datesThisWeekWithValues, ({ date, taken, shouldTake }, idx) => (
      <div
        key={`history-edit-med-${idx}`}
        className={classnames(classes.checkboxRow, {
          [classes.disabledRow]:
            !shouldTake || !date.isSameOrBefore(moment(), 'd'),
        })}
      >
        <div>
          <Typography>{date.format('dddd, MMMM DD')}</Typography>
          {date.isSameOrBefore(moment(), 'd') && (
            <Typography
              className={classnames(classes.secondaryText, {
                [classes.taken]: taken,
              })}
            >
              {!shouldTake
                ? 'Not taking'
                : shouldTake && !taken && date.isBefore(moment(), 'd')
                  ? 'Missed'
                  : taken
                    ? 'Taken'
                    : 'Not taken'}
            </Typography>
          )}
        </div>
        {date.isSameOrBefore(moment(), 'd') &&
          shouldTake && (
            <CustomCheckbox
              checked={taken}
              onClick={(e) => e.stopPropagation()}
              onChange={() =>
                onDateCheck(medication, new Date(date.format('MM/DD/YYYY')))
              }
            />
          )}
      </div>
    ))}
  </DrawerContent>
);

MedDetailDrawer.propTypes = {
  onConfigureMedication: PropTypes.func.isRequired,
  onDateCheck: PropTypes.func.isRequired,
  medicationName: PropTypes.string.isRequired,
  // from withStyles
  classes: PropTypes.object.isRequired,
  // from withStateHandlers
  weekOf: PropTypes.object.isRequired,
  changeWeekOfBy: PropTypes.func.isRequired,
  isPresent: PropTypes.bool.isRequired,
  // from withProps
  datesThisWeekWithValues: PropTypes.array.isRequired,
  // from connect
  medication: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect((state, { medicationName }) => ({
    medication: {
      ..._.get(state, `demo.medications[${medicationName}]`),
      name: medicationName,
    },
  })),
  withStateHandlers(
    {
      weekOf: moment().startOf('w'),
      isPresent: true,
    },
    {
      changeWeekOfBy: ({ weekOf }) => (offsetBy) => {
        const offsetIsPresent = weekOf
          .add(offsetBy, 'w')
          .startOf('w')
          .isSame(moment().startOf('w'), 'd');

        return {
          weekOf,
          isPresent: offsetIsPresent,
        };
      },
    }
  ),
  withProps(({ weekOf, medication }) => {
    const datesThisWeek = _.times(7, (offset) =>
      weekOf
        .clone()
        .startOf('w')
        .add(offset, 'd')
    );

    const datesThisWeekWithValues = datesThisWeek.map((date) => ({
      date,
      shouldTake: _.get(
        medication,
        `schedule[${date.format('dddd').toLowerCase()}]`
      ),
      taken: !!_.find(medication.history, (historyDate) =>
        historyDate.isSame(date, 'd')
      ),
    }));

    return { datesThisWeekWithValues };
  })
)(MedDetailDrawer);
