import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import BottomNav from './BottomNav';
import {
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import FloatingLabel from '../common/FloatingLabel';
import { Features } from '@healthmine/greyhound-core/src/constants';

const AboutYourselfMarkup = ({
  heightFt,
  heightIn,
  weight,
  errors,
  updateHeightFt,
  updateHeightIn,
  heightInOnBlur,
  updateWeight,
  scrollDown,
  scrollUp,
  skip,
}) => {
  const inputContainer = {
    marginBottom: '30px',
  };

  const mt60 = {
    marginTop: '60px',
  };

  const errorStyle = {
    lineHeight: '25px',
    color: 'red',
  };

  const emptyBox = {
    paddingBottom: '25px',
  };

  const question = {
    fontSize: '16px',
    marginBottom: '30px',
  };

  const inputWrapper = {
    display: 'inline-block',
    width: '150px',
    marginRight: '30px',
  };

  return (
    <div>
      <h3 style={[GlobalStyles.regular, question, mt60]}>How tall are you?</h3>
      <div style={inputContainer}>
        <FloatingLabel
          onChange={updateHeightFt}
          placeHolder={'Ft.'}
          value={heightFt}
          inputWrapper={inputWrapper}
        />
        <FloatingLabel
          onBlur={heightInOnBlur}
          onChange={updateHeightIn}
          placeHolder={'In.'}
          value={heightIn}
          inputWrapper={inputWrapper}
        />
      </div>

      <h3 style={[GlobalStyles.regular, question]}>How much do you weigh?</h3>
      <div style={inputContainer}>
        <FloatingLabel
          onChange={updateWeight}
          placeHolder={'Lbs.'}
          value={weight == 0 ? '' : weight}
        />
      </div>
      {errors.length ? (
        errors.map((error) => (
          <div key={error}>
            <span style={[GlobalStyles.regular, errorStyle]}>{error}</span>
          </div>
        ))
      ) : (
        <div style={emptyBox} />
      )}
      <BottomNav
        showBack={false}
        scrollDown={scrollDown}
        scrollUp={scrollUp}
        skip={
          AppConfig.validateFeature(Features.OPTIONAL_ONBOARDING) ? skip : null
        }
      />
    </div>
  );
};

AboutYourselfMarkup.propTypes = {
  heightFt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  heightIn: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weight: PropTypes.number,
  errors: PropTypes.array.isRequired,
  updateHeightFt: PropTypes.func.isRequired,
  updateHeightIn: PropTypes.func.isRequired,
  heightInOnBlur: PropTypes.func.isRequired,
  updateWeight: PropTypes.func.isRequired,
  scrollDown: PropTypes.func.isRequired,
  scrollUp: PropTypes.func.isRequired,
  skip: PropTypes.func,
};

AboutYourselfMarkup.defaultProps = {
  heightFt: 0,
  heightIn: 0,
  weight: 0,
};

export default Radium(AboutYourselfMarkup);
