import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import FreeformMarkup from './FreeformMarkup';

class Freeform extends React.Component {
  constructor(props, context) {
    super(props, context);

    const { mask } = props;
    let { value, errorWhenInactive } = props;

    let isValid = this._isValid(value);

    if (mask && mask.init) {
      value = mask.init(value);
    }

    this.state = {
      value: value || '',
      isActive: false,
      isValid,
      errorWhenInactive,
    };
  }

  componentWillMount() {
    const { value, onAnswered } = this.props;

    const isValid = this._isValid(value);

    if (isValid === false && onAnswered) {
      onAnswered(value, isValid);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { mask } = nextProps;
    let { value } = nextProps;

    let isValid = this._isValid(value);

    if (mask && mask.init) {
      value = mask.init(value);
    }

    this.setState({ value: value || '', isValid });
  }

  render() {
    return (
      <div>
        <FreeformMarkup
          {...this.props}
          {...this.state}
          onFocus={this._onFocus}
          onBlur={this._onBlur}
          onChange={this._onChange}
          displayError={this._displayError}
        />
      </div>
    );
  }

  @autobind
  _onFocus() {
    let { valueLabel, mask } = this.props;

    let { value, isValid } = this.state;

    if (!mask) {
      value =
        isValid && valueLabel
          ? value.replace(new RegExp(valueLabel, 'g'), '').trim()
          : value;

      this.setState({
        isActive: true,
        value,
      });
    } else {
      this.setState({
        isActive: true,
      });
    }
  }

  @autobind
  _onBlur() {
    let { valueLabel, mask } = this.props;

    let { value, isValid } = this.state;

    if (!mask) {
      value = isValid && valueLabel ? `${value} ${valueLabel}` : value;
      this.setState({
        isActive: false,
        value,
      });
    } else {
      this.setState({
        isActive: false,
      });
    }
  }

  @autobind
  _onChange(e) {
    const { onAnswered, mask } = this.props;

    let value = e.target.value;

    let { isValid } = this.state;

    this.setState({ value });

    if (mask && mask.extract) {
      value = mask.extract(value);
    }

    isValid = this._isValid(value);

    this.setState({
      isValid,
      errorWhenInactive: false,
    });

    if (onAnswered) onAnswered(value, isValid);
  }

  @autobind
  _displayError(e) {
    const isEnter = !e || (e && e.key === 'Enter');

    if (isEnter) {
      this.setState({ errorWhenInactive: true });
    }
  }

  @autobind
  _isValid(value) {
    const { allowEmpty, validationRegExps, validationMethod } = this.props;
    let isValid = true;

    let validationError = '';

    if (!allowEmpty && !value) return false;

    if (validationMethod) {
      let validation = validationMethod(value);
      isValid = validation.isValid;
      validationError = validation.validationError;

      if (!isValid && validationError) {
        this.setState({
          validationError,
        });
      }
    }

    if (
      validationRegExps &&
      (!validationMethod || (validationMethod && isValid))
    ) {
      isValid = validationRegExps.every((exp) => exp.test(value));
    }

    return isValid;
  }
}

Freeform.propTypes = {
  placeholder: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  valueLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mask: PropTypes.object,
  validationRegExps: PropTypes.arrayOf(PropTypes.instanceOf(RegExp)),
  onAnswered: PropTypes.func,
  moduleType: PropTypes.string,
  allowEmpty: PropTypes.bool,
  validationMethod: PropTypes.func,
  errorWhenInactive: PropTypes.bool,
};

export default connect(null, null)(Freeform);
