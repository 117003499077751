import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';

const InformationMenuMarkup = ({ groupNumber, informationLinks }) => {
  const styles = {
    menuHeader: {
      margin: '0px 15px',
      paddingLeft: '10px',
      paddingBottom: '20px',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.bodyText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      paddingTop: '15px',
    },

    menuOptionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '10px',
    },

    menuOptionContainer: {
      ':hover': {
        backgroundColor: Colors.gutterGray,
      },
    },

    menuOption: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '16px',
      textDecoration: 'none',
      padding: '8px 20px',
      cursor: 'pointer',
    },
  };

  const benefitsLink =
    AppConfig.validateFeature(Features.BENEFITS_LINK) &&
    AppConfig.effectiveConfig.benefitLinks[groupNumber];

  return (
    <div>
      <div style={styles.menuHeader}>Information</div>

      <div style={styles.menuOptionsContainer}>
        {benefitsLink && (
          <div key="BenefitsEnLink" style={styles.menuOptionContainer}>
            <a
              key="BenefitsEn"
              href={benefitsLink}
              target="_blank"
              style={styles.menuOption}
            >
              Summary of Benefits
            </a>
          </div>
        )}

        {benefitsLink && (
          <div key="BenefitsEsLink" style={styles.menuOptionContainer}>
            <a
              key="BenefitsES"
              href={benefitsLink.replace('.pdf', '-s.pdf')}
              target="_blank"
              style={styles.menuOption}
            >
              Resumen de Beneficios
            </a>
          </div>
        )}

        {informationLinks.map((link) => {
          const isS3 = /^s3:\/\//.test(link.route);
          return (
            <div key={link.caption} style={styles.menuOptionContainer}>
              {isS3 || /^http/.test(link.route) ? (
                <a
                  key={link.route}
                  href={AppConfig.replaceS3(link.route)}
                  target="_blank"
                  style={styles.menuOption}
                >
                  {link.caption}
                </a>
              ) : (
                <Link to={link.route} style={styles.menuOption}>
                  {link.caption}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

InformationMenuMarkup.propTypes = {
  groupNumber: PropTypes.string,
  informationLinks: PropTypes.array,
};

export default Radium(InformationMenuMarkup);
