export default {
  root: {
    padding: '10px',
  },
  title: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: '10px',
    fontSize: '18px',
  },
  link: {
    display: 'block',
    cursor: 'pointer',
    padding: '5px',
  },
};
