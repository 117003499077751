import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import Typography from '../../../common/Typography';
import { ListItem, ListItemStyles } from '../../../common/list2/List';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  ...ListItemStyles,
  conditionTitle: {
    fontSize: '16px',
  },
});

const ConditionItem = ({ classes, name }) => {
  return (
    <ListItem>
      <Typography type="h3Medium" inline className={classes.conditionTitle}>
        {name}
      </Typography>
    </ListItem>
  );
};

ConditionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default compose(pure, withStyles(styles))(ConditionItem);
