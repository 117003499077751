import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import { getStringForLocale } from '@healthmine/greyhound-core/src/constants/LocalizedContent';

const QuestionMarkup = ({
  currentQuestionNumber,
  questionCount,
  questionText,
  instructionText,
  styles,
  children,
  subQuestion,
  hraLanguageCode,
}) => {
  const _styles = {
    container: {
      paddingTop: '40px',
      ...(styles && styles.container),
    },

    progress: {
      color: Colors.navyBlue,
      fontSize: '16px',
      marginBottom: '5px',
      ...(styles && styles.progress),
    },

    questionTextContainer: {
      fontSize: '16pt',
      marginBottom: instructionText ? '20px' : '30px',
      ...(styles && styles.questionTextContainer),
    },

    questionText: {
      ...(styles && styles.questionText),
      fontSize: subQuestion === true ? '20px' : styles.questionText.fontSize,
    },

    instructionText: {
      fontSize: '16px',
      marginBottom: '30px',
      ...(styles && styles.instructionText),
    },

    questionContainer: {
      ...(styles && styles.questionContainer),
    },

    question: {
      width: '100%',
      paddingBottom: '15px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      marginBottom: '15px',
      ...(styles && styles.question),
    },
  };

  const questionLabel =
    getStringForLocale('additionalTexts.questionLabel', hraLanguageCode) ||
    'Question';

  const questionOf =
    getStringForLocale('additionalTexts.questionOf', hraLanguageCode) || 'of';

  return (
    <StyleRoot style={_styles.container}>
      {subQuestion !== true ? (
        <div style={[GlobalStyles.regular, _styles.progress]}>
          <div
            style={_styles.question}
          >{`${questionLabel} ${currentQuestionNumber} ${questionOf} ${questionCount}`}</div>
        </div>
      ) : null}

      <div style={[GlobalStyles.light, _styles.questionTextContainer]}>
        {'string' == typeof questionText ? (
          <div style={_styles.questionText}>{questionText}</div>
        ) : (
          questionText.map((question, index) => (
            <div key={index} style={_styles.questionText}>
              {question}
            </div>
          ))
        )}
      </div>

      {instructionText ? (
        <div style={_styles.instructionText}>{instructionText}</div>
      ) : null}

      <div style={_styles.questionContainer}>{children}</div>
    </StyleRoot>
  );
};

QuestionMarkup.propTypes = {
  currentQuestionNumber: PropTypes.number.isRequired,

  questionCount: PropTypes.number.isRequired,

  questionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,

  instructionText: PropTypes.string,

  styles: PropTypes.shape({
    container: PropTypes.object,
    progress: PropTypes.object,
    questionTextContainer: PropTypes.object,
    questionText: PropTypes.object,
    instructionText: PropTypes.object,
    questionContainer: PropTypes.object,
  }),

  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  subQuestion: PropTypes.bool,
  hraLanguageCode: PropTypes.string,
};

QuestionMarkup.defaultProps = {
  subQuestion: false,
};

export default Radium(QuestionMarkup);
