import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import typographyStyles from '../../styles/Typography';

const Typography = ({
  children,
  className,
  classes,
  el = 'div',
  theme,
  color,
  center,
  inline,
  primary,
  secondary,
  type,
  ...props
}) => {
  const El = el;
  return (
    <El
      {...props}
      className={classnames(className, classes.base, classes[type], {
        [classes.center]: center,
        [classes.inline]: inline,
        [classes.primary]: primary,
        [classes.secondary]: secondary,
      })}
      style={{
        color: theme.palette.common[color] || color,
        fontFamily: theme.globalStyles.baseFont,
      }}
    >
      {children}
    </El>
  );
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  color: PropTypes.string,
  center: PropTypes.bool,
  inline: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  type: PropTypes.oneOf(_.keys(typographyStyles)),
};

export default compose(withTheme(), withStyles(typographyStyles))(Typography);
