import { compose, pure, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import HeroCardMarkup from './HeroCard.markup';
import { selectHraStatus } from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import { withRouter } from 'react-router';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import _ from 'lodash';
import moment from 'moment';

const hraRoute = (hraStatus) =>
  hraStatus === AppConstants.QuestionnaireStatus.COMPLETED
    ? '/hra-result'
    : '/hra';

export default compose(
  pure,
  withRouter,
  connect((state) => {
    const hraStatus = selectHraStatus(state);

    const preventiveClinicalActions = _.get(
      state,
      'carePlanGoals.subjects.PREVENTIVE.clinical.actions',
      []
    );

    const hraAction = _.find(
      preventiveClinicalActions,
      {
        healthActionCode:
          AppConstants.HealthActions.COMPLETE_HEALTH_QUESTIONNAIRE,
      },
      {}
    );

    const myAction = _.get(
      state,
      ['myClinicalActions', hraAction.healthActionId],
      {}
    );

    const completions = _.get(myAction, 'completions', []);

    const lastCompleted = _.get(
      _.head(_.orderBy(completions, 'completionDate', 'desc')),
      'completionDate',
      undefined
    );
    return {
      hraAction,
      hraStatus,
      lastCompleted,
    };
  }),
  withProps(({ hraAction, hraStatus, lastCompleted }) => {
    const { incentiveType, incentiveCurrency } = AppConfig.effectiveConfig;

    const incentiveAmount = _.get(hraAction, 'incentiveAmount');

    const isHRAComplete =
      hraStatus === AppConstants.QuestionnaireStatus.COMPLETED;

    const title = isHRAComplete
      ? 'View Health Risk Assessment Results'
      : 'Take Health Risk Assessment';

    const completedText =
      isHRAComplete && lastCompleted
        ? `Completed on ${moment(lastCompleted).format('MM/DD/YYYY')}`
        : '';

    const incentiveText = isHRAComplete
      ? incentiveType === AppConstants.IncentiveType.NONE
        ? ''
        : incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS
          ? `You earned $${incentiveAmount}!`
          : `You earned ${incentiveAmount} points!`
      : incentiveType === AppConstants.IncentiveType.NONE
        ? ''
        : incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS
          ? `Earn $${incentiveAmount}`
          : `Earn ${incentiveAmount} points`;
    return {
      iconClass: 'thin-0378_analytics_presentation_statistics_graph',
      title,
      completedText,
      incentiveText,
      actionComplete: isHRAComplete,
    };
  }),
  withHandlers(() => ({
    onCardClick: ({ router, hraStatus }) => () =>
      router.push(hraRoute(hraStatus)),
  }))
)(HeroCardMarkup);
