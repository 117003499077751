import React from 'react';
import { DrawerContent, withDrawer } from '../../../common/drawer2';
import { withStyles } from '@material-ui/core/styles';
import { compose, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import _ from 'lodash';

const styles = () => ({
  content: {
    padding: '20px',
  },
  boldText: {
    fontSize: '16px',
    fontWeight: 600,
    color: Colors.bodyText,
  },
  currentSelectionImg: {
    maxWidth: '120px',
    padding: '10px 0px',
  },
  deactivationText: {
    color: Colors.contentText,
    fontSize: '16px',
    width: '320px',
    lineHeight: '18px',
  },
  button: {
    width: '180px',
    color: Colors.white,
    backgroundColor: Colors.dark,
    padding: '8px',
    marginBottom: '0px',
    outline: 'none',
    fontSize: '16px',
    borderRadius: '6px',
    cursor: 'pointer',
    marginTop: '16px',
  },
});

const CancelAutomatedRewardsDrawer = ({
  classes,
  automatedReward,
  cancelAutomatedRewards,
}) => (
  <DrawerContent title="Automated Rewards Cancellation">
    <div className={classes.content}>
      <p className={classes.boldText}>Current Automated Reward:</p>
      {_.get(automatedReward, 'imageUrl') && (
        <img
          className={classes.currentSelectionImg}
          src={automatedReward.imageUrl}
          alt={`${automatedReward.name} Card`}
          title={`${automatedReward.name} Card`}
        />
      )}
      {automatedReward && <p>{automatedReward.name}</p>}
      <div className={classes.deactivationText}>
        Are you sure you want to deactivate your Automated Rewards? Once you
        confirm, you will have to manually select the cards you want to redeem
        with your current balance.
        <p>
          If you want to reactivate your Automated Rewards, just follow the
          link.
        </p>
      </div>
      <button className={classes.button} onClick={cancelAutomatedRewards}>
        Deactivate
      </button>
    </div>
  </DrawerContent>
);

CancelAutomatedRewardsDrawer.propTypes = {
  classes: PropTypes.object,
  drawer: PropTypes.object,
  automatedReward: PropTypes.object,
  cancelAutomatedRewards: PropTypes.func,
};

export default compose(
  withStyles(styles),
  withDrawer,
  connect(
    (state) => {
      const preferences = state.rewards.preference;
      const rewards = _.get(state, 'rewards.rewards');
      const automatedReward = _.find(rewards, {
        productId: preferences.productId,
        deliveryForm: preferences.delivery,
      });

      return {
        preferences,
        automatedReward,
      };
    },
    (dispatch) => {
      const { deleteRewardsPreference, getRewardsPreference } = RewardsActions;

      return {
        actions: bindActionCreators(
          {
            deleteRewardsPreference,
            getRewardsPreference,
          },
          dispatch
        ),
      };
    }
  ),
  withHandlers({
    cancelAutomatedRewards: ({ drawer, preferences, actions }) => () => {
      const preferenceId = _.get(preferences, 'preferenceId');
      if (preferenceId)
        actions.deleteRewardsPreference(preferenceId).then(() => {
          actions.getRewardsPreference().then(() => drawer.close());
        });
    },
  })
)(CancelAutomatedRewardsDrawer);
