import React from 'react';
import {
  Grid,
  withStyles,
  Button,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { map, get } from 'lodash';

const styles = () => ({
  root: {
    backgroundColor: 'white',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginTop: '8px',
  },
  whyContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  whyText: {
    color: '#4B4B4B',
    fontSize: '16px',
    paddingBottom: '4px',
    borderBottom: '2px solid #4B4B4B',
  },
  nextContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  loader: {
    marginRight: '16px',
  },
  tooltip: {
    fontSize: '16px',
    padding: '8px',
  },
  backButton: {
    padding: '14px 16px 14px 10px',
    backgroundColor: '#7DC5A8',
    color: 'white',
    borderRadius: '6px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#7DC5A8',
    },
  },
  nextButton: {
    padding: '14px 10px 14px 16px',
    backgroundColor: '#4B4B4B',
    color: 'white',
    borderRadius: '6px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#4B4B4B',
    },
  },
  leftIcon: {
    marginRight: '8px',
  },
  rightIcon: {
    marginLeft: '8px',
  },
  tooltipText: {
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
});

const QuestionBoxFooter = ({
  classes,
  nextButtonOnClick,
  nextButtonText,
  prevButtonOnClick,
  noPrevButton,
  whyText,
  nextButtonColorOverride,
  isLoading,
  isSkippable,
}) => (
  <Grid item container className={classes.root}>
    <Grid item xs={3} className={classes.prevContainer}>
      {!noPrevButton && (
        <Button
          variant="contained"
          onClick={prevButtonOnClick}
          className={classes.backButton}
        >
          <span className={classes.leftIcon}>◂</span>
          back
        </Button>
      )}
    </Grid>
    <Grid item xs={6} className={classes.whyContainer}>
      {get(whyText, 'length') !== 0 && (
        <Tooltip
          title={
            <>
              {map(whyText, (text, idx) => (
                <div key={idx} className={classes.tooltipText}>
                  {text}
                </div>
              ))}
            </>
          }
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <span className={classes.whyText}>Why do we ask this?</span>
        </Tooltip>
      )}
    </Grid>
    <Grid item xs={3} className={classes.nextContainer}>
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      {((nextButtonText === 'skip' && isSkippable) ||
        nextButtonText !== 'skip') && (
        <Button
          variant="contained"
          onClick={nextButtonOnClick}
          className={classes.nextButton}
          disabled={isLoading}
          style={{
            backgroundColor: nextButtonColorOverride,
          }}
        >
          {nextButtonText}
          <span className={classes.rightIcon}>▸</span>
        </Button>
      )}
    </Grid>
  </Grid>
);

QuestionBoxFooter.propTypes = {
  nextButtonText: PropTypes.string.isRequired,
  nextButtonOnClick: PropTypes.func.isRequired,
  prevButtonOnClick: PropTypes.func.isRequired,
  noPrevButton: PropTypes.bool.isRequired,
  whyText: PropTypes.array,
  nextButtonColorOverride: PropTypes.string,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isSkippable: PropTypes.bool,
};

export default withStyles(styles)(QuestionBoxFooter);
