import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import _ from 'lodash';
import ViewDocument from '../common/ViewDocument';
import Card from '../common/card/Card';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    width: '800px',
    minHeight: '800px',
    margin: '0 auto',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
});

const PublicDocument = ({ classes, documentFile }) => {
  return (
    <Card customClass={classes.root}>
      <ViewDocument documentFile={documentFile} drawerFrame />
    </Card>
  );
};

PublicDocument.propTypes = {
  classes: PropTypes.object,
  iconClass: PropTypes.string,
  title: PropTypes.string,
  documentFile: PropTypes.string,
};

export default compose(
  pure,
  withProps(({ location }) => {
    const query = _.get(location, 'query');
    return {
      documentFile: _.get(query, 'documentFile'),
    };
  }),
  withStyles(styles)
)(PublicDocument);
