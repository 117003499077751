import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const styles = {
  tutorialContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: '9999999999999',
    height: '200px',
    // backgroundColor: 'red',//use this for debugging
  },
  tutorialArc: {
    // backgroundColor: 'rgba(255,255,255,0.5)',//use this to view the svg grid
    fill: 'transparent',
    stroke: 'rgb(33, 150, 243)',
    strokeWidth: 3,
    width: '200px',
    height: '80px',
  },
  tutorialCopyHeader: {
    color: 'rgb(33, 150, 243)',
    fontSize: '24px',
  },
  tutorialCopyText: {
    width: '350px',
    fontSize: '18px',
    color: '#c9c9c9',
  },
};

const Callout = ({ path, position, title, text, direction, arrowEnd }) => {
  let marker = {};
  marker[arrowEnd] = 'url(#head)';

  return (
    <div id="callout" style={[styles.tutorialContainer, position, direction]}>
      <svg style={styles.tutorialArc} xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="head"
            orient="auto"
            markerWidth="5"
            markerHeight="4"
            refX="0.1"
            refY="2"
          >
            <path d="M0,0 V4 L6,2 Z" strokeWidth="0" fill="rgb(33, 150, 243)" />
          </marker>
        </defs>
        <path d={path} {...marker} />
      </svg>

      <div style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
        <div
          style={[
            styles.tutorialCopyHeader,
            { marginTop: direction.flexDirection == 'row' ? '30px' : null },
          ]}
        >
          {title}
        </div>

        <div style={styles.tutorialCopyText}>{text}</div>
      </div>
    </div>
  );
};

Callout.propTypes = {
  position: PropTypes.shape({
    left: PropTypes.string.isRequired,
    top: PropTypes.string.isRequired,
  }),
  direction: PropTypes.shape({
    flexDirection: PropTypes.string.isRequired,
  }),
  arrowEnd: PropTypes.oneOf(['markerEnd', 'markerStart']),
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Radium(Callout);
