import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import { hashHistory } from 'react-router';
import ReactMarkdown from 'react-markdown';
import {
  HealthActionIcons,
  CompletionStatus,
  HRAState,
  BiometricState,
} from '@healthmine/greyhound-core/src/constants/CarePlan';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { ReactHeight } from 'react-height';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import ThirdPartyCookieWarning from '../common/ThirdPartyCookieWarning';
import WelcomeBackModal from '../common/WelcomeBackModal';
import HealthActionFormLink from './common/HealthActionFormLink';
import _ from 'lodash';

function getBiometricCaption(biometricStatus) {
  if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)) {
    return BiometricState.SCREENING_NOTSTARTED;
  }

  switch (biometricStatus) {
    case CompletionStatus.COMPLETED: {
      return BiometricState.SCREENING_COMPLETED;
    }
    case CompletionStatus.STARTED: {
      return BiometricState.SCREENING_STARTED;
    }
    case CompletionStatus.NOTSTARTED: {
      return BiometricState.SCREENING_NOTSTARTED;
    }
    default: {
      return null;
    }
  }
}

function getBiometricRoute(biometricStatus) {
  const baseRoute = '/biometric-screening';
  if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)) {
    return '/biometric-screening/events';
  }
  return biometricStatus === CompletionStatus.STARTED
    ? `${baseRoute}/appointment`
    : biometricStatus === CompletionStatus.COMPLETED
      ? `${baseRoute}/results`
      : baseRoute;
}

const PreventiveHealthActionsMarkup = ({
  index,
  details,
  detailHeight,
  toggleDetails,
  healthAction,
  onHeightReady,
  HRAStatus,
  biometricStatus,
  longDescription,
  onContentClick,
  showWelcomeBackModal,
  onWelcomeBackModalClosed,
  showThirdPartyCookieWarning,
  onThirdPartyCookieWarningClosed,
}) => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const moduleColor = Colors[healthAction.healthActionCode]
    ? Colors[healthAction.healthActionCode]
    : Colors.navyBlue;

  const icon = _.get(
    HealthActionIcons,
    healthAction.healthActionCode,
    'thin-0060_error_warning_danger_stop_delete_exit'
  );

  const iconClass = icon === 'thin-0060_error_warning_danger_stop_delete_exit';

  const { lingo } = AppConfig.effectiveConfig;

  const styles = {
    section: {
      flex: '0.3334 1 0%',
      boxSizing: 'border-box',
      minWidth: '300px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      borderRight: `1px solid ${Colors.gutterGray}`,
      position: 'relative',
      paddingTop: '40px',
      marginBottom: details ? `${detailHeight + 80}px` : null,
    },

    noBorderBottom: {
      borderBottom: 'none',
    },

    content: {
      padding: '10px',
    },

    title: {
      fontSize: '16px',
      margin: '10px 0 20px',
      textAlign: 'center',
    },

    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      paddingTop: 0,
      flex: fiveFiveUI ? '1 1 800px' : '1 1 900px',
    },

    iconContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: '20px 20px 0px 20px',
      justifyContent: 'center',
    },

    icon: {
      display: 'flex',
      border: `2px solid ${moduleColor}`,
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      fontSize: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: moduleColor,
      position: 'relative',
    },

    descriptionText: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '16px',
      lineHeight: '18px',
      margin: '0px 0px 0px 15px',
      width: '140px',
      height: '54px',
    },

    measureLabel: {
      marginLeft: '15px',
    },

    primaryText: {
      fontSize: '30px',
      margin: '0 0 20px',
    },

    details: {
      color: Colors.lightSlateGray,
      cursor: 'pointer',
      position: 'relative',
      paddingRight: '30px',
      marginTop: '18px',
      textAlign: 'right',
      fontSize: '16px',
    },

    detailsContent: {
      backgroundColor: Colors.light,
      padding: '40px 40px 40px 40px',
      display: 'none',
      transition: '0.1s ease all',
      position: 'absolute',
      left: `-${(index % 3) * 300}px`,
      right: `${(index % 3) * 300 - 600}px`,
      color: Colors.contentText,
      textAlign: 'left',
    },

    callToAction: {
      marginTop: '20px',
      fontSize: '16px',
      textAlign: 'center',
      color: Colors.highlightBlue,
    },

    callToActionLink: {
      color: Colors.highlightBlue,
      textDecoration: 'none',
      fontSize: '16px',
      justifyContent: 'center',
      marginTop: '20px',
      marginBottom: 0,
      cursor: 'pointer',
      textAlign: 'right',
    },

    callToActionLabel: {
      cursor: 'pointer',
      marginLeft: '-20px',
      ':hover': {
        fontWeight: '600',
      },
    },

    detailsContentActive: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: `${detailHeight}px`,
      clear: 'both',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      borderTop: 'none !important',
    },

    completed: {
      fontSize: '16px',
      color: Colors.measurement.missing,
      marginTop: '-15px',
    },

    descriptionWrapper: {
      height: '80px',
    },

    description: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
      marginLeft: '50px',
      lineHeight: '15px',
      marginTop: '20px',
      width: '200px',
      marginRight: '45px',
      fontSize: '16px',
      textAlign: 'center',
      color: '#467297',
      fontWeight: 300,
    },

    iconStyle: {
      color: Colors.white,
      transform: iconClass ? 'rotate(45deg)' : null,
    },

    badge: {
      display: 'flex',
      position: 'absolute',
      top: '-4px',
      right: '-4px',
      height: '15px',
      width: '15px',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
    },

    badgeIcon: {
      backgroundColor: 'transparent',
      color: Colors.white,
      fontSize: '16px',
    },

    healthActionCreditFormDescription: {
      color: Colors.contentText,
      fontSize: '16px',
      lineHeight: 1.5,
    },
  };

  const completed =
    !_.isEmpty(healthAction.completions) ||
    (healthAction.healthActionCode ===
      AppConstants.HealthActions.COMPLETE_HEALTH_QUESTIONNAIRE &&
      HRAStatus === CompletionStatus.COMPLETED);

  const actionRoute =
    healthAction.healthActionCode ===
    AppConstants.HealthActions.COMPLETE_HEALTH_QUESTIONNAIRE
      ? HRAStatus === CompletionStatus.COMPLETED
        ? '/hra-result/'
        : '/hra/'
      : healthAction.healthActionCode ===
        AppConstants.HealthActions.COMPLETE_BIOMETRIC_TESTING
        ? getBiometricRoute(biometricStatus)
        : '';

  const HRALink =
    HRAStatus !== undefined
      ? HRAStatus === CompletionStatus.COMPLETED
        ? HRAState.hraCompleted(lingo)
        : HRAStatus === CompletionStatus.STARTED
          ? HRAState.hraStarted(lingo)
          : HRAStatus === CompletionStatus.NOTSTARTED
            ? HRAState.hraStateNotStarted(lingo)
            : null
      : null;

  const biometricLink = getBiometricCaption(biometricStatus);

  const showHRALink =
    healthAction.healthActionCode ===
    AppConstants.HealthActions.COMPLETE_HEALTH_QUESTIONNAIRE;

  const showBiometricLink =
    healthAction.healthActionCode ===
    AppConstants.HealthActions.COMPLETE_BIOMETRIC_TESTING;

  const ctaUI =
    showHRALink || showBiometricLink ? (
      <div style={[GlobalStyles.regular, styles.callToAction]}>
        <span
          style={styles.callToActionLabel}
          onClick={() => hashHistory.push(actionRoute)}
        >
          {showHRALink ? HRALink : null}
          {showBiometricLink ? biometricLink : null}
        </span>
      </div>
    ) : null;

  const descUI = (
    <ReactHeight onHeightReady={onHeightReady} onClick={onContentClick}>
      <ReactMarkdown
        className="Markdown HealthActionDescription"
        source={AppConfig.replaceS3(longDescription.replace(/\\n/g, '\n'))}
        renderers={{
          /* eslint-disable */
          Link: ({ href, children }) => {
            return _.startsWith(href, 'https://s3.amazonaws.com', 0) ? (
              <a href={href} target="_blank">
                {children}
              </a>
            ) : (
              <a href={href}>{children}</a>
            );
          },
        }}
        /* eslint-enable */
      />
      <HealthActionFormLink
        style={styles.healthActionCreditFormDescription}
        actionCode={healthAction.healthActionCode}
      />
    </ReactHeight>
  );

  return (
    <StyleRoot>
      {showWelcomeBackModal ? (
        <WelcomeBackModal onClose={onWelcomeBackModalClosed} />
      ) : showThirdPartyCookieWarning ? (
        <ThirdPartyCookieWarning onClose={onThirdPartyCookieWarningClosed} />
      ) : null}

      <div style={styles.contentContainer}>
        <div style={styles.section}>
          <div style={styles.iconContainer}>
            <span style={styles.icon}>
              <i className={icon} style={styles.iconStyle} />
              {
                <div
                  style={[
                    styles.badge,
                    { backgroundColor: completed ? Colors.linkBlue : null },
                  ]}
                >
                  {!completed ? null : (
                    <i className="material-icons" style={styles.badgeIcon}>
                      done
                    </i>
                  )}
                </div>
              }
            </span>
            <div style={styles.descriptionText}>
              {completed ? (
                <span style={styles.completed}>{'completed'}</span>
              ) : null}
              <span>{healthAction.shortDescription}</span>
            </div>
          </div>
          <div style={styles.descriptionWrapper}>
            <div style={styles.description}>{healthAction.subTitle}</div>
            {ctaUI}
          </div>
          <div>
            <p style={styles.details} onClick={toggleDetails}>
              {details ? 'Hide Details' : 'More Details'}{' '}
              <i className={details ? 'caret up' : 'caret'} />
            </p>
            <div
              style={[
                styles.detailsContent,
                details ? styles.detailsContentActive : null,
              ]}
            >
              {descUI}
            </div>
          </div>
        </div>
      </div>
    </StyleRoot>
  );
};

PreventiveHealthActionsMarkup.propTypes = {
  index: PropTypes.number,
  detailHeight: PropTypes.number,
  healthAction: PropTypes.object,
  HRAStatus: PropTypes.string,
  biometricStatus: PropTypes.string,
  details: PropTypes.bool,
  toggleDetails: PropTypes.func,
  onHeightReady: PropTypes.func,
  longDescription: PropTypes.string,
  onContentClick: PropTypes.func.isRequired,
  showWelcomeBackModal: PropTypes.bool.isRequired,
  onWelcomeBackModalClosed: PropTypes.func.isRequired,
  showThirdPartyCookieWarning: PropTypes.bool.isRequired,
  onThirdPartyCookieWarningClosed: PropTypes.func.isRequired,
};

PreventiveHealthActionsMarkup.defaultProps = {
  module: {},
};

export default Radium(PreventiveHealthActionsMarkup);
