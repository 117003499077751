import _every from 'lodash/every';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';

////////////////////////////////////////////////////////////////////////////////
//  Implements a reduction of the code mappings as documented @
//  https://bluebutton.cms.gov/resources/codesystem/eob-type/
////////////////////////////////////////////////////////////////////////////////

//  prettier-ignore
const CRITERIA = [
  {
    type: 'doctor',
    'https://bluebutton.cms.gov/resources/codesystem/eob-type': 'CARRIER',
    'http://hl7.org/fhir/ex-claimtype': 'professional',
    'https://bluebutton.cms.gov/resources/variables/nch_clm_type_cd': ['71', '72'],
    'https://bluebutton.cms.gov/resources/variables/nch_near_line_rec_ident_cd': 'O',
  },

  {
    type: 'medicalEquipment',
    'https://bluebutton.cms.gov/resources/codesystem/eob-type': 'DME',
    'https://bluebutton.cms.gov/resources/variables/nch_clm_type_cd': ['81', '82'],
    'https://bluebutton.cms.gov/resources/variables/nch_near_line_rec_ident_cd': 'M',
  },

  {
    type: 'homeHealth',
    'https://bluebutton.cms.gov/resources/codesystem/eob-type': 'HHA',
    'https://bluebutton.cms.gov/resources/variables/nch_clm_type_cd': '10',
    'https://bluebutton.cms.gov/resources/variables/nch_near_line_rec_ident_cd': ['U', 'V', 'W'],
  },

  {
    type: 'hospice',
    'https://bluebutton.cms.gov/resources/codesystem/eob-type': 'HOSPICE',
    'http://hl7.org/fhir/ex-claimtype': 'institutional',
    'https://bluebutton.cms.gov/resources/variables/nch_clm_type_cd': '50',
    'https://bluebutton.cms.gov/resources/variables/nch_near_line_rec_ident_cd': 'V',
  },

  {
    type: 'inpatient',
    'https://bluebutton.cms.gov/resources/codesystem/eob-type': 'INPATIENT',
    'http://hl7.org/fhir/ex-claimtype': 'institutional',
    'https://bluebutton.cms.gov/resources/variables/nch_clm_type_cd': '60',
    'https://bluebutton.cms.gov/resources/variables/nch_near_line_rec_ident_cd': 'V',
  },

  {
    type: 'outpatient',
    'https://bluebutton.cms.gov/resources/codesystem/eob-type': 'OUTPATIENT',
    'http://hl7.org/fhir/ex-claimtype': 'professional',
    'https://bluebutton.cms.gov/resources/variables/nch_clm_type_cd': '40',
    'https://bluebutton.cms.gov/resources/variables/nch_near_line_rec_ident_cd': 'W',
  },

  {
    type: 'pharmacy',
    'https://bluebutton.cms.gov/resources/codesystem/eob-type': 'PDE',
    'http://hl7.org/fhir/ex-claimtype': 'pharmacy',
  },

  {
    type: 'skilledNursing',
    'https://bluebutton.cms.gov/resources/codesystem/eob-type': 'SNF',
    'http://hl7.org/fhir/ex-claimtype': 'institutional',
    'https://bluebutton.cms.gov/resources/variables/nch_clm_type_cd': ['20', '30'],
    'https://bluebutton.cms.gov/resources/variables/nch_near_line_rec_ident_cd': 'V',
  },
];

export const decodeCareEpisodeType = (resource) => {
  const resourceCoding = _get(resource, 'type.coding');
  let type = 'other';

  for (let criteria of CRITERIA) {
    const { type: criteriaType, ...criteriaCoding } = criteria;

    if (
      _every(criteriaCoding, (codeValue, codeSystem) => {
        const resourceCodeValue = _get(
          _find(resourceCoding, { system: codeSystem }),
          'code'
        );

        return (
          resourceCodeValue &&
          (_isArray(codeValue)
            ? _includes(codeValue, resourceCodeValue)
            : resourceCodeValue === codeValue)
        );
      })
    ) {
      type = criteriaType;
      break;
    }
  }

  return type;
};
