import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Radium, { StyleRoot } from 'radium';
import { RegistrationActions } from '@healthmine/greyhound-core/src/actions';
import BasicInfoMarkup from '../../components/registration/BasicInfoMarkup';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import { hashHistory } from 'react-router';
import autobind from 'autobind-decorator';
import { Features } from '@healthmine/greyhound-core/src/constants';

class RegistrationBasicInfoPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopUpOpen: false,
      date: '',
      errors: [],
      validatedFields: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    const { status } = nextProps.state.registration.apiMemberStatus;
    if (status === 'ELIGIBLE') {
      hashHistory.push(
        AppConfig.getLingo('registrationOptInContentHtml')
          ? 'registration/opt-in'
          : 'registration/create-profile'
      );
    } else if (status === 'NOT-FOUND') {
      this.setState({ errors: ['Information Entered is Not Found'] });
    } else if (status === 'ALREADY-REGISTERED') {
      this.setState({ errors: ['User is Already Registered'] });
    } else if (status === 'NOT-ELIGIBLE') {
      this.setState({ errors: ['User is Not Eligible'] });
    }
  }

  render() {
    const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);
    const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);

    const newStyles = {
      imgContainer: {},
      bgImg: {},
    };

    const oldStyles = {
      imgContainer: {
        fontSize: '16px',
        overflowY: 'auto',
        position: 'relative',
        color: Colors.light,
        backgroundColor: leapFrogUI ? 'none' : Colors.light,
        overflow: 'hidden',
      },
      bgImg: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
      },
    };

    const styles = fiveFiveUI ? newStyles : oldStyles;

    const { form } = this.props.state.registration;
    return (
      <StyleRoot
        style={[styles.imgContainer, GlobalStyles.regular]}
        className="index"
      >
        <div style={styles.bgImg}>
          <BasicInfoMarkup
            formState={form}
            actions={this.props.actions}
            onDOBChange={this._setDOB}
            errors={this.state.errors}
            onClickHandler={this._continueClickHander}
            isPopUpOpen={this.state.isPopUpOpen}
            validatedFields={this.state.validatedFields}
            validateField={this._validateField}
            onPopUpChange={this._handlePopUp}
          />
        </div>
      </StyleRoot>
    );
  }
  @autobind
  _handlePopUp() {
    let isPopUpOpen = !this.state.isPopUpOpen;
    this.setState({ isPopUpOpen });
  }

  @autobind
  _validateField(feild) {
    if (this.state.validatedFields.indexOf(feild) === -1)
      this.setState({
        validatedFields: [...this.state.validatedFields, feild],
      });
  }
  @autobind
  _setDOB(value) {
    this.setState({ date: value }, function() {
      if (value && value.toString() !== 'Invalid Date')
        this.props.actions.updateDateOfBirth(value);
      else this.props.actions.updateDateOfBirth(undefined);
    });
  }
  @autobind
  _formIsValid() {
    let formIsValid = true;

    if (
      this.props.state.registration.form.lastName.value == '' ||
      this.props.state.registration.form.lastName.value == undefined
    ) {
      formIsValid = false;
      this.props.actions.updateLastName(
        this.props.state.registration.form.lastName.value
      );
    }

    const date = this.state.date;
    if (
      date.toString() === 'Invalid Date' ||
      this.props.state.registration.form.dateOfBirth.value == '' ||
      this.props.state.registration.form.dateOfBirth.value == undefined
    ) {
      formIsValid = false;
      this.props.actions.updateDateOfBirth(date);
    }

    if (
      this.props.state.registration.form.memberNumber.value == '' ||
      this.props.state.registration.form.memberNumber.value == undefined
    ) {
      formIsValid = false;
      this.props.actions.updateMemberNumber(
        this.props.state.registration.form.memberNumber.value
      );
    }

    return formIsValid;
  }
  @autobind
  _continueClickHander() {
    if (this._formIsValid() == false) {
      return;
    }
    this.props.actions.postMemberStatus();
  }
}

RegistrationBasicInfoPage.propTypes = {
  actions: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state: {
      registration: state.registration,
    },
  };
}

function mapDispatchToProps(dispatch) {
  //const {deviceConnected, getConnectedDeviceOptions} = RegistrationActions;
  return {
    actions: bindActionCreators(RegistrationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(RegistrationBasicInfoPage)
);
