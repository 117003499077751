import React from 'react';
import PropTypes from 'prop-types';
import CarePlanCardWrapper from './card/CarePlanCardWrapper';

const CarePlanSectionWrapper = ({
  children,
  isListView,
  trackers,
  coachingPrograms,
}) => {
  if (isListView) {
    return <div>{children}</div>;
  }

  return (
    <CarePlanCardWrapper
      trackers={trackers}
      coachingPrograms={coachingPrograms}
    >
      {children}
    </CarePlanCardWrapper>
  );
};

CarePlanSectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isListView: PropTypes.bool.isRequired,
  trackers: PropTypes.array,
  coachingPrograms: PropTypes.array,
};

export default CarePlanSectionWrapper;
