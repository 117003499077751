import React from 'react';
import { Grid, withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

const styles = () => ({
  root: {
    backgroundColor: '#E73B44',
    borderRadius: '20px',
    border: '7px solid #E41F29',
    fontFamily: 'Rubik',
    padding: '46px',
    paddingBottom: '32px',
    color: 'white',
    zIndex: '2',
    width: '750px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '38px',
    marginBottom: '16px',
    textAlign: 'center',
  },
  paragraph: {
    fontSize: '16px',
    marginBottom: '12px',
    textAlign: 'center',
    fontWeight: '300',
  },
  button: {
    backgroundColor: '#FEB600',
    fontSize: '26px',
    color: 'white',
    borderRadius: '8px',
    padding: '14px 16px',
    textTransform: 'uppercase',
    fontWeight: '500',
    fontFamily: 'Rubik',
    '&:hover': {
      backgroundColor: '#FEB600',
    },
  },
  confirm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonIconLeft: {
    fontSize: '18px',
    marginRight: '16px',
  },
  nevermind: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '36px',
    fontSize: '20px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonIconRight: {
    marginLeft: '16px',
    fontSize: '12px',
  },
  nevermindText: {
    textTransform: 'uppercase',
    paddingBottom: '4px',
    borderBottom: '2px solid white',
  },
});

const ExitWarningBox = ({ classes, doneWithExitWarning, text }) => (
  <Grid item container direction="column" className={classes.root}>
    <Grid item className={classnames(classes.centered, classes.title)}>
      {_.get(text, 'title')}
    </Grid>
    <Grid item className={classnames(classes.centered, classes.paragraph)}>
      <p>{_.get(text, 'description')}</p>
    </Grid>
    <div className={classes.confirm}>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => doneWithExitWarning(true)}
      >
        Confirm
      </Button>
    </div>
    <a className={classes.nevermind} onClick={() => doneWithExitWarning(false)}>
      <span className={classes.nevermindText}>Nevermind</span>
    </a>
  </Grid>
);

ExitWarningBox.propTypes = {
  doneWithExitWarning: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  text: PropTypes.object,
};

export default withStyles(styles)(ExitWarningBox);
