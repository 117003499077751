import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { LanguageConstants } from '@healthmine/greyhound-core/src/constants';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import { getStringForLocale } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import Freeform from './Freeform';
import DateComponent from './Date';
import PickerComponent from './Picker';
import Question from './Question';

const BPQuestionMarkup = ({
  questionNumber,
  questionCount,
  questions,
  bplastTakenAnswer,
  systolicBp,
  diastolicBp,
  setBloodPressure,
  setDate,
  isValid,
  validationError,
  canSkip,
  skipQuestion,
  hraLanguageCode,
}) => {
  const shouldDisable = !isValid || canSkip;

  const _styles = {
    root: {
      position: 'relative',
    },
    bpQuestion: {
      display: 'flex',
      alignItems: 'center',
    },
    freeform: {
      container: {
        width: '200px',
        margin: '0px',
      },
      input: {
        width: '80px',
        textAlign: 'center',
      },
      placeholder: {
        fontSize: '16px',
      },
      floatingPlaceholder: {
        display: 'none',
      },
      validValueIndicator: {
        display: 'none',
      },
    },
    input: {
      width: '80px',
    },
    date: {
      container: {
        marginTop: '25px',
        marginBottom: '15px',
      },
    },
    separator: {
      width: '50px',
      textAlign: 'center',
      fontSize: '35px',
      fontWeight: '100',
      padding: '0 5px',
      color: Colors.contentText,
    },
    picker: {
      smallBubble: {
        width: '15px',
        height: '15px',
      },
      textItem: {
        paddingTop: '0px !important',
      },
      label: {
        color: Colors.darkGray,
        fontSize: '16px',
      },
      mainPickerContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '0px',
      },
    },
    notApplicable: {
      paddingLeft: '10%',
    },
    question: {
      container: {
        paddingTop: '20px',
      },

      progress: {
        fontWeight: 500,
        fontSize: '16px',
        color: Colors.navyBlue,
        marginTop: '0px',
        paddingLeft: '10%',
      },

      questionTextContainer: {
        padding: '0px 10% 0px',
        minHeight: '30px',
        marginBottom: '0px',
      },

      questionText: {
        fontSize: '20px',
        color: Colors.bodyText,
      },

      questionContainer: {
        paddingLeft: '10%',
      },
    },
    subQuestion: {
      container: {
        paddingTop: '20px',
      },

      progress: {
        fontWeight: 500,
        fontSize: '16px',
        color: Colors.navyBlue,
        marginTop: '0px',
        paddingLeft: '10%',
      },

      questionTextContainer: {
        padding: '25px 10% 0px',
        minHeight: '0px',
        marginBottom: '0px',
      },

      questionText: {
        fontSize: '20px',
        color: shouldDisable ? Colors.contentText : Colors.bodyText,
      },

      questionContainer: {
        paddingLeft: '10%',
      },
    },
    unit: {
      fontSize: '16px',
      padding: '0 20px',
      marginBottom: '-25px',
      color: Colors.contentText,
    },
    instructionText: {
      color: Colors.darkGray,
      fontSize: '16px',
      fontWeight: '500',
    },
    disabledContent: {
      color: Colors.lightGray,
    },
    errorMessage: {
      color: Colors.red,
      margin: '0 0 20px',
    },
  };

  const updateSystolicBp = (event) => setBloodPressure({ systolicBp: event });

  const updateDiastolic = (event) => setBloodPressure({ diastolicBp: event });

  const questionConfig = getStringForLocale('questionConfig', hraLanguageCode);

  const dateConfig = questionConfig['HNA_BP.LAST.DATE.TAKEN'];

  const properties = dateConfig && dateConfig.properties;

  return (
    <div style={[_styles.root, GlobalStyles.regular]}>
      <Question
        currentQuestionNumber={questionNumber}
        questionCount={questionCount}
        questionText={
          questions[0].question[hraLanguageCode] ||
          questions[0].question[LanguageConstants.LanguageCodes.EN]
        }
        hraLanguageCode={hraLanguageCode}
        styles={_styles.question}
      >
        <div style={_styles.bpQuestion}>
          <div style={_styles.input}>
            <Freeform
              placeholder="Ex. 140"
              styles={_styles.freeform}
              onAnswered={updateSystolicBp}
              value={systolicBp}
            />
          </div>
          <div className="slant" style={_styles.separator}>
            /
          </div>
          <div style={_styles.input}>
            <Freeform
              placeholder="Ex. 90"
              styles={_styles.freeform}
              onAnswered={updateDiastolic}
              value={diastolicBp}
            />
          </div>
          <div style={_styles.unit}>mmHg</div>
        </div>
        {validationError && (
          <p style={_styles.errorMessage}>{validationError}</p>
        )}
      </Question>
      <div style={_styles.notApplicable}>
        <PickerComponent
          options={[
            {
              label:
                getStringForLocale(
                  'additionalTexts.hraUnknownBP',
                  hraLanguageCode
                ) || '',
              value: canSkip,
            },
          ]}
          values={[true]}
          styles={_styles.picker}
          onAnswered={skipQuestion}
        />
      </div>
      <div>
        <Question
          currentQuestionNumber={questionNumber}
          questionCount={questionCount}
          questionText={
            questions[1].question[hraLanguageCode] ||
            questions[1].question[LanguageConstants.LanguageCodes.EN]
          }
          subQuestion
          styles={_styles.subQuestion}
        >
          <DateComponent
            value={bplastTakenAnswer}
            onAnswered={(answer, isValid) => setDate(answer, isValid)}
            fullMonth
            hraLanguageCode={hraLanguageCode}
            disabled={shouldDisable}
            styles={_styles.date}
            {...properties}
          />
          <div
            style={[
              _styles.instructionText,
              shouldDisable ? _styles.disabledContent : null,
            ]}
          >
            {getStringForLocale(
              'additionalTexts.hraBestGuess',
              hraLanguageCode
            ) || ''}
          </div>
        </Question>
      </div>
    </div>
  );
};

BPQuestionMarkup.propTypes = {
  questionNumber: PropTypes.number,
  questionCount: PropTypes.number,
  questions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  systolicBp: PropTypes.string,
  diastolicBp: PropTypes.string,
  bplastTakenAnswer: PropTypes.string,
  setBloodPressure: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  validationError: PropTypes.string,
  canSkip: PropTypes.bool,
  skipQuestion: PropTypes.func,
  hraLanguageCode: PropTypes.string,
};

export default Radium(BPQuestionMarkup);
