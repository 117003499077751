export const types = {
  POPUP_MENU_SET_VISIBILITY: 'web/popupMenu/setVisibility',
};

function setPopupMenuVisibility(visible: boolean) {
  return {
    type: types.POPUP_MENU_SET_VISIBILITY,
    visible,
  };
}

export default {
  setPopupMenuVisibility,
};
