import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '20px',
    backgroundColor: theme.palette.common.formulaOne,
    borderRadius: theme.globalStyles.borderRadius1,
    margin: '0 auto',
    marginTop: '10px',
  },
  progressionFill: {
    height: '20px',
    borderRadius: theme.globalStyles.borderRadius1,
    backgroundColor: theme.palette.primary.main,
  },
});

const ProgressBar = ({ classes, className, percentage, color }) => {
  return (
    <div className={classnames(classes.root, className)}>
      <div
        className={classes.progressionFill}
        style={{ backgroundColor: color, width: `${percentage}%` }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default withStyles(styles)(ProgressBar);
