import React from 'react';
import PropTypes from 'prop-types';
const page = {
  fontSize: '16px',
  margin: '0px auto',
};

class OnboardingContainer extends React.Component {
  render() {
    return <div style={page}>{this.props.children}</div>;
  }
}

OnboardingContainer.propTypes = {
  children: PropTypes.object.isRequired,
};

export default OnboardingContainer;
