export default {
  sectionHeading: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    marginBottom: '12px',
  },

  complianceBox: {
    background: '#fff',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    padding: '30px',
    position: 'relative',
    marginBottom: '40px',
  },

  title: {
    fontSize: '18px',
    fontWeight: '500',
  },

  description: {
    marginTop: '12px',
    fontSize: '18px',
    lineHeight: '22px',
  },
};
