import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withStateHandlers } from 'recompose';
import ReactMarkdown from 'react-markdown';
import {
  DrawerContent,
  DrawerSection,
  DrawerSectionHeader,
  DrawerNavigation,
  withDrawer,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';
import {
  WithBiometricReportSelection,
  WithPreventiveActions,
} from '@healthmine/greyhound-core/src/hocs';
import BiometricResultsDrawer from './BiometricResults';
import EvaluatingResults from './EvaluatingResults';
import BiometricScreeningConstants from '@healthmine/greyhound-core/src/constants/BiometricScreening';
import BiometricModals from './BiometricModals';

const Status = BiometricScreeningConstants.Status;

const WhatToExpectDrawer = ({
  biometricScreeningOnlySupportsPhysicianScreeningForm,
  biometricScreeningPreventiveActionDisplay,
  biometricScreeningStatus,
  biometricScreeningSupportsOnsiteEvents,
  biometricScreeningSupportsOffsiteLabs,
  biometricScreeningSupportsPhysicianScreeningForm,
  biometricScreeningWhatToExpectVerbiage,
  drawer,
  reportOptions,
  showBiometricModal,
  toggleBiometricModal,
}) => {
  return (
    <DrawerContent title={biometricScreeningPreventiveActionDisplay}>
      {!biometricScreeningOnlySupportsPhysicianScreeningForm && (
        <DrawerSectionHeader title="What Can I Expect at a Biometric Screening?" />
      )}

      <DrawerSection>
        <Typography>
          <ReactMarkdown source={biometricScreeningWhatToExpectVerbiage} />
        </Typography>
      </DrawerSection>
      {biometricScreeningStatus === Status.SCHEDULED && (
        <DrawerNavigation
          title="View Current Appointment"
          iconClass="icon-Calendar-Schedule"
          onClick={toggleBiometricModal}
        />
      )}
      {reportOptions.length > 0 && (
        <DrawerNavigation
          title="View Results"
          iconClass="icon-Graph-Clipboard"
          onClick={() =>
            drawer.open(BiometricResultsDrawer, null, { large: true })
          }
        />
      )}
      {reportOptions.length === 0 &&
        biometricScreeningStatus === Status.COMPLETED && (
          <DrawerNavigation
            title="View Results"
            iconClass="icon-Graph-Clipboard"
            onClick={() => drawer.open(EvaluatingResults)}
          />
        )}
      {biometricScreeningSupportsOnsiteEvents && (
        <DrawerNavigation
          title="View Onsite Screening Events"
          iconClass="icon-Calendar-Schedule"
          onClick={toggleBiometricModal}
        />
      )}
      {biometricScreeningSupportsOffsiteLabs && (
        <DrawerNavigation
          title="Offsite Lab Screening"
          iconClass="icon-Calendar-Event"
          onClick={toggleBiometricModal}
        />
      )}
      {biometricScreeningSupportsPhysicianScreeningForm && (
        <DrawerNavigation
          title="Physician Screening Form"
          iconClass="icon-Document-Lines"
          onClick={toggleBiometricModal}
        />
      )}
      <BiometricModals
        screeningType={1}
        showModals={showBiometricModal}
        onClose={toggleBiometricModal}
      />
    </DrawerContent>
  );
};

WhatToExpectDrawer.propTypes = {
  biometricScreeningOnlySupportsPhysicianScreeningForm:
    PropTypes.bool.isRequired,
  biometricScreeningPreventiveActionDisplay: PropTypes.string.isRequired,
  biometricScreeningStatus: PropTypes.string.isRequired,
  biometricScreeningSupportsOffsiteLabs: PropTypes.bool.isRequired,
  biometricScreeningSupportsOnsiteEvents: PropTypes.bool.isRequired,
  biometricScreeningSupportsPhysicianScreeningForm: PropTypes.bool.isRequired,
  biometricScreeningWhatToExpectVerbiage: PropTypes.string.isRequired,
  drawer: PropTypes.object.isRequired,
  reportOptions: PropTypes.array.isRequired,
  showBiometricModal: PropTypes.bool.isRequired,
  toggleBiometricModal: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withDrawer,
  WithBiometricReportSelection,
  WithPreventiveActions,
  withStateHandlers(
    {
      showBiometricModal: false,
    },
    {
      toggleBiometricModal: ({ showBiometricModal }) => () => ({
        showBiometricModal: !showBiometricModal,
      }),
    }
  )
)(WhatToExpectDrawer);
