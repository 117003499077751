import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import { withDemoOverrides } from '@healthmine/greyhound-core/src/hocs';
import styles from './Demofy.styles';

const DemofyCoaching = ({ coachingOverrides, override }) => (
  <div style={styles.root}>
    <div style={styles.title}>Coaching withDemoOverrides</div>
    <a style={styles.link} onClick={() => override()}>
      COACHING_NO_MOCK
    </a>
    {coachingOverrides.map((x) => (
      <a key={x} style={styles.link} onClick={() => override(x)}>
        {x}
      </a>
    ))}
  </div>
);

DemofyCoaching.propTypes = {
  override: PropTypes.func.isRequired,
  coachingOverrides: PropTypes.array.isRequired,
};

export default compose(
  pure,
  withRouter,
  withDemoOverrides,
  withHandlers({
    override: ({ demoCoaching, router }) => (type) => {
      demoCoaching(type).then(() => router.push('/health-overview'));
    },
  })
)(DemofyCoaching);
