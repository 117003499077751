import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const InfoPopupMarkup = ({
  children,
  displayPopup,
  onHoverStart,
  onHoverEnd,
  styles,
}) => {
  const compositeStyles = {
    container: {
      display: 'inline-block',
      textAlign: 'right',
      position: 'relative',
      ...styles.container,
    },

    icon: {
      display: 'inline-block',
      width: '26px',
      height: '26px',
      lineHeight: '26px',
      color: Colors.hexToRgba(Colors.contentText),
      backgroundColor: Colors.hexToRgba(Colors.lighterGray),
      borderRadius: '13px',
      textAlign: 'center',
      fontSize: '16px',
      marginLeft: '10px',
      cursor: 'pointer',
      ...styles.icon,
    },

    popupContainer: {
      textAlign: 'left',
      position: 'absolute',
      right: '16px',
      marginTop: '24px',
      zIndex: 4000,
      ...styles.popupContainer,
    },

    popupStem: {
      display: 'inline-block',
      position: 'absolute',
      top: '-20px',
      right: '0',
      borderTop: '20px solid transparent',
      borderRight: `15px solid ${Colors.hexToRgba(Colors.lighterGray)}`,
      borderBottom: '0 solid transparent',
      borderLeft: '0 solid transparent',
      ...styles.popupStem,
    },

    popupContent: {
      margin: '0',
      padding: '6px 10px 8px',
      borderRadius: '10px 0 10px 10px',
      position: 'relative',
      fontSize: '16px',
      fontWeight: 'bold',
      wordWrap: 'break-word',
      backgroundColor: Colors.hexToRgba(Colors.lighterGray),
      color: Colors.dark,
      lineHeight: '1.5',
      ...styles.popupContent,
    },
  };

  return (
    <div
      onMouseEnter={() => onHoverStart()}
      onMouseLeave={() => onHoverEnd()}
      style={compositeStyles.container}
    >
      <span style={compositeStyles.icon}>i</span>
      {displayPopup ? (
        <div style={compositeStyles.popupContainer}>
          <div style={compositeStyles.popupStem} />
          <div style={compositeStyles.popupContent}>{children}</div>
        </div>
      ) : null}
    </div>
  );
};

InfoPopupMarkup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.nodes),
    PropTypes.node,
  ]).isRequired,

  displayPopup: PropTypes.bool.isRequired,
  onHoverStart: PropTypes.func.isRequired,
  onHoverEnd: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
};

export default Radium(InfoPopupMarkup);
