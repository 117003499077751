import React from 'react';
import PropTypes from 'prop-types';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import flattenProp from 'recompose/flattenProp';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import CarePlanBubble from './CarePlanBubble';
import CarePlanBody from './CarePlanBody';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { Subjects } from '@healthmine/greyhound-core/src/constants/CarePlan';
import moment from 'moment';

const styles = {
  carePlan: {
    display: 'flex',
    width: '298px',
    maxWidth: '298px',
    height: '250px',
    maxHeight: '250px',
    margin: '0.5px',
    backgroundColor: Colors.white,
    borderBottom: `1px solid ${Colors.gutterGray}`,
    borderRight: `1px solid ${Colors.gutterGray}`,
  },
  carePlanInner: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '40px 25px 20px',
  },
};

const measurementForAction = (primaryTracking) => ({
  value: primaryTracking.latest || 0,
  date: primaryTracking.latestDate,
  label: primaryTracking.unit,
  connected: primaryTracking.manual === false,
});

const measurementForPlan = (carePlan) => {
  if (carePlan.subject === Subjects.PREVENTIVE)
    return {
      value: `${carePlan.completedCount}/${carePlan.totalCount}`,
      label: 'Complete',
    };

  if (carePlan.status === AppConstants.PlanStatus.ASSIGNED) return null;

  if (carePlan.subject === Subjects.SMOKING)
    return {
      value: moment(carePlan.endDate).diff(moment(), 'days') || 0,
      label: 'days left',
    };

  if (!carePlan.primaryTracking) return null;

  return measurementForAction(carePlan.primaryTracking);
};

const callToActionFor = (carePlan) => {
  if (carePlan.status === AppConstants.PlanStatus.ASSIGNED) return null;
  if (carePlan.subject === Subjects.PREVENTIVE) return null;
  if (!carePlan.primaryTracking) return null;

  return {
    text: carePlan.primaryTracking.text,
    color: Colors.highlightBlue,
  };
};

const calloutDetail = {
  title: 'My Care Plans',
  text: `Each plan is your key to understanding how you can improve your health. 
          Plans contain important actions you can take to earn rewards.`,
};

const CarePlanCard = ({
  carePlan,
  tutorial,
  selectCarePlan,
  activateTracking,
  activatePrimaryTracking,
}) => {
  return (
    <div style={styles.carePlan}>
      <div style={styles.carePlanInner}>
        <CarePlanBubble
          carePlan={carePlan}
          tutorial={tutorial}
          calloutDetail={calloutDetail}
          incentiveCurrency={AppConfig.effectiveConfig.incentiveCurrency}
          selectCarePlan={selectCarePlan}
          callToAction={callToActionFor(carePlan)}
          activateTracking={activatePrimaryTracking}
          measure={measurementForPlan(carePlan)}
        />
        <CarePlanBody
          carePlan={carePlan}
          selectCarePlan={selectCarePlan}
          activateTracking={activateTracking}
        />
      </div>
    </div>
  );
};

CarePlanCard.propTypes = {
  carePlan: PropTypes.shape({
    subject: PropTypes.string.isRequired,
    iconClass: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checkpoint: PropTypes.bool,
    tracking: PropTypes.object,
    primaryTracking: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
  tutorial: PropTypes.bool,
  selectCarePlan: PropTypes.func,
  activateTracking: PropTypes.func,
  activatePrimaryTracking: PropTypes.func,
};

export default compose(
  withRouter,
  withHandlers({
    activatePrimaryTracking: ({ carePlan, activateTracking }) => () => {
      if (carePlan.primaryTracking) {
        activateTracking(carePlan.primaryTracking.type);
      }
    },
    selectCarePlan: ({ router, carePlan }) => () => router.push(carePlan.route),
  }),
  flattenProp('carePlan')
)(CarePlanCard);
