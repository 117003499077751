import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Svg from '@healthmine/greyhound-core/src/images/Svg';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import InputElement from 'react-input-mask';

const FreeformMarkup = ({
  placeholder,
  inputType,
  moduleType,
  validationError,
  styles,
  onFocus,
  onBlur,
  onChange,
  onPressEnter,
  value,
  isActive,
  isValid,
  mask,
  errorWhenInactive,
  displayError,
}) => {
  const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);

  const __DEFAULT_FORMAT_CHARS__ = {
    '9': '[0-9]',
    a: '[A-Za-z]',
    '*': '[A-Za-z0-9]',
  };

  const _styles = {
    container: {
      width: '320px',
      padding: '20px 0',
      position: 'relative',
      margin: '20px 0',
      ...styles.container,
    },
    input: {
      fontSize: '16px',
      padding: '10px 0px',
      display: 'block',
      width: '100%',
      maxWidth: '330px',
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      outline: 'none',
      borderBottom: '1px solid #92aeba',
      ...styles.input,
    },
    placeholder: {
      fontSize: '16px',
      color: Colors.contentText,
      position: 'absolute',
      top: '30px',
      transition: '0.2s ease all',
      pointerEvents: 'none',
      ...styles.placeholder,
    },
    floatingPlaceholder: {
      top: 0,
      ...styles.floatingPlaceholder,
    },
    errorIndicator: {
      color: Colors.red,
      padding: '5px 0',
      margin: 0,
      ...styles.errorIndicator,
    },
    noteIndicator: {
      color: Colors.contentText,
      padding: '5px 0',
      margin: 0,
      textAlign: 'left',
      ...styles.noteIndicator,
    },
    validValueIndicator: {
      position: 'absolute',
      width: '24px',
      right: 0,
      top: '30px',
      display: 'block',
      fill: '#7fc24a',
      ...styles.validValueIndicator,
    },
    tickIcon: {
      position: 'absolute',
      top: '13px',
      right: 0,
      fontSize: '17px',
      color: Colors.lightGray,
    },
    validInput: {
      fontWeight: 600,
      color: Colors.black,
      cursor: 'pointer',
    },
  };

  const showError =
    validationError && value && !isValid && (errorWhenInactive || !isActive);

  return (
    <div style={[GlobalStyles.regular, _styles.container]}>
      <InputElement
        id="Freeform"
        className={!leapFrogUI ? 'inputMaterial' : ''}
        type={inputType}
        mask={mask.placeholder}
        formatChars={mask.formatChars || __DEFAULT_FORMAT_CHARS__}
        style={_styles.input}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required
        maskChar={mask.maskChar === undefined ? ' ' : mask.maskChar}
        onKeyDown={isValid ? onPressEnter : displayError}
      />

      <label
        style={[
          _styles.placeholder,
          isActive || value ? _styles.floatingPlaceholder : null,
        ]}
      >
        {placeholder}
      </label>

      {isValid && !moduleType ? (
        <span style={_styles.validValueIndicator}>{Svg.CHECKMARK}</span>
      ) : null}

      {isValid && moduleType ? (
        <i
          style={
            isValid ? [_styles.tickIcon, _styles.validInput] : _styles.tickIcon
          }
          className="thin-0154_ok_successful_check"
          onClick={isValid ? () => onPressEnter(null, value) : null}
        />
      ) : null}

      {showError && <p style={_styles.errorIndicator}>{validationError}</p>}
      {!showError &&
        moduleType === AppConstants.ModuleType.BLOOD_PRESSURE && (
          <p style={_styles.noteIndicator}>e.g. 120/80</p>
        )}
    </div>
  );
};

FreeformMarkup.propTypes = {
  placeholder: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validationError: PropTypes.string,
  styles: PropTypes.object,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPressEnter: PropTypes.func,
  isActive: PropTypes.bool,
  isValid: PropTypes.bool,
  mask: PropTypes.object,
  moduleType: PropTypes.string,
  errorWhenInactive: PropTypes.bool,
  displayError: PropTypes.func,
};

FreeformMarkup.defaultProps = {
  inputType: 'text',
  styles: {},
  moduleType: '',
  mask: {},
};

export default Radium(FreeformMarkup);
