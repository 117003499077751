import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FteActions } from '@healthmine/greyhound-core/src/actions';
import RoutineMarkup from '../../components/onboarding/RoutineMarkup';
import _ from 'lodash';

class RoutineContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }
  render() {
    const { errors } = this.state;

    const {
      physicalFitness,
      goodFoodChoice,
      badFoodChoice,
      goodFoodAnswerChoices,
      badFoodAnswerChoices,
    } = this.props;

    const {
      setPhysicalFitness,
      updateGoodFoodChoice,
      updateBadFoodChoice,
    } = this.props.actions;

    return (
      <RoutineMarkup
        fitnessAnswer={physicalFitness.healthAnswer.textAnswers[0]}
        goodFoodAnswer={goodFoodChoice.healthAnswer.questionAnswerOptionId[0]}
        goodFoodAnswerChoices={goodFoodAnswerChoices}
        badFoodAnswerChoices={badFoodAnswerChoices}
        badFoodAnswer={badFoodChoice.healthAnswer.questionAnswerOptionId[0]}
        onClickFitness={setPhysicalFitness}
        onClickGoodFood={updateGoodFoodChoice}
        onClickBadFood={updateBadFoodChoice}
        errors={errors}
        scrollDown={this._continueClickHander}
        scrollUp={this._scrollUp}
        skip={this._scrollDown}
      />
    );
  }

  _scrollUp = () => {
    this.props.scrollUp(this.props.step - 1);
  };

  _scrollDown = () => {
    this.props.scrollDown(this.props.step + 1);
  };

  _optionIsSelected = () => {
    let isSelected = true;

    let errors = [];
    if (
      !this.props.physicalFitness.isValid ||
      !this.props.goodFoodChoice.isValid ||
      !this.props.badFoodChoice.isValid
    ) {
      errors = [
        ...errors,
        'Please select your exercise, good food and bad food choices',
      ];
      isSelected = false;
    }

    this.setState({ errors });
    return isSelected;
  };

  _continueClickHander = () => {
    if (!this._optionIsSelected()) {
      return;
    }
    const { physicalFitness, goodFoodChoice, badFoodChoice } = this.props;

    const { postHealthAnswers } = this.props.actions;
    postHealthAnswers([
      goodFoodChoice.healthAnswer,
      badFoodChoice.healthAnswer,
      physicalFitness.healthAnswer,
    ]).then(this._scrollDown);
  };
}

RoutineContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  scrollDown: PropTypes.func.isRequired,
  scrollUp: PropTypes.func.isRequired,
  vis: PropTypes.bool,
  colorful: PropTypes.bool,
  step: PropTypes.number.isRequired,
  physicalFitness: PropTypes.object,
  goodFoodChoice: PropTypes.object,
  badFoodChoice: PropTypes.object,
  goodFoodAnswerChoices: PropTypes.array,
  badFoodAnswerChoices: PropTypes.array,
};

function mapStateToProps(state) {
  const {
    physicalFitness,
    goodFoodChoice,
    badFoodChoice,
    questionnaire,
  } = state.onBoarding;

  const questions = _.get(questionnaire, 'questions');

  const goodFoodQuestion = _.find(questions, [
    'id',
    goodFoodChoice.healthAnswer.questionId,
  ]);

  const badFoodQuestion = _.find(questions, [
    'id',
    badFoodChoice.healthAnswer.questionId,
  ]);

  const goodFoodAnswerChoices = _.get(goodFoodQuestion, 'answerOptions');

  const badFoodAnswerChoices = _.get(badFoodQuestion, 'answerOptions');

  return {
    physicalFitness,
    goodFoodChoice,
    badFoodChoice,
    goodFoodAnswerChoices,
    badFoodAnswerChoices,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    setPhysicalFitness,
    updateGoodFoodChoice,
    updateBadFoodChoice,
    postHealthAnswers,
  } = FteActions;
  return {
    actions: bindActionCreators(
      {
        setPhysicalFitness,
        updateGoodFoodChoice,
        updateBadFoodChoice,
        postHealthAnswers,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(RoutineContainer)
);
