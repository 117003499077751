import PropTypes from 'prop-types';
import React from 'react';
import { compose, pure, withState } from 'recompose';
import { WithGinaAgreement } from '@healthmine/greyhound-core/src/hocs';
import { Assets } from '@healthmine/greyhound-core/src/modules';
import { Button, Grid, Modal, Checkbox, withStyles } from '@material-ui/core';
import Typography from '../common/Typography';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
  },
  hero: {
    color: theme.palette.common.white,
    paddingTop: '80px',
    paddingLeft: '70px',
    paddingRight: '170px',
    height: '250px',
    backgroundImage: `url(${require('../../../images/background/gina.png')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'right 15%',
  },
  frameContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  frame: {
    flexGrow: 1,
    padding: '0 70px',
  },
  checkboxContainer: {
    height: '45px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    margin: '0px 70px 16px 70px',
  },
  checkboxLabel: {
    cursor: 'pointer',
    color: theme.palette.common.black,
    fontSize: '16px',
    marginRight: '10px',
  },
  buttonContainer: {
    paddingBottom: '20px',
    textAlign: 'center',
  },
  button: {
    backgroundColor: theme.palette.common.blueGray,
    color: theme.palette.common.white,
    padding: '10px 140px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.beefy,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.formulaOne,
      color: theme.palette.common.white,
    },
  },
});

const GinaModal = ({
  classes,
  isSpouse,
  createMemberGinaAgreement,
  isAcknowledged,
  updateIsAcknowledged,
}) => (
  <Modal open disableBackdropClick>
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.hero}>
        <Typography type="h1">
          Genetic Information Nondiscrimination Act
        </Typography>
        <Typography type="h4">
          The Genetic Information Nondiscrimination Act of 2008 (GINA) is a
          federal law that protects individuals from genetic discrimination in
          health insurance and employment. Genetic discrimination is the misusue
          of genetic information.
        </Typography>
      </Grid>
      <Grid item className={classes.frameContainer}>
        <iframe
          src={Assets.getAssetURL(
            `gina_${isSpouse ? 'spouse' : 'dependent'}_en.html`
          )}
          frameBorder="0"
          className={classes.frame}
        />
      </Grid>
      <Grid item className={classes.checkboxContainer}>
        <label htmlFor="GINA_checkbox" className={classes.checkboxLabel}>
          Check here to indicate that you have read and agree to the terms of
          the GINA Authorization.
        </label>
        <Checkbox
          className={classes.checkbox}
          disabled={isAcknowledged}
          id="GINA_checkbox"
          onChange={() => updateIsAcknowledged(true)}
          checked={isAcknowledged}
        />
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button
          variant="flat"
          className={classes.button}
          disabled={!isAcknowledged}
          onClick={createMemberGinaAgreement}
        >
          Enter
        </Button>
      </Grid>
    </Grid>
  </Modal>
);

GinaModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isSpouse: PropTypes.bool.isRequired,
  createMemberGinaAgreement: PropTypes.func.isRequired,
  isAcknowledged: PropTypes.bool.isRequired,
  updateIsAcknowledged: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  WithGinaAgreement,
  withState('isAcknowledged', 'updateIsAcknowledged', false)
)(GinaModal);
