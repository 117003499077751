import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  CompletionStatus,
  HRAState,
} from '@healthmine/greyhound-core/src/constants/CarePlan';
import { selectHraStatus } from '@healthmine/greyhound-core/src/selectors/HraSelectors';

const styles = {
  callToAction: {
    color: '#1E80F0',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
};

const hraCaption = (status) => {
  if (!status) return null;

  const { lingo } = AppConfig.effectiveConfig;
  switch (status) {
    case AppConstants.QuestionnaireStatus.COMPLETED: {
      return HRAState.hraCompleted(lingo);
    }
    case AppConstants.QuestionnaireStatus.STARTED: {
      return HRAState.hraStarted(lingo);
    }
    case AppConstants.QuestionnaireStatus.NOT_STARTED: {
      return HRAState.hraStateNotStarted(lingo);
    }
    default:
      return null;
  }
};

const hraRoute = (hraStatus) =>
  hraStatus === CompletionStatus.COMPLETED ? '/hra-result' : '/hra';

const HRALink = ({ hraCaption, goToHra }) => (
  <a onClick={goToHra} style={styles.callToAction}>
    {hraCaption}
  </a>
);

HRALink.propTypes = {
  hraCaption: PropTypes.string,
  goToHra: PropTypes.func,
  hraStatus: PropTypes.string,
};

export default compose(
  withRouter,
  connect((state) => {
    const hraStatus = selectHraStatus(state);

    return {
      hraCaption: hraCaption(hraStatus),
      hraStatus,
    };
  }),
  withHandlers({
    goToHra: ({ router, hraStatus }) => () => router.push(hraRoute(hraStatus)),
  }),
  Radium
)(HRALink);
