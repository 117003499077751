import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const styles = (theme) => ({
  root: {
    borderRadius: theme.globalStyles.borderRadius1,
    boxShadow: theme.globalStyles.shadow2,
    padding: '20px',
    backgroundColor: '#fff',
    transition: theme.globalStyles.transition1,
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.globalStyles.shadow3,
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  primary: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
});

const HMCard = ({ children, className, onClick, onMouseOver, onMouseOut }) => {
  return (
    <div
      className={className}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children}
    </div>
  );
};

HMCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  customClass: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  column: PropTypes.bool,
  primary: PropTypes.bool,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ classes, customClass, onClick, column, primary }) => {
    const customClassStr = customClass ? customClass : '';

    const link = !_.isUndefined(onClick) ? classes.link : '';

    const columnClass = column ? classes.column : '';

    const primaryClass = primary ? classes.primary : '';

    return {
      className: `${
        classes.root
      } ${columnClass} ${primaryClass} ${link} ${customClassStr}`,
    };
  })
)(HMCard);
