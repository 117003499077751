import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BiometricScreeningActions } from '@healthmine/greyhound-core/src/actions';
import WelcomeBackModalMarkup from './WelcomeBackModalMarkup';

class WelcomeBackModal extends React.Component {
  render() {
    return <WelcomeBackModalMarkup onClose={this._onClose} />;
  }

  _onClose = () => {
    this.props.actions.getEvents();
    this.props.onClose();
  };
}

WelcomeBackModal.propTypes = {
  onClose: PropTypes.func.isRequired,

  actions: PropTypes.shape({
    getEvents: PropTypes.func.isRequired,
  }).isRequired,
};

function mapDispatchToProps(dispatch) {
  const { getEvents } = BiometricScreeningActions;

  return {
    actions: bindActionCreators({ getEvents }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(WelcomeBackModal);
