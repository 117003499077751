import React from 'react';
import PropTypes from 'prop-types';
import DateMarkup from './DateMarkup';
import moment from 'moment';

class DateComponent extends React.Component {
  constructor(props) {
    super(props);
    const currDate = moment(props.value || '');

    const validDate = currDate.isValid();

    const day = validDate ? currDate.date() : -1;

    const month = validDate ? currDate.month() + 1 : -1;

    const year = validDate ? currDate.year() : -1;
    this.state = {
      day,
      month,
      year,
    };
    this._setDate = this._setDate.bind(this);
    this._isValid = this._isValid.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const currDate = moment(nextProps.value || '');

      const validDate = currDate.isValid();

      const day = validDate ? currDate.date() : -1;

      const month = validDate ? currDate.month() + 1 : -1;

      const year = validDate ? currDate.year() : -1;
      this.setState({ day, month, year });
    }
  }
  render() {
    const { day, month, year } = this.state;
    return (
      <DateMarkup
        {...this.props}
        setDate={this._setDate}
        day={day}
        month={month}
        year={year}
        isValid={this.state.isValid}
      />
    );
  }

  _setDate(value) {
    this.setState(value, function() {
      const { month, day, year } = this.state;
      if (month > 0 && day > 0 && year > 0) {
        const thisMoment = moment([year, month - 1, day]);

        const isValid = this._isValid(thisMoment);

        this.setState({ isValid });

        if (thisMoment.isValid()) {
          this.props.onAnswered(thisMoment.format('YYYY-MM-DD'), isValid);
        }
      }
    });
  }
  _isValid(value) {
    const { minDate, maxDate } = this.props;

    const fromDate = moment(minDate);

    const toDate = moment(maxDate);

    const momentValue = moment(value);

    return (
      momentValue.isSameOrBefore(toDate, 'day') &&
      momentValue.isSameOrAfter(fromDate, 'day')
    );
  }
}

DateComponent.propTypes = {
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  value: PropTypes.string,
  fullMonth: PropTypes.bool,
  onAnswered: PropTypes.func.isRequired,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  useDefaultSelect: PropTypes.bool,
};

export default DateComponent;
