import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import CheckpointModuleCard from './checkpoint/ModuleCard';
import HealthActionFormLink from '../common/HealthActionFormLink';

const ClinicalTrackingMarkup = ({
  isCheckpointMode,
  carePlan,
  clinicalActions,
  iconClass,
  iconColor,
  bubbleColor,
  bubbleFilled,
  title,
  index,
  expanded,
  toggleDetails,
  measure,
  onBubbleClick,
  onTitleClick,
  styleOverrides,
  detailHeight,
}) => {
  const styles = {
    section: {
      flex: '0 0 299px',
      boxSizing: 'border-box',
      minWidth: '299px',
      height: '250px',
      maxHeight: '250px',
      borderBottom: expanded ? 'none' : `1px solid ${Colors.gutterGray}`,
      borderRight: `1px solid ${Colors.gutterGray}`,
      marginBottom: expanded ? `${detailHeight}px` : 0,
    },
    content: {
      padding: 10,
      position: 'relative',
      height: '100%',
      boxSizing: 'border-box',
    },
    icon: {
      display: 'flex',
      border: `2px solid ${bubbleColor}`,
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      padding: '0px',
      fontSize: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: bubbleFilled ? bubbleColor : 'transparent',
      cursor: onBubbleClick ? 'pointer' : 'default',
    },
    title: {
      fontSize: '16px',
      cursor: onTitleClick ? 'pointer' : 'default',
      margin: '10px 0 20px',
      textAlign: 'center',
      ...styleOverrides.title,
    },
    measureContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginTop: '3px',
      color: '#C5D2DD',
      ...styleOverrides.measureContainer,
    },
    measureValue: {
      fontSize: '28px',
    },
    measureLabel: {
      fontSize: '27px',
      margin: 'auto 0px auto 20px',
    },
    callToAction: {
      marginTop: '20px',
      fontSize: '16px',
      textAlign: 'center',
      color: Colors.highlightBlue,
    },
    callToActionLink: {
      color: Colors.highlightBlue,
      textDecoration: 'none',
      fontSize: '16px',
      justifyContent: 'center',
      textAlign: 'end',
      marginTop: '20px',
      marginRight: '10px',
      cursor: 'pointer',
      float: 'right',
    },
    completed: {
      width: '100px',
      textAlign: 'left',
      color: Colors.measurement.missing,
      margin: '20px 0px auto 10px',
    },
    actionList: {
      marginLeft: '45px',
      fontSize: '16px',
      fontWeight: '300',
      color: Colors.bodyText,
      marginBottom: '15px',
    },
    actionComplete: {
      color: Colors.white,
      position: 'relative',
      top: '1px',
      padding: '2px',
      marginLeft: '8px',
      fontWeight: '600',
      fontSize: '16px',
      backgroundColor: Colors.linkBlue,
      borderRadius: '50%',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginLeft: '20px',
      marginBottom: '30px',
    },
    callToActionLabel: {
      cursor: 'pointer',
      marginLeft: '-20px',
      ':hover': {
        fontWeight: '600',
      },
    },
    detailsLabel: {
      color: Colors.lightSlateGray,
      cursor: 'pointer',
      position: 'absolute',
      paddingRight: '28px',
      marginTop: 0,
      marginBottom: 0,
      textAlign: 'right',
      fontSize: '16px',
      bottom: '15px',
      right: '10px',
    },

    detailsContent: isCheckpointMode
      ? {}
      : {
          backgroundColor: Colors.light,
          padding: '40px',
          display: 'none',
          transition: '0.5s ease all',
          borderLeft: `1px solid ${Colors.gutterGray}`,
          borderBottom: `1px solid ${Colors.gutterGray}`,
          position: 'absolute',
          left: `-${(index % 3) * 300 + 1}px`,
          right: `${(index % 3) * 300 - 600 - 2}px`,
          top: 250,
          zIndex: 9,
          width: '800px',
        },

    detailsContentActive: {
      display: 'block',
    },

    actionsContent: {
      display: 'flex',
      textAlign: 'left',
    },

    actionColumn: {
      flex: '1 1 50%',
      padding: '0 20px',
    },
    actionTitle: {
      fontSize: '16px',
      marginTop: 0,
      color: Colors.bodyText,
    },
    actionDescription: {
      fontSize: '16px',
      marginBottom: '20px',
      lineHeight: '1.5',
      color: Colors.contentText,
      marginTop: '10px',
    },
    actionCompleteTitle: {
      opacity: '0.6',
    },
    actionCompleteDescription: {
      opacity: '0.6',
    },

    healthActionCreditFormDescription: {
      color: Colors.contentText,
      fontSize: '16px',
      padding: '0 20px',
      lineHeight: 1.5,
    },

    healthActionCreditFormLink: {
      color: Colors.highlightBlue,
    },
  };

  let measureUI = null;

  let actionUI = null;

  if (measure) {
    measureUI = <div>{measure.label}</div>;
  }

  if (clinicalActions && clinicalActions.length) {
    actionUI = (
      <div
        id="clinicalContent"
        style={[
          styles.detailsContent,
          expanded ? styles.detailsContentActive : null,
        ]}
      >
        <div style={styles.actionsContent}>
          {_.chunk(clinicalActions, Math.ceil(clinicalActions.length / 2)).map(
            (actionGroup, groupIndex) => (
              <div key={groupIndex} style={styles.actionColumn}>
                {actionGroup.map((action, index) => (
                  <div key={index}>
                    <p style={styles.actionTitle}>
                      <span
                        style={
                          action.completions.length > 0
                            ? styles.actionCompleteTitle
                            : null
                        }
                      >
                        {action.shortDescription}
                      </span>
                      {action.completions.length > 0 && (
                        <i
                          className="material-icons"
                          style={styles.actionComplete}
                        >
                          done
                        </i>
                      )}
                    </p>
                    <div
                      style={[
                        styles.actionDescription,
                        action.completions.length > 0
                          ? styles.actionCompleteDescription
                          : null,
                      ]}
                    >
                      <ReactMarkdown source={action.longDescription} />
                    </div>
                  </div>
                ))}
              </div>
            )
          )}
        </div>
        <HealthActionFormLink
          style={styles.healthActionCreditFormDescription}
        />
      </div>
    );
  }

  return isCheckpointMode ? (
    <CheckpointModuleCard
      horizontalPlacementIndex={index}
      carePlan={carePlan}
      tracking={clinicalActions}
      displayedDetailsModuleType={
        expanded ? AppConstants.ModuleType.CLINICAL : ''
      }
      detailsLabel={_.size(clinicalActions) ? 'All Actions' : ''}
      onDetailsToggled={() => toggleDetails()}
      onRenderDetails={() => actionUI}
      styleOverrides={{
        root: {
          marginBottom: expanded ? `${detailHeight + 80}px` : '0px',
        },
      }}
    />
  ) : (
    <StyleRoot style={styles.section}>
      <div style={styles.content}>
        <div
          style={styles.title}
          onClick={() => onTitleClick && onTitleClick()}
        >
          {title}
        </div>
        <div style={styles.contentContainer}>
          <div
            style={styles.icon}
            onClick={() => onBubbleClick && onBubbleClick()}
          >
            <i className={iconClass} style={{ color: iconColor }} />
          </div>
          <div style={styles.measureLabel}>{measure ? measureUI : null}</div>
          <span style={styles.completed}>{'completed'}</span>
        </div>

        {clinicalActions.slice(0, 3).map((action, index) => (
          <div style={styles.actionList} key={index}>
            {action.shortDescription && `${action.shortDescription}`}
            {action.completions.length > 0 && (
              <i className="material-icons" style={styles.actionComplete}>
                done
              </i>
            )}
          </div>
        ))}

        {clinicalActions && clinicalActions.length ? (
          <div>
            <p style={styles.detailsLabel} onClick={toggleDetails}>
              {expanded ? 'Hide All Actions' : 'View All Actions'}{' '}
              <i className={expanded ? 'caret up' : 'caret'} />
            </p>

            {actionUI}
          </div>
        ) : null}
      </div>
    </StyleRoot>
  );
};

ClinicalTrackingMarkup.propTypes = {
  isCheckpointMode: PropTypes.bool.isRequired,
  carePlan: PropTypes.object.isRequired,
  clinicalActions: PropTypes.array,
  iconClass: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  bubbleColor: PropTypes.string.isRequired,
  bubbleFilled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  index: PropTypes.number,
  expanded: PropTypes.bool,
  toggleDetails: PropTypes.func,
  detailHeight: PropTypes.number,
  healthActionDescriptions: PropTypes.object,

  measure: PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  }),

  onBubbleClick: PropTypes.func,
  onTitleClick: PropTypes.func,

  styleOverrides: PropTypes.shape({
    root: PropTypes.object,
    title: PropTypes.object,
    measureContainer: PropTypes.object,
  }),
};

ClinicalTrackingMarkup.defaultProps = {
  bubbleFilled: false,
  styleOverrides: {},
};

export default Radium(ClinicalTrackingMarkup);
