import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import { withTheme } from '@material-ui/core/styles';
import List, { ListHeader, ListContent } from '../../common/list/List';
import MessageItem from './MessageItem';
import { compose, pure, withState } from 'recompose';

const MessageList = ({
  header,
  messages,
  openDrawer,
  isOpen,
  toggleMessages,
}) => {
  const maybeSubtext = isEmpty(messages) ? header.emptyText : null;
  const subtext = isOpen ? maybeSubtext : 'click anywhere to expand';
  return (
    <List isOpen={isOpen}>
      <ListHeader
        iconClass="icon-Message-Inbox"
        title={header.title}
        color={header.color}
        subtext={subtext}
        remainingCount={messages.length}
        remainingCountNote={header.countNote}
        noBubbleIcon
        onClick={() => toggleMessages(!isOpen)}
        isMute={messages.length < 1}
      />
      {isOpen && (
        <ListContent>
          {messages.map((x) => (
            <MessageItem
              key={x.id}
              message={x}
              openDrawer={() => openDrawer(x)}
            />
          ))}
        </ListContent>
      )}
    </List>
  );
};

MessageList.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    countNote: PropTypes.string.isRequired,
    emptyText: PropTypes.string,
  }).isRequired,
  openDrawer: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  toggleMessages: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

export default compose(
  pure,
  withTheme(),
  withState('isOpen', 'toggleMessages', true)
)(MessageList);
