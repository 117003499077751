import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Card from '../../../../common/card/Card';
import FrequencyBoxes from '../../../../common/widgets/FrequencyBoxes';

const styles = (theme) => ({
  customCard: {
    width: '237px',
    height: '230px',
    position: 'relative',
  },
  bubble: {
    height: '60px',
    width: '60px',
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '30px',
  },
  title: {
    marginTop: '10px',
    flexGrow: 1,
  },
});

const CoachingCard = ({
  theme,
  classes,
  title,
  iconClass,
  openDrawer,
  compliantActivityCount,
  label,
}) => {
  return (
    <Card customClass={classes.customCard} column onClick={openDrawer}>
      <div className={classes.bubble}>
        <i className={`${iconClass} ${classes.icon}`} />
      </div>
      <div className={classes.title}>{title}</div>
      <div style={{ width: '100%' }}>
        <FrequencyBoxes
          label={label}
          frequencyCount={3}
          markedCount={compliantActivityCount}
          markedColor={theme.palette.primary.main}
        />
      </div>
    </Card>
  );
};

CoachingCard.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  compliantActivityCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  openDrawer: PropTypes.func,
};

export default compose(pure, withTheme(), withStyles(styles))(CoachingCard);
