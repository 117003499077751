import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import moment from 'moment';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import {
  AppConstants,
  LanguageConstants,
} from '@healthmine/greyhound-core/src/constants';
import { getStringForLocale } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import DateComponent from '../common/question/Date';
import Freeform from '../common/question/Freeform';
import LinearScale from '../common/question/LinearScale';
import PickerComponent from '../common/question/Picker';
import Dropdown from '../common/question/Dropdown';
import Question from '../common/question/Question';
import BPQuestion from '../common/question/BPQuestion';
import HRAModal from './HRAModal';
import HealthRiskAssessmentError from './HealthRiskAssessmentError';

const HealthRiskAssessmentSurveyMarkup = ({
  questions,
  answerOptions,
  questionsAnswers,
  questionNumber,
  questionCount,
  isValid,
  onBack,
  onQuestionAnswered,
  onSubmitAnswers,
  skipQuestion,
  modalOpen,
  toggleModal,
  leaveAssesment,
  hraLanguageCode,
  errorCount,
  disableNext,
}) => {
  const question = questions[0];

  const currentQuestionAnswers = questionsAnswers[0];

  const questionAnswers = _.get(currentQuestionAnswers, 'answers', []);

  const canSubmitAnswers = !!(
    !disableNext &&
    questionAnswers.length &&
    isValid !== false
  );

  const styles = {
    page: {
      paddingTop: '20px',
      paddingBottom: '40px',
    },

    pageTitle: {
      color: Colors.bodyText,
      paddingTop: '20px',
      paddingLeft: '10%',
      marginBottom: '25px',
      fontSize: '20px',
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'row',
    },

    icon: {
      display: 'flex',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '50%',
      color: 'white',
      width: '76px',
      height: '76px',
      fontWeight: '100',
      fontSize: '40px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#7770ff',
    },

    descriptionText: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '26px',
      margin: '8px 0px 0px 15px',
    },

    shortTitle: {
      fontSize: '16px',
      fontWeight: '500',
      paddingBottom: '3px',
    },

    loadingMessage: {
      fontSize: '30px',
      color: Colors.bodyText,
    },

    question: {
      container: {
        //paddingTop : '20px',
      },

      progress: {
        fontWeight: 500,
        fontSize: '16px',
        color: Colors.bodyText,
        marginTop: '0px',
        paddingLeft: '10%',
      },

      questionTextContainer: {
        padding: '0px 10%',
        minHeight: '30px',
        marginBottom: '0px',
      },

      questionText: {
        fontSize: '20px',
        color: Colors.bodyText,
      },

      questionContainer: {
        paddingLeft: '10%',
      },
    },
    picker: {
      largeBubble: {
        width: '76px',
        height: '76px',
        border: '1px solid #99a7b6',
      },
      textItem: {
        paddingRight: '40px',
      },
      mainPickerContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '50px',
      },
    },
    linearScale: {
      container: {
        paddingRight: '45%',
        paddingTop: '100px',
      },

      scaleContainer: {
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
      },

      bubbleContainer: {
        left: '-1px',
        right: '-1px',
        justifyContent: 'space-between',
        borderLeft: `1px solid ${Colors.lightGray}`,
        borderRight: `1px solid ${Colors.lightGray}`,
      },

      scaleLine: {
        color: '#000',
      },

      label: {
        width: 'auto',
      },

      labelContainer: {
        position: 'absolute',
        top: '-15px',
        left: '60px',
        right: '46px',
      },
    },

    freeform: {
      container: {
        margin: '90px 0px',
        width: '360px',
      },
      input: {
        maxWidth: '360px',
      },
      validValueIndicator: {
        display: 'none',
      },
    },

    pageFooter: {
      fontSize: '18px',
      display: 'flex',
      marginTop: errorCount > 0 ? '45px' : '150px',
      paddingLeft: '10%',
    },

    backLinkContainer: {
      display: 'flex',
      flex: 0.8,
      alignItems: 'center',
      color: Colors.mediumGray4,
    },

    nextLinkContainer: {
      display: 'flex',
      flex: 3,
      alignItems: 'center',
      color: canSubmitAnswers ? Colors.bodyText : Colors.contentText,
    },

    finishLinkContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      color: Colors.mediumGray,
    },

    footerLink: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },

    nextLinkIcon: {
      fontSize: '30px',
      marginLeft: '10px',
    },

    finishLinkIcon: {
      fontSize: '30px',
      marginLeft: '10px',
      transform: 'rotate(270deg)',
    },

    dropDownWidth: {
      width: '45%',
      marginTop: '90px',
    },

    dateField: {
      container: {
        marginTop: '90px',
      },
    },
    initialErrorStyle: {
      marginTop: '65px',
      padding: '10px 90px',
    },
    errorContainerStyle: {
      padding: '10px 90px',
    },
  };

  const questionConfig = getStringForLocale('questionConfig', hraLanguageCode);

  const overrideConfig = question && questionConfig[question.id];

  const properties = overrideConfig && overrideConfig.properties;

  const questionType =
    (overrideConfig && overrideConfig.typeOverride) ||
    (question && question.type);

  let contentUI = null;

  let footerUI = null;

  if (question) {
    let questionUI = null;

    if (question.id === 'HNA_BP') {
      contentUI = (
        <BPQuestion
          key={question.id}
          onQuestionAnswered={onQuestionAnswered}
          answers={questionsAnswers}
          questionNumber={questionNumber}
          questionCount={questionCount}
          questions={questions}
          skipQuestion={skipQuestion}
          hraLanguageCode={hraLanguageCode}
          {...properties}
        />
      );
    } else {
      switch (questionType) {
        case 'RADIO_BUTTONS':
          questionUI = (
            <PickerComponent
              key={question.id}
              options={answerOptions.map((answerOption) => ({
                label:
                  answerOption.option[hraLanguageCode] ||
                  answerOption.option[LanguageConstants.LanguageCodes.EN],
                value: answerOption.id,
              }))}
              onAnswered={(answers) => onQuestionAnswered(question.id, answers)}
              {...properties}
              values={questionAnswers}
              styles={styles.picker}
              readOnly={
                question && question.id === AppConstants.HRAQuestionId.GENDER
                  ? true
                  : false
              }
            />
          );

          break;

        case 'CHECKBOXES':
          questionUI = (
            <PickerComponent
              key={question.id}
              options={answerOptions.map((answerOption) => ({
                label:
                  answerOption.option[hraLanguageCode] ||
                  answerOption.option[LanguageConstants.LanguageCodes.EN],
                value: answerOption.id,
              }))}
              multiSelect
              onAnswered={(answers) => onQuestionAnswered(question.id, answers)}
              values={questionAnswers}
              styles={styles.picker}
              {...properties}
            />
          );

          break;

        case 'LINEAR_SCALE':
          questionUI = (
            <LinearScale
              key={question.id}
              {...properties}
              value={questionAnswers[0]}
              options={answerOptions.map((answerOption, answerOptionIndex) => ({
                label:
                  properties && properties.labels
                    ? properties.labels[answerOptionIndex]
                    : answerOption.option[hraLanguageCode] ||
                      answerOption.option[LanguageConstants.LanguageCodes.EN],

                value: answerOption.id,
                score: answerOption.score,
              }))}
              onAnswered={(answers) => onQuestionAnswered(question.id, answers)}
              styles={styles.linearScale}
            />
          );

          break;

        case 'TEXTBOX':
          questionUI = (
            <Freeform
              key={question.id}
              placeholder="Please enter a valid value"
              onAnswered={(answer, isValid) =>
                onQuestionAnswered(question.id, answer, isValid)
              }
              value={questionAnswers[0]}
              styles={styles.freeform}
              {...properties}
            />
          );

          break;

        case 'DATE_FIELD':
          questionUI = (
            <DateComponent
              key={question.id}
              minDate={moment([1990, 0, 1])}
              maxDate={moment()}
              {...properties}
              fullMonth
              hraLanguageCode={hraLanguageCode}
              value={questionAnswers[0]}
              onAnswered={(answer, isValid) =>
                onQuestionAnswered(question.id, answer, isValid)
              }
            />
          );

          break;

        case 'DROPDOWN':
          questionUI = (
            <Dropdown
              key={question.id}
              options={answerOptions.map((answerOption) => ({
                label:
                  answerOption.option[hraLanguageCode] ||
                  answerOption.option[LanguageConstants.LanguageCodes.EN],
                value: answerOption.id,
              }))}
              onAnswered={(answer) =>
                onQuestionAnswered(question.id, answer.value)
              }
              styles={styles.dropDownWidth}
              {...properties}
              value={questionAnswers[0]}
            />
          );

          break;
      }

      contentUI = (
        <div>
          <Question
            currentQuestionNumber={questionNumber}
            questionCount={questionCount}
            questionText={
              question.question[hraLanguageCode] ||
              question.question[LanguageConstants.LanguageCodes.EN]
            }
            hraLanguageCode={hraLanguageCode}
            styles={styles.question}
          >
            {questionUI}
          </Question>
        </div>
      );
    }

    footerUI = (
      <div style={styles.pageFooter}>
        <div style={styles.backLinkContainer}>
          <span style={styles.footerLink} onClick={onBack}>
            {getStringForLocale('additionalTexts.backLabel', hraLanguageCode)}
          </span>
        </div>

        <div style={styles.nextLinkContainer}>
          <span
            style={[
              styles.footerLink,
              { cursor: canSubmitAnswers ? 'pointer' : 'not-allowed' },
            ]}
            onClick={canSubmitAnswers ? onSubmitAnswers : null}
          >
            {getStringForLocale('additionalTexts.nextLabel', hraLanguageCode)}
            <i
              className="thin-0160_arrow_next_right"
              style={styles.nextLinkIcon}
            />
          </span>
        </div>
      </div>
    );
  } else {
    contentUI = <div style={styles.loadingMessage}>Loading...</div>;
  }

  return (
    <StyleRoot style={styles.page}>
      {errorCount < 3 && contentUI}
      <HRAModal
        isOpen={modalOpen}
        closeHandler={toggleModal}
        leaveAssesment={leaveAssesment}
      />
      <HealthRiskAssessmentError
        initialError={
          'There was an error saving your answer.  Please try again.'
        }
        initialErrorStyle={styles.initialErrorStyle}
        containerStyle={styles.errorContainerStyle}
      />
      {errorCount < 3 && footerUI}
    </StyleRoot>
  );
};

HealthRiskAssessmentSurveyMarkup.propTypes = {
  questions: PropTypes.array,
  answerOptions: PropTypes.arrayOf(PropTypes.object),
  questionsAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionNumber: PropTypes.number,
  questionCount: PropTypes.number,
  isValid: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onQuestionAnswered: PropTypes.func.isRequired,
  onSubmitAnswers: PropTypes.func.isRequired,
  skipQuestion: PropTypes.func,
  modalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  leaveAssesment: PropTypes.func,
  hraLanguageCode: PropTypes.string,
  errorCount: PropTypes.number,
  disableNext: PropTypes.bool,
};

export default Radium(HealthRiskAssessmentSurveyMarkup);
