import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ListHeader from './ListHeader';
import ListContent from './ListContent';
import ListItem from './ListItem';
import ListItemStyles from './ListItem.styles';

const styles = (theme) => ({
  root: {
    marginBottom: '40px',
    padding: '20px',
    border: theme.globalStyles.dashedBorder,
  },
  open: {
    cursor: 'pointer',
  },
  closed: {
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    border: 'none',
  },
});

const List = ({ children, classes, isOpen }) => {
  const rootClass = classNames(
    classes.root,
    isOpen === true ? classes.open : undefined,
    isOpen === false ? classes.closed : undefined
  );

  return <div className={rootClass}>{children}</div>;
};

List.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
};

export default withStyles(styles)(List);

export { ListContent, ListHeader, ListItem, ListItemStyles };
