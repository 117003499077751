import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const SessionTimeoutPageMarkup = ({ loginUrl }) => {
  const styles = {
    root: {
      width: '1000px',
      maxWidth: '100%',
      margin: '65px auto 30px auto',
    },

    messageContainer: {
      fontFamily: 'Fira Sans, Open Sans, Helvetica, Arial, Sans-Serif',
      padding: '40px',
      lineHeight: '22px',
      background: Colors.white,
      boxShadow: '0 0 2px #D7D7D7',
    },

    title: {
      fontSize: '30px',
      lineHeight: '34px',
      fontWeight: 'normal',
      margin: '0 0 30px',
      letterSpacing: '-.04em',
      color: Colors.red,
    },

    content: {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'normal',
      margin: '10px 0 5px',
      letterSpacing: '-.04em',
      borderTop: `1px solid ${Colors.gutterGray}`,
      paddingTop: '10px',
    },
  };

  return (
    <div style={styles.root}>
      <div style={styles.messageContainer}>
        <h3 style={styles.title}>Session Timeout</h3>

        <h4 style={styles.content}>
          You've been logged out due to inactivity. Please{' '}
          <a href={loginUrl}>log in</a> again to access the website.
        </h4>
      </div>
    </div>
  );
};

export default SessionTimeoutPageMarkup;

SessionTimeoutPageMarkup.propTypes = {
  loginUrl: PropTypes.string.isRequired,
};
