import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import EducationItem from './EducationItem';
import List, { ListHeader, ListContent } from '../../common/list/List';
import { compose, pure, withState } from 'recompose';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';

const EducationList = ({
  educationItems,
  iconClass,
  title,
  theme,
  trackEducation,
  isOpen,
  toggleEducationItems,
}) => {
  const unviewedCount = _.sumBy(
    educationItems,
    (education) => (education.completed ? 0 : 1)
  );

  const hasContent = !_.isEmpty(educationItems);
  let subtext = !hasContent
    ? 'no content available, try changing your search criteria for more results.'
    : undefined;
  subtext = isOpen || !hasContent ? subtext : 'click anywhere to expand';

  const assignedEducation = !AppConfig.validateFeature(
    Features.NO_ASSIGNED_EDUCATION
  );

  return (
    <List isOpen={hasContent ? isOpen : undefined}>
      <ListHeader
        iconClass={iconClass}
        title={title}
        color={theme.palette.common.peopleEater}
        remainingCount={
          assignedEducation && hasContent ? unviewedCount : undefined
        }
        remainingCountNote={
          assignedEducation && hasContent ? 'not viewed' : undefined
        }
        noBubbleIcon
        subtext={subtext}
        onClick={() => toggleEducationItems(!isOpen)}
        isMute={!hasContent || (assignedEducation && unviewedCount < 1)}
      />
      {hasContent &&
        isOpen && (
          <ListContent>
            {_.map(educationItems, (education) => (
              <EducationItem
                trackEducation={trackEducation}
                key={`education_item_${education.contentId}`}
                education={education}
              />
            ))}
          </ListContent>
        )}
    </List>
  );
};

EducationList.propTypes = {
  educationItems: PropTypes.array.isRequired,
  iconClass: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  trackEducation: PropTypes.func.isRequired,
  toggleEducationItems: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

export default compose(
  pure,
  withTheme(),
  withState('isOpen', 'toggleEducationItems', true)
)(EducationList);
