import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  pure,
  withProps,
  withStateHandlers,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import _, { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import { rewardsSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import { DrawerContent, withDrawer } from '../../../common/drawer2';
import classnames from 'classnames';
import {
  RewardsConstants,
  AppConstants,
} from '@healthmine/greyhound-core/src/constants';
import OrderConfirmation from './OrderConfirmation';
import { registerIncentivesDrawerRoute } from '../../../common/drawer2/DrawerRouteMapping';

const styles = (theme) => ({
  content: {
    backgroundColor: theme.palette.common.white,
    padding: '20px',
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  summary: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
  },
  description: {
    color: Colors.contentText,
    fontSize: '16px',
    maxWidth: '370px',
    lineHeight: '16px',
  },
  boldText: {
    fontSize: '16px',
    fontWeight: 600,
    color: Colors.bodyText,
  },
  currentSelectionContainer: {
    width: '300px',
  },
  currentSelectionTitle: {
    marginTop: '0',
  },
  currentSelectionContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  currentSelectionImg: {
    width: '85px',
    height: '50px',
    display: 'inline-block',
    marginRight: '16px',
  },
  currentSelectionName: {
    display: 'inline-block',
  },
  sectionTitle: {
    color: Colors.dark,
    fontSize: '16px',
    fontWeight: 500,
    borderBottom: '1px solid #e6e7e8',
    paddingBottom: '24px',
    marginTop: '32px',
  },
  section: {
    marginTop: '12px',
  },
  clickable: {
    cursor: 'pointer',
  },
  giftcardSelection: {
    width: '33%',
    display: 'inline-block',
    margin: '12px 0',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  radioButton: {
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    border: '2px solid grey',
    marginRight: '8px',
  },
  selected: {
    backgroundColor: 'grey',
  },
  giftcardImage: {
    width: '60px',
    height: '40px',
  },
  giftcardTitle: {
    marginLeft: '8px',
  },
  giftcardVersionContainer: {
    marginLeft: '8px',
    '&>div': {
      display: 'inline-block',
      color: 'white',
      fontSize: '16px',
      padding: '2px',
      borderRadius: '3px',
      textTransform: 'uppercase',
      '&:not(:first-child)': {
        marginTop: '2px',
      },
    },
  },
  isDigital: {
    backgroundColor: Colors.lightGreen,
  },
  isPhysical: {
    backgroundColor: Colors.orange,
  },
  radio: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: 500,
    color: Colors.black,
    width: '140px',
    display: 'inline-block',
    margin: '5px 0px',
  },
  inputValue: {
    fontSize: '16px',
    fontWeight: 200,
    color: Colors.black,
    width: '250px',
    display: 'inline-block',
    margin: '5px 0px',
  },
  editButton: {
    fontSize: '16px',
    color: Colors.linkBlue,
    textDecoration: 'none',
    marginLeft: '30px',
    marginTop: '3px',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    outline: 'none',
    width: 'auto',
  },
  button: {
    width: '180px',
    color: Colors.white,
    backgroundColor: Colors.dark,
    padding: '8px',
    marginBottom: '0px',
    outline: 'none',
    fontSize: '16px',
    borderRadius: '6px',
    cursor: 'pointer',
    marginTop: '16px',
    '&:last-child': {
      marginLeft: '8px',
    },
  },
  error: {
    color: Colors.red,
    marginBottom: '40px',
  },
  errorLabel: {
    marginLeft: '15px',
    color: Colors.red,
  },
});

const AutomatedRewardsDrawer = ({
  classes,
  drawer,
  rewards,
  selectedGiftCard,
  selectGiftCard,
  onDeliveryFormChange,
  selectedDeliveryForm,
  name,
  emailAddress,
  address,
  isEditable,
  toggleEditable,
  changeAddress,
  cancelUpdateAddress,
  addressError,
  updateAddress,
  updateRewardsPreference,
  requestFailed,
  rewardSelectionComplete,
  automatedReward,
}) => {
  const img =
    get(selectedGiftCard, 'imageUrl') ||
    require('../../../../../images/rewards.png');

  const selectedImageTitle = `${get(
    selectedGiftCard,
    'name',
    'Select a'
  )} Card`;

  const { autoRewardsInfo } = AppConfig.effectiveConfig.lingo;

  return (
    <DrawerContent title="Automated Rewards">
      {rewardSelectionComplete ? (
        <OrderConfirmation
          name={name}
          emailAddress={emailAddress}
          address={address}
          automatedReward={automatedReward}
        >
          hello!
        </OrderConfirmation>
      ) : (
        <div className={classes.content}>
          <div className={classes.topContainer}>
            <div className={classes.summary}>
              {!!autoRewardsInfo && (
                <div className={classes.description}>{autoRewardsInfo}</div>
              )}
              <p className={classes.boldText}>
                This card will automatically be used until:
              </p>
              <ul className={classes.description}>
                <li>You have redeemed all of your Rewards for the year</li>
                <li>You change your gift card selection</li>
                <li>You choose to discontinue the Automated Rewards Option</li>
              </ul>
            </div>
            <div className={classes.currentSelectionContainer}>
              <p
                className={classnames(
                  classes.boldText,
                  classes.currentSelectionTitle
                )}
              >
                Current Selection:
              </p>
              <div className={classes.currentSelectionContent}>
                <img
                  className={classes.currentSelectionImg}
                  src={img}
                  alt={selectedImageTitle}
                  title={selectedImageTitle}
                />
                <div className={classes.currentSelectionName}>
                  {selectedImageTitle}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sectionTitle}>1. Choose a Gift Card</div>
          <div className={classes.section}>
            {rewards.map((item, idx) => (
              <div
                onClick={() => selectGiftCard(item.productId)}
                className={classnames(
                  classes.giftcardSelection,
                  classes.clickable
                )}
                key={`giftcard_${idx}`}
              >
                <div className={classes.flex}>
                  <div
                    className={classnames(classes.radioButton, {
                      [classes.selected]:
                        get(selectedGiftCard, 'productId') === item.productId,
                    })}
                  />
                  <img
                    className={classes.giftcardImage}
                    src={item.imageUrl || img}
                    alt={`${item.name} Card`}
                    title={`${item.name} Card`}
                  />
                  <div className={classes.giftcardInfo}>
                    <div className={classes.giftcardTitle}>{item.name}</div>
                    <div className={classes.giftcardVersionContainer}>
                      {item.isDigital && (
                        <div className={classes.isDigital}>email</div>
                      )}
                      {item.isPhysical && (
                        <div className={classes.isPhysical}>standard mail</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.sectionTitle}>
            2. Choose a Delivery Method
          </div>
          <div className={classes.section}>
            {selectedGiftCard ? (
              get(selectedGiftCard, 'variations.length') > 1 ? (
                <div>
                  <label className={classes.clickable}>
                    <input
                      className={classes.radio}
                      type="radio"
                      value={RewardsConstants.RewardDeliveryForm.PHYSICAL}
                      name="DeliveryMethod"
                      checked={
                        selectedDeliveryForm ===
                        RewardsConstants.RewardDeliveryForm.PHYSICAL
                      }
                      onChange={() =>
                        onDeliveryFormChange(
                          RewardsConstants.RewardDeliveryForm.PHYSICAL
                        )
                      }
                    />
                    Standard Mail
                  </label>
                  <br />
                  <label className={classes.clickable}>
                    <input
                      className={classes.radio}
                      type="radio"
                      value={RewardsConstants.RewardDeliveryForm.DIGITAL}
                      name="DeliveryMethod"
                      checked={
                        selectedDeliveryForm ===
                        RewardsConstants.RewardDeliveryForm.DIGITAL
                      }
                      onChange={() =>
                        onDeliveryFormChange(
                          RewardsConstants.RewardDeliveryForm.DIGITAL
                        )
                      }
                    />
                    Email
                  </label>
                </div>
              ) : (
                <div>
                  {get(selectedGiftCard, 'variations[0].deliveryForm') ===
                  'physical'
                    ? 'Standard Mail'
                    : 'Email'}
                </div>
              )
            ) : null}
          </div>
          <div className={classes.sectionTitle}>
            3. Confirm{selectedGiftCard
              ? selectedDeliveryForm ===
                RewardsConstants.RewardDeliveryForm.DIGITAL
                ? ' Your Email Address'
                : ' Your Mailing Address'
              : ''}
            {selectedDeliveryForm ===
              RewardsConstants.RewardDeliveryForm.PHYSICAL &&
              !isEditable && (
                <button onClick={toggleEditable} className={classes.editButton}>
                  Edit
                </button>
              )}
          </div>
          {selectedGiftCard ? (
            selectedDeliveryForm ===
            RewardsConstants.RewardDeliveryForm.PHYSICAL ? (
              <div className={classes.section}>
                <div className={classes.inputContainer}>
                  <div className={classes.inputLabel}>Name:</div>
                  <div className={classes.inputValue}>{name}</div>
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.inputLabel}>Street:</div>
                  {isEditable ? (
                    <input
                      type="text"
                      placeholder="Address line 1"
                      value={address && get(address, 'streetAddr1')}
                      onChange={(e) =>
                        changeAddress('streetAddr1', e.target.value)
                      }
                    />
                  ) : (
                    get(address, 'streetAddr1') && (
                      <div className={classes.inputValue}>
                        {address.streetAddr1}
                      </div>
                    )
                  )}
                </div>
                <div className={classes.inputContainer}>
                  {(get(address, 'streetAddr2') || isEditable) && (
                    <div className={classes.inputLabel} />
                  )}
                  {isEditable ? (
                    <input
                      type="text"
                      placeholder="Address line 2"
                      value={address && (get(address, 'streetAddr2', '') || '')}
                      onChange={(e) =>
                        changeAddress('streetAddr2', e.target.value)
                      }
                    />
                  ) : (
                    get(address, 'streetAddr2') && (
                      <div className={classes.inputValue}>
                        {address.streetAddr2}
                      </div>
                    )
                  )}
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.inputLabel}>City:</div>
                  {isEditable ? (
                    <input
                      type="text"
                      placeholder="City"
                      value={address && get(address, 'city')}
                      onChange={(e) => changeAddress('city', e.target.value)}
                    />
                  ) : (
                    get(address, 'city') && (
                      <div className={classes.inputValue}>{address.city}</div>
                    )
                  )}
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.inputLabel}>State:</div>
                  {isEditable ? (
                    // <input type="text" placeholder="State" />
                    <select
                      value={address && get(address, 'state')}
                      onChange={(e) => changeAddress('state', e.target.value)}
                    >
                      {AppConstants.States.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    get(address, 'state') && (
                      <div className={classes.inputValue}>{address.state}</div>
                    )
                  )}
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.inputLabel}>Zip Code:</div>
                  {isEditable ? (
                    <input
                      type="text"
                      placeholder="Zip"
                      value={address && get(address, 'zip')}
                      onChange={(e) => changeAddress('zip', e.target.value)}
                    />
                  ) : (
                    get(address, 'zip') && (
                      <div className={classes.inputValue}>{address.zip}</div>
                    )
                  )}
                </div>
                {addressError && (
                  <p className={classes.error}>{addressError}</p>
                )}
                {isEditable && (
                  <button className={classes.button} onClick={updateAddress}>
                    Update
                  </button>
                )}
                {isEditable && (
                  <button
                    className={classes.button}
                    onClick={cancelUpdateAddress}
                  >
                    Cancel
                  </button>
                )}
              </div>
            ) : (
              <div className={classes.inputContainer}>
                <div className={classes.inputLabel}>Email:</div>
                <div className={classes.inputValue}>{emailAddress}</div>
              </div>
            )
          ) : null}
          {selectedGiftCard && selectedDeliveryForm && !isEditable ? (
            <div style={styles.footerContent}>
              <button
                className={classes.button}
                onClick={updateRewardsPreference}
              >
                Sign Up For Auto Rewards
              </button>
              {requestFailed && (
                <span classNames={classes.errorLabel}>
                  Something went wrong. Check back in a few minutes and retry
                  your submission.
                </span>
              )}
            </div>
          ) : !isEditable ? (
            <div style={styles.footerContent}>
              <button className={classes.button} onClick={drawer.close}>
                Cancel
              </button>
            </div>
          ) : null}
        </div>
      )}
    </DrawerContent>
  );
};

AutomatedRewardsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
  selectedGiftCard: PropTypes.object,
  selectGiftCard: PropTypes.func,
  onDeliveryFormChange: PropTypes.func,
  selectedDeliveryForm: PropTypes.string,
  rewards: PropTypes.array,
  name: PropTypes.string,
  emailAddress: PropTypes.string,
  address: PropTypes.object,
  isEditable: PropTypes.bool,
  toggleEditable: PropTypes.func,
  editableAddress: PropTypes.object,
  changeAddress: PropTypes.func,
  cancelUpdateAddress: PropTypes.func,
  addressError: PropTypes.string,
  updateAddress: PropTypes.func,
  updateRewardsPreference: PropTypes.func,
  requestFailed: PropTypes.bool,
  rewardSelectionComplete: PropTypes.bool,
  automatedReward: PropTypes.object,
};

const composedAutomatedRewardsDrawer = compose(
  pure,
  withDrawer,
  connect(
    (state) => {
      const preference = state.rewards.preference;
      const rewards = rewardsSelector(state);
      const rewardsFlat = get(state, 'rewards.rewards');
      const isAutomated =
        preference &&
        preference.productId !== null &&
        preference.productId !== undefined
          ? true
          : false;
      const preferredDeliveryMethod = _.get(preference, 'delivery');
      const automatedReward = isAutomated
        ? _.find(rewardsFlat, {
            productId: preference.productId,
            deliveryForm: preferredDeliveryMethod,
          })
        : undefined;
      const address = state.rewards.preference;
      const name = `${state.userInfo.firstName} ${state.userInfo.lastName}`;
      const emailAddress = state.userInfo.emailAddress;

      return {
        name,
        address,
        emailAddress,
        rewards,
        isAutomated,
        automatedReward,
      };
    },
    (dispatch) => {
      const {
        getRewardsPreference,
        updateAddressLocally,
        updateRewardsPreference,
      } = RewardsActions;

      return {
        actions: bindActionCreators(
          {
            getRewardsPreference,
            updateAddressLocally,
            updateRewardsPreference,
          },
          dispatch
        ),
      };
    }
  ),
  withStyles(styles),
  withProps(() => ({
    incentiveCurrency: get(
      AppConfig,
      'effectiveConfig.incentiveCurrency',
      'points'
    ).toLowerCase(),
  })),
  withStateHandlers(
    ({ address, automatedReward, isAutomated, rewards }) => ({
      selectedGiftCard: isAutomated
        ? _.find(rewards, {
            productId: _.toString(get(automatedReward, 'productId')),
          })
        : null,
      selectedDeliveryForm: isAutomated
        ? get(automatedReward, 'deliveryForm')
        : RewardsConstants.RewardDeliveryForm.DIGITAL,
      isEditable: false,
      originalAddress: address,
      addressError: '',
      requestFailed: false,
      rewardSelectionComplete: false,
    }),
    {
      selectGiftCard: ({ originalAddress }, { rewards }) => (productId) => {
        const selectedGiftCard = _.find(rewards, {
          productId,
        });
        const defaultDeliveryForm =
          get(selectedGiftCard, 'variations.length') > 1
            ? RewardsConstants.RewardDeliveryForm.DIGITAL
            : get(selectedGiftCard, 'variations[0].deliveryForm');
        return {
          selectedGiftCard,
          selectedDeliveryForm: defaultDeliveryForm,
          isEditable: false,
          addressError: '',
          address: originalAddress,
        };
      },
      onDeliveryFormChange: ({ originalAddress }) => (selectedDeliveryForm) => {
        return {
          selectedDeliveryForm,
          isEditable: false,
          addressError: '',
          address: originalAddress,
        };
      },
      toggleEditable: ({ isEditable }) => () => ({
        isEditable: !isEditable,
      }),
      changeAddress: ({ address }) => (key, value) => {
        const currAddress = address;
        const newAddress = {
          ...currAddress,
          [key]: value,
        };
        return { address: newAddress };
      },
      cancelUpdateAddress: ({ originalAddress }) => () => ({
        address: originalAddress,
        isEditable: false,
        addressError: '',
      }),
      updateAddress: ({ address }, { actions }) => () => {
        if (!address.streetAddr1 || !address.city || !address.zip) {
          return {
            addressError: `${
              !address.streetAddr1 ? '"Street address line 1"' : ''
            }
              ${
                !address.streetAddr1 && (!address.city || !address.zip)
                  ? ', '
                  : ''
              }
              ${!address.city ? '"City"' : ''}
              ${!address.city && !address.zip ? ', ' : ''}
              ${!address.zip ? '"ZIP Code" ' : ''}
          can not be empty.`,
          };
        } else {
          actions.updateAddressLocally(address);
          return {
            isEditable: false,
            addressError: '',
            requestFailed: false,
          };
        }
      },
      handleRewardsPreferenceUpdate: () => (key, value) => ({
        [key]: value,
      }),
    }
  ),
  withHandlers({
    updateRewardsPreference: ({
      actions,
      selectedGiftCard,
      selectedDeliveryForm,
      handleRewardsPreferenceUpdate,
    }) => () => {
      actions
        .updateRewardsPreference(
          get(selectedGiftCard, 'productId'),
          selectedDeliveryForm
        )
        .then(() => {
          handleRewardsPreferenceUpdate('rewardSelectionComplete', true);
        })
        .catch(() => {
          handleRewardsPreferenceUpdate('requestFailed', true);
        });
    },
  })
)(AutomatedRewardsDrawer);

registerIncentivesDrawerRoute('automated', {
  component: composedAutomatedRewardsDrawer,
  isLarge: true,
});

export default composedAutomatedRewardsDrawer;
