import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import moment from 'moment';
import { Flex } from 'reflexbox';
import Header from './Header';

const WeeklyHistoryTable = ({
  currentWeek,
  week,
  currentWeekData,
  minDate,
  maxDate,
  unit,
  nextWeek,
  prevWeek,
}) => {
  const styles = {
    tableRow: {
      borderBottom: `1px solid ${Colors.gutterGray}`,
      fontSize: '16px',
    },
    tableValue: {
      color: Colors.linkBlue,
      paddingLeft: '5px',
    },
  };

  const toFixedDecimalString = (val) => {
    const numericVal = parseFloat(val);

    return isNaN(numericVal) ? val : numericVal.toFixed(1).replace(/\.0$/, '');
  };

  return (
    <Flex col={4} p={1} column>
      <Header
        currentWeek={currentWeek}
        week={week}
        minDate={minDate}
        maxDate={maxDate}
        nextWeek={nextWeek}
        prevWeek={prevWeek}
      />
      <Flex column auto mx={1}>
        {Array.from(new Array(7).keys()).map((day) => {
          const today = moment(currentWeek).add(day, 'd');

          const todayValue = currentWeekData[today.valueOf()] || '0';

          const todayStyle = todayValue === '0' ? {} : styles.tableValue;

          return (
            <Flex
              p={1}
              auto
              key={`${day}-day`}
              style={styles.tableRow}
              justify="space-between"
            >
              <div>{today.format('dddd, MMMM Do')}</div>
              <div style={todayStyle}>
                {Array.isArray(todayValue)
                  ? todayValue.map(toFixedDecimalString).join('/')
                  : toFixedDecimalString(todayValue)}
                <span style={{ color: Colors.bodyText }}> {unit}</span>
              </div>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

WeeklyHistoryTable.propTypes = {
  currentWeek: PropTypes.object,
  currentWeekData: PropTypes.object,
  week: PropTypes.number,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  unit: PropTypes.string,
  nextWeek: PropTypes.func,
  prevWeek: PropTypes.func,
};

export default Radium(WeeklyHistoryTable);
