import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridWrapper from './GridWrapper';
import _ from 'lodash';
import Typography from './Typography';

const styles = (theme) => ({
  chip: {
    width: '153px',
    height: '35px',
    borderRadius: '35px',
    border: `solid 1px ${theme.palette.common.blueGray}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    cursor: 'pointer',
    transition: theme.globalStyles.transition1,
    color: theme.palette.common.blueGray,
  },
  selectedChip: {
    color: theme.palette.common.white,
    background: theme.palette.common.beefy,
    border: 'none',
  },
});

const ChipFilter = ({ classes, filters, filterClick, selectedFilters }) => {
  return (
    <GridWrapper justifyFlexEnd>
      {_.map(filters, (chip, idx) => {
        const isSelectedChip = !!_.find(
          selectedFilters,
          (filter) => filter === chip.value
        );
        return (
          <a
            key={`chip_filter_${idx}`}
            className={`${classes.chip} ${
              isSelectedChip ? classes.selectedChip : ''
            }`}
            onClick={() => filterClick(chip.value)}
          >
            <Typography>{chip.display}</Typography>
          </a>
        );
      })}
    </GridWrapper>
  );
};

ChipFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  filterClick: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

export default withStyles(styles)(ChipFilter);
