import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Measurements from '@healthmine/greyhound-core/src/modules/Measurements';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import Measurement from './Measurement';
import AddDevice from './AddDevice';
import { Flex } from 'reflexbox';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import { connect } from 'react-redux';
import {
  selectIsHypertensive,
  selectIsDiabetic,
} from '@healthmine/greyhound-core/src/selectors/CarePlanSelector';
const ConnectedBody = ({
  history,
  toggleHistory = () => {},
  diabetic,
  hypertensive,
}) => {
  return (
    <Flex wrap>
      {Object.entries(Measurements.MeasurementTypes)
        .filter(
          ([measurementType]) =>
            (diabetic || measurementType !== AppConstants.ModuleType.GLUCOSE) &&
            (hypertensive ||
              measurementType !== AppConstants.ModuleType.HYPERTENSION)
        )
        .map(([measurementType, measurement], index) => (
          <Measurement
            key={measurementType}
            history={history === measurementType}
            type={measurementType}
            measurement={measurement}
            toggleHistory={toggleHistory}
            index={index}
          />
        ))}
      <AddDevice />
    </Flex>
  );
};

ConnectedBody.propTypes = {
  history: PropTypes.string,
  toggleHistory: PropTypes.func,
  diabetic: PropTypes.bool,
  hypertensive: PropTypes.bool,
};
export default compose(
  Radium,
  connect((state) => {
    const hypertensive = selectIsHypertensive(state);

    const diabetic = selectIsDiabetic(state);

    return {
      hypertensive,
      diabetic,
    };
  }),
  withState('history', 'toggleHistory', '')
)(ConnectedBody);
