import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  SweepstakesActions,
  RewardsActions,
} from '@healthmine/greyhound-core/src/actions';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import SweepstakesHistoryMarkup from './SweepstakesHistoryMarkup';
import RewardsHistoryMarkup from './RewardsHistoryMarkup';
import { selectClosedProducts } from '@healthmine/greyhound-core/src/selectors/SweepstakesSelector';
import { selectIncentiveTransactionsKeyedByTypeSelector } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
const PRODUCTS_PER_PAGE = 10;

class History extends React.Component {
  render() {
    const { incentiveType } = AppConfig.effectiveConfig;

    const { closedProducts, rewardsHistory } = this.props;

    const pageParam = parseInt(this.props.params.page);

    const currPage = pageParam ? pageParam : 1;

    const sweepstakes = closedProducts
      .filter((item) => {
        return item.memberEntries > 0;
      })
      .sort((a, b) => {
        return moment(b.closeDate) - moment(a.closeDate);
      });

    const sweepstakesHistory = sweepstakes.slice(
      (currPage - 1) * PRODUCTS_PER_PAGE,
      currPage * PRODUCTS_PER_PAGE
    );

    const pages = Math.ceil(sweepstakes.length / PRODUCTS_PER_PAGE);

    const historyMarkup =
      incentiveType === AppConstants.IncentiveType.REWARDS ? (
        <RewardsHistoryMarkup rewardsHistory={rewardsHistory} />
      ) : (
        <SweepstakesHistoryMarkup
          sweepstakesHistory={sweepstakesHistory}
          currPage={currPage}
          pages={pages}
        >
          {this.props.children}
        </SweepstakesHistoryMarkup>
      );

    return <div>{historyMarkup}</div>;
  }
}

History.propTypes = {
  actions: PropTypes.object.isRequired,
  params: PropTypes.object,
  children: PropTypes.element,
  closedProducts: PropTypes.array,
  rewardsHistory: PropTypes.object,
};

function mapStateToProps(state) {
  const closedProducts = selectClosedProducts(state);

  const rewardsHistory = selectIncentiveTransactionsKeyedByTypeSelector(state);

  return {
    closedProducts,
    rewardsHistory,
  };
}

function mapDispatchToProps(dispatch) {
  const { getAllClosedSweepstakes } = SweepstakesActions;

  const { getRewardsHistory } = RewardsActions;
  return {
    actions: bindActionCreators(
      { getAllClosedSweepstakes, getRewardsHistory },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(History);
