import {
  compose,
  pure,
  withHandlers,
  withStateHandlers,
  withProps,
} from 'recompose';
import ActionItemMarkup from './ActionItem.markup';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import {
  DiabetesMonitor,
  PieChart,
  Whistle,
  HeartHealth,
  Stethoscope,
} from '../../../styles/uhc-icons';

export default compose(
  pure,

  withStateHandlers(
    {
      detailHeight: 0,
    },

    {
      setDetailHeight: () => (detailHeight) => ({ detailHeight }),
    }
  ),

  withHandlers({
    toggleDetails: ({ toggleDetails, actionItemIndex }) => () =>
      toggleDetails(actionItemIndex),
  }),

  withProps(({ actionItem }) => {
    const { iconClass } = actionItem;
    const uhcBranded = AppConfig.validateFeature(Features.UHC_BRANDED);
    let uhcIcon = '';

    if (uhcBranded) {
      switch (iconClass) {
        case 'thin-0825_stetoscope_doctor_hospital_ill':
          uhcIcon = Stethoscope;
          break;
        case 'thin-0813_heart_vitals_pulse_rate_health':
          uhcIcon = HeartHealth;
          break;
        case 'thin-0833_referee_whistle':
          uhcIcon = Whistle;
          break;
        case 'thin-0378_analytics_presentation_statistics_graph':
          uhcIcon = PieChart;
          break;
        case 'thin-diabetes':
          uhcIcon = DiabetesMonitor;
          break;
      }
    }

    return { uhcIcon };
  })
)(ActionItemMarkup);
