import _ from 'lodash';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import {
  AppConstants,
  RewardsConstants,
} from '@healthmine/greyhound-core/src/constants';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';

const SummaryCardMarkup = ({
  primaryAction,
  pointPotential,
  pointsEarned,
  goalAchieved,
  onCreateCheckpoint,
  isIncentiveSummaryVisible,
  onToggleIncentiveSummary,
  styleOverrides,
}) => {
  const styles = {
    root: {
      display: 'flex',
      flex: '0 0 300px',
      boxSizing: 'border-box',
      height: '250px',
    },

    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '40px 25px 15px',
      ..._.get(styleOverrides, 'content', {}),
    },

    iconBubble: {
      display: 'flex',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      fontSize: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Colors.navyBlue,
    },

    icon: {
      color: Colors.white,
    },

    title: {
      fontSize: '16px',
    },

    description: {
      margin: '0px',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: '300',
      color: Colors.bodyText,
      width: '100%',
    },

    continueLink: {
      fontSize: '16px',
      color: Colors.highlightBlue,
      textDecoration: 'none',
      cursor: 'pointer',
    },

    incentiveToggle: {
      width: '100%',
      textAlign: 'right',
      paddingRight: '30px',
      color: Colors.contentText,
      cursor: 'pointer',
    },

    incentiveToggleText: {
      fontSize: '16px',
    },

    incentiveToggleCaret: {
      position: 'relative',
      top: 0,
      right: 0,
    },
  };

  const incentiveLabel =
    AppConfig.effectiveConfig.incentiveType ===
    AppConstants.IncentiveType.SWEEPSTAKES
      ? 'Bonus Point'
      : 'Reward';

  let motivation;

  if (
    AppConfig.effectiveConfig.incentiveCurrency ===
    AppConstants.IncentiveCurrency.POINTS
  ) {
    motivation =
      pointsEarned === pointPotential
        ? 'Keep up the good work and earn more points!'
        : goalAchieved
          ? 'Great job achieving your goal, keep up the good work and earn more points!'
          : "Looks like you had some trouble meeting your goal this time. Don't give up, try again, and keep earning points!";
  } else {
    let callToAction = '';
    if (
      AppConstants.TrackingModuleTypes.includes(_.get(primaryAction, 'type'))
    ) {
      const simpleModuleName = primaryAction.type.toLowerCase();

      callToAction = ` and continue tracking your ${simpleModuleName}`;
    }

    motivation = goalAchieved
      ? `Keep up the good work${callToAction}!`
      : "Looks like you had some trouble meeting your goal this time. Don't give up, try again, and keep earning rewards!";
  }

  return (
    <div style={styles.root}>
      <div style={styles.content}>
        {pointPotential !== 0 && (
          <div>
            <div style={styles.iconBubble}>
              <i
                style={styles.icon}
                className="thin-0670_diamond_prize_award_jewellery_ring"
              />
            </div>

            <label style={styles.title}>
              {`${RewardsConstants.setCurrencyLabel(
                pointsEarned
              )} ${incentiveLabel}${pointsEarned !== 1 ? 's' : ''} Earned${
                pointsEarned ? '!' : ''
              }`}
            </label>
          </div>
        )}

        <p style={styles.description}>{motivation}</p>

        <a onClick={onCreateCheckpoint} style={styles.continueLink}>
          Continue
        </a>

        <div style={styles.incentiveToggle} onClick={onToggleIncentiveSummary}>
          <span style={styles.incentiveToggleText}>
            {isIncentiveSummaryVisible ? 'Hide' : 'Show'} Care Plan Summary
          </span>
          <i
            className={isIncentiveSummaryVisible ? 'caret up' : 'caret'}
            style={styles.incentiveToggleCaret}
          />
        </div>
      </div>
    </div>
  );
};

SummaryCardMarkup.propTypes = {
  primaryAction: PropTypes.object,
  pointPotential: PropTypes.number.isRequired,
  pointsEarned: PropTypes.number.isRequired,
  goalAchieved: PropTypes.bool.isRequired,
  onCreateCheckpoint: PropTypes.func.isRequired,
  isIncentiveSummaryVisible: PropTypes.bool.isRequired,
  onToggleIncentiveSummary: PropTypes.func.isRequired,
  styleOverrides: PropTypes.object.isRequired,
};

export default Radium(SummaryCardMarkup);
