import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import moment from 'moment';
import InfoPopup from '../../common/InfoPopup';

const RewardsHistoryMarkup = ({ rewardsHistory }) => {
  const rewardHistory = rewardsHistory.REWARD;

  const redeemHistory = rewardsHistory.REDEEM;

  const styles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '75px',
      paddingRight: '75px',
    },

    header: {
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.black,
      paddingBottom: '20px',
    },

    table: {
      textAlign: 'left',
      fontSize: '16px',
      width: '100%',
      borderCollapse: 'collapse',
    },

    th: {
      padding: '10px 5px',
      color: Colors.mediumGray2,
      backgroundColor: '#f3f6f8',
      fontSize: '16px',
      textAlign: 'center',
    },

    td: {
      padding: '20px 5px',
      color: Colors.contentText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      textAlign: 'center',
    },

    firstColumn: {
      textAlign: 'left',
    },

    noContent: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '20px',
      fontWeight: 300,
      paddingLeft: '75px',
      paddingRight: '75px',
    },
    detailsLink: {
      color: Colors.linkBlue,
      textDecoration: 'none',
      textAlign: 'center',
      ':hover': {
        color: Colors.hexToRgba(Colors.dark, 0.5),
      },
      ':active': {
        color: Colors.dark,
      },
    },
    infoPopup: {
      popupContainer: {
        left: '26px',
        right: 'initial',
      },
      popupContent: {
        borderRadius: '0 10px 10px 10px',
      },
      popupStem: {
        left: 0,
        right: 'initial',
        borderTop: '20px solid transparent',
        borderLeft: `15px solid ${Colors.hexToRgba(Colors.lighterGray)}`,
        borderBottom: '0 solid transparent',
        borderRight: '0 solid transparent',
      },
    },
    tooltip: {
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: 400,
      width: '240px',
    },
  };

  const { lingo } = AppConfig.effectiveConfig;

  const { setCurrencyLabel } = RewardsConstants;

  const rewardHistoryUI =
    rewardHistory && rewardHistory.length ? (
      <div style={styles.content}>
        <div style={[GlobalStyles.semiBold, styles.header]}>
          Rewards Activity
        </div>
        <table style={styles.table}>
          <thead>
            <tr>
              <th
                style={[GlobalStyles.bold, styles.th, styles.firstColumn]}
                width="25%"
              >
                Rewards Earned
              </th>
              <th style={[GlobalStyles.bold, styles.th]} width="25%">
                Date Completed
                <InfoPopup styles={styles.infoPopup}>
                  <div style={styles.tooltip}>
                    Date represents date of service on claim or completion date
                    of health action
                  </div>
                </InfoPopup>
              </th>
              <th style={[GlobalStyles.bold, styles.th]} width="25%">
                Date Rewarded
                <InfoPopup styles={styles.infoPopup}>
                  <div style={styles.tooltip}>
                    Date Rewarded may differ from Date Completed due to claims
                    processing cycle
                  </div>
                </InfoPopup>
              </th>
              <th style={[GlobalStyles.bold, styles.th]} width="25%">
                {lingo.healthAction}
              </th>
            </tr>
          </thead>
          <tbody>
            {rewardHistory.map((item, index) => {
              const lastCompletedDate = moment(item.lastCompletedDate);
              return (
                <tr key={index}>
                  <td style={[styles.td, styles.firstColumn]}>
                    {setCurrencyLabel(item.amount)}
                  </td>
                  <td style={styles.td}>
                    {lastCompletedDate.isValid()
                      ? lastCompletedDate.format('MM/DD/YYYY')
                      : 'Unknown Date'}
                  </td>
                  <td style={styles.td}>
                    {moment(item.lastIssuedDate).format('MM/DD/YYYY')}
                  </td>
                  <td style={styles.td}>{item.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    ) : (
      <div style={styles.content}>
        <div style={[GlobalStyles.semiBold, styles.header]}>
          Rewards Activity
        </div>
        <div style={styles.noContent}>No Rewards History Available</div>
      </div>
    );

  const redeemHistoryUI =
    redeemHistory && redeemHistory.length ? (
      <div style={styles.content}>
        <div style={[GlobalStyles.semiBold, styles.header]}>
          Redeem Activity
        </div>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th} width="30%">
                Rewards
              </th>
              <th style={styles.th} width="20.5%">
                Date
              </th>
              <th style={styles.th} width="14.5%">
                Quantity
              </th>
              <th style={styles.th} width="20%">
                Delivery
              </th>
              <th style={styles.th} width="18%">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {redeemHistory.map((item, index) => {
              if (item.dataType === 'SWEEPSTAKES_ENTRY_DATA') {
                return null;
              }
              return (
                <tr key={index}>
                  <td style={styles.td}>{item.description}</td>
                  <td style={styles.td}>
                    {moment(item.lastIssuedDate).format('MM/DD/YYYY')}
                  </td>
                  <td style={styles.td}>{item.completedCount}</td>
                  <td style={styles.td}>
                    {item.deliveryForm ===
                    RewardsConstants.RewardDeliveryForm.DIGITAL
                      ? 'Email'
                      : item.deliveryForm ===
                        RewardsConstants.RewardDeliveryForm.PHYSICAL
                        ? 'Standard Mail'
                        : null}
                  </td>
                  <td style={styles.td}>
                    {setCurrencyLabel(Math.abs(item.amount || 0))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    ) : (
      <div style={styles.content}>
        <div style={[GlobalStyles.semiBold, styles.header]}>
          Redeem Activity
        </div>
        <div style={styles.noContent}>No Redemption History Available</div>
      </div>
    );

  return (
    <div>
      {rewardHistoryUI}
      {redeemHistoryUI}
    </div>
  );
};

RewardsHistoryMarkup.propTypes = {
  rewardsHistory: PropTypes.object.isRequired,
};

export default Radium(RewardsHistoryMarkup);
