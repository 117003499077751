import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure, withHandlers, withProps } from 'recompose';
import Radium from 'radium';
import Styles from './SelectConfirmCoverage.styles';
import { WithCoverageSelection } from '@healthmine/greyhound-core/src/hocs';
import {
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import BottomLinks from '../../components/common/bottomLinks';
import PageHeader from '../../components/common/pageHeader';
import { withRouter } from 'react-router';
import { Features } from '@healthmine/greyhound-core/src/constants';
import _ from 'lodash';

const CoverageCard = compose(
  pure,

  withHandlers({
    chooseCoverage: (props) => () => {
      props.selectCoverage(props.coverageId);
    },
  }),

  Radium
)(
  ({
    coverageId,
    relationshipTagline,
    chooseCoverage,
    selectedCoverage,
    coverageName,
  }) => (
    <a style={Styles.coverageCard} onClick={chooseCoverage}>
      <div style={Styles.coverageName}>{coverageName}</div>
      <div style={Styles.coverageTagLine}>{relationshipTagline}</div>
      {coverageId === selectedCoverage && (
        <div style={Styles.iconWrapper}>
          <i className="thin-0154_ok_successful_check" style={Styles.icon} />
        </div>
      )}
    </a>
  )
);

const SelectConfirmCoverage = ({
  memberCoverages,
  selectedMemberCoverageId,
  onMemberCoverageSelected,
  onCommit,
  currentPathname,
}) => {
  return (
    <div style={Styles.root}>
      <PageHeader currentPathname={currentPathname} />
      <div style={Styles.container}>
        <div style={Styles.coverageSection}>
          <div style={Styles.title}>
            <span style={Styles.imageSpacing}>
              <i
                className="thin-0699_user_profile_avatar_man_male"
                style={Styles.iconImage}
              />
            </span>
            <span>
              <h2 style={[Styles.mainHeader, GlobalStyles.extraLight]}>
                Choose Your Coverage
              </h2>
              <h3 style={[Styles.subHeaderText, GlobalStyles.regular]}>
                Choose which plan you want to associate with your new account.
              </h3>
            </span>
          </div>

          <div style={Styles.formContainer}>
            <div style={Styles.coveragesContainer}>
              {memberCoverages.map((cov, idx) => {
                const {
                  memberCoverageId,
                  coverageName,
                  relationshipLabel,
                } = cov;

                return (
                  <CoverageCard
                    key={idx}
                    relationshipTagline={relationshipLabel}
                    coverageName={coverageName}
                    coverageId={memberCoverageId}
                    selectCoverage={onMemberCoverageSelected}
                    selectedCoverage={selectedMemberCoverageId}
                  />
                );
              })}
            </div>

            <div style={Styles.bottomLinks}>
              <BottomLinks
                text={'Confirm'}
                onClick={onCommit}
                disabled={!true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SelectConfirmCoverage.propTypes = {
  memberCoverages: PropTypes.arrayOf(
    PropTypes.shape({
      memberCoverageId: PropTypes.number.isRequired,
      coverageName: PropTypes.string.isRequired,
      relationshipLabel: PropTypes.string.isRequired,
    })
  ),

  selectedMemberCoverageId: PropTypes.number,
  onMemberCoverageSelected: PropTypes.func.isRequired,
  onCommit: PropTypes.func.isRequired,
  currentPathname: PropTypes.string,
};

export default compose(
  pure,
  withRouter,

  connect((state) => ({
    onboardingComplete: state.onBoarding.completed,
  })),

  withProps((props) => ({
    currentPathname: _.get(props, 'location.pathname', '/'),
  })),

  withHandlers({
    onSelectionCommitted: ({ router, onboardingComplete }) => () => {
      if (
        AppConfig.validateFeature(Features.NO_ONBOARDING) ||
        onboardingComplete
      ) {
        router.push('health-overview');
      } else {
        router.push('/introduction/');
      }
    },
  }),

  WithCoverageSelection,
  Radium
)(SelectConfirmCoverage);
