import moment from 'moment';
import Radium, { StyleRoot } from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import {
  MessageCenterConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import striptags from 'striptags';
import PageLinks from '../common/PageLinks';
import MessageModal from '../common/MessageModal';
import Page from '../common/Page';
import { Mail } from '../../styles/uhc-icons';

const MessagesMarkup = ({
  displayedMessages,
  currentPageNumber,
  pageCount,
  selectedMessageFilter,
  onMessageFilterChanged,
  viewedMessage,
  onViewMessage,
  onViewedMessageClosed,
}) => {
  const styles = {
    root: {
      padding: '0 69px 80px',
    },

    pageContent: {
      display: 'flex',
      flexDirection: 'column',
    },

    contentHeader: {
      textAlign: 'right',
      marginBottom: '30px',
    },

    filterList: {
      display: 'inline-block',
      minWidth: '80px',
      fontSize: '16px',
      height: '34px',
      color: Colors.lightGray3,
      borderRadius: '5px',
      paddingLeft: '5px',
    },

    messageContainer: {
      display: 'flex',
      position: 'relative',
      minHeight: '60px',
      padding: '15px',
      border: `1px solid ${Colors.gutterGray}`,
      borderRadius: '5px',
      marginBottom: '15px',
      cursor: 'pointer',
    },

    unreadMessageContainer: {
      border: `1px solid ${Colors.lightGray3}`,
    },

    messageContentContainer: {
      maxWidth: '621px', //IEFix
      paddingRight: '10px',
    },

    messageDateContainer: {
      minWidth: '100px',
      textAlign: 'right',
    },

    messageSubject: {
      fontSize: '16px',
      color: Colors.navyBlue,
    },

    unreadMessageSubject: {
      fontWeight: 500,
    },

    messageDate: {
      fontSize: '16px',
      color: Colors.navyBlue,
    },

    unreadMessageDate: {
      fontWeight: 500,
    },

    messageText: {
      color: Colors.contentText,
      fontSize: '16px',
      padding: '15px 0 0',
      height: '32px',
    },

    unreadMessageText: {
      fontWeight: 500,
      color: Colors.bodyText,
    },

    unreadIndicator: {
      backgroundColor: Colors.lightGreen,
      border: `1px solid ${Colors.light}`,
      color: Colors.light,
      display: 'inline-block',
      float: 'right',
      width: '15px',
      height: '15px',
      fontSize: '16px',
      lineHeight: '15px',
      textAlign: 'center',
      borderRadius: '50%',
      position: 'absolute',
      top: '-6px',
      left: '-6px',
    },

    pageLinksWrapper: {
      padding: '30px 0',
    },

    noMessages: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '30px',
      fontWeight: 200,
    },

    noMessagesUhcBrandedIcon: {
      width: '150px',
    },

    noMessagesIcon: {
      fontSize: '90px',
      padding: '30px',
    },
  };

  const uhcBranded = AppConfig.validateFeature(Features.UHC_BRANDED);

  return (
    <Page
      title={'Messages'}
      icon={'thin-0319_email_mail_post_card'}
      uhcIcon={Mail}
      iconStyle={{
        backgroundColor: uhcBranded ? Colors.white : Colors.lightGray,
      }}
    >
      <StyleRoot style={[styles.root, GlobalStyles.light]}>
        {viewedMessage ? (
          <MessageModal
            message={viewedMessage}
            onClosed={onViewedMessageClosed}
          />
        ) : null}
        <div style={styles.pageContent}>
          <div style={styles.contentHeader}>
            <select
              style={styles.filterList}
              value={selectedMessageFilter}
              onChange={onMessageFilterChanged}
            >
              <option value="all">All Messages</option>
              <option value={MessageCenterConstants.MessageStatus.READ}>
                Read
              </option>
              <option value={MessageCenterConstants.MessageStatus.UNREAD}>
                Unread
              </option>
              <option value={MessageCenterConstants.MessageStatus.DELETE}>
                Deleted
              </option>
            </select>
          </div>

          {displayedMessages.length ? (
            <div>
              {displayedMessages.map((message, index) => {
                const isUnread =
                  message.status ===
                  MessageCenterConstants.MessageStatus.UNREAD;

                return (
                  <div
                    key={index}
                    onClick={() => onViewMessage(message)}
                    style={[
                      styles.messageContainer,
                      isUnread ? styles.unreadMessageContainer : null,
                    ]}
                  >
                    {isUnread ? (
                      <span
                        style={[
                          styles.unreadIndicator,
                          GlobalStyles.extraLight,
                        ]}
                      />
                    ) : null}

                    <div style={styles.messageContentContainer}>
                      <div
                        style={[
                          styles.messageSubject,
                          isUnread ? styles.unreadMessageSubject : null,
                        ]}
                      >
                        {message.subject}
                      </div>

                      {/* eslint-disable react/no-danger */}
                      <div
                        style={[
                          styles.messageText,
                          isUnread ? styles.unreadMessageText : null,
                        ]}
                        className="truncate-text"
                        dangerouslySetInnerHTML={{
                          __html: striptags(message.body),
                        }}
                      />
                      {/* eslint-enable react/no-danger */}
                    </div>

                    <div style={styles.messageDateContainer}>
                      <div
                        style={[
                          styles.messageDate,
                          isUnread ? styles.unreadMessageDate : null,
                        ]}
                      >
                        {moment().diff(moment(message.sentTime), 'd') <= 1
                          ? moment(message.sentTime).fromNow()
                          : moment(message.sentTime).format('MM/DD/YYYY')}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div style={styles.pageLinksWrapper}>
                <PageLinks
                  linkBase={'/messages/'}
                  currPage={currentPageNumber}
                  pages={pageCount}
                />
              </div>
            </div>
          ) : (
            <div style={styles.noMessages}>
              No{' '}
              {selectedMessageFilter ===
              MessageCenterConstants.MessageStatus.READ
                ? 'Old'
                : selectedMessageFilter ===
                  MessageCenterConstants.MessageStatus.DELETE
                  ? 'Deleted'
                  : 'New'}{' '}
              Messages
              {uhcBranded ? (
                <img style={styles.noMessagesUhcBrandedIcon} src={Mail} />
              ) : (
                <i
                  style={styles.noMessagesIcon}
                  className="thin-0319_email_mail_post_card"
                />
              )}
            </div>
          )}
        </div>
      </StyleRoot>
    </Page>
  );
};

MessagesMarkup.propTypes = {
  displayedMessages: PropTypes.array.isRequired,
  currentPageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  selectedMessageFilter: PropTypes.string.isRequired,
  viewedMessage: PropTypes.object,
  onMessageFilterChanged: PropTypes.func.isRequired,
  onViewMessage: PropTypes.func.isRequired,
  onViewedMessageClosed: PropTypes.func.isRequired,
};

export default Radium(MessagesMarkup);
