import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Colors,
  GlobalStyles,
  Errors,
} from '@healthmine/greyhound-core/src/modules';
import { HraActions } from '@healthmine/greyhound-core/src/actions';
import Button from '../../components/common/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClipboardItem from '../../components/common/ClipboardItem';
import { withRouter } from 'react-router';

const errorLimit = 3;

const HealthRiskAssessmentError = ({
  count,
  initialError,
  errors,
  router,
  initialErrorStyle,
  containerStyle,
  actions,
}) => {
  const returnHome = () => router.replace('/');

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '280px',
      fontSize: '18px',
      color: 'red',
      margin: '15px',
      maxWidth: '435px',
    },
    button: {
      width: '250px',
      padding: '10px 25px',
      marginTop: '20px',
      marginBottom: '20px',
    },
    errorMessage: {
      marginBottom: '10px',
      maxWidth: '400px',
    },
    errorCode: {
      color: Colors.bodyText,
    },
    startError: {
      color: Colors.red,
      fontSize: '18px',
      marginTop: '15px',
    },
  };

  if (!count) {
    return null;
  }

  return count < errorLimit ? (
    <div style={[styles.startError, initialErrorStyle]}>{initialError}</div>
  ) : (
    <div style={[styles.container, containerStyle]}>
      <div style={styles.errorMessage}>
        Unfortunately, our system is having issues and we cannot proceed at this
        time. Please try again later. Your progress is saved every time you
        answer a question and will be resumed where things went wrong. If the
        issue persists, please contact your customer service team with the
        following error code:
      </div>
      <ul style={styles.errorCode}>
        {errors.map((error) => (
          <li key={error}>
            {error}
            <ClipboardItem text={error} />
          </li>
        ))}
      </ul>
      <Button
        text={'Back to Health Overview'}
        style={[styles.button, GlobalStyles.regular]}
        onClick={() => {
          actions.clearErrors();
          returnHome();
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: Errors.getErrorMessages(state),
    count: state.errors.count,
    impersonating: !!state.signIn.impersonationEmail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        clearErrors: HraActions.clearErrors,
      },
      dispatch
    ),
  };
};

HealthRiskAssessmentError.propTypes = {
  count: PropTypes.number,
  errors: PropTypes.array,
  impersonating: PropTypes.bool,
  initialError: PropTypes.string,
  initialErrorStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  router: PropTypes.object,
  actions: PropTypes.shape({
    retry: PropTypes.func,
    logout: PropTypes.func,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(Radium(HealthRiskAssessmentError))
);
