import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { Link } from 'react-router';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

const EducationTrackingMarkup = ({
  iconColor,
  bubbleColor,
  bubbleFilled,
  title,
  description,
  measure,
  callToAction,
  onBubbleClick,
  onTitleClick,
  styleOverrides,
}) => {
  const styles = {
    section: {
      flex: '0 0 299px',
      boxSizing: 'border-box',
      minWidth: '299px',
      height: '250px',
      maxHeight: '250px',
      borderRight: `1px solid ${Colors.gutterGray}`,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      position: 'relative',
    },

    content: {
      padding: '10px',
    },

    icon: {
      display: 'flex',
      border: `2px solid ${bubbleColor}`,
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      padding: '0px',
      fontSize: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: bubbleFilled ? bubbleColor : 'transparent',
      cursor: onBubbleClick ? 'pointer' : 'default',
    },
    title: {
      fontSize: '16px',
      cursor: onTitleClick ? 'pointer' : 'default',
      margin: '10px 0 20px',
      textAlign: 'center',
      ...styleOverrides.title,
    },
    measureValue: {
      fontSize: '28px',
    },
    measureLabel: {
      fontSize: '27px',
      margin: 'auto 0px auto 20px',
    },
    callToAction: {
      marginTop: '20px',
      fontSize: '16px',
      textAlign: 'center',
      color: Colors.highlightBlue,
    },

    healthLibraryLinkContainer: {
      textAlign: 'right',
      position: 'absolute',
      right: '29px',
      bottom: '15px',
    },

    healthLibraryLink: {
      color: Colors.highlightBlue,
      textDecoration: 'none',
      fontSize: '16px',
      cursor: 'pointer',
    },

    completed: {
      width: '100px',
      textAlign: 'left',
      color: Colors.measurement.missing,
      margin: '20px 0px auto 10px',
    },
    descriptionText: {
      marginLeft: '35px',
      marginTop: '20px',
      fontSize: '16px',
      fontWeight: '300',
      textAlign: 'center',
      color: Colors.bodyText,
      width: '215px',
    },
    para: {
      margin: 0,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginLeft: '20px',
    },
    callToActionLabel: {
      color: Colors.highlightBlue,
      textDecoration: 'none',
      cursor: 'pointer',
    },
  };

  let measureUI = null;

  let ctaUI = null;

  if (measure) {
    measureUI = <div>{measure.label}</div>;
  }

  if (callToAction) {
    ctaUI = (
      <div style={styles.callToAction}>
        {callToAction.link ? (
          <Link to={callToAction.link} style={styles.callToActionLabel}>
            {callToAction.text}
          </Link>
        ) : (
          <span style={styles.callToActionLabel}>{callToAction.text}</span>
        )}
      </div>
    );
  }

  return (
    <StyleRoot style={[GlobalStyles.regular, styles.section]}>
      <div style={styles.content}>
        <div
          style={styles.title}
          onClick={() => onTitleClick && onTitleClick()}
        >
          {title}
        </div>
        <div style={styles.contentContainer}>
          <div
            style={styles.icon}
            onClick={() => onBubbleClick && onBubbleClick()}
          >
            <i
              className={'thin-0007_book_reading_read_bookmark'}
              style={{ color: iconColor }}
            />
          </div>
          <div style={styles.measureLabel}>{measure ? measureUI : null}</div>
          <span style={styles.completed}>{'completed'}</span>
        </div>
        <div style={styles.descriptionText}>
          <p style={styles.para}>{description && `${description}.`}</p>
        </div>

        {ctaUI}

        <div style={styles.healthLibraryLinkContainer}>
          <Link to="/pha/" style={styles.healthLibraryLink}>
            {'Go to Health Library >'}
          </Link>
        </div>
      </div>
    </StyleRoot>
  );
};

EducationTrackingMarkup.propTypes = {
  iconColor: PropTypes.string.isRequired,
  bubbleColor: PropTypes.string.isRequired,
  bubbleFilled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,

  measure: PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  }),

  callToAction: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string,
  }),

  onBubbleClick: PropTypes.func,
  onTitleClick: PropTypes.func,

  styleOverrides: PropTypes.shape({
    root: PropTypes.object,
    title: PropTypes.object,
  }),
};

EducationTrackingMarkup.defaultProps = {
  bubbleFilled: false,
  styleOverrides: {},
};

export default Radium(EducationTrackingMarkup);
