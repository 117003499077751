import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Assets } from '@healthmine/greyhound-core/src/modules';
import Typography from '../../common/Typography';
import { get } from 'lodash';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'start',
    position: 'relative',
    marginBottom: '16px',
  },
  bannerImage: {
    width: '100%',
  },
  bannerText: {
    position: 'absolute',
    left: '32px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  primaryText: {
    textTransform: 'capitalize',
  },
});

const HeroBanner = ({ firstName, classes, secondaryText }) => {
  return (
    <div className={classes.root}>
      <img
        className={classes.bannerImage}
        src={Assets.getAssetURL('hero_watermark.png')}
      />
      <div className={classes.bannerText}>
        <Typography type="h1" color="white" className={classes.primaryText}>
          Welcome{firstName && `, ${firstName.toLowerCase()}`}!
        </Typography>
        {secondaryText && (
          <Typography type="h2" color="white">
            {secondaryText}
          </Typography>
        )}
      </div>
    </div>
  );
};

HeroBanner.propTypes = {
  secondaryText: PropTypes.string,
  // from connect
  firstName: PropTypes.string,
  // from withStyles
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect((state) => ({ firstName: get(state, 'userInfo.firstName') })),
  withStyles(styles)
)(HeroBanner);
