import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Item from '../../common/Item';
import { StepStatus } from '@healthmine/greyhound-core/src/constants/CarePlan';

const incompleteIconColor = Colors.hexToRgba(Colors.contentText, 0.5);

const incompleteBorderColor = Colors.hexToRgba(Colors.contentText, 0.3);

const iconColor = (status, required, configured, color) => {
  switch (status) {
    case StepStatus.ACTIVE:
      return required ? Colors.lightGreen : color;
    case StepStatus.COMPLETED:
      return Colors.white;
    default:
      return configured ? Colors.white : incompleteIconColor;
  }
};

const iconBorderColor = (status, required, configured, color) => {
  switch (status) {
    case StepStatus.ACTIVE: {
      return required ? Colors.lightGreen : color;
    }
    case StepStatus.COMPLETED: {
      return required ? Colors.lightGreen : configured ? 'transparent' : color;
    }

    default:
      return required || configured ? 'transparent' : incompleteBorderColor;
  }
};

const iconBackgroundColor = (status, required, configured, color) => {
  switch (status) {
    case StepStatus.ACTIVE:
      return Colors.white;
    case StepStatus.COMPLETED:
      return required ? Colors.lightGreen : color;
    case StepStatus.TODO:
      return configured
        ? Colors.hexToRgba(required ? Colors.lightGreen : color, 0.7)
        : required
          ? incompleteBorderColor
          : Colors.light;
    case StepStatus.SKIPPED:
      return Colors.gutterGray;
  }
};

const iconStyles = (status, required, configured, color) => ({
  color: iconColor(status, required, configured, color),
  border: `2px solid ${iconBorderColor(status, required, configured, color)}`,
  backgroundColor: iconBackgroundColor(status, required, configured, color),
});

const StepIcon = ({
  iconClass,
  connected,
  status,
  required,
  configured,
  color,
}) => {
  const styles = {
    icon: iconStyles(status, required, configured, color),
  };

  return (
    <Item
      iconStyle={styles.icon}
      iconClass={iconClass}
      keep
      connected={connected}
    />
  );
};

StepIcon.propTypes = {
  iconClass: PropTypes.string.isRequired,
  connected: PropTypes.bool,
  status: PropTypes.oneOf([
    StepStatus.ACTIVE,
    StepStatus.COMPLETED,
    StepStatus.TODO,
    StepStatus.SKIPPED,
  ]),
  required: PropTypes.bool.isRequired,
  configured: PropTypes.bool,
  color: PropTypes.string,
};

export default StepIcon;
