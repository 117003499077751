import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import Radium from 'radium';
import {
  GlobalStyles,
  Colors,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import { AgreementActions } from '@healthmine/greyhound-core/src/actions';
import { Agreement } from '@healthmine/greyhound-core/src/constants/prop-types';
import { AgreementSelectors } from '@healthmine/greyhound-core/src/selectors';
import { Features } from '@healthmine/greyhound-core/src/constants';
import _ from 'lodash';
import AnnouncementAgreementModal from './AnnouncementAgreementModal';
import PendingPrivacyAgreementModal from './PendingPrivacyAgreementModal';
import { withMemberSummaryData } from '@healthmine/greyhound-core/src/hocs';

const styles = {
  customStyles: {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
      zIndex: 1299,
    },
    content: {
      minWidth: '300px',
      maxWidth: '800px',
      minHeight: '200px',
      right: 'initial',
      bottom: 'initial',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: '#fff',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '15px',
      outline: 'none',
      padding: '15px 50px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    fontSize: '25px',
    color: Colors.black,
    cursor: 'pointer',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '200px',
  },
  pageTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  pageFooter: {
    display: 'flex',
    marginTop: '50px',
    justifyContent: 'center',
  },
  pageContent: {
    ...GlobalStyles.light,
    color: Colors.bodyText,
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    fontSize: '16px',
    textAlign: 'center',
    marginLeft: '50px',
    marginRight: '50px',
  },
  logo: {
    maxHeight: '40px',
    maxWidth: '200px',
    alignSelf: 'left',
  },
  button: {
    width: '250px',
    padding: '10px 25px',
    marginBottom: 0,
    fontSize: '16px',
    ...GlobalStyles.light,
  },
  document: {
    border: 'none',
    background: 'none',
    marginBottom: '5px',
    cursor: 'pointer',
    color: Colors.navyBlue,
  },
};

class Announcements extends React.Component {
  render() {
    const {
      firstName,
      isLoggedIn,
      announcement,
      pendingPrivacyAnnouncements,
      appLoading = false,
      isLoadingAgreements,
    } = this.props;

    if (!isLoggedIn || appLoading) return null;

    if (
      AppConfig.validateFeature(Features.PRIVACY_DOCUMENTS) &&
      pendingPrivacyAnnouncements.length > 0
    ) {
      return (
        <PendingPrivacyAgreementModal
          title={`Hi ${_.capitalize(firstName)}`}
          styles={styles}
          pendingAnnouncements={pendingPrivacyAnnouncements}
          dismissAnnouncement={this._dismissPrivacyPolicyAnnouncements}
          isLoading={isLoadingAgreements}
        />
      );
    }

    if (announcement) {
      return (
        <AnnouncementAgreementModal
          styles={styles}
          legalText={announcement.legalText}
          dismissAnnouncement={this._dismissAnnouncement}
        />
      );
    }

    return null;
  }

  _dismissAnnouncement = () =>
    this.props.actions.createMemberAgreement({
      agreementTag: this.props.announcement.tag,
    });

  _dismissPrivacyPolicyAnnouncements = async () => {
    this.props.setIsLoadingAgreements(true);
    await Promise.all(
      this.props.pendingPrivacyAnnouncements.map(
        async ({ tag: agreementTag }) =>
          await this.props.actions.createMemberAgreement({ agreementTag })
      )
    );
    this.props.setIsLoadingAgreements(false);
  };
}

Announcements.propTypes = {
  firstName: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  announcement: Agreement,
  pendingPrivacyAnnouncements: PropTypes.arrayOf(Agreement),

  actions: PropTypes.shape({
    createMemberAgreement: PropTypes.func.isRequired,
  }).isRequired,
  appLoading: PropTypes.bool,
  isLoadingAgreements: PropTypes.bool,
  setIsLoadingAgreements: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  const { createMemberAgreement } = AgreementActions;

  return {
    actions: bindActionCreators(
      {
        createMemberAgreement,
      },
      dispatch
    ),
  };
}

function mapStateToProps(state) {
  const { accessToken } = state.signIn;

  const pendingAnnouncements = AgreementSelectors.selectPendingAnnouncementAgreements(
    state
  );

  const pendingPrivacyAnnouncements = AgreementSelectors.selectPendingPrivacyAgreements(
    state
  );

  const announcement = _.head(pendingAnnouncements);

  return {
    isLoggedIn: !!accessToken,
    appLoading: state.signIn.appLoading,
    announcement,
    pendingPrivacyAnnouncements,
  };
}

export default compose(
  withMemberSummaryData,
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoadingAgreements', 'setIsLoadingAgreements', false),
  Radium
)(Announcements);
