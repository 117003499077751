import React from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles,
} from '@material-ui/core';
import compose from 'recompose/compose';
import Navigational from '../../../../common/icons/Navigational';
import MarkdownDescription from '../../../../common/MarkdownDescription';
import HealthAction, { enhance } from './HealthAction';

const styles = () => ({
  root: {
    padding: 0,
  },
  content: {
    margin: 0,
    display: 'block',
  },
});

const ChildHealthAction = ({
  classes,
  defaultExpanded,
  healthAction,
  ...props
}) => (
  <div>
    {healthAction.longDescription ? (
      <ExpansionPanel defaultExpanded={defaultExpanded}>
        <ExpansionPanelSummary
          classes={{ root: classes.root, content: classes.content }}
          expandIcon={<Navigational iconClass="icon-Down-Chevron-Circle" />}
        >
          <HealthAction {...props} healthAction={healthAction} suppressChips />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <MarkdownDescription description={healthAction.longDescription} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ) : (
      <HealthAction {...props} healthAction={healthAction} suppressChips />
    )}
  </div>
);

ChildHealthAction.propTypes = {
  classes: PropTypes.object,
  defaultExpanded: PropTypes.bool,
  healthAction: PropTypes.shape({
    longDescription: PropTypes.string,
  }).isRequired,
};

export default compose(enhance, withStyles(styles))(ChildHealthAction);
