import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { DrawerNavigation } from '../../../../common/drawer2';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import styles from '../../../../common/list/ListItem.styles';
import { withStyles } from '@material-ui/core/styles';

const EducationAction = ({
  classes,
  selectHealthAction,
  title,
  incentiveText,
}) => {
  return (
    <DrawerNavigation
      onClick={selectHealthAction}
      status="neutral"
      title={title}
      subtitle={incentiveText}
      subtitleClass={classes.incentiveToBeEarned}
    />
  );
};

EducationAction.propTypes = {
  classes: PropTypes.object.isRequired,
  selectHealthAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  incentiveText: PropTypes.string.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ education, selectHealthAction }) => {
    const { contentId, title, rewardAmount } = education;

    const incentiveText = IncentiveSummary.getIncentiveEarningText(
      false,
      rewardAmount
    );

    return {
      selectHealthAction: () => selectHealthAction(contentId),
      title,
      incentiveText,
    };
  })
)(EducationAction);
