import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, pure, withHandlers } from 'recompose';
import { withStyles, Grid } from '@material-ui/core';
import { HraActions } from '@healthmine/greyhound-core/src/actions';
import {
  AppConstants,
  LanguageConstants,
} from '@healthmine/greyhound-core/src/constants';
import { HraSelectors } from '@healthmine/greyhound-core/src/selectors';
import Typography from '../../../common/Typography';
import Clickable from '../../../common/Clickable';
import { withDrawer } from '../../../common/drawer2';
import HraQuestions from './HraQuestions';
import Terms from '../../help/top-picks/Terms';
import HraTerms from './HraTerms';
import HraLeave from './HraLeave';
import TypographyStyles from '../../../../styles/Typography';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.venus,
    minHeight: '100%',
    padding: '80px',
  },
  heart: {
    width: '350px',
  },
  start: {
    background: theme.palette.common.white,
    color: theme.palette.common.venus,
    width: '350px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: theme.globalStyles.transition1,
    '&:hover': {
      background: theme.palette.common.formulaOne,
    },
  },
  spanishStart: {
    textAlign: 'center',
    textDecoration: 'underline',
    color: '#fff',
    cursor: 'pointer',
  },
  agreement: {
    textAlign: 'center',
  },
  userWarnText: {
    color: '#f9e32c',
    fontWeight: 'bold',
  },
  link: TypographyStyles.link,
});

const HraIntro = ({
  buttonCaption,
  classes,
  openTerms,
  openHraTerms,
  start,
  hasCompletedHras,
  visitMemberHealthPrograms,
}) => {
  return (
    <div className={classes.root}>
      <Grid container direction="column" alignItems="center" spacing={40}>
        <Grid item>
          <img
            className={classes.heart}
            src={require('../../../../../images/Heart.png')}
            role="presentation"
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center" spacing={16}>
            <Grid item>
              <Typography type="h1" color="white">
                Health Risk Assessment
              </Typography>
            </Grid>
            <Grid item>
              <Typography type="h3" color="white">
                This short assessment contains a series of questions designed to
                help you learn where you are in your health journey. Completing
                this vital assessment helps you answer questions about your
                health, understand your risks, and get the tools you need to
                stay healthy. Click below to get started today!
              </Typography>
            </Grid>
            <Grid item>
              <Typography type="h3" color="white">
                To learn more about staying healthy, visit the{' '}
                <a onClick={visitMemberHealthPrograms} className={classes.link}>
                  Member Health Programs
                </a>{' '}
                page!
              </Typography>
            </Grid>
            {hasCompletedHras && (
              <Grid item>
                <Typography type="h3" className={classes.userWarnText}>
                  In order to complete your Health Risk Assessment, please
                  review your previous answers and update them to make them
                  current to complete your Health Risk Assessment.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <a
            className={classes.start}
            onClick={() => start(LanguageConstants.LanguageCodes.EN)}
          >
            <Typography color="beefy" type="h4Medium">
              {buttonCaption}
            </Typography>
          </a>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center" spacing={8}>
            <Grid item>
              <a
                className={classes.spanishStart}
                onClick={() => start(LanguageConstants.LanguageCodes.ES)}
              >
                <Typography color="white" type="h4Medium">
                  ¿Habla español? Haga clic aquí para aceptar y continuar en
                  español.
                </Typography>
              </a>
            </Grid>
            <Grid item>
              <Typography
                className={classes.agreement}
                color="white"
                type="h4Medium"
              >
                By clicking "{buttonCaption}," you agree to our{' '}
                <Clickable underline onClick={openTerms}>
                  Terms
                </Clickable>
                and
                <Clickable underline onClick={openHraTerms}>
                  Disclosure Statement.
                </Clickable>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

HraIntro.propTypes = {
  hraStatus: PropTypes.string.isRequired,
  buttonCaption: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  openTerms: PropTypes.func.isRequired,
  openHraTerms: PropTypes.func.isRequired,
  start: PropTypes.func.isRequired,
  hasCompletedHras: PropTypes.bool.isRequired,
  visitMemberHealthPrograms: PropTypes.func.isRequired,
};

export default compose(
  pure,
  connect(
    (state) => {
      const hraStatus = HraSelectors.selectHraStatus(state);

      const buttonVerb =
        hraStatus === AppConstants.QuestionnaireStatus.STARTED
          ? 'Finish'
          : 'Start';
      const buttonCaption = `${buttonVerb} Assessment`;
      const hasCompletedHras =
        HraSelectors.selectCompletedHraQuestionnaires(state).length > 0;

      return {
        hraStatus,
        buttonCaption,
        hasCompletedHras,
      };
    },

    (dispatch) => {
      return {
        setHraLanguage: (languageCode) =>
          dispatch(HraActions.setHraLanguage(languageCode)),
      };
    }
  ),
  withRouter,
  withDrawer,
  withHandlers({
    openTerms: ({ drawer }) => () => drawer.open(Terms, null, { large: true }),
    openHraTerms: ({ drawer }) => () =>
      drawer.open(HraTerms, null, { large: true }),
    start: ({ setHraLanguage, drawer }) => (languageCode) =>
      setHraLanguage(languageCode).then(() => {
        drawer.open(HraQuestions, null, {
          resetStack: true,
          large: true,
          disableBackdropClose: true,
          closeConfirmation: HraLeave,
        });
      }),
    visitMemberHealthPrograms: ({ drawer, router }) => () => {
      drawer.close();
      router.push('/health-programs');
    },
  }),
  withStyles(styles)
)(HraIntro);
