import _ from 'lodash';

export const types = {
  BACK_DRAWER: 'web/drawer/back',
  CLOSE_DRAWER: 'web/drawer/close',
  OPEN_DRAWER: 'web/drawer/open',
};

const onDrawerViewCloseCallBack = (state) => {
  return _.invoke(state, 'drawer.onClose');
};

export const backDrawer = () => (dispatch, getState) => {
  onDrawerViewCloseCallBack(getState());
  return dispatch({
    type: types.BACK_DRAWER,
  });
};

export const closeDrawer = () => (dispatch, getState) => {
  onDrawerViewCloseCallBack(getState());
  return dispatch({
    type: types.CLOSE_DRAWER,
  });
};

export const openDrawer = (Component, props, options) => ({
  type: types.OPEN_DRAWER,
  Component,
  props,
  options,
});

export default {
  backDrawer,
  closeDrawer,
  openDrawer,
};
