import _ from 'lodash';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import Dropdown, {
  DropdownTrigger,
  DropdownContent,
} from 'react-simple-dropdown';
import Callout from '../tutorial/callout';

const HeaderMenuMarkup = ({
  menuId,
  menuType,
  isDropdownMenu,
  title,
  subTitle,
  iconClassName,
  iconBubbleContent,
  customStyles,
  showActiveState,
  showHoverState,
  showMenu,
  onHoverStateChange,
  onCaptionClick,
  onMenuClick,
  children,
  tutorial,
  uhcIcon,
}) => {
  const showTutorial = !!(_.get(tutorial, 'page', null) == 'healthOverview');

  const isPrimaryMenu = menuType === 'primary';

  const isLongBubbleCaption = iconBubbleContent.length > 2;

  const uhcBranded = AppConfig.validateFeature(Features.UHC_BRANDED);

  const showUhcIcon = uhcIcon && uhcBranded;

  const styles = {
    captionContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: isPrimaryMenu ? 'row' : 'column',
      width: isPrimaryMenu ? 'auto' : '45px',
      height: '100px',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      cursor: 'pointer',
      boxSizing: 'border-box',
      padding: isPrimaryMenu ? '0px 15px' : '0px',
      color: Colors.bodyText,
      marginBottom: showTutorial ? null : '-1px',
      zIndex: showTutorial ? 900003 : 3000,
      filter: showTutorial ? 'opacity(0.3)' : null,
      borderBottom: showTutorial
        ? null
        : `4px solid ${
            showActiveState
              ? Colors.highlightBlue
              : showHoverState
                ? Colors.contentText
                : 'transparent'
          }`,
      backgroundColor: showTutorial ? Colors.white : 'transparent',

      ..._.get(customStyles, 'captionContainer', {}),
    },
    tutorialBorderBaseMenu: {
      borderTop: 'solid 3px rgb(33, 150, 243)',
      borderLeft: 'solid 3px rgb(33, 150, 243)',
      borderRight: 'solid 3px rgb(33, 150, 243)',
      borderBottom: 'solid 3px rgb(11, 122, 213)',
      boxSizing: 'border-box',
      boxShadow: null,
      borderRadius: '',
    },
    captionContentContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: isPrimaryMenu || showUhcIcon ? 'auto' : '26px',
      height: isPrimaryMenu || showUhcIcon ? 'auto' : '26px',
      color: isPrimaryMenu ? Colors.bodyText : Colors.contentText,
    },

    captionIcon: {
      height: showUhcIcon ? '40px' : isPrimaryMenu ? '28px' : '26px',
      width: showUhcIcon ? '40px' : isPrimaryMenu ? '28px' : '26px',
      fontSize: showUhcIcon ? '40px' : isPrimaryMenu ? '28px' : '26px',
      marginRight: isPrimaryMenu ? '5px' : '0px',
    },

    captionIconBubble: {
      position: 'absolute',
      top: `-${isLongBubbleCaption ? 10 : 5}px`,
      left: `-${isLongBubbleCaption ? 10 : 5}px`,
      backgroundColor: Colors.lightGreen,
      border: '1px solid #fff',
      color: '#fff',
      display: 'inline-block',
      float: 'right',
      width: `${isLongBubbleCaption ? 20 : 17}px`,
      height: `${isLongBubbleCaption ? 20 : 17}px`,
      fontSize: '16px',
      lineHeight: `${isLongBubbleCaption ? 20 : 17}px`,
      textAlign: 'center',
      borderRadius: '50%',
    },

    primaryCaptionTitle: {
      fontSize: '16px',
      whiteSpace: 'pre-line',
    },

    textCaption: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: Colors.bodyText,
    },

    captionTitle: {
      fontSize: '16px',
      margin: '-1px 0px 3px',
    },

    captionSubTitle: {
      fontSize: '17px',
    },

    dropdownContent: {
      left: isPrimaryMenu ? '0px' : 'auto',
      right: isPrimaryMenu ? 'auto' : '0px',
    },

    menuContainer: {
      marginTop: showTutorial ? null : '-3px',
      width: '275px',
      display: 'flex',
      flexDirection: 'column',
      padding: '0px 0px 15px 0px',
      borderWidth: showTutorial ? null : '1px',
      borderStyle: showTutorial ? null : 'solid',
      borderColor: showTutorial ? null : Colors.gutterGray,
      borderTopWidth: showTutorial ? null : '4px',
      boxShadow: showTutorial
        ? null
        : `2px 2px 2px ${Colors.lightenColor(Colors.lightGray)}`,
      borderRadius: showTutorial ? null : '0px 0px 5px 5px',
      zIndex: showTutorial ? 900003 : null,
      backgroundColor: '#fff',
    },
    tutorialBorderDropMenu: {
      border: 'solid 3px rgb(33, 150, 243)',
      boxSizing: 'border-box',
      boxShadow: null,
      borderRadius: '',
      marginTop: '-3px',
    },
    tutorialFilter: {
      filter: showTutorial ? 'opacity(.3)' : null,
    },
    tutorialBringToFront: {
      zIndex: showTutorial ? 900003 : null,
    },
  };

  const captionId = `MenuCaption_${menuId}`;

  let menuUI =
    iconClassName || showUhcIcon ? (
      <span style={styles.captionContentContainer}>
        {showUhcIcon ? (
          <img src={uhcIcon} style={styles.captionIcon} />
        ) : (
          <i className={iconClassName} style={styles.captionIcon} />
        )}
        {iconBubbleContent ? (
          <span style={[styles.captionIconBubble, GlobalStyles.extraLight]}>
            {iconBubbleContent}
          </span>
        ) : null}

        {isPrimaryMenu && title ? (
          <span style={[styles.primaryCaptionTitle, GlobalStyles.regular]}>
            {title}
          </span>
        ) : null}
      </span>
    ) : (
      <div style={styles.textCaption}>
        <span style={[styles.captionTitle, GlobalStyles.light]}>{title}</span>

        <span style={[styles.captionSubTitle, GlobalStyles.bold]}>
          {subTitle}
        </span>
      </div>
    );

  if (isDropdownMenu) {
    const contentId = `MenuContent_${menuId}`;

    menuUI = (
      <Dropdown
        active={showMenu || showTutorial}
        style={styles.tutorialBringToFront}
      >
        <div style={showTutorial ? styles.tutorialBorderBaseMenu : null}>
          {showTutorial ? (
            <Callout
              path={'M175 40 C 175 40, 100 40, 85 18'}
              position={{ top: '230px', left: '-50px' }}
              title={'Manage'}
              text={
                'Your go-to place for health data--whether you track it manually or via a connected device,' +
                " it's all here. You can also add or change your Care Plans with ease."
              }
              direction={{ flexDirection: 'row' }}
              arrowEnd={'markerEnd'}
            />
          ) : null}
          <DropdownTrigger
            id={captionId}
            style={styles.captionContainer}
            onClick={() => onCaptionClick()}
            onMouseEnter={() => onHoverStateChange(true)}
            onMouseLeave={(evt) => {
              if (_.get(evt, 'relatedTarget.id') !== contentId) {
                onHoverStateChange(false);
              }
            }}
          >
            {menuUI}
          </DropdownTrigger>
        </div>
        <DropdownContent style={styles.dropdownContent}>
          <div style={showTutorial ? styles.tutorialBorderDropMenu : null}>
            <div
              id={contentId}
              style={[
                GlobalStyles.regular,
                styles.menuContainer,
                styles.tutorialFilter,
              ]}
              onMouseEnter={() => onHoverStateChange(true)}
              onMouseLeave={(evt) => {
                if (_.get(evt, 'relatedTarget.id') !== captionId) {
                  onHoverStateChange(false);
                }
              }}
              onClick={() => onMenuClick()}
            >
              {children}
            </div>
          </div>
        </DropdownContent>
      </Dropdown>
    );
  } else {
    menuUI = (
      <div
        id={captionId}
        style={styles.captionContainer}
        onClick={() => onCaptionClick()}
        onMouseEnter={() => onHoverStateChange(true)}
        onMouseLeave={() => onHoverStateChange(false)}
      >
        {menuUI}
      </div>
    );
  }

  return menuUI;
};

HeaderMenuMarkup.propTypes = {
  menuId: PropTypes.string.isRequired,
  menuType: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  isDropdownMenu: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  iconClassName: PropTypes.string.isRequired,
  iconBubbleContent: PropTypes.string.isRequired,
  customStyles: PropTypes.object.isRequired,
  showActiveState: PropTypes.bool.isRequired,
  showHoverState: PropTypes.bool.isRequired,
  showMenu: PropTypes.bool.isRequired,
  onHoverStateChange: PropTypes.func.isRequired,
  onCaptionClick: PropTypes.func.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  uhcIcon: PropTypes.string,

  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Radium(HeaderMenuMarkup);
