export default {
  root: {
    height: '200px',
    width: '250px',
    borderRadius: '4px',
    backgroundColor: '#1C2B43',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.3)',
    color: '#fff',
    padding: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },

  icon: {
    fontSize: '40px',
  },

  title: {
    marginTop: '10px',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '22px',
    marginBottom: '10px',
  },

  completedText: {
    fontSize: '16px',
    lineHeight: '17px',
  },

  incentiveAmount: {
    fontSize: '16px',
    lineHeight: '17px',
  },
};
