import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Radium from 'radium';

import Button from '../common/Button';
import { GlobalStyles, Assets } from '@healthmine/greyhound-core/src/modules';

const AnnouncementAgreementModal = ({
  styles,
  legalText,
  dismissAnnouncement,
}) => {
  return (
    <Modal
      isOpen
      contentLabel="Announcements"
      style={styles.customStyles}
      onRequestClose={dismissAnnouncement}
    >
      <div style={[styles.modalContent, GlobalStyles.light]}>
        <div style={styles.pageTitle}>
          <img
            src={Assets.getAssetURL('logo_primary.png')}
            alt="logo"
            style={styles.logo}
          />
          <a onClick={dismissAnnouncement} style={styles.closeButton}>
            <i className="thin-0153_delete_exit_remove_close" />
          </a>
        </div>
        {/* eslint-disable react/no-danger */}
        <div
          style={styles.pageContent}
          dangerouslySetInnerHTML={{
            __html: legalText,
          }}
        />
        {/* eslint-enable react/no-danger */}
        <div style={styles.pageFooter}>
          <Button
            style={styles.button}
            text="Continue"
            onClick={dismissAnnouncement}
          />
        </div>
      </div>
    </Modal>
  );
};

AnnouncementAgreementModal.propTypes = {
  styles: PropTypes.object.isRequired,
  legalText: PropTypes.string.isRequired,
  dismissAnnouncement: PropTypes.func.isRequired,
};

export default Radium(AnnouncementAgreementModal);
