import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    padding: '0 24px',
  },

  container: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  coverageSection: {
    display: 'inline-block',
  },

  title: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '30px',
  },

  imageSpacing: {
    marginRight: '20px',
    marginTop: '20px',
  },

  mainHeader: {
    fontSize: '24px',
    margin: '0 0 5px',
    color: Colors.black,
  },

  subHeaderText: {
    margin: '0 0 20px',
    color: Colors.black,
    maxWidth: '520px',
    fontSize: '16px',
  },

  iconImage: {
    fontSize: '35px',
    color: Colors.white,
    backgroundColor: Colors.bodyText,
    borderRadius: '50%',
    padding: '20px',
  },

  coveragesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '36px 0',
  },

  coverageCard: {
    backgroundColor: Colors.white,
    boxShadow: GlobalStyles.shadow1.boxShadow,
    padding: '12px 72px 12px 24px',
    margin: '5px',
    width: '285px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    position: 'relative',
  },

  coverageName: {
    fontSize: '20px',
    fontWeight: '200',
    color: Colors.darkGray,
    paddingBottom: '5px',
  },

  coverageTagLine: {
    fontSize: '16px',
    color: Colors.lightGray,
  },

  iconWrapper: {
    height: '36px',
    width: '36px',
    position: 'absolute',
    right: '24px',
    top: '50%',
    transform: 'translateY(-50%)',
  },

  icon: {
    color: Colors.highlightBlue,
    fontSize: '36px',
  },

  bottomLinks: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
