import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  compose,
  withStateHandlers,
  withProps,
  withHandlers,
  lifecycle,
} from 'recompose';
import _ from 'lodash';
import fp from 'lodash/fp';
import {
  QuestionBox,
  QuestionnaireSectionProgress,
  WelcomeBox,
  SkipWarningBox,
  VerifyQuestionnaireBox,
  ExitWarningBox,
  QuestionnaireProgressBar,
} from './';
import classnames from 'classnames';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import Typography from '../../common/Typography';

const styles = () => ({
  root: {
    color: 'white',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  background: {
    width: '100%',
    maxWidth: '1024px',
    maxHeight: '640px',
    alignItems: 'center',
    padding: '16px',
    fontFamily: 'Rubik',
    position: 'relative',
    overflow: 'hidden',
    height: '640px',
  },
  backgroundImage: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '-1',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    fontSize: '30px',
    color: 'white',
    cursor: 'pointer',
  },
  exit: {
    position: 'fixed',
    top: '8px',
    left: '8px',
  },
});
const Questionnaire = ({
  classes,
  currentQuestionIndex,
  nextQuestion,
  prevQuestion,
  updateAnswers,
  shouldBeNext,
  currentAnswers,
  currentQuestions,
  markAsValid,
  updateAnswersOnNext,
  sections,
  currentSectionIndex,
  welcoming,
  startQuestionnaire,
  backgroundImagePath,
  doneWithSkipWarning,
  showSkipWarning,
  verifying,
  isComplete,
  setVerifying,
  isLoading,
  completeQuestionnaire,
  sectionImages,
  questionnaireText,
  isSkippable,
  isExitable,
  exiting,
  setExiting,
  onExit,
  hasExitFeature,
  showProgressBar,
  relevantQuestions,
}) => (
  <div className={classes.root}>
    <Grid
      container
      className={classnames(classes.background, {
        [classes.centered]: welcoming || !backgroundImagePath,
      })}
    >
      {backgroundImagePath && (
        <img
          className={classes.backgroundImage}
          src={require(`../../../../images/${backgroundImagePath}`)}
        />
      )}
      {(isExitable || hasExitFeature) &&
        !exiting && (
          <div className={classes.exit}>
            <i
              className={classnames('icon-Checkmark-X', classes.closeIcon)}
              onClick={setExiting}
            />
            <Typography>Exit</Typography>
          </div>
        )}
      {exiting ? (
        <ExitWarningBox
          text={_.get(questionnaireText, 'exit')}
          doneWithExitWarning={onExit}
        />
      ) : welcoming ? (
        <WelcomeBox
          text={_.get(questionnaireText, 'welcome')}
          startQuestionnaire={startQuestionnaire}
          foregroundImage={_.get(sectionImages, 'welcomeForeground')}
        />
      ) : showSkipWarning ? (
        <SkipWarningBox doneWithSkipWarning={doneWithSkipWarning} />
      ) : verifying ? (
        <VerifyQuestionnaireBox
          onBack={() => setVerifying(false)}
          isIncomplete={!isComplete}
          onComplete={completeQuestionnaire}
          text={_.get(questionnaireText, 'done')}
        />
      ) : (
        <div className={classes.content}>
          {sections && (
            <QuestionnaireSectionProgress
              sections={sections}
              currentSectionIndex={currentSectionIndex}
              verifying={false}
              isIncomplete={false}
              showSkipWarning={false}
            />
          )}
          {showProgressBar && (
            <QuestionnaireProgressBar
              currentQuestionIndex={currentQuestionIndex}
              relevantQuestions={relevantQuestions}
            />
          )}
          <QuestionBox
            currentAnswers={currentAnswers}
            questions={currentQuestions}
            prevQuestion={prevQuestion}
            nextQuestion={nextQuestion}
            updateAnswers={updateAnswers}
            nextButtonText={shouldBeNext ? 'next' : 'skip'}
            noPrevButton={currentQuestionIndex === 0}
            markAsValid={markAsValid}
            updateAnswersOnNext={updateAnswersOnNext}
            isLoading={isLoading}
            isSkippable={isSkippable}
            avatar={
              !welcoming && !verifying && !showSkipWarning && !exiting
                ? _.get(sectionImages, 'avatar')
                : undefined
            }
          />
        </div>
      )}
    </Grid>
  </div>
);

Questionnaire.propTypes = {
  classes: PropTypes.object.isRequired,
  completeQuestionnaire: PropTypes.func.isRequired,
  sectionImages: PropTypes.object,
  isSkippable: PropTypes.bool,
  isExitable: PropTypes.bool,
  onQuestionnaireExit: PropTypes.func,
  showProgressBar: PropTypes.bool,
  questionnaireText: PropTypes.shape({
    welcome: PropTypes.shape({
      subtitle: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      cta: PropTypes.string,
    }),
    exit: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      backCta: PropTypes.string,
      forwardCta: PropTypes.string,
    }),
    skip: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      backCta: PropTypes.string,
      forwardCta: PropTypes.string,
    }),
    done: PropTypes.shape({
      incomplete: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      complete: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  }),
  // from withProps
  relevantQuestions: PropTypes.array.isRequired,
  // from withStateHandlers
  currentQuestionIndex: PropTypes.number.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  prevQuestion: PropTypes.func.isRequired,
  markAsValid: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  welcoming: PropTypes.bool.isRequired,
  showSkipWarning: PropTypes.bool.isRequired,
  verifying: PropTypes.bool.isRequired,
  setVerifying: PropTypes.func.isRequired,
  startQuestionnaire: PropTypes.func.isRequired,
  doneWithSkipWarning: PropTypes.func.isRequired,
  exiting: PropTypes.bool.isRequired,
  setExiting: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  hasExitFeature: PropTypes.bool.isRequired,
  // from withProps
  shouldBeNext: PropTypes.bool.isRequired,
  currentQuestions: PropTypes.array,
  currentAnswers: PropTypes.object,
  sections: PropTypes.array,
  currentSectionIndex: PropTypes.number,
  backgroundImagePath: PropTypes.string,
  isComplete: PropTypes.bool.isRequired,
  // from withHandlers
  updateAnswers: PropTypes.func.isRequired,
  updateAnswersOnNext: PropTypes.func.isRequired,
};

export default compose(
  withProps(({ questionnaire, dependencyAnswers }) => ({
    relevantQuestions: fp.flow(
      fp.get('questionnaire.questions'),
      fp.filter(
        (question) =>
          fp.flow(
            fp.get('questionDependency'),
            fp.some((dependency) =>
              fp.flow(
                fp.get(dependency.dependsOnQuestionId),
                fp.some(
                  (answer) =>
                    _.get(answer, 'answer') ===
                    _.get(dependency, 'dependsOnQaOption')
                )
              )(dependencyAnswers)
            )
          )(question) || !_.has(question, 'questionDependency')
      ),
      fp.groupBy('displayOrder'),
      fp.map((set) => set)
    )(questionnaire),
    hasExitFeature: AppConfig.validateFeature(Features.EXIT_FTE),
  })),
  withStateHandlers(
    ({ relevantQuestions, savedAnswers }) => {
      const currentQuestionIndex = _.findIndex(
        relevantQuestions,
        (questionSet) =>
          _.find(questionSet, (question) => !_.has(savedAnswers, question.id))
      );
      const markedAsValid = _.map(
        _.get(relevantQuestions, currentQuestionIndex),
        (question) => _.has(savedAnswers, _.get(question, 'id'))
      );
      const welcoming = true;
      const showSkipWarning = false;
      const hasShownSkipWarning = false;
      const verifying = currentQuestionIndex === -1;
      const isLoading = false;
      const exiting = false;

      return {
        currentQuestionIndex,
        markedAsValid,
        welcoming,
        showSkipWarning,
        hasShownSkipWarning,
        verifying,
        isLoading,
        exiting,
      };
    },
    {
      setExiting: (x, { hasExitFeature, onQuestionnaireExit }) => () => {
        if (hasExitFeature) {
          onQuestionnaireExit();
          return { exiting: false };
        } else return { exiting: true };
      },
      onExit: (x, { isExitable, onQuestionnaireExit }) => (confirmed) => {
        if (isExitable && confirmed) onQuestionnaireExit();
        else if (isExitable && !confirmed) return { exiting: false };
      },
      setIsLoading: () => (isLoading) => ({ isLoading }),
      setVerifying: ({ currentQuestionIndex }, { relevantQuestions }) => (
        verifying
      ) => {
        return {
          verifying,
          currentQuestionIndex:
            currentQuestionIndex === -1
              ? relevantQuestions.length - 1
              : currentQuestionIndex,
        };
      },
      startQuestionnaire: () => () => ({
        welcoming: false,
      }),
      doneWithSkipWarning: (
        { currentQuestionIndex },
        { relevantQuestions }
      ) => (stay) => {
        return {
          currentQuestionIndex: stay
            ? currentQuestionIndex
            : currentQuestionIndex + 1,
          showSkipWarning: false,
          hasShownSkipWarning: true,
          verifying:
            currentQuestionIndex === _.size(relevantQuestions) - 1 && !stay,
        };
      },
      nextQuestion: (
        { currentQuestionIndex, markedAsValid, hasShownSkipWarning },
        { relevantQuestions }
      ) => () => {
        const showSkipWarning = !_.every(markedAsValid) && !hasShownSkipWarning;
        return {
          currentQuestionIndex: showSkipWarning
            ? currentQuestionIndex
            : _.min([_.size(relevantQuestions) - 1, currentQuestionIndex + 1]),
          showSkipWarning,
          verifying:
            currentQuestionIndex === relevantQuestions.length - 1 &&
            !showSkipWarning,
        };
      },
      prevQuestion: ({ currentQuestionIndex }) => () => {
        return {
          currentQuestionIndex: _.max([0, currentQuestionIndex - 1]),
        };
      },
      setMarkedAsValid: () => (markedAsValid) => ({ markedAsValid }),
      markAsValid: (
        { currentQuestionIndex, markedAsValid },
        { relevantQuestions }
      ) => (questionId, isValid) => {
        const newMarkedAsValid = _.map(
          _.get(relevantQuestions, currentQuestionIndex),
          (question, idx) =>
            question.id === questionId ? isValid : markedAsValid[idx]
        );
        return { markedAsValid: newMarkedAsValid };
      },
    }
  ),
  withProps(
    ({
      savedAnswers,
      relevantQuestions,
      currentQuestionIndex,
      markedAsValid,
      sectionImages,
      welcoming,
      verifying,
    }) => {
      const currentQuestions = _.orderBy(
        _.get(relevantQuestions, currentQuestionIndex),
        ['displayOrder', 'subDisplayOrder'],
        ['asc', 'asc']
      );
      const currentAnswers = _.pick(
        savedAnswers,
        _.map(currentQuestions, (question) => question.id)
      );
      const shouldBeNext =
        _.every(_.get(relevantQuestions, currentQuestionIndex), (question) =>
          _.has(savedAnswers, question.id)
        ) || _.every(markedAsValid);
      const sections = _(relevantQuestions)
        .map((questionSet) => _.get(questionSet, '[0].sectionTitle'))
        .uniq()
        .value();
      const currentSectionIndex = _.findIndex(
        sections,
        (section) => _.get(currentQuestions, '[0].sectionTitle') === section
      );
      const isComplete = _.every(relevantQuestions, (questionSet) =>
        _.every(questionSet, (question) => _.has(savedAnswers, question.id))
      );
      const backgroundImagePath = sectionImages
        ? welcoming
          ? _.get(sectionImages, 'welcome', sectionImages.fallback)
          : verifying
            ? isComplete
              ? _.get(sectionImages, 'doneComplete', sectionImages.fallback)
              : _.get(sectionImages, 'doneIncomplete', sectionImages.fallback)
            : currentSectionIndex !== -1
              ? _.get(
                  sectionImages,
                  currentSectionIndex + 1,
                  sectionImages.fallback
                )
              : sectionImages.fallback
        : null;
      const progress =
        (currentQuestionIndex + 1) / _.size(relevantQuestions) * 100;
      return {
        currentQuestions,
        currentAnswers,
        shouldBeNext,
        sections: _.every(sections, (section) => section === undefined)
          ? undefined
          : sections,
        currentSectionIndex,
        backgroundImagePath,
        isComplete,
        progress,
      };
    }
  ),
  withHandlers(() => ({
    updateAnswersOnNext: ({
      questionnaire,
      submitAnswer,
      setIsLoading,
      nextQuestion,
    }) => (answerObject) => {
      const answersForSubmission = _.map(
        answerObject,
        (question, questionId) =>
          _.get(question, 'questionType') === 'CHECKBOXES'
            ? {
                questionType: 'CHECKBOXES',
                questionId,
                questionAnswerOptionId: _.map(
                  _.get(question, 'answers'),
                  (answer) => _.get(answer, 'id')
                ),
              }
            : _.get(question, 'questionType') === 'TEXTBOX'
              ? {
                  questionType: 'TEXTBOX',
                  questionId,
                  textAnswers: _.get(question, 'answers'),
                }
              : {}
      );
      if (_.size(answersForSubmission) > 0) {
        setIsLoading(true);
        submitAnswer(
          answersForSubmission,
          _.get(questionnaire, 'memberQuestionnaire.id'),
          _.get(questionnaire, 'memberQuestionnaire.type')
        ).then(() => {
          setIsLoading(false);
          nextQuestion();
        });
      }
    },
    updateAnswers: ({
      questionnaire,
      setIsLoading,
      submitAnswer,
      nextQuestion,
      currentQuestions,
    }) => (questionId, answer, questionType) => {
      const shapedForSubmission =
        questionType === 'TEXTBOX'
          ? [
              {
                questionType,
                questionId,
                textAnswers: [answer],
              },
            ]
          : questionType === 'RADIO_BUTTONS'
            ? [
                {
                  questionType,
                  questionId,
                  questionAnswerOptionId: [_.get(answer, 'id')],
                },
              ]
            : [];
      if (_.size(shapedForSubmission) > 0) {
        setIsLoading(true);
        submitAnswer(
          shapedForSubmission,
          _.get(questionnaire, 'memberQuestionnaire.id'),
          _.get(questionnaire, 'memberQuestionnaire.type')
        ).then(() => {
          setIsLoading(false);
          if (_.size(currentQuestions) <= 1) nextQuestion();
        });
      }
    },
  })),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const relevantQuestions = _.get(nextProps, 'relevantQuestions');
      const savedAnswers = _.get(nextProps, 'savedAnswers');
      const currentQuestionIndex = _.get(nextProps, 'currentQuestionIndex');
      if (_.get(this.props, 'currentQuestionIndex') !== currentQuestionIndex) {
        this.props.setMarkedAsValid(
          _.map(_.get(relevantQuestions, currentQuestionIndex), (question) =>
            _.has(savedAnswers, _.get(question, 'id'))
          )
        );
      }
    },
  }),
  withStyles(styles)
)(Questionnaire);
