import React from 'react';
import PropTypes from 'prop-types';
import PreventiveHeaderMarkup from './PreventiveHeaderMarkup';
import {
  CompletionStatus,
  HRAState,
  BiometricState,
} from '@healthmine/greyhound-core/src/constants/CarePlan';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';

class PreventiveHeader extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      pointsOpen: false,
    };

    this._togglePoints = this._togglePoints.bind(this);
  }

  render() {
    const { healthActions, HRAStatus, biometricStatus, carePlan } = this.props;

    const { completedCount, totalCount } = carePlan;

    const selectedAlert = getSelectedAlert(
      healthActions,
      HRAStatus,
      biometricStatus
    );

    return (
      <PreventiveHeaderMarkup
        selectedAlert={selectedAlert}
        measure={{
          label: `${completedCount} / ${totalCount}`,
          value: `${completedCount} / ${totalCount}`,
        }}
        togglePoints={this._togglePoints}
        {...this.state}
        {...this.props}
      />
    );
  }

  _togglePoints() {
    this.setState({
      pointsOpen: !this.state.pointsOpen,
    });
  }
}

const getHraLink = (hraStatus) => {
  const { lingo } = AppConfig.effectiveConfig;

  return hraStatus !== undefined
    ? hraStatus === CompletionStatus.COMPLETED
      ? HRAState.hraCompleted(lingo)
      : hraStatus === CompletionStatus.STARTED
        ? HRAState.hraStarted(lingo)
        : hraStatus === CompletionStatus.NOTSTARTED
          ? HRAState.hraStateNotStarted(lingo)
          : null
    : null;
};

const getBiometricLink = (biometricStatus) =>
  biometricStatus !== undefined
    ? biometricStatus === CompletionStatus.COMPLETED
      ? BiometricState.SCREENING_COMPLETED
      : biometricStatus === CompletionStatus.STARTED
        ? BiometricState.SCREENING_STARTED
        : biometricStatus === CompletionStatus.NOTSTARTED
          ? BiometricState.SCREENING_NOTSTARTED
          : null
    : null;

const isTheHra = (healthAction) =>
  healthAction &&
  healthAction.healthActionCd ===
    AppConstants.HealthActions.COMPLETE_HEALTH_QUESTIONNAIRE;

const isBiometricScreening = (healthAction) =>
  healthAction &&
  healthAction.healthActionCd ===
    AppConstants.HealthActions.COMPLETE_BIOMETRIC_TESTING;

const getSelectedAlert = (healthActions, hraStatus, biometricStatus) => {
  if (!healthActions) return null;

  if (!hraStatus || hraStatus === 'COMPLETED') {
    if (!biometricStatus || biometricStatus === 'COMPLETED') return null;

    const biometricAction = healthActions.find((healthAction) =>
      isBiometricScreening(healthAction)
    );
    return (
      biometricAction && {
        description: biometricAction.description,
        status: biometricStatus,
        linkText: getBiometricLink(biometricStatus),
        linkRoute: '/biometric-screening',
      }
    );
  }

  const hraAction = healthActions.find((healthAction) =>
    isTheHra(healthAction)
  );
  return (
    hraAction && {
      description: hraAction.description,
      status: hraStatus,
      linkText: getHraLink(hraStatus),
      linkRoute: '/hra',
    }
  );
};

PreventiveHeader.propTypes = {
  carePlan: PropTypes.object,
  goalStatement: PropTypes.object,
  healthActions: PropTypes.array,
  HRAStatus: PropTypes.string,
  biometricStatus: PropTypes.string,
};

export default PreventiveHeader;
