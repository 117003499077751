import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import Icons from '@healthmine/greyhound-core/src/modules/Icons';
import DateComponent from '../../common/question/Date';
import moment from 'moment';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import Step from './Step';

const DateStep = ({
  active,
  scrollId,
  nextStep,
  previousStep,
  startDate,
  maxDate,
  duration,
  startDateChanged,
  last,
}) => {
  const endDate =
    duration === 365
      ? maxDate
      : moment
          .min(moment(maxDate), moment(startDate).add(duration, 'days'))
          .format('YYYY-MM-DD');

  const selectedStartDay = moment(startDate).date();

  const selectedStartMonth = moment(startDate).month();

  const selectedStartYear = moment(startDate).year();

  const selectedEndDay = moment(endDate).date();

  const selectedEndMonth = moment(endDate).month();

  const selectedEndYear = moment(endDate).year();

  const styles = {
    container: {
      marginTop: '60px',
      paddingLeft: '100px',
    },
    description: {
      fontSize: '16px',
      color: Colors.dark,
      margin: '0 0 35px',
      maxWidth: '615px',
    },
    note: {
      top: '-25px',
      position: 'relative',
      margin: 0,
      color: Colors.dark,
    },
    dateContainer: {
      marginTop: '20px',
    },
    dateLabel: {
      display: 'block',
      fontSize: '16px',
      color: Colors.dark,
      marginBottom: '15px',
    },
    dateValue: {
      display: 'inline-block',
      verticalAlign: 'middle',
      fontSize: '20px',
      lineHeight: '20px',
      color: Colors.dark,
    },
    dateComponent: {
      display: 'inline-block',
    },
    dateSelector: {
      height: '34px',
      fontSize: '16px',
      color: Colors.darkGray,
      display: 'inline-block',
      verticalAlign: 'middle',
      borderRadius: '6px',
      marginRight: '15px',
      paddingLeft: '5px',
    },
  };

  return (
    <Step
      title={'Set a Start Date For This Plan'}
      summaryLines={[
        `Start Date: ${
          localizedContent.months[selectedStartMonth]
        } ${selectedStartDay}, ${selectedStartYear}`,
        `End Date: ${
          localizedContent.months[selectedEndMonth]
        } ${selectedEndDay}, ${selectedEndYear}`,
      ]}
      iconClass={Icons.CALENDAR}
      active={active}
      scrollId={scrollId}
      previousStep={previousStep}
      nextStep={nextStep}
      required
      last={last}
      valid={
        moment(startDate).isSameOrAfter(moment().startOf('day')) &&
        moment(endDate).isSameOrBefore(moment(maxDate))
      }
    >
      <div style={[styles.container, GlobalStyles.regular]}>
        <p style={styles.description}>
          {localizedContent.plans.DateConfig.instructions(duration)}
        </p>

        {duration !== 365 ? (
          <p style={styles.note}>
            Duration can not be greater than {duration} days for this plan.
          </p>
        ) : null}

        <div style={styles.dateContainer}>
          <label style={styles.dateLabel}>Start Date</label>
          {duration === 365 ? (
            <span style={styles.dateValue}>
              {`${
                localizedContent.months[selectedStartMonth]
              } ${selectedStartDay}, ${selectedStartYear}`}
            </span>
          ) : (
            <div>
              <DateComponent
                minDate={moment()}
                maxDate={moment(maxDate)}
                value={moment(startDate).format('YYYY-MM-DD')}
                validationError={`Please select date between ${moment().format(
                  'YYYY-MM-DD'
                )} and ${moment(maxDate).format('YYYY-MM-DD')}`}
                fullMonth
                styles={{
                  container: { marginTop: 10 },
                  day: { width: 110 },
                  month: { width: 60 },
                  year: { width: 80 },
                }}
                onAnswered={startDateChanged}
                useDefaultSelect
              />
            </div>
          )}
        </div>

        <div style={styles.dateContainer}>
          <label style={styles.dateLabel}>End Date</label>
          <span style={styles.dateValue}>{`${
            localizedContent.months[selectedEndMonth]
          } ${selectedEndDay}, ${selectedEndYear}`}</span>
        </div>
      </div>
    </Step>
  );
};

DateStep.propTypes = {
  active: PropTypes.bool.isRequired,
  last: PropTypes.bool,
  scrollId: PropTypes.string.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,

  setStartDate: PropTypes.func.isRequired,
  startDateChanged: PropTypes.func,
};

export default compose(
  withHandlers({
    startDateChanged: ({ setStartDate }) => (date) => {
      setStartDate(date);
    },
  }),
  Radium
)(DateStep);
