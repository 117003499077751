import { types } from './actions';

const initialState = {
  default: 'Maria',
  selected: 'Maria',
  people: ['Maria', 'Anthony', 'Sofia'],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DEPENDENT:
      return {
        ...state,
        selected: action.payload,
      };

    default:
      return state;
  }
};
