import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { compose, withProps, withStateHandlers, lifecycle } from 'recompose';
import AnswerOption from './AnswerOption';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
});

const RadioButtonAnswer = ({
  classes,
  answers,
  markAnswer,
  question,
  currentAnswer,
  isSelected,
}) => (
  <div className={classes.root}>
    {_(answers)
      .sortBy('displayOrder')
      .map((opt, idx) => {
        const isChosen = _.some(isSelected)
          ? get(isSelected, idx, false)
          : get(currentAnswer, 'answer') === opt.id;
        return (
          <AnswerOption
            key={idx}
            index={idx}
            answer={opt}
            iconName={get(opt, 'iconName', null)}
            updateAnswers={markAnswer}
            questionId={get(question, 'id')}
            isChosen={isChosen}
            questionType={get(question, 'type')}
          />
        );
      })
      .value()}
  </div>
);

RadioButtonAnswer.propTypes = {
  question: PropTypes.object.isRequired,
  updateAnswers: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  currentAnswer: PropTypes.object,
  markAnswer: PropTypes.func.isRequired,
  isSelected: PropTypes.array.isRequired,
};

export default compose(
  withStyles(styles),
  withProps(({ question, currentAnswers }) => ({
    answers: get(question, 'answerOptions', []),
    currentAnswer: get(currentAnswers, 0),
  })),
  withStateHandlers(
    ({ answers, currentAnswer }) => ({
      isSelected: _.map(
        answers,
        (answer) => !!_.find(currentAnswer, { answer: answer.id })
      ),
    }),
    {
      updateIsSelected: () => (isSelected) => ({ isSelected }),
      markAnswer: ({ isSelected }, { updateAnswers, markAsValid }) => (
        questionId,
        answer,
        questionType,
        index
      ) => {
        updateAnswers(questionId, answer, questionType);
        markAsValid(questionId, true);
        isSelected = _.map(isSelected, (answer, idx) => idx === index);
        return { isSelected };
      },
    }
  ),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (
        _.get(this.props, 'question.id') !== _.get(nextProps, 'question.id') ||
        _.get(this.props, 'currentAnswer') !== _.get(nextProps, 'currentAnswer')
      ) {
        this.props.updateIsSelected(
          _.map(
            nextProps.answers,
            (answer) => !!_.find(nextProps.currentAnswer, { answer: answer.id })
          )
        );
      }
    },
  })
)(RadioButtonAnswer);
