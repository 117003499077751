import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserInfoActions } from '@healthmine/greyhound-core/src/actions';
import {
  AppConstants,
  ErrorConstants,
} from '@healthmine/greyhound-core/src/constants';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import ChangePasswordMarkup from './ChangePasswordMarkup';

class ChangePassword extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      formData: {
        password: {
          value: '',
          isValid: false,
          error: '',
        },

        newPassword: {
          value: '',
          isValid: false,
          error: '',
        },

        confirmNewPassword: {
          value: '',
          isValid: false,
          error: '',
        },
      },

      isFormValid: false,
      isActionComplete: false,
    };
  }

  render() {
    const { editPassword, toggleEditPassword } = this.props;

    const {
      formData: { password, newPassword, confirmNewPassword },
      isFormValid,
      isActionComplete,
    } = this.state;

    return (
      <ChangePasswordMarkup
        password={password}
        newPassword={newPassword}
        confirmNewPassword={confirmNewPassword}
        editPassword={editPassword}
        toggleEditPassword={toggleEditPassword}
        updatePassword={this._updatePassword}
        updateNewPassword={this._updateNewPassword}
        updateConfirmNewPassword={this._updateConfirmNewPassword}
        savePassword={this._savePassword}
        isFormValid={isFormValid}
        isActionComplete={isActionComplete}
      />
    );
  }

  _updatePassword = (evt) => {
    const { formData } = this.state;

    const { password } = formData;

    password.value = _.get(evt, 'target.value', '');

    password.error = !AppConstants.PasswordRegEx.test(password.value)
      ? localizedContent.errorMessages[
          ErrorConstants.ErrorType.INVALID_PASSWORD
        ]
      : '';

    password.isValid = !password.error;

    this.setState({
      formData,
    });

    this._isFormValid();
  };

  _updateNewPassword = (evt) => {
    const { formData } = this.state;

    const { newPassword, confirmNewPassword } = formData;

    newPassword.value = _.get(evt, 'target.value', '');

    newPassword.error = !AppConstants.PasswordRegEx.test(newPassword.value)
      ? localizedContent.errorMessages[
          ErrorConstants.ErrorType.INVALID_PASSWORD_FORMAT
        ]
      : '';

    newPassword.isValid = !newPassword.error;

    if (newPassword.isValid) {
      confirmNewPassword.error =
        confirmNewPassword.value &&
        confirmNewPassword.value !== newPassword.value
          ? localizedContent.errorMessages[
              ErrorConstants.ErrorType.PASSWORD_CONFIRMPASSWORD_MISMATCH
            ]
          : '';

      confirmNewPassword.isValid = !confirmNewPassword.error;
    }

    this.setState({
      formData,
    });

    this._isFormValid();
  };

  _updateConfirmNewPassword = (evt) => {
    const { formData } = this.state;

    const { newPassword, confirmNewPassword } = formData;

    confirmNewPassword.value = _.get(evt, 'target.value', '');

    confirmNewPassword.error =
      confirmNewPassword.value !== newPassword.value
        ? localizedContent.errorMessages[
            ErrorConstants.ErrorType.PASSWORD_CONFIRMPASSWORD_MISMATCH
          ]
        : '';

    confirmNewPassword.isValid = !confirmNewPassword.error;

    this.setState({
      formData,
    });

    this._isFormValid();
  };

  _isFormValid = () => {
    const { formData } = this.state;

    const isFormValid = _.every(formData, { isValid: true });

    this.setState({
      isFormValid,
    });
  };

  _savePassword = () => {
    const {
      isFormValid,

      formData: { password, newPassword },
    } = this.state;

    if (isFormValid) {
      this.props.actions
        .changeUserPassword(password.value, newPassword.value)
        .then(() => this._savePasswordSuccess())
        .catch(() => this._savePasswordFailed());
    }
  };

  _savePasswordSuccess = () => {
    this.setState({
      formData: {
        password: {
          value: '',
          isValid: false,
          error: '',
        },

        newPassword: {
          value: '',
          isValid: false,
          error: '',
        },

        confirmNewPassword: {
          value: '',
          isValid: false,
          error: '',
        },
      },

      isActionComplete: true,
    });
  };

  _savePasswordFailed = () => {
    this.setState({
      formData: {
        password: {
          value: '',
          isValid: false,
          error: 'Old password is not correct',
        },

        newPassword: {
          value: '',
          isValid: false,
          error: '',
        },

        confirmNewPassword: {
          value: '',
          isValid: false,
          error: '',
        },
      },
    });
  };
}

ChangePassword.propTypes = {
  actions: PropTypes.shape({
    changeUserPassword: PropTypes.func.isRequired,
  }).isRequired,

  editPassword: PropTypes.bool.isRequired,
  toggleEditPassword: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  const { changeUserPassword } = UserInfoActions;

  return {
    actions: bindActionCreators({ changeUserPassword }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ChangePassword);
