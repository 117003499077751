import React from 'react';
import PropTypes from 'prop-types';
import PickerMarkup from './PickerMarkup';

class PickerComponent extends React.Component {
  constructor(props) {
    super(props);
    this._onOptionSelected = this._onOptionSelected.bind(this);
  }

  render() {
    const { values } = this.props;

    const { columns, options, styles, labelOrientation, spacing } = this.props;

    const _styles = {
      mainPickerContainer: {
        display: 'flex',
        flexDirection: 'row',
        ...styles.mainPickerContainer,
      },
      pickerRow: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '10px',
        ...styles.pickerRow,
      },
    };

    const itemsPerColumn = !columns ? 1 : Math.ceil(options.length / columns);

    const rows =
      itemsPerColumn > 0
        ? options.reduce(
            (a, b, i, g) =>
              !(i % itemsPerColumn)
                ? (a.push(g.slice(i, i + itemsPerColumn)), a)
                : a,
            []
          )
        : [options];
    return (
      <div style={_styles.mainPickerContainer}>
        {options.length > 0 &&
          rows.map((row) => (
            <div key={row[0].value} style={_styles.pickerRow}>
              {row.map((option, i) => (
                <PickerMarkup
                  text={option.label}
                  key={i}
                  onAnswered={() => this._onOptionSelected(option.value)}
                  selected={
                    values === undefined
                      ? false
                      : values.indexOf(option.value) > -1
                        ? true
                        : false
                  }
                  spacing={spacing}
                  labelOrientation={labelOrientation}
                  styles={styles}
                  readOnly={this.props.readOnly}
                />
              ))}
            </div>
          ))}
      </div>
    );
  }

  _onOptionSelected(value) {
    const { multiSelect, exclusionOptionValue } = this.props;

    const existingValues = this.props.values;
    let values = [];
    if (multiSelect === true) {
      if (exclusionOptionValue === value) {
        values =
          existingValues.indexOf(value) > -1
            ? existingValues.filter((val) => val !== value)
            : [value];
      } else {
        values =
          existingValues.indexOf(value) > -1
            ? existingValues.filter((val) => val !== value)
            : [
                ...existingValues.filter((val) => val !== exclusionOptionValue),
                value,
              ];
      }
    } else {
      values = [value];
    }

    this.props.onAnswered(values);
  }
}

PickerComponent.propTypes = {
  options: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onAnswered: PropTypes.func.isRequired,
  columns: PropTypes.number,
  labelOrientation: PropTypes.string,
  multiSelect: PropTypes.bool,
  exclusionOptionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  spacing: PropTypes.string,
  styles: PropTypes.object,
  readOnly: PropTypes.bool,
};

PickerComponent.defaultProps = {
  columns: 0,
  labelOrientation: 'out',
  multiSelect: false,
  spacing: 'normal',
  readOnly: false,
};

export default PickerComponent;
