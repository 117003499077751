import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose, pure, withProps } from 'recompose';
import { DrawerNavigation } from '../../../common/drawer2';

const PointAction = ({ completed, onClick, title, status, subtitle }) => (
  <DrawerNavigation
    onClick={onClick}
    title={title}
    status={status}
    incentivesToEarn={!completed}
    subtitle={subtitle}
  />
);

PointAction.propTypes = {
  completed: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  subtitle: PropTypes.string,
};

export const enhance = compose(
  pure,
  withProps(({ pointAction, onPointActionClick }) => {
    const { title, completed } = pointAction;
    const onClick = onPointActionClick
      ? () => onPointActionClick(pointAction)
      : null;

    let subtitle;
    if (pointAction.displayStatus === 'negative') {
      subtitle = `${pointAction.totalPointsEarned.toLocaleString()} Points earned as of ${moment(
        pointAction.dueDate
      ).format('MM/DD/YYYY')}`;
    }

    return {
      completed,
      onClick,
      title,
      status: pointAction.displayStatus,
      subtitle,
    };
  })
);

export default enhance(PointAction);
