import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { DrawerContent, DrawerSection } from '../../../common/drawer2';
import Typography from '../../../common/Typography';

const CategoryDrawerFAQs = ({ faqs, title }) => {
  return (
    <DrawerContent title={title}>
      {_.map(faqs, (questionItem, idx) => {
        const question = _.get(questionItem, 'question', '');

        const answer = _.get(questionItem, 'answer', '');

        return (
          <div key={`drawer_faq_question_${idx}`}>
            <DrawerSection>
              <Typography type="h3Medium">{question}</Typography>
              <Typography type="body">
                {/* eslint-disable react/no-danger */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: AppConfig.replaceS3(answer),
                  }}
                />
                {/* eslint-enable react/no-danger */}
              </Typography>
            </DrawerSection>
          </div>
        );
      })}
    </DrawerContent>
  );
};

CategoryDrawerFAQs.propTypes = {
  faqs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default CategoryDrawerFAQs;
