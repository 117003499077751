import React from 'react';
import Radium from 'radium';
import BiometricScreeningConstants from '@healthmine/greyhound-core/src/constants/BiometricScreening';
import { BiometricReportResult } from '@healthmine/greyhound-core/src/constants/prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const {
  MeasurementRiskLevel,
  MeasurementRiskLevelColor,
} = BiometricScreeningConstants;

const styles = {
  tableColumnContent: {
    padding: '10px 20px',
    fontWeight: 'bold',
    border: '3px solid white',
  },

  descriptionColumnContent: {
    backgroundColor: Colors.lightenColor(Colors.lightGray, 2),
  },

  resultColumnContent: {
    display: 'block',
    margin: '3px',
    border: `2px solid ${Colors.lightGray}`,
    backgroundColor: Colors.white,
  },

  lowRiskColumnContent: {
    backgroundColor: Colors.lightenColor(
      MeasurementRiskLevelColor[MeasurementRiskLevel.LOW],
      3
    ),
  },

  modRiskColumnContent: {
    backgroundColor: Colors.lightenColor(
      MeasurementRiskLevelColor[MeasurementRiskLevel.MODERATE],
      3
    ),
  },

  highRiskColumnContent: {
    backgroundColor: Colors.lightenColor(
      MeasurementRiskLevelColor[MeasurementRiskLevel.HIGH],
      3
    ),
  },

  reportTable: {
    borderBottom: `1px solid ${Colors.lighterGray}`,
  },
};

const BiometricReportRow = ({
  testResult: {
    description,
    riskLevelColor,
    result,
    unit,
    lowRiskRange,
    modRiskRange,
    highRiskRange,
  },
}) => (
  <tr>
    <td style={[styles.tableColumnContent, styles.descriptionColumnContent]}>
      {description}
    </td>
    <td style={[styles.tableColumnContent, styles.resultColumnContent]}>
      <span style={{ color: riskLevelColor }}>
        {result}
        {unit ? ` ${unit}` : ''}
      </span>
    </td>
    <td style={[styles.tableColumnContent, styles.lowRiskColumnContent]}>
      {lowRiskRange}
    </td>
    <td style={[styles.tableColumnContent, styles.modRiskColumnContent]}>
      {modRiskRange}
    </td>
    <td style={[styles.tableColumnContent, styles.highRiskColumnContent]}>
      {highRiskRange}
    </td>
  </tr>
);

BiometricReportRow.propTypes = {
  testResult: BiometricReportResult.isRequired,
};

export default Radium(BiometricReportRow);
