import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { includes } from 'lodash/fp';
import { withStyles, withTheme } from '@material-ui/core';
import { DrawerSection } from '../common/drawer2';
import Typography from '../common/Typography';

const styles = (theme) => ({
  root: {
    display: 'inline-block',
    textAlign: 'center',
  },
  box: {
    cursor: 'pointer',
    width: '100px',
    height: '100px',
    border: `1px solid ${theme.palette.common.blueGray}`,
    borderRadius: '4px',
    display: 'inline-block',
    color: theme.palette.common.blueGray,
    margin: '10px',
    textAlign: 'center',
    position: 'relative',
  },
  boxContent: {
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
  },
  error: {
    color: 'red',
  },
});

const Picker = ({ classes, options, onClick, error }) => (
  <DrawerSection className={classes.root} noPadding>
    {options.map(({ value, label, style }, i) => (
      <div
        key={i}
        className={classes.box}
        style={style}
        onClick={() => onClick(value)}
      >
        <div key={i} className={classes.boxContent}>
          {label}
        </div>
      </div>
    ))}
    {error && (
      <div className={classes.error}>
        <Typography type="h4">{error}</Typography>
      </div>
    )}
  </DrawerSection>
);

Picker.propTypes = {
  classes: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.string.isRequired,
      style: PropTypes.object,
    })
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    ),
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  color: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default compose(
  pure,
  withStyles(styles),
  withTheme(),
  withProps(({ theme, multiple, color, options, value }) => {
    const selectedColor = theme.palette.common[color] || color;
    const isSelected = (x) => (multiple ? includes(x, value) : x === value);

    return {
      options: options.map((x) => ({
        ...x,
        style: isSelected(x.value)
          ? { color: selectedColor, borderColor: selectedColor }
          : null,
      })),
    };
  })
)(Picker);
