import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PlanInformationMarkup from './PlanInformation.markup';

class PlanInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCategory: 0,
    };
  }

  render() {
    const { planInfo } = this.props;

    return (
      <PlanInformationMarkup
        planInfo={planInfo}
        activeCategory={this.state.activeCategory}
        selectCategory={this.selectCategory}
      />
    );
  }

  selectCategory = (idx) => {
    this.setState({
      activeCategory: idx,
    });
  };
}

PlanInformation.propTypes = {
  planInfo: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    planInfo: state.planInfo,
  };
}

export default connect(mapStateToProps, null)(PlanInformation);
