import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import Modal from 'react-modal';
import { compose } from 'recompose';
import { WithGinaAgreement } from '@healthmine/greyhound-core/src/hocs';
import { Assets } from '@healthmine/greyhound-core/src/modules';
import Styles from './GinaModal.styles';

const GinaModal = ({
  isSpouse,
  ginaAcknowledged,
  createMemberGinaAgreement,
}) => (
  <Modal contentLabel="GinaAgreementModal" isOpen style={Styles.modalStyles}>
    <p style={Styles.agreementHeader}>GINA Authorization</p>

    <div style={Styles.agreementScroller}>
      <iframe
        src={Assets.getAssetURL(
          `gina_${isSpouse ? 'spouse' : 'dependent'}_en.html`
        )}
        frameBorder="0"
        style={Styles.agreementFrame}
      />
    </div>

    <div style={Styles.agreementCheckboxContainer}>
      <input
        disabled={ginaAcknowledged}
        type="checkbox"
        className="input-assumpte"
        id="GINA_checkbox"
        onChange={createMemberGinaAgreement}
        checked={ginaAcknowledged}
      />

      <label htmlFor="GINA_checkbox">
        Check here to indicate that you have read and agree to the terms of the
        GINA Authorization.
      </label>
    </div>
  </Modal>
);

GinaModal.propTypes = {
  isSpouse: PropTypes.bool.isRequired,
  ginaAcknowledged: PropTypes.bool.isRequired,
  createMemberGinaAgreement: PropTypes.func.isRequired,
};

export default compose(Radium, WithGinaAgreement)(GinaModal);
