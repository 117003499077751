import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import Typography from '../../../common/Typography';
import { ListItem, ListItemStyles } from '../../../common/list2/List';
import { withStyles } from '@material-ui/core/styles';
import replace from 'lodash/replace';

const styles = () => ({
  ...ListItemStyles,
  itemTitle: {
    marginBottom: '2px',
    fontSize: '16px',
  },
});

const ScreeningProcedureItem = ({ classes, display, dateOfService }) => {
  return (
    <ListItem>
      <Typography type="h3Medium" inline className={classes.itemTitle}>
        {replace(display, /^"|"$/g, '')}
      </Typography>
      <Typography type="h4" color="blueGray">
        completed on {dateOfService}
      </Typography>
    </ListItem>
  );
};

ScreeningProcedureItem.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
  dateOfService: PropTypes.string.isRequired,
};

export default compose(pure, withStyles(styles))(ScreeningProcedureItem);
