import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';

const PrimaryMenusMarkup = ({ children }) => {
  const styles = {
    root: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'row',
    },
  };

  return <div style={styles.root}>{children}</div>;
};

PrimaryMenusMarkup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Radium(PrimaryMenusMarkup);
