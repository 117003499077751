import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { compose, pure, withHandlers } from 'recompose';
import { withRouter } from 'react-router';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px 10px 30px',
    borderRadius: '4px',
    marginBottom: '1px',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },

  message: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '22px',
    paddingRight: '10px',
  },

  rightContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  boxedMessage: {
    border: '1px solid #CBD6E1',
    backgroundColor: '#fff',
    height: '50px',
    width: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    textTransform: 'uppercase',
    color: Colors.bodyText,
    textAlign: 'center',
    fontWeight: 'bold',
  },

  goTo: {
    fontSize: '32px',
  },
};

const AgendaItem = ({ message, isPastDue, selectAgendaItem }) => {
  const backgroundColor = isPastDue ? Colors.newRed : 'transparent';

  const color = isPastDue ? Colors.white : Colors.bodyText;
  return (
    <a
      onClick={selectAgendaItem}
      style={[styles.root, { backgroundColor, color }]}
    >
      <div style={styles.message}>{message}</div>
      <div style={styles.rightContent}>
        {isPastDue && (
          <div style={styles.boxedMessage}>
            <div>Past</div>
            <div style={{ fontSize: '18px' }}>Due</div>
          </div>
        )}
        <i style={styles.goTo} className="material-icons">
          chevron_right
        </i>
      </div>
    </a>
  );
};

AgendaItem.propTypes = {
  message: PropTypes.string,
  isPastDue: PropTypes.bool,
  selectAgendaItem: PropTypes.func,
};

export default compose(
  pure,
  withRouter,
  withHandlers({
    selectAgendaItem: ({ router, route }) => () => {
      if (
        router.isActive('/care-plan/preventive') ||
        router.isActive('/care-plan/coaching')
      ) {
        return router.replace(route);
      }
      return router.push(route);
    },
  }),
  Radium
)(AgendaItem);
