import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  Button,
  MobileStepper,
  withStyles,
  withTheme,
} from '@material-ui/core';
import Typography from '../common/Typography';
import {
  DrawerSection,
  DrawerHeaderAction,
  withDrawer,
} from '../common/drawer2';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  hero: {
    position: 'relative',
    color: theme.palette.common.white,
    paddingTop: '130px',
    paddingLeft: '70px',
    paddingRight: '170px',
    height: '350px',
    backgroundSize: 'cover',
  },
  heroTitle: {
    marginBottom: '10px',
  },
  heroDescription: {
    whiteSpace: 'pre-line',
  },
  optionalAction: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '20px',
  },
  stepper: {
    backgroundColor: theme.palette.common.white,
    position: 'unset',
  },
});

const DrawerContent = ({
  classes,
  theme,
  drawer,
  color,
  children,
  pageIndex,
  pageCount,
  onBack,
  submitLabel,
  disableSubmit,
  onSubmit,
  hero,
  onSkip,
  hideStepper,
  isOptional,
}) => (
  <div className={classes.root}>
    {hero && (
      <DrawerSection
        noBorder
        noPadding
        classes={{ root: classes.hero }}
        style={{
          backgroundColor: theme.palette.common[color],
          backgroundImage: hero.background
            ? `url(${require(`../../../images/background/${
                hero.background
              }.png`)})`
            : undefined,
        }}
      >
        <Typography type="h1" className={classes.heroTitle}>
          {hero.title}
        </Typography>
        <Typography type="h4Medium" className={classes.heroDescription}>
          {hero.description}
        </Typography>
        {isOptional && (
          <div className={classes.optionalAction}>
            <DrawerHeaderAction
              label="Skip"
              color="white"
              onClick={() => drawer.close()}
            />
          </div>
        )}
      </DrawerSection>
    )}
    <DrawerSection grow noBorder noPadding>
      {children}
    </DrawerSection>
    {!hideStepper && (
      <DrawerSection noBorder noPadding>
        <MobileStepper
          steps={pageCount}
          activeStep={pageIndex}
          classes={{
            root: classes.stepper,
          }}
          nextButton={
            <div>
              {onSkip && <Button onClick={() => onSkip()}>Skip</Button>}
              <Button onClick={() => onSubmit()} disabled={disableSubmit}>
                {submitLabel}
              </Button>
            </div>
          }
          backButton={
            <Button onClick={() => onBack()} disabled={pageIndex <= 0}>
              Back
            </Button>
          }
        />
      </DrawerSection>
    )}
  </div>
);

DrawerContent.defaultProps = {
  submitLabel: 'Next',
};

DrawerContent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  disableSubmit: PropTypes.bool,
  hideStepper: PropTypes.bool,
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  hero: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    background: PropTypes.string,
  }),
  isOptional: PropTypes.bool.isRequired,
};

export default compose(withDrawer, withStyles(styles), withTheme())(
  DrawerContent
);
