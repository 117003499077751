import React from 'react';
import PropTypes from 'prop-types';
import List, {
  ListHeader,
  ListContent,
  ListItem,
  ListFooter,
} from '../../common/list2/List';
import MyNotes from './MyNotes';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import _isNull from 'lodash/isNull';
import ChecklistItem from './ChecklistItem';
import Medication from './Medication';
import { withDoctorChecklist } from '@healthmine/greyhound-core/src/hocs';

class Medications extends React.Component {
  state = {
    addingNew: false,
    medEditIdx: -1,
  };

  render() {
    const {
      listColor,
      prescriptions,
      mySelfReportedMedications,
      updatePrescriptionFeedback,
    } = this.props;
    const { addingNew, medEditIdx } = this.state;
    const medications = _isNull(mySelfReportedMedications)
      ? []
      : mySelfReportedMedications;
    const selfReportedMedList = addingNew
      ? [...medications, { rxName: '', currentlyTaking: null }]
      : medications;
    return (
      <List disabled={!prescriptions.length}>
        <ListHeader title="Medications & Supplements" color={listColor} />
        <ListContent
          isEmpty={
            prescriptions.length === 0 &&
            selfReportedMedList.length === 0 &&
            !_isNull(medications)
          }
          emptyMessage="Click 'Add New' below to add a medication."
        >
          {_map(
            prescriptions,
            ({ id, name, drugStrength, currentlyTaking }) => {
              const medKey = `prescription_${id}`;
              return (
                <ListItem key={medKey}>
                  <ChecklistItem>
                    <Medication
                      id={id}
                      name={name}
                      drugStrength={drugStrength}
                      editing={false}
                      currentlyTaking={currentlyTaking}
                      medKey={medKey}
                      updatePrescriptionFeedback={updatePrescriptionFeedback}
                    />
                  </ChecklistItem>
                  <MyNotes />
                </ListItem>
              );
            }
          )}
          {_map(selfReportedMedList, ({ id, rxName, currentlyTaking }, idx) => {
            const medKey = `self_added_med_${id}`;
            return (
              <ListItem key={medKey}>
                <ChecklistItem>
                  <Medication
                    name={rxName}
                    id={id}
                    selfAdded
                    currentlyTaking={currentlyTaking}
                    deleteMed={
                      addingNew ? this.cancelAdd : this.deleteSelfReportedMed
                    }
                    editMed={
                      !addingNew && medEditIdx === -1
                        ? this.editSelfReportedMed
                        : undefined
                    }
                    saveMed={
                      addingNew
                        ? this.saveNewSelfReportedMed
                        : this.saveSelfReportedMed
                    }
                    editing={idx === medEditIdx}
                    medKey={medKey}
                    hideEdit={medEditIdx !== -1}
                  />
                </ChecklistItem>
                <MyNotes />
              </ListItem>
            );
          })}
        </ListContent>
        <ListFooter onClick={this.addNew} actionText="Add New" />
      </List>
    );
  }

  addNew = () => {
    this.setState({
      addingNew: true,
      medEditIdx: this.props.mySelfReportedMedications.length,
    });
  };

  cancelAdd = () => {
    this.setState({
      addingNew: false,
      medEditIdx: -1,
    });
  };

  deleteSelfReportedMed = (id) => {
    this.props.deleteSelfReportedMedication(id);
    this.setState({
      medEditIdx: -1,
    });
  };

  editSelfReportedMed = (id) => {
    this.setState({
      medEditIdx: _findIndex(this.props.mySelfReportedMedications, { id }),
    });
  };

  saveNewSelfReportedMed = (id, rxName) => {
    if (rxName === '') {
      this.cancelAdd();
    } else {
      this.props.createSelfReportedMedication({
        rxName,
        currentlyTaking: true,
      });
    }

    this.setState({
      addingNew: false,
      medEditIdx: -1,
    });
  };

  saveSelfReportedMed = (id, rxName, currentlyTaking) => {
    const selfReportedMedIdx = _findIndex(
      this.props.mySelfReportedMedications,
      { id }
    );
    const selfReportedMed = this.props.mySelfReportedMedications[
      selfReportedMedIdx
    ];
    if (selfReportedMedIdx !== this.state.medEditIdx) {
      this.props.updateSelfReportedMedication({
        ...selfReportedMed,
        currentlyTaking,
      });
    } else if (
      selfReportedMed &&
      rxName !== '' &&
      rxName !== selfReportedMed.rxName
    ) {
      this.props.updateSelfReportedMedication({
        ...selfReportedMed,
        rxName,
        currentlyTaking,
      });
    }

    this.setState({
      medEditIdx: -1,
    });
  };
}

Medications.propTypes = {
  listColor: PropTypes.string.isRequired,
  prescriptions: PropTypes.array.isRequired,
  mySelfReportedMedications: PropTypes.array,
  createSelfReportedMedication: PropTypes.func.isRequired,
  updateSelfReportedMedication: PropTypes.func.isRequired,
  deleteSelfReportedMedication: PropTypes.func.isRequired,
  updatePrescriptionFeedback: PropTypes.func.isRequired,
};

export default withDoctorChecklist(Medications);
