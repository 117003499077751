import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Button from '../common/Button';

const CarePlansBarMarkup = ({ carePlan, onCreateUserPlan }) => {
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      color: Colors.bodyText,
      fontWeight: '200',
      fontSize: '16px',
      maxHeight: '121px',
    },

    identityContainer: {
      flex: 5,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: '50px',
      margin: '20px 0px',
    },

    iconBubble: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '76px',
      minWidth: '76px',
      height: '76px',
      minHeight: '76px',
      borderRadius: '50%',
      border: `2px solid ${Colors.lightGreen}`,
      backgroundColor: 'transparent',
    },

    icon: {
      fontSize: '40px',
      fontWeight: '100',
      marginRight: '-3px',
      color: Colors.lightGreen,
    },

    title: {
      marginLeft: '15px',
      fontWeight: '400',
    },

    description: {
      flex: 4,
      color: Colors.contentText,
    },

    configureButtonContainer: {
      flex: 3,
      textAlign: 'right',
    },

    configureButton: {
      backgroundColor: Colors.white,
      color: Colors.highlightBlue,
      borderRadius: '3px',
      borderColor: Colors.highlightBlue,
      padding: '10px 15px',
      fontSize: '16px',
      marginRight: '20px',
      marginBottom: '0px',

      ':hover': {
        backgroundColor: Colors.highlightBlue,
        color: Colors.light,
      },

      ':active': {
        backgroundColor: Colors.darkenColor(Colors.highlightBlue),
        color: Colors.light,
      },
    },
  };

  return (
    <div style={styles.root}>
      <div style={styles.identityContainer}>
        <span style={styles.iconBubble}>
          <i className={carePlan.iconClass} style={styles.icon} />
        </span>

        <span style={styles.title}>{carePlan.name}</span>
      </div>

      <div style={styles.description}>{carePlan.description}</div>

      <div style={styles.configureButtonContainer}>
        <Button
          text="Configure Now"
          style={styles.configureButton}
          onClick={() => onCreateUserPlan(carePlan)}
        />
      </div>
    </div>
  );
};

CarePlansBarMarkup.propTypes = {
  carePlan: PropTypes.object.isRequired,
  onCreateUserPlan: PropTypes.func.isRequired,
};

export default Radium(CarePlansBarMarkup);
