const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleOuterWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    fontSize: '30px',
    marginRight: '10px',
    color: theme.palette.common.grannySmith,
  },
  failedBox: {
    fontSize: '30px',
    marginRight: '10px',
    color: theme.palette.common.cosmopolitan,
  },
  unchecked: {
    height: '30px',
    width: '30px',
    minWidth: '30px',
    marginRight: '10px',
    border: theme.globalStyles.dashedBorder,
  },
  title: {
    fontSize: '20px',
  },
  status: {
    marginTop: '5px',
    textTransform: 'lowercase',
  },
  incentiveConditionsWrapper: {
    textAlign: 'right',
  },
  incentive: {
    textTransform: 'lowercase',
  },
  incentiveToBeEarned: {
    color: theme.palette.common.whiskeySour,
  },
  reason: {
    color: theme.palette.secondary.main,
    marginTop: '5px',
  },
  description: {
    width: '400px',
    flexGrow: '1',
    paddingRight: '20px',
    paddingLeft: '20px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  complete: {
    color: theme.palette.common.grannySmith,
  },
  notComplete: {
    color: theme.palette.common.cosmopolitan,
  },
  grayedOut: {
    color: theme.palette.secondary.main,
  },
  trackerValue: {
    color: theme.palette.common.mintJulep,
    fontSize: '20px',
    fontWeight: '500',
  },
  goalTitle: {
    marginTop: '5px',
    color: theme.palette.secondary.main,
  },
  viewMore: {
    color: theme.palette.secondary.main,
    fontSize: '20px',
    alignSelf: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
  emphasis: {
    fontWeight: '500',
  },
});

export default styles;
