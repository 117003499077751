import moment from 'moment';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  AppConstants,
  RewardsConstants,
} from '@healthmine/greyhound-core/src/constants';
import { Colors, AppConfig } from '@healthmine/greyhound-core/src/modules';
import PageLinks from '../../common/PageLinks';
import SweepstakeCard from './SweepstakeCard';
import RewardCard from './RewardCard';
import CurrentReward from './CurrentReward';

const ShopMarkup = ({
  products,
  sortLowToHigh,
  currPage,
  pages,
  incentiveType,
  toggleSort,
  toggleProduct,
  filterProduct,
  availablePoints,
  filterBy,
}) => {
  const styles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '75px',
      paddingRight: '75px',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.mediumGray2,
      paddingBottom: '10px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },

    headerLabel: {
      color: Colors.navyBlue,
      fontSize: '16px',
      fontWeight: 600,
      flex: 7.8,
    },

    filterDropdownContainer: {
      width: '115px',
      fontSize: '16px',
    },

    filterContent: {
      display: 'flex',
      alignItems: 'center',
      flex: 3.2,
    },

    sortLabel: {
      paddingRight: '15px',
      fontWeight: 600,
      color: Colors.mediumGray2,
    },

    select: {
      display: 'inline-block',
      minWidth: '80px',
      fontSize: '16px',
      height: '34px',
      color: Colors.lightGray3,
      borderRadius: '5px',
      paddingLeft: '5px',
    },

    productRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    modifiedRow: {
      display: 'flex',
    },

    description: {
      color: Colors.contentText,
      fontSize: '16px',
      maxWidth: '420px',
      lineHeight: '18px',
    },

    rewardsTitleText: {
      margin: 0,
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.mediumGray2,
    },

    rewardsBalace: {
      fontSize: '20px',
      fontWeight: '600',
      margin: '18px 0 8px',
      color: '#244d68',
    },

    pageLinksWrapper: {
      padding: '50px 0',
    },

    pageDescription: {
      display: 'flex',
      paddingTop: '50px',
      paddingLeft: '75px',
      paddingRight: '75px',
    },

    automatedRewards: {
      flex: 2.8,
    },

    balance: {
      flex: 7.2,
      display: 'flex',
      flexDirection: 'column',
    },

    noContent: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '20px',
      fontWeight: 300,
      paddingTop: '40px',
      paddingLeft: '75px',
      paddingRight: '75px',
    },

    pageDescriptionContent: {
      paddingLeft: '75px',
      paddingTop: '40px',
    },

    descriptionLabel: {
      color: Colors.bodyText,
      fontWeight: '400',
      fontSize: '16px',
    },

    descriptionText: {
      color: Colors.contentText,
      fontSize: '16px',
    },

    descriptionList: {
      color: Colors.contentText,
      fontSize: '16px',
      paddingLeft: '16px',
    },

    descriptionListItem: {
      padding: '3px 75px 3px 0',
    },
  };

  const { setCurrencyLabel } = RewardsConstants;

  const { lingo } = AppConfig.effectiveConfig;

  const currentDate = moment();

  const currentYear = currentDate.year();

  const sweepstakesHeader = (
    <div style={styles.pageDescriptionContent}>
      <label style={styles.descriptionLabel}>How to Earn Points:</label>
      <p style={styles.descriptionText}>
        In three simple steps you can earn and spend points for a chance to win
        awesome prizes!
      </p>
      <ul style={styles.descriptionList}>
        <li style={styles.descriptionListItem}>
          Check out your care plans located on your homepage—every care plan
          contains an important set of actions.
        </li>
        <li style={styles.descriptionListItem}>
          Earn points for completing each one, plus other actions like logging
          in every day.
        </li>
        <li style={styles.descriptionListItem}>
          Spend your points on any sweepstakes entries you like for a chance to
          win! Remember: you are entering for a chance to win, the odds of
          winning depend on the number of entries.
        </li>
      </ul>
    </div>
  );

  const RewardsHeader = (
    <div>
      <div style={styles.pageDescription}>
        <div style={styles.balance}>
          <span style={styles.rewardsTitleText}>Reward Dollars to Spend:</span>
          <span style={styles.rewardsBalace}>
            {setCurrencyLabel(availablePoints)}
          </span>
          <div style={styles.description}>
            {`For your ${currentYear} Annual Wellness Visit, you’ll earn $50. (It’s worth $25, if you've already earned $75 towards your $100 annual maximum).`}
            <br />
            {`All other ${
              lingo.healthAction
            }s will earn you a $25 gift card, up to the maximum $100 annual amount.`}{' '}
            {currentDate.quarter() === 1 &&
              `(Note: if you have unredeemed awards earned in ${currentYear -
                1}, you have until
              March 31, ${currentYear} to redeem those.)`}
          </div>
        </div>
        <div style={styles.automatedRewards}>
          <CurrentReward />
        </div>
      </div>
    </div>
  );

  const sweepstakesFilter = (
    <div style={styles.filterContent}>
      <span style={styles.sortLabel}>Sort By:</span>
      <select
        style={styles.select}
        value={sortLowToHigh ? 'lowToHigh' : 'highToLow'}
        onChange={toggleSort}
      >
        <option value="lowToHigh">Points Low to High</option>
        <option value="highToLow">Points High to Low</option>
      </select>
    </div>
  );

  const rewardsFilter = (
    <div style={[styles.filterContent, { flex: 2.8 }]}>
      <span style={styles.sortLabel}>Filter By:</span>

      <div style={styles.filterDropdownContainer}>
        <Select
          searchable={false}
          value={filterBy || 'all'}
          options={[
            { label: 'All', value: 'all' },
            {
              label: 'Email',
              value: RewardsConstants.RewardDeliveryForm.DIGITAL,
            },
            {
              label: 'Standard Mail',
              value: RewardsConstants.RewardDeliveryForm.PHYSICAL,
            },
          ]}
          onChange={(selectedFilter) => filterProduct(selectedFilter.value)}
        />
      </div>
    </div>
  );

  const shopHeaderUI =
    incentiveType === AppConstants.IncentiveType.REWARDS
      ? RewardsHeader
      : sweepstakesHeader;

  const filterUI =
    incentiveType === AppConstants.IncentiveType.REWARDS
      ? rewardsFilter
      : sweepstakesFilter;

  return (
    <div>
      {shopHeaderUI}
      {products && products.length ? (
        <div style={styles.content}>
          <div style={styles.header}>
            <label style={styles.headerLabel}>Gift Cards</label>
            {filterUI}
          </div>

          <div style={styles.productContainer}>
            {products.map((row, rowIndex) => (
              <div
                key={rowIndex}
                style={row.length < 3 ? styles.modifiedRow : styles.productRow}
              >
                {row.map((product, index) => {
                  return (
                    <div
                      key={`${rowIndex}-${index}`}
                      style={{ flexBasis: row.length < 3 ? '38.5%' : 'auto' }}
                    >
                      {incentiveType === AppConstants.IncentiveType.REWARDS ? (
                        <RewardCard
                          reward={product}
                          toggleProduct={toggleProduct}
                        />
                      ) : (
                        <SweepstakeCard
                          sweepstake={product}
                          toggleProduct={toggleProduct}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            ))}

            <div style={styles.pageLinksWrapper}>
              <PageLinks
                linkBase={`/${
                  incentiveType === AppConstants.IncentiveType.REWARDS
                    ? 'rewards'
                    : 'sweepstakes'
                }/shop/`}
                currPage={currPage}
                pages={pages}
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={styles.noContent}>
          No{' '}
          {incentiveType === AppConstants.IncentiveType.REWARDS
            ? 'rewards'
            : 'open sweepstakes'}{' '}
          at this time. Check back later!
        </div>
      )}
    </div>
  );
};

ShopMarkup.propTypes = {
  products: PropTypes.array.isRequired,
  sortLowToHigh: PropTypes.bool.isRequired,
  currPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  incentiveType: PropTypes.string,
  toggleSort: PropTypes.func.isRequired,
  filterProduct: PropTypes.func.isRequired,
  toggleProduct: PropTypes.func.isRequired,
  availablePoints: PropTypes.number,
  filterBy: PropTypes.string,
};

export default Radium(ShopMarkup);
