import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Select from 'react-select';

const DropdownMarkup = ({
  placeholder,
  options,
  value,
  onAnswered,
  styles,
  disabled,
  useDefaultSelect,
}) => {
  const container = {
    position: 'relative',
    width: '100%',
    ...styles,
  };

  const select = {
    height: '35px',
    width: '100%',
    fontSize: '16px',
    borderRadius: '5px',
    paddingLeft: '10px',
    marginBottom: '20px',
  };

  return (
    <div style={container}>
      <div style={styles}>
        {useDefaultSelect ? (
          <select
            value={value}
            style={select}
            onChange={onAnswered}
            disabled={disabled}
          >
            {options.map((option, optionIndex) => (
              <option
                key={optionIndex}
                value={option.value}
                disabled={disabled}
              >
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <Select
            placeholder={placeholder}
            value={value}
            options={options}
            onChange={onAnswered}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

DropdownMarkup.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAnswered: PropTypes.func.isRequired,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  useDefaultSelect: PropTypes.bool,
};

DropdownMarkup.defaultProps = {
  placeholder: 'Select...',
  disabled: false,
  styles: {},
  useDefaultSelect: false,
};

export default Radium(DropdownMarkup);
