import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { DrawerContent } from '../../common/drawer2';

const styles = () => ({
  frame: {
    flexGrow: 1,
    width: '100%',
    border: 'none',
  },
});

const EducationDrawerContent = ({ classes, education }) => (
  <DrawerContent title={education.title}>
    <iframe
      className={classes.frame}
      src={education.url}
      allowFullScreen="true"
    />
  </DrawerContent>
);

EducationDrawerContent.propTypes = {
  classes: PropTypes.object.isRequired,
  education: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(pure, withStyles(styles))(EducationDrawerContent);
