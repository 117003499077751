import React from 'react';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';

const EvaluatingResults = () => (
  <DrawerContent title="Biometric Screening">
    <DrawerSectionHeader title="Evaluating results" />
    <DrawerSection>
      <Typography>
        Doctors are currently evaulating your data. We'll let you know when your
        results are ready.
      </Typography>
    </DrawerSection>
  </DrawerContent>
);

export default EvaluatingResults;
