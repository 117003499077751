import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import Item from '../common/Item';
import Spinner from '../common/Spinner';

const Title = ({
  iconClass,
  text,
  backColor,
  onClick,
  loading,
  colorful,
  linked,
  unlinked,
}) => {
  const title = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '415px',
  };

  const icon = {
    padding: '20px',
    color: Colors.light,
    backgroundColor: backColor,
  };

  const disabledIcon = {
    padding: '18px',
    color: Colors.contentText,
    border: `2px solid ${Colors.contentText}`,
  };

  const label = {
    fontSize: '24px',
    textAlign: 'center',
    margin: '0px',
  };
  return (
    <StyleRoot style={title} onClick={onClick}>
      {loading ? (
        <Spinner style={icon} />
      ) : (
        <Item
          iconStyle={colorful ? icon : disabledIcon}
          iconClass={iconClass}
          loading={loading}
          linked={linked}
          unlinked={unlinked}
          keep
        />
      )}
      <h1 style={[label, GlobalStyles.extraLight]}>{text}</h1>
    </StyleRoot>
  );
};

Title.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string.isRequired,
  backColor: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  colorful: PropTypes.bool,
  linked: PropTypes.bool,
  unlinked: PropTypes.bool,
};

export default Radium(Title);
