import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  root: {
    maxWidth: '800px',
  },
  header: {
    padding: '20px 0px',
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
  },
  toggleLink: {
    cursor: 'pointer',
    userSelect: 'none',
    marginLeft: '.25rem',
  },
  selectedToggleLink: {
    color: Colors.linkBlue,
  },
  cardItemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
};
