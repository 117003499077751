import React from 'react';
import { routerActions } from 'react-router-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import { createSelector } from 'reselect';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';

const signInSelector = (state) => state.signIn;

const onboardingSelector = (state) => state.onBoarding;

const getAuthInfo = createSelector(
  signInSelector,
  onboardingSelector,
  (signIn, onboarding) => ({
    impersonationEmail: signIn.impersonationEmail,
    onboardingComplete: !!onboarding.completed,
  })
);

const userIsOnboard = connectedReduxRedirect({
  authenticatedSelector: (state) =>
    getAuthInfo(state) &&
    (AppConfig.validateFeature(Features.NO_ONBOARDING) ||
      !!getAuthInfo(state).impersonationEmail ||
      getAuthInfo(state).onboardingComplete),
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsOnboard',
  redirectPath: '/introduction',
  allowRedirectBack: false,
});

export default userIsOnboard((props) => React.cloneElement(props.children));
