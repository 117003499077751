import { types } from '../actions/TutorialActions';

function tutorial(
  state = {
    show: false,
    page: 'intro',
  },
  action
) {
  switch (action.type) {
    case types.SHOW_TUTORIAL: {
      return {
        ...state,
        show: true,
        page: 'intro',
      };
    }

    case types.HIDE_TUTORIAL: {
      return {
        ...state,
        show: false,
        page: 'intro',
      };
    }

    case types.SET_TUTORIAL_TYPE: {
      return {
        ...state,
        show: true,
        page: action.page,
      };
    }

    default:
      return state;
  }
}

export default {
  tutorial,
};
