import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const MapMarker = ({
  item,
  activeLat,
  activeLng,
  onHover,
  onLeave,
  onClick,
}) => {
  const isActive = item.latitude === activeLat && item.longitude === activeLng;

  const styles = {
    mapMarker: {
      width: isActive ? '50px' : '40px',
      height: isActive ? '50px' : '40px',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer',
    },
    mapImage: {
      alignSelf: 'center',
      width: isActive ? '50px' : '40px',
      height: isActive ? '50px' : '40px',
    },
  };

  return (
    <div
      style={styles.mapMarker}
      onMouseOver={onHover}
      onMouseLeave={onLeave}
      onClick={(e) => onClick(e, item)}
    >
      <img
        src={require('../../../images/map-marker.png')}
        style={styles.mapImage}
        role="presentation"
      />
    </div>
  );
};

MapMarker.propTypes = {
  item: PropTypes.object.isRequired,
  activeLat: PropTypes.number,
  activeLng: PropTypes.number,
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
  onClick: PropTypes.func,
};

export default Radium(MapMarker);
