import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DrawerSection from './DrawerSection';
import Navigational from '../icons/Navigational';
import StatusTitle from '../StatusTitle';
import Title from '../Title';

const DrawerNavigation = ({
  children,
  title,
  subtitle,
  onClick,
  status,
  iconClass,
  titleClass,
  subtitleClass,
  navigationalIcon,
  incentivesToEarn,
}) => (
  <DrawerSection onClick={onClick}>
    <Grid container spacing={0} alignItems="center">
      <Grid item xs={11}>
        {status ? (
          <StatusTitle
            title={title}
            subtitle={subtitle}
            status={status}
            iconClass={iconClass}
            titleClass={titleClass}
            subtitleClass={subtitleClass}
            incentivesToEarn={incentivesToEarn}
          />
        ) : (
          <Title
            title={title}
            subtitle={subtitle}
            iconClass={iconClass}
            titleClass={titleClass}
            subtitleClass={subtitleClass}
          />
        )}
        {children}
      </Grid>
      {onClick && (
        <Grid item xs={1}>
          <Navigational iconClass={navigationalIcon} />
        </Grid>
      )}
    </Grid>
  </DrawerSection>
);

DrawerNavigation.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  iconClass: PropTypes.string,
  titleClass: PropTypes.string,
  subtitleClass: PropTypes.string,
  status: PropTypes.oneOf(['positive', 'neutral', 'negative']),
  navigationalIcon: PropTypes.string.isRequired,
  incentivesToEarn: PropTypes.bool,
};

DrawerNavigation.defaultProps = {
  navigationalIcon: 'icon-Right-Chevron-Circle',
};

export default DrawerNavigation;
