import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { withRisksOrConditionsForm } from '@healthmine/greyhound-core/src/hocs';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import DrawerContent from '../DrawerContent';
import Picker from '../Picker';
import { DrawerSectionHeader } from '../../common/drawer2';

const RisksOrConditionsDrawer = ({
  color,
  medConditionsField,
  submit,
  skip,
  disableSubmit,
  ...props
}) => (
  <DrawerContent
    {...props}
    color={color}
    submitLabel={
      AppConfig.validateFeature(Features.FTE_COMMUNICATION_DEMO)
        ? 'Keep Going'
        : 'Almost Done'
    }
    onSkip={skip}
    onSubmit={submit}
    disableSubmit={disableSubmit}
  >
    <DrawerSectionHeader title="Check all that apply" />
    <Picker
      multiple
      color={color}
      value={medConditionsField.value}
      error={medConditionsField.valid ? null : 'Selection is required'}
      options={medConditionsField.options}
      onClick={medConditionsField.update}
    />
  </DrawerContent>
);

RisksOrConditionsDrawer.propTypes = {
  color: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  medConditionsField: PropTypes.object.isRequired,
  skip: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
};

export default compose(
  pure,
  withRisksOrConditionsForm(({ onNext }) => onNext),
  withProps(({ title, description }) => ({
    color: 'venus',
    hero: { title, description, background: 'risks_or_conditions' },
  }))
)(RisksOrConditionsDrawer);
