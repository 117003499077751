import moment from 'moment';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { MessageCenterConstants } from '@healthmine/greyhound-core/src/constants';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import MessageModal from '../common/MessageModal';

const MessagesMenuMarkup = ({
  displayedMessages,
  viewedMessage,
  onViewMessage,
  onViewedMessageClosed,
}) => {
  const styles = {
    menuHeader: {
      margin: '0px 15px',
      paddingLeft: '10px',
      paddingBottom: '20px',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.bodyText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      paddingTop: '15px',
    },

    messageContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      paddingTop: '12px',
      paddingLeft: '15px',
      paddingRight: '15px',
      cursor: 'pointer',

      ':hover': {
        backgroundColor: Colors.gutterGray,
        marginTop: '-1px',
        paddingTop: '13px',
      },
    },

    messageContent: {
      flex: 1,
      paddingLeft: '20px',
      paddingBottom: '15px',
      overflow: 'hidden',
      fontWeight: '300',
      lineHeight: '12px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },

    unreadIndicator: {
      backgroundColor: Colors.lightGreen,
      width: '12px',
      minWidth: '12px',
      height: '12px',
      minHeight: '12px',
      borderRadius: '50%',
      marginRight: '-12px',
    },

    subject: {
      fontSize: '16px',
      color: Colors.bodyText,
      paddingBottom: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    receivedDate: {
      marginTop: '5px',
      fontSize: '16px',
      color: Colors.contentText,
    },

    messageCenterLink: {
      display: 'block',
      padding: '20px 0 10px',
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      color: Colors.highlightBlue,
      cursor: 'pointer',
      textDecoration: 'none',
    },

    noMessageLabel: {
      fontSize: '16px',
      padding: '15px 0px',
      color: Colors.contentText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      textAlign: 'center',
    },
  };

  return (
    <div>
      {viewedMessage ? (
        <MessageModal
          message={viewedMessage}
          onClosed={onViewedMessageClosed}
        />
      ) : null}

      <div style={styles.menuHeader}>Latest Messages</div>

      {displayedMessages.length > 0 ? (
        displayedMessages.map((message, messageIndex) => (
          <div
            key={`Message${messageIndex}`}
            style={styles.messageContainer}
            onClick={() => onViewMessage(message)}
          >
            {message.status === MessageCenterConstants.MessageStatus.UNREAD ? (
              <div style={styles.unreadIndicator} />
            ) : null}

            <div style={styles.messageContent}>
              <div style={styles.subject}>{message.subject}</div>

              <div style={styles.receivedDate}>
                {moment().diff(moment(message.sentTime), 'd') <= 1
                  ? moment(message.sentTime).fromNow()
                  : moment(message.sentTime).format('MM/DD/YYYY')}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div style={styles.noMessageLabel}>No Messages</div>
      )}

      <Link to="/messages" style={styles.messageCenterLink}>
        Message Center
      </Link>
    </div>
  );
};

MessagesMenuMarkup.propTypes = {
  displayedMessages: PropTypes.array.isRequired,
  viewedMessage: PropTypes.object,
  onViewMessage: PropTypes.func.isRequired,
  onViewedMessageClosed: PropTypes.func.isRequired,
};

export default Radium(MessagesMenuMarkup);
