import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import TrackingInput from '../../care-plan-tracking/common/TrackingInput';
import WeeklySummary from '../../care-plan-tracking/common/WeeklySummary';
import TrackingGoal from '../../care-plan-tracking/common/TrackingGoal';
import Measurements from '@healthmine/greyhound-core/src/modules/Measurements';
import HistoryToggle from '../../data/MeasurementBody';
import { Flex } from 'reflexbox';

const ModuleTrackingMarkup = ({
  index,
  history,
  toggleHistory,
  isHeader,
  isModal,
  tracking,
  todaysValue,
}) => {
  const moduleName = tracking.text;

  const measurement = Measurements.MeasurementTypes[tracking.type];

  const styles = {
    container: {
      flex: `0 0 ${isModal || isHeader ? '300px' : '300px'}`,
      boxSizing: 'border-box',
      minWidth: `${isModal || isHeader ? '300px' : '300px'}`,
      height: '250px',
      maxHeight: '250px',
      borderBottom:
        isModal || isHeader || history
          ? 'none'
          : `1px solid ${Colors.gutterGray}`,
      borderRight:
        isModal || isHeader ? 'none' : `1px solid ${Colors.gutterGray}`,
      position: 'relative',
      marginBottom: history ? '356px' : 0,
    },
    content: {
      padding: '10px',
      height: '100%',
      boxSizing: 'border-box',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: isHeader ? '35px' : 0,
    },
    title: {
      fontSize: '16px',
      margin: '10px 0 20px',
      textAlign: 'center',
    },
    measureLabel: {
      marginLeft: '15px',
      minWidth: '100px',
    },
    primaryText: {
      fontSize: '30px',
      margin: '0 0 20px',
    },
    connectedIcon: {
      marginRight: '10px',
      fontSize: '18px',
      color: Colors.contentText,
    },
    dateLabel: {
      color: Colors.contentText,
      fontSize: '16px',
      fontWeight: '300',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
    },
    small: {
      fontSize: '16px',
    },
  };

  return (
    <Flex column style={styles.container} align="center">
      <Flex style={styles.content}>
        {!isHeader && <div style={styles.title}>{moduleName}</div>}
        {todaysValue ? (
          <div
            style={[
              styles.primaryText,
              todaysValue ? {} : { color: Colors.contentText },
            ]}
          >
            {todaysValue || 0}{' '}
            <span style={styles.small}>{measurement.unit}</span>
            <div style={styles.dateLabel}>
              <div>Today</div>
            </div>
          </div>
        ) : (
          <TrackingInput type={tracking.type} hideIcon={isHeader} />
        )}
        <WeeklySummary type={tracking.type} />
        <TrackingGoal type={tracking.type} />
      </Flex>

      <HistoryToggle
        history={history}
        toggleHistory={toggleHistory}
        index={index}
        measurement={measurement}
        hideSources
      />
    </Flex>
  );
};

ModuleTrackingMarkup.propTypes = {
  index: PropTypes.number,
  history: PropTypes.bool,
  toggleHistory: PropTypes.func,
  isHeader: PropTypes.bool,
  isModal: PropTypes.bool,
  validationMethod: PropTypes.func,
  setActiveTrackingValue: PropTypes.func,
  tracking: PropTypes.object,
  todaysValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ModuleTrackingMarkup.defaultProps = {
  tracking: {},
};

export default Radium(ModuleTrackingMarkup);
