import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';

const PostConfigurationMarkup = ({
  isSavingConfiguration,
  backHandler,
  onConfigurePlan,
}) => {
  const styles = {
    postConfigurationContainer: {
      marginTop: '80px',
      paddingLeft: '200px',
    },

    nav: {
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: '50px',
    },

    icon: {
      display: 'inline-block',
      fontSize: '24px',
      color: !isSavingConfiguration ? Colors.lightGreen : Colors.contentText,
    },

    back: {
      textAlign: 'left',
      color: Colors.linkBlue,
      opacity: '.75',
      cursor: 'pointer',
      width: '79px',
    },

    next: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: !isSavingConfiguration ? 'pointer' : 'not-allowed',
      color: !isSavingConfiguration ? Colors.black : Colors.contentText,
    },
  };

  return (
    <div style={styles.postConfigurationContainer}>
      <div style={[styles.nav, GlobalStyles.light]}>
        <span style={styles.back} onClick={backHandler}>
          Back
        </span>
        <div style={styles.next} onClick={onConfigurePlan}>
          <span>{localizedContent.plans.postConfig.buttonCaption} &nbsp;</span>
          <div style={styles.icon}>
            <i className="thin-0154_ok_successful_check" />
          </div>
        </div>
      </div>
    </div>
  );
};

PostConfigurationMarkup.propTypes = {
  isSavingConfiguration: PropTypes.bool.isRequired,
  backHandler: PropTypes.func.isRequired,
  onConfigurePlan: PropTypes.func.isRequired,
};

export default Radium(PostConfigurationMarkup);
