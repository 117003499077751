import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import { rewardsSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import RewardProductModalMarkup from './RewardProductModalMarkup';
import { selectAvailablePoints } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';

class RewardProductModal extends React.Component {
  constructor() {
    super(...arguments);

    const { product } = this.props;

    const { denominations } = product;

    const deliveryOptions = [];

    if (product.isDigital) {
      deliveryOptions.push({
        label: 'Email',
        value: RewardsConstants.RewardDeliveryForm.DIGITAL,
      });
    }

    if (product.isPhysical) {
      deliveryOptions.push({
        label: 'Standard Mail',
        value: RewardsConstants.RewardDeliveryForm.PHYSICAL,
      });
    }

    this.state = {
      denominations,
      deliveryOptions,
      quantity: 1,
      denomination: denominations.length === 1 ? denominations[0] : null,
      deliveryMethod:
        deliveryOptions.length === 1 ? deliveryOptions[0].value : null,
      enterStatus: false,
    };
  }

  render() {
    const {
      quantity,
      denomination,
      denominations,
      deliveryMethod,
      deliveryOptions,
      enterStatus,
    } = this.state;

    const {
      product,
      points,
      isAutomated,
      productId,
      toggleProduct,
    } = this.props;

    return (
      <RewardProductModalMarkup
        product={product}
        points={points}
        enterSuccess={enterStatus}
        isOpen={!!productId}
        closeHandler={toggleProduct}
        quantity={quantity}
        denomination={denomination}
        deliveryMethod={deliveryMethod}
        denominations={denominations}
        deliveryOptions={deliveryOptions}
        onQuantitySelected={this._onQuantitySelected}
        onDeliveryMethodSelected={this._onDeliveryMethodSelected}
        onDenominationSelected={this._onDenominationSelected}
        addReward={this._addReward}
        isAutomated={isAutomated}
      />
    );
  }

  _onQuantitySelected = (selectedQuantity) => {
    this.setState({
      quantity: selectedQuantity.value,
    });
  };

  _onDenominationSelected = (selectedDenomination) => {
    this.setState({
      denomination: selectedDenomination.value,
    });
  };

  _onDeliveryMethodSelected = (evt) => {
    this.setState({
      deliveryMethod: _.get(evt, 'target.value'),
    });
  };

  _addReward = () => {
    const { quantity, denomination, deliveryMethod } = this.state;

    if (quantity && deliveryMethod && denomination) {
      const productId = _.get(this, 'props.product.productId');

      this.props.actions.addToCart(
        productId,
        quantity,
        denomination,
        deliveryMethod
      );

      this.setState({
        enterStatus: true,
      });
    }
  };
}

RewardProductModal.propTypes = {
  actions: PropTypes.object.isRequired,
  params: PropTypes.object,
  productId: PropTypes.string,
  toggleProduct: PropTypes.func.isRequired,
  isAutomated: PropTypes.bool,
  product: PropTypes.object,
  points: PropTypes.number,
};

function mapStateToProps(state, props) {
  const prodId = props.productId ? props.productId : props.params.id;

  const rewards = rewardsSelector(state);

  const product = rewards && _.find(rewards, ['productId', prodId]);

  const preference = state.rewards.preference;

  const isAutomated = !!_.get(preference, 'rewardId');

  return {
    product,
    isAutomated,
    points: selectAvailablePoints(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { addToCart } = RewardsActions;

  return {
    actions: bindActionCreators({ addToCart }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardProductModal);
