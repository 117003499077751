import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';
import { Grid } from '@material-ui/core';

const EmptyList = ({ children }) => (
  <ListItem>
    <Grid container justify="center" className="print-hidden">
      <Grid item>{children}</Grid>
    </Grid>
  </ListItem>
);

EmptyList.propTypes = {
  children: PropTypes.string.isRequired,
};

export default EmptyList;
