import React from 'react';
import { routerActions } from 'react-router-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';

const userIsNotAuthenticated = connectedReduxRedirect({
  authenticatedSelector: (state) =>
    state.signIn &&
    (!state.signIn.accessToken ||
      state.signIn.appLoading ||
      state.signIn.appLoadFailed),
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  redirectPath: (state, ownProps) =>
    ownProps.location.query.redirect || '/health-overview',
  allowRedirectBack: false,
});

export default userIsNotAuthenticated((props) =>
  React.cloneElement(props.children)
);
