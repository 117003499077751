import React from 'react';
import DataChart from './DataChart';

/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
const oneDay = 24 * 3600 * 1000;

const reduceBpData = (data) =>
  data.reduce(
    ([sysSeries, diasSeries], [date, [systolic, diastolic]]) => [
      [...sysSeries, [date, systolic]],
      [...diasSeries, [date, diastolic]],
    ],
    [[], []]
  );

const createSeries = (data, min, name) => ({
  name,
  data,
  pointInterval: oneDay,
  tooltip: {
    dateTimeLabelFormats: {
      hour: '%m/%d/%Y',
    },
  },
});

const createBpSeries = (data) => {
  const [systolic, diastolic] = reduceBpData(data);
  return [
    createSeries(systolic, 'Systolic'),
    createSeries(diastolic, 'Diastolic'),
  ];
};

const Glucose = ({ data, ticks }) => (
  <DataChart
    series={[createSeries(data, ticks[0], 'Glucose')]}
    ticks={ticks}
    name={'Glucose'}
    unit={'mg/dL'}
  />
);

const Sleep = ({ data, ticks }) => (
  <DataChart
    series={[createSeries(data, ticks[0], 'Sleep')]}
    ticks={ticks}
    name={'Sleep'}
    unit={'hours'}
    bar
  />
);

const Weight = ({ data, ticks }) => (
  <DataChart
    series={[createSeries(data, ticks[0], 'Weight')]}
    ticks={ticks}
    name={'Weight'}
    unit={'lbs'}
  />
);

const Steps = ({ data, ticks, planYearTotalSteps }) => {
  const label =
    typeof planYearTotalSteps === 'number'
      ? `<span style='float:left'>
      Steps
    </span>
    <span style='float:right;color:#7c8a97;font-weight:200'>
      Plan Year Total:
      <span style='font-weight:bold'>
      ${planYearTotalSteps.toLocaleString()}
      </span>
    </span>`
      : 'Steps';

  return (
    <DataChart
      series={[createSeries(data, ticks[0], 'Steps')]}
      ticks={ticks}
      name={label}
      unit={'steps'}
      bar
    />
  );
};

const Workouts = ({ data, ticks }) => (
  <DataChart
    series={[createSeries(data, ticks[0], 'Daily Workout')]}
    ticks={ticks}
    name={'Daily Workout'}
    unit={'cal'}
    bar
  />
);

const Nutrition = ({ data, ticks }) => (
  <DataChart
    series={[createSeries(data, ticks[0], 'Nutrition')]}
    ticks={ticks}
    name={'Nutrition'}
    unit={'cal'}
    bar
  />
);

const BloodPressure = ({ data, ticks }) => (
  <DataChart
    series={createBpSeries(data, ticks[0])}
    ticks={ticks}
    name={'Blood Pressure'}
    unit={'mmHg'}
    legend
  />
);

export default {
  glucose: Glucose,
  sleep: Sleep,
  weight: Weight,
  steps: Steps,
  workouts: Workouts,
  nutrition: Nutrition,
  bloodPressure: BloodPressure,
};
/* eslint-enable react/prop-types */
/* eslint-enable react/no-multi-comp */
