import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, TrackingData } from '@healthmine/greyhound-core/src/modules';
import SimpleCardIcon from '../care-plan-tracking/cards/common/SimpleCardIcon';
import { Flex, Box } from 'reflexbox';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import _ from 'lodash';
import createCachedSelector from 're-reselect';

const EMPTY_ARRAY = [];

const dataSelector = (state, ownProps) =>
  _.last(ownProps.measurement.dataSelector(state)) || EMPTY_ARRAY;

const propSelector = createCachedSelector(dataSelector, ([date, value]) => ({
  date,
  value,
}))((state, ownProps) => ownProps.measurement.dataKey);

const MeasurementHeader = ({
  measurement,
  date,
  value,
  editing,
  setEdit,
  setSecondaryEdit,
}) => {
  const styles = {
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '5px',
      justifyContent: 'center',
    },
    measurement: {
      fontSize: '28px',
    },
    headerText: {
      fontSize: '18px',
      color: Colors.bodyText,
      lineHeight: '20px',
      height: '54px',
    },
    date: {
      fontSize: '16px',
      color: Colors.contentText,
    },
    input: {
      width: '80px',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      boxShadow: 'none',
      fontSize: '28px',
      display: 'inline-block',
      ':focus': {
        outline: 'none',
      },
    },
    dualInputOne: {
      width: '40px',
    },
    dualInputTwo: {
      width: '30px',
    },
  };

  const toFixedDecimalString = (val) => {
    const numericVal = parseFloat(val);

    return isNaN(numericVal) ? val : numericVal.toFixed(1).replace(/\.0$/, '');
  };

  return (
    <Flex mt={1} justify={'center'}>
      <SimpleCardIcon icon={measurement.icon} color={measurement.color} />
      <Box col={6} ml={2} style={styles.headerText}>
        <div>
          {editing ? (
            measurement.dataKey === 'bloodPressure' ? (
              <span>
                <input
                  key={'systolic'}
                  style={[styles.input, styles.dualInputOne]}
                  onBlur={setEdit}
                  autoFocus
                />{' '}
                /
                <input
                  key={'diastolic'}
                  style={[styles.input, styles.dualInputTwo]}
                  onBlur={setSecondaryEdit}
                />
              </span>
            ) : (
              <input autoFocus style={styles.input} onBlur={setEdit} />
            )
          ) : (
            <span style={styles.measurement}>
              {value
                ? Array.isArray(value)
                  ? value.map(toFixedDecimalString).join('/')
                  : toFixedDecimalString(value)
                : '0'}
            </span>
          )}{' '}
          <span>{measurement.unit}</span>
        </div>
        <div style={styles.date}>
          {editing ? 'Today' : TrackingData.getMeasurementDate(date)}
        </div>
      </Box>
    </Flex>
  );
};

MeasurementHeader.propTypes = {
  measurement: PropTypes.object,
  date: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  editing: PropTypes.bool,
  setEdit: PropTypes.func,
  setSecondaryEdit: PropTypes.func,
  showBadge: PropTypes.bool,
};

export default compose(
  connect((state, ownProps) => propSelector(state, ownProps)),
  Radium
)(MeasurementHeader);
