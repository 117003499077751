import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router';
import moment from 'moment';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import FloatingLabel from '../common/FloatingLabel';
import DateComponent from '../common/question/Date';
import Button from '../../components/common/Button';
import { Features } from '@healthmine/greyhound-core/src/constants';
import InsuranceCardInfoPopup from '../common/InsuranceCardInfoPopup';

const RetrieveAccountMarkup = ({
  formState,
  onDOBChange,
  errors,
  isFetching,
  validateField,
  validatedFields,
  onLastNameChange,
  onMemberIdChange,
  onClickHandler,
  onFirstNameChange,
}) => {
  const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);

  const styles = {
    mainContainer: {
      width: leapFrogUI ? 'initial' : '1000px',
      maxWidth: '100%',
      color: Colors.darkText,
      backgroundColor: Colors.light,
      padding: leapFrogUI ? '30px' : '10px 10px 10px 200px',
      marginTop: '10px',
      boxSizing: leapFrogUI ? 'initial' : 'border-box',
      borderRadius: leapFrogUI ? '6px' : '0',
      border: leapFrogUI ? `1px solid ${Colors.mediumGray3}` : 'none',

      '@media screen and (max-width: 760px) and (min-width: 481px)': {
        padding: '10px 10px 10px 100px',
      },

      '@media screen and (max-width: 480px)': {
        padding: '10px 40px',
      },
    },

    inputWrapper: {
      clear: 'both',
      marginTop: '10px',
    },

    bottomLinks: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '60px',
      marginBottom: '110px',
    },

    inputContainer: {
      float: 'left',
      marginRight: '10px',
      marginTop: '10px',
      marginBottom: '10px',
      position: 'relative',
    },

    floatRight: {
      color: '#dadfe4',
      width: '330px',
      textAlign: 'right',
      marginTop: '-15px',

      '@media screen and (max-width: 480px)': {
        width: '240px',
      },
    },

    link: {
      color: Colors.linkBlue,
      fontSize: '16px',
      position: 'relative',
      lineHeight: '20px',
    },

    dateWrapper: {
      marginBottom: '20px',
      color: Colors.lightGray3,
    },

    mainHeader: {
      fontSize: '24px',
      margin: '0 0 5px',
      color: Colors.black,
    },

    subHeaderText: {
      margin: '0 0 20px',
      color: Colors.black,
      maxWidth: '520px',
      fontSize: '16px',
    },

    imageSpacing: {
      marginRight: '20px',
      marginTop: '20px',
    },

    iconImage: {
      fontSize: '35px',
      color: Colors.white,
      backgroundColor: Colors.bodyText,
      borderRadius: '50%',
      padding: '20px',
    },

    title: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '30px',
    },

    formContainer: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
    },

    dobLabel: {
      fontSize: '16px',
      marginTop: '20px',
    },

    dateComponent: {
      container: {
        marginTop: '10px',
      },
      day: {
        width: '120px',

        '@media screen and (max-width: 480px)': {
          width: '95px',
        },
      },
      month: {
        width: '90px',

        '@media screen and (max-width: 480px)': {
          width: '60px',
        },
      },
      year: {
        width: '100px',

        '@media screen and (max-width: 480px)': {
          width: '65px',
        },
      },
    },

    helpText: {
      color: 'red',
      fontSize: '16px',
      display: 'block',
      position: 'absolute',
      top: '45px',
    },

    errorMessageWrapper: {
      color: 'red',
      fontWeight: '600',
      fontSize: '16px',
      marginTop: '40px',
    },

    errorMessage: {},

    button: {
      backgroundColor: Colors.dark,
      color: Colors.light,
      width: '100px',
      height: '37px',
      marginTop: '-13px',
      marginLeft: '100px',
      outline: 'none',
      borderRadius: '4px',
      fontSize: '16px',
    },

    linkbtn: {
      color: Colors.contentText,
      textDecoration: 'none',
      display: 'flex',
      fontSize: '16pt',
      marginTop: '-2px',
      marginRight: '3px',
      cursor: 'pointer',
    },
  };

  const RadiumLink = Radium(Link);

  const { lastName, dateOfBirth, memberId, firstName } = formState;

  const formIsValid =
    lastName.isValid &&
    dateOfBirth.isValid &&
    memberId.isValid &&
    firstName.isValid;

  const updateLastName = (event) => onLastNameChange(event.target.value);

  const validateLastName = () => validateField('lastName');

  const updateFirstName = (event) => onFirstNameChange(event.target.value);

  const validateFirstName = () => validateField('firstName');

  const updateMemberId = (event) => onMemberIdChange(event.target.value);

  const validateMemberId = () => validateField('memberNumber');

  return (
    <div style={styles.mainContainer}>
      <div style={styles.title}>
        <span style={styles.imageSpacing}>
          <i className="thin-0632_security_lock" style={styles.iconImage} />
        </span>
        <span>
          <h2 style={[styles.mainHeader, GlobalStyles.extraLight]}>
            Reset Your Password
          </h2>
          <h3 style={[styles.subHeaderText, GlobalStyles.regular]}>
            Enter the information found on your ID card. You will receive an
            email with further instructions.
          </h3>
        </span>
      </div>
      <div style={styles.formContainer}>
        <div
          style={
            firstName.isValid !== false
              ? styles.inputContainer
              : [styles.inputContainer, { marginBottom: '20px' }]
          }
        >
          <FloatingLabel
            onChange={updateFirstName}
            placeHolder="First Name"
            value={firstName.value}
            isValid={
              validatedFields.indexOf('firstName') > -1
                ? firstName.isValid
                : null
            }
            onBlur={validateFirstName}
          />
          {firstName.isValid == false &&
          validatedFields.indexOf('firstName') > -1 ? (
            <span style={styles.helpText}>First Name should not be empty.</span>
          ) : null}
        </div>
        <div
          style={
            lastName.isValid !== false
              ? styles.inputContainer
              : [styles.inputContainer, { marginBottom: '20px' }]
          }
        >
          <FloatingLabel
            onChange={updateLastName}
            placeHolder="Last Name"
            value={lastName.value}
            isValid={
              validatedFields.indexOf('lastName') > -1 ? lastName.isValid : null
            }
            onBlur={validateLastName}
          />
          {lastName.isValid == false &&
          validatedFields.indexOf('lastName') > -1 ? (
            <span style={styles.helpText}>Last Name should not be empty.</span>
          ) : null}
        </div>
        <div style={styles.inputWrapper}>
          <div style={[styles.inputContainer, styles.dateWrapper]}>
            <label style={[GlobalStyles.regular, styles.dobLabel]}>
              Date of Birth
            </label>
            <DateComponent
              minDate={moment().subtract(120, 'years')}
              maxDate={moment()}
              value={dateOfBirth.value}
              fullMonth
              styles={styles.dateComponent}
              onAnswered={(date) => onDOBChange(date)}
            />
          </div>
        </div>
        <div style={styles.inputWrapper}>
          <div
            style={
              memberId.isValid !== false
                ? styles.inputContainer
                : [styles.inputContainer, { marginBottom: '20px' }]
            }
          >
            <FloatingLabel
              onChange={updateMemberId}
              placeHolder="Member ID"
              value={memberId.value}
              isValid={
                validatedFields.indexOf('memberNumber') > -1
                  ? memberId.isValid
                  : null
              }
              onBlur={validateMemberId}
            />
            {memberId.isValid == false &&
            validatedFields.indexOf('memberNumber') > -1 ? (
              <span style={styles.helpText}>
                Member ID should not be empty.
              </span>
            ) : null}
          </div>
        </div>
        <div style={styles.inputWrapper}>
          <div style={styles.floatRight}>
            <span style={[styles.link, GlobalStyles.regular]}>
              Where is my member ID?
              <InsuranceCardInfoPopup />
            </span>
          </div>
        </div>

        {errors.length > 0 && (
          <div style={styles.errorMessageWrapper}>
            {errors.map((val, index) => {
              return (
                <p
                  key={index}
                  style={[GlobalStyles.regular, styles.errorMessage]}
                >
                  {val}
                </p>
              );
            })}
          </div>
        )}

        <div style={styles.bottomLinks}>
          <RadiumLink
            style={[GlobalStyles.regular, styles.linkbtn]}
            to={'/signin/'}
          >
            Back
          </RadiumLink>

          <Button
            style={[styles.button, GlobalStyles.regular]}
            text={'Email Me'}
            onClick={formIsValid ? onClickHandler : null}
            disabled={!formIsValid || isFetching}
          />
        </div>
      </div>
    </div>
  );
};

RetrieveAccountMarkup.propTypes = {
  formState: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  validatedFields: PropTypes.array,
  onDOBChange: PropTypes.func,
  validateField: PropTypes.func,
  onLastNameChange: PropTypes.func,
  onMemberIdChange: PropTypes.func,
  onClickHandler: PropTypes.func,
  onFirstNameChange: PropTypes.func,
};

export default Radium(RetrieveAccountMarkup);
