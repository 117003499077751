import _ from 'lodash';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import HistoryToggle from '../../../data/MeasurementBody';
import Measurements from '@healthmine/greyhound-core/src/modules/Measurements';

const ModuleCardMarkup = ({
  horizontalPlacementIndex,
  tracking,
  title,
  subTitle,
  summaryStatement,
  isCompleted,
  isHeader,
  // detailsLabel,
  areDetailsVisible,
  onToggleDetails,
  styleOverrides,
}) => {
  const moduleColor = _.get(Colors, tracking.type, Colors.navyBlue);

  const measurement = Measurements.MeasurementTypes[tracking.type];

  const styles = {
    root: {
      display: 'flex',
      flex: `0 0 ${isHeader ? '300px' : '299px'}`,
      boxSizing: 'border-box',
      height: '250px',
      position: 'relative',
      borderBottom:
        isHeader || areDetailsVisible
          ? 'none'
          : `1px solid ${Colors.gutterGray}`,
      borderRight: isHeader ? 'none' : `1px solid ${Colors.gutterGray}`,
      marginBottom: areDetailsVisible ? '356px' : 0,
      ..._.get(styleOverrides, 'root', {}),
    },

    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '40px 10px 15px',
      ..._.get(styleOverrides, 'content', {}),
    },

    header: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },

    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: !isHeader || isCompleted ? 'flex-start' : 'center',
    },

    iconBubble: {
      display: 'flex',
      position: 'relative',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      fontSize: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: isHeader ? 'transparent' : moduleColor,
      margin: '12px 20px 0px 50px',
    },

    icon: {
      color: Colors.white,
    },

    completionBadge: {
      display: 'flex',
      position: 'absolute',
      top: '-2px',
      right: '-2px',
      height: '14px',
      width: '14px',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Colors.linkBlue,
      border: '1px solid #fff',
    },

    completionBadgeIcon: {
      backgroundColor: 'transparent',
      color: Colors.white,
      fontSize: '16px',
    },

    completionSuperscript: {
      fontSize: '16px',
      fontWeight: 'normal',
      color: Colors.contentText,
      marginBottom: '2px',
    },

    title: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '16px',
      paddingTop: isCompleted ? '0px' : '16px',
    },

    subTitle: {
      margin: '10px auto -10px',
      fontSize: '16px',
      textAlign: 'center',
    },

    summaryStatement: {
      margin: '0px',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: '300',
      color: Colors.bodyText,
      maxWidth: '100%',
    },

    detailsToggle: {
      width: '100%',
      textAlign: 'right',
      paddingRight: '60px',
      color: Colors.contentText,
      cursor: 'pointer',
    },

    detailsToggleText: {
      fontSize: '16px',
    },

    detailsToggleCaret: {
      position: 'relative',
      top: 0,
      right: 0,
    },

    detailsContainer: {
      backgroundColor: Colors.light,
      padding: '40px',
      transition: '0.5s ease all',
      textAlign: 'center',
      position: 'absolute',
      left: `-${(horizontalPlacementIndex % 3) * 300}px`,
      right: `${(horizontalPlacementIndex % 3) * 300 - 600}px`,
      top: '251px',
    },
  };

  const iconClass =
    measurement && measurement.icon
      ? measurement.icon
      : 'thin-0007_book_reading_read_bookmark';

  return (
    <div style={styles.root}>
      <div style={styles.content}>
        <div style={styles.header}>
          <div style={styles.titleContainer}>
            {!isHeader || isCompleted ? (
              <div style={styles.iconBubble}>
                {!isHeader ? (
                  <i className={iconClass} style={styles.icon} />
                ) : null}
                {isCompleted ? (
                  <div style={styles.completionBadge}>
                    <i
                      className="material-icons"
                      style={styles.completionBadgeIcon}
                    >
                      done
                    </i>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div style={styles.title}>
              {isCompleted ? (
                <label style={styles.completionSuperscript}>completed</label>
              ) : null}
              {title}
            </div>
          </div>

          <div style={styles.subTitle}>{subTitle}</div>
        </div>

        <div style={styles.summaryStatement}>{summaryStatement}</div>
        {tracking.type !== 'EDUCATION' && (
          <HistoryToggle
            history={areDetailsVisible}
            toggleHistory={onToggleDetails}
            index={horizontalPlacementIndex}
            measurement={measurement}
            hideSources
          />
        )}
      </div>
    </div>
  );
};

ModuleCardMarkup.propTypes = {
  horizontalPlacementIndex: PropTypes.number.isRequired,
  tracking: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  summaryStatement: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isHeader: PropTypes.bool.isRequired,
  detailsLabel: PropTypes.string,
  areDetailsVisible: PropTypes.bool.isRequired,
  onToggleDetails: PropTypes.func.isRequired,
  detailsComponent: PropTypes.node,
  styleOverrides: PropTypes.object.isRequired,
};

export default Radium(ModuleCardMarkup);
