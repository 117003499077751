import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import AutomatedRewards from '../automated-rewards/AutomatedRewards';
import AutomatedRewardCancellation from '../automated-rewards/AutomatedRewardCancellation';
import Modal from 'react-modal';

const CurrentRewardMarkup = ({
  isAutomated,
  toggleAutomatedModal,
  toggleCancelModal,
  cancelModalOpen,
  automatedModalOpen,
  automatedReward,
}) => {
  const styles = {
    currentReward: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px 0px 10px',
    },
    rewardImage: {
      width: '85px',
      height: '50px',
    },
    actionLabel: {
      color: Colors.linkBlue,
      fontSize: '16px',
      fontWeight: '500',
      paddingRight: '5px',
      cursor: 'pointer',
    },
    rewardsTitleText: {
      margin: 0,
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.mediumGray2,
    },
    automatedRewards: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '11px',
    },
    separator: {
      fontSize: '16px',
      color: Colors.bodyText,
      paddingRight: '5px',
    },
    productName: {
      marginLeft: '10px',
      fontWeight: '500',
      fontSize: '16px',
    },
    deliveryMethods: {
      display: 'flex',
      flexDirection: 'row',
      margin: '5px 0px 10px 10px',
    },
    deliveryMethod: {
      textTransform: 'uppercase',
      fontSize: '16px',
      color: Colors.white,
      lineHeight: '16px',
      marginRight: '5px',
      padding: '1px 2px 1px 3px',
    },
    digitalDeliveryMethod: {
      backgroundColor: Colors.lightGreen,
    },
    physicalDeliveryMethod: {
      backgroundColor: Colors.orange,
    },
    customModal: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
        overflow: 'auto',
      },
      content: {
        marginBottom: '40px',
        bottom: 'auto',
        right: 'initial',
        left: '50%',
        top: '40px',
        transform: 'translate(-50%, 0)',
        border: 'none',
        background: Colors.white,
        WebkitOverflowScrolling: 'touch',
        outline: 'none',
        padding: '60px 60px 40px 60px',
      },
    },
    closeButton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },
  };

  const imageUrl =
    isAutomated === true
      ? automatedReward.imageUrl
      : require('../../../images/rewards.png');

  return (
    <div>
      {isAutomated ? (
        <div style={styles.automatedRewards}>
          <span style={styles.rewardsTitleText}>
            Current Automated Rewards:
          </span>
          <div style={styles.currentReward}>
            <img
              style={styles.rewardImage}
              src={imageUrl}
              alt={`${automatedReward.name} Card`}
              title={`${automatedReward.name} Card`}
            />
            <div>
              <span style={styles.productName}>{automatedReward.name}</span>
              <div style={[GlobalStyles.light, styles.deliveryMethods]}>
                {automatedReward.deliveryForm === 'digital' ? (
                  <span
                    style={[
                      styles.deliveryMethod,
                      styles.digitalDeliveryMethod,
                    ]}
                  >
                    email
                  </span>
                ) : null}

                {automatedReward.deliveryForm === 'physical' ? (
                  <span
                    style={[
                      styles.deliveryMethod,
                      styles.physicalDeliveryMethod,
                    ]}
                  >
                    standard mail
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <label
              style={styles.actionLabel}
              onClick={() => toggleAutomatedModal()}
            >
              Change
            </label>
            <span style={styles.separator}> or </span>
            <label
              style={[styles.actionLabel, { color: Colors.red }]}
              onClick={toggleCancelModal}
            >
              Cancel Selection
            </label>
          </div>
        </div>
      ) : (
        <div style={styles.automatedRewards}>
          <span style={styles.rewardsTitleText}>Automated Rewards:</span>
          <div style={styles.currentReward}>
            <img
              style={styles.rewardImage}
              src={imageUrl}
              alt="Choose a Card"
              title="Choose a Card"
            />
          </div>
          <label
            style={styles.actionLabel}
            onClick={() => toggleAutomatedModal()}
          >
            Choose a Card
          </label>
        </div>
      )}

      <AutomatedRewardCancellation
        modalOpen={cancelModalOpen}
        automatedReward={automatedReward}
        toggleProduct={toggleCancelModal}
      />

      <Modal
        isOpen={automatedModalOpen}
        onRequestClose={toggleAutomatedModal}
        contentLabel={''}
        style={styles.customModal}
      >
        <a onClick={toggleAutomatedModal} style={styles.closeButton}>
          <i className="thin-0153_delete_exit_remove_close" />
        </a>
        <div>
          <AutomatedRewards toggleModal={toggleAutomatedModal} />
        </div>
      </Modal>
    </div>
  );
};

CurrentRewardMarkup.propTypes = {
  isAutomated: PropTypes.bool,
  toggleCancelModal: PropTypes.func,
  cancelModalOpen: PropTypes.bool,
  toggleAutomatedModal: PropTypes.func,
  automatedModalOpen: PropTypes.bool,
  automatedReward: PropTypes.object,
};

export default Radium(CurrentRewardMarkup);
