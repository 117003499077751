import React from 'react';
import PropTypes from 'prop-types';
import List, { ListHeader, ListContent } from '../../../common/list2/List';
import LabItem from './LabItem';
import { compose, withProps } from 'recompose';
import _ from 'lodash';
import moment from 'moment';

const LabList = ({ labs, labCount }) => {
  return (
    <List disabled={!labCount}>
      <ListHeader
        iconClass="icon-Medicine-Pills"
        title="Labs"
        color="#1ABEEF"
        subtitle={labCount !== 0 ? `${labCount} total` : undefined}
      />
      <ListContent isEmpty={!labCount} emptyMessage="No labs found.">
        {labs.map(({ labType, dateOfService }, idx) => (
          <LabItem
            key={`labs-${idx}`}
            labType={labType}
            dateOfService={moment(dateOfService).format('MM/DD/YYYY')}
          />
        ))}
      </ListContent>
    </List>
  );
};

LabList.propTypes = {
  labs: PropTypes.array.isRequired,
  labCount: PropTypes.number.isRequired,
};

export default compose(
  withProps(({ labs }) => {
    const flattenedLabs = _.flatMap(labs, (claim) => _.get(claim, 'labs'));
    return {
      labs: flattenedLabs,
      labCount: _.get(labs, 'length', 0),
    };
  })
)(LabList);
