import moment from 'moment';

export const getWeeklySummary = (currentWeek, currentWeekData) =>
  Array.from(new Array(7).keys()).map((day) => {
    const date = moment(currentWeek).add(day, 'd');
    const currentValue = currentWeekData[date.valueOf()] || 0;
    const isBloodPressure = Array.isArray(currentValue);
    const value = isBloodPressure ? currentValue.join('/') : currentValue;
    return { date, value };
  });
