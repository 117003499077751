import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import Button from '../../common/Button';
import InfoPopup from '../../common/InfoPopup';
import { hashHistory } from 'react-router';

const OrderSummaryMarkup = ({
  shoppingCartItems,
  firstName,
  lastName,
  address,
  points,
  redemptionStatus,
  isAutomated,
  automatedReward,
  emailAddress,
}) => {
  const containerPadding = isAutomated ? '0px' : '75px';

  const styles = {
    para: {
      color: Colors.contentText,
      margin: '30px 0px 60px 0px',
      fontSize: '16px',
    },
    paragraph: {
      display: 'flex',
      fontSize: '16px',
      color: Colors.contentText,
      flexDirection: 'column',
      width: '625px',
      justifyContent: 'center',
      paddingLeft: containerPadding,
      paddingTop: '30px',
    },
    root: {
      paddingTop: '20px',
      paddingBottom: '40px',
      paddingLeft: '45px',
      paddingRight: '45px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: containerPadding,
      paddingRight: containerPadding,
    },

    table: {
      textAlign: 'left',
      display: 'flex',
      fontSize: '16px',
      width: '100%',
      padding: '20px 0px',
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      color: Colors.navyBlue,
      paddingBottom: '20px',
      marginBottom: '10px',
      width: '100%',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },
    itemImage: {
      width: '120px',
      height: '75px',
    },
    description: {
      marginLeft: '15px',
      width: '450px',
    },
    label: {
      fontSize: '16px',
      fontWeight: 600,
    },
    subLabel: {
      fontSize: '16px',
      color: Colors.contentText,
    },
    contentTable: {
      marginRight: '-20px',
      width: '100%',
    },
    column: {
      flex: 1,
    },
    description1: {
      color: Colors.contentText,
      fontSize: '16px',
      margin: '2px 0 10px',
    },

    deliveryMethodInfoPopup: {
      icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14px',
        height: '14px',
        lineHeight: '14px',
        fontSize: '16px',
        color: Colors.white,
        backgroundColor: Colors.dark,
      },

      popupContainer: {
        left: '18px',
        right: 'initial',
      },

      popupContent: {
        borderRadius: '0 10px 10px 10px',
        backgroundColor: Colors.lightenColor(Colors.lightGray, 2),
        padding: '10px 20px',
      },

      popupStem: {
        left: 0,
        right: 'initial',
        top: '-19px',
        borderTop: '20px solid transparent',
        borderLeft: `15px solid ${Colors.lightenColor(Colors.lightGray, 2)}`,
        borderBottom: '0 solid transparent',
        borderRight: '0 solid transparent',
      },
    },

    deliveryMethodInfoPopupContent: {
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: 400,
      width: '240px',
    },

    contentHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.mediumGray2,
      marginBottom: '5px',
    },
    footer: {
      fontSize: '16px',
      marginTop: '10px',
    },
    footerContainer: {
      textAlign: 'right',
      paddingTop: '25px',
      borderTop: `1px solid ${Colors.gutterGray}`,
    },
    button: {
      width: '220px',
      padding: '8px',
      fontSize: '16px',
    },
    contentSelect: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  };

  const { autoRewardsContact } = AppConfig.effectiveConfig.lingo;

  const { setCurrencyLabel } = RewardsConstants;

  const tableContent = shoppingCartItems.map((shoppingCartItem) => (
    <div
      style={styles.table}
      key={`${shoppingCartItem.productId}${shoppingCartItem.denomination}${
        shoppingCartItem.method
      }`}
    >
      <span>
        <img
          src={shoppingCartItem.imageUrl}
          style={styles.itemImage}
          alt={`${shoppingCartItem.name} Card`}
          title={`${shoppingCartItem.name} Card`}
        />
      </span>
      <span style={styles.description}>
        <div style={styles.label}>{shoppingCartItem.name}</div>
      </span>
      <span style={styles.contentTable}>
        <div style={styles.contentHeader}>
          <label style={styles.column}>Denomination</label>
          <label style={styles.column}>Quantity</label>
          <label style={styles.column}>
            Delivery Method
            <InfoPopup styles={styles.deliveryMethodInfoPopup}>
              <div style={styles.deliveryMethodInfoPopupContent}>
                {shoppingCartItem.method ===
                RewardsConstants.RewardDeliveryForm.DIGITAL
                  ? 'Check your Message Center on the Healthy Rewards portal within 12 hours for your e-code(s) and redemption instructions.'
                  : 'Please allow for 7-10 business days for delivery of your gift card(s).'}
              </div>
            </InfoPopup>
          </label>
        </div>
        <div style={[styles.contentSelect, GlobalStyles.regular]}>
          <span style={[styles.column, styles.description1]}>
            {shoppingCartItem.denomination}
          </span>
          <span style={[styles.column, styles.description1]}>
            {shoppingCartItem.quantity}
          </span>
          <span style={[styles.column, styles.description1]}>
            {shoppingCartItem.method ===
            RewardsConstants.RewardDeliveryForm.DIGITAL
              ? 'Email'
              : 'Standard Mail'}
          </span>
        </div>
      </span>
    </div>
  ));

  let total = 0;

  shoppingCartItems.map((item) => {
    total += item.quantity * item.denomination;
  });

  return (
    <div style={styles.root}>
      {isAutomated !== true && redemptionStatus !== 'FAILED' ? (
        <div>
          <div style={[GlobalStyles.regular, styles.paragraph]}>
            <p
            >{`${firstName}, thank you for your order. We plan to ship your card as soon as possible. You should be receiving a secure
              message shortly to confirm your order`}</p>
            {!!autoRewardsContact && <p>{autoRewardsContact}</p>}
            <p>Thank you again for your order!</p>
          </div>
          <div style={styles.content}>
            <div>
              <span style={[GlobalStyles.regular, styles.tableHeader]}>
                Delivered to:
              </span>
              <div style={[GlobalStyles.regular, styles.para]}>
                {`${firstName} ${lastName}`}
                <br />
                {address &&
                  `${address.streetAddr1} ${
                    address.streetAddr2 !== null ? address.streetAddr2 : ''
                  }`}
                <br />
                {address && `${address.city}, ${address.state} ${address.zip}`}
              </div>
            </div>
            <div style={[styles.tableHeader, GlobalStyles.regular]}>Items:</div>
            <div>{tableContent}</div>
            <div style={styles.footerContainer}>
              <div style={[GlobalStyles.regular, styles.footer]}>
                Card Total: {setCurrencyLabel(total)}
              </div>
              <div style={[GlobalStyles.regular, styles.footer]}>
                Your Balance: {setCurrencyLabel(points)}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isAutomated !== true && redemptionStatus === 'FAILED' ? (
        <div>
          <div style={[GlobalStyles.regular, styles.paragraph]}>
            <p>{`${firstName}, Your order has failed`}</p>
            {!!autoRewardsContact && <p>{autoRewardsContact}</p>}
            <p>Please try after some time!</p>
          </div>
          <div style={[styles.content, { marginTop: '5px' }]}>
            <Button
              style={[GlobalStyles.regular, styles.button, { width: '180px' }]}
              text="Back to Rewards"
              onClick={() => hashHistory.push('/rewards/shop/')}
            />
          </div>
        </div>
      ) : null}
      {isAutomated === true ? (
        <div>
          <div style={[GlobalStyles.regular, styles.paragraph]}>
            <p>
              {`${firstName}, thank you for setting up your automated gift card. You should be receiving a secure message shortly to confirm your order.`}
            </p>
            {!!autoRewardsContact && <p>{autoRewardsContact}</p>}
            <p>Thank you again for your order!</p>
          </div>
          <div style={styles.content}>
            <div>
              <span style={[GlobalStyles.regular, styles.tableHeader]}>
                Deliver to:
              </span>
              <div style={[GlobalStyles.regular, styles.para]}>
                {emailAddress}
                <br />
                {address &&
                  `${address.streetAddr1} ${
                    address.streetAddr2 !== null ? address.streetAddr2 : ''
                  }`}
                <br />
                {address && `${address.city}, ${address.state} ${address.zip}`}
              </div>
            </div>
            <div style={[styles.tableHeader, GlobalStyles.regular]}>Items:</div>
            <div style={styles.table}>
              <span>
                <img
                  src={automatedReward.imageUrl}
                  style={styles.itemImage}
                  alt={`${automatedReward.name} Card`}
                  title={`${automatedReward.name} Card`}
                />
              </span>
              <span style={styles.description}>
                <div style={styles.label}>{automatedReward.name}</div>
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

OrderSummaryMarkup.propTypes = {
  shoppingCartItems: PropTypes.array,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  emailAddress: PropTypes.string,
  redemptionStatus: PropTypes.string,
  address: PropTypes.object,
  points: PropTypes.number,
  isAutomated: PropTypes.bool,
  automatedReward: PropTypes.object,
};

export default Radium(OrderSummaryMarkup);
