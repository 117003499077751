import React from 'react';
import { hashHistory } from 'react-router';
import Radium, { StyleRoot } from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import ReportComplianceModal from './ReportComplianceModal';
import { Document, Clipboard } from '../../styles/uhc-icons';

class FeaturedItemsMarkup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showReportCompliance: false,
    };
  }

  render() {
    const styles = {
      root: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: Colors.white,
        padding: '20px 0px',
        justifyContent: 'center',
      },

      uhcBrandedItem: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        cursor: 'pointer',
        margin: '0px 10px',
      },

      uhcBrandedIcon: {
        width: '80px',
      },
    };

    return (
      <StyleRoot style={styles.root}>
        <a
          style={styles.uhcBrandedItem}
          onClick={() => hashHistory.push('/plan-information')}
        >
          <img style={styles.uhcBrandedIcon} src={Document} />
          <span>
            Program<br />Information
          </span>
        </a>

        <a
          style={styles.uhcBrandedItem}
          onClick={this.openReportComplianceModal}
        >
          <img style={styles.uhcBrandedIcon} src={Clipboard} />
          <span>
            Health Action<br />Notification Form
          </span>
        </a>

        {this.state.showReportCompliance && (
          <ReportComplianceModal close={this.closeTracking} />
        )}
      </StyleRoot>
    );
  }

  openReportComplianceModal = () => {
    this.setState({
      showReportCompliance: true,
    });
  };

  closeTracking = () => {
    this.setState({
      showReportCompliance: false,
    });
  };
}

export default Radium(FeaturedItemsMarkup);
