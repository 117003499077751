import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = () => ({
  root: {
    display: 'flex',
    fontWeight: '500',
    alignItems: 'flex-start',
    '&:not(:last-child)': {
      marginBottom: '12px',
    },
  },
  icon: {
    height: '16px',
    marginRight: '8px',
    marginTop: '2px',
  },
  value: {
    marginLeft: '8px',
  },
  units: {
    marginLeft: '4px',
  },
  textContainer: {
    display: 'flex',
    marginBottom: '2px',
  },
  recordedOnDate: {
    fontSize: '14px',
    color: '#99A7B5',
    fontWeight: '400',
  },
});

const MemberField = ({ classes, field, value, units, icon, date }) => (
  <div className={classes.root}>
    {icon && (
      <img
        className={classes.icon}
        src={require(`../../../../../images/${icon}.png`)}
      />
    )}
    <div>
      <div className={classes.textContainer}>
        <div>{field}</div>
        <div className={classes.value}>
          {value !== undefined ? `${value} ${units || ''}` : 'N/A'}
        </div>
      </div>
      {date && (
        <div className={classes.recordedOnDate}>Recorded On: {date}</div>
      )}
    </div>
  </div>
);

MemberField.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  units: PropTypes.string,
  icon: PropTypes.string,
  date: PropTypes.string,
};

export default withStyles(styles)(MemberField);
