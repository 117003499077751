import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const styles = {
  summaryWrapper: {
    position: 'absolute',
    opacity: 0,
    transition: 'opacity 0.5s 0.5s',
  },
  summaryWrapperVisible: {
    position: 'absolute',
    opacity: 1,
    transition: 'opacity 0.5s 0.5s',
  },
  summaryLine: {
    display: 'block',
    marginTop: '5px',
    marginBottom: '0px',
    fontWeight: 'normal',
    fontSize: '16px',
    color: Colors.navyBlue,
  },
};

const StepSummary = ({ active, summaryLines }) => (
  <div style={active ? styles.summaryWrapperVisible : styles.summaryWrapper}>
    {active &&
      (summaryLines || []).map((summaryLine, summaryLineIndex) => (
        <h2 key={`SummaryLine_${summaryLineIndex}`} style={styles.summaryLine}>
          {summaryLine}
        </h2>
      ))}
  </div>
);

StepSummary.propTypes = {
  active: PropTypes.bool,
  summaryLines: PropTypes.arrayOf(PropTypes.string),
};

export default Radium(StepSummary);
