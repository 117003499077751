import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router';

const RadiumLink = Radium(Link);

class SmartLink extends React.Component {
  render() {
    const { to, children, ...otherProps } = this.props;

    return /^https?:\/\//i.test(to) ? (
      <a href={to} target="_blank" {...otherProps}>
        {children}
      </a>
    ) : (
      <RadiumLink to={to} {...otherProps}>
        {children}
      </RadiumLink>
    );
  }
}

SmartLink.propTypes = {
  to: PropTypes.string.isRequired,

  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Radium(SmartLink);
