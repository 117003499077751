import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import {
  AppConstants,
  RewardsConstants,
} from '@healthmine/greyhound-core/src/constants';
import { DrawerContent } from '../../../common/drawer2';
import Button from '../../../common/Button';
import InfoPopup from '../../../../../components/common/InfoPopup';

const CheckoutSummaryMarkup = ({
  editAddress,
  changeAddress,
  isEditable,
  shoppingCartItems,
  address,
  name,
  emailAddress,
  addressError,
  saveAddress,
  points,
  placeOrder,
  closeCart,
  editCart,
}) => {
  const styles = {
    root: {
      paddingTop: '20px',
      paddingBottom: '40px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '75px',
      paddingRight: '75px',
    },

    header: {
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.navyBlue,
      marginBottom: '10px',
      paddingBottom: '20px',
    },

    table: {
      textAlign: 'left',
      display: 'flex',
      fontSize: '16px',
      width: '100%',
      padding: '20px 0px',
    },
    editBtn: {
      fontSize: '16px',
      color: Colors.linkBlue,
      textDecoration: 'none',
      marginLeft: '30px',
      marginTop: '0px',
      cursor: 'pointer',
      border: 'none',
      background: 'none',
      outline: 'none',
      width: '160px',
    },
    disabledLink: {
      fontSize: '16px',
      color: Colors.linkBlue,
      marginLeft: '30px',
      cursor: 'not-allowed',
      border: 'none',
      background: 'none',
      outline: 'none',
      width: '160px',
      opacity: '0.4',
    },
    tableHeader: {
      display: 'flex',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },
    itemImage: {
      width: '120px',
      height: '75px',
    },
    description: {
      marginLeft: '20px',
      width: '490px',
    },
    label: {
      fontSize: '16px',
      fontWeight: 600,
    },
    subLabel: {
      fontSize: '16px',
      color: Colors.contentText,
    },
    contentTable: {
      marginRight: '-20px',
      width: '100%',
    },

    deliveryMethodInfoPopup: {
      icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14px',
        height: '14px',
        lineHeight: '14px',
        fontSize: '16px',
        color: Colors.white,
        backgroundColor: Colors.dark,
      },

      popupContainer: {
        left: 'initial',
        right: '8px',
      },

      popupContent: {
        borderRadius: '10px 0 10px 10px',
        backgroundColor: Colors.lightenColor(Colors.lightGray, 2),
        padding: '10px 20px',
      },

      popupStem: {
        right: '0',
        top: '-19px',
        borderTop: '20px solid transparent',
        borderRight: `15px solid ${Colors.lightenColor(Colors.lightGray, 2)}`,
        borderBottom: '0 solid transparent',
        borderLeft: '0 solid transparent',
      },
    },

    deliveryMethodInfoPopupContent: {
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: 400,
      width: '240px',
    },

    column: {
      flex: 1,
    },
    description1: {
      fontSize: '16px',
      margin: '2px 0 10px',
    },
    contentHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.bodyText,
      marginBottom: '5px',
    },
    editableInput: {
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      borderBottom: `1px solid ${Colors.mediumGray3}`,
      width: '210px',
      marginLeft: '20px',
      height: '30px',
      fontSize: '16px',
      fontWeight: 400,
      color: Colors.black,
      outline: 'none',
    },

    inputLabel: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.bodyText,
      width: '140px',
      display: 'inline-block',
      margin: '5px 0px',
    },

    editableLabel: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.navyBlue,
      width: '120px',
      display: 'inline-block',
    },
    nameLabel: {
      margin: '0px 0px 0px 20px',
    },
    inputContainer: {
      margin: isEditable ? '10px 0px' : '5px',
    },
    footer: {
      fontSize: '16px',
      fontWeight: '500',
      marginTop: '10px',
    },
    footerContainer: {
      textAlign: 'right',
      paddingTop: '25px',
      borderTop: isEditable ? 'none' : `1px solid ${Colors.gutterGray}`,
    },
    button: {
      width: '210px',
      padding: '8px',
      fontSize: '16px',
      marginBottom: '0px',
    },
    contentSelect: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    select: {
      width: '210px',
      fontSize: '16px',
      color: Colors.lightGray3,
      marginLeft: '20px',
      height: '35px',
      borderRadius: '5px',
    },
    actionLabel: {
      color: Colors.linkBlue,
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      cursor: 'pointer',
      marginLeft: '80px',
      marginTop: '3px',
      textDecoration: 'none',
    },
    deliveryContent: {
      marginTop: '20px',
      paddingBottom: '25px',
    },
    noItems: {
      textAlign: 'center',
      fontSize: '20px',
      height: '300px',
      lineHeight: '10',
    },
    placeOrderContent: {
      marginTop: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    error: {
      color: Colors.red,
      marginBottom: '40px',
    },
  };

  const { setCurrencyLabel } = RewardsConstants;

  const tableContent = shoppingCartItems.map((shoppingCartItem) => (
    <div
      style={styles.table}
      key={`${shoppingCartItem.productId}${shoppingCartItem.productId}${
        shoppingCartItem.denomination
      }${shoppingCartItem.method}`}
    >
      <span>
        <img
          src={shoppingCartItem.imageUrl}
          style={styles.itemImage}
          alt={`${shoppingCartItem.name} Card`}
          title={`${shoppingCartItem.name} Card`}
        />
      </span>
      <span style={styles.description}>
        <div style={styles.label}>{shoppingCartItem.name}</div>
      </span>
      <span style={styles.contentTable}>
        <div style={styles.contentHeader}>
          <label style={styles.column}>Denomination</label>
          <label style={styles.column}>Quantity</label>
          <label style={styles.column}>
            Delivery Method
            <InfoPopup styles={styles.deliveryMethodInfoPopup}>
              <div style={styles.deliveryMethodInfoPopupContent}>
                {shoppingCartItem.method ===
                RewardsConstants.RewardDeliveryForm.DIGITAL
                  ? 'Check your Message Center on the Healthy Rewards portal within 12 hours for your e-code(s) and redemption instructions.'
                  : 'Please allow for 7-10 business days for delivery of your gift card(s).'}
              </div>
            </InfoPopup>
          </label>
        </div>
        <div style={[styles.contentSelect, GlobalStyles.regular]}>
          <span style={[styles.column, styles.description1]}>
            {setCurrencyLabel(shoppingCartItem.denomination)}
          </span>
          <span style={[styles.column, styles.description1]}>
            {shoppingCartItem.quantity}
          </span>
          <span style={[styles.column, styles.description1]}>
            {shoppingCartItem.method ===
            RewardsConstants.RewardDeliveryForm.DIGITAL
              ? 'Email'
              : 'Standard Mail'}
          </span>
        </div>
      </span>
    </div>
  ));

  let total = 0;

  let updatedPoints = 0;

  shoppingCartItems.map((item) => {
    total += item.quantity * item.denomination;
    updatedPoints = points - total;
  });

  return (
    <DrawerContent title="Shopping Cart">
      <div style={styles.root}>
        <div>
          <div style={styles.content}>
            {shoppingCartItems.length > 0 && (
              <div>
                <div style={styles.tableHeader}>
                  <span style={[GlobalStyles.regular, styles.header]}>
                    Items In Cart
                  </span>
                  <div
                    style={[GlobalStyles.regular, styles.actionLabel]}
                    onClick={editCart}
                  >
                    Edit
                  </div>
                </div>
                <div>{tableContent}</div>
                <div style={[styles.tableHeader, { marginTop: '40px' }]}>
                  <span style={[GlobalStyles.regular, styles.header]}>
                    Delivery Information
                  </span>
                  {!isEditable ? (
                    <div
                      style={[GlobalStyles.regular, styles.actionLabel]}
                      onClick={editAddress}
                    >
                      Edit
                    </div>
                  ) : null}
                </div>
                <div style={styles.deliveryContent}>
                  <div style={styles.inputContainer}>
                    <label
                      htmlFor="name"
                      style={[
                        GlobalStyles.regular,
                        isEditable ? styles.editableLabel : styles.inputLabel,
                      ]}
                    >
                      Name:{' '}
                    </label>
                    <label
                      style={[
                        GlobalStyles.regular,
                        styles.inputLabel,
                        { fontSize: '16px' },
                        isEditable ? styles.nameLabel : null,
                      ]}
                    >
                      {name}
                    </label>
                  </div>
                  <div style={styles.inputContainer}>
                    <label
                      htmlFor="email"
                      style={[
                        GlobalStyles.regular,
                        isEditable ? styles.editableLabel : styles.inputLabel,
                      ]}
                    >
                      Email:{' '}
                    </label>
                    <label
                      style={[
                        GlobalStyles.regular,
                        styles.inputLabel,
                        { fontSize: '16px' },
                        isEditable ? styles.nameLabel : null,
                      ]}
                    >
                      {emailAddress ? emailAddress : ''}
                    </label>
                  </div>
                  <div style={styles.inputContainer}>
                    <label
                      htmlFor="street"
                      style={[
                        GlobalStyles.regular,
                        isEditable ? styles.editableLabel : styles.inputLabel,
                      ]}
                    >
                      Street:{' '}
                    </label>
                    {isEditable ? (
                      <input
                        type="text"
                        id="streetAddr1"
                        style={styles.editableInput}
                        value={address && address.streetAddr1}
                        onChange={changeAddress}
                      />
                    ) : (
                      <label
                        style={[
                          GlobalStyles.regular,
                          styles.inputLabel,
                          { fontSize: '16px', width: '170px' },
                        ]}
                      >
                        {address && address.streetAddr1}
                      </label>
                    )}
                  </div>
                  <div
                    style={[
                      styles.inputContainer,
                      {
                        display:
                          (address && address.streetAddr2) || isEditable
                            ? 'block'
                            : 'none',
                      },
                    ]}
                  >
                    <label
                      htmlFor="street"
                      style={[
                        GlobalStyles.regular,
                        isEditable ? styles.editableLabel : styles.inputLabel,
                      ]}
                    />
                    {isEditable ? (
                      <input
                        type="text"
                        id="streetAddr2"
                        style={styles.editableInput}
                        value={
                          address && address.streetAddr2 !== null
                            ? address.streetAddr2
                            : ''
                        }
                        onChange={changeAddress}
                      />
                    ) : (
                      <label
                        style={[
                          GlobalStyles.regular,
                          styles.inputLabel,
                          { fontSize: '16px' },
                        ]}
                      >
                        {address && address.streetAddr2 !== null
                          ? address.streetAddr2
                          : ''}
                      </label>
                    )}
                  </div>
                  <div style={styles.inputContainer}>
                    <label
                      htmlFor="city"
                      style={[
                        GlobalStyles.regular,
                        isEditable ? styles.editableLabel : styles.inputLabel,
                      ]}
                    >
                      City:{' '}
                    </label>
                    {isEditable ? (
                      <input
                        type="text"
                        id="city"
                        style={styles.editableInput}
                        value={address && address.city}
                        onChange={changeAddress}
                      />
                    ) : (
                      <label
                        style={[
                          GlobalStyles.regular,
                          styles.inputLabel,
                          { fontSize: '16px' },
                        ]}
                      >
                        {address && address.city}
                      </label>
                    )}
                  </div>
                  <div
                    style={[
                      styles.inputContainer,
                      { display: 'flex', alignItems: 'baseline' },
                    ]}
                  >
                    <label
                      htmlFor="state"
                      style={[
                        GlobalStyles.regular,
                        isEditable ? styles.editableLabel : styles.inputLabel,
                      ]}
                    >
                      State :{' '}
                    </label>
                    {isEditable ? (
                      <select
                        value={address && address.state}
                        style={styles.select}
                        id="state"
                        onChange={changeAddress}
                      >
                        {AppConstants.States.map((state) => (
                          <option key={state.value} value={state.value}>
                            {state.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <label
                        style={[
                          GlobalStyles.regular,
                          styles.inputLabel,
                          { fontSize: '16px' },
                        ]}
                      >
                        {address && address.state}
                      </label>
                    )}
                  </div>
                  <div style={styles.inputContainer}>
                    <label
                      htmlFor="zipcode"
                      style={[
                        GlobalStyles.regular,
                        isEditable ? styles.editableLabel : styles.inputLabel,
                      ]}
                    >
                      ZIP Code :{' '}
                    </label>
                    {isEditable ? (
                      <input
                        type="text"
                        id="zip"
                        style={styles.editableInput}
                        value={address && address.zip}
                        onChange={changeAddress}
                      />
                    ) : (
                      <label
                        style={[
                          GlobalStyles.regular,
                          styles.inputLabel,
                          { fontSize: '16px' },
                        ]}
                      >
                        {address && address.zip}
                      </label>
                    )}
                  </div>
                </div>
                {isEditable ? (
                  <div style={{ marginTop: '40px' }}>
                    {addressError && <p style={styles.error}>{addressError}</p>}
                    <Button
                      style={[
                        styles.button,
                        GlobalStyles.regular,
                        { width: '142px' },
                      ]}
                      text="Save"
                      onClick={saveAddress}
                    />
                    <button
                      style={[GlobalStyles.regular, styles.editBtn]}
                      onClick={editAddress}
                    >
                      Cancel
                    </button>
                  </div>
                ) : null}
                <div style={styles.footerContainer}>
                  <div style={[GlobalStyles.regular, styles.footer]}>
                    Card Total: {setCurrencyLabel(total)}
                  </div>
                  <div style={[GlobalStyles.regular, styles.footer]}>
                    Your Balance: {setCurrencyLabel(updatedPoints)}
                  </div>
                </div>
                <div style={styles.placeOrderContent}>
                  <Button
                    style={[
                      GlobalStyles.regular,
                      { width: '180px' },
                      styles.button,
                    ]}
                    text="Place Order"
                    onClick={() => placeOrder(updatedPoints)}
                    disabled={isEditable}
                  />
                  <button
                    onClick={closeCart}
                    disabled={isEditable}
                    style={[
                      GlobalStyles.regular,
                      { marginLeft: '80px' },
                      isEditable ? styles.disabledLink : styles.editBtn,
                    ]}
                  >
                    Continue Shopping
                  </button>
                </div>
              </div>
            )}
            {shoppingCartItems.length === 0 && (
              <div style={styles.noItems}>There are no items in your cart</div>
            )}
          </div>
        </div>
      </div>
    </DrawerContent>
  );
};

CheckoutSummaryMarkup.propTypes = {
  editAddress: PropTypes.func,
  changeAddress: PropTypes.func,
  saveAddress: PropTypes.func,
  isEditable: PropTypes.bool,
  shoppingCartItems: PropTypes.array,
  address: PropTypes.object,
  name: PropTypes.string,
  emailAddress: PropTypes.string,
  addressError: PropTypes.string,
  points: PropTypes.number,
  placeOrder: PropTypes.func,
  closeCart: PropTypes.func.isRequired,
  editCart: PropTypes.func.isRequired,
};

export default Radium(CheckoutSummaryMarkup);
