import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import Button from '../../components/common/Button';

const LearnMorePage = () => {
  const learnMoreHtml = AppConfig.getLingo('learnMoreHtml');

  const styles = {
    root: {
      position: 'relative',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    button: {
      padding: '10px 25px',
      width: '250px',
      marginTop: '30px',
    },
    link: {
      color: '#2095f3',
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '18px',

      ':hover': {
        color: Colors.darkenColor('#2095f3'),
      },

      ':active': {
        color: Colors.darkenColor('#2095f3', 2),
      },
    },
    backLink: {
      position: 'absolute',
      left: '0px',
      top: '0px',
    },
    contentBox: {
      boxShadow: '0 2px 15px 0 rgba(0,0,0,0.3)',
      backgroundColor: '#FFFFFF',
      width: '600px',
      margin: '0 auto',
      marginBottom: '40px',
      padding: '50px',
      textAlign: 'center',
      boxSizing: 'border-box',
    },
  };

  return (
    <div style={styles.root}>
      <div style={styles.backLink}>
        <Link style={styles.link} to="/">
          &lt; Back to Welcome Page
        </Link>
      </div>
      <div style={styles.contentBox}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: learnMoreHtml }} />
        {/* eslint-enable react/no-danger */}
        <Button
          style={[GlobalStyles.regular, styles.button]}
          text="Let's Get Registered"
          link="/registration/basic-information"
        />
      </div>
    </div>
  );
};

export default Radium(LearnMorePage);
