import React from 'react';
import { routerActions } from 'react-router-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import SignInPage from '../containers/sign-in/SignInPage';
import RegistrationContainer from '../containers/registration/RegisterContainer';
import { createSelector } from 'reselect';

const signInSelector = (state) => state.signIn;

const authenticating = createSelector(
  signInSelector,
  (signIn) => signIn.appLoading || signIn.appLoadFailed
);

const Loading = (
  <RegistrationContainer>
    <SignInPage />
  </RegistrationContainer>
);

const userIsAuthenticated = connectedReduxRedirect({
  authenticatedSelector: (state) =>
    state.signIn !== null &&
    state.signIn.accessToken &&
    !state.signIn.appLoading &&
    !state.signIn.appLoadFailed,
  authenticatingSelector: (state) => authenticating(state),
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated',
  redirectPath: () =>
    AppConfig.effectiveConfig.inboundSSOEnabled ? '/sso-error' : '/signin',
  allowRedirectBack: () => !AppConfig.effectiveConfig.inboundSSOEnabled,
  AuthenticatingComponent: () => Loading,
});

export default userIsAuthenticated((props) =>
  React.cloneElement(props.children)
);
