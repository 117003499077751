import React from 'react';
import PropTypes from 'prop-types';
import { WithTracking } from '@healthmine/greyhound-core/src/hocs/';
import { compose } from 'recompose';

const styles = {
  track: {
    fontSize: '16px',
    textAlign: 'center',
    margin: '10px 0',
  },
};

const TrackingGoal = ({ frequency, unit, intensity }) => (
  <p style={styles.track}>
    {intensity > 1 ? intensity : 'Track your'} {unit}
    {intensity > 1 ? ', ' : ''} {frequency}x per week
  </p>
);

TrackingGoal.propTypes = {
  frequency: PropTypes.number,
  unit: PropTypes.string,
  intensity: PropTypes.number,
  type: PropTypes.string,
  measurement: PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    actionText: PropTypes.string,
  }),
};

export default compose(WithTracking)(TrackingGoal);
