import React from 'react';
import PropTypes from 'prop-types';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import ConfigurableAction from '../actions/ConfigurableAction';

const Checkpoint = ({ selectCarePlan }) => (
  <ConfigurableAction onClick={selectCarePlan}>
    {AppConfig.effectiveConfig.incentiveCurrency ===
    AppConstants.IncentiveCurrency.POINTS
      ? 'View Points'
      : 'View Summary'}
  </ConfigurableAction>
);

Checkpoint.propTypes = {
  selectCarePlan: PropTypes.func,
};

export default Checkpoint;
