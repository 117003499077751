import React from 'react';
import IntroMarkup from '../../components/registration/IntroMarkup';

class RegistrationIntroPage extends React.Component {
  render() {
    return (
      <div>
        <IntroMarkup />
      </div>
    );
  }
}
export default RegistrationIntroPage;
