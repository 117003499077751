import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import Styles from './EmployerGroupBanner.styles';

const EmployerGroupBannerMarkup = ({
  employerGroupName,
  programName,
  employerGroupLogoUrl,
  showProgress,
  actionItemCount,
  completedActionItemCount,
}) => (
  <div style={Styles.root}>
    <div style={Styles.section}>
      {employerGroupLogoUrl ? (
        <img
          style={Styles.employerGroupLogo}
          src={AppConfig.replaceS3(employerGroupLogoUrl)}
          alt={`${employerGroupName} Logo`}
          title={`${employerGroupName} Logo`}
        />
      ) : null}
    </div>

    <div style={Styles.section}>
      <div style={Styles.welcomeStatement}>
        Welcome to{' '}
        {`${employerGroupName}'${!/s$/i.test(employerGroupName) ? 's' : ''}`}{' '}
        {programName}! The program is designed to support you with your care
        management and help you lower your out-of-pocket expenses.
      </div>
    </div>

    <div style={Styles.section}>
      {showProgress ? (
        <div style={Styles.completionContainer}>
          <div style={Styles.measurementContainer}>
            <span style={Styles.measurement}>{completedActionItemCount}</span>{' '}
            of <span style={Styles.measurement}>{actionItemCount}</span>{' '}
            Completed
          </div>
          Complete all {actionItemCount} health actions to stay on track with
          your health care.
        </div>
      ) : null}
    </div>
  </div>
);

EmployerGroupBannerMarkup.propTypes = {
  employerGroupName: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired,
  employerGroupLogoUrl: PropTypes.string,
  showProgress: PropTypes.bool.isRequired,
  actionItemCount: PropTypes.number.isRequired,
  completedActionItemCount: PropTypes.number.isRequired,
};

export default Radium(EmployerGroupBannerMarkup);
