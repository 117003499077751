import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { compose, withProps } from 'recompose';
import PageHeader from '../../components/common/pageHeader';
import Radium from 'radium';
import AutomatedRewards from '../sweepstakes/automated-rewards/AutomatedRewards';

const AutoReward = ({ currentPathname }) => (
  <div>
    <PageHeader currentPathname={currentPathname} />
    <div style={styles.container}>
      <AutomatedRewards registration />
    </div>
  </div>
);

AutoReward.propTypes = {
  currentPathname: PropTypes.string,
};

const styles = {
  container: {
    width: '900px',
    margin: '0 auto',
    border: '1px solid',
    padding: '40px',
    borderRadius: '5px',
    background: '#fff',
  },
};

export default compose(
  withProps((props) => ({
    currentPathname: _get(props, 'location.pathname', '/'),
  })),
  Radium
)(AutoReward);
