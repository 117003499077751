import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Features from '@healthmine/greyhound-core/src/constants/Features';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { ThirdPartyCookieSupportActions } from '../../actions';

class ThirdPartyCookieCheck extends React.Component {
  componentDidMount() {
    window.addEventListener('message', this._onWindowMessageReceived, false);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    window.removeEventListener('message', this._onWindowMessageReceived);
  }

  render() {
    const { assetsRootUrl } = AppConfig.effectiveConfig;

    const usesProductGroupDemo = AppConfig.validateFeature(
      Features.PRODUCT_GROUP_DEMO
    );

    const activeProductGroupId = sessionStorage.getItem('productGroup');

    const pathComponentsToRemove = usesProductGroupDemo
      ? activeProductGroupId
        ? 3
        : 2
      : 2;

    const rootEnvUrl = `${assetsRootUrl}${
      !_.endsWith(assetsRootUrl, '/') ? '/' : ''
    }`
      .split('/')
      .slice(0, -pathComponentsToRemove)
      .join('/');

    return (
      <iframe
        src={`${rootEnvUrl}/common/html/ThirdPartyCookie_Init.html`}
        style={{ display: 'none' }}
      />
    );
  }

  _onWindowMessageReceived = (evt) => {
    const messageData = _.get(evt, 'data');

    const thirdPartyCookiesSupported = /^HealthMine:ThirdPartyCookiesSupported:(True|False)$/i.exec(
      messageData
    );

    const thirdPartyCookiesSupportedStatus = _.get(
      thirdPartyCookiesSupported,
      '[1]',
      ''
    ).toLowerCase();

    if (thirdPartyCookiesSupportedStatus) {
      this.props.actions.setThirdPartyCookieSupport(
        thirdPartyCookiesSupportedStatus === 'true'
      );
    }
  };
}

ThirdPartyCookieCheck.propTypes = {
  detected: PropTypes.bool.isRequired,

  actions: PropTypes.shape({
    setThirdPartyCookieSupport: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    detected: state.thirdPartyCookieSupport.detected,
  };
}

function mapDispatchToProps(dispatch) {
  const { setThirdPartyCookieSupport } = ThirdPartyCookieSupportActions;

  return {
    actions: bindActionCreators({ setThirdPartyCookieSupport }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ThirdPartyCookieCheck
);
