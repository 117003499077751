import _ from 'lodash';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import OffsetCloseButton from '../common/OffsetCloseButton';
import Page from '../common/Page';
import CarePlanBarMarkup from './CarePlanBarMarkup';
import CarePlanCardMarkup from './CarePlanCardMarkup';
import DismissCarePlanMarkup from './DismissCarePlanMarkup';

const CarePlansPageMarkup = ({
  assignableCarePlans,
  myCarePlans,
  carePlanBeingDismissed,
  carePlanBeingViewed,
  onCreateUserPlan,
  onTogglePlanDetails,
  onDismissAssignedPlan,
  onCarePlanDismissed,
}) => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const styles = {
    section: {
      color: Colors.bodyText,
      overflow: 'hidden',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: Colors.gutterGray,
      borderBottomWidth: '0px',
      marginBottom: '-1px',
      width: fiveFiveUI ? '900px' : 'auto',
      backgroundColor: fiveFiveUI ? '#fff' : '',
    },

    sectionHeader: {
      fontSize: '20px',
      fontWeight: 'normal',
      backgroundColor: Colors.whiteSmoke,
      padding: '20px 0px 10px 50px',
      margin: '0px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },

    assignableCarePlansContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    noAssignableCarePlans: {
      fontSize: '16px',
      width: '100%',
      maxWidth: '75%',
      margin: '30px auto',
    },

    myCarePlansContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0px -2px 0px 0px',
    },

    noCarePlansStatement: {
      marginTop: '50px',
      fontSize: '28px',
      color: Colors.contentText,
      alignSelf: 'center',
    },
  };

  const assignableCarePlanCount = _.size(assignableCarePlans);

  const assignedCarePlanCount = _.size(myCarePlans);

  return (
    <Page title="Add/Change Care Plans" icon="thin-0205_bullets_list">
      <OffsetCloseButton />

      {assignableCarePlanCount || assignedCarePlanCount ? (
        <div>
          <section style={styles.section}>
            <h1 style={styles.sectionHeader}>Add New Care Plan</h1>

            <div style={styles.assignableCarePlansContainer}>
              {assignableCarePlanCount ? (
                assignableCarePlans.map((carePlan, carePlanIndex) => (
                  <CarePlanBarMarkup
                    key={`NewCarePlan${carePlanIndex}`}
                    carePlan={carePlan}
                    onCreateUserPlan={onCreateUserPlan}
                  />
                ))
              ) : (
                <p style={styles.noAssignableCarePlans}>
                  Congratulations! You now have every opportunity to improve
                  your health by participating in all of the care plans that are
                  assigned to you.
                </p>
              )}
            </div>
          </section>

          {assignedCarePlanCount ? (
            <section style={styles.section}>
              <h1 style={styles.sectionHeader}>My Care Plans</h1>

              <div style={styles.myCarePlansContainer}>
                {myCarePlans.map((carePlan, carePlanIndex) => (
                  <CarePlanCardMarkup
                    key={`MyCarePlan${carePlanIndex}`}
                    carePlan={carePlan}
                    areDetailsVisible={carePlanBeingViewed === carePlan}
                    leftPosition={(carePlanIndex % 3) * 300}
                    onToggleDetails={onTogglePlanDetails}
                    onDismissAssignedPlan={onDismissAssignedPlan}
                  />
                ))}
              </div>
            </section>
          ) : null}
        </div>
      ) : (
        <span style={styles.noCarePlansStatement}>No Care Plans</span>
      )}

      {carePlanBeingDismissed ? (
        <DismissCarePlanMarkup
          carePlan={carePlanBeingDismissed}
          onCarePlanDismissed={onCarePlanDismissed}
        />
      ) : null}
    </Page>
  );
};

CarePlansPageMarkup.propTypes = {
  assignableCarePlans: PropTypes.arrayOf(PropTypes.object),
  myCarePlans: PropTypes.arrayOf(PropTypes.object),

  carePlanBeingDismissed: PropTypes.object,
  carePlanBeingViewed: PropTypes.object,
  onCreateUserPlan: PropTypes.func.isRequired,
  onTogglePlanDetails: PropTypes.func.isRequired,
  onDismissAssignedPlan: PropTypes.func.isRequired,
  onCarePlanDismissed: PropTypes.func.isRequired,
};

export default Radium(CarePlansPageMarkup);
