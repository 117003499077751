import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import BottomNavButtons from '../../components/common/BottomNavButtons';
import Item from '../common/Item';
import {
  Colors,
  AppConfig,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import FloatingLabel from '../common/FloatingLabel';
import Loading from '../sign-in/Loading';

const VerifyMfaMarkup = ({
  isLoading,
  isVerified,
  mfaCode,
  errorMessage,
  onMfaCodeChange,
  onVerifyMfaCode,
}) => {
  const styles = {
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    loadingBox: {
      border: '1px solid',
      borderRadius: '6px',
      width: '300px',
      height: '300px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loadingText: {
      fontSize: '24px',
      margin: '0 0 40px',
      color: Colors.bodyText,
      textAlign: 'center',
    },
    content: {
      margin: '40px auto',
      alignItems: 'center',
    },
    headerCard: {
      alignItems: 'center',
      flexDirection: 'row',
      display: 'flex',
    },
    headerCardTitle: { margin: '0px', fontWeight: 'normal', fontSize: '22px' },
    headerCardText: { marginTop: '8px', fontSize: '14px' },
    headerCardIcon: {
      color: Colors.hexToRgba(Colors.white, 0.81),
      backgroundColor: Colors.black,
      width: '35px',
      height: '35px',
      fontSize: '35px',
      borderRadius: '50%',
      padding: '20px',
      margin: '0 15px 20px',
    },
    instructionContainer: {
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    instructionText: {
      color: Colors.lightSlateGray,
    },
    bottomLinks: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '60px',
      marginBottom: '150px',
      marginLeft: '-105px',
    },
    errorMessage: {
      color: Colors.red,
    },
  };

  return (
    <div style={styles.content}>
      {isLoading &&
        isVerified && (
          <h2 style={[GlobalStyles.extraLight, styles.loadingText]}>{`Loading ${
            AppConfig.effectiveConfig.clientDisplayName
          }!`}</h2>
        )}
      {isLoading && (
        <div style={styles.loadingContainer}>
          <div style={styles.loadingBox}>
            <Loading />
          </div>
        </div>
      )}

      {!isLoading && (
        <>
          <div style={styles.headerCard}>
            <Item
              iconStyle={styles.headerCardIcon}
              iconClass="thin-0640_security_lock_protected"
              keep
            />
            <div>
              <h1 style={styles.headerCardTitle}>
                Two-Step Verification Set Up
              </h1>
              <p style={styles.headerCardText}>
                Protects your account by adding an extra layer of security.
              </p>
            </div>
          </div>
          <div style={styles.instructionContainer}>
            <p style={styles.instructionText}>
              Enter the 6-digit code generated by your authentication app.
            </p>
            <div>
              <FloatingLabel
                type="number"
                value={mfaCode}
                onChange={onMfaCodeChange}
                id="mfaCode"
                name="mfaCode"
              />
            </div>
            {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}
            <div style={styles.bottomLinks}>
              <BottomNavButtons backDisabled onClickForw={onVerifyMfaCode} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

VerifyMfaMarkup.propTypes = {
  isLoading: PropTypes.bool,
  isVerified: PropTypes.bool,
  mfaCode: PropTypes.string,
  errorMessage: PropTypes.string,
  onMfaCodeChange: PropTypes.func,
  onVerifyMfaCode: PropTypes.func,
};

export default Radium(VerifyMfaMarkup);
