export const types = {
  SHOW_TUTORIAL: 'tutorial/show',
  HIDE_TUTORIAL: 'tutorial/hide',
  SET_TUTORIAL_TYPE: 'tutorial/setType',
};

function showTutorial() {
  return {
    type: types.SHOW_TUTORIAL,
    show: false,
  };
}

function hideTutorial() {
  return {
    type: types.HIDE_TUTORIAL,
    show: false,
  };
}

function setTutorialPage(page) {
  return {
    type: types.SET_TUTORIAL_TYPE,
    page,
  };
}

export default {
  showTutorial,
  hideTutorial,
  setTutorialPage,
};
