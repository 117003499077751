import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { Subjects as CarePlanTypes } from '@healthmine/greyhound-core/src/constants/CarePlan';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import CarePlanDetailsMarkup from './CarePlanDetailsMarkup';

const CarePlansCardMarkup = ({
  carePlan,
  areDetailsVisible,
  leftPosition,
  //  eslint-disable-next-line no-unused-vars
  onToggleDetails,
  onDismissAssignedPlan,
}) => {
  const { subject, status } = carePlan;

  const isPreventiveCarePlan = subject === CarePlanTypes.PREVENTIVE;

  const isPlanAssigned = status === AppConstants.PlanStatus.ASSIGNED;

  const styles = {
    root: {
      position: 'relative',
      flex: '0 0 300px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxSizing: 'border-box',
      color: Colors.bodyText,
      height: areDetailsVisible ? '500px' : '250px',
      fontWeight: '200',
      fontSize: '16px',
    },

    card: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '250px',
      maxHeight: '250px',
      boxSizing: 'border-box',
      borderRight: `1px solid ${Colors.gutterGray}`,
      borderBottom: `1px solid ${
        areDetailsVisible ? 'transparent' : Colors.gutterGray
      }`,
      padding: '20px 40px 15px',
    },

    dismissLink: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      right: '15px',
      top: '15px',
      color: Colors.navyBlue,
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: '300',
      lineHeight: '15px',
    },

    dismissIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '5px',
      height: '14px',
      width: '14px',
      border: `1px solid ${Colors.navyBlue}`,
      borderRadius: '50%',
      backgroundColor: 'transparent',
    },

    dismissIcon: {
      fontSize: '16px',
    },

    header: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'stretch',
      alignItems: 'center',
      marginLeft: '10px',
    },

    iconBubble: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '76px',
      minWidth: '76px',
      height: '76px',
      minHeight: '76px',
      borderRadius: '50%',
      borderWidth: '2px',
      borderStyle: 'solid',

      borderColor: isPreventiveCarePlan ? Colors.pink : Colors.lightGreen,

      backgroundColor:
        isPreventiveCarePlan || !isPlanAssigned
          ? isPreventiveCarePlan
            ? Colors.pink
            : Colors.lightGreen
          : 'transparent',
    },

    icon: {
      fontSize: '40px',
      fontWeight: '100',
      marginRight: '-3px',

      color:
        isPreventiveCarePlan || !isPlanAssigned
          ? Colors.white
          : Colors.lightGreen,
    },

    name: {
      marginLeft: '15px',
      fontWeight: '400',
    },

    content: {
      marginTop: '10px',
      color: Colors.contentText,
    },

    footer: {
      marginTop: 'auto',
      marginBottom: '0px',
      width: '100%',
      /***********************************************************************
         *  Jason Cone, 20170426
         *    The following is commented out because of some last-minute changes
         *    that were implemented to simplify HI-897. Additional changes were
         *    made below in the markup.
         *
        textAlign     : isPlanAssigned ? 'left' : 'right',
         **********************************************************************/
      textAlign: 'left',
    },

    configureLink: {
      color: Colors.highlightBlue,
      cursor: 'pointer',
      textDecoration: 'none',
    },

    detailLink: {
      color: Colors.contentText,
      cursor: 'pointer',
      marginRight: '-25px',
    },

    detailCaret: {
      position: 'relative',
      marginLeft: '10px',
      top: '1px',
      height: '12px',
      width: '12px',
      fontSize: '16px',
      border: `1px solid ${Colors.contentText}`,
      borderRadius: '50%',
      backgroundColor: areDetailsVisible ? Colors.contentText : 'transparent',
      color: areDetailsVisible ? Colors.white : Colors.contentText,
    },
  };

  return (
    <div style={styles.root}>
      <div style={styles.card}>
        {isPlanAssigned ? (
          <a
            style={styles.dismissLink}
            onClick={() => onDismissAssignedPlan(carePlan)}
          >
            <span style={styles.dismissIconContainer}>
              <i className="material-icons" style={styles.dismissIcon}>
                clear
              </i>
            </span>
            Dismiss
          </a>
        ) : null}
        <div style={styles.header}>
          <span style={styles.iconBubble}>
            <i className={carePlan.iconClass} style={styles.icon} />
          </span>

          <span style={styles.name}>{carePlan.name}</span>
        </div>

        <div style={styles.content}>{carePlan.description}</div>

        <div style={styles.footer}>
          {/***********************************************************************
            *  Jason Cone, 20170426
            *    The following is commented out because of some last-minute changes
            *    that were implemented to simplify HI-897. Additional changes were
            *    made below in the markup.
            isPlanAssigned
              ?
              <Link
                to={`/care-plan-build/${carePlan.userPlanId}`}
                style={styles.configureLink}
              >
                Configure Now
              </Link>

              :
              <a
                style={styles.detailLink}
                onClick={() => onToggleDetails(carePlan)}
              >
                {areDetailsVisible ? 'Hide' : 'View'} Care Plan Details
                <i className="material-icons" style={styles.detailCaret}>{areDetailsVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
              </a>
            **********************************************************************/}
          <Link
            to={`/care-plan${isPlanAssigned ? '-build' : ''}/${
              carePlan.subject
            }`}
            style={styles.configureLink}
          >
            {isPlanAssigned ? 'Configure Now' : 'View Progress'}
          </Link>
        </div>
      </div>

      {areDetailsVisible ? (
        <CarePlanDetailsMarkup
          carePlan={carePlan}
          leftPosition={leftPosition}
        />
      ) : null}
    </div>
  );
};

CarePlansCardMarkup.propTypes = {
  carePlan: PropTypes.object.isRequired,
  areDetailsVisible: PropTypes.bool.isRequired,
  leftPosition: PropTypes.number.isRequired,
  onToggleDetails: PropTypes.func.isRequired,
  onDismissAssignedPlan: PropTypes.func.isRequired,
};

export default Radium(CarePlansCardMarkup);
