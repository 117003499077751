import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { MessageCenterActions } from '@healthmine/greyhound-core/src/actions';
import { MessageCenterConstants } from '@healthmine/greyhound-core/src/constants';
import HeaderMenu from './HeaderMenu';
import MessagesMenuMarkup from './MessagesMenuMarkup';
import { Mail } from '../../styles/uhc-icons';

class MessagesMenu extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      viewedMessage: null,
    };
  }

  componentWillMount() {
    this.props.actions.getUnreadMessageCount();
    this.props.actions.getMessages();
  }

  render() {
    const { viewedMessage } = this.state;

    const { unreadMessageCount, displayedMessages } = this.props;

    return (
      <HeaderMenu
        menuId="MessagesMenu"
        menuType="secondary"
        primaryRoute="/messages"
        iconClassName="thin-0275_chat_message_comment_bubble_typing"
        uhcIcon={Mail}
        iconBubbleContent={(unreadMessageCount || '').toString()}
        activeRule={/\/messages/}
      >
        <MessagesMenuMarkup
          displayedMessages={displayedMessages}
          viewedMessage={viewedMessage}
          onViewMessage={this._onViewMessage}
          onViewedMessageClosed={this._onViewedMessageClosed}
        />
      </HeaderMenu>
    );
  }

  _onViewMessage = (message) => {
    this.setState({
      viewedMessage: message,
    });
  };

  _onViewedMessageClosed = () => {
    this.setState({
      viewedMessage: null,
    });
  };
}

MessagesMenu.propTypes = {
  actions: PropTypes.shape({
    getUnreadMessageCount: PropTypes.func.isRequired,
    getMessages: PropTypes.func.isRequired,
  }).isRequired,

  unreadMessageCount: PropTypes.number.isRequired,
  displayedMessages: PropTypes.array.isRequired,
};

const messageCenterSelector = (state) => state.messageCenter;

const propSelector = createSelector(messageCenterSelector, (messageCenter) => ({
  unreadMessageCount: messageCenter.unreadMessageCount,
  displayedMessages: _(messageCenter.messages)
    .filter(
      (message) =>
        message.status !== MessageCenterConstants.MessageStatus.DELETE
    )
    .take(4)
    .value(),
}));

function mapStateToProps(state) {
  return propSelector(state);
}

function mapDispatchToProps(dispatch) {
  const { getUnreadMessageCount, getMessages } = MessageCenterActions;

  return {
    actions: bindActionCreators(
      {
        getUnreadMessageCount,
        getMessages,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesMenu);
