import _ from 'lodash';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import { compose, pure, setPropTypes } from 'recompose';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import Button from '../common/Button';
import { Question } from '../common/questionnaire';
import Styles from './SatisfactionSurvey.styles';

export default compose(
  pure,
  Radium,

  setPropTypes({
    isSurveyOpen: PropTypes.bool,
    canSubmit: PropTypes.bool.isRequired,
    hasSubmitted: PropTypes.bool.isRequired,

    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(_.values(AppConstants.QuestionType)),
        question: PropTypes.objectOf(PropTypes.string).isRequired,

        answerOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            option: PropTypes.objectOf(PropTypes.string).isRequired,
            score: PropTypes.number.isRequired,
            displayOrder: PropTypes.number.isRequired,
          })
        ),
      })
    ),

    toggleSurveyVisibility: PropTypes.func.isRequired,
    onQuestionAnswered: PropTypes.func.isRequired,
    onSubmitSurvey: PropTypes.func.isRequired,
  })
)(
  ({
    isSurveyOpen,
    canSubmit,
    hasSubmitted,
    questions,
    toggleSurveyVisibility,
    onQuestionAnswered,
    onSubmitSurvey,
    ...props
  }) => (
    <div
      style={[
        Styles.surveyContainer,

        _.size(questions) ? Styles.availableSurvey : null,

        _.isBoolean(isSurveyOpen)
          ? isSurveyOpen
            ? Styles.openedSurvey
            : Styles.closedSurvey
          : null,
      ]}
    >
      <div
        style={{
          ...Styles.collapseExpandButton,

          cursor: hasSubmitted ? 'default' : 'pointer',
        }}
        onClick={() => !hasSubmitted && toggleSurveyVisibility()}
      >
        <span style={Styles.collapseExpandButtonCaption}>
          {hasSubmitted ? 'Thank you for your feedback!' : 'Give us a hand!'}
        </span>

        {hasSubmitted ? (
          <i className="material-icons" style={Styles.submittedIndicator}>
            done
          </i>
        ) : (
          <i
            className="material-icons"
            style={Styles.collapseExpandButtonChevron}
          >
            {isSurveyOpen ? 'close' : 'expand_less'}
          </i>
        )}
      </div>

      {_.size(questions) ? (
        <div style={Styles.surveyContent}>
          <div style={Styles.surveyIntro}>
            How strongly do you agree or disagree with the following statements?
          </div>

          <div style={Styles.questionsContainer}>
            <div style={Styles.questionColumn}>
              {questions
                .slice(0, 2)
                .map((question, questionIndex) => (
                  <Question
                    key={`Question_${question.id}`}
                    question={question}
                    questionNumber={questionIndex + 1}
                    value={props[`QuestionAnswer_${question.id}`]}
                    onValueChanged={(questionID, answer) =>
                      onQuestionAnswered(questionID, answer)
                    }
                  />
                ))}
            </div>

            <div style={Styles.questionColumn}>
              {questions
                .slice(2)
                .map((question, questionIndex) => (
                  <Question
                    key={`Question_${question.id}`}
                    question={question}
                    questionNumber={questionIndex + 3}
                    value={props[`QuestionAnswer_${question.id}`]}
                    onValueChanged={(questionID, answer) =>
                      onQuestionAnswered(questionID, answer)
                    }
                  />
                ))}

              <Button
                text="Submit Survey"
                style={Styles.submitButton}
                disabledStyle={Styles.disabledSubmitButton}
                disabled={!canSubmit}
                onClick={() => onSubmitSurvey()}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
);
