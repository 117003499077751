import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { shoppingCartSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import { selectAvailablePoints } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import HeaderMenu from './HeaderMenu';
import ShoppingCartMenuMarkup from './ShoppingCartMenuMarkup';

class ShoppingCartMenu extends React.Component {
  render() {
    const {
      cartItemCount,
      availablePoints,
      shoppingCartItems,
      redemptionStatus,
    } = this.props;

    const isCartEmpty = !!(
      shoppingCartItems.length === 0 || redemptionStatus === 'COMPLETED'
    );

    const bubbleCount = isCartEmpty ? '' : (cartItemCount || '').toString();

    return (
      <HeaderMenu
        menuId="ShoppingCartMenu"
        menuType="secondary"
        primaryRoute="/rewards/checkout"
        iconClassName="thin-0439_shopping_cart_basket_store"
        iconBubbleContent={bubbleCount}
        activeRule={/\/rewards\/(?!summary)/}
      >
        <ShoppingCartMenuMarkup
          userPoints={availablePoints}
          shoppingCartItems={shoppingCartItems}
          redemptionStatus={redemptionStatus}
        />
      </HeaderMenu>
    );
  }
}

ShoppingCartMenu.propTypes = {
  availablePoints: PropTypes.number,
  userPlans: PropTypes.object,
  cartItemCount: PropTypes.number.isRequired,
  shoppingCartItems: PropTypes.array,
  redemptionStatus: PropTypes.string,
};

function mapStateToProps(state) {
  const shoppingCartItems = shoppingCartSelector(state);

  return {
    shoppingCartItems,
    availablePoints: selectAvailablePoints(state),
    cartItemCount: state.rewards.shoppingCart.length,
    redemptionStatus: state.rewards.redemptionStatus,
  };
}

export default connect(mapStateToProps)(ShoppingCartMenu);
