import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { withTheme } from '@material-ui/core/styles';
import { matchesProperty } from 'lodash/fp';
import { MessageCenterConstants } from '@healthmine/greyhound-core/src/constants';
import { withSecureMessages } from '@healthmine/greyhound-core/src/hocs';
import { withDrawer } from '../../common/drawer2';
import PageSection from '../../common/page/PageSection';
import MessageList from './MessageList';
import MessageDrawerContent from './MessageDrawerContent';

const {
  MessageStatus: { READ, UNREAD, DELETE: ARCHIVED },
} = MessageCenterConstants;
const statusIs = matchesProperty('status');

const Messages = ({ theme, openMessageDrawer, allSecureMessages }) => {
  const readHeader = {
    title: 'Read',
    emptyText: 'You have no read messages',
    color: theme.palette.primary.main,
    countNote: 'messages',
  };
  const readMessages = allSecureMessages.filter(statusIs(READ));
  const unreadHeader = {
    title: 'Unread',
    emptyText: 'You have no unread messages',
    color: theme.palette.common.grannySmith,
    countNote: 'new messages',
  };
  const unreadMessages = allSecureMessages.filter(statusIs(UNREAD));
  const archivedHeader = {
    title: 'Archived',
    emptyText: 'You have no archived messages',
    color: theme.palette.primary.main,
    countNote: 'messages',
  };
  const archivedMessages = allSecureMessages.filter(statusIs(ARCHIVED));

  return (
    <PageSection title="Messages">
      <MessageList
        header={unreadHeader}
        messages={unreadMessages}
        openDrawer={openMessageDrawer}
      />
      <MessageList
        header={readHeader}
        messages={readMessages}
        openDrawer={openMessageDrawer}
      />
      <MessageList
        header={archivedHeader}
        messages={archivedMessages}
        openDrawer={openMessageDrawer}
      />
    </PageSection>
  );
};

Messages.propTypes = {
  drawer: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  openMessageDrawer: PropTypes.func.isRequired,
  allSecureMessages: PropTypes.array.isRequired,
};

export default compose(
  pure,
  withDrawer,
  withSecureMessages,
  lifecycle({
    componentWillMount() {
      this.props.getMessages();
    },
  }),
  withHandlers({
    openMessageDrawer: ({ drawer }) => (drawerProps) => {
      drawer.open(MessageDrawerContent, drawerProps);
    },
  }),
  withTheme()
)(Messages);
