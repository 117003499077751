import Radium from 'radium';
import React from 'react';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import CompliancePeriodSelector from './CompliancePeriodSelector';
import Styles from './MyCarePlan.styles';

const MyCarePlanNotEnrolledMarkup = () => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const maxWidth = fiveFiveUI ? '800px' : '900px';
  return (
    <div style={{ maxWidth }}>
      <div style={Styles.title}>
        <span style={Styles.programLabel}>Program Enrollment</span>
        <CompliancePeriodSelector />
      </div>

      <p style={Styles.message}>
        You are currently not enrolled in a health management program.
      </p>
    </div>
  );
};

export default Radium(MyCarePlanNotEnrolledMarkup);
