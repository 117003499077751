import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import EducationGroupMarkup from './EducationGroupMarkup';
import _ from 'lodash';
import { SUGGESTED_SEARCHES } from '@healthmine/greyhound-core/src/constants/Education';

const SuggestedEducationMarkup = ({
  suggestions,
  expanded,
  setFrameLink,
  toggleExpansion,
}) => {
  const transform = (array) => {
    let newArray = [];
    _.forEach(array, (item) => {
      delete item.data;
      delete item.contentType;
      delete item.contentTypeValue;
      _.forEach(_.values(item), (obj) => {
        newArray.push(obj);
      });
    });

    return newArray;
  };

  return (
    <div>
      {SUGGESTED_SEARCHES.map((topic) => {
        if (suggestions[_.camelCase(topic)] !== undefined)
          return (
            <EducationGroupMarkup
              key={topic}
              title={topic}
              items={transform(suggestions[_.camelCase(topic)])}
              expanded={expanded}
              setFrameLink={setFrameLink}
              toggleExpansion={toggleExpansion}
            />
          );
      })}
    </div>
  );
};

SuggestedEducationMarkup.propTypes = {
  suggestions: PropTypes.object,
  expanded: PropTypes.string,
  setFrameLink: PropTypes.func,
  toggleExpansion: PropTypes.func,
};

export default Radium(SuggestedEducationMarkup);
