import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { lowerCase } from 'lodash';
import {
  DrawerContent,
  DrawerSectionHeader,
  withDrawer,
  DrawerNavigation,
} from '../../../../common/drawer2';
import StopTracking from '../StopTracking';
import TrackerHeader from './TrackerHeader';
import TrackerManualEntry from './TrackerManualEntry';
import TrackerTotalSteps from './TrackerTotalSteps';
import TrackerHistory from './TrackerHistory';
import ManageDevice from '../ManageDevice';
import HowToEarnPointsDrawer from '../HowToEarnPoints';
import { selectNewestSharedAnswers } from '@healthmine/greyhound-core/src/selectors/HraSelectors';

const TrackerDrawer = ({
  tracker,
  stopTracking,
  howToEarnPoints,
  sharedAnswers,
}) => {
  const { type, displayName } = tracker;

  return (
    <DrawerContent
      title={`${displayName} Tracker`}
      headerAction={{
        label: 'Remove tracker',
        color: 'cosmopolitan',
        onClick: stopTracking,
      }}
    >
      <TrackerHeader tracker={tracker} sharedAnswers={sharedAnswers} />
      <TrackerManualEntry tracker={tracker} />
      <DrawerSectionHeader title="Device Settings" tracker={tracker} />
      <ManageDevice tracker={tracker} />
      <DrawerSectionHeader title="Points" />
      <DrawerNavigation
        title={`How I earn points for tracking ${lowerCase(displayName)}`}
        onClick={howToEarnPoints}
      />
      <DrawerSectionHeader title="History" />
      {type === 'STEPS' && <TrackerTotalSteps tracker={tracker} />}
      <TrackerHistory tracker={tracker} />
    </DrawerContent>
  );
};

TrackerDrawer.propTypes = {
  drawer: PropTypes.object.isRequired,
  tracker: PropTypes.object.isRequired,
  stopTracking: PropTypes.func.isRequired,
  howToEarnPoints: PropTypes.func.isRequired,
  currentWeekData: PropTypes.object,
  data: PropTypes.array,
  sharedAnswers: PropTypes.object,
};

export default compose(
  pure,
  withDrawer,
  connect((state) => {
    const { tracking, deviceCredentials } = state;
    const sharedAnswers = selectNewestSharedAnswers(state);
    return {
      tracking,
      deviceCredentials,
      sharedAnswers,
    };
  }),
  withHandlers({
    stopTracking: ({ drawer, tracker }) => () => {
      drawer.open(StopTracking, { tracker });
    },
    howToEarnPoints: ({ drawer, tracker }) => () => {
      drawer.open(HowToEarnPointsDrawer, { tracker });
    },
  })
)(TrackerDrawer);
