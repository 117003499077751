import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { GlobalStyles, Colors } from '@healthmine/greyhound-core/src/modules';
import CarePlans from '@healthmine/greyhound-core/src/modules/CarePlans';
import _ from 'lodash';

const Detailed = ({ carePlan }) => {
  const styles = {
    container: {
      minHeight: '200px',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
    },
    titleSection: {
      marginBottom: '40px',
      display: 'flex',
    },
    moduleSection: {
      marginBottom: '20px',
      display: 'flex',
    },
    sectionHeader: {
      fontSize: '16px',
      color: Colors.bodyText,
      paddingBottom: '10px',
    },
    pointRow: {
      fontSize: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '8px',
    },
    pointLabel: {
      color: Colors.contentText,
    },

    pointValueContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingRight: '0px',
      position: 'relative',
    },

    column: {
      paddingLeft: '20px',
      paddingRight: '20px',
      flex: 1,
      flexDirection: 'column',
    },

    completionBadge: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      display: 'flex',
      height: '14px',
      width: '14px',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Colors.linkBlue,
      border: '1px solid #fff',
    },

    completionBadgeIcon: {
      backgroundColor: 'transparent',
      color: Colors.white,
      fontSize: '16px',
    },
  };

  const pointsProfile = CarePlans.getPointsProfile(carePlan);

  const goalPointProfile = _.get(pointsProfile, 'goal');

  const goalProfileHeading = _.get(goalPointProfile, 'heading');

  const goalRequirement = _.head(_.get(goalPointProfile, 'requirements'));

  const clinicalItem = _.get(pointsProfile, 'clinical');

  const trackingItems = _.get(pointsProfile, 'tracking', []);

  const educationItem = _.get(pointsProfile, 'education');

  const coachingItems = _.get(pointsProfile, 'coaching');

  const actionProfileItems = _([
    clinicalItem,
    ...trackingItems,
    educationItem,
    coachingItems,
  ])
    .filter()
    .chunk(2)
    .value();

  return (
    <div style={[GlobalStyles.regular, styles.container]}>
      {goalProfileHeading &&
        goalRequirement && (
          <div style={styles.titleSection}>
            <div style={styles.column}>
              <div style={styles.sectionHeader}>{goalProfileHeading}</div>

              <div style={styles.pointRow}>
                <span style={styles.pointLabel}>
                  {goalRequirement.description}:
                </span>
                <span
                  style={[
                    styles.pointValueContainer,
                    goalRequirement.isCompleted
                      ? { paddingRight: '26px' }
                      : null,
                  ]}
                >
                  {goalRequirement.pointsDisplay}
                  {goalRequirement.isCompleted ? (
                    <div style={styles.completionBadge}>
                      <i
                        className="material-icons"
                        style={styles.completionBadgeIcon}
                      >
                        done
                      </i>
                    </div>
                  ) : null}
                </span>
              </div>
            </div>

            <div style={styles.column} />
          </div>
        )}
      {actionProfileItems.map((item, i) => (
        <div style={styles.moduleSection} key={`chunk${i}`}>
          {item.map((section, sectionIndex) => {
            return (
              <div style={styles.column} key={`itemChunk_${i}_${sectionIndex}`}>
                <div style={styles.sectionHeader}>{section.heading}</div>
                {section.requirements.map((requirement, requirementIndex) => (
                  <div
                    style={styles.pointRow}
                    key={`requirement_${i}_${sectionIndex}_${requirementIndex}`}
                  >
                    <span style={styles.pointLabel}>
                      {requirement.description}:
                    </span>
                    <span
                      style={[
                        styles.pointValueContainer,
                        requirement.isCompleted
                          ? { paddingRight: '26px' }
                          : null,
                      ]}
                    >
                      {requirement.pointsDisplay}
                      {requirement.isCompleted ? (
                        <div style={styles.completionBadge}>
                          <i
                            className="material-icons"
                            style={styles.completionBadgeIcon}
                          >
                            done
                          </i>
                        </div>
                      ) : null}
                    </span>
                  </div>
                ))}
              </div>
            );
          })}
          {item.length % 2 !== 0 && <div style={styles.column} />}
        </div>
      ))}
    </div>
  );
};

Detailed.propTypes = {
  carePlan: PropTypes.object,
};

export default Radium(Detailed);
