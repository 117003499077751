export const HealthOverviewDrawerRouteMapping = {};
export const IncentivesDrawerRouteMapping = {};

export const registerHealthOverviewDrawerRoute = (route, component) => {
  HealthOverviewDrawerRouteMapping[route] = component;
};

export const registerIncentivesDrawerRoute = (route, component) => {
  IncentivesDrawerRouteMapping[route] = component;
};

export const getHealthOverviewDrawerRouteComponent = (route) =>
  HealthOverviewDrawerRouteMapping[route];

export const getIncentivesDrawerRouteComponent = (route) =>
  IncentivesDrawerRouteMapping[route];
