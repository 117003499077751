import React from 'react';
import {
  DrawerContent,
  DrawerSection,
  DrawerButton,
  withDrawer,
} from '../../../common/drawer2';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure, withHandlers, withStateHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { HraActions } from '@healthmine/greyhound-core/src/actions';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import * as HraSelectors from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import { Question } from '../../../../../components/common/questionnaire';
import { Grid } from '@material-ui/core';
import Typography from '../../../common/Typography';
import HraResults from './HraResults';

const HraSurvey = ({
  canSubmit,
  questions,
  onQuestionAnswered,
  onSubmitSurvey,
  ...props
}) => {
  return (
    <DrawerContent title="Health Risk Assessment">
      <DrawerSection grow>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Typography type="h3">
              How strongly do you agree or disagree with the following
              statements?
            </Typography>
          </Grid>

          {questions.map((question, questionIndex) => (
            <Grid item key={`Question_${question.id}`}>
              <Question
                question={question}
                questionNumber={questionIndex + 1}
                value={props[`QuestionAnswer_${question.id}`]}
                onValueChanged={(questionID, answer) =>
                  onQuestionAnswered(questionID, answer)
                }
              />
            </Grid>
          ))}
        </Grid>
      </DrawerSection>
      <DrawerButton
        text="Submit Survey"
        disabled={!canSubmit}
        onClick={() => onSubmitSurvey()}
      />
    </DrawerContent>
  );
};

HraSurvey.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(_.values(AppConstants.QuestionType)),
      question: PropTypes.objectOf(PropTypes.string).isRequired,

      answerOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          option: PropTypes.objectOf(PropTypes.string).isRequired,
          score: PropTypes.number.isRequired,
          displayOrder: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  onQuestionAnswered: PropTypes.func.isRequired,
  onSubmitSurvey: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withDrawer,
  connect(
    (state, ownProps) => {
      const questionnaire = _.get(state.hra, 'satisfactionQuestionnaire');

      const allQuestions = _(_.get(questionnaire, 'questions'))
        .map((question) => [question.id, question])
        .fromPairs()
        .value();

      const filteredQuestionSteps = HraSelectors.selectFilteredQuestionSteps(
        state,
        AppConstants.QuestionnaireType.HRA_SAT
      );

      const questions = _.map(filteredQuestionSteps, (step) =>
        _.get(allQuestions, _.get(step, 'questions[0]'))
      );

      return {
        questions: ownProps.questions
          ? _.size(ownProps.questions) !== _.size(questions)
            ? questions
            : ownProps.questions
          : questions,
      };
    },

    (dispatch) => {
      const { submitSatisfactionSurvey } = HraActions;

      return {
        surveyActions: bindActionCreators(
          {
            submitSatisfactionSurvey,
          },
          dispatch
        ),
      };
    }
  ),

  withStateHandlers(
    {
      canSubmit: false,
    },
    {
      enableSubmission: (state) => () => ({
        ...state,
        canSubmit: true,
      }),

      disableSubmission: (state) => () => ({
        ...state,
        canSubmit: false,
      }),

      setQuestionAnswer: (state) => (questionId, optionValue) => ({
        ...state,
        [`QuestionAnswer_${questionId}`]: optionValue,
      }),
    }
  ),

  withHandlers({
    onQuestionAnswered: ({
      questions,
      setQuestionAnswer,
      enableSubmission,
      disableSubmission,
      ...props
    }) => (questionId, optionValue) => {
      const areRequiredQuestionsAnswered = _(questions)
        .reject('optional')
        .map((question) => [
          question.id,
          _.get(props, `QuestionAnswer_${question.id}`),
        ])
        .fromPairs()
        .set(questionId, optionValue)
        .every();

      setQuestionAnswer(questionId, optionValue);

      if (areRequiredQuestionsAnswered) {
        enableSubmission();
      } else {
        disableSubmission();
      }
    },

    onSubmitSurvey: ({
      questions,
      surveyActions: { submitSatisfactionSurvey },
      disableSubmission,
      drawer,
      ...props
    }) => () => {
      const answers = _(questions)
        .map((question) => [
          question.id,
          { answers: [_.get(props, `QuestionAnswer_${question.id}`)] },
        ])
        .filter((questionPair) => _.get(questionPair, '[1].answers[0]'))
        .fromPairs()
        .value();

      disableSubmission();

      submitSatisfactionSurvey(answers).then(() => {
        drawer.open(HraResults, null, { resetStack: true });
      });
    },
  })
)(HraSurvey);
