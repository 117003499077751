import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '../card/Card';

const styles = {
  customCard: {
    marginBottom: '10px',
  },
};

const ListItem = ({ children, classes, onClick }) => (
  <Card onClick={onClick} customClass={classes.customCard}>
    {children}
  </Card>
);

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(ListItem);
