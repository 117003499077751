import { get, size } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import AppConfig from '@healthmine/greyhound-core/src/modules/AppConfig';

//  eslint-disable-next-line max-len
const IMMUNIZATION_SCHEDULE_DESC = `Childhood vaccines protect children from a number of serious and potentially life-threatening diseases such as diphtheria, measles, meningitis, polio, tetanus and whooping cough, at a time in their lives when they are most vulnerable to disease.

Immunizations are essential for disease prevention and are a critical aspect of preventable care for children.

Vaccination coverage must be maintained in order to prevent a resurgence of vaccine-preventable diseases.`;

/* eslint-disable max-len */
const IMMUNIZATION_HEALTH_ACTIONS = [
  {
    assigned: true,
    availableAmount: 0,
    childActions: [
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_DTAP',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 2000001,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          '**DTaP vaccine** can help protect your child from **diphtheria**, **tetanus**, and **pertussis**.',
        recommended: true,
        shortDescription: '',
        title: 'Diptheria, Tetanus, & Pertussis (DTaP)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_IPV',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 2000002,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          '**IPV vaccine** can protect people from **polio**, which is a virus that is spread mainly by person-to-person contact.',
        recommended: true,
        shortDescription: '',
        title: 'Polio (IPV)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_MMR',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 2000003,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          '**Measles**, **mumps**, and **rubella** are viral diseases that can have serious consequences. Before vaccines, these diseases were very common in the United States, especially among children. They are still common in many parts of the world.',
        recommended: true,
        shortDescription: '',
        title: 'Measles, Mumps, & Rubella (MMR)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_HIB',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 2000004,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          '_Haemophilus influenzae_ type b (Hib) disease is a serious disease caused by bacteria.  It usually affects children under 5 years old. It can also affect adults with certain medical conditions.',
        recommended: true,
        shortDescription: '',
        title: 'Haemophilus Influenzae Type B (HiB)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_HEP_B',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 2000005,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription: `Hepatitis B is a serious disease caused by a virus that attacks the liver. The virus, which is called hepatitis B virus (HBV), can cause lifelong infection, cirrhosis (scarring) of the liver, liver cancer, liver failure, and death.

Hepatitis B vaccine is available for all age groups to prevent HBV infection.`,
        recommended: true,
        shortDescription: '',
        title: 'Hepatitis B (HepB)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_VZV',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 2000006,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          'Chickenpox is a very contagious disease caused by the varicella-zoster virus (VZV). It causes a blister-like rash, itching, tiredness, and fever.',
        recommended: true,
        shortDescription: '',
        title: 'Chickenpox (VZV)',
      },
    ],
    code: 'DEMO_IMMUNIZATION_COMBO_2',
    completed: false,
    completions: [],
    compliant: false,
    displayOrder: 1,
    earnedAmount: 0,
    evidence: { conditions: [] },
    goal: '-',
    groups: [
      {
        id: 'PREVENTIVE',
        name: 'Preventive Health Actions',
        displayOrder: 2,
      },
    ],
    id: 2000001,
    incentiveAmount: 600,
    lastCompletedOn: undefined,
    longDescription: IMMUNIZATION_SCHEDULE_DESC,
    shortDescription: '',
    title: 'Childhood Immunizations (Josh, age 3)',
  },

  {
    assigned: true,
    availableAmount: 0,
    childActions: [
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_DTAP',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000001,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          '**DTaP vaccine** can help protect your child from **diphtheria**, **tetanus**, and **pertussis**.',
        recommended: true,
        shortDescription: '',
        title: 'Diptheria, Tetanus, & Pertussis (DTaP)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_IPV',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000002,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          '**IPV vaccine** can protect people from **polio**, which is a virus that is spread mainly by person-to-person contact.',
        recommended: true,
        shortDescription: '',
        title: 'Polio (IPV)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_MMR',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000003,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          '**Measles**, **mumps**, and **rubella** are viral diseases that can have serious consequences. Before vaccines, these diseases were very common in the United States, especially among children. They are still common in many parts of the world.',
        recommended: true,
        shortDescription: '',
        title: 'Measles, Mumps, & Rubella (MMR)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_HIB',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000004,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          '_Haemophilus influenzae_ type b (Hib) disease is a serious disease caused by bacteria.  It usually affects children under 5 years old. It can also affect adults with certain medical conditions.',
        recommended: true,
        shortDescription: '',
        title: 'Haemophilus Influenzae Type B (HiB)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_HEP_A',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000005,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          'Hepatitis A is a liver disease caused by the hepatitis A virus (HAV). Hepatitis A can affect anyone. Vaccines are available for long-term prevention of HAV infection in persons 1 year of age and older. Good personal hygiene and proper sanitation can also help prevent the spread of hepatitis A.',
        recommended: true,
        shortDescription: '',
        title: 'Hepatitis A (HepA)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_HEP_B',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000006,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription: `Hepatitis B is a serious disease caused by a virus that attacks the liver. The virus, which is called hepatitis B virus (HBV), can cause lifelong infection, cirrhosis (scarring) of the liver, liver cancer, liver failure, and death.

        Hepatitis B vaccine is available for all age groups to prevent HBV infection.`,
        recommended: true,
        shortDescription: '',
        title: 'Hepatitis B (HepB)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_VZV',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000007,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          'Chickenpox is a very contagious disease caused by the varicella-zoster virus (VZV). It causes a blister-like rash, itching, tiredness, and fever.',
        recommended: true,
        shortDescription: '',
        title: 'Chicken Pox (VZV)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_PCV',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000008,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          'Vaccines help prevent pneumococcal disease, which is any type of infection caused by Streptococcus pneumoniae bacteria.',
        recommended: true,
        shortDescription: '',
        title: 'Pneumococcal Conjugate (PCV)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_ROTOVIRUS',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000009,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          'Rotavirus spreads easily among infants and young children. The virus can cause severe watery diarrhea, vomiting, fever, and abdominal pain. Children who get rotavirus disease can become dehydrated and may need to be hospitalized.',
        recommended: true,
        shortDescription: '',
        title: 'Rotovirus (RV)',
      },
      {
        assigned: true,
        availableAmount: 0,
        code: 'DEMO_IMMUNIZATION_INFLUENZA',
        completed: false,
        complianceNextDueDate: '2018-12-31',
        compliant: false,
        displayOrder: 1,
        earnedAmount: 0,
        evidence: { conditions: [] },
        goal: '-',
        groups: [
          {
            id: 'PREVENTIVE',
            name: 'Preventive Health Actions',
            displayOrder: 2,
          },
        ],
        id: 10000010,
        incentiveAmount: 100,
        incentiveDescription: '',
        lastCompletedOn: undefined,
        longDescription:
          'Influenza (flu) is a contagious respiratory illness caused by influenza viruses that infect the nose, throat, and lungs. It can cause mild to severe illness, and can lead to hospitalization and death.',
        recommended: true,
        shortDescription: '',
        title: 'Flu',
      },
    ],
    code: 'DEMO_IMMUNIZATION_COMBO_10',
    completed: false,
    completions: [],
    compliant: false,
    displayOrder: 1,
    earnedAmount: 0,
    evidence: { conditions: [] },
    goal: '-',
    groups: [
      {
        id: 'PREVENTIVE',
        name: 'Preventive Health Actions',
        displayOrder: 2,
      },
    ],
    id: 10000001,
    incentiveAmount: 1000,
    lastCompletedOn: undefined,
    longDescription: IMMUNIZATION_SCHEDULE_DESC,
    shortDescription: '',
    title: 'Childhood Immunizations (Sally, age 8)',
  },
];
/* eslint-enable max-len */

export default compose(
  setPropTypes({
    preventiveActionCount: PropTypes.number.isRequired,
    preventiveActions: PropTypes.array.isRequired,
    completedPreventiveActionCount: PropTypes.number.isRequired,
    remainingPreventiveActionCount: PropTypes.number.isRequired,
  }),

  connect((state, props) => {
    const memberId = get(state, 'signIn.userId');

    const usesHedisImmunizationDemo = AppConfig.validateFeature(
      `hedis_immunization_demo_${memberId}`
    );

    const immunizationHealthActions = usesHedisImmunizationDemo
      ? IMMUNIZATION_HEALTH_ACTIONS
      : [];

    const preventiveActions = usesHedisImmunizationDemo
      ? [...immunizationHealthActions, ...props.preventiveActions]
      : props.preventiveActions;

    const preventiveActionCount = size(preventiveActions);

    const remainingPreventiveActionCount =
      props.remainingPreventiveActionCount + size(immunizationHealthActions);

    return {
      preventiveActions,
      preventiveActionCount,
      remainingPreventiveActionCount,
    };
  })
);
