import Radium, { StyleRoot } from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import ResetPasswordMarkup from '../../components/registration/ResetPasswordMarkup';
import { UserInfoActions } from '@healthmine/greyhound-core/src/actions';
import {
  AppConstants,
  ErrorConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';

class ResetPasswordPage extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      errors: [],
      validatedFields: [],
      emailAddress: {},
      password: {},
      passwordConfirm: {},
      isComplete: false,
      changeSubmitted: false,
    };
  }

  render() {
    const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);
    const styles = {
      imageContainer: {
        fontSize: '16px',
        overflowY: 'auto',
        position: 'relative',
        color: Colors.light,
        backgroundColor: leapFrogUI ? 'transparent' : Colors.light,
        overflow: 'hidden',
      },

      image: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: 'inherit',
        overflowX: 'hidden',
        overflowY: 'auto',
      },
    };

    const {
      emailAddress,
      password,
      passwordConfirm,
      isComplete,
      changeSubmitted,
    } = this.state;

    return (
      <StyleRoot
        style={[styles.imageContainer, GlobalStyles.regular]}
        className="index"
      >
        <div style={styles.image}>
          <ResetPasswordMarkup
            formState={{ emailAddress, password, passwordConfirm }}
            errors={this.state.errors}
            validatedFields={this.state.validatedFields}
            validateField={this._validateField}
            onClickHandler={this._continueClickHander}
            onEmailChange={this._setEmailAddress}
            onPasswordChange={this._setPassword}
            onPasswordConfirmChange={this._setConfirmPassword}
            isComplete={isComplete}
            changeSubmitted={changeSubmitted}
          />
        </div>
      </StyleRoot>
    );
  }

  _validateField = (feild) => {
    if (this.state.validatedFields.indexOf(feild) === -1) {
      this.setState({
        validatedFields: [...this.state.validatedFields, feild],
      });
    }
  };

  _setEmailAddress = (value) => {
    const isValid = AppConstants.EmailAddressRegEx.test(value);

    const error = !isValid
      ? localizedContent.errorMessages[ErrorConstants.ErrorType.INVALID_EMAIL]
      : null;

    const currEmailAddress = this.state.emailAddress;

    const emailAddress = {
      ...currEmailAddress,
      value,
      isValid,
      error,
    };

    this.setState({
      emailAddress,
    });
  };

  _setPassword = (value) => {
    const error = !AppConstants.PasswordRegEx.test(value)
      ? localizedContent.errorMessages[
          ErrorConstants.ErrorType.INVALID_PASSWORD_FORMAT
        ]
      : null;

    const isValid = !error;

    const currPassword = this.state.password;

    const password = {
      ...currPassword,
      value,
      isValid,
      error,
    };

    this.setState({
      password,
    });
  };

  _setConfirmPassword = (value) => {
    const error = !AppConstants.PasswordRegEx.test(value)
      ? localizedContent.errorMessages[
          ErrorConstants.ErrorType.INVALID_PASSWORD_FORMAT
        ]
      : value !== this.state.password.value
        ? localizedContent.errorMessages[
            ErrorConstants.ErrorType.PASSWORD_CONFIRMPASSWORD_MISMATCH
          ]
        : null;

    const isValid = !error;

    const currPasswordConfirm = this.state.passwordConfirm;

    const passwordConfirm = {
      ...currPasswordConfirm,
      value,
      isValid,
      error,
    };

    this.setState({
      passwordConfirm,
    });
  };

  _continueClickHander = () => {
    const { password, emailAddress } = this.state;

    const tempPassword = this.props.location.query.pw;

    this.setState({
      changeSubmitted: true,
    });

    this.props.actions
      .setUserPassword(emailAddress.value, tempPassword, password.value)
      .then((status) => {
        if (status === 200) {
          this._handleSuccess();
        } else {
          this._handleFailure();
        }
      })
      .catch(() => this._handleFailure());
  };

  _handleSuccess = () => {
    const { inboundSSOEnabled } = AppConfig.effectiveConfig;

    if (inboundSSOEnabled) {
      this.props.router.push('/reset-password-success');
    } else {
      this.setState({ isComplete: true }, () =>
        setTimeout(() => this.props.router.push('/signin/'), 2000)
      );
    }
  };

  _handleFailure = () => {
    this.setState({
      changeSubmitted: false,
      errors: ['Something went wrong. Please try again later.'],
    });
  };
}

ResetPasswordPage.propTypes = {
  actions: PropTypes.shape({
    setUserPassword: PropTypes.func.isRequired,
  }).isRequired,

  state: PropTypes.shape({
    userInfo: PropTypes.object.isRequired,
  }).isRequired,

  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state: {
      userInfo: state.userInfo,
    },
  };
}

function mapDispatchToProps(dispatch) {
  const { setUserPassword } = UserInfoActions;

  return {
    actions: bindActionCreators({ setUserPassword }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(Radium(ResetPasswordPage))
);
