import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Radium from 'radium';

const styles = {
  informationalAction: {
    fontSize: '16px',
    fontWeight: '200',
    marginBottom: '15px',
    color: Colors.bodyText,
  },
};

const InformationalAction = ({ children }) => (
  <div style={styles.informationalAction}>{children}</div>
);

InformationalAction.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Radium(InformationalAction);
