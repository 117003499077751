import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure, withHandlers, withState, withProps } from 'recompose';
import { get, isEmpty } from 'lodash/fp';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { withIntroductionExperience } from '@healthmine/greyhound-core/src/hocs';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import GinaModal from './GinaModal';
import WelcomeModal from './WelcomeModal';
import { pageConfig, linkPages } from './utils';
import {
  TrackingDrawer,
  PointsDrawer,
  TransitionDrawer,
  UpgradesDrawer,
} from './fte';
import {
  AboutYourselfDrawer,
  CommunicationDrawer,
  LiftoffDrawer,
  ReviewDrawer,
  RisksOrConditionsDrawer,
  RoutineDrawer,
} from './onboarding';
import { withDrawer } from '../common/drawer2';
import FteModal from './FteModal';
import FteFollowupModal from './FteFollowupModal';
import moment from 'moment';

const Welcome = ({
  showWelcome,
  showFTE,
  showFteFollowup,
  closeWelcomeModal,
  closeFTEModal,
  isImpersonating,
  closeFTEFollowup,
}) => (
  <div>
    {AppConfig.validateFeature(Features.NEW_FTE) ? (
      showFteFollowup ? (
        <FteFollowupModal onClose={closeFTEFollowup} open={showFteFollowup} />
      ) : (
        <FteModal open={showFTE} onClose={closeFTEModal} />
      )
    ) : (
      <WelcomeModal
        open={showWelcome}
        onClose={closeWelcomeModal}
        isOptional={isImpersonating}
      />
    )}
    {!isImpersonating && <GinaModal />}
  </div>
);

Welcome.propTypes = {
  showWelcome: PropTypes.bool.isRequired,
  showFTE: PropTypes.bool.isRequired,
  showFteFollowup: PropTypes.bool.isRequired,
  closeWelcomeModal: PropTypes.func.isRequired,
  closeFTEModal: PropTypes.func.isRequired,
  closeFTEFollowup: PropTypes.func.isRequired,
  isImpersonating: PropTypes.bool,
};

export default compose(
  pure,
  withDrawer,
  withIntroductionExperience,
  connect((state) => ({
    isImpersonating: !!get('signIn.impersonationEmail', state),
    fteQuestionnaire: get('questionnaires.FTE', state),
    fteComplete: get(
      AppConfig.validateFeature(Features.NEW_FTE)
        ? 'questionnaires.FTE.completed'
        : 'onBoarding.completed',
      state
    ),
    fteFollowupComplete: get('questionnaires.FTE_SURVEY.completed', state),
    fteCompletionDate: get(
      AppConfig.validateFeature(Features.NEW_FTE)
        ? 'questionnaires.FTE.memberQuestionnaire.endTimestamp'
        : 'onBoarding.memberQuestionnaire.endTimestamp',
      state
    ),
  })),
  withProps(
    ({
      drawer,
      tutorialWebComplete,
      completeTutorialWeb,
      onBoardingComplete,
      isNewMember,
      isImpersonating,
    }) => {
      const components = [];
      const defaultDrawerProps = { isOptional: isImpersonating };
      const defaultDrawerOptions = { large: true, disableBackdropClose: true };
      const pageFor = pageConfig(defaultDrawerProps, defaultDrawerOptions);

      if (!tutorialWebComplete) {
        if (!isNewMember) {
          components.push(pageFor(TransitionDrawer));
        }

        [
          pageFor(UpgradesDrawer),
          pageFor(PointsDrawer),
          pageFor(TrackingDrawer, { onNext: completeTutorialWeb }),
        ].forEach((x) => components.push(x));
      }

      if (
        !isImpersonating &&
        !onBoardingComplete &&
        !AppConfig.validateFeature(Features.NO_ONBOARDING)
      ) {
        [
          pageFor(LiftoffDrawer),
          pageFor(AboutYourselfDrawer),
          pageFor(RoutineDrawer),
          pageFor(RisksOrConditionsDrawer),
        ].forEach((x) => components.push(x));

        if (AppConfig.validateFeature(Features.FTE_COMMUNICATION_DEMO)) {
          components.push(pageFor(CommunicationDrawer));
        }

        components.push(pageFor(ReviewDrawer));
      }

      return { pages: linkPages(drawer, components) };
    }
  ),
  withState('showWelcome', 'updateShowWelcome', ({ pages }) => !isEmpty(pages)),
  withState(
    'showFTE',
    'updateShowFTE',
    ({ onBoardingComplete, fteComplete }) => !onBoardingComplete && !fteComplete
  ),
  withState(
    'showFteFollowup',
    'updateShowFteFollowup',
    ({ fteFollowupComplete, fteComplete, fteCompletionDate }) => {
      if (fteComplete && !fteFollowupComplete) {
        const lastSeen = localStorage.getItem('fteFollowupLastSeen');
        if (
          (moment().diff(moment(fteCompletionDate), 'days') >= 7 &&
            lastSeen === null) ||
          (lastSeen !== null &&
            moment().diff(moment(new Date(lastSeen)), 'days') >= 30)
        ) {
          return true;
        } else {
          return false;
        }
      } else return false;
    }
  ),
  withHandlers({
    closeWelcomeModal: ({ drawer, pages, updateShowWelcome }) => (skip) => {
      updateShowWelcome(false);

      if (skip) return;

      const [page] = pages;
      drawer.open(page.Component, page.props, page.options);
    },
    closeFTEModal: ({ updateShowFTE }) => () => {
      updateShowFTE(false);
    },
    closeFTEFollowup: ({ updateShowFteFollowup }) => (clear) => {
      if (clear) localStorage.removeItem('fteFollowupLastSeen');
      else localStorage.setItem('fteFollowupLastSeen', new Date());
      updateShowFteFollowup(false);
    },
  })
)(Welcome);
