import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import MemberField from './MemberField';
import _ from 'lodash';

const styles = () => ({
  root: {
    marginBottom: '32px',
  },
  header: {
    backgroundColor: '#7e7e7e',
    padding: '16px 20px',
    color: 'white',
    display: 'flex',
    fontWeight: 'bold',
  },
  name: {
    display: 'flex',
    flexGrow: '1',
  },
  lastUpdated: {
    display: 'flex',
  },
  content: {
    padding: '16px',
  },
  contentField: {
    marginBottom: '8px',
  },
});

const MemberSummary = ({
  classes,
  defaultIsSelected,
  fullName,
  memberFields,
}) => {
  if (!defaultIsSelected) return null;

  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.header}>
        <div className={classes.name}>
          {fullName !== ' ' ? fullName : 'Health Record'}
        </div>
      </div>
      <Grid container className={classes.content} spacing={8}>
        {memberFields.map(
          ({ field, value, level, units, recordedOn }, mfIdx) => (
            <Grid item xs={4} key={`member-field-${mfIdx}`}>
              <MemberField
                field={field}
                value={value}
                icon={_.get(level, 'icon')}
                units={units}
                date={recordedOn}
              />
            </Grid>
          )
        )}
      </Grid>
    </Card>
  );
};

MemberSummary.propTypes = {
  memberFields: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      level: PropTypes.object,
      units: PropTypes.string,
      field: PropTypes.string.isRequired,
      recordedOn: PropTypes.string,
    })
  ),
  fullName: PropTypes.string,
  // from withStyles
  classes: PropTypes.object.isRequired,
  defaultIsSelected: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  defaultIsSelected:
    state.demo.dependents.selected === state.demo.dependents.default,
});

export default compose(connect(mapStateToProps), withStyles(styles))(
  MemberSummary
);
