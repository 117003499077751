import React from 'react';
import PropTypes from 'prop-types';
import BottomLinks from './bottomLinks';

const BottomNavButtons = ({
  backLink,
  forwLink,
  forwText,
  disabled,
  backDisabled,
  forwDisabled,
  onClickForw,
  onClickBack,
}) => {
  const mainContainer = {
    display: 'flex',
  };

  return (
    <div style={mainContainer}>
      <BottomLinks
        link={backLink}
        onClick={onClickBack}
        text="Back"
        hideIcon
        disabled={backDisabled || disabled}
      />
      <BottomLinks
        link={forwLink}
        text={forwText}
        onClick={onClickForw}
        disabled={forwDisabled || disabled}
      />
    </div>
  );
};

BottomNavButtons.propTypes = {
  backLink: PropTypes.string,
  forwLink: PropTypes.string,
  forwText: PropTypes.string,
  disabled: PropTypes.bool,
  backDisabled: PropTypes.bool,
  forwDisabled: PropTypes.bool,
  onClickForw: PropTypes.func,
  onClickBack: PropTypes.func,
};

BottomNavButtons.defaultProps = {
  disabled: false,
};

export default BottomNavButtons;
