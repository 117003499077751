import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import BottomNavButtons from '../../components/common/BottomNavButtons';

const OptInMarkup = ({
  content,
  agreedToStatement,
  toggleStatementAgreement,
  onContinue,
}) => {
  const styles = {
    root: {
      ...GlobalStyles.regular,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '1000px',
      maxWidth: '100%',
      color: Colors.darkText,
      backgroundColor: Colors.light,
      padding: '10px 10px 10px 200px',
      marginTop: '10px',
      boxSizing: 'border-box',

      '@media screen and (max-width: 760px) and (min-width: 481px)': {
        padding: '10px 10px 10px 100px',
      },

      '@media screen and (max-width: 480px)': {
        padding: '10px 40px',
      },
    },

    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: '30px',
      maxWidth: '50%',
    },

    iconBubble: {
      marginRight: '20px',
      width: '75px',
      minWidth: '75px',
      height: '75px',
      minHeight: '75px',
      borderRadius: '50%',
      backgroundColor: Colors.bodyText,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    icon: {
      fontSize: '35px',
      color: Colors.white,
    },

    caption: {
      ...GlobalStyles.extraLight,
      fontSize: '24px',
      margin: '0px',
      color: Colors.black,
    },

    agreement: {
      ...GlobalStyles.light,
      maxWidth: '75%',
      fontSize: '16px',
    },

    agreementCheckboxContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '45px',
      maxHeight: '45px',
      color: Colors.navyBlue,
      fontSize: '16px',
      fontWeight: '300',
    },

    bottomLinks: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '60px',
      marginBottom: '110px',
    },
  };

  return (
    <div style={styles.root}>
      <div style={styles.container}>
        <div style={styles.title}>
          <div style={styles.iconBubble}>
            <i
              className="thin-0005_write_pen_handwriting_signing"
              style={styles.icon}
            />
          </div>

          <h2 style={styles.caption}>Member Registration Requirements</h2>
        </div>

        {/* eslint-disable react/no-danger */}
        <div
          style={styles.agreement}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {/* eslint-enable react/no-danger */}

        <div style={styles.agreementCheckboxContainer}>
          <input
            type="checkbox"
            className="input-assumpte"
            id="GINA_checkbox"
            onChange={toggleStatementAgreement}
            checked={agreedToStatement}
          />

          <label htmlFor="GINA_checkbox">
            I have read and accepted these requirements
          </label>
        </div>

        <div style={styles.bottomLinks}>
          <BottomNavButtons
            backLink="/registration/basic-information"
            onClickForw={onContinue}
            disabled={!agreedToStatement}
            forwText="Next"
          />
        </div>
      </div>
    </div>
  );
};

OptInMarkup.propTypes = {
  content: PropTypes.string.isRequired,
  agreedToStatement: PropTypes.bool.isRequired,
  toggleStatementAgreement: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default Radium(OptInMarkup);
