import React from 'react';
import { Grid, withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

const styles = () => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '20px',
    fontFamily: 'Rubik',
    padding: '54px 46px',
    width: '750px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  subtitle: {
    color: '#858585',
    fontSize: '18px',
    marginBottom: '16px',
  },
  title: {
    color: '#4b4b4b',
    fontSize: '38px',
    marginBottom: '16px',
  },
  paragraph: {
    color: '#797979',
    fontSize: '20px',
    marginBottom: '32px',
    textAlign: 'center',
    fontWeight: '300',
  },
  button: {
    backgroundColor: '#19BEEF',
    fontSize: '26px',
    color: 'white',
    borderRadius: '8px',
    padding: '20px 40px',
    textTransform: 'none',
    fontWeight: '500',
    fontFamily: 'Rubik',
    '&:hover': {
      backgroundColor: '#19BEEF',
    },
  },
  foregroundImage: {
    position: 'absolute',
    bottom: '24px',
  },
});

const WelcomeBox = ({ classes, startQuestionnaire, foregroundImage, text }) => (
  <Grid item container direction="column" className={classes.root}>
    {foregroundImage && (
      <img
        className={classes.foregroundImage}
        src={require(`../../../../images/${foregroundImage}`)}
        role="presentation"
      />
    )}
    <Grid item className={classnames(classes.centered, classes.subtitle)}>
      {_.get(text, 'subtitle')}
    </Grid>
    <Grid item className={classnames(classes.centered, classes.title)}>
      {_.get(text, 'title')}
    </Grid>
    <Grid item className={classnames(classes.centered, classes.paragraph)}>
      <p>{_.get(text, 'description')}</p>
    </Grid>
    <div className={classes.centered}>
      <Button
        variant="contained"
        className={classes.button}
        onClick={startQuestionnaire}
      >
        {_.get(text, 'cta')}
      </Button>
    </div>
  </Grid>
);

WelcomeBox.propTypes = {
  startQuestionnaire: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  foregroundImage: PropTypes.string,
  text: PropTypes.object,
};

export default withStyles(styles)(WelcomeBox);
