import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose, withProps, withStateHandlers, lifecycle } from 'recompose';
import AnswerOption from './AnswerOption';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
});

const CheckBoxAnswer = ({
  classes,
  answers,
  question,
  isSelected,
  toggleAnswer,
}) => (
  <div className={classes.root}>
    {_(answers)
      .sortBy('displayOrder')
      .map((opt, idx) => {
        const isChosen = _.get(isSelected, idx);

        return (
          <AnswerOption
            key={idx}
            index={idx}
            answer={opt}
            iconName={_.get(opt, 'iconName', null)}
            updateAnswers={toggleAnswer}
            questionId={_.get(question, 'id')}
            isChosen={isChosen}
            questionType={_.get(question, 'type')}
          />
        );
      })
      .value()}
  </div>
);

CheckBoxAnswer.propTypes = {
  question: PropTypes.object.isRequired,
  currentAnswers: PropTypes.array,
  classes: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  isSelected: PropTypes.array.isRequired,
  toggleAnswer: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withProps(({ question }) => ({
    answers: _.get(question, 'answerOptions', []),
  })),
  withStateHandlers(
    ({ answers, currentAnswers }) => ({
      isSelected: _.map(
        answers,
        (answer) => !!_.find(currentAnswers, { answer: answer.id })
      ),
    }),
    {
      updateIsSelected: () => (isSelected) => ({ isSelected }),
      toggleAnswer: (
        { isSelected },
        { answers, updateAnswersForSubmission, markAsValid }
      ) => (questionId, answer, questionType, index) => {
        isSelected[index] = !isSelected[index];
        const mappedSelectedAnswers = _.filter(
          answers,
          (answer, idx) => isSelected[idx]
        );
        updateAnswersForSubmission(
          questionId,
          questionType,
          mappedSelectedAnswers
        );
        markAsValid(questionId, _.size(mappedSelectedAnswers) > 0);
        return { isSelected };
      },
    }
  ),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (
        _.get(this.props, 'question.id') !== _.get(nextProps, 'question.id') ||
        _.get(this.props, 'currentAnswers') !==
          _.get(nextProps, 'currentAnswers')
      ) {
        this.props.updateIsSelected(
          _.map(
            nextProps.answers,
            (answer) =>
              !!_.find(nextProps.currentAnswers, { answer: answer.id })
          )
        );
      }
    },
  })
)(CheckBoxAnswer);
