import EventBus from 'vertx3-eventbus-client';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import uuid from 'react-native-uuid';

let _eventBus;
let _reconnectTimeout;
let _tagManagerSessionId;

function setupEventBus(toPublish) {
  //set the timeout so we don't try to create a second socket before this one is open
  _reconnectTimeout = setTimeout(clearReconnectTimeout, 1000 * 10);
  if (AppConfig.effectiveConfig.analyticsRoot) {
    if (_eventBus) {
      _eventBus.close();
    }
    _eventBus = new EventBus(AppConfig.effectiveConfig.analyticsRoot);

    _eventBus.onopen = () => {
      if (toPublish) {
        window.publish(toPublish);
      }
    };

    //setup any other handlers here
  }
}

function getHeader() {
  const signInResult = JSON.parse(sessionStorage.getItem('signInResult'));
  return signInResult
    ? {
        authorization: `Bearer ${signInResult.access_token}`,
      }
    : {};
}

function clearReconnectTimeout() {
  clearTimeout(_reconnectTimeout);
  _reconnectTimeout = undefined;
}

/**
 * If the connection is open publish the message to the server
 * If the connection is closed, try to reconnect and publish, at most once every 10 seconds
 * Any messages published while in the _reconnectTimeout and before the socket is open are DROPPED!
 * @param {The object to publish to the analytics server} obj
 */
function publish(obj) {
  if (_eventBus) {
    obj.tagManagerSessionId = _tagManagerSessionId;
    if (_eventBus.state === 1) {
      //OPEN
      _eventBus.publish('send.message', obj, getHeader());
    } else if (!_reconnectTimeout) {
      setupEventBus(obj);
    }
    //in the _reconnectTimeout but no socket open, message dropped!
  }
}

function initialize() {
  _tagManagerSessionId = sessionStorage.getItem('tagManagerSessionId');
  if (!_tagManagerSessionId) {
    sessionStorage.setItem('tagManagerSessionId', uuid.v4());
  }
  window.publish = publish;
  setupEventBus();
}

export default {
  initialize,
};
