import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { Grid, Switch, withStyles } from '@material-ui/core';
import Typography from '../../common/Typography';

const styles = (theme) => ({
  root: {
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.palette.common.formulaOne}`,
    padding: '16px',
  },
  label: {
    height: '29px',
  },
  switchBase: {
    height: 'unset',
    '&$switchOn + $switchBar': {
      backgroundColor: theme.palette.common.grannySmith,
      opacity: 'unset',
    },
  },
  switchBar: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '10px',
    top: '-1px',
    marginTop: '0px',
    width: '40px',
    height: '20px',
  },
  switchOn: {},
  switchOnIcon: {
    transform: 'translateX(6px)',
  },
  switchIcon: {
    boxShadow: 'none',
    color: theme.palette.common.white,
    width: '18px',
    height: '18px',
  },
});

const DrawerSwitch = ({ classes, onChange, label, value }) => {
  return (
    <Grid container className={classes.root}>
      <Grid item xs={10}>
        <Typography type="h3" className={classes.label}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Switch
          checked={value}
          onChange={onChange}
          classes={{
            switchBase: classes.switchBase,
            bar: classes.switchBar,
            checked: classes.switchOn,
            icon: classes.switchIcon,
            iconChecked: classes.switchOnIcon,
          }}
        />
      </Grid>
    </Grid>
  );
};

DrawerSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
};

export default compose(pure, withStyles(styles))(DrawerSwitch);
