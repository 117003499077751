import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, lifecycle, pure } from 'recompose';
import { find } from 'lodash/fp';
import moment from 'moment';
import { MessageCenterConstants } from '@healthmine/greyhound-core/src/constants';
import { withSecureMessages } from '@healthmine/greyhound-core/src/hocs';
import {
  DrawerContent,
  DrawerSection,
  DrawerSectionHeader,
} from '../../common/drawer2';

const {
  MessageStatus: { DELETE },
} = MessageCenterConstants;

const MessageDrawerContent = ({
  message,
  deleteSecureMessage,
  restoreSecureMessage,
}) => {
  const headerAction =
    message.status === DELETE
      ? {
          label: 'Restore',
          color: 'grannySmith',
          onClick: () => restoreSecureMessage(message),
        }
      : {
          label: 'Archive',
          onClick: () => deleteSecureMessage(message),
        };
  return (
    <DrawerContent title={message.subject} headerAction={headerAction}>
      <DrawerSectionHeader
        title={moment(message.sentTime).format('MM/DD/YYYY')}
      />
      <DrawerSection>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: message.body }} />
      </DrawerSection>
    </DrawerContent>
  );
};

MessageDrawerContent.propTypes = {
  message: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  deleteSecureMessage: PropTypes.func.isRequired,
  restoreSecureMessage: PropTypes.func.isRequired,
};

export default compose(
  pure,
  connect(({ messageCenter }, { id }) => ({
    message: find({ id }, messageCenter.messages),
  })),
  withSecureMessages,
  lifecycle({
    componentWillMount() {
      const { message, markSecureMessageAsRead } = this.props;
      markSecureMessageAsRead(message);
    },
  })
)(MessageDrawerContent);
