import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import ClipboardButton from 'react-clipboard.js';

const ClipboardItem = ({ text, title }) => {
  const styles = {
    clipboard: {
      padding: '5px',
      fontSize: '18px',
      lineHeight: '18px',
      marginLeft: '10px',
      color: '#333',
      cursor: 'pointer',
      backgroundColor: '#eee',
      backgroundImage: 'linear-gradient(#fcfcfc,#eee)',
      border: '1px solid #d5d5d5',
      borderRadius: '3px',
    },
  };

  return (
    <ClipboardButton
      style={styles.clipboard}
      data-clipboard-text={text}
      button-title={title}
    >
      <i className="thin-0255_paste_clipboard" alt={title} />
    </ClipboardButton>
  );
};

ClipboardItem.defaultProps = {
  title: 'Copy To Clipboard',
};

ClipboardItem.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default Radium(ClipboardItem);
