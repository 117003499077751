import React from 'react';
import PropTypes from 'prop-types';
import LinearScaleMarkup from './LinearScaleMarkup';

class LinearScale extends React.Component {
  constructor(props, context) {
    super(props, context);

    this._onAnswered = this._onAnswered.bind(this);

    this.state = {
      value: this.props.value,
    };
  }
  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    this.setState({ value });
  }

  render() {
    return (
      <div>
        <LinearScaleMarkup
          {...this.props}
          {...this.state}
          onAnswered={this._onAnswered}
        />
      </div>
    );
  }

  _onAnswered(value) {
    this.setState({ value });
    this.props.onAnswered(value);
  }
}

LinearScale.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      score: PropTypes.number.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,

  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  onAnswered: PropTypes.func.isRequired,

  styles: PropTypes.shape({
    container: PropTypes.object,
    labelContainer: PropTypes.object,
    label: PropTypes.object,
    scaleContainer: PropTypes.object,
    scaleLine: PropTypes.object,
    bubbleContainer: PropTypes.object,
    bubble: PropTypes.object,
    bubbleValue: PropTypes.object,
    selectedBubble: PropTypes.object,
    selectedBubbleValue: PropTypes.object,
    hoverBubble: PropTypes.object,
    hoverBubbleValue: PropTypes.object,
  }),
};

LinearScale.defaultProps = {
  styles: {},
};

export default LinearScale;
