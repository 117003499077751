import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import { CompliancePeriod } from '@healthmine/greyhound-core/src/constants/prop-types';
import Styles from './CompliancePeriodSelector.styles';

const CompliancePeriodSelectorMarkup = ({
  compliancePeriodOptions,
  selectedCompliancePeriod,
  onCompliancePeriodSelected,
}) => {
  return (
    <div style={Styles.root}>
      <span>Program Year:</span>
      <select
        disabled={_.size(compliancePeriodOptions) === 1}
        style={Styles.select}
        value={_.get(selectedCompliancePeriod, 'id', 0)}
        onChange={onCompliancePeriodSelected}
      >
        {_.map(
          compliancePeriodOptions,
          (compliancePeriodOption, compliancePeriodOptionIndex) => (
            <option
              key={`CompliancePeriodOption${compliancePeriodOptionIndex}`}
              value={compliancePeriodOption.value}
            >
              {compliancePeriodOption.label}
            </option>
          )
        )}
      </select>
      <span>{`${moment(selectedCompliancePeriod.startDate).format(
        'MM/DD/Y'
      )} - ${moment(selectedCompliancePeriod.endDate).format(
        'MM/DD/Y'
      )}`}</span>
    </div>
  );
};

CompliancePeriodSelectorMarkup.propTypes = {
  compliancePeriodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,

  selectedCompliancePeriod: CompliancePeriod,
  onCompliancePeriodSelected: PropTypes.func.isRequired,
};

export default Radium(CompliancePeriodSelectorMarkup);
