import React from 'react';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
} from '../../../../common/drawer2';
import { commaFormat } from '@healthmine/greyhound-core/src/utils';
import StatusWidget from '../../../../common/widgets/StatusWidget';
import HealthAction from './HealthAction';
import PrescribedTrackerAction from './PrescribedTrackerAction';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import { withTheme } from '@material-ui/core';

const HealthActionListDrawer = ({
  title,
  healthActions,
  selectHealthAction,
  remainingActionCount,
  actionCompletionPercent,
  remainingIncentiveCount,
  incentiveCompletionPercent,
  color,
  trackers,
  theme,
}) => {
  const incentiveLabel = IncentiveSummary.incentiveLabel();
  return (
    <DrawerContent title={title}>
      <DrawerSection>
        <StatusWidget
          color={color}
          status={remainingActionCount}
          statusNote={`action${
            remainingActionCount !== 1 ? 's' : ''
          } remaining`}
          percentage={actionCompletionPercent}
        />
      </DrawerSection>
      <DrawerSection>
        <StatusWidget
          color={theme.palette.common.whiskeySour}
          status={commaFormat(remainingIncentiveCount)}
          statusNote={`${incentiveLabel} available to earn`}
          percentage={incentiveCompletionPercent}
        />
      </DrawerSection>
      <DrawerSectionHeader title="Health Actions" />
      {healthActions.map((healthActionItem, idx) => (
        <HealthAction
          key={`care_plan_draw_ha_${idx}`}
          selectHealthAction={selectHealthAction}
          healthAction={healthActionItem}
        />
      ))}
      {trackers.map((tracker, idx) => (
        <PrescribedTrackerAction
          key={`care_plan_draw_tracker_${idx}`}
          tracker={tracker}
        />
      ))}
    </DrawerContent>
  );
};

HealthActionListDrawer.propTypes = {
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  selectHealthAction: PropTypes.func.isRequired,
  healthActions: PropTypes.array,
  remainingActionCount: PropTypes.number.isRequired,
  actionCompletionPercent: PropTypes.number.isRequired,
  remainingIncentiveCount: PropTypes.number.isRequired,
  incentiveCompletionPercent: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  trackers: PropTypes.array,
};

HealthActionListDrawer.defaultProps = {
  trackers: [],
};

export default withTheme()(HealthActionListDrawer);
