import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose, pure, withProps, withStateHandlers } from 'recompose';
import _ from 'lodash';
import { selectUnifiedCarePlan } from '@healthmine/greyhound-core/src/selectors/UnifiedCarePlanSelectors';
import { Subjects } from '@healthmine/greyhound-core/src/constants/CarePlan';
import CarePlans from '@healthmine/greyhound-core/src/modules/CarePlans';
import UnifiedCarePlanTrackingMarkup from './UnifiedCarePlanTracking.markup';

const propSelector = createSelector(
  selectUnifiedCarePlan,
  (unifiedCarePlan) => {
    return {
      unifiedCarePlan,
    };
  }
);

function mapStateToProps(state) {
  return propSelector(state);
}

export default compose(
  pure,
  connect(mapStateToProps),
  withStateHandlers(
    {
      isSchedulingOpen: false,
    },

    {
      openScheduling: () => () => ({
        isSchedulingOpen: true,
      }),

      closeScheduling: () => () => ({
        isSchedulingOpen: false,
      }),
    }
  ),
  withProps(({ params, unifiedCarePlan }) => {
    const subject = CarePlans.goalSubjectFromUrl(params.subject);

    const carePlan =
      subject === Subjects.PREVENTIVE
        ? _.get(unifiedCarePlan, params.subject)
        : _.find(unifiedCarePlan.goals, (goal) => goal.subject === subject);

    const {
      pastDueActions,
      requiredSoonActions,
      requiredActions,
      optionalActions,
      completedActions,
    } = carePlan;

    return {
      carePlan,
      //  eslint-disable-next-line max-len
      carePlanActions: _.concat(
        pastDueActions || [],
        requiredSoonActions || [],
        requiredActions,
        optionalActions,
        _.map(completedActions, (action) => ({ ...action, completed: true })) ||
          []
      ),
      planSubTitle: '',
    };
  })
)(UnifiedCarePlanTrackingMarkup);
