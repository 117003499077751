import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { MessageCenterConstants } from '@healthmine/greyhound-core/src/constants';
import { ListItem, ListItemStyles as styles } from '../../common/list/List';
import Typography from '../../common/Typography';
import StatusTitle from '../../common/StatusTitle';

const {
  MessageStatus: { UNREAD },
} = MessageCenterConstants;

const MessageItem = ({
  title,
  subtitle,
  status,
  sentTime,
  titleClass,
  subtitleClass,
  openDrawer,
}) => (
  <ListItem onClick={openDrawer}>
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs={10}>
        <StatusTitle
          status={status}
          title={title}
          subtitle={subtitle}
          titleClass={titleClass}
          subtitleClass={subtitleClass}
        />
      </Grid>
      <Grid item>
        <Typography secondary>
          {moment(sentTime).format('MM/DD/YYYY')}
        </Typography>
      </Grid>
    </Grid>
  </ListItem>
);

MessageItem.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.oneOf(['positive', 'neutral']).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  message: PropTypes.shape({
    sentTime: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
  openDrawer: PropTypes.func.isRequired,
  titleClass: PropTypes.string,
  subtitleClass: PropTypes.string,
  sentTime: PropTypes.string,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ message, classes }) => {
    const unraed = message.status === UNREAD;
    return {
      title: message.subject,
      subtitle: unraed ? 'new' : 'read',
      status: unraed ? 'neutral' : 'positive',
      sentTime: message.sentTime,
      subtitleClass: classes.complete,
      titleClass: unraed ? classes.emphasis : undefined,
    };
  })
)(MessageItem);
