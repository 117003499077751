import _ from 'lodash';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import { Link } from 'react-router';
import { Colors, AppConfig } from '@healthmine/greyhound-core/src/modules';
import Dropdown from '../common/question/Dropdown';
import Page from '../common/Page';

const HealthRiskAssessmentResultMarkup = ({
  assessments,
  selectedAssessmentIndex,
  onAssessmentChanged,
  questionAnswers,
}) => {
  const RadiumLink = Radium(Link);

  const styles = {
    page: {
      paddingTop: '20px',
      paddingBottom: '40px',
      paddingLeft: '10%',
      paddingRight: '10%',
    },

    pageTitle: {
      color: Colors.bodyText,
      paddingTop: '20px',
      marginBottom: '25px',
      fontSize: '20px',
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'row',
    },

    icon: {
      display: 'flex',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '50%',
      color: 'white',
      width: '76px',
      height: '76px',
      fontWeight: '100',
      fontSize: '40px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#7770ff',
    },

    descriptionText: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '26px',
      margin: '8px 0px 0px 15px',
    },

    shortTitle: {
      fontSize: '16px',
      fontWeight: '500',
      paddingBottom: '3px',
    },

    titleText: {
      marginBottom: '40px',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.navyBlue,
    },
    picker: {
      width: '220px',
    },
    pickerContainer: {
      width: '220px',
      flex: 5,
    },
    pickerLabel: {
      flex: 2,
      fontSize: '16px',
      fontWeight: '600',
      color: Colors.bodyText,
    },
    callToActionLabel: {
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.linkBlue,
      ':hover': {
        fontWeight: '600',
      },
    },
    callToAction: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 3,
    },
    summaryLink: {
      marginTop: '45px',
      display: 'flex',
      alignItems: 'center',
    },
    contentTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '16px',
      fontWeight: 600,
    },
    questionTitle: {
      flex: 7,
    },
    answerTitle: {
      flex: 3,
    },
    questionTableContent: {
      fontSize: '16px',
      display: 'flex',
      color: Colors.mediumGray4,
      lineHeight: '18px',
      alignItems: 'center',
      padding: '10px 0px',
      borderBottom: '1px solid #f1eeee',
    },
    answerColumn: {
      flex: 3,
    },
    questionColumn: {
      flex: 7,
    },
    questionTableHeader: {
      margin: '40px 0px 20px 0px',
      padding: '10px 0px',
      fontWeight: '600',
      color: Colors.mediumGray2,
      fontSize: '16px',
      display: 'flex',
      backgroundColor: '#f3f6f8',
      alignItems: 'center',
    },
    question: {
      width: '420px',
    },
    note: {
      fontSize: '16px',
      fontWeight: 300,
      margin: '30px 0',
    },
  };

  const { lingo } = AppConfig.effectiveConfig;

  return (
    <Page
      title={`${lingo.healthRiskAssessment} Results`}
      icon={'thin-0378_analytics_presentation_statistics_graph'}
      iconStyle={{ backgroundColor: '#7770ff' }}
    >
      {_.size(assessments) ? (
        <div style={styles.page}>
          <div style={styles.titleText}>
            {lingo.healthRiskAssessment} Answers
          </div>

          <div style={styles.summaryLink}>
            <div style={styles.pickerLabel}>{lingo.assessment} Date:</div>

            <div style={styles.pickerContainer}>
              <Dropdown
                onAnswered={(value) => onAssessmentChanged(value.value)}
                value={selectedAssessmentIndex}
                options={assessments}
                styles={styles.picker}
              />
            </div>

            <div style={styles.callToAction}>
              <RadiumLink to="/hra-summary" style={styles.callToActionLabel}>
                View Summary
              </RadiumLink>
              <RadiumLink to="/hra" style={styles.callToActionLabel}>
                Retake {lingo.hra}
              </RadiumLink>
            </div>
          </div>

          <div>
            <div style={styles.questionTableHeader}>
              <span style={styles.questionTitle}>Question</span>
              <span style={styles.answerTitle}>Answer</span>
            </div>

            {questionAnswers.map(
              (question, i) =>
                question.answer !== null ? (
                  <div key={i} style={styles.questionTableContent}>
                    <div style={styles.questionColumn}>
                      <div style={styles.question}>{question.question}</div>
                    </div>

                    <div style={styles.answerColumn}>{question.answer}</div>
                  </div>
                ) : null
            )}
          </div>
        </div>
      ) : (
        <div style={styles.page}>
          <div style={styles.note}>
            We don't seem to have a record of you completing a{' '}
            {lingo.healthRiskAssessment}.
          </div>
        </div>
      )}
    </Page>
  );
};

HealthRiskAssessmentResultMarkup.propTypes = {
  assessments: PropTypes.arrayOf(PropTypes.object),
  selectedAssessmentIndex: PropTypes.number.isRequired,
  onAssessmentChanged: PropTypes.func.isRequired,
  questionAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Radium(HealthRiskAssessmentResultMarkup);
