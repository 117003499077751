import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import HealthAction from './HealthAction';

const styles = {
  actionListHeader: {
    fontSize: '18px',
    padding: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const ActionList = ({ title, actions, pastDue, showDueDate, openTracking }) => {
  return (
    <div>
      <div
        style={[
          styles.actionListHeader,
          pastDue ? { color: Colors.newRed } : {},
        ]}
      >
        <span style={{ fontWeight: 'bold' }}>{title}</span>
      </div>
      <div>
        {actions.map((action, idx) => {
          return (
            <HealthAction
              key={`action_${idx}`}
              isEven={(idx + 1) % 2 === 0}
              action={action}
              openTracking={openTracking}
              pastDue={pastDue}
              showDueDate={showDueDate}
            />
          );
        })}
      </div>
    </div>
  );
};

ActionList.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
  pastDue: PropTypes.bool,
  openTracking: PropTypes.func,
  coaching: PropTypes.bool,
  showDueDate: PropTypes.bool,
  activateTracking: PropTypes.func,
};

export default Radium(ActionList);
