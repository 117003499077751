import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium from 'radium';
import { Colors, AppConfig } from '@healthmine/greyhound-core/src/modules';
import PageHeader from '../../components/common/pageHeader';
import { Features } from '@healthmine/greyhound-core/src/constants';

class RegisterContainer extends React.Component {
  render() {
    const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);
    const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);

    const currentPathname = _.get(this, 'props.location.pathname', '/');
    const isPublicDocument = currentPathname.split('/')[1] === 'public';

    const needBackground =
      this.props.initialLocation &&
      !fiveFiveUI &&
      !leapFrogUI &&
      (this.props.initialLocation == '/' ||
        this.props.initialLocation.pathname.includes('/signin'));

    const bgImage = require('../../images/BubbleBG.png');

    const styles = {
      mainContainer: leapFrogUI
        ? {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }
        : {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            color: Colors.darkGray,
            backgroundColor: fiveFiveUI ? Colors.backdropGray : Colors.light,
            overflow: 'hidden',
          },
      bgContainer: {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      },
      page: {
        width: '100%',
        fontSize: '16px',
        margin: '0px auto',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: isPublicDocument ? '50px' : 0,
      },
    };

    return (
      <div
        style={[
          styles.mainContainer,
          needBackground ? styles.bgContainer : null,
          leapFrogUI && { fontSize: '16px' },
        ]}
      >
        {!isPublicDocument && <PageHeader currentPathname={currentPathname} />}

        <div style={styles.page}>{this.props.children}</div>
      </div>
    );
  }
}

RegisterContainer.propTypes = {
  children: PropTypes.object.isRequired,
  initialLocation: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    initialLocation: state.routing.locationBeforeTransitions,
    isLoggedIn: !!state.signIn.accessToken,
  };
}

export default connect(mapStateToProps, null)(Radium(RegisterContainer));
