import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import _ from 'lodash';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import {
  DrawerButton,
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
  withDrawer,
} from '../../../../common/drawer2';
import ChildHealthAction from './ChildHealthAction';
import HealthAction from './HealthAction';
import Typography from '../../../../common/Typography';
import MarkdownDescription from '../../../../common/MarkdownDescription';
import WhatToExpectDrawer from '../../biometric-screening/WhatToExpect';
import HraIntro from '../../hra/HraIntro';
import HraResults from '../../hra/HraResults';
import classnames from 'classnames';
import { CompletionStatus } from '@healthmine/greyhound-core/src/constants/CarePlan';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import BiometricScreeningHelpText from './BiometricScreeningHelpText';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';

const styles = (theme) => ({
  complete: {
    color: theme.palette.common.grannySmith,
  },
  notComplete: {
    color: theme.palette.common.cosmopoltian,
  },
});

const HealthActionDrawerView = ({
  action,
  childActions,
  classes,
  completed,
  lastCompletedOn,
  description,
  grow,
  healthAction,
}) => {
  return (
    <DrawerContent>
      <HealthAction healthAction={healthAction} />
      <DrawerSectionHeader title="Status" />
      <DrawerSection>
        <div style={{ textAlign: 'center' }}>
          <Typography
            type="h1"
            className={classnames(
              completed ? classes.complete : classes.notComplete
            )}
          >
            {completed ? 'completed' : 'not complete'}
          </Typography>
          {completed &&
            lastCompletedOn && (
              <Typography secondary>
                {moment(lastCompletedOn).format('MM/DD/YYYY')}
              </Typography>
            )}
        </div>
      </DrawerSection>
      <DrawerSectionHeader title="Description" />
      <DrawerSection grow={grow}>
        <MarkdownDescription description={description} />
        {healthAction.id === 83 && <BiometricScreeningHelpText />}
      </DrawerSection>
      {childActions.map((childAction, index) => (
        <ChildHealthAction
          key={`child-action-${childAction.code}`}
          healthAction={childAction}
          defaultExpanded={index === 0}
        />
      ))}
      {action && <DrawerButton {...action} />}
    </DrawerContent>
  );
};

HealthActionDrawerView.propTypes = {
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  classes: PropTypes.object.isRequired,
  childActions: PropTypes.array,
  completed: PropTypes.bool.isRequired,
  lastCompletedOn: PropTypes.string,
  description: PropTypes.string.isRequired,
  drawer: PropTypes.object.isRequired,
  grow: PropTypes.bool.isRequired,
  healthAction: PropTypes.object.isRequired,
};

HealthActionDrawerView.defaultProps = {
  childActions: [],
};

export default compose(
  pure,
  withDrawer,
  withProps(({ drawer, healthAction, hraStatus }) => {
    let action;
    const {
      longDescription,
      completed,
      lastCompletedOn,
      code,
      childActions,
    } = healthAction;

    const description =
      code === 'DOCTORS_VISIT'
        ? `${longDescription}\n\n${_.get(
            AppConfig,
            'effectiveConfig.lingo.findProviderMarkdown',
            ''
          )}`
        : longDescription;

    if (code === 'COMPLETE_BIOMETRIC_TESTING') {
      action = {
        text: 'Scheduling options',
        onClick: () => drawer.open(WhatToExpectDrawer),
      };
    } else if (code === 'COMPLETE_HEALTH_QUESTIONNAIRE') {
      const isComplete = completed || hraStatus === CompletionStatus.COMPLETED;
      action = {
        text: isComplete
          ? 'View Health Risk Assessment Results'
          : hraStatus === AppConstants.QuestionnaireStatus.STARTED
            ? 'Finish Health Risk Assessment'
            : 'Take Health Risk Assessment',
        onClick: isComplete
          ? () => drawer.open(HraResults)
          : () => drawer.open(HraIntro, null, { large: true }),
      };
    }

    return {
      action,
      childActions,
      completed,
      lastCompletedOn,
      description,
      grow: _.isEmpty(childActions),
    };
  }),
  withStyles(styles)
)(HealthActionDrawerView);
