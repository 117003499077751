import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure, withState, withHandlers } from 'recompose';
import { DrawerSection, DrawerStepperBasic } from '../../../../common/drawer2';
import { get, head, last, map } from 'lodash';
import Measurements from '@healthmine/greyhound-core/src/modules/Measurements';
import createCachedSelector from 're-reselect';
import Typography from '../../../../common/Typography';
import Chart from '../../../../../../components/data/chart';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import { withStyles } from '@material-ui/core/styles';
import { getWeeklySummary } from '../utils';

const styles = () => ({
  dailyItem: {
    textAlign: 'right',
  },
});

const EMPTY_OBJECT = {};
const dataSelector = (state, props) => props.tracker.dataSelector(state);
const currentWeekSelector = (state, props) =>
  props.tracker.weekSelector(state, props.week);
const deviceRewardsSelector = (state, props) =>
  get(state, ['deviceRewards', props.tracker.dataKey], EMPTY_OBJECT);
const propSelector = createCachedSelector(
  dataSelector,
  currentWeekSelector,
  deviceRewardsSelector,
  (data, currentWeekData, deviceRewards) => ({
    data,
    currentWeekData,
    deviceRewards,
    maxDate: head(last(data)),
  })
)((state, props) => props.tracker.dataKey);

const TrackerHistory = ({
  classes,
  tracker,
  currentWeekData,
  week,
  data,
  maxDate,
  nextWeek,
  previousWeek,
  deviceRewards,
}) => {
  const { unit, dataKey } = tracker;
  const TrackingChart = Chart[dataKey];
  const ticks = Measurements.getWeeks(week, maxDate, 5);
  const currentWeek = moment(maxDate)
    .add(week, 'w')
    .startOf('w');
  const currentSummary = getWeeklySummary(currentWeek, currentWeekData);

  return (
    <div>
      <TrackingChart data={data} ticks={ticks} />
      <DrawerStepperBasic
        next={nextWeek}
        previous={previousWeek}
        options={[{ title: `Week of ${currentWeek.format('MM/DD/YYYY')}` }]}
      />
      {map(currentSummary, ({ date, value }, i) => {
        const rewardAmount = get(deviceRewards, date.format('YYYY-MM-DD'), 0);

        return (
          <DrawerSection key={i}>
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <Typography type="h3Medium" color="mintJulep">
                  {value}
                </Typography>
                <Typography>{unit}</Typography>
              </Grid>
              <Grid item xs={8} className={classes.dailyItem}>
                <Typography type="h3">
                  {date.format('dddd, MMMM Do')}
                </Typography>
                <Typography>
                  {rewardAmount > 0 &&
                    IncentiveSummary.getIncentiveEarningText(
                      true,
                      rewardAmount
                    )}
                </Typography>
              </Grid>
            </Grid>
          </DrawerSection>
        );
      })}
    </div>
  );
};

TrackerHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  tracker: PropTypes.object.isRequired,
  currentWeekData: PropTypes.object,
  data: PropTypes.array,
  maxDate: PropTypes.number,
  week: PropTypes.number,
  nextWeek: PropTypes.func.isRequired,
  previousWeek: PropTypes.func.isRequired,
  deviceRewards: PropTypes.object,
};

export default compose(
  pure,
  withStyles(styles),
  withState('week', 'setWeek', 0),
  connect((state, props) => propSelector(state, props)),
  withHandlers({
    nextWeek: ({ setWeek }) => () => setWeek((week) => week + 1),
    previousWeek: ({ setWeek }) => () => setWeek((week) => week - 1),
  })
)(TrackerHistory);
