import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import React from 'react';
import Modal from 'react-modal';
import { LanguageConstants } from '@healthmine/greyhound-core/src/constants';
import { CompletionStatus } from '@healthmine/greyhound-core/src/constants/CarePlan';
import {
  Assets,
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import Button from '../common/Button';
import HealthRiskAssessmentError from './HealthRiskAssessmentError';

const HealthRiskAssessmentPrologueMarkup = ({
  HRAStatus,
  setHraLanguage,
  errorCount,
  toggleTermsModalVisibility,
  isTermsModalVisible,
}) => {
  const styles = {
    pageTitle: {
      color: Colors.bodyText,
      paddingTop: '20px',
      paddingLeft: '10%',
      marginBottom: '25px',
      fontSize: '20px',
      display: 'flex',
      flexDirection: 'row',
    },

    pageContent: {
      color: Colors.bodyText,
      fontSize: '23px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    icon: {
      display: 'flex',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '50%',
      color: 'white',
      width: '76px',
      height: '76px',
      fontWeight: '100',
      fontSize: '40px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#7770ff',
    },

    descriptionText: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '23px',
      margin: '8px 0px 0px 15px',
    },

    shortTitle: {
      fontSize: '16px',
      fontWeight: '500',
      paddingBottom: '3px',
    },

    benefitsContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '40px 5%',
      minHeight: '185px',
      fontWeight: '400',
    },

    benefitContainer1: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: '145px',
    },

    benefitContainer2: {
      display: 'flex',
      flexDirection: 'column',
      width: '145px',
    },

    benefitImageContainer1: {
      height: '90px',
      textAlign: 'center',
    },

    benefitImageContainer2: {
      height: '135px',
      textAlign: 'center',
    },

    benefitImage: {
      height: '65px',
      width: 'auto',
    },

    benefitImage1: {
      height: '75px',
      width: 'auto',
    },

    benefitImage2: {
      height: '115px',
      width: 'auto',
    },

    benefitCaption: {
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: '300',
      margin: '0px auto',
    },

    nextLinkContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px 0px 60px 0px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },

    languageLink: {
      textAlign: 'center',
      color: Colors.linkBlue,
      fontSize: '16px',
      fontWeight: '500',
      maxWidth: '220px',
      marginTop: '15px',
      cursor: 'pointer',
    },

    pageBottomContent: {
      fontSize: '16px',
      color: Colors.mediumGray4,
      padding: '2% 10%',
      textAlign: 'center',
    },

    button: {
      width: '220px',
      padding: '10px 15px',
      fontSize: '16px',
    },

    termsLink: {
      color: Colors.highlightBlue,
      cursor: 'pointer',
      textDecoration: 'none',
    },

    modalStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },

      content: {
        padding: '0px',
        overflow: 'hidden',
        width: '40%',
        maxWidth: '40%',
        height: '60%',
        maxHeight: '60%',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '20%',
        transform: 'translate(-50%, -10%)',
        border: 'none',
        backgroundColor: Colors.white,
        outline: 'none',
      },
    },

    modalCloseButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    modalHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '45px',
      margin: '0px',
      fontSize: '18px',
    },

    modalContent: {
      position: 'absolute',
      top: '45px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      borderTop: `1px solid ${Colors.mediumGray3}`,
    },

    modalContentFrame: {
      width: '100%',
      height: '100%',
    },
  };

  const { lingo } = AppConfig.effectiveConfig;

  const canProceed = errorCount < 3;

  const buttonText = `${
    HRAStatus && HRAStatus === CompletionStatus.STARTED ? 'Resume' : 'Start'
  } ${lingo.assessment}`;

  return (
    <StyleRoot style={GlobalStyles.light}>
      <div style={styles.pageContent}>
        Learn where you are in your health journey.
      </div>

      <div style={styles.benefitsContainer}>
        <div style={styles.benefitContainer1}>
          <div style={styles.benefitImageContainer1}>
            <img
              src={require('../../images/HRAAnswerBubbles.png')}
              style={styles.benefitImage}
              role="presentation"
            />
          </div>

          <div style={styles.benefitCaption}>
            Answer questions about your health
          </div>
        </div>

        <div style={styles.benefitContainer2}>
          <div style={styles.benefitImageContainer2}>
            <img
              src={require('../../images/HRARisks.png')}
              style={styles.benefitImage2}
              role="presentation"
            />
          </div>

          <div style={styles.benefitCaption}>Know your risks</div>
        </div>

        <div style={styles.benefitContainer1}>
          <div style={styles.benefitImageContainer1}>
            <img
              src={require('../../images/HRAHealthy.png')}
              style={styles.benefitImage1}
              role="presentation"
            />
          </div>

          <div style={styles.benefitCaption}>
            Get the tools you need to stay healthy
          </div>
        </div>
      </div>
      <div style={styles.nextLinkContainer}>
        {canProceed && (
          <Button
            text={buttonText}
            onClick={() => setHraLanguage(LanguageConstants.LanguageCodes.EN)}
            style={[styles.button, GlobalStyles.regular]}
          />
        )}
        {canProceed && (
          <span
            style={styles.languageLink}
            onClick={() => setHraLanguage(LanguageConstants.LanguageCodes.ES)}
          >
            &iquest;Habla espa&ntilde;ol? Haga clic aqu&iacute; para aceptar y
            continuar en espa&ntilde;ol.
          </span>
        )}
        <HealthRiskAssessmentError
          initialError={`There was an error beginning your ${
            lingo.assessment
          }. Please try again.`}
        />
      </div>

      <div style={styles.pageBottomContent}>
        By clicking "Start {lingo.assessment}", you agree to our{' '}
        <a style={styles.termsLink} href="/#/terms">
          Terms
        </a>{' '}
        and{' '}
        <span
          style={styles.termsLink}
          onClick={() => toggleTermsModalVisibility()}
        >
          Disclosure Policy.
        </span>
      </div>

      <Modal
        contentLabel="HRATerms"
        isOpen={isTermsModalVisible}
        style={styles.modalStyles}
        onRequestClose={() => toggleTermsModalVisibility()}
      >
        <a
          onClick={() => toggleTermsModalVisibility()}
          style={styles.modalCloseButton}
        >
          <i className="thin-0153_delete_exit_remove_close" />
        </a>

        <div style={styles.modalContent}>
          {isTermsModalVisible ? (
            <iframe
              src={Assets.getAssetURL('hra_terms_en.html')}
              frameBorder="0"
              style={styles.modalContentFrame}
            />
          ) : null}
        </div>
      </Modal>
    </StyleRoot>
  );
};

HealthRiskAssessmentPrologueMarkup.propTypes = {
  HRAStatus: PropTypes.string,
  setHraLanguage: PropTypes.func.isRequired,
  errorCount: PropTypes.number,
  toggleTermsModalVisibility: PropTypes.func.isRequired,
  isTermsModalVisible: PropTypes.bool.isRequired,
};

export default Radium(HealthRiskAssessmentPrologueMarkup);
