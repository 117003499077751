import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { Cell, Pie, PieChart } from 'recharts';
import { max, min } from 'lodash/fp';
import { withStyles, withTheme } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  label: {
    fill: theme.palette.primary.main,
    fontFamily: theme.globalStyles.baseFont,
  },
});

const RADIAN = Math.PI / 180;

const GaugeWidget = ({
  classes,
  theme,
  width,
  height,
  percent,
  label,
  animateGauge,
}) => {
  const size = min([width, height]);
  const centerX = max([size, width]) / 2;
  const centerY = max([size, height]) / 2;
  const innerRadius = size * 0.38;
  const outerRadius = size * 0.4;
  const radius = innerRadius + (outerRadius - innerRadius) / 2;
  const midAngle = 180 - 180 * percent / 100;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(RADIAN * midAngle);
  const cx = centerX + radius * cos;
  const cy = centerY + radius * sin;
  const dotSize = size * 0.02;
  const sections = [
    { value: 1, color: theme.palette.common.notBubbleGum },
    { value: 1, color: theme.palette.common.whiskeySour },
    { value: 1, color: theme.palette.common.grannySmith },
  ];

  /**
   * Animate by default and only turn off if explicity pass in false for animate
   */
  const isAnimationActive = animateGauge === false ? false : true;

  return (
    <div className={classes.root}>
      <PieChart width={width} height={height}>
        <Pie
          dataKey="value"
          data={sections}
          startAngle={180}
          endAngle={0}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          isAnimationActive={isAnimationActive}
        >
          {sections.map((d, i) => <Cell key={i} fill={d.color} />)}
        </Pie>
        <circle cx={cx} cy={cy} r={dotSize} />
        {label && (
          <text
            className={classes.label}
            x={centerX}
            y={centerY}
            textAnchor="middle"
            alignmentBaseline="baseline"
          >
            {label}
          </text>
        )}
      </PieChart>
    </div>
  );
};

GaugeWidget.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  label: PropTypes.string,
  animateGauge: PropTypes.bool,
};

export default compose(pure, withStyles(styles), withTheme())(GaugeWidget);
