import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import { includes } from 'lodash/fp';
import { TrackingConstants } from '@healthmine/greyhound-core/src/constants';
import { DrawerContent, DrawerSwitch } from '../../../../common/drawer2';
import { withTrackers } from '@healthmine/greyhound-core/src/hocs';

const {
  TrackingStatus: { ACTIVE, AVAILABLE },
} = TrackingConstants;
const ON_STATUSES = [ACTIVE, AVAILABLE];

const ManageTrackers = ({ allTrackers, toggle, isOn }) => (
  <DrawerContent title="Manage Tracker Cards">
    {allTrackers.map((x) => (
      <DrawerSwitch
        key={x.type}
        label={`${x.displayName} Tracker`}
        value={isOn(x)}
        onChange={() => toggle(x)}
      />
    ))}
  </DrawerContent>
);

ManageTrackers.propTypes = {
  allTrackers: PropTypes.array.isRequired,
  isOn: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  disableMyTracking: PropTypes.func.isRequired,
  enableMyTracking: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withTrackers,
  withHandlers(() => {
    const isOn = (x) => includes(x.status, ON_STATUSES);

    return {
      isOn: () => isOn,
      toggle: ({ disableMyTracking, enableMyTracking }) => (x) => {
        return isOn(x) ? disableMyTracking(x.type) : enableMyTracking(x.type);
      },
    };
  })
)(ManageTrackers);
