import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Input, FormHelperText } from '@material-ui/core';
import Typography from '../../common/Typography';
import ItemEdit from './ItemEdit';

const styles = () => ({
  itemContent: {
    flexGrow: 1,
  },
  mainContent: {
    marginRight: '20px',
  },
  inputRoot: {
    width: '100%',
  },
  hidden: {
    position: 'absolute',
    top: -9999,
    left: -9999,
    visibility: 'hidden',
  },
});

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      question: props.question,
      ignoreEmptyQuestion: true,
    };
  }
  componentDidMount() {
    if (this.props.editing) {
      this.inputRef.current.focus();
    }
  }
  render() {
    const { classes, editing, hideEdit } = this.props;
    const { question, ignoreEmptyQuestion } = this.state;
    return (
      <Grid container>
        <Grid item className={classes.itemContent}>
          <div className={classes.mainContent}>
            <div className={!editing ? classes.hidden : ''}>
              <Input
                value={question}
                onChange={this.updateQuestion}
                classes={{
                  root: classes.inputRoot,
                }}
                placeholder="Add a new question"
                onKeyPress={this.handleKeyPress}
                inputRef={this.inputRef}
              />
              {question === '' &&
                !ignoreEmptyQuestion && (
                  <FormHelperText>
                    <Typography color="cosmopolitan">
                      Must provide a question.
                    </Typography>
                  </FormHelperText>
                )}
            </div>
            {!editing && <Typography type="h2">{question}</Typography>}
          </div>
        </Grid>
        <Grid item>
          <ItemEdit
            editing={editing}
            updateItem={this.saveQuestion}
            deleteItem={this.deleteQuestion}
            editItem={this.editQuestion}
            hideEdit={hideEdit}
          />
        </Grid>
      </Grid>
    );
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.saveQuestion(this.props.id, this.state.question);
    }
  };

  updateQuestion = (e) => {
    this.setState({
      question: e.target.value,
      ignoreEmptyQuestion: false,
    });
  };

  editQuestion = () => this.props.editQuestion(this.props.id);

  saveQuestion = () => {
    if (this.state.question !== '') {
      this.props.saveQuestion(this.props.id, this.state.question);
    }
    this.setState({
      ignoreEmptyQuestion: false,
    });
  };

  deleteQuestion = () => this.props.deleteQuestion(this.props.id);
}

Question.propTypes = {
  classes: PropTypes.object.isRequired,
  question: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  editQuestion: PropTypes.func,
  saveQuestion: PropTypes.func.isRequired,
  id: PropTypes.number,
  hideEdit: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Question);
