import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Radio,
  withStyles,
  Input,
  FormHelperText,
} from '@material-ui/core';
import Typography from '../../common/Typography';
import Chip from '../../common/Chip';
import ItemEdit from './ItemEdit';

const styles = (theme) => ({
  itemContent: {
    flexGrow: 1,
  },
  main: {
    flexGrow: 1,
    padding: '20px, 0px, 20px, 20px',
  },
  radio: {
    height: 'initial',
    width: 'initial',
    color: theme.palette.common.blueGray,
  },
  inputRoot: {
    width: '100%',
  },
  hidden: {
    position: 'absolute',
    top: -9999,
    left: -9999,
    visibility: 'hidden',
  },
  feedback: {
    borderLeft: `1px solid ${theme.palette.common.blueGray}`,
  },
});

class Medication extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      name: props.name,
      currentlyTaking: props.currentlyTaking,
      ignoreEmptyMedName: true,
    };
  }

  componentDidMount() {
    if (this.props.editing) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const {
      drugStrength,
      selfAdded,
      classes,
      editing,
      medKey,
      hideEdit,
    } = this.props;
    const { name, currentlyTaking, ignoreEmptyMedName } = this.state;
    const title = drugStrength ? `${name} - ${drugStrength}` : name;
    return (
      <Grid container spacing={16} alignItems="center">
        <Grid item className={classes.main}>
          <Grid container>
            <Grid item className={classes.itemContent}>
              <div className={!editing ? classes.hidden : ''}>
                <Input
                  value={name}
                  onChange={this.updateName}
                  classes={{
                    root: classes.inputRoot,
                  }}
                  placeholder="Add a new medication or supplement"
                  onKeyPress={this.handleKeyPress}
                  inputRef={this.inputRef}
                />
                {name === '' &&
                  !ignoreEmptyMedName && (
                    <FormHelperText>
                      <Typography color="cosmopolitan">
                        Must provide a name.
                      </Typography>
                    </FormHelperText>
                  )}
              </div>
              {!editing && (
                <Grid container spacing={16} alignItems="center">
                  <Grid item>
                    <Typography type="h2">{title}</Typography>
                  </Grid>
                  {selfAdded && (
                    <Grid item>
                      <Chip label="Self Added" color="venus" />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            {selfAdded && (
              <Grid item>
                <ItemEdit
                  editing={editing}
                  updateItem={this.saveMed}
                  deleteItem={this.deleteMed}
                  editItem={this.editMed}
                  hideEdit={hideEdit}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!(hideEdit || editing) && (
          <Grid item>
            <Grid
              container
              className={classes.feedback}
              direction="column"
              spacing={8}
              alignItems="center"
            >
              <Grid item>
                <Typography color="blueGray">Currently Taking?</Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={8}>
                  <Grid item>
                    <Grid container alignItems="center" spacing={8}>
                      <Grid item>
                        <Radio
                          checked={currentlyTaking === true}
                          value="true"
                          classes={{ root: classes.radio }}
                          name={medKey}
                          onChange={this.updateCurrentlyTaking}
                        />
                      </Grid>
                      <Grid item>
                        <Typography color="blueGray">Yes</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" spacing={8}>
                      <Grid item>
                        <Radio
                          checked={currentlyTaking === false}
                          value="false"
                          classes={{ root: classes.radio }}
                          name={medKey}
                          onChange={this.updateCurrentlyTaking}
                        />
                      </Grid>
                      <Grid item>
                        <Typography color="blueGray">No</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.saveMed(
        this.props.id,
        this.state.name,
        this.state.currentlyTaking
      );
    }
  };

  updateName = (e) => {
    this.setState({
      name: e.target.value,
      ignoreEmptyMedName: false,
    });
  };

  editMed = () => this.props.editMed(this.props.id);

  saveMed = () => {
    if (this.state.name !== '') {
      this.props.saveMed(
        this.props.id,
        this.state.name,
        this.state.currentlyTaking
      );
    }
    this.setState({
      ignoreEmptyMedName: false,
    });
  };

  deleteMed = () => this.props.deleteMed(this.props.id);

  updateCurrentlyTaking = (e) => {
    const currentlyTaking = e.target.value === 'true';
    if (!this.props.selfAdded) {
      this.props.updatePrescriptionFeedback({
        rxId: this.props.id,
        currentlyTaking,
      });
    } else {
      this.props.saveMed(this.props.id, this.state.name, currentlyTaking);
    }
    this.setState({
      currentlyTaking,
    });
  };
}

Medication.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number,
  name: PropTypes.string,
  currentlyTaking: PropTypes.bool,
  drugStrength: PropTypes.string,
  editing: PropTypes.bool,
  deleteMed: PropTypes.func,
  editMed: PropTypes.func,
  saveMed: PropTypes.func,
  hideEdit: PropTypes.bool,
  medKey: PropTypes.string.isRequired,
  selfAdded: PropTypes.bool,
  updatePrescriptionFeedback: PropTypes.func,
};

export default withStyles(styles)(Medication);
