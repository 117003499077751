import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Page from '../common/Page';
import { GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import StickyBox from 'react-sticky-box/dist/esnext';
import styles from './PlanInformation.styles';
import { Document } from '../../styles/uhc-icons';

const PlanInformationMarkup = ({
  planInfo,
  selectCategory,
  activeCategory,
}) => {
  const activePlanInfo = _.get(planInfo, activeCategory);

  const planInfoComponent = () =>
    planInfo.map((info, idx) => {
      return (
        <a
          onClick={() => selectCategory(idx)}
          key={`pi_nav_item_${idx}`}
          style={[
            styles.tableOfContentsEntry,
            idx === activeCategory ? styles.activeEntry : {},
          ]}
        >
          {info.category}
        </a>
      );
    });

  return (
    <Page
      title={'Program Information'}
      uhcIcon={Document}
      iconStyle={{ backgroundColor: '#fff' }}
    >
      {planInfo.length > 0 ? (
        <div style={[styles.root, GlobalStyles.light]}>
          <div style={styles.sectionWrapper}>
            <div style={styles.tableOfContents}>
              <StickyBox style={styles.stickyTable} topOffset={100}>
                {planInfoComponent()}
              </StickyBox>
            </div>

            {/* eslint-disable react/no-danger */}
            <div
              style={styles.body}
              className="plan-info-section-wrapper"
              dangerouslySetInnerHTML={{
                __html: _.get(activePlanInfo, 'content'),
              }}
            />
            {/* eslint-enable react/no-danger */}
          </div>
        </div>
      ) : (
        <div style={styles.emptyPlanInfo}>
          No Program Information to display.
        </div>
      )}
    </Page>
  );
};

PlanInformationMarkup.propTypes = {
  planInfo: PropTypes.array,
  activeCategory: PropTypes.number,
  selectCategory: PropTypes.func,
};

export default Radium(PlanInformationMarkup);
