import React from 'react';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import EmployerGroupBanner from './EmployerGroupBanner';
import Hero from './hero/Hero';
import FeaturedItems from './FeaturedItemsMarkup';
import CarePlans from './care-plans/CarePlans';
import MyCarePlan from './my-care-plan/MyCarePlan';
import UnifiedCarePlan from './unified-care-plan/UnifiedCarePlan';
import Education from './Education';
import _ from 'lodash';
import Radium from 'radium';
import { Link } from 'react-router';

const styles = {
  root: {
    width: '100%',
    position: 'relative',
  },

  importantNote: {
    color: Colors.lightSlateGray,
    fontSize: '16px',
    lineHeight: '17px',
  },

  addGoal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: '500',
    color: Colors.popularGray,
    border: `2px dashed ${Colors.popularGray}`,
    height: '75px',
    marginBottom: '24px',
    cursor: 'pointer',
    transition: '1s ease',
    ':hover': {
      backgroundColor: 'rgba(255,255,255,0.7)',
    },
  },
};

const HealthOverviewPageMarkup = () => {
  const usesSingleCarePlan = AppConfig.validateFeature(
    Features.SINGLE_CARE_PLAN
  );

  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const unifiedCarePlan = AppConfig.validateFeature(Features.UNIFIED_CARE_PLAN);

  const hasAssignedEducation = !AppConfig.validateFeature(
    Features.NO_ASSIGNED_EDUCATION
  );

  const importantNoteHtml = _.get(
    AppConfig,
    'effectiveConfig.lingo.importantNoteHtml'
  );

  const uhcBranded = AppConfig.validateFeature(Features.UHC_BRANDED);

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {usesSingleCarePlan ? <EmployerGroupBanner /> : <Hero />}

      {uhcBranded && <FeaturedItems />}

      {usesSingleCarePlan ? (
        <MyCarePlan />
      ) : unifiedCarePlan ? (
        <UnifiedCarePlan />
      ) : (
        <CarePlans />
      )}

      {fiveFiveUI &&
        !unifiedCarePlan && (
          <Link to="/care-plans" style={{ textDecoration: 'none' }}>
            <div style={styles.addGoal}>Add Health Goal</div>
          </Link>
        )}

      {fiveFiveUI && hasAssignedEducation && !unifiedCarePlan && <Education />}

      {/* eslint-disable react/no-danger */
      importantNoteHtml && (
        <div
          style={styles.importantNote}
          dangerouslySetInnerHTML={{ __html: importantNoteHtml }}
        />
      )
      /* eslint-enable react/no-danger */
      }
    </div>
  );
};

export default Radium(HealthOverviewPageMarkup);
