import React from 'react';
import PropTypes from 'prop-types';
import ViewDocument from '../../../common/ViewDocument';
import { DrawerContent } from '../../../common/drawer2';

const HraTerms = () => {
  return (
    <DrawerContent>
      <ViewDocument documentFile="hra_terms_en.html" drawerFrame />
    </DrawerContent>
  );
};

HraTerms.propTypes = {
  title: PropTypes.string,
};

export default HraTerms;
