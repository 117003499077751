import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { compose, pure, withHandlers, withProps } from 'recompose';
import { withRouter } from 'react-router';
import { Colors, AppConfig } from '@healthmine/greyhound-core/src/modules';
import { WithCoverageSelection } from '@healthmine/greyhound-core/src/hocs';
import _ from 'lodash';

const { lingo } = AppConfig.effectiveConfig;

const CoverageViewer = ({
  coverageName,
  viewCoverageSettings,
  multiCoverage,
}) => {
  const styles = {
    root: {
      borderBottom: `1px solid ${Colors.lightGray}`,
      marginBottom: '24px',
      paddingBottom: '24px',
    },
    header: {
      fontWeight: 'bold',
      fontSize: '16px',
      marginBottom: '10px',
    },
    title: {
      fontSize: '16px',
      marginBottom: multiCoverage ? '10px' : '0px',
    },
    callToActionLink: {
      cursor: 'pointer',
      fontSize: '16px',
      color: Colors.linkBlue,
      textDecoration: 'none',
    },
  };

  return (
    <div style={styles.root}>
      <div style={styles.header}>My {lingo.coverageLabel}:</div>
      <div style={styles.title}>{coverageName}</div>
      {multiCoverage && (
        <a style={styles.callToActionLink} onClick={viewCoverageSettings}>
          Switch {lingo.coverageLabel}
        </a>
      )}
    </div>
  );
};

CoverageViewer.propTypes = {
  coverageName: PropTypes.string,
  viewCoverageSettings: PropTypes.func,
  multiCoverage: PropTypes.bool,
};

export default compose(
  pure,
  withRouter,
  WithCoverageSelection,

  withHandlers({
    viewCoverageSettings: ({ router }) => () => {
      router.push('settings/coverage');
    },
  }),

  withProps(({ memberCoverages, savedCoverageId }) => {
    const selectedCov = _.find(
      memberCoverages,
      (cov) => cov.memberCoverageId === savedCoverageId
    );
    return {
      coverageName: selectedCov ? selectedCov.coverageName : '',
      multiCoverage: memberCoverages.length > 1,
    };
  }),

  Radium
)(CoverageViewer);
