import { types } from '../actions/ThirdPartyCookieSupportActions';

const __INITIAL_THIRD_PARTY_COOKIE_SUPPORT_STATE__ = {
  thirdPartyCookieSupport: {
    supported: false,
    detected: false,
  },
};

function thirdPartyCookieSupport(
  state = __INITIAL_THIRD_PARTY_COOKIE_SUPPORT_STATE__.thirdPartyCookieSupport,
  action
) {
  switch (action.type) {
    case types.SET_THIRD_PARTY_COOKIE_SUPPORT:
      return {
        ...state,
        supported: action.supported,
        detected: true,
      };

    default:
      return state;
  }
}

export default {
  thirdPartyCookieSupport,
};
