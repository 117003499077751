import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { DrawerSection } from '../../../../common/drawer2';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import Typography from '../../../../common/Typography';
import { withStyles } from '@material-ui/core/styles';
import Frequency from '../../../../common/Frequency';
import { getWeeklySummary } from '../utils';
import moment from 'moment';
import { commaFormat } from '@healthmine/greyhound-core/src/utils';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';

const styles = () => ({
  details: {
    textAlign: 'center',
  },
  unit: {
    marginBottom: '20px',
  },
});

const dataSelector = (state, props) => props.tracker.dataSelector(state);
const latestWeekSelector = (state, props) =>
  props.tracker.weekSelector(state, 0);
const propSelector = createCachedSelector(
  dataSelector,
  latestWeekSelector,
  (data, latestWeekData) => ({
    data,
    latestWeekData,
    maxDate: _.head(_.last(data)),
  })
)((state, props) => props.tracker.dataKey);
const getSharedAnswerFromTrackerType = (sharedAnswers, trackerType) => {
  // Intercept our strangely named friend BLOOD_PRESSURE
  const _trackerType =
    trackerType === 'BLOOD_PRESSURE'
      ? AppConstants.HRAQuestionId.HNA_BP
      : trackerType;
  return sharedAnswers[
    _.keys(sharedAnswers).find((sharedConstant) =>
      sharedConstant.includes(_trackerType)
    )
  ];
};
const TrackerHeader = ({
  classes,
  tracker,
  latestWeekData,
  maxDate,
  sharedAnswers,
}) => {
  const latestWeek = moment(maxDate).startOf('w');
  const latestWeekSummary = getWeeklySummary(latestWeek, latestWeekData);
  const { type, currentValue } = tracker;
  const sharedAnswer = getSharedAnswerFromTrackerType(sharedAnswers, type);
  const value =
    _.has(sharedAnswer, 'value') && sharedAnswer.value != 'undefined/undefined'
      ? sharedAnswer.value !== 'NA'
        ? sharedAnswer.value
        : '---'
      : currentValue
        ? type === 'BLOOD_PRESSURE'
          ? currentValue
          : commaFormat(currentValue)
        : '---';

  return (
    <DrawerSection className={classes.details}>
      <Typography type="h1" color="mintJulep">
        {value}
      </Typography>
      <Typography className={classes.unit} type="h2" color="blueGray">
        {tracker.unit}
      </Typography>
      <Frequency frequencies={latestWeekSummary.map(({ value }) => !!value)} />
    </DrawerSection>
  );
};

TrackerHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  tracker: PropTypes.object.isRequired,
  latestWeekData: PropTypes.object,
  maxDate: PropTypes.number,
  sharedAnswers: PropTypes.object,
};

export default compose(
  pure,
  withStyles(styles),
  connect((state, props) => propSelector(state, props))
)(TrackerHeader);
