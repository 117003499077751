import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.globalStyles.borderRadius1,
    color: '#fff',
    opacity: 0,
    transition: theme.globalStyles.transition1,
    padding: '20px',
  },
  show: {
    opacity: 1,
  },
  icon: {
    fontSize: '30px',
  },
  whatsNext: {
    fontSize: '20px',
    marginTop: '10px',
    textAlign: 'center',
    flexGrow: 1,
  },
});

const Overlay = ({ actionTitle, classes, color, iconClass, visible }) => {
  return (
    <div
      className={`${classes.root} ${visible ? classes.show : ''}`}
      style={{ backgroundColor: color }}
    >
      <i className={`${iconClass} ${classes.icon}`} />
      <div style={{ marginTop: '10px' }}>What's Next:</div>
      <div className={classes.whatsNext}>{actionTitle}</div>
      <div>View Actions</div>
    </div>
  );
};

Overlay.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Overlay);
