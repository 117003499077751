import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { compose, pure } from 'recompose';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
} from '../../../../common/drawer2';
import Typography from '../../../../common/Typography';
import FrequencyBoxes from '../../../../common/widgets/FrequencyBoxes';
import StatusTitle from '../../../../common/StatusTitle';

const styles = () => ({
  progressTitle: {
    textAlign: 'center',
  },
});

const CoachingSessions = ({
  availabilityDescription,
  classes,
  compliantActivityCount,
  compliant,
  programStatusDescription,
  requiredActivityCount,
  sessionsComplianceStatus,
  sessionsComplianceSubtitle,
  theme,
}) => {
  return (
    <DrawerContent
      title={`Complete ${requiredActivityCount} coaching sessions`}
      status={sessionsComplianceStatus}
      subtitle={sessionsComplianceSubtitle}
      incentivesToEarn={!compliant}
    >
      <DrawerSection>
        <Typography type="h1" className={classes.progressTitle}>
          Progress
        </Typography>
        <FrequencyBoxes
          label="sessions completed"
          frequencyCount={3}
          markedCount={compliantActivityCount}
          markedColor={theme.palette.primary.main}
        />
      </DrawerSection>
      <DrawerSectionHeader title="Description" />
      <DrawerSection>
        <Typography type="body">
          {programStatusDescription}
          {'  '}
          {availabilityDescription}
        </Typography>
      </DrawerSection>
      {_.times(3, (idx) => {
        const isSessionNumberComplete = idx < compliantActivityCount;
        const status = isSessionNumberComplete ? 'positive' : 'neutral';

        return (
          <DrawerSection key={`coaching_session_${idx}`}>
            <StatusTitle
              title={`Coaching Session ${idx + 1}`}
              status={status}
            />
          </DrawerSection>
        );
      })}
    </DrawerContent>
  );
};

CoachingSessions.propTypes = {
  availabilityDescription: PropTypes.string,
  classes: PropTypes.object.isRequired,
  compliantActivityCount: PropTypes.number.isRequired,
  compliant: PropTypes.bool.isRequired,
  programStatusDescription: PropTypes.string.isRequired,
  requiredActivityCount: PropTypes.number.isRequired,
  sessionsComplianceStatus: PropTypes.string.isRequired,
  sessionsComplianceSubtitle: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

export default compose(pure, withTheme(), withStyles(styles))(CoachingSessions);
