import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import Measurements from '@healthmine/greyhound-core/src/modules/Measurements';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import DeviceConfiguration from './DeviceConfiguration';
import Step from '../Step';
import AttributeSelector from './AttributeSelector';

const STEPS = AppConstants.FitnessModule.ActivityType.STEPS;

const CALORIES = AppConstants.FitnessModule.ActivityType.CALORIES;

const FITNESS_UNITS = [STEPS, CALORIES];
const FREQUENCY = 2;
const FREQUENCIES = [FREQUENCY];

const convertFitnessUnit = (fitnessUnit) =>
  fitnessUnit === STEPS
    ? Measurements.MeasurementKeys.STEPS
    : Measurements.MeasurementKeys.WORKOUTS;

const FitnessStep = ({
  scrollId,
  active,
  next,
  skip,
  previousStep,
  manual,
  manualClicked,
  connectedClicked,
  fitnessUnit,
  fitnessUnitChanged,
  last,
  required,
}) => {
  const styles = {
    container: {
      marginTop: '60px',
      paddingLeft: '100px',
    },
    attributes: {
      display: 'flex',
      marginTop: '40px',
    },
  };

  const metadata = Measurements.fitnessMetadata;

  const copy = localizedContent.measurements.FITNESS.measurement;

  return (
    <Step
      scrollId={scrollId}
      active={active}
      nextStep={next}
      skipStep={skip}
      previousStep={previousStep}
      title={metadata.displayName}
      iconClass={metadata.icon}
      configured={false}
      last={last}
      summaryLines={[
        `Type: ${_.capitalize(fitnessUnit)}`,
        `Frequency: ${FREQUENCY}x Per Week`,
      ]}
      color={metadata.color}
      valid={manual !== undefined}
      required={!!required}
    >
      <div style={styles.container}>
        <div>
          <DeviceConfiguration
            copy={copy}
            frequency={FREQUENCY}
            manual={manual}
            manualClicked={manualClicked}
            connectedClicked={connectedClicked}
          />
          <div style={styles.attributes}>
            <AttributeSelector
              label="Type"
              value={fitnessUnit}
              valueChanged={fitnessUnitChanged}
              attributes={FITNESS_UNITS}
            />
            <AttributeSelector
              label="Frequency"
              attributes={FREQUENCIES}
              template={'x a week'}
              value={FREQUENCY}
            />
          </div>
        </div>
      </div>
    </Step>
  );
};

FitnessStep.propTypes = {
  scrollId: PropTypes.string.isRequired,
  previousStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  last: PropTypes.bool,
  required: PropTypes.bool,

  configuration: PropTypes.oneOf([
    Measurements.MeasurementKeys.STEPS,
    Measurements.MeasurementKeys.WORKOUTS,
  ]),
  fitnessUnit: PropTypes.oneOf(FITNESS_UNITS),

  manual: PropTypes.bool,
  manualClicked: PropTypes.func,
  connectedClicked: PropTypes.func,
  fitnessUnitChanged: PropTypes.func,

  next: PropTypes.func,
  skip: PropTypes.func,
};

export default compose(
  withState('manual', 'setManual', undefined),
  withState('fitnessUnit', 'setFitnessUnit', STEPS),
  withHandlers({
    manualClicked: ({ setManual }) => () => setManual(true),
    connectedClicked: ({ setManual }) => () => setManual(false),
    fitnessUnitChanged: ({ setFitnessUnit }) => (event) =>
      setFitnessUnit(event.target.value),
    next: ({ nextStep, fitnessUnit, setTracking }) => () => {
      setTracking(convertFitnessUnit(fitnessUnit));
      nextStep();
    },
    skip: ({ nextStep, setTracking }) => () => {
      setTracking(null);
      nextStep();
    },
  }),
  Radium
)(FitnessStep);
