import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Radium, { StyleRoot } from 'radium';
import { UserInfoActions } from '@healthmine/greyhound-core/src/actions';
import RetrieveAccountMarkup from '../../components/registration/RetrieveAccountMarkup';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import { hashHistory } from 'react-router';
import _ from 'lodash';
import { Features } from '@healthmine/greyhound-core/src/constants';

class RetrieveAccountPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopUpOpen: false,
      errors: [],
      validatedFields: [],
      lastName: {},
      dateOfBirth: {},
      memberId: {},
      firstName: {},
      isFetching: false,
    };
  }

  render() {
    const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);

    const imgContainer = {
      fontSize: '16px',
      overflowY: 'auto',
      position: 'relative',
      color: Colors.light,
      backgroundColor: leapFrogUI ? 'none' : Colors.light,
      overflow: 'hidden',
    };

    const bgImg = {
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      width: 'inherit',
      overflowX: 'hidden',
      overflowY: 'auto',
      backgroundImage: 'none',
    };

    const { lastName, dateOfBirth, memberId, firstName } = this.state;
    return (
      <StyleRoot style={[imgContainer, GlobalStyles.regular]} className="index">
        <div style={bgImg}>
          <RetrieveAccountMarkup
            formState={{ lastName, dateOfBirth, memberId, firstName }}
            onLastNameChange={this._setLastName}
            onMemberIdChange={this._setMemberId}
            onDOBChange={this._setDOB}
            errors={this.state.errors}
            isFetching={this.state.isFetching}
            onClickHandler={this._continueClickHander}
            isPopUpOpen={this.state.isPopUpOpen}
            validatedFields={this.state.validatedFields}
            validateField={this._validateField}
            onPopUpChange={this._handlePopUp}
            onFirstNameChange={this._setFirstName}
          />
        </div>
      </StyleRoot>
    );
  }

  _handlePopUp = () => {
    let isPopUpOpen = !this.state.isPopUpOpen;
    this.setState({ isPopUpOpen });
  };

  _validateField = (feild) => {
    if (this.state.validatedFields.indexOf(feild) === -1)
      this.setState({
        validatedFields: [...this.state.validatedFields, feild],
      });
  };

  _setDOB = (value) => {
    let isValid = true;
    if (value === undefined || value.toString() === 'Invalid Date') {
      isValid = false;
    }
    const currDOB = this.state.dateOfBirth;

    const dateOfBirth = { ...currDOB, value, isValid };
    this.setState({ dateOfBirth });
  };

  _setLastName = (value) => {
    let isValid = true;
    if (value === '' || value === undefined) {
      isValid = false;
    }
    const currLastName = this.state.lastName;

    const lastName = { ...currLastName, value, isValid };
    this.setState({ lastName });
  };

  _setFirstName = (value) => {
    let isValid = true;
    if (value === '' || value === undefined) {
      isValid = false;
    }
    const currFirstName = this.state.firstName;

    const firstName = { ...currFirstName, value, isValid };
    this.setState({ firstName });
  };

  _setMemberId = (value) => {
    const memberIdRegEx = new RegExp(
      _.get(AppConfig, 'effectiveConfig.registration.memberIdRegEx', '.*')
    );
    let isValid = true;

    let error = error ? error : undefined;
    if (value === '' || value === undefined) {
      isValid = false;
    } else if (!memberIdRegEx.test(value)) {
      isValid = false;
      error = 'Please Enter Valid Member Number';
    }
    /* eslint-disable one-var  */
    const currMemberId = this.state.memberId,
      memberId = { ...currMemberId, value, isValid, error };
    /* eslint-enable one-var  */
    this.setState({ memberId });
  };

  _formIsValid = () => {
    let formIsValid = true;
    const date = this.state.dateOfBirth.value;

    if (
      this.state.firstName.value == '' ||
      this.state.firstName.value == undefined
    ) {
      formIsValid = false;
      this._setFirstName(this.state.firstName.value);
    }

    if (
      this.state.lastName.value == '' ||
      this.state.lastName.value == undefined
    ) {
      formIsValid = false;
      this._setLastName(this.state.lastName.value);
    }

    if (
      date.toString() === 'Invalid Date' ||
      this.state.dateOfBirth.value == '' ||
      this.state.dateOfBirth.value == undefined
    ) {
      formIsValid = false;
      this._setDOB(this.state.dateOfBirth.value);
    }

    if (
      this.state.memberId.value == '' ||
      this.state.memberId.value == undefined
    ) {
      formIsValid = false;
      this._setMemberId(this.state.memberId.value);
    }

    return formIsValid;
  };

  _continueClickHander = () => {
    if (this._formIsValid() == false) {
      return;
    }
    const { lastName, dateOfBirth, memberId, firstName } = this.state;

    const { initiatePasswordReset } = this.props.actions;

    this.setState({ isFetching: true }, () => {
      initiatePasswordReset(
        lastName.value,
        dateOfBirth.value,
        memberId.value,
        firstName.value
      )
        .then((status) => {
          if (status === 200) {
            this._handleSuccess();
          } else {
            this._handleFailure(status);
          }
        })
        .catch(() => this._handleFailure());
    });
  };

  _handleSuccess = () => {
    hashHistory.push('/return/');
    this.setState({ isFetching: false });
  };

  _handleFailure = (status) => {
    const errorMessage =
      status === 404
        ? 'Information Entered is Not Found'
        : 'Something went wrong please try again in some time';
    this.setState({ errors: [errorMessage], isFetching: false });
  };
}

RetrieveAccountPage.propTypes = {
  actions: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    state: {
      userInfo: state.userInfo,
    },
  };
}

function mapDispatchToProps(dispatch) {
  const { initiatePasswordReset } = UserInfoActions;
  return {
    actions: bindActionCreators({ initiatePasswordReset }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(RetrieveAccountPage)
);
