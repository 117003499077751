import React from 'react';
import DemofyBiometricScreening from './DemofyBiometricScreening';
import DemofyCoaching from './DemofyCoaching';
import DemofyCompliance from './DemofyCompliance';

const Demofy = () => {
  return (
    <div>
      <DemofyBiometricScreening />
      <DemofyCoaching />
      <DemofyCompliance />
    </div>
  );
};

export default Demofy;
