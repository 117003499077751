import React from 'react';
import PropTypes from 'prop-types';
import List, { ListHeader, ListContent } from '../../../common/list2/List';
import ScreeningProcedureItem from './ScreeningProcedureItem';
import { compose, withProps } from 'recompose';
import _ from 'lodash';
import moment from 'moment';

const ScreeningProcedureList = ({ items, itemCount }) => {
  return (
    <List disabled={!itemCount}>
      <ListHeader
        iconClass="icon-Medicine-Pills"
        title="Screening &amp; Procedures"
        color="#f26436"
        subtitle={itemCount !== 0 ? `${itemCount} total` : undefined}
      />
      <ListContent
        isEmpty={!itemCount}
        emptyMessage="No screenings or procedures found."
      >
        {items.map(({ display, date }, idx) => (
          <ScreeningProcedureItem
            key={`procedure-${idx}`}
            display={display}
            dateOfService={moment(date).format('MM/DD/YYYY')}
          />
        ))}
      </ListContent>
    </List>
  );
};

ScreeningProcedureList.propTypes = {
  items: PropTypes.array.isRequired,
  itemCount: PropTypes.number.isRequired,
};

export default compose(
  withProps(({ items }) => ({
    itemCount: _.get(items, 'length', 0),
  }))
)(ScreeningProcedureList);
