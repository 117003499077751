import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, pure, withHandlers, withProps } from 'recompose';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import withLinks from './withLinks';
import Chip from '../common/Chip';
import { get } from 'lodash';
import CheckoutSummary from '../pages/incentives/ShoppingCart/CheckoutSummary';
import { withDrawer } from '../common/drawer2';

const styles = (theme) => ({
  root: {
    marginTop: '-40px',
    flex: '0 0 120px',
  },
  link: {
    display: 'block',
    margin: '40px 0',
    cursor: 'pointer',
    transition: theme.globalStyles.transition1,
    textAlign: 'center',
    color: theme.palette.primary.main,
    maxWidth: '80px',
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  active: {
    color: theme.palette.common.beefy,
    '&:hover': {
      color: theme.palette.common.beefy,
    },
  },
  icon: {
    fontSize: '40px',
    display: 'block',
  },
  title: {
    marginTop: '10px',
    fontWeight: 500,
  },
  badgeIconWrapper: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    right: '-20px',
    top: '-20px',
  },
});

const Navigation = ({ classes, links, isLinkActive, navigate }) => {
  return (
    <div className={classes.root}>
      {links.map(
        ({ title, iconClass, route, badgeText, actionOverride }, i) => (
          <a
            key={i}
            className={classnames(classes.link, {
              [classes.active]: isLinkActive(route),
            })}
            onClick={() => navigate(route, actionOverride)}
          >
            {badgeText ? (
              <div className={classes.badgeIconWrapper}>
                <Chip
                  className={classes.badge}
                  label={badgeText}
                  color="grannySmith"
                />
                <i className={classnames(iconClass, classes.icon)} />
              </div>
            ) : (
              <i className={classnames(iconClass, classes.icon)} />
            )}
            <div className={classes.title}>{title}</div>
          </a>
        )
      )}
    </div>
  );
};

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
  isLinkActive: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  pure,
  withRouter,
  withLinks,
  withDrawer,
  withProps(({ links }) => ({
    links: links.filter(({ drawerOnly }) => !drawerOnly),
  })),
  withHandlers({
    navigate: ({ router, location, drawer }) => (route, actionOverride) => {
      // fixes subsequent clicks on Cart nav icon
      if (get(location, 'pathname') === '/rewards' && actionOverride) {
        actionOverride();
        drawer.open(CheckoutSummary, null, {
          large: true,
          onClose: () => router.push('/rewards'),
        });
      } else if (actionOverride) actionOverride();
      else router.push(route);
    },
  }),
  withStyles(styles)
)(Navigation);
