import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Scroll from 'react-scroll';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

const CarePlanTitleMarkup = ({
  name,
  scrollId,
  description,
  init,
  iconClass,
  continueHandler,
}) => {
  const ScrollElement = Scroll.Element;

  const bgImage = require('../../images/BubbleBG.png');

  const styles = {
    mainContainer: {
      padding: '60px 0',
      borderTop: `1px solid ${Colors.gutterGray}`,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      marginBottom: '80px',
      minHeight: '108px',
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    titleWrapper: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'flex-start',
      paddingLeft: '200px',
    },
    titleText: {
      maxWidth: 'calc(100% - 95px)', //IEFix
    },
    icon: {
      fontSize: '40px',
      width: '40px',
      height: '40px',
      padding: '20px',
      borderRadius: '50%',
      backgroundColor: Colors.lightGreen,
      color: Colors.light,
      marginRight: '15px',
    },
    title: {
      fontSize: '26px',
      fontWeight: 300,
      margin: 0,
    },
    subtitle: {
      fontSize: '18px',
      margin: '3px 0',
    },
    btn: {
      display: 'flex',
      alignItems: 'center',
      color: Colors.linkBlue,
      marginTop: '25px',
      cursor: 'pointer',
    },
    btnText: {
      fontSize: '18px',
      display: 'inline-block',
      margin: '0 10px 0 0',
    },
    btnIcon: {
      display: 'inline-block',
      height: '30px',
      width: '30px',
      transform: 'rotate(90deg)',
    },
  };

  const piconRight = (
    <i className="thin-0160_arrow_next_right" style={{ fontSize: '30px' }} />
  );

  return (
    <ScrollElement name={scrollId}>
      <div style={[styles.mainContainer, GlobalStyles.extraLight]}>
        <div style={styles.titleWrapper}>
          <div style={styles.icon}>
            <i className={iconClass} />
          </div>
          <div style={styles.titleText}>
            <h1 style={styles.title}>{name}</h1>
            <p style={styles.subtitle}>{description}</p>
            {init && (
              <div style={styles.btn} onClick={continueHandler}>
                <span style={styles.btnText}>Get Started</span>
                <div style={styles.btnIcon}>{piconRight}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ScrollElement>
  );
};

CarePlanTitleMarkup.propTypes = {
  name: PropTypes.string.isRequired,
  scrollId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  init: PropTypes.bool.isRequired,
  continueHandler: PropTypes.func.isRequired,
};

export default Radium(CarePlanTitleMarkup);
