import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import {
  AppConfig,
  GlobalStyles,
  Assets,
} from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import HeroStyles from './Hero.styles';
import BiometricScreeningCard from './hero-card/BiometricScreeningCard';
import HRACard from './hero-card/HRACard';
import PreventiveVisitCard from './hero-card/PreventiveVisitCard';

const HeroMarkup = ({ firstName, hasBiometricScreening }) => {
  const bgImage = require('../../../images/BubbleBG.png');

  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const dashboardHeroImage = AppConfig.validateFeature(
    Features.DASHBOARD_HERO_IMAGE
  );

  const styles = fiveFiveUI ? HeroStyles.newStyles : HeroStyles.oldStyles;

  const { clientDisplayName } = AppConfig.effectiveConfig;

  if (dashboardHeroImage) {
    return (
      <div style={styles.employerHero}>
        <img
          style={styles.heroImage}
          src={Assets.getAssetURL('hero_watermark.png')}
        />
        <div style={styles.heroText}>
          <div style={styles.imageWelcome}>
            Welcome, {firstName.toLowerCase()}!
          </div>
          <div style={styles.callToActionText}>{clientDisplayName}</div>
        </div>
      </div>
    );
  }

  let LeftCard;
  let MiddleCard;
  let RightCard;

  if (hasBiometricScreening) {
    LeftCard = <BiometricScreeningCard />;
    MiddleCard = <HRACard />;
    RightCard = <PreventiveVisitCard />;
  } else {
    LeftCard = <HRACard />;
    MiddleCard = <PreventiveVisitCard />;
  }

  if (fiveFiveUI) {
    return (
      <div>
        <div style={styles.welcome}>Welcome, {firstName}!</div>
        <div style={styles.cardWrapper}>
          <div style={styles.leftCardWrapper}>{LeftCard}</div>
          <div style={styles.middleCardWrapper}>{MiddleCard}</div>
          <div style={styles.rightCardWrapper}>{RightCard}</div>
        </div>
      </div>
    );
  }

  return (
    <StyleRoot
      style={[
        GlobalStyles.light,
        styles.root,
        { backgroundImage: `url(${bgImage})` },
      ]}
    >
      <div style={styles.welcomeBlock}>
        <p style={styles.primaryText}>Hello {firstName.toLowerCase()},</p>
        <p style={styles.secondaryText}>
          Welcome to <span style={styles.highlight}>{clientDisplayName}!</span>
        </p>
      </div>

      <div style={styles.guideBlock}>
        <div style={styles.scaleContainer}>
          <div style={styles.scaleLine}>
            <div style={styles.bubbleContainer}>
              <div style={[styles.bubble, styles.bubbleBlue]}>1</div>
              <div style={[styles.bubble, styles.bubbleGreen]}>2</div>
              <div style={[styles.bubble, styles.bubbleRed]}>3</div>
            </div>

            <div style={styles.labelContainer}>
              <div style={styles.label}>
                Understand Your Health
                <i
                  style={styles.icon}
                  className="thin-0825_stetoscope_doctor_hospital_ill"
                />
              </div>
              <div style={styles.label}>
                Build a Care Plan
                <i
                  style={styles.icon}
                  className="thin-0244_text_bullets_list"
                />
              </div>
              <div style={styles.label}>
                Earn Fun Rewards!
                <i
                  style={styles.icon}
                  className="thin-0670_diamond_prize_award_jewellery_ring"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyleRoot>
  );
};

HeroMarkup.propTypes = {
  firstName: PropTypes.string,
  hasBiometricScreening: PropTypes.bool,
};

HeroMarkup.defaultProps = {
  firstName: '',
};

export default Radium(HeroMarkup);
