import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, pure, withState, withHandlers, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import DrawerSection from './DrawerSection';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '../../common/Typography';
import Navigational from '../icons/Navigational';

const styles = (theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  title: {
    fontSize: '20px',
  },
  subLabel: {
    color: theme.palette.common.beefy,
  },
  menuItem: {
    width: '250px',
  },
  menuItemCheck: {
    color: theme.palette.common.beefy,
    fontSize: '20px',
  },
  menuItemNotCheck: {
    color: theme.palette.common.beefy,
    height: '20px',
    width: '20px',
    borderRadius: '20px',
    border: '2px solid',
    boxSizing: 'border-box',
  },
});

const DrawerSelect = ({
  classes,
  anchorEl,
  openMenu,
  closeMenu,
  optionsWithOnClick,
  title,
  value,
  subLabel,
}) => {
  return (
    <Fragment>
      <DrawerSection onClick={openMenu}>
        <div
          className={classes.main}
          aria-owns={anchorEl ? 'drawer-select-menu' : null}
          aria-haspopup="true"
        >
          <div>
            <div className={classes.title}>{title}</div>
            <div className={classes.subLabel}>{subLabel}</div>
          </div>
          <div>
            <Navigational iconClass="icon-Down-Chevron-Circle" />
          </div>
        </div>
      </DrawerSection>
      <Menu
        id="drawer-select-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {_.map(
          optionsWithOnClick,
          ({ label, subLabel, onClick, value: optionValue }, idx) => {
            return (
              <MenuItem
                onClick={onClick}
                key={`drawer_select_option_${idx}`}
                className={classes.menuItem}
              >
                <Grid container justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    {optionValue === value ? (
                      <i
                        className={classnames(
                          'icon-Checkmark-Circle',
                          classes.menuItemCheck
                        )}
                      />
                    ) : (
                      <div className={classes.menuItemNotCheck} />
                    )}
                  </Grid>
                  <Grid item xs={10}>
                    <Typography type="h4">{label}</Typography>
                    {subLabel && (
                      <Typography type="h4" color="blueGray">
                        {subLabel}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </MenuItem>
            );
          }
        )}
      </Menu>
    </Fragment>
  );
};

DrawerSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  openMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  optionsWithOnClick: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      subLabel: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subLabel: PropTypes.string,
};

export default compose(
  pure,
  withState('anchorEl', 'updateAnchorEl', null),
  withHandlers({
    openMenu: ({ updateAnchorEl }) => (event) => {
      updateAnchorEl(event.currentTarget);
    },
    closeMenu: ({ updateAnchorEl }) => () => {
      updateAnchorEl(null);
    },
  }),
  withProps(({ options, onChange, closeMenu, value }) => ({
    optionsWithOnClick: _.map(options, (option) => ({
      ...option,
      onClick: () => {
        onChange(option.value);
        closeMenu();
      },
    })),
    title: _.get(
      _.find(options, (option) => option.value === value),
      'label',
      ''
    ),
  })),
  withStyles(styles)
)(DrawerSelect);
