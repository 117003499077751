import React from 'react';
import Radium from 'radium';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';

const PostPasswordResetPageMarkup = () => {
  const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);
  const styles = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',

      color: Colors.darkText,
      backgroundColor: leapFrogUI ? 'transparent' : Colors.light,
      marginTop: '30px',
      boxSizing: 'border-box',
    },

    contentContainer: {
      display: 'flex',
      alignItems: 'center',
    },

    iconContainer: {
      marginRight: '20px',
      width: '75px',
      height: '75px',
      backgroundColor: Colors.bodyText,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    icon: {
      fontSize: '35px',
      color: Colors.white,
    },

    header: {
      fontSize: '24px',
      margin: '0 0 15px',
      color: Colors.black,
    },

    subHeader: {
      color: Colors.black,
      maxWidth: '520px',
      fontSize: '16px',
      margin: '0',
    },
  };

  return (
    <div style={styles.root}>
      <div style={styles.contentContainer}>
        <span style={styles.iconContainer}>
          <i className="thin-0632_security_lock" style={styles.icon} />
        </span>

        <span>
          <h2 style={[styles.header, GlobalStyles.extraLight]}>
            Password Reset Successfully
          </h2>

          <h3 style={[styles.subHeader, GlobalStyles.regular]}>
            Your password has been reset successfully. You may now use it to
            sign in to the mobile application.
          </h3>
        </span>
      </div>
    </div>
  );
};

export default Radium(PostPasswordResetPageMarkup);
