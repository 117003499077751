import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  ListItem,
  ListItemStyles as styles,
} from '../../../../../common/list/List';
import { withDrawer } from '../../../../../common/drawer2';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import StatusTitle from '../../../../../common/StatusTitle';
import { Grid } from '@material-ui/core';
import { lowerCase, some } from 'lodash';
import StartTracking from '../../../tracking/StartTracking';
import Tracker from '../../../tracking/tracker';
import classnames from 'classnames';

const TrackerAction = ({ classes, tracker, drawer }) => {
  const { displayName, unit, isActive, currentValue, dataSources } = tracker;
  const isComplete = !!currentValue;
  const trackerDrawer = isActive ? Tracker : StartTracking;
  const points = IncentiveSummary.formatPoints(tracker.maxDailyRewardAmount);

  return (
    <ListItem onClick={() => drawer.open(trackerDrawer, { tracker })}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={10}>
          <StatusTitle
            status={isComplete ? 'positive' : 'neutral'}
            title={`${currentValue || 0} ${unit}`}
            subtitle={displayName}
          />
        </Grid>
        <Grid item>
          <div className={classes.incentiveConditionsWrapper}>
            <div
              className={classnames(
                classes.incentive,
                classes.incentiveToBeEarned
              )}
            >
              {`Earn up to ${lowerCase(points)} daily`}
            </div>
            {some(dataSources) && (
              <div className={classes.reason}>Device Connected</div>
            )}
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
};

TrackerAction.propTypes = {
  classes: PropTypes.object,
  tracker: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
};

export default compose(pure, withDrawer, withStyles(styles))(TrackerAction);
