import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemStyles as styles } from '../../common/list/List';
import moment from 'moment';
import { flow, getOr, map, merge } from 'lodash/fp';
import {
  AppConfig,
  IncentiveSummary,
} from '@healthmine/greyhound-core/src/modules';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { withDrawer } from '../../common/drawer2';
import Chips from '../../common/Chips';
import StatusTitle from '../../common/StatusTitle';
import EducationDrawerContent from './EducationDrawerContent';
import { Features } from '@healthmine/greyhound-core/src/constants';
import Typography from '../../common/Typography';

const itemStyles = (theme) =>
  merge(styles(theme), {
    complete: {
      color: theme.palette.common.blueGray,
    },
  });

const EducationItem = ({ education, open, classes }) => {
  const { completed } = education;
  const incentiveClass = classNames(
    classes.incentive,
    completed ? classes.complete : classes.incentiveToBeEarned
  );
  const subtitle = completed
    ? `viewed on ${moment(education.completionDate).format('MM/DD/YYYY')}`
    : 'not viewed';
  const conditions = flow([getOr([], 'context'), map('tag')])(education);
  const assignedEducation = !AppConfig.validateFeature(
    Features.NO_ASSIGNED_EDUCATION
  );

  return (
    <ListItem onClick={open}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={9}>
          {assignedEducation ? (
            <StatusTitle
              title={education.title}
              subtitle={subtitle}
              status={completed ? 'positive' : 'neutral'}
            />
          ) : (
            <Typography type="h2">{education.title}</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <div className={classes.incentiveConditionsWrapper}>
            <div className={incentiveClass}>
              {education.rewardAmount &&
                IncentiveSummary.getIncentiveEarningText(
                  completed,
                  education.rewardAmount
                )}
            </div>
            <Chips color="venus" labels={conditions} />
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
};

EducationItem.propTypes = {
  classes: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
  education: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
  trackEducation: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withDrawer,
  withHandlers({
    open: ({ drawer, education, trackEducation }) => () => {
      trackEducation(education.contentId);
      drawer.open(EducationDrawerContent, { education }, { large: true });
    },
  }),
  withStyles(itemStyles)
)(EducationItem);
