import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import { Link } from 'react-router';
import Select from 'react-select';
import Button from '../../common/Button';
import InfoPopup from '../../common/InfoPopup';

const ShoppingCartMarkup = ({
  shoppingCartItems,
  updateDenomination,
  updateQuantity,
  updateDelivery,
  removeFromCart,
  updateCart,
  points,
}) => {
  const styles = {
    image: {
      width: '120px',
      height: '75px',
    },
    imageOverlay: {
      position: 'absolute',
      width: '150px',
      height: '150px',
      background: 'rgba(255,255,255,0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0,
      ':hover': {
        opacity: 1,
      },
    },
    select: {
      width: '80px',
      fontSize: '16px',
      height: '34px',
      color: Colors.lightGray3,
      borderRadius: '2px',
      padding: '0 5px',
    },
    overlayIcon: {
      fontSize: '28px',
    },
    root: {
      paddingTop: '20px',
      paddingBottom: '40px',
    },
    titleText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: Colors.bodyText,
      fontSize: '24px',
      marginLeft: '20px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '82px',
      paddingRight: '82px',
    },

    header: {
      fontSize: '16px',
      color: Colors.navyBlue,
      paddingBottom: '20px',
      width: '100%',
      marginBottom: '10px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },

    table: {
      textAlign: 'left',
      display: 'flex',
      fontSize: '16px',
      width: '100%',
      padding: '20px 0px',
    },
    editBtn: {
      fontSize: '16px',
      color: Colors.linkBlue,
      marginLeft: '30px',
      textDecoration: 'none',
      marginTop: '3px',
    },
    tableHeader: {
      display: 'flex',
    },
    itemImage: {
      width: '120px',
      height: '75px',
    },
    description: {
      marginLeft: '20px',
      width: '375px',
    },
    label: {
      fontSize: '16px',
      fontWeight: 600,
    },
    subLabel: {
      fontSize: '16px',
      color: Colors.contentText,
    },
    contentTable: {
      marginLeft: '25px',
      marginRight: '-20px',
      width: '100%',
    },
    contentHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.mediumGray2,
      marginBottom: '5px',
    },

    contentSelect: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    column: {
      flex: 1,
    },

    dropdownSelectionContainer: {
      marginRight: '30%',
    },

    deliveryMethodContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    deliveryMethodInfoPopup: {
      icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14px',
        height: '14px',
        lineHeight: '14px',
        fontSize: '16px',
        color: Colors.white,
        backgroundColor: Colors.dark,
      },

      popupContainer: {
        left: '18px',
        right: 'initial',
      },

      popupContent: {
        borderRadius: '0 10px 10px 10px',
        backgroundColor: Colors.lightenColor(Colors.lightGray, 2),
        padding: '10px 20px',
      },

      popupStem: {
        left: 0,
        right: 'initial',
        top: '-19px',
        borderTop: '20px solid transparent',
        borderLeft: `15px solid ${Colors.lightenColor(Colors.lightGray, 2)}`,
        borderBottom: '0 solid transparent',
        borderRight: '0 solid transparent',
      },
    },

    deliveryMethodInfoPopupContent: {
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: 400,
      width: '240px',
    },

    deliveryMethodOption: {
      margin: '3px 0px',
      fontSize: '16px',
      cursor: 'pointer',
    },

    deliveryMethodOptionRadioButton: {
      marginRight: '10px',
    },

    input: {
      border: 'none',
      width: '250px',
      marginLeft: '20px',
      height: '30px',
      fontSize: '16px',
      outline: 'none',
      fontWeight: 400,
    },
    inputLabel: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.black,
      width: '80px',
      display: 'inline-block',
    },
    inputContainer: {
      margin: '10px 0px',
    },
    footer: {
      fontSize: '16px',
      marginTop: '10px',
      fontWeight: '500',
    },
    footerContainer: {
      textAlign: 'right',
      marginTop: '30px',
      paddingTop: '25px',
      borderTop: `1px solid ${Colors.gutterGray}`,
    },
    button: {
      width: '220px',
      padding: '8px',
      fontSize: '16px',
    },
    removeLabel: {
      color: '#c93940',
      textAlign: 'right',
      cursor: 'pointer',
      paddingTop: '15px',
      paddingRight: '22px',
      fontWeight: 600,
    },
    noItems: {
      textAlign: 'center',
      fontSize: '20px',
      height: '300px',
      lineHeight: '10',
    },

    quantitySelect: {
      width: '70px',
      fontSize: '16px',
      height: '32px',
      color: 'rgb(146, 174, 186)',
      borderRadius: '2px',
      border: 'thin solid rgb(169, 169, 169)',
      padding: '0 0 0 10px',
    },
  };

  const { setCurrencyLabel } = RewardsConstants;

  const RadiumLink = Radium(Link);

  const shoppingCartContents = shoppingCartItems.map(
    (shoppingCartItem, index) => (
      <div
        style={styles.table}
        key={`${shoppingCartItem.productId}${shoppingCartItem.denomination}${
          shoppingCartItem.method
        }`}
      >
        <span>
          <img
            src={shoppingCartItem.imageUrl}
            style={styles.itemImage}
            alt={`${shoppingCartItem.name} Card`}
            title={`${shoppingCartItem.name} Card`}
          />
        </span>
        <span style={styles.description}>
          <div style={styles.label}>{shoppingCartItem.name}</div>
        </span>
        <span style={styles.contentTable}>
          <div style={styles.contentHeader}>
            <label style={styles.column}>Denomination</label>
            <label style={styles.column}>Quantity</label>
            <label style={styles.column}>
              Delivery Method
              <InfoPopup styles={styles.deliveryMethodInfoPopup}>
                <div style={styles.deliveryMethodInfoPopupContent}>
                  {shoppingCartItem.deliveryOptions.length > 1
                    ? `Select email delivery for the quickest access to your rewards (same day delivery).
                    Check your Message Center on the Healthy Rewards portal within 12 hours for your e-code(s) and redemption instructions.
                    If you choose standard mail, please allow 7-10 business days for delivery of your gift card(s).`
                    : shoppingCartItem.deliveryOptions[0].value ===
                      RewardsConstants.RewardDeliveryForm.DIGITAL
                      ? 'Check your Message Center on the Healthy Rewards portal within 12 hours for your e-code(s) and redemption instructions.'
                      : 'Please allow for 7-10 business days for delivery of your gift card(s).'}
                </div>
              </InfoPopup>
            </label>
          </div>

          <div style={styles.contentSelect}>
            <div style={styles.column}>
              {shoppingCartItem.denominations.length === 1 ? (
                setCurrencyLabel(shoppingCartItem.denominations[0])
              ) : (
                <div style={styles.dropdownSelectionContainer}>
                  <Select
                    searchable={false}
                    value={shoppingCartItem.denomination}
                    options={shoppingCartItem.denominations.map(
                      (denomination) => ({
                        label: setCurrencyLabel(denomination),
                        value: denomination,
                      })
                    )}
                    onChange={(selectedItem) =>
                      updateDenomination(
                        shoppingCartItem,
                        selectedItem.value,
                        index
                      )
                    }
                  />
                </div>
              )}
            </div>

            <div style={styles.column}>
              <div style={styles.dropdownSelectionContainer}>
                <Select
                  searchable={false}
                  value={shoppingCartItem.quantity}
                  options={_.range(1, 5).map((quantity) => ({
                    label: quantity,
                    value: quantity,
                  }))}
                  onChange={(selectedItem) =>
                    updateQuantity(shoppingCartItem, selectedItem.value, index)
                  }
                />
              </div>
            </div>

            <div style={styles.column}>
              {shoppingCartItem.deliveryOptions.length === 1 ? (
                shoppingCartItem.deliveryOptions[0].label
              ) : (
                <div style={styles.deliveryMethodContainer}>
                  {shoppingCartItem.deliveryOptions.map((option) => (
                    <label
                      key={option.value}
                      style={[GlobalStyles.light, styles.deliveryMethodOption]}
                    >
                      <input
                        type="radio"
                        name={`DeliveryMethod${shoppingCartItem.productId}`}
                        value={option.value}
                        onChange={(evt) =>
                          updateDelivery(
                            shoppingCartItem,
                            evt.target.value,
                            index
                          )
                        }
                        style={styles.deliveryMethodOptionRadioButton}
                        checked={shoppingCartItem.method === option.value}
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div style={styles.removeLabel} onClick={() => removeFromCart(index)}>
            Remove
          </div>
        </span>
      </div>
    )
  );

  let total = 0;

  shoppingCartItems.map((item) => {
    total += item.quantity * item.denomination;
  });

  return (
    <div style={styles.root}>
      {shoppingCartItems.length > 0 && (
        <div>
          <div style={styles.content}>
            <div style={styles.tableHeader}>
              <span style={[GlobalStyles.regular, styles.header]}>
                Items In Cart
              </span>
            </div>
            <div>{shoppingCartContents}</div>
            <div style={styles.footerContainer}>
              <div style={[GlobalStyles.regular, styles.footer]}>
                Card Total: {setCurrencyLabel(total)}
              </div>
              <div style={[GlobalStyles.regular, styles.footer]}>
                Your Balance: {setCurrencyLabel(points - total)}
              </div>
            </div>
            <div style={{ marginTop: '40px' }}>
              <Button
                style={[
                  styles.button,
                  GlobalStyles.regular,
                  { width: '200px' },
                ]}
                onClick={updateCart}
                text="Update Cart"
              />
              <RadiumLink
                to="/rewards/shop/"
                style={[GlobalStyles.regular, styles.editBtn]}
              >
                <span style={{ marginLeft: '20px' }}>Continue Shopping</span>
              </RadiumLink>
            </div>
          </div>
        </div>
      )}
      {shoppingCartItems.length === 0 && (
        <div style={styles.noItems}>
          There are no items in your cart
          <div style={[styles.content, { textAlign: 'left' }]}>
            <RadiumLink
              to="/rewards/shop/"
              style={[
                GlobalStyles.regular,
                styles.editBtn,
                { marginLeft: '0px' },
              ]}
            >
              Continue Shopping
            </RadiumLink>
          </div>
        </div>
      )}
    </div>
  );
};

ShoppingCartMarkup.propTypes = {
  shoppingCartItems: PropTypes.array,
  updateDenomination: PropTypes.func,
  updateQuantity: PropTypes.func,
  updateDelivery: PropTypes.func,
  removeFromCart: PropTypes.func,
  updateCart: PropTypes.func,
  points: PropTypes.number,
};

export default Radium(ShoppingCartMarkup);
