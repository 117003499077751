import React from 'react';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const SsoError = () => {
  const styles = {
    container: {
      width: '1000px',
      maxWidth: '100%',
      margin: '65px auto 0 auto',
    },
    h3: {
      fontSize: '30px',
      lineHeight: '34px',
      fontWeight: 'normal',
      margin: '0 0 5px 0',
      letterSpacing: '-.04em',
      color: Colors.red,
    },
    h4: {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'normal',
      margin: '10px 0 5px 0',
      letterSpacing: '-.04em',
      borderTop: `1px solid ${Colors.gutterGray}`,
      paddingTop: '10px',
    },
    errorMessage: {
      fontFamily: 'Fira Sans, Open Sans, Helvetica, Arial, Sans-Serif',
      padding: '40px',
      lineHeight: '22px',
      background: Colors.white,
      boxShadow: '0 0 2px #D7D7D7',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.errorMessage}>
        <h3 style={styles.h3}>Log In Error</h3>
        <br />
        <h4 style={styles.h4}>
          Please contact the Blue KC Customer Service team at the number listed
          on your Blue KC member ID card, Monday through Friday, from 8 a.m. to
          8 p.m. Central Time.
        </h4>
      </div>
    </div>
  );
};

SsoError.propTypes = {};

export default Radium(SsoError);
