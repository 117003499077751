import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, pure, withProps } from 'recompose';
import { FirstTimeExperienceConstants } from '@healthmine/greyhound-core/src/constants';
import { withStyles } from '@material-ui/core';
import DrawerContent from '../DrawerContent';
import Title from '../../common/Title';
import { DrawerSection, DrawerSectionHeader } from '../../common/drawer2';

const styles = (theme) => ({
  subTitle: {
    color: theme.palette.common.blueGray,
  },
  preventive: {
    color: theme.palette.common.notBubbleGum,
  },
  chronic: {
    color: theme.palette.common.venus,
  },
  education: {
    color: theme.palette.common.peopleEater,
  },
  tracking: {
    color: theme.palette.common.mintJulep,
  },
});

const { title, description, items } = FirstTimeExperienceConstants.upgrades;

const UpgradesDrawer = ({ classes, items, onNext, ...props }) => (
  <DrawerContent {...props} onSubmit={onNext}>
    <DrawerSectionHeader title="Types Of Cards" />
    {items.map(({ title, subTitle, iconClass }, i) => (
      <DrawerSection key={i}>
        <Title
          title={title}
          subtitle={subTitle}
          subtitleClass={classes.subTitle}
          iconClass={iconClass}
        />
      </DrawerSection>
    ))}
  </DrawerContent>
);

UpgradesDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ classes }) => ({
    color: 'grannySmith',
    hero: { title, description, background: 'upgrades' },
    items: items.map((x) => ({
      ...x,
      iconClass: classnames(`icon-${x.icon}`, classes.icon),
    })),
  }))
)(UpgradesDrawer);
