import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
  withDrawer,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';
import _ from 'lodash';
import StatusWidget from '../../../common/widgets/StatusWidget';

import HealthActionDrawerView from '../care-plan/drawer/HealthActionDrawerView';
import CoachingProgram from '../care-plan/drawer/CoachingProgram';
import HealthAction from './HealthAction';
import PointAction from './PointAction';
import PointActionDrawer from './PointActionDrawer';
import CustomAction from './CustomAction';
import CustomActionDrawer from './CustomActionDrawer';
import { selectIncentiveRequirement } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import WhatToExpectDrawer from '../biometric-screening/WhatToExpect';

const styles = (theme) => ({
  complete: {
    color: theme.palette.common.grannySmith,
  },
  notComplete: {
    color: theme.palette.common.cosmopoltian,
  },
});

const EmployerIncentiveDrawer = ({
  title,
  classes,
  healthActions,
  customActions,
  showMoreCustomAction,
  pointAction,
  percentCompleted,
  actionsIncompleteCount,
  onHealthActionSelect,
  onPointActionSelect,
  incentiveReqDocUrl,
  isPastDueDate,
  isCompleted,
  theme,
  emptyRequirement,
}) => {
  return (
    <DrawerContent title={title}>
      <DrawerSection>
        {(isPastDueDate || isCompleted) && (
          <div style={{ textAlign: 'center' }}>
            <Typography
              type="h1"
              className={classnames(
                isCompleted ? classes.complete : classes.notComplete
              )}
            >
              {isCompleted ? 'completed' : 'not complete'}
            </Typography>
          </div>
        )}
        {!isPastDueDate &&
          !isCompleted &&
          !emptyRequirement && (
            <StatusWidget
              color={theme.palette.common.notBubbleGum}
              status={actionsIncompleteCount}
              percentage={percentCompleted}
              statusNote={'actions remaining'}
            />
          )}
      </DrawerSection>
      {emptyRequirement ? (
        <DrawerSection>
          <Typography type="body">
            Please check back at a later time to view the requirements.
          </Typography>
        </DrawerSection>
      ) : (
        <div>
          <DrawerSectionHeader title="Health Actions" />
          {pointAction && (
            <PointAction
              pointAction={pointAction}
              onPointActionClick={onPointActionSelect}
            />
          )}
          {healthActions.map((healthActionItem, idx) => (
            <HealthAction
              key={`incentive_req_ha_${idx}`}
              selectHealthAction={onHealthActionSelect}
              healthAction={healthActionItem}
            />
          ))}
          {customActions.map(({ title, description, completed }) => (
            <CustomAction
              key={`custom_action_${title}`}
              title={title}
              completed={completed}
              showMore={() =>
                showMoreCustomAction({
                  title,
                  description,
                  completed,
                })
              }
            />
          ))}
          {incentiveReqDocUrl && (
            <DrawerSection>
              {isPastDueDate === true &&
                !isCompleted && (
                  <Typography type="body">
                    While you may still complete actions after the due date,
                    requirements were not met by the incentive deadline.
                  </Typography>
                )}
              <Typography type="body">
                For more information about your employer health and wellness
                program,{' '}
                <a href={incentiveReqDocUrl} target="_blank">
                  click here
                </a>
                .
              </Typography>
            </DrawerSection>
          )}
        </div>
      )}
    </DrawerContent>
  );
};

EmployerIncentiveDrawer.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  healthActions: PropTypes.array.isRequired,
  pointAction: PropTypes.object,
  customActions: PropTypes.array.isRequired,
  showMoreCustomAction: PropTypes.func.isRequired,
  percentCompleted: PropTypes.number.isRequired,
  actionsIncompleteCount: PropTypes.number.isRequired,
  onHealthActionSelect: PropTypes.func,
  onPointActionSelect: PropTypes.func,
  incentiveReqDocUrl: PropTypes.string,
  isPastDueDate: PropTypes.bool,
  isCompleted: PropTypes.bool,
  emptyRequirement: PropTypes.bool,
};

export default compose(
  pure,
  connect((state) => selectIncentiveRequirement(state)),
  withDrawer,
  withProps(({ drawer, healthActions }) => {
    const onHealthActionSelect = (id) => {
      const healthAction = _.find(healthActions, (action) => action.id === id);

      if (
        healthAction.incentiveActionType ===
        AppConstants.IncentiveRequirementActionTypes.COACHING
      ) {
        drawer.open(CoachingProgram, { programId: healthAction.id });
      } else if (
        healthAction.code ===
        AppConstants.HealthActions.COMPLETE_BIOMETRIC_TESTING
      ) {
        drawer.open(WhatToExpectDrawer);
      } else {
        drawer.open(HealthActionDrawerView, { healthAction });
      }
    };
    const onPointActionSelect = (pointAction) => {
      drawer.open(PointActionDrawer, { pointAction });
    };
    const showMoreCustomAction = ({ title, description, completed }) => {
      drawer.open(CustomActionDrawer, {
        title,
        description,
        completed,
      });
    };
    return {
      onHealthActionSelect,
      onPointActionSelect,
      showMoreCustomAction,
    };
  }),
  withTheme(),
  withStyles(styles)
)(EmployerIncentiveDrawer);
