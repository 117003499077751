import AppTheme from '@healthmine/greyhound-core/src/modules/app-themes/LeapFrog';

export default {
  ...AppTheme,
  baseFont: '"Rubik", sans-serif',
  baseFontSize: '16px',
  shadow1: '0 2px 6px 0 rgba(0,0,0,0.06)',
  shadow2: '0 2px 4px 0 rgba(0,0,0,0.2)',
  shadow3: '0 2px 8px 0 rgba(0, 0, 0, .4)',
  transition1: 'all 400ms ease-in-out',
  borderRadius1: '4px',
  borderRadiusFull: '9999px',
  dashedBorder: `2px dashed ${AppTheme.colors.twentyCC}`,
};
