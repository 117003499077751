import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  action: {
    display: 'flex',
    width: '300px',
    maxWidth: '300px',
    height: '230px',
    maxHeight: '230px',
    backgroundColor: Colors.white,
    borderRight: `1px solid ${Colors.gutterGray}`,
    position: 'relative',
    boxSizing: 'border-box',
  },

  actionWithVisibleDetails: {
    borderBottom: 'none',
  },

  actionWithHiddenDetails: {
    borderBottom: `1px solid ${Colors.gutterGray}`,
    marginBottom: '0px',
  },

  lastActionInRow: {
    borderRight: 'none',
    maxWidth: '299px',
  },

  actionInner: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '194px',
    paddingTop: '25px',
  },

  headerRoot: {
    marginBottom: '10px',
    minHeight: '50px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    color: Colors.bodyText,
    padding: '0px 15px 0px 25px',
  },

  iconBubbleBase: {
    position: 'relative',
    display: 'flex',
    width: '50px',
    height: '50px',
    minWidth: '50px',
    minHeight: '50px',
    padding: '0px',
    fontSize: '25px',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
    zIndex: 1,
  },

  iconBubbleBackground: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '50%',
    borderColor: Colors.lightGreen,
    backgroundColor: Colors.lightGreen,
  },

  uhcBrandedIconBubble: {
    border: 'none',
    backgroundColor: Colors.white,
  },

  icon: {
    color: Colors.white,
  },

  completed: {
    display: 'flex',
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.linkBlue,
  },

  completedIcon: {
    backgroundColor: 'transparent',
    color: Colors.white,
    fontSize: '18px',
  },

  content: {
    marginLeft: '18px',
    flexBasis: '100%',
  },

  title: {
    paddingTop: '3px',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100px',
    fontSize: '20px',
  },

  titleSuperscript: {
    fontSize: '16px',
    marginTop: '-10px',
    fontWeight: 'normal',
  },

  completedSuperscript: {
    color: Colors.lightGray,
  },

  dueSuperscript: {
    color: Colors.navyBlue,
  },

  titleSubscript: {
    fontSize: '16px',
    color: Colors.contentText,
  },

  bottom: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
    width: '100%',
  },

  linkWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  link: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: Colors.lightSlateGray,
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'right',
    marginBottom: '0px',
    userSelect: 'none',
  },

  detailsIcon: {
    color: Colors.linkBlue,
    height: '15px',
    width: '15px',
    position: 'relative',
    display: 'inline',
    marginLeft: '30px',
    marginRight: '-15px',
  },

  detailsContent: {
    padding: '40px 40px 40px 40px',
    display: 'none',
    transition: '0.1s ease all',
    position: 'absolute',
    color: Colors.contentText,
    textAlign: 'left',
    fontSize: '16px',
    lineHeight: '20px',
  },

  detailsContentActive: {
    display: 'flex',
    flexDirection: 'column',
    clear: 'both',
    borderBottom: `1px solid ${Colors.gutterGray}`,
    borderTop: 'none !important',
  },

  actionBody: {
    padding: '0px 25px',
  },

  measurementTitle: {
    fontSize: '16px',
    borderBottom: `1px solid ${Colors.gutterGray}`,
    padding: '5px 0',
  },

  measurementBody: {
    display: 'flex',
  },

  valueContainer: {
    width: '50%',
    padding: '5px',
    borderRight: `1px solid ${Colors.gutterGray}`,
  },

  rangeContainer: {
    width: '50%',
    padding: '5px',
  },

  measurementBodyTitle: {
    fontSize: '16px',
    color: Colors.lightGray,
  },

  valueDisplay: {
    fontSize: '30px',
    textAlign: 'center',
    padding: '10px 0px',
    color: Colors.darkGreen,
    fontWeight: '600',
  },

  rangeDisplay: {
    fontSize: '30px',
    textAlign: 'center',
    padding: '10px 0px',
    color: Colors.lightGray,
  },

  description: {
    marginTop: '10px',
    fontSize: '16px',
    color: Colors.lightGray,
  },
};
