import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import PreventiveHeader from './PreventiveHeader';
import PreventiveHealthActions from './PreventiveHealthActions';
import {
  hasBiometricResults,
  selectAppointmentStatus,
} from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';
import * as HraSelectors from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import { connect } from 'react-redux';
import { CompletionStatus } from '@healthmine/greyhound-core/src/constants/CarePlan';

class PreventiveTracking extends React.Component {
  constructor() {
    super(...arguments);
    this._setActiveDetails = this._setActiveDetails.bind(this);

    this.state = {
      activeDetails: '',
    };
  }

  render() {
    const styles = {
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
      contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 0,
        maxWidth: '900px',
      },
    };

    const { carePlan, goalStatement } = this.props;

    const { activeDetails } = this.state;

    const moduleUI = carePlan.myClinicalActions.map((healthAction, index) => {
      return (
        <PreventiveHealthActions
          key={healthAction.healthActionCode}
          index={index}
          carePlan={carePlan}
          healthAction={healthAction}
          longDescription={healthAction.longDescription}
          activeDetails={activeDetails}
          HRAStatus={this.props.HRAStatus}
          biometricStatus={this.props.biometricStatus}
          setActiveDetails={() =>
            this._setActiveDetails(healthAction.healthActionCode)
          }
        />
      );
    });

    return (
      <StyleRoot
        style={[GlobalStyles.regular, styles.root]}
        className="preventiveContainer"
      >
        <PreventiveHeader
          carePlan={carePlan}
          healthActions={carePlan.myClinicalActions}
          HRAStatus={this.props.HRAStatus}
          biometricStatus={this.props.biometricStatus}
          goalStatement={goalStatement}
        />

        <div style={styles.contentContainer}>{moduleUI}</div>
      </StyleRoot>
    );
  }

  _setActiveDetails(healthActionCd) {
    this.setState({
      activeDetails:
        this.state.activeDetails !== healthActionCd ? healthActionCd : '',
    });
  }
}

PreventiveTracking.propTypes = {
  carePlan: PropTypes.object.isRequired,
  goalStatement: PropTypes.object.isRequired,
  HRAStatus: PropTypes.string,
  biometricStatus: PropTypes.string,
};

function mapStateToProps(state) {
  const HRAStatus = HraSelectors.selectHraStatus(state);

  const appointmentStatus = selectAppointmentStatus(state);

  const resultStatus = hasBiometricResults(state);

  const biometricStatus =
    resultStatus === true
      ? CompletionStatus.COMPLETED
      : appointmentStatus === 'NOAPPOINTMENT' && resultStatus === false
        ? CompletionStatus.NOTSTARTED
        : appointmentStatus !== 'NOAPPOINTMENT' && resultStatus === false
          ? CompletionStatus.STARTED
          : '';

  return {
    HRAStatus,
    biometricStatus,
  };
}

export default connect(mapStateToProps)(Radium(PreventiveTracking));
