import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import CarePlanActionList from './CarePlanActionList';
import _ from 'lodash';
import Styles from './ActionList.styles';

const CarePlanListItemMarkup = ({
  title,
  subTitle,
  iconClass,
  bubbleColor,
  actionCount,
  completedCount,
  remainingHours,
  pastDueActions,
  requiredSoonActions,
  requiredActions,
  optionalActions,
  completedActions,
  noBorder,
  openTracking,
  activateTracking,
  status,
  carePlanActions,
  checkpoint,
  dailyStatus,
}) => {
  const isAssignedStatus = status === AppConstants.PlanStatus.ASSIGNED;

  const checkpointText = !checkpoint
    ? ''
    : AppConfig.effectiveConfig.incentiveCurrency ===
      AppConstants.IncentiveCurrency.DOLLARS
      ? 'Care Plan Check-In'
      : 'Care Plan Ended';

  const badge = checkpoint
    ? 'starred'
    : dailyStatus === AppConstants.TrackingStatus.COMPLETE
      ? 'verified'
      : dailyStatus === AppConstants.TrackingStatus.ALERT
        ? 'alert'
        : null;

  const badgeStyle = {
    backgroundColor:
      badge === 'starred'
        ? Colors.white
        : badge === 'verified' || badge === 'COMPLETED'
          ? Colors.linkBlue
          : Colors.yellow,
  };

  const badgeIconStyle = {
    color: badge === 'starred' ? Colors.yellow : Colors.white,
    fontSize: badge === 'starred' ? '22px' : '18px',
  };

  const unifiedCarePlan = AppConfig.validateFeature(Features.UNIFIED_CARE_PLAN);

  return (
    <div
      style={[
        Styles.root,
        isAssignedStatus || checkpoint ? { backgroundColor: '#eaeaea' } : {},
      ]}
    >
      <div style={Styles.header}>
        <a onClick={openTracking} style={Styles.headerInfo}>
          <div style={[Styles.bubble, { backgroundColor: bubbleColor }]}>
            <i className={iconClass} style={Styles.icon} />
            {badge && (
              <div style={[Styles.badge, badgeStyle]}>
                {badge === 'alert' ? (
                  <span style={[Styles.badgeIcon, badgeIconStyle]}>!</span>
                ) : (
                  <i
                    className="material-icons"
                    style={[Styles.badgeIcon, badgeIconStyle]}
                  >
                    {badge === 'starred' ? 'stars' : 'done'}
                  </i>
                )}
              </div>
            )}
          </div>
          <div style={Styles.titleWrapper}>
            <div style={Styles.titleCheckpointWrapper}>
              <div style={Styles.checkpointText}>{checkpointText}</div>
              <div style={Styles.title}>{title}</div>
            </div>
            {unifiedCarePlan ||
              (isAssignedStatus && (
                <div style={Styles.subTitle}>{subTitle}</div>
              ))}
          </div>
        </a>
        {isAssignedStatus ? (
          <a style={Styles.CTA} onClick={openTracking}>
            Configure Now
          </a>
        ) : checkpoint ? (
          <a style={Styles.CTA} onClick={openTracking}>
            View Points
          </a>
        ) : (
          unifiedCarePlan &&
          actionCount > 0 &&
          !_.isUndefined(completedCount) && (
            <div style={Styles.completeCountWrapper}>
              <div style={Styles.completeCount}>
                {completedCount}/{actionCount}
              </div>
              <div style={Styles.completeCountMemo}>Complete</div>
            </div>
          )
        )}
      </div>

      <div style={{ borderTop: !noBorder && '1px solid' }}>
        {!_.isEmpty(carePlanActions) &&
          !isAssignedStatus &&
          !checkpoint && (
            <CarePlanActionList
              actions={carePlanActions}
              showDueDate={false}
              activateTracking={activateTracking}
            />
          )}
        {!_.isEmpty(pastDueActions) && (
          <CarePlanActionList
            title="Past Due Recommended Actions"
            actions={pastDueActions}
            openTracking={openTracking}
            showDueDate
            pastDue
          />
        )}
        {!_.isEmpty(requiredSoonActions) && (
          <CarePlanActionList
            title="Recommended Within 6 Months"
            actions={requiredSoonActions}
            openTracking={openTracking}
            showDueDate
          />
        )}
        {(!_.isEmpty(requiredActions) || !_.isEmpty(completedActions)) && (
          <CarePlanActionList
            title="Recommended Actions"
            actions={_.concat(
              requiredActions,
              _.map(completedActions, (action) => ({
                ...action,
                completed: true,
              }))
            )}
            remainingHours={remainingHours}
            openTracking={openTracking}
            showDueDate
          />
        )}
        {!_.isEmpty(optionalActions) && (
          <CarePlanActionList
            title="Optional Actions"
            actions={optionalActions}
            openTracking={openTracking}
            showDueDate
          />
        )}
      </div>
    </div>
  );
};

CarePlanListItemMarkup.propTypes = {
  tutorial: PropTypes.bool,
  selectCarePlan: PropTypes.func,
  activateTracking: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  iconClass: PropTypes.string,
  actionCount: PropTypes.number,
  completedCount: PropTypes.number,
  remainingHours: PropTypes.string,
  pastDueActions: PropTypes.array,
  requiredSoonActions: PropTypes.array,
  requiredActions: PropTypes.array,
  optionalActions: PropTypes.array,
  completedActions: PropTypes.array,
  noBorder: PropTypes.bool,
  bubbleColor: PropTypes.string,
  openTracking: PropTypes.func,
  carePlanActions: PropTypes.array,
  status: PropTypes.string,
  checkpoint: PropTypes.bool,
  dailyStatus: PropTypes.string,
};

export default Radium(CarePlanListItemMarkup);
