import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  DrawerContent,
  DrawerSection,
  withDrawer,
} from '../../../../common/drawer2';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../../../../common/Typography';
import classnames from 'classnames';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { times as _times } from 'lodash';

const styles = (theme) => ({
  marginTop30: {
    marginTop: '30px',
  },
  button: {
    padding: '10px',
    cursor: 'pointer',
    borderRadius: theme.globalStyles.borderRadius1,
  },
  mainButton: {
    background: theme.palette.common.planet9,
    color: theme.palette.common.white,
  },
  formControl: {
    width: '100%',
  },
});

const MedicationRefillReport = ({ classes, drawer, addRefill, refillType }) => {
  const today = new Date();
  const [refillReport, setRefillReport] = useState({
    month: today.getMonth() + 1,
    day: today.getDate(),
    year: 2019,
    supply: 90,
  });
  return (
    <DrawerContent>
      <DrawerSection grow>
        <Typography center type="h3Medium">
          Tell us about your Lovastatin refill:
        </Typography>
        <Typography type="h4Medium" center className={classes.marginTop30}>
          When did you refill?
        </Typography>
        <Grid
          spacing={16}
          container
          justify="center"
          alignItems="center"
          className={classes.marginTop30}
        >
          <Grid item xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-simple">Month</InputLabel>
              <Select
                value={refillReport.month}
                onChange={(e) =>
                  setRefillReport({ ...refillReport, month: e.target.value })
                }
                inputProps={{
                  name: 'month',
                  id: 'month-simple',
                }}
              >
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-simple">Day</InputLabel>
              <Select
                value={refillReport.day}
                onChange={(e) =>
                  setRefillReport({ ...refillReport, day: e.target.value })
                }
                inputProps={{
                  name: 'day',
                  id: 'day-simple',
                }}
              >
                {_times(31, (idx) => (
                  <MenuItem key={`dayOfMonth_${idx}`} value={idx + 1}>
                    {idx + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-simple">Day</InputLabel>
              <Select
                value={refillReport.year}
                onChange={(e) =>
                  setRefillReport({ ...refillReport, year: e.target.value })
                }
                inputProps={{
                  name: 'year',
                  id: 'year-simple',
                }}
              >
                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2018}>2018</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Typography type="h4Medium" center className={classes.marginTop30}>
          How many days will the refill last?
        </Typography>
        <FormControl className={classes.marginTop30}>
          <Select
            value={refillReport.supply}
            onChange={(e) =>
              setRefillReport({ ...refillReport, supply: e.target.value })
            }
            inputProps={{
              name: 'year',
              id: 'year-simple',
            }}
          >
            <MenuItem value={30}>30 Day Supply</MenuItem>
            <MenuItem value={60}>60 Day Supply</MenuItem>
            <MenuItem value={90}>90 Day Supply</MenuItem>
          </Select>
        </FormControl>
        <div
          className={classnames(
            classes.button,
            classes.mainButton,
            classes.marginTop30
          )}
          onClick={() => {
            addRefill(refillType, {
              refillDate: `${refillReport.month}/${refillReport.day}/${
                refillReport.year
              }`,
              refillSupply: refillReport.supply,
            });
            drawer.back();
          }}
        >
          <Typography center>Save</Typography>
        </div>
      </DrawerSection>
    </DrawerContent>
  );
};

MedicationRefillReport.propTypes = {
  classes: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
  addRefill: PropTypes.func.isRequired,
  refillType: PropTypes.string.isRequired,
};

export default compose(withDrawer, withStyles(styles))(MedicationRefillReport);
