import moment from 'moment';
import _ from 'lodash';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

/**
 * Helper function to prop up schedule and history of a medication
 * @param {Object} schedule
 * @param {Number} daysTaken
 * @param {Array} daysMissed
 */
export const createMedData = (
  schedule = {},
  daysTaken = 60,
  daysMissed = []
) => {
  return {
    schedule,
    history: Array.from(Array(daysTaken).keys())
      .map((_, index) => moment().add(index * -1, 'd'))
      .filter((date) => {
        switch (date.weekday()) {
          case 0:
            return schedule.sunday === true;
          case 1:
            return schedule.monday === true;
          case 2:
            return schedule.tuesday === true;
          case 3:
            return schedule.wednesday === true;
          case 4:
            return schedule.thursday === true;
          case 5:
            return schedule.friday === true;
          case 6:
            return schedule.saturday === true;
          default:
            return false;
        }
      })
      .filter((_, index) => !daysMissed.includes(index)),
  };
};

export const setupHistory = (result, userInfo) => {
  const sessionState = sessionStorage.getItem('medicationAdherenceState');
  const sessionMeds = sessionState ? JSON.parse(sessionState) : {};
  const allDemoDates = _.times(
    29,
    (offset) =>
      moment('2019-09-01')
        .clone()
        .add(offset, 'd')._d
  );
  const schedule = {
    //sunday: true, -- commenting weekend day out for medicaid demo (maria's tracking schedule needs to be 5 days a week)
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    //saturday: true, -- commenting weekend day out for medicaid demo (maria's tracking schedule needs to be 5 days a week)
  };

  const firstName = _.get(userInfo, 'firstName');

  const memberMedHistory = [
    {
      name: 'Charles',
      meds: [
        {
          name: 'LOVASTATIN',
          datesMissed: [
            '2019-05-27',
            '2019-06-01',
            '2019-06-08',
            '2019-06-09',
            '2019-06-11',
            '2019-06-15',
            '2019-06-16',
          ],
        },
        {
          name: 'ZESTRIL',
          datesMissed: [
            '2019-05-27',
            '2019-06-01',
            '2019-06-04',
            '2019-06-05',
            '2019-06-06',
            '2019-06-07',
            '2019-06-08',
            '2019-06-09',
            '2019-06-15',
            '2019-06-16',
          ],
        },
      ],
    },
    {
      name: 'Anthony',
      meds: [
        {
          name: 'QVAR',
          datesMissed: ['2019-05-27', '2019-06-01', '2019-06-15', '2019-06-16'],
        },
      ],
    },
    {
      name: 'Maria',
      meds: [
        {
          name: 'PRENATAL',
          datesMissed: ['2019-09-02', '2019-09-09', '2019-09-20', '2019-09-25'],
        },
      ],
    },
  ];

  const formattedMedHistory = (memberName, drugName, baseDates) => {
    const datesMissed = _.flow(
      find({ name: memberName }),
      get('meds'),
      find({ name: drugName }),
      get('datesMissed')
    )(memberMedHistory);

    return {
      schedule,
      history: baseDates
        .filter((bd) => !datesMissed.includes(moment(bd).format('YYYY-MM-DD')))
        .map((date) => moment(date)),
    };
  };

  const meds = _.mapValues(_.keyBy(result, 'name'), ({ name }) => {
    if (
      memberMedHistory.some((member) => member.name === firstName) &&
      memberMedHistory
        .find((member) => member.name === firstName)
        .meds.some((med) => med.name === name)
    ) {
      return formattedMedHistory(firstName, name, allDemoDates);
    } else {
      return {
        schedule: _.get(sessionMeds, [name, 'schedule'], {}),
        history: _.map(_.get(sessionMeds, [name, 'history'], []), (date) =>
          moment(date)
        ),
      };
    }
  });

  return meds;
};
