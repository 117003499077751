import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SweepstakesActions } from '@healthmine/greyhound-core/src/actions';
import EntriesMarkup from './EntriesMarkup';
import {
  selectEnteredProducts,
  selectWonProducts,
} from '@healthmine/greyhound-core/src/selectors/SweepstakesSelector';
class Entries extends React.Component {
  render() {
    const { enteredProducts, wonProducts } = this.props;

    return (
      <EntriesMarkup
        enteredProducts={enteredProducts}
        wonProducts={wonProducts}
      />
    );
  }
}

Entries.propTypes = {
  actions: PropTypes.object.isRequired,
  enteredProducts: PropTypes.array,
  wonProducts: PropTypes.array,
};

function mapStateToProps(state) {
  const enteredProducts = selectEnteredProducts(state);

  const wonProducts = selectWonProducts(state);

  return {
    enteredProducts,
    wonProducts,
  };
}

function mapDispatchToProps(dispatch) {
  const { getAllOpenSweepstakes, getAllClosedSweepstakes } = SweepstakesActions;

  return {
    actions: bindActionCreators(
      {
        getAllOpenSweepstakes,
        getAllClosedSweepstakes,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Entries);
