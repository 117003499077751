import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from './Typography';
import MaterialChip from '@material-ui/core/Chip';
import { compose } from 'recompose';
import classnames from 'classnames';

const styles = (theme) => ({
  root: {
    borderRadius: '4px',
    color: theme.palette.common.white,
    height: '20px',
    margin: '5px 5px 0',
  },
});

const Chip = ({ classes, className, theme, label, color }) => {
  const backgroundColor = theme.palette.common[color];

  return (
    <MaterialChip
      className={classnames(classes.root, className)}
      style={{ backgroundColor }}
      label={<Typography type="h4">{label}</Typography>}
    />
  );
};

Chip.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

Chip.defaultProps = {
  color: 'blueGray',
};

export default compose(withTheme(), withStyles(styles))(Chip);
