import React from 'react';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import SettingsDevicesMarkup from '../settings/SettingsDevicesMarkup';

const NotConnectedBody = () => {
  const styles = {
    container: {
      padding: '0 60px',
    },
    message: {
      fontSize: '16px',
      padding: '50px 75px 0',
      color: Colors.darkBlue,
      fontWeight: 300,
    },
    connectionStatus: {
      fontWeight: 600,
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.message}>
        You currently have{' '}
        <span style={styles.connectionStatus}>No Devices Connected</span>,
        choose from the list below to connect your device to our platform:
      </div>
      <SettingsDevicesMarkup />
    </div>
  );
};

export default Radium(NotConnectedBody);
