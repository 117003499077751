import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../common/Typography';
import { ListItem, ListItemStyles } from '../../../common/list2/List';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  ...ListItemStyles,
  leftside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  medicationTitle: {
    marginBottom: '2px',
    fontSize: '16px',
  },
  supply: {
    textAlign: 'center',
  },
  drugStrength: {
    fontSize: '16px',
  },
});

const MedicationItem = ({
  classes,
  drugName,
  dateOfService,
  drugStrength,
  daysSupply,
}) => {
  return (
    <ListItem>
      <Grid container>
        <Grid item xs className={classes.leftside}>
          <Typography
            type="h3Medium"
            inline
            className={classes.medicationTitle}
          >
            {drugName}
            {drugStrength && (
              <Typography type="h3" inline className={classes.drugStrength}>
                , {drugStrength}
              </Typography>
            )}
          </Typography>
          <Typography type="h4" color="blueGray">
            last filled on {dateOfService}
          </Typography>
        </Grid>
        <Grid item className={classes.supply}>
          <Typography type="h1" color="blueGray">
            {daysSupply}
          </Typography>
          <Typography type="h4Medium" color="blueGray">
            days supply
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

MedicationItem.propTypes = {
  classes: PropTypes.object.isRequired,
  drugName: PropTypes.string.isRequired,
  dateOfService: PropTypes.string.isRequired,
  drugStrength: PropTypes.string,
  daysSupply: PropTypes.number,
  bb: PropTypes.bool,
};

export default compose(pure, withStyles(styles))(MedicationItem);
