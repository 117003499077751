import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  ListItem,
  ListItemStyles as styles,
} from '../../../../../common/list/List';
import StatusTitle from '../../../../../common/StatusTitle';

const EducationAction = ({ title, incentiveAmount, classes, openDrawer }) => {
  return (
    <ListItem onClick={openDrawer}>
      <div className={classes.root}>
        <StatusTitle status="neutral" title={title} />
        <div className={classes.incentiveConditionsWrapper}>
          <div
            className={`${classes.incentive} ${classes.incentiveToBeEarned}`}
          >
            Earn {incentiveAmount} pts
          </div>
          <div className={classes.reason}>Hypertension</div>
        </div>
      </div>
    </ListItem>
  );
};

EducationAction.propTypes = {
  title: PropTypes.string.isRequired,
  incentiveAmount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  openDrawer: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
};

export default compose(
  pure,
  withProps(({ openDrawer, contentId }) => ({
    openDrawer: () => openDrawer(contentId),
  })),
  withStyles(styles)
)(EducationAction);
