import React from 'react';
import PropTypes from 'prop-types';
import { hashHistory } from 'react-router';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import Modal from 'react-modal';
import Radium from 'radium';
import { compose, withProps } from 'recompose';
import { withDrawer } from '../../leapfrog/views/common/drawer2';
import PrivacyPolicy from '../../leapfrog/views/pages/help/top-picks/PrivacyPolicy';

import Button from '../common/Button';
import {
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { Agreement } from '@healthmine/greyhound-core/src/constants/prop-types';
import Terms from '../../leapfrog/views/pages/help/top-picks/Terms';

const PendingPrivacyAgreementModal = ({
  handleDocumentClick,
  styles,
  title,
  pendingAnnouncements,
  dismiss,
  isLoading,
}) => (
  <Modal
    isOpen
    contentLabel="Announcements"
    style={styles.customStyles}
    onRequestClose={dismiss}
  >
    <div style={[styles.modalContent, GlobalStyles.light]}>
      <div style={styles.pageTitle}>
        <h2>{title}</h2>
        <a onClick={dismiss} style={styles.closeButton}>
          <i className="thin-0153_delete_exit_remove_close" />
        </a>
      </div>
      <div style={styles.pageContent}>
        <p>
          For your security, we’ve updated our member agreements. To view the
          updated document(s) click on a link below or press ‘Ok’ to continue to
          your homepage
        </p>
        {pendingAnnouncements.map((announcement) => (
          <button
            style={styles.document}
            key={announcement.id}
            onClick={() => handleDocumentClick(announcement.tag)}
          >
            {startCase(toLower(announcement.tag))}
          </button>
        ))}
      </div>
      <div style={styles.pageFooter}>
        <Button
          disabled={isLoading}
          style={styles.button}
          text="Ok"
          onClick={dismiss}
        />
      </div>
    </div>
  </Modal>
);

PendingPrivacyAgreementModal.propTypes = {
  handleDocumentClick: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  pendingAnnouncements: PropTypes.arrayOf(Agreement),
  dismissAnnouncement: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default compose(
  withDrawer,
  withProps(({ dismissAnnouncement, isLoading }) => ({
    dismiss: () => !isLoading && dismissAnnouncement(),
  })),
  withProps(({ drawer, dismiss }) => ({
    handleDocumentClick: (tag) => {
      dismiss();

      const isLeapFrog = AppConfig.validateFeature(Features.LEAP_FROG_UI);

      if (isLeapFrog) {
        switch (tag) {
          case 'PRIVACY_POLICY':
            return drawer.open(PrivacyPolicy, null, { large: true });

          case 'TERMS_OF_USE':
            return drawer.open(Terms, null, { large: true });
        }
      }

      switch (tag) {
        case 'PRIVACY_POLICY':
          return hashHistory.push('/privacy');

        case 'TERMS_OF_USE':
          return hashHistory.push('/terms');
      }
    },
  })),
  Radium
)(PendingPrivacyAgreementModal);
