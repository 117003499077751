import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import DrawerContent from './DrawerContent';
import withDrawer from './withDrawer';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.cosmopolitan,
    color: theme.palette.common.white,
  },
  title: {
    color: theme.palette.common.white,
  },
});

const WarningDrawerContent = (props) => (
  <DrawerContent
    {...props}
    showActions={false}
    titleSectionProps={{ noBorder: true }}
    headerSectionProps={{ noBorder: true }}
    headerAction={{
      label: 'Nevermind',
      color: 'white',
      onClick: props.drawer.back,
    }}
  />
);

WarningDrawerContent.propTypes = {
  drawer: PropTypes.object.isRequired,
};

export default compose(withDrawer, withStyles(styles))(WarningDrawerContent);
