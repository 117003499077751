import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withStateHandlers } from 'recompose';
import { withRouter } from 'react-router';
import withHandlers from 'recompose/withHandlers';
import { withDrawer } from '../../../../common/drawer2';
import BiometricModals from '../../biometric-screening/BiometricModals';
import Typography from '../../../../common/Typography';
import { withStyles } from '@material-ui/core';
import TypographyStyles from '../../../../../styles/Typography';

const BiometricScreeningHelpText = ({
  openHelp,
  toggleNavigation,
  showModals,
  classes,
}) => {
  return (
    <div>
      <Typography type="body">
        To complete this health action, you can register for an onsite
        appointment offered by your employer or you can manually submit results
        from your physician using the Physician Screening Form{' '}
        <a className={classes.link} onClick={toggleNavigation}>
          here
        </a>.
      </Typography>
      <br />
      <Typography type="body">
        Questions? Take a look at our <a
          className={classes.link}
          onClick={openHelp}
        >
          FAQ
        </a> section or contact us, we will be happy to assist you on your path
        to a healthier you.
      </Typography>

      <BiometricModals
        screeningType={2}
        showModals={showModals}
        onClose={toggleNavigation}
      />
    </div>
  );
};

BiometricScreeningHelpText.propTypes = {
  classes: PropTypes.object.isRequired,
  openHelp: PropTypes.func.isRequired,
  toggleNavigation: PropTypes.func.isRequired,
  showModals: PropTypes.bool.isRequired,
};

export default compose(
  pure,
  withRouter,
  withDrawer,
  withStateHandlers(
    {
      showModals: false,
    },
    {
      toggleNavigation: ({ showModals }) => () => ({ showModals: !showModals }),
    }
  ),
  withHandlers({
    openHelp: ({ router, drawer }) => () => {
      router.push('/help');
      drawer.close();
    },
  }),
  withStyles({ link: TypographyStyles.link })
)(BiometricScreeningHelpText);
