import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, withStyles } from '@material-ui/core';
import Typography from '../Typography';

const styles = (theme) => ({
  box: {
    height: '20px',
    width: '20px',
    borderRadius: theme.globalStyles.borderRadius1,
    backgroundColor: theme.palette.common.formulaOne,
  },
});

const FrequencyBoxes = ({
  classes,
  label,
  frequencyCount,
  markedCount,
  markedColor,
}) => {
  return (
    <Grid container direction="column" alignItems="center" spacing={8}>
      {label && (
        <Grid item>
          <Typography secondary>{label}</Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={8}>
          {_.times(frequencyCount, (idx) => {
            return (
              <Grid item key={`frequency_box_${idx}`}>
                <div
                  className={classes.box}
                  style={{
                    background: idx < markedCount ? markedColor : '',
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

FrequencyBoxes.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  frequencyCount: PropTypes.number.isRequired,
  markedCount: PropTypes.number.isRequired,
  markedColor: PropTypes.string.isRequired,
};

export default withStyles(styles)(FrequencyBoxes);
