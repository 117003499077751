import React from 'react';
import PropTypes from 'prop-types';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import Radium from 'radium';

const styles = {
  configurableAction: {
    fontSize: '16px',
    fontWeight: '200',
    color: Colors.highlightBlue,
    cursor: 'pointer',
    marginTop: 'auto',
    marginBottom: '0px',
    ...GlobalStyles.regular,
  },
};

const ConfigurableAction = ({ children = 'Configure Now', onClick }) => (
  <div onClick={onClick} style={styles.configurableAction}>
    {children}
  </div>
);

ConfigurableAction.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
};

export default Radium(ConfigurableAction);
