import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SweepstakesActions } from '@healthmine/greyhound-core/src/actions';
import DetailsMarkup from './DetailsMarkup';

class Details extends React.Component {
  componentWillMount() {
    const { prodId } = this.props;

    const { getClosedSweepstakeWinners } = this.props.actions;

    getClosedSweepstakeWinners(prodId);
  }

  componentWillUnmount() {
    this.props.actions.clearClosedSweepstakeWinners();
  }

  render() {
    const { prod, winners } = this.props;
    if (!prod) return null;
    return <DetailsMarkup prod={prod} winners={winners} />;
  }
}

Details.propTypes = {
  actions: PropTypes.object.isRequired,
  params: PropTypes.object,
  prodId: PropTypes.string,
  prod: PropTypes.object,
  winners: PropTypes.object,
};

function mapStateToProps(state, props) {
  const prodId = props.params.id;

  const prod = state.sweepstakes.entities[prodId];

  return {
    prodId,
    prod,
    winners: state.sweepstakes.winners,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getAllClosedSweepstakes,
    getClosedSweepstakeWinners,
    clearClosedSweepstakeWinners,
  } = SweepstakesActions;

  return {
    actions: bindActionCreators(
      {
        getAllClosedSweepstakes,
        getClosedSweepstakeWinners,
        clearClosedSweepstakeWinners,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
