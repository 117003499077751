import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import CarePlanCard from './CarePlanCard';
import CarePlansStyles from './CarePlans.styles';
import CarePlanActionList from '../list-item/CarePlanActionListWrapper';
import TrackingModal from './TrackingModal';

const CarePlansMarkup = ({
  tracking,
  tutorial,
  activateTracking,
  closeTracking,
  carePlans,
}) => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const styles = fiveFiveUI
    ? CarePlansStyles.newStyles
    : CarePlansStyles.oldStyles;

  const CarePlanItem = fiveFiveUI ? CarePlanActionList : CarePlanCard;

  return (
    <StyleRoot style={styles.root}>
      <div style={styles.title}>My Care Plans</div>
      <div style={styles.carePlans} className="carePlansContainer">
        {carePlans.map((carePlan) => {
          return (
            <CarePlanItem
              key={carePlan.subject}
              carePlan={carePlan}
              tutorial={tutorial}
              activateTracking={activateTracking}
            />
          );
        })}
      </div>
      {tracking && <TrackingModal type={tracking} close={closeTracking} />}
    </StyleRoot>
  );
};

CarePlansMarkup.propTypes = {
  carePlans: PropTypes.array.isRequired,
  activateTracking: PropTypes.func,
  tutorial: PropTypes.bool,
  tracking: PropTypes.string,
  closeTracking: PropTypes.func,
};

export default Radium(CarePlansMarkup);
