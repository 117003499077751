import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  root: {
    marginBottom: '24px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px',
  },
  headerInfo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  bubble: {
    backgroundColor: '#6E81F5',
    height: '50px',
    width: '50px',
    minWidth: '50px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: '#FFF',
    fontSize: '25px',
  },
  badge: {
    display: 'flex',
    position: 'absolute',
    top: -5,
    right: -5,
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  badgeIcon: {
    backgroundColor: 'transparent',
  },
  titleWrapper: {
    marginLeft: '10px',
  },
  checkpointText: {
    color: Colors.lightSlateGray,
  },
  title: {
    fontSize: '18px',
    lineHeight: '22px',
    textTransform: 'uppercase',
  },
  subTitle: {
    fontSize: '16px',
    lineHeight: '19px',
  },
  CTA: {
    whiteSpace: 'nowrap',
    marginLeft: '10px',
    color: '#1e80f0',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
  },
  completeCountWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  completeCount: {
    fontSize: '32px',
    lineHeight: '30px',
  },
  completeCountMemo: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    marginLeft: '.5rem',
  },
};
