import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Scroll from 'react-scroll';
import Title from '../../components/onboarding/Title';

const ScrollElement = Scroll.Element;

const section = {
  //collapse animation
  width: 'inherit',
  maxHeight: '0px',
  overflow: 'hidden',
  transition: 'max-height 1s', //landing page delay: 2s... all other components need a 4s delay
  margin: '0px 300px',
};

const show = {
  //expand animation
  maxHeight: '900px',
  transition: 'max-height 2s linear 1s',
};

class StepContainer extends React.Component {
  render() {
    const {
      sectionName,
      iconClass,
      title,
      backColor,
      vis,
      colorful,
    } = this.props;
    return (
      <ScrollElement name={sectionName}>
        <Title
          iconClass={iconClass}
          text={title}
          backColor={backColor}
          colorful={colorful}
        />
        <div style={[section, vis ? show : {}]}>{this.props.children}</div>
      </ScrollElement>
    );
  }
}

StepContainer.propTypes = {
  children: PropTypes.object.isRequired,
  sectionName: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  backColor: PropTypes.string.isRequired,
  vis: PropTypes.bool,
  colorful: PropTypes.bool,
};

export default Radium(StepContainer);
