import Radium from 'radium';
import React from 'react';
import { withRouter } from 'react-router';
import { compose, pure } from 'recompose';
import SatisfactionSurvey from './SatisfactionSurvey';
import Styles from './CompletionPage.styles';

export default compose(Radium, pure, withRouter)(({ router }) => (
  <div style={Styles.page}>
    <h1 style={Styles.primaryText}>Congratulations!</h1>

    <h2 style={Styles.secondaryText}>
      You Finished Your Health Risk Assessment
    </h2>

    <h3 style={Styles.tertiaryText}>
      We are processing your health risk assessment summary.
    </h3>

    <h3 style={Styles.tertiaryText}>Check back later to see your results!</h3>

    <div
      style={Styles.dashboardLinkButton}
      onClick={() => router.replace('/health-overview')}
    >
      <i
        className="thin-0159_arrow_back_left"
        style={Styles.dashboardLinkButtonIcon}
      />

      <span style={Styles.dashboardLinkButtonCaption}>
        Back to Health Overview
      </span>
    </div>

    <SatisfactionSurvey />
  </div>
));
