import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const Impersonation = ({ username, impersonationEmail }) => {
  const isImpersonating = !!impersonationEmail;

  const mainContainer = {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    zIndex: 2000,
  };

  const impersonationInfo = {
    display: 'flex',
    fontSize: 16,
    height: '30px',
    background: Colors.darkGray,
    color: Colors.whiteSmoke,
  };

  const usernameContainer = {
    padding: '5px',
    flex: 2,
    textAlign: 'center',
  };

  const emailContainer = {
    padding: '5px',
    flex: 1,
    textAlign: 'left',
  };
  return isImpersonating ? (
    <StyleRoot style={mainContainer}>
      <div style={impersonationInfo}>
        <div style={usernameContainer}>
          CURRENTLY VIEWING MEMBER PORTAL FOR: {username}
        </div>
        <div style={emailContainer}>{impersonationEmail}</div>
      </div>
    </StyleRoot>
  ) : null;
};

Impersonation.propTypes = {
  username: PropTypes.string,
  impersonationEmail: PropTypes.string,
};

export default Radium(Impersonation);
