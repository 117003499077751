import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import {
  GlobalStyles,
  Colors,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import BubbleTitleMarkup from '../common/BubbleTitleMarkup';
import ModuleTracking from './cards/ModuleTracking';
import CheckpointModuleCard from './cards/checkpoint/ModuleCard';
import IncentiveSummary from './incentives/IncentiveSummary';
import OffsetCloseButton from '../common/OffsetCloseButton';
import CheckpointSummaryCard from './cards/checkpoint/SummaryCard';
import AlertCard from './cards/AlertCard';

const HeaderMarkup = ({
  carePlan,
  moduleCompletionStatus,
  goalStatement,
  primaryTracking,
  modalOpen,
  toggleModal,
  activeAccordian,
  setActiveAccordian,
}) => {
  const { checkpoint: isCheckpointMode } = carePlan;

  const pointsOpen = activeAccordian === 'points';

  const historyOpen = activeAccordian === _.get(primaryTracking, 'type', false);

  const styles = {
    root: {
      display: 'flex',
      borderTop: `1px solid ${Colors.gutterGray}`,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      position: 'relative',
    },

    borderBlocker: {
      height: '1px',
      width: '300px',
      bottom: '1px',
      marginLeft: isCheckpointMode ? 'auto' : '0px',
      marginRight: isCheckpointMode ? '0px' : 'auto',
      position: 'relative',
      background: Colors.white,
    },

    section: {
      flex: '0 0 300px',
      minWidth: '300px',
    },

    content: {
      padding: '24px 10px 15px 50px',
      height: '250px',
      maxHeight: '211px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    shadedContent: {
      boxShadow: carePlan.checkpoint
        ? `inset 0px -100px 100px -100px ${Colors.lightenColor(
            Colors.lightGray
          )}`
        : 'none',
    },

    contentLast: {
      padding: '50px',
      height: '150px',
      textAlign: 'center',
      alignItems: 'center',
    },

    dark: {
      fontWeight: 600,
    },
    bubbleTitleStyles: {
      title: {
        fontSize: '17px',
        fontWeight: 600,
      },
      content: {
        marginTop: '16px',
      },
    },
    para: {
      fontSize: '16px',
    },
    alertIcon: {
      color: Colors.light,
      backgroundColor: '#dfd13a',
      display: 'inline-block',
      width: '35px',
      height: '38px',
      paddingRight: '3px',
      textAlign: 'center',
      fontSize: '35px',
      lineHeight: '35px',
      borderRadius: '50%',
    },
    alertText: {
      color: Colors.mediumBlue,
      fontSize: '16px',
      margin: '10px 0 15px',
      width: '100%',
    },
    alertButton: {
      fontSize: '16px',
      fontWeight: 500,
      color: '#359ef3',
      cursor: 'pointer',
    },
    sectionBackground: {
      height: '25px',
      backgroundColor: '#f3f6f8',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },
    pointToggle: {
      color: Colors.contentText,
      cursor: 'pointer',
      textAlign: 'right',
      display: 'flex',
      alignSelf: 'flex-end',
      paddingRight: '30px',
    },
    pointText: {
      fontSize: '16px',
    },
    pointCaret: {
      position: 'relative',
      top: 0,
      right: 0,
    },
    planTileBody: {},
  };

  const { incentiveType, incentiveCurrency } = AppConfig.effectiveConfig;

  const concisePointSummary =
    incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS;

  const incentiveLabel =
    incentiveType === AppConstants.IncentiveType.SWEEPSTAKES
      ? 'Points'
      : 'Rewards';

  const primaryTrackingType = _.get(primaryTracking, 'type');

  const alertModuleType =
    !isCheckpointMode &&
    carePlan.dailyStatus === AppConstants.TrackingStatus.ALERT
      ? primaryTracking &&
        _.find(carePlan.alerts, (alert) => alert === primaryTrackingType)
        ? primaryTrackingType
        : _.get(carePlan, 'alerts[0]')
      : null;

  const alertAction = alertModuleType
    ? alertModuleType === primaryTrackingType
      ? carePlan.primaryTracking
      : _.find(carePlan.actions, ['type', alertModuleType])
    : null;

  return (
    <StyleRoot style={GlobalStyles.regular}>
      <div style={styles.root}>
        <div style={styles.section}>
          <div
            style={[
              styles.content,
              historyOpen || (carePlan.checkpoint && pointsOpen)
                ? styles.shadedContent
                : null,
            ]}
          >
            <div style={styles.planTileBody}>
              <BubbleTitleMarkup
                iconClass={carePlan.iconClass}
                iconColor={Colors.white}
                bubbleColor={Colors.lightGreen}
                bubbleFilled
                titleSuperscript={
                  !isCheckpointMode
                    ? ''
                    : incentiveCurrency ===
                      AppConstants.IncentiveCurrency.DOLLARS
                      ? 'Care Plan Check-In'
                      : 'Care Plan Ended'
                }
                title={carePlan.name}
                styleOverrides={styles.bubbleTitleStyles}
              />

              {goalStatement && goalStatement.markup}
            </div>

            {!isCheckpointMode ? (
              <div
                style={styles.pointToggle}
                onClick={() => setActiveAccordian('points')}
              >
                <span style={styles.pointText}>
                  How To Earn {incentiveLabel}
                </span>
                <i
                  className={pointsOpen ? 'caret up' : 'caret'}
                  style={styles.pointCaret}
                />
              </div>
            ) : null}
          </div>
          <OffsetCloseButton />
        </div>

        {primaryTracking ? (
          isCheckpointMode ? (
            <CheckpointModuleCard
              isHeader
              horizontalPlacementIndex={1}
              carePlan={carePlan}
              tracking={primaryTracking}
              detailsLabel="History"
              activeHistory={activeAccordian}
              setActiveHistory={() => setActiveAccordian(primaryTracking.type)}
              styleOverrides={{
                content: {
                  ...(pointsOpen ? styles.shadedContent : {}),
                },
              }}
            />
          ) : (
            <ModuleTracking
              index={1}
              carePlan={carePlan}
              tracking={primaryTracking}
              isHeader
              activeHistory={activeAccordian}
              setActiveHistory={() => setActiveAccordian(primaryTracking.type)}
            />
          )
        ) : (
          <div style={styles.section}>
            <div
              style={[
                styles.content,
                historyOpen || pointsOpen ? styles.shadedContent : null,
              ]}
            />
          </div>
        )}

        {isCheckpointMode ? (
          <CheckpointSummaryCard
            carePlan={carePlan}
            tracking={primaryTracking}
            moduleCompletionStatus={moduleCompletionStatus}
            isIncentiveSummaryVisible={pointsOpen}
            onToggleIncentiveSummary={() => setActiveAccordian('points')}
            styleOverrides={{
              content: {
                ...(historyOpen ? styles.shadedContent : {}),
              },
            }}
          />
        ) : alertAction ? (
          <AlertCard
            action={alertAction}
            modalOpen={modalOpen}
            onToggleTrackingModal={toggleModal}
          />
        ) : (
          <div style={styles.section}>
            <div
              style={[
                styles.content,
                historyOpen || pointsOpen ? styles.shadedContent : null,
              ]}
            />
          </div>
        )}
      </div>
      {pointsOpen && <div style={styles.borderBlocker} />}
      {pointsOpen && (
        <IncentiveSummary
          carePlan={carePlan}
          moduleCompletionStatus={moduleCompletionStatus}
          title={carePlan.name}
          concise={concisePointSummary}
        />
      )}
      <div style={styles.sectionBackground} />
    </StyleRoot>
  );
};

HeaderMarkup.propTypes = {
  carePlan: PropTypes.object,
  moduleCompletionStatus: PropTypes.object,
  goalStatement: PropTypes.object,
  primaryTracking: PropTypes.object,
  modalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  activeAccordian: PropTypes.string,
  setActiveAccordian: PropTypes.func,
};

export default Radium(HeaderMarkup);
