import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import BiometricEventMarkup from './BiometricEventMarkup';
import Button from '../common/Button';
import Map from '../common/map/Map';
import ThirdPartyCookieWarning from '../common/ThirdPartyCookieWarning';
import WelcomeBackModal from '../common/WelcomeBackModal';

const EventDetailMarkup = ({
  events,
  pageNumber,
  pageSize,
  activeId,
  activeLat,
  activeLng,
  onHover,
  onLeave,
  onNext,
  onPrev,
  showNext,
  showPrev,
  onOpenEHS,
  showWelcomeBackModal,
  onWelcomeBackModalClosed,
  showThirdPartyCookieWarning,
  onThirdPartyCookieWarningClosed,
}) => {
  const styles = {
    container: {
      padding: '0 75px',
    },
    eventDetails: {
      marginBottom: '20px',
      paddingBottom: '10px',
      borderBottom: `1px solid ${Colors.lighterGray}`,
    },

    eventsHeader: {
      fontSize: '22px',
      color: Colors.lightGray,
      fontWeight: 400,
      paddingBottom: '20px',
      marginBottom: '20px',
      borderBottom: `1px solid ${Colors.lighterGray}`,
    },

    eventsCount: {
      fontSize: '16px',
      fontWeight: 400,
      color: Colors.linkBlue,
      marginBottom: '20px',
    },

    eventsContainer: {
      display: 'flex',
      marginBottom: '10px',
      minHeight: '520px',
    },

    eventList: {
      flex: '2 1 35%',
      marginRight: '5px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      paddingBottom: '40px',
    },

    eventNav: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      boxSizing: 'border-box',
    },

    eventNavArrow: {
      fontSize: '30px',
      color: Colors.linkBlue,
      cursor: 'pointer',
      ':hover': {
        color: Colors.darkenColor(Colors.linkBlue, 2),
      },
      ':active': {
        color: Colors.darkenColor(Colors.linkBlue, 4),
      },
    },

    mapContainer: {
      flex: '4 2 65%',
      marginLeft: '5px',
      maxHeight: '480px',
    },

    button: {
      width: '220px',
      padding: '12px',
    },
  };

  const hasEvents = events && events.length > 0;

  const selectedEvents = events
    ? events.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
    : [];

  const mapItems =
    (selectedEvents &&
      selectedEvents.map((event) => ({ ...event, id: event.eventId }))) ||
    [];

  return (
    <div style={styles.container}>
      {hasEvents ? (
        <div>
          <div style={styles.eventDetails}>
            <h2 style={styles.eventsHeader}>Upcoming Events</h2>

            <h4 style={styles.eventsCount}>
              {`${events.length} UPCOMING EVENTS`}
            </h4>

            <div style={styles.eventsContainer}>
              <div style={[GlobalStyles.light, styles.eventList]}>
                {selectedEvents.map((event, index) => (
                  <BiometricEventMarkup
                    event={event}
                    index={(pageNumber - 1) * pageSize + index}
                    key={`${event.eventId}-event`}
                    activeId={activeId}
                    activeLat={activeLat}
                    activeLng={activeLng}
                    onHover={onHover}
                    onLeave={onLeave}
                    onClick={onOpenEHS}
                  />
                ))}
                <div style={styles.eventNav}>
                  {showPrev ? (
                    <i
                      className="thin-0159_arrow_back_left"
                      style={styles.eventNavArrow}
                      onClick={onPrev}
                      key="prev"
                    />
                  ) : (
                    <span />
                  )}
                  {showNext ? (
                    <i
                      className="thin-0160_arrow_next_right"
                      style={styles.eventNavArrow}
                      onClick={onNext}
                      key="next"
                    />
                  ) : (
                    <span />
                  )}
                </div>
              </div>
              <div style={styles.mapContainer}>
                <Map
                  mapKey="bio-screening"
                  items={mapItems}
                  activeLat={activeLat}
                  activeLng={activeLng}
                  onHover={onHover}
                  onLeave={onLeave}
                  onClick={onOpenEHS}
                />
              </div>
            </div>
          </div>
          <Button
            style={[GlobalStyles.regular, styles.button]}
            onClick={onOpenEHS}
            text="Schedule Now"
          />
        </div>
      ) : (
        <h2 style={styles.eventsHeader}>No Upcoming Events at this Time</h2>
      )}

      {showWelcomeBackModal ? (
        <WelcomeBackModal onClose={onWelcomeBackModalClosed} />
      ) : showThirdPartyCookieWarning ? (
        <ThirdPartyCookieWarning onClose={onThirdPartyCookieWarningClosed} />
      ) : null}
    </div>
  );
};

EventDetailMarkup.propTypes = {
  events: PropTypes.array.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  activeId: PropTypes.number,
  activeLat: PropTypes.number,
  activeLng: PropTypes.number,
  onHover: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  showNext: PropTypes.bool.isRequired,
  showPrev: PropTypes.bool.isRequired,
  onOpenEHS: PropTypes.func.isRequired,
  showWelcomeBackModal: PropTypes.bool.isRequired,
  onWelcomeBackModalClosed: PropTypes.func.isRequired,
  showThirdPartyCookieWarning: PropTypes.bool.isRequired,
  onThirdPartyCookieWarningClosed: PropTypes.func.isRequired,
};

export default Radium(EventDetailMarkup);
