export default {
  mainContainer: {
    fontSize: '16px',
    textAlign: 'center',
    padding: '30px 0 80px',
  },

  icon: {
    fontSize: '50px',
    cursor: 'pointer',
  },

  welcomeText: {
    fontSize: '28px',
    margin: '10px 0',
  },
};
