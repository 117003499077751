import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { CoverageSelectors } from '@healthmine/greyhound-core/src/selectors';
import { withDrawer } from '../common/drawer2';
import AccountProfileDrawerContent from '../common/AccountProfileDrawerContent';

const styles = (theme) => ({
  root: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 20px',
    height: '80px',
    userSelect: 'none',
    transition: theme.globalStyles.transition1,
    '&:hover': {
      color: theme.palette.common.beefy,
    },
  },
  icon: {
    fontSize: '40px',
  },
  welcome: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
});

const MemberInfo = ({
  classes,
  drawer,
  userInfo: { firstName },
  selectedMemberCoverage: { coverageName },
}) => {
  return (
    <div>
      <a
        className={classes.root}
        onClick={() => drawer.open(AccountProfileDrawerContent)}
      >
        <i className={`icon-User-Circle ${classes.icon}`} />
        <div style={{ marginLeft: '10px' }}>
          <div className={classes.welcome}>Welcome, {firstName}</div>
          <div>{coverageName}</div>
        </div>
      </a>
    </div>
  );
};

MemberInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
  userInfo: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }).isRequired,
  selectedMemberCoverage: PropTypes.shape({
    coverageName: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  pure,
  withDrawer,
  connect((state) => ({
    userInfo: state.userInfo,
    selectedMemberCoverage: CoverageSelectors.selectCurrentCoverage(state),
  })),
  withStyles(styles)
)(MemberInfo);
