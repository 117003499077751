import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  ErrorConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import FloatingLabel from '../common/FloatingLabel';
import Button from '../../components/common/Button';
import _get from 'lodash/get';

const ResetPasswordMarkup = ({
  formState,
  errors,
  validateField,
  validatedFields,
  onEmailChange,
  onPasswordChange,
  onPasswordConfirmChange,
  onClickHandler,
  isComplete,
  changeSubmitted,
}) => {
  const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);
  const styles = {
    mainContainer: {
      width: leapFrogUI ? 'initial' : '1000px',
      maxWidth: '100%',
      color: Colors.darkText,
      backgroundColor: Colors.light,
      padding: leapFrogUI ? '30px' : '10px 10px 10px 200px',
      marginTop: '10px',
      boxSizing: 'border-box',
      borderRadius: leapFrogUI ? '6px' : '0',
      border: leapFrogUI ? `1px solid ${Colors.mediumGray3}` : 'none',

      '@media screen and (max-width: 760px) and (min-width: 481px)': {
        padding: '10px 10px 10px 100px',
      },

      '@media screen and (max-width: 480px)': {
        padding: '10px 40px',
      },
    },

    inputWrapper: {
      clear: 'both',
      marginTop: '20px',
    },

    bottomLinks: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '30px',
    },

    linkbtn: {
      color: Colors.bodyText,
      textDecoration: 'none',
      fontSize: '16px',
      marginTop: '-2px',
      marginLeft: '63px',
      display: 'none',
    },

    inputContainer: {
      float: 'left',
      marginRight: '10px',
      marginTop: '10px',
      marginBottom: '0px',
      position: 'relative',
    },

    errorMessage: {
      color: 'red',
      fontWeight: '600',
      fontSize: '16px',
      marginTop: '30px',
      marginBottom: '-30px',
    },

    mainHeader: {
      fontSize: '24px',
      margin: '0 0 5px',
      color: Colors.black,
    },

    subHeaderText: {
      margin: '0 0 20px',
      color: Colors.black,
      maxWidth: '520px',
      fontSize: '16px',
    },

    imageSpacing: {
      marginRight: '20px',
      marginTop: '20px',
    },

    iconImage: {
      fontSize: '35px',
      color: Colors.white,
      backgroundColor: Colors.bodyText,
      borderRadius: '50%',
      padding: '20px',
    },

    title: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '30px',
    },

    formContainer: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
      boxSizing: 'initial',
    },

    wrapper: {
      marginTop: '30px',
      marginLeft: '-101px',
    },

    marginTop: {
      marginTop: '40px',
    },

    helpText: {
      color: 'red',
      fontSize: '16px',
      display: 'block',
      position: 'absolute',
      width: '605px',
    },

    passwordMessage: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingLeft: '59px',
    },

    para: {
      maxWidth: '595px',
      color: 'rgb(124, 136, 149)',
      paddingTop: '0px',
      margin: '-10px 0px -6px -60px',
      lineHeight: '15px',
      fontSize: '16px',
    },

    button: {
      backgroundColor: Colors.dark,
      color: Colors.light,
      width: '100px',
      height: '37px',
      marginTop: '-12px',
      marginLeft: '-93px',
      borderRadius: '4px',
      fontSize: '16px',
      userSelect: 'auto',
    },
  };

  const { emailAddress, password, passwordConfirm } = formState;

  const passwordMatchAndValid =
    _get(password, 'value') === _get(passwordConfirm, 'value') &&
    _get(password, 'isValid') === true;

  const isEmailValidated = validatedFields.indexOf('emailAddress') > -1;

  const isPasswordValidated = validatedFields.indexOf('password') > -1;

  const isConfirmPasswordValidated =
    validatedFields.indexOf('confirmPassword') > -1;

  const formIsValid =
    emailAddress.isValid && password.isValid && passwordConfirm.isValid;

  const updateEmail = (event) => onEmailChange(event.target.value);

  const validateEmailAddress = () => validateField('emailAddress');

  const updatePassword = (event) => onPasswordChange(event.target.value);

  const validatePassword = () => validateField('password');

  const updateConfirmPassword = (event) =>
    onPasswordConfirmChange(event.target.value);

  const validateConfirmPassword = () => validateField('confirmPassword');

  const displayLabel = isComplete
    ? { ...styles.linkbtn, display: 'block' }
    : styles.linkbtn;

  const buttonStyle = formIsValid
    ? { ...styles.button, marginLeft: '100px' }
    : { ...styles.button, marginLeft: '100px' };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.title}>
        <span style={styles.imageSpacing}>
          <i className="thin-0632_security_lock" style={styles.iconImage} />
        </span>
        <span>
          <h2 style={[styles.mainHeader, GlobalStyles.extraLight]}>
            Reset Password
          </h2>
          <h3 style={[styles.subHeaderText, GlobalStyles.regular]}>
            Please enter your email and new password. All items are kept secure
            and confidential.
          </h3>
        </span>
      </div>
      <div style={styles.formContainer}>
        <div
          style={
            isEmailValidated === false || emailAddress.isValid !== false
              ? styles.inputContainer
              : [styles.inputContainer, { marginBottom: '20px' }]
          }
        >
          <FloatingLabel
            onChange={updateEmail}
            placeHolder={'Email Address'}
            value={emailAddress.value}
            isValid={isEmailValidated ? emailAddress.isValid : null}
            onBlur={validateEmailAddress}
          />
          {isEmailValidated &&
            (emailAddress.isValid == false ? (
              <span style={styles.helpText}>
                {emailAddress.error
                  ? emailAddress.error
                  : localizedContent.errorMessages[
                      ErrorConstants.ErrorType.INVALID_EMAIL
                    ]}
              </span>
            ) : null)}
        </div>
        <div
          style={[
            styles.inputContainer,
            styles.passwordMessage,
            GlobalStyles.regular,
          ]}
        >
          <p
            style={{ fontSize: '16px', fontWeight: '600', marginLeft: '-60px' }}
          >
            Please Enter New Password:
          </p>
          <p style={styles.para}>
            {
              localizedContent.errorMessages[
                ErrorConstants.ErrorType.INVALID_PASSWORD_FORMAT
              ]
            }
          </p>
        </div>
        <div style={styles.marginTop} />
        <div
          style={
            isPasswordValidated === false || password.isValid !== false
              ? styles.inputContainer
              : [styles.inputContainer, { marginBottom: '20px' }]
          }
        >
          <FloatingLabel
            onChange={updatePassword}
            placeHolder={'Password'}
            type={'password'}
            value={password.value}
            isValid={isPasswordValidated ? password.isValid : null}
            onBlur={validatePassword}
          />
          {isPasswordValidated &&
            (password.isValid == false ? (
              <span style={styles.helpText}>
                {password.error
                  ? password.error
                  : localizedContent.errorMessages[
                      ErrorConstants.ErrorType.INVALID_PASSWORD_MATCH
                    ]}
              </span>
            ) : null)}
        </div>
        <div style={styles.inputWrapper}>
          <div
            style={
              isConfirmPasswordValidated === false ||
              passwordConfirm.isValid !== false
                ? styles.inputContainer
                : [styles.inputContainer, { marginBottom: '20px' }]
            }
          >
            <FloatingLabel
              onChange={updateConfirmPassword}
              placeHolder={'Confirm Password'}
              type={'password'}
              value={passwordConfirm.value}
              isValid={
                passwordMatchAndValid || isConfirmPasswordValidated
                  ? passwordConfirm.isValid
                  : null
              }
              onBlur={validateConfirmPassword}
            />
            {isConfirmPasswordValidated &&
              (passwordConfirm.isValid == false ? (
                <span style={styles.helpText}>
                  {passwordConfirm.error
                    ? passwordConfirm.error
                    : localizedContent.errorMessages[
                        ErrorConstants.ErrorType.INVALID_PASSWORD_MATCH
                      ]}
                </span>
              ) : null)}
          </div>
        </div>
        {errors.length > 0 && (
          <div style={styles.inputWrapper}>
            <div style={styles.errorMessage}>
              <table>
                <tbody>
                  {errors.map((val) => {
                    return (
                      <tr key={val}>
                        <td>
                          <span style={GlobalStyles.regular}>{val}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div style={[styles.inputWrapper, styles.wrapper]}>
          <div style={styles.bottomLinks}>
            <Button
              style={[GlobalStyles.regular, buttonStyle]}
              text="Submit"
              onClick={
                !formIsValid || (formIsValid && changeSubmitted)
                  ? undefined
                  : onClickHandler
              }
            />

            <span style={[GlobalStyles.regular, displayLabel]}>
              New Password Confirmed
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPasswordMarkup.propTypes = {
  formState: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  validateField: PropTypes.func,
  validatedFields: PropTypes.array,
  onEmailChange: PropTypes.func,
  onPasswordChange: PropTypes.func,
  onPasswordConfirmChange: PropTypes.func,
  onClickHandler: PropTypes.func,
  isComplete: PropTypes.bool,
  changeSubmitted: PropTypes.bool,
};

export default Radium(ResetPasswordMarkup);
