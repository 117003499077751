import AppTheme from '@healthmine/greyhound-core/src/modules/app-themes/LeapFrog';
import globalStyles from './GlobalStyles';

export default {
  base: {
    lineHeight: 1.5,
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: globalStyles.baseFont,
  },
  link: {
    color: AppTheme.colors.beefy,
    cursor: 'pointer',
    transition: globalStyles.transition1,
    display: 'inline',
    textDecoration: 'none',
    '&:hover': {
      color: AppTheme.utils.colorLighten(AppTheme.colors.beefy),
    },
  },
  body: {
    lineHeight: 1.5,
  },
  h1: {
    fontSize: '36px',
    lineHeight: '43px',
    fontWeight: '500',
  },
  h2: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '400',
  },
  h3: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: '400',
  },
  h3Medium: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: '500',
  },
  h4: {
    fontSize: '16px',
    lineHeight: '17px',
    fontWeight: '400',
  },
  h4Medium: {
    fontSize: '16px',
    lineHeight: '17px',
    fontWeight: '500',
  },
  h5: {
    fontSize: '16px',
    lineHeight: '17px',
    fontWeight: '700',
    textTransform: 'uppercase',
    color: AppTheme.colors.blueGray,
  },
  center: {
    textAlign: 'center',
  },
  inline: {
    display: 'inline-block',
  },
  primary: {
    color: AppTheme.colors.planet9,
  },
  secondary: {
    color: AppTheme.colors.blueGray,
  },
};
