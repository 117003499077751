import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import MeasurementHeader from './MeasurementHeader';
import MeasurementBody from './MeasurementBody';
import { Flex, Box } from 'reflexbox';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { connect } from 'react-redux';

const Measurement = ({
  measurement,
  history,
  setHistory,
  index,
  editing,
  startEdit,
  completeEdit,
  cancelEdit,
  setEdit,
  setSecondaryEdit,
  errorMessage,
  type,
}) => {
  const styles = {
    container: {
      flex: '0 0 300px',
      height: '220px',
      maxHeight: '220px',
      borderBottom: history ? 0 : `1px solid ${Colors.gutterGray}`,
      borderRight: `1px solid ${Colors.gutterGray}`,
      position: 'relative',
      marginBottom: history ? '356px' : 0,
      maxWidth: '300px',
    },
    manualEntry: {
      fontSize: '30px',
      color: Colors.contentText,
      cursor: 'pointer',
      ':hover': {
        color: Colors.linkBlue,
      },
    },
    cancelEntry: {
      fontSize: '30px',
      color: Colors.bodyText,
      cursor: 'pointer',
      ':hover': {
        color: Colors.red,
      },
    },
    saveEntry: {
      fontSize: '30px',
      color: Colors.bodyText,
      cursor: 'pointer',
      ':hover': {
        color: Colors.lightGreen,
      },
      visibility: editing ? 'visible' : 'hidden',
    },
    title: {
      fontSize: '16px',
      fontWeight: 400,
      textAlign: 'center',
    },
    measurementError: {
      alignSelf: 'center',
      color: Colors.red,
      fontWeight: '200',
      fontSize: '16px',
      textAlign: 'center',
    },
  };

  return (
    <Flex column sm={4} style={styles.container}>
      <Flex px={2} pt={1} pb={3} align={'center'} justify={'space-between'}>
        <Box>
          {editing ? (
            <i
              key={'cancelData'}
              style={styles.cancelEntry}
              className={'thin-0153_delete_exit_remove_close'}
              title={'Cancel'}
              onClick={cancelEdit}
            />
          ) : (
            <i
              key={'enterData'}
              style={styles.manualEntry}
              className={'thin-0001_compose_write_pencil_new'}
              title={"Edit today's value manually"}
              onClick={startEdit}
            />
          )}
        </Box>
        <Box style={styles.title}>{measurement.displayName}</Box>
        <Box>
          <i
            key={'saveData'}
            style={styles.saveEntry}
            className={'thin-0154_ok_successful_check'}
            title={'Save'}
            onClick={completeEdit}
          />
        </Box>
      </Flex>
      <MeasurementHeader
        measurement={measurement}
        editing={editing}
        setEdit={setEdit}
        setSecondaryEdit={setSecondaryEdit}
      />
      {errorMessage && (
        <Box col={10} mt={2} style={styles.measurementError}>
          {errorMessage}
        </Box>
      )}
      <MeasurementBody
        history={history}
        toggleHistory={setHistory}
        index={index}
        measurement={measurement}
        type={type}
      />
    </Flex>
  );
};

Measurement.propTypes = {
  measurement: PropTypes.object,
  history: PropTypes.bool,
  toggleHistory: PropTypes.func,
  setHistory: PropTypes.func,
  index: PropTypes.number,
  editing: PropTypes.bool,
  startEdit: PropTypes.func,
  cancelEdit: PropTypes.func,
  completeEdit: PropTypes.func,
  setEdit: PropTypes.func,
  setSecondaryEdit: PropTypes.func,
  errorMessage: PropTypes.string,
  action: PropTypes.object,
  type: PropTypes.string,
  actions: PropTypes.object,
};

export default compose(
  withState('editing', 'toggleEditing', false),
  withState('editValue', 'setEditValue', ['', '']),
  withState('errorMessage', 'setErrorMessage', undefined),
  connect(null, null),
  withHandlers({
    startEdit: ({ toggleEditing }) => () => toggleEditing(true),

    completeEdit: ({
      toggleEditing,
      editValue,
      dispatch,
      measurement,
      setErrorMessage,
    }) => () => {
      if (measurement.validate(editValue)) {
        setErrorMessage(undefined);
        dispatch(measurement.saveAction(editValue)).then(() =>
          toggleEditing(false)
        );
      } else {
        setErrorMessage(measurement.errorMessage);
      }
    },

    cancelEdit: ({ toggleEditing, setErrorMessage }) => () => {
      setErrorMessage(undefined);
      toggleEditing(false);
    },
    setEdit: ({ setEditValue, editValue }) => (event) =>
      setEditValue([event.target.value, editValue[1]]),
    setSecondaryEdit: ({ setEditValue, editValue }) => (event) =>
      setEditValue([editValue[0], event.target.value]),
    setHistory: ({ toggleHistory, type }) => () =>
      toggleHistory((history) => (history === type ? '' : type)),
  }),
  Radium
)(Measurement);
