import PageWithSubNav from '../components/common/PageWithSubNav';
import Authentication from './Authentication';
import ViewDocument from '../components/common/ViewDocument';
import withProps from 'recompose/withProps';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { QuestionMark, LightBulb } from '../styles/uhc-icons';

const SweepstakesPage = withProps({
  title: 'Sweepstakes',
  key: 'sweepstakesPage',
  icon: 'thin-0670_diamond_prize_award_jewellery_ring',
})(PageWithSubNav);

const RewardsPage = withProps({
  title: 'Rewards',
  key: 'rewardsPage',
  icon: 'thin-0670_diamond_prize_award_jewellery_ring',
})(PageWithSubNav);

const InfoPage = withProps({
  title: 'Information',
  key: 'infoPage',
  icon: 'information',
  iconStyle: {
    backgroundColor: Colors.contentText,
    fontSize: '76px',
  },
})(PageWithSubNav);

const InfoPageUhcBranded = withProps({
  title: 'Information',
  key: 'infoPage',
  uhcIcon: QuestionMark,
  iconStyle: { backgroundColor: Colors.white },
})(PageWithSubNav);

const PrivacyPage = withProps({
  title: 'Privacy Policy',
  key: 'infoPage',
  documentFile: 'privacy_policy_en.html',
  iconClass: 'thin-0043_eye_visibility_show_visible',
  hideContainer: true,
})(ViewDocument);

const TermsPage = withProps({
  title: 'Terms of Use',
  key: 'infoPage',
  documentFile: 'terms_of_use_en.html',
  hideContainer: true,
})(ViewDocument);

const SettingsPage = withProps({
  title: 'Settings',
  key: 'settingsPage',
  icon: 'thin-0051_settings_gear_preferences',
})(PageWithSubNav);

const SettingsPageUhcBranded = withProps({
  title: 'Settings',
  key: 'settingsPage',
  uhcIcon: LightBulb,
  iconStyle: { backgroundColor: Colors.white },
})(PageWithSubNav);

const BioMetricScreeningsPage = withProps({
  title: 'Biometric Screening',
  key: 'biometricScreeningPage',
  icon: 'thin-0637_fingerprint_scan_security_access',
})(PageWithSubNav);

const MinimalHeaderAuth = withProps({
  minimalHeader: true,
})(Authentication);

export {
  SweepstakesPage,
  RewardsPage,
  InfoPage,
  PrivacyPage,
  TermsPage,
  SettingsPage,
  MinimalHeaderAuth,
  BioMetricScreeningsPage,
  InfoPageUhcBranded,
  SettingsPageUhcBranded,
};
