import _get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import _template from 'lodash/template';

const BLUE_BUTTON_AUTH_ENDPOINT =
  'https://sandbox.bluebutton.cms.gov/v1/o/authorize/';

const BLUE_BUTTON_CLIENT_ID = 'BH54txOBrVkOD2EONkKCxI1623J4ZCQjjLMv5Xny';
const BLUE_BUTTON_AUTH_REDIRECT_URI = `${window.location.origin}/blue_button/`;
const BLUE_BUTTON_POST_AUTH_ROUTE = '/#/health-record';

const BLUE_BUTTON_API_URLS = mapValues(
  {
    COVERAGE:
      'https://sandbox.bluebutton.cms.gov/v1/fhir/Coverage/?beneficiary=<%= patientId %>',

    EOB:
      'https://sandbox.bluebutton.cms.gov/v1/fhir/ExplanationOfBenefit/?patient=<%= patientId %>',

    USER_INFO: 'https://sandbox.bluebutton.cms.gov/v1/connect/userinfo',

    PATIENT:
      'https://sandbox.bluebutton.cms.gov/v1/fhir/Patient/<%= patientId %>',
  },
  _template
);

export const types = {
  BLUE_BUTTON_CLEAR: 'blueButton/clearData',
  BLUE_BUTTON_SET_ACCESS_TOKEN: 'blueButton/setAccessToken',
  BLUE_BUTTON_SET_PATIENT_ID: 'blueButton/setPatientId',
  BLUE_BUTTON_SET_RAW_DATA: 'blueButton/setRawData',
  BLUE_BUTTON_START_AUTH: 'blueButton/startAuth',
};

const dispatchFetch = (urlTemplate) => (dispatch, getState) => {
  const state = getState();
  const accessToken = _get(state, 'blueButton.accessToken');
  const patientId = _get(state, 'blueButton.patientId');

  const url = urlTemplate({ patientId });

  const fetchOptions = {
    method: 'GET',

    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetch(url, fetchOptions)
    .then((response) => response.text())
    .then(JSON.parse);
};

const clearBlueButtonData = () => (dispatch) => {
  sessionStorage.removeItem('BlueButtonAccessToken');
  dispatch({
    type: types.BLUE_BUTTON_CLEAR,
  });
};

const initializeBlueButton = (windowLocation, dispatch) => {
  let redirect = false;
  let blueButtonAccessToken;

  if (windowLocation.pathname === '/blue_button/') {
    blueButtonAccessToken = /^#\/access_token=(.+?)&/.exec(windowLocation.hash)
      ? RegExp.$1
      : '';

    redirect = true;
  } else {
    blueButtonAccessToken = sessionStorage.getItem('BlueButtonAccessToken');
  }

  if (blueButtonAccessToken) {
    dispatch(setBlueButtonAccessToken(blueButtonAccessToken));

    if (!redirect) {
      dispatch(loadBlueButtonData());
    }
  }

  if (redirect) {
    window.location.href = BLUE_BUTTON_POST_AUTH_ROUTE;
  }
};

const loadBlueButtonData = () => (dispatch) =>
  dispatch(dispatchFetch(BLUE_BUTTON_API_URLS.USER_INFO))
    .then(({ patient: patientId }) =>
      dispatch({
        type: types.BLUE_BUTTON_SET_PATIENT_ID,
        patientId,
      })
    )
    //  We're not really using the patient information, but we could, so
    //  I'll leave it in here if only to serve as an illustration of what's
    //  possible.
    .then(() => dispatch(dispatchFetch(BLUE_BUTTON_API_URLS.PATIENT)))
    .then(() => dispatch(dispatchFetch(BLUE_BUTTON_API_URLS.EOB)))
    .then((rawData) =>
      dispatch({
        type: types.BLUE_BUTTON_SET_RAW_DATA,
        rawData,
      })
    );

const redirectToBlueButtonAuth = () => {
  window.location.href = `${BLUE_BUTTON_AUTH_ENDPOINT}?client_id=${BLUE_BUTTON_CLIENT_ID}&redirect_uri=${BLUE_BUTTON_AUTH_REDIRECT_URI}&response_type=token`;
};

const setBlueButtonAccessToken = (accessToken) => {
  sessionStorage.setItem('BlueButtonAccessToken', accessToken);

  return {
    type: types.BLUE_BUTTON_SET_ACCESS_TOKEN,
    accessToken,
  };
};

const startBlueButtonAuth = () => {
  sessionStorage.removeItem('BlueButtonAccessToken');

  setTimeout(redirectToBlueButtonAuth, 0);

  return {
    type: types.BLUE_BUTTON_START_AUTH,
  };
};

export default {
  clearBlueButtonData,
  initializeBlueButton,
  startBlueButtonAuth,
};
