import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import _ from 'lodash';
import List, {
  ListHeader,
  ListContent,
  ListItem,
} from '../../../../common/list/List';
import { withTheme, Grid } from '@material-ui/core';
import { withDrawer } from '../../../../common/drawer2';
import CoachingProgram from '../drawer/CoachingProgram';
import CoachingEnroll from '../drawer/CoachingEnroll';
import StatusTitle from '../../../../common/StatusTitle';
import FrequencyBoxes from '../../../../common/widgets/FrequencyBoxes';

const CoachingProgramList = ({
  theme,
  coachingPrograms,
  openCoachingProgram,
  openCoachingEnroll,
}) => {
  return (
    <List>
      <ListHeader
        color={theme.palette.primary.main}
        iconClass="icon-Stopwatch"
        title="Health Coaching"
        isMute={_.isEmpty(coachingPrograms)}
      />
      <ListContent>
        {_.map(
          coachingPrograms,
          (
            {
              isEnrolled,
              programId,
              programName,
              compliantActivityCount,
              actionLabel,
              availabilityDescription,
              compliant,
            },
            idx
          ) => {
            const openDrawer = isEnrolled
              ? () => openCoachingProgram(programId)
              : () => openCoachingEnroll(programId);

            const markedCount = compliantActivityCount;
            const label = _.isUndefined(availabilityDescription)
              ? actionLabel
              : availabilityDescription;
            return (
              <ListItem onClick={openDrawer} key={`coaching_program_${idx}`}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={10}>
                    <StatusTitle
                      status={compliant ? 'positive' : 'neutral'}
                      title={programName}
                      subtitle={label}
                    />
                  </Grid>
                  <Grid item>
                    <FrequencyBoxes
                      frequencyCount={3}
                      markedCount={markedCount}
                      markedColor={theme.palette.primary.main}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            );
          }
        )}
      </ListContent>
    </List>
  );
};

CoachingProgramList.propTypes = {
  theme: PropTypes.object.isRequired,
  coachingPrograms: PropTypes.array.isRequired,
  openCoachingProgram: PropTypes.func.isRequired,
  openCoachingEnroll: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withDrawer,
  withHandlers({
    openCoachingEnroll: ({ drawer }) => (programId) =>
      drawer.open(CoachingEnroll, { programId }),
    openCoachingProgram: ({ drawer }) => (programId) =>
      drawer.open(CoachingProgram, { programId }),
  }),
  withTheme()
)(CoachingProgramList);
