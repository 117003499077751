import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, pure, withHandlers } from 'recompose';
import {
  DrawerContent,
  DrawerSection,
  DrawerNavigation,
  withDrawer,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';
import HraSurvey from './HraSurvey';
import { withStyles } from '@material-ui/core';
import TypographyStyles from '../../../../styles/Typography';

const HraEpilogue = ({ openHraSurvey, classes, visitMemberHealthPrograms }) => {
  return (
    <DrawerContent title="Health Risk Assessment">
      <DrawerSection>
        <Typography type="body">
          Congratulations! You finished your Health Risk Assessment.
        </Typography>
        <Typography type="body" className={classes.healthProgramText}>
          To learn more about staying healthy, visit the{' '}
          <a onClick={visitMemberHealthPrograms} className={classes.link}>
            Member Health Programs
          </a>{' '}
          page!
        </Typography>
      </DrawerSection>
      <DrawerNavigation
        title="Take a quick survey on your experience"
        onClick={openHraSurvey}
      />
    </DrawerContent>
  );
};

HraEpilogue.propTypes = {
  openHraSurvey: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  visitMemberHealthPrograms: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withRouter,
  withDrawer,
  withHandlers({
    openHraSurvey: ({ drawer }) => () => {
      drawer.open(HraSurvey, null, { resetStack: true });
    },
    visitMemberHealthPrograms: ({ drawer, router }) => () => {
      drawer.close();
      router.push('/health-programs');
    },
  }),
  withStyles({
    link: TypographyStyles.link,
    healthProgramText: {
      marginTop: '15px',
    },
  })
)(HraEpilogue);
