import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ListContext } from './List';

const styles = () => ({
  root: {
    backgroundColor: 'white',
    borderTop: '1px solid rgba(0,0,0,0.1)',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0 0 4px 4px',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
  },
  icon: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    border: '1px solid #b7b7b7',
    marginRight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& div:first-child': {
      height: '10px',
      width: '2px',
      backgroundColor: 'black',
      position: 'absolute',
    },
    '& div:last-child': {
      height: '2px',
      width: '10px',
      backgroundColor: 'black',
    },
  },
});

const ListFooter = ({ classes, onClick, actionText }) => {
  const { state } = useContext(ListContext);
  return (
    state.isOpen && (
      <div className={classnames(classes.root, 'print-hidden')}>
        <button className={classes.actionContainer} onClick={onClick}>
          <div className={classes.icon}>
            <div />
            <div />
          </div>
          {actionText && <div>{actionText}</div>}
        </button>
      </div>
    )
  );
};

ListFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  actionText: PropTypes.string,
};

export default withStyles(styles)(ListFooter);
