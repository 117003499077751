import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { withTheme } from '@material-ui/core/styles';
import { Grid, withStyles } from '@material-ui/core';
import ChecklistHeader from './ChecklistHeader';
import HealthActions from './HealthActions';
import Medications from './Medications';
import Questions from './Questions';
import AccountSwitcher from '../../common/AccountSwitcher';
import _isNull from 'lodash/isNull';
import {
  withDoctorChecklist,
  withMemberSummaryData,
} from '@healthmine/greyhound-core/src/hocs';
import { compose, withProps } from 'recompose';
import './doctorChecklist.css';
import { filter as _filter, get as _get } from 'lodash';
import MemberSummary from '../health-record/MemberSummary/MemberSummary';

const styles = (theme) => ({
  titleContainer: {
    marginBottom: '20px',
  },
  button: {
    borderRadius: theme.globalStyles.borderRadius1,
    height: '48px',
    padding: '0 24px',
    backgroundColor: '#f6f6f6',
    boxShadow: theme.globalStyles.shadow2,
    fontWeight: 'bold',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  buttonText: {
    marginLeft: '15px',
  },
});

class DoctorChecklist extends React.Component {
  constructor() {
    super();
    this.toPrint = createRef();
  }
  componentDidMount() {
    if (_isNull(this.props.myDoctorQuestions)) {
      this.props.getDoctorQuestions();
    }

    if (_isNull(this.props.mySelfReportedMedications)) {
      this.props.getSelfReportedMedications();
    }
  }

  render() {
    const { theme, classes, memberFields, fullName } = this.props;

    return (
      <Grid container direction="column" spacing={32}>
        <AccountSwitcher />
        <Grid container className={classes.titleContainer}>
          <Grid item sm={10}>
            <ChecklistHeader />
          </Grid>
          <Grid item sm={2}>
            <ReactToPrint
              trigger={() => (
                <button className={classes.button}>
                  <span className="icon-Printer" />
                  <span className={classes.buttonText}>PRINT</span>
                </button>
              )}
              content={() => this.toPrint}
            />
          </Grid>
        </Grid>

        <div ref={(el) => (this.toPrint = el)}>
          {memberFields.length > 0 && (
            <Grid item>
              <div className="print-spacing">
                <MemberSummary
                  fullName={fullName}
                  memberFields={memberFields}
                />
              </div>
            </Grid>
          )}
          <Grid item>
            <div className="print-spacing">
              <HealthActions listColor={theme.palette.common.beefy} />
            </div>
          </Grid>
          <Grid item>
            <div className="print-spacing">
              <Medications listColor={theme.palette.common.venus} />
            </div>
          </Grid>
          <Grid item>
            <Questions listColor={theme.palette.common.mintJulep} />
          </Grid>
        </div>
      </Grid>
    );
  }
}

DoctorChecklist.propTypes = {
  theme: PropTypes.object.isRequired,
  myDoctorQuestions: PropTypes.array,
  getDoctorQuestions: PropTypes.func.isRequired,
  mySelfReportedMedications: PropTypes.array,
  getSelfReportedMedications: PropTypes.func.isRequired,
  classes: PropTypes.object,
  fullName: PropTypes.string,
  memberFields: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      level: PropTypes.object,
      units: PropTypes.string,
      field: PropTypes.string.isRequired,
      recordedOn: PropTypes.string,
    })
  ),
};

export default compose(
  withMemberSummaryData,
  connect((state, { firstName }) => {
    return {
      provider: _get(state, ['demo', 'provider', firstName.toLowerCase()]),
    };
  }),
  withDoctorChecklist,
  withProps(({ height, weight, provider }) => ({
    memberFields: _filter([provider, height, weight], (field) => !!field),
  })),
  withTheme(),
  withStyles(styles)
)(DoctorChecklist);
