import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, pure, withHandlers } from 'recompose';
import { DrawerNavigation } from '../../../common/drawer2';
import createCachedSelector from 're-reselect';
import { selectDataSources } from '@healthmine/greyhound-core/src/selectors/MyDataSelector';
import { pick, chain, join, lowerCase } from 'lodash';
import { MyDataActions } from '@healthmine/greyhound-core/src/actions';

const sourceSelector = (state, props) => {
  const sources = selectDataSources(state);
  return props.tracker.sourceSelector(sources);
};
const propSelector = createCachedSelector(
  sourceSelector,
  ({ deviceCredentials }) => deviceCredentials,
  (sources, deviceCredentials) => ({
    sources: chain(sources)
      .map(({ name }) => name)
      .filter((name) => name !== 'manual')
      .value(),
    deviceCredentials,
  })
)((state, props) => props.tracker.dataKey);

const ManageDevice = ({ configureDevice, sources, tracker }) => {
  const hasSources = sources.length > 0;
  const { displayName } = tracker;

  return (
    <DrawerNavigation
      title={
        hasSources
          ? 'Manage devices'
          : `Connect a ${lowerCase(displayName)} tracking device`
      }
      iconClass={hasSources ? 'icon-Repeat-Once-2' : undefined}
      subtitle={
        hasSources ? `connected ${join(sources, ', ')}` : 'no device connected'
      }
      onClick={configureDevice}
    />
  );
};

ManageDevice.propTypes = {
  tracker: PropTypes.object.isRequired,
  configureDevice: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired,
};

export default compose(
  pure,
  connect(
    (state, props) => propSelector(state, props),
    (dispatch) => ({
      actions: bindActionCreators(
        pick(MyDataActions, ['deviceConnected', 'getMyDataSummary']),
        dispatch
      ),
    })
  ),
  withHandlers({
    configureDevice: ({ deviceCredentials, actions }) => () => {
      const { memberId, humanApiClientId, publicToken } = deviceCredentials;

      window.HumanConnect.open({
        clientUserId: memberId,
        clientId: humanApiClientId,
        publicToken,
        modal: 1,
        finish: (err, sessionTokenObject) => {
          if (err) {
            actions.getMyDataSummary();
          } else {
            const payload = pick(sessionTokenObject, [
              'humanId',
              'sessionToken',
            ]);
            actions.deviceConnected(payload);
          }
        },
        close: actions.getMyDataSummary,
        error: actions.getMyDataSummary,
      });
    },
  })
)(ManageDevice);
