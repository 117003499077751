import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import ReactHighcharts from 'react-highcharts';
import { GlobalStyles, Colors } from '@healthmine/greyhound-core/src/modules';

const oneDay = 24 * 3600 * 1000;

const barConfig = {
  column: {
    animation: false,
    maxPointWidth: 5,
    borderWidth: 0,
  },
};

const lineConfig = {
  line: {
    animation: false,
    marker: {
      enabled: false,
    },
  },
};

const createConfig = ({ series, ticks, legend, name, unit, bar }) => ({
  chart: {
    type: bar ? 'column' : 'line',
    height: 275,
    animation: false,
    style: {
      ...GlobalStyles.regular,
    },
  },
  title: {
    text: name,
    useHTML: true,
    widthAdjust: 0,
    align: 'left',
    style: {
      fontSize: '16px',
      fontWeight: 600,
      left: 0,
      top: '2px',
      right: 0,
      paddingTop: '5px',
      paddingBottom: '8px',
      borderBottom: `1px solid ${Colors.black}`,
      display: 'block',
      textAlign: 'center',
    },
  },
  legend: {
    enabled: !!legend,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ` ${unit}`,
  },
  xAxis: {
    showFirstLabel: true,
    type: 'datetime',
    dateTimeLabelFormats: {
      week: '%m/%d',
    },
    min: ticks[0],
    max: ticks[4],
    minTickInterval: oneDay * 7,
    tickInterval: oneDay * 7,
    startOfWeek: 0,
    gridLineWidth: 1,
    tickWidth: 0,
    plotBands: [
      {
        color: {
          linearGradient: [0, 250, 0, 0],
          stops: [[0, '#fff'], [1, '#D5D5D5']],
        },
        from: ticks[2],
        to: ticks[3],
      },
    ],
  },
  yAxis: {
    tickAmount: 5,
    endOnTick: false,
    allowDecimals: false,
    min: 0,
    gridLineWidth: 1,
    gridLineDashStyle: 'dot',
    showFirstLabel: false,
    showLastLabel: true,
    title: {
      text: null,
    },
  },
  plotOptions: bar ? barConfig : lineConfig,
  series,
});
const DataChart = ({ series, ticks, legend, name, unit, bar }) => (
  <ReactHighcharts
    config={createConfig({
      series,
      ticks,
      legend,
      name,
      unit,
      bar,
    })}
  />
);

DataChart.propTypes = {
  series: PropTypes.array,
  ticks: PropTypes.array,
  legend: PropTypes.bool,
  name: PropTypes.string,
  unit: PropTypes.string,
  bar: PropTypes.bool,
};

export default Radium(DataChart);
