import React from 'react';
import { withStyles, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Questionnaire } from '../common/questionnaire';
import { QuestionnaireActions } from '@healthmine/greyhound-core/src/actions';
import { bindActionCreators } from 'redux';

const styles = () => ({
  modalRoot: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
const FteModal = ({
  classes,
  updatedFte,
  fteAnswers,
  sectionImages,
  actions,
  open,
  onClose,
  completeFTE,
  firstName,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    disableBackdropClick
    className={classes.modalRoot}
  >
    <div className={classes.root}>
      <Questionnaire
        questionnaire={updatedFte}
        dependencyAnswers={fteAnswers}
        savedAnswers={fteAnswers || {}}
        submitAnswer={actions.postQuestionnaireAnswers}
        sectionImages={sectionImages}
        completeQuestionnaire={completeFTE}
        isSkippable
        onQuestionnaireExit={onClose}
        questionnaireText={{
          welcome: {
            subtitle: 'JUMPSTART YOUR HEALTH JOURNEY',
            title: 'Customize Your Experience',
            description:
              // eslint-disable-next-line
              'It takes just 5 minutes to complete your health profile and earn $15. Plus get the tools you need to take control of your health and live the healthiest life possible.',
            cta: "Let's Get Started",
          },
          skip: {
            title: 'Are you sure you want to skip this question?',
            description:
              // eslint-disable-next-line
              'By skipping questions, you will miss out on earning $15 for completing the survey.',
            backCta: 'TAKE ME BACK',
            forwardCta: 'YES, I UNDERSTAND',
          },
          done: {
            incomplete: {
              title: 'So close!',
              description:
                // eslint-disable-next-line
                'Looks like you skipped a question or two along the way. In order to receive your $15 reward you must answer every question. You can go back now and answer any questions by pressing "Back" or press "Done" to submit an incomplete survey and lose the opportunity to earn $15.',
            },
            complete: {
              title: `Great job ${firstName}, you just earned $15!`,
              description:
                // eslint-disable-next-line
                'Thank you for taking the time to complete this survey. Your answers have been carefully recorded. Click "Finish" to view your personal health plan, and be sure to check your inbox for redemption instructions.',
            },
          },
        }}
      />
    </div>
  </Modal>
);

FteModal.propTypes = {
  classes: PropTypes.object.isRequired,
  updatedFte: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fteAnswers: PropTypes.object.isRequired,
  sectionImages: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  completeFTE: PropTypes.func.isRequired,
  firstName: PropTypes.string,
};

export default compose(
  connect(
    (state) => ({
      firstName: _.get(state, 'userInfo.firstName'),
      fte: _.get(state, 'questionnaires.FTE', {}),
      fteAnswers: _.get(
        state,
        'questionnaires.FTE.memberQuestionnaire.answers',
        {}
      ),
      memberQuestionnaireId: _.get(
        state,
        'questionnaires.FTE.memberQuestionnaire.id'
      ),
    }),
    (dispatch) => {
      const {
        postQuestionnaireAnswers,
        completeQuestionnaire,
      } = QuestionnaireActions;
      return {
        actions: bindActionCreators(
          {
            postQuestionnaireAnswers,
            completeQuestionnaire,
          },
          dispatch
        ),
      };
    }
  ),
  withHandlers({
    completeFTE: ({ onClose, actions, memberQuestionnaireId }) => () => {
      const { completeQuestionnaire } = actions;
      completeQuestionnaire(memberQuestionnaireId, 'FTE').then(onClose);
    },
  }),
  withProps(({ fte, fteAnswers }) => {
    let updatedFte = _.cloneDeep(fte);
    const fteQuestions = _.get(updatedFte, 'questionnaire.questions');
    const conditionConcernAnswers = _.filter(
      _.get(
        _.find(fteQuestions, {
          id: 'FTE-MEDICARE--MOST-CONCERNED-CONDITIONS',
        }),
        'answerOptions',
        []
      ),
      (answerOption) => {
        const answerOptionText = _.get(answerOption, 'option.en');
        const conditionAnswers = _.filter(
          _.get(
            _.find(fteQuestions, {
              id: 'FTE-MEDICARE--CONDITIONS',
            }),
            'answerOptions'
          ),
          (conditionAnswerOption) =>
            _.find(
              _.get(fteAnswers, 'FTE-MEDICARE--CONDITIONS'),
              (answer) =>
                _.get(answer, 'answer') === _.get(conditionAnswerOption, 'id')
            )
        );
        return (
          answerOptionText === 'None of these' ||
          _.find(
            conditionAnswers,
            (answer) => _.get(answer, 'option.en') === answerOptionText
          )
        );
      }
    );
    const conditionConcernIndex = _.findIndex(fteQuestions, {
      id: 'FTE-MEDICARE--MOST-CONCERNED-CONDITIONS',
    });
    if (conditionConcernIndex !== -1)
      _.set(
        updatedFte,
        `questionnaire.questions[${conditionConcernIndex}].answerOptions`,
        conditionConcernAnswers
      );
    return {
      updatedFte,
      sectionImages: {
        welcome: 'new-fte/backgrounds/background-1.png',
        welcomeForeground: 'new-fte/avatar.png',
        doneIncomplete: 'new-fte/backgrounds/background-fail.png',
        doneComplete: 'new-fte/backgrounds/background-success.png',
        1: 'new-fte/backgrounds/background-2.png',
        2: 'new-fte/backgrounds/background-3.png',
        3: 'new-fte/backgrounds/background-4.png',
        4: 'new-fte/backgrounds/background-5.png',
        fallback: 'new-fte/backgrounds/background-2.png',
      },
    };
  }),
  withStyles(styles)
)(FteModal);
