import React from 'react';
import Radium from 'radium';
import Spinner from 'react-spinkit';

const Loading = () => {
  const styles = {
    spinner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '280px',
    },
  };

  return (
    <div style={styles.spinner}>
      <Spinner name={'cube-grid'} className={'loading'} fadeIn="none" />
    </div>
  );
};

export default Radium(Loading);
