import { hashHistory } from 'react-router';
import { compose, pure, withProps, withStateHandlers } from 'recompose';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import OptInPageMarkup from '../../components/registration/OptInMarkup';

export default compose(
  pure,

  withProps(() => ({
    content: AppConfig.getLingo('registrationOptInContentHtml'),
    onContinue: () => hashHistory.push('registration/create-profile'),
  })),

  withStateHandlers(
    { agreedToStatement: false },
    {
      toggleStatementAgreement: ({ agreedToStatement }) => () => ({
        agreedToStatement: !agreedToStatement,
      }),
    }
  )
)(OptInPageMarkup);
