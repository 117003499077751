import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import Grid from '@material-ui/core/Grid';
import Typography from '../../common/Typography';
import { ListItem, ListItemStyles } from '../../common/list/List';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  ...ListItemStyles,
  prescriptionTitle: {
    marginBottom: '16px',
  },
  supply: {
    textAlign: 'center',
  },
});

const PrescriptionItem = ({
  classes,
  name,
  startDate,
  drugStrength,
  daysSupply,
}) => {
  return (
    <ListItem>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={10}>
          <div className={classes.prescriptionTitle}>
            <Typography type="h3Medium" inline>
              {name}
            </Typography>
            {drugStrength && (
              <Typography type="h3" inline>
                , {drugStrength}
              </Typography>
            )}
          </div>
          <div>
            <Typography type="h4" color="blueGray">
              last filled on {startDate}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={2} className={classes.supply}>
          <Typography type="h1" color="blueGray">
            {daysSupply}
          </Typography>
          <Typography type="h4Medium" color="blueGray">
            days supply
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

PrescriptionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  drugStrength: PropTypes.string,
  daysSupply: PropTypes.number,
};

export default compose(pure, withStyles(styles))(PrescriptionItem);
