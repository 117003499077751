import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, pure, withProps } from 'recompose';
import { withStyles } from '@material-ui/core';
import { withAboutYourselfForm } from '@healthmine/greyhound-core/src/hocs';
import DrawerContent from '../DrawerContent';
import Typography from '../../common/Typography';
import { DrawerSectionHeader } from '../../common/drawer2';

const styles = (theme) => ({
  inputRow: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.common.formulaOne}`,
    position: 'relative',
  },
  inputContainer: {
    display: 'inline-block',
    width: '100px',
    margin: '30px',
  },
  input: {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.common.formulaOne}`,
    textAlign: 'center',
    width: '75px',
    '&::placeholder': {
      fontWeight: 'bold',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    color: theme.palette.common.blueGray,
  },
  errorContainer: {
    position: 'relative',
    top: '-15px',
    left: 0,
    right: 0,
    whiteSpace: 'nowrap',
  },
  error: {
    color: 'red',
  },
});

const OneOffInput = (inputProps) => {
  const { classes, label, value, valid, placeholder, onChange } = inputProps;

  return (
    <div className={classes.inputContainer}>
      <input
        type="number"
        className={classes.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <Typography
        type="h3"
        inline
        className={classnames(classes.label, { [classes.error]: !valid })}
      >
        {label}
      </Typography>
    </div>
  );
};

const AboutYourselfDrawer = ({
  classes,
  feetField,
  inchesField,
  weightField,
  submit,
  skip,
  disableSubmit,
  ...props
}) => (
  <DrawerContent
    {...props}
    submitLabel="Continue"
    onSkip={skip}
    onSubmit={submit}
    disableSubmit={disableSubmit}
  >
    <DrawerSectionHeader title="How Tall Are You?" />
    <div className={classes.inputRow}>
      <OneOffInput
        classes={classes}
        {...feetField}
        label="ft"
        placeholder="--"
        onChange={(e) => feetField.update(e.target.value, inchesField.value)}
      />
      <OneOffInput
        classes={classes}
        {...inchesField}
        label="in"
        placeholder="--"
        onChange={(e) => inchesField.update(feetField.value, e.target.value)}
      />
      {!(feetField.valid && inchesField.valid) && (
        <div className={classnames(classes.errorContainer, classes.error)}>
          <Typography type="h4">Must be between 1' and 8'</Typography>
        </div>
      )}
    </div>
    <DrawerSectionHeader title="What Is Your Current Weight?" />
    <div className={classes.inputRow}>
      <OneOffInput
        classes={classes}
        {...weightField}
        label="lbs"
        placeholder="---"
        onChange={(e) => weightField.update(e.target.value)}
      />
      {!weightField.valid && (
        <div className={classnames(classes.errorContainer, classes.error)}>
          <Typography type="h4">Must be between 40 and 700 lbs.</Typography>
        </div>
      )}
    </div>
  </DrawerContent>
);

AboutYourselfDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  feetField: PropTypes.object.isRequired,
  inchesField: PropTypes.object.isRequired,
  weightField: PropTypes.object.isRequired,
  skip: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  withAboutYourselfForm(({ onNext }) => onNext),
  withProps(({ title, description }) => ({
    color: 'notBubbleGum',
    hero: { title, description, background: 'about_yourself' },
  }))
)(AboutYourselfDrawer);
