import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeaderMenu from './HeaderMenu';
import InformationMenuMarkup from './InformationMenuMarkup';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { CoverageSelectors } from '@healthmine/greyhound-core/src/selectors';
import { QuestionMark } from '../../styles/uhc-icons';

class InformationMenu extends React.Component {
  render() {
    const { groupNumber, informationLinks } = this.props;

    return (
      <HeaderMenu
        menuId="InformationMenu"
        menuType="secondary"
        primaryRoute="/faq"
        iconClassName="thin-0551_information_about"
        uhcIcon={QuestionMark}
        activeRule={/\/(faq|privacy|terms|view-document)/}
      >
        <InformationMenuMarkup
          groupNumber={groupNumber}
          informationLinks={informationLinks}
        />
      </HeaderMenu>
    );
  }
}

InformationMenu.propTypes = {
  groupNumber: PropTypes.string,
  informationLinks: PropTypes.array,
};

function mapStateToProps(state) {
  const { informationLinks } = AppConfig.effectiveConfig;

  const currentCoverage = CoverageSelectors.selectCurrentCoverage(state);

  const groupNumber = _.get(
    currentCoverage,
    'coverage.medicalPlan.clientSpecificPlanId',
    ''
  ).replace('-', '');

  return {
    groupNumber,
    informationLinks,
  };
}

export default connect(mapStateToProps)(InformationMenu);
