import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { rewardsSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import { selectOpenProducts } from '@healthmine/greyhound-core/src/selectors/SweepstakesSelector';
import { selectAvailablePoints } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import {
  AppConstants,
  RewardsConstants,
} from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import ShopMarkup from './ShopMarkup';
import ProductModal from './ProductModal';
import RewardProductModal from './RewardProductModal';
import _ from 'lodash';

const PRODUCTS_PER_ROW = 3;

const NUM_OF_ROWS = 4;

class Shop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productId: '',
      rewards: props.rewards,
      sortLowToHigh: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.rewards !== nextProps.rewards) {
      this.setState({ rewards: nextProps.rewards });
    }
  }

  render() {
    const { incentiveType } = AppConfig.effectiveConfig;

    const { points } = this.props;

    const { rewards } = this.state;

    const pageParam = parseInt(this.props.params.page);

    const productsPerPage = PRODUCTS_PER_ROW * NUM_OF_ROWS;

    const cardContents =
      incentiveType === AppConstants.IncentiveType.REWARDS
        ? rewards
        : this._getFilteredSortedProducts();

    const pages = Math.ceil(cardContents.length / productsPerPage);

    const currPage = pageParam && pages > 1 ? pageParam : 1;

    const displayProducts = this._getProducts(
      cardContents,
      currPage - 1,
      productsPerPage
    );

    const modal = this.state.productId ? (
      incentiveType === AppConstants.IncentiveType.REWARDS ? (
        <RewardProductModal
          productId={this.state.productId}
          toggleProduct={this._toggleProduct}
        />
      ) : (
        <ProductModal
          productId={this.state.productId}
          toggleProduct={this._toggleProduct}
        />
      )
    ) : null;
    return (
      <div>
        <ShopMarkup
          products={displayProducts}
          sortLowToHigh={this.state.sortLowToHigh}
          currPage={currPage}
          pages={pages}
          toggleSort={this._toggleSort}
          toggleProduct={this._toggleProduct}
          incentiveType={incentiveType}
          filterBy={this.state.filterBy}
          availablePoints={points}
          filterProduct={this._filterProduct}
        />

        {modal}
      </div>
    );
  }

  _getFilteredSortedProducts = () => {
    const { products } = this.props;

    const sortedProducts = _.sortBy(products, (product) => {
      return product.entryCost;
    });

    if (!this.state.sortLowToHigh) {
      return _.reverse(sortedProducts);
    }
    return sortedProducts;
  };

  _getProducts = (products, currPage, productsPerPage) => {
    let rowCount = 0;

    let productsCopy = [...products];

    let productsArr = [];

    while (
      productsCopy.length > productsPerPage * currPage &&
      rowCount < NUM_OF_ROWS
    ) {
      productsArr.push(
        productsCopy.splice(currPage * productsPerPage, PRODUCTS_PER_ROW)
      );
      rowCount++;
    }
    return productsArr;
  };

  _toggleSort = () => {
    this.setState({ sortLowToHigh: !this.state.sortLowToHigh });
  };

  _filterProduct = (newFilter) => {
    const { rewards } = this.props;

    const filteredRewards =
      newFilter === RewardsConstants.RewardDeliveryForm.DIGITAL
        ? rewards.filter((reward) => reward.isDigital)
        : newFilter === RewardsConstants.RewardDeliveryForm.PHYSICAL
          ? rewards.filter((reward) => reward.isPhysical)
          : rewards;

    this.setState({
      rewards: filteredRewards,
      filterBy: newFilter,
    });
  };

  _toggleProduct = (productId) => {
    this.setState({
      productId: productId || '',
    });
  };
}

Shop.propTypes = {
  params: PropTypes.object,
  points: PropTypes.number,
  rewards: PropTypes.array,
  products: PropTypes.array,
};

function mapStateToProps(state) {
  const products = selectOpenProducts(state);

  const rewards = rewardsSelector(state);

  return {
    products,
    rewards,
    points: selectAvailablePoints(state),
  };
}

export default connect(mapStateToProps)(Shop);
