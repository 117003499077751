import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import { Subjects } from '@healthmine/greyhound-core/src/constants/CarePlan';
import CarePlanTrackingMarkup from './CarePlanTrackingMarkup';
import PreventiveTracking from './PreventiveTracking';
import moment from 'moment';
import { CoverageSelectors } from '@healthmine/greyhound-core/src/selectors';
import { selectGoalForTracking } from '@healthmine/greyhound-core/src/selectors/CarePlanSelector';

class CarePlanTracking extends React.Component {
  render() {
    if (!this.props.carePlan) {
      return null;
    }

    const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

    const styles = {
      root: {
        width: fiveFiveUI ? '900px' : 'auto',
        backgroundColor: fiveFiveUI ? '#fff' : '',
      },
      dark: {
        fontWeight: 600,
      },
      para: {
        fontSize: '16px',
        fontWeight: '300',
      },
    };

    const { carePlan } = this.props;

    const goalStatement = this._getPlanGoalStatement(carePlan, styles);

    const primaryTrackingType = _.get(carePlan, 'primaryTracking.type');

    const currentTracking = _.get(carePlan, 'currentTracking');

    const secondaryTracking = _.filter(
      currentTracking,
      (tracking) =>
        tracking.type !== primaryTrackingType &&
        AppConstants.TrackingModuleTypes.includes(tracking.type)
    );

    return (
      <div style={styles.root}>
        {carePlan.subject === Subjects.PREVENTIVE ? (
          <PreventiveTracking
            carePlan={carePlan}
            goalStatement={goalStatement}
          />
        ) : (
          <CarePlanTrackingMarkup
            carePlan={carePlan}
            primaryTracking={carePlan.primaryTracking}
            secondaryTracking={secondaryTracking}
            goalStatement={goalStatement}
          />
        )}
      </div>
    );
  }

  _getPlanGoalStatement = (carePlan, styles) => {
    if (carePlan.status === AppConstants.PlanStatus.COMPLETED) {
      return {
        markup: (
          <p style={styles.para}>
            Keep up with your fitness goals and continue tracking your activity
            each week.
          </p>
        ),
      };
    } else {
      const goalDate = moment(carePlan.endDate).format('MM/DD/YYYY');

      const { lingo } = AppConfig.effectiveConfig;

      let objective = '';

      let targetValue = '';

      switch (carePlan.subject) {
        case Subjects.PHYSICAL_ACTIVITY:
          objective = 'increase my physical activity';
          break;

        case Subjects.CARDIOVASCULAR_DISEASE:
        case Subjects.CORONARY_ARTERY_DISEASE:
          return {
            markup: (
              <p style={styles.para}>
                My goal is to have a blood pressure lower than{' '}
                <span style={styles.dark}>140/90 mmHG</span> and cholesterol
                level of <span style={styles.dark}>130 mg/dL</span> by{' '}
                <span style={styles.dark}>{goalDate}.</span>
              </p>
            ),
          };

        case Subjects.DIABETES:
          objective = 'have an A1C lower than';
          targetValue = '6.5%';
          break;

        case Subjects.ENERGY:
          objective = 'have more energy';
          break;

        case Subjects.FINANCIAL_STRESS:
          objective = 'improve my finances';
          break;

        case Subjects.FOOD_CHOICES:
          objective = 'improve my eating habits';
          break;

        case Subjects.HAPPINESS:
          objective = 'improve my mood';
          break;

        case Subjects.HYPERLIPIDEMIA:
          objective = 'have a cholesterol level of';
          targetValue = '130 mg/dL';
          break;

        case Subjects.HYPERTENSION:
        case Subjects.METABOLIC_RISKS:
          objective = 'have a blood pressure lower than';
          targetValue = '140/90 mmHG';
          break;

        case Subjects.LEARN_ABOUT_HEALTH:
          objective = 'learn about my health risks';
          break;

        case Subjects.ASTHMA:
        case Subjects.COPD:
        case Subjects.IMPROVE_MOOD:
        case Subjects.HEART_FAILURE:
        case Subjects.BMI:
        case Subjects.PREGNANCY:
          objective = 'complete my plan';
          break;

        case Subjects.PREVENTIVE:
          return {
            markup: (
              <p style={styles.para}>
                Make an effort to complete all your {lingo.healthAction}s by{' '}
                <span style={styles.dark}>
                  {this.props.compliancePeriodEndDate}
                </span>.
              </p>
            ),
          };

        case Subjects.RELAX:
          objective = 'feel more relaxed';
          break;

        case Subjects.SLEEP:
          objective = 'improve my sleeping habits';
          break;

        case Subjects.SMOKING:
          objective = 'quit smoking';
          break;

        case Subjects.LOSE_WEIGHT:
          objective = 'weigh';
          targetValue = `${carePlan.goalEnd} lbs.`;
          break;
      }

      return {
        markup: (
          <p style={styles.para}>
            My goal is to {objective}
            {targetValue ? (
              <span style={styles.dark}> {targetValue}</span>
            ) : null}{' '}
            by <span style={styles.dark}>{goalDate}</span>.
          </p>
        ),
      };
    }
  };
}

CarePlanTracking.propTypes = {
  params: PropTypes.shape({
    subject: PropTypes.string.isRequired,
  }),

  carePlan: PropTypes.object,
  compliancePeriodEndDate: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const { subject } = ownProps.params;

  const myGoal = selectGoalForTracking(state, subject);

  const compliancePeriodEndDate = moment(
    _.get(CoverageSelectors.selectCurrentCompliancePeriod(state), 'endDate')
  ).format('MM/DD/YYYY');

  return {
    carePlan: myGoal,
    compliancePeriodEndDate,
  };
}

export default connect(mapStateToProps)(CarePlanTracking);
