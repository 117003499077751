const initialState = {
  charles: {
    value: 'Dr. Edmund Platt',
    units: '- Geriatrics',
    field: 'Primary Care Physician:',
  },
  anthony: {
    value: 'Dr. Jack Rosenthal',
    units: '- Pediatrician',
    field: 'Primary Care Physician:',
  },
};

export default (state = initialState) => {
  return state;
};
