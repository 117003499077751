import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { Link } from 'react-router';
import {
  Colors,
  GlobalStyles,
  TrackingData,
} from '@healthmine/greyhound-core/src/modules';
import moment from 'moment';
import Callout from '../tutorial/callout';

const BubbleTitleMarkup = ({
  iconClass,
  iconColor,
  bubbleColor,
  bubbleFilled,
  titleSuperscript,
  title,
  badge,
  measure,
  callToAction,
  activateTracking,
  onBubbleClick,
  onTitleClick,
  styleOverrides,
  showTutorial,
  calloutDetail,
}) => {
  const styles = {
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      color: Colors.bodyText,
      ...styleOverrides.root,
    },

    icon: {
      position: 'relative',
      display: 'flex',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '50%',
      width: '76px',
      height: '76px',
      minWidth: '76px',
      minHeight: '76px',
      padding: '0px',
      fontSize: '40px',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: onBubbleClick ? 'pointer' : 'default',
    },

    badge: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '20px',
      width: '20px',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',

      backgroundColor:
        badge === 'starred'
          ? Colors.white
          : badge === 'verified' || badge == 'COMPLETED'
            ? Colors.linkBlue
            : Colors.yellow,
    },

    badgeIcon: {
      backgroundColor: 'transparent',
      color: badge === 'starred' ? Colors.yellow : Colors.white,
      fontSize: badge === 'starred' ? '22px' : '18px',
    },

    content: {
      marginTop: '4px',
      marginLeft: '18px',
      flexBasis: '100%',
      ...styleOverrides.content,
    },

    title: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '100px',
      fontSize: '16px',
      cursor: onTitleClick ? 'pointer' : 'default',
      ...styleOverrides.title,
    },

    titleSuperscript: {
      fontSize: '16px',
      fontWeight: 'normal',
      color: Colors.contentText,
    },

    measureContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginTop: '3px',
      color: '#C5D2DD',
      ...styleOverrides.measureContainer,
    },

    measureValue: {
      fontSize: '28px',
      ...styleOverrides.measureValue,
    },

    measureLabel: {
      fontSize: '16px',
      marginLeft: '5px',
      paddingBottom: '3px',
    },

    measureDate: {
      minWidth: '100px',
    },

    callToAction: {
      marginTop: '3px',
      fontSize: '16px',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      ...styleOverrides.callToAction,
    },

    callToActionLink: {
      color: Colors.linkBlue,
      textDecoration: 'none',
    },
    dateLabel: {
      color: Colors.contentText,
      fontSize: '16px',
      fontWeight: '300',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
    },
    connectedIcon: {
      marginRight: '10px',
      fontSize: '18px',
      color: Colors.contentText,
    },
    showOnTutorial: {
      zIndex: showTutorial ? 900005 : null,
    },
  };

  const showCta =
    !measure ||
    !measure.value ||
    measure.value === '0/0' ||
    measure.missing ||
    moment(measure.date).isBefore(moment(), 'day');

  let measureUI = null;

  let ctaUI = null;

  if (measure) {
    measureUI = (
      <div>
        <div style={[styles.measureContainer, GlobalStyles.light]}>
          <div style={styles.measureValue}>{measure.value}</div>
          <div style={styles.measureLabel}>{measure.label}</div>
        </div>
        <div style={styles.dateLabel}>
          {measure.date && (
            <div>{TrackingData.getMeasurementDate(measure.date)}</div>
          )}
        </div>
      </div>
    );
  }

  if (callToAction) {
    ctaUI = (
      <div style={{ ...styles.callToAction, color: callToAction.color }}>
        {callToAction.link ? (
          <Link to={callToAction.link} style={styles.callToActionLink}>
            {callToAction.text}
          </Link>
        ) : activateTracking ? (
          <div>
            <span onClick={activateTracking}>{callToAction.text}</span>
          </div>
        ) : (
          callToAction.text
        )}
      </div>
    );
  }

  return (
    <StyleRoot style={[GlobalStyles.regular, styles.root]}>
      {showTutorial && (
        <Callout
          path={'M175 40 C 175 40, 100 40, 85 18'}
          position={{ top: '70px', left: '0px' }}
          title={calloutDetail.title}
          text={calloutDetail.text}
          direction={{ flexDirection: 'row' }}
          arrowEnd={'markerEnd'}
        />
      )}
      <div
        style={[
          {
            ...styles.icon,
            borderColor: bubbleColor,
            backgroundColor: bubbleFilled ? bubbleColor : 'transparent',
          },
          styles.showOnTutorial,
        ]}
        onClick={() => onBubbleClick && onBubbleClick()}
      >
        <i className={iconClass} style={{ color: iconColor }} />
        {badge && (
          <div style={styles.badge}>
            {badge === 'alert' ? (
              <span style={styles.badgeIcon}>!</span>
            ) : (
              <i className="material-icons" style={styles.badgeIcon}>
                {badge === 'starred' ? 'stars' : 'done'}
              </i>
            )}
          </div>
        )}
      </div>

      <div style={styles.content}>
        <div
          style={styles.title}
          onClick={() => onTitleClick && onTitleClick()}
        >
          {titleSuperscript && (
            <span style={styles.titleSuperscript}>{titleSuperscript}</span>
          )}
          {title}
        </div>
        {measureUI}
        {showCta && ctaUI}
      </div>
    </StyleRoot>
  );
};

BubbleTitleMarkup.propTypes = {
  iconClass: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  bubbleColor: PropTypes.string.isRequired,
  bubbleFilled: PropTypes.bool,
  titleSuperscript: PropTypes.string,
  title: PropTypes.string.isRequired,
  badge: PropTypes.oneOf([
    'verified',
    'alert',
    'COMPLETED',
    'STARTED',
    'NOT_STARTED',
    'starred',
  ]),

  measure: PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    missing: PropTypes.bool,
    connected: PropTypes.bool,
  }),

  callToAction: PropTypes.shape({
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    link: PropTypes.string,
  }),

  activateTracking: PropTypes.func,
  onBubbleClick: PropTypes.func,
  onTitleClick: PropTypes.func,

  styleOverrides: PropTypes.shape({
    root: PropTypes.object,
    title: PropTypes.object,
    measureContainer: PropTypes.object,
    callToAction: PropTypes.object,
  }),
  showTutorial: PropTypes.bool,
  calloutDetail: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

BubbleTitleMarkup.defaultProps = {
  bubbleFilled: false,
  styleOverrides: {},
};

export default Radium(BubbleTitleMarkup);
