import React from 'react';
import ViewDocument from '../../../common/ViewDocument';
import { DrawerContent } from '../../../common/drawer2';

const Support = () => {
  return (
    <DrawerContent>
      <ViewDocument documentFile="contact_us_en.html" drawerFrame />
    </DrawerContent>
  );
};

export default Support;
