import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Styles from './CardItem.styles';

const CardItemMarkup = ({
  selectCarePlan,
  iconClass,
  bubbleColor,
  title,
  subTitle,
  nextAction,
  actionCount,
  completedCount,
}) => (
  <a onClick={selectCarePlan} style={Styles.root}>
    <div style={Styles.header}>
      <div style={[Styles.bubble, { backgroundColor: bubbleColor }]}>
        <i className={iconClass} style={Styles.icon} />
      </div>
      <div style={Styles.titleWrapper}>
        <div style={Styles.title}>{title}</div>
        {actionCount > 0 && (
          <div style={Styles.completion}>
            <span style={Styles.completeLabel}>
              {completedCount}/{actionCount} Complete
            </span>
          </div>
        )}
      </div>
    </div>
    <div style={Styles.body}>
      <div style={Styles.subTitle}>{subTitle}</div>
      <div>
        <div style={Styles.nextLabel}>Next:</div>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: nextAction }} />
        {/* eslint-enable react/no-danger */}
      </div>
    </div>
  </a>
);

CardItemMarkup.propTypes = {
  selectCarePlan: PropTypes.func,
  iconClass: PropTypes.string,
  bubbleColor: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  nextAction: PropTypes.string,
  actionCount: PropTypes.number,
  completedCount: PropTypes.number,
};

export default Radium(CardItemMarkup);
