import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import _ from 'lodash';

const HeaderMarkup = ({ children, isImpersonating, tutorial }) => {
  const showTutorial = !!(_.get(tutorial, 'page', null) == 'healthOverview');

  const styles = {
    root: {
      position: showTutorial ? 'absolute' : 'fixed',
      top: `${isImpersonating ? 30 : 0}px`,
      left: '0px',
      right: '0px',
      height: '100px',
      backgroundColor: Colors.light,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      zIndex: showTutorial ? null : 1001,
      paddingLeft: '20px',
      paddingRight: '20px',
      '@media screen and (max-width: 1200px)': {
        position: showTutorial ? 'absolute' : 'initial',
      },
    },

    content: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '1340px',
      width: '100%',
      height: '100px',
      alignItems: 'center',
      margin: '0px auto',
    },

    shadowBox: {
      position: 'fixed',
      top: `${isImpersonating ? 30 : 0}px`,
      left: '0px',
      right: '0px',
      height: '100px',
      boxShadow: `0px 0px 20px ${Colors.lightenColor(Colors.lightGray, 1)}`,
      zIndex: -1,
    },

    emptyHeader: {
      height: `${isImpersonating ? 130 : 100}px`,
      '@media screen and (max-width: 1200px)': {
        height: isImpersonating ? '30px' : 'initial',
      },
    },
  };

  return (
    <StyleRoot>
      {/*
        The #emptyHeader element is used by the ./containers/SmartScroll container to detect
        the height of the header area at runtime, which accounts for the impersonation
        bar that can be displayed.
      */}
      <div id="emptyHeader" style={styles.emptyHeader} />

      <div style={styles.root}>
        <div style={styles.content}>{children}</div>
      </div>

      <div style={styles.shadowBox} />
    </StyleRoot>
  );
};

HeaderMarkup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,

  isImpersonating: PropTypes.bool.isRequired,
  tutorial: PropTypes.object,
};

export default Radium(HeaderMarkup);
