import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

const GridWrapper = ({ children, justifyFlexEnd }) => {
  return (
    <Grid
      container
      spacing={16}
      style={{ justifyContent: justifyFlexEnd ? 'flex-end' : 'flex-start' }}
    >
      {_.map(children, (child, idx) => {
        return (
          child && (
            <Grid item key={`grid_item${idx}`}>
              {child}
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

GridWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  justifyFlexEnd: PropTypes.bool,
};

export default GridWrapper;
