import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import EducationList from './list/EducationList';
import CarePlanCard from './card/CarePlanCard';
import EducationListDrawer from './drawer/EducationListDrawer';
import EducationDrawerContent from '../../health-library/EducationDrawerContent';
import { withDrawer } from '../../../common/drawer2';
import { EducationActions } from '@healthmine/greyhound-core/src/actions';
import withRecommendedEducation from '@healthmine/greyhound-core/src/hocs/WithRecommendedEducation';

const Education = ({
  isListView,
  remainingRecommendedEducation,
  title,
  iconClass,
  color,
  remainingRecommendedEducationCount,
  openEducationListDrawer,
  openEducationItemDrawer,
  completedRecommendedEducationPercent,
  whatsNextActionTitle,
  recommendedEducationMaxDisplayCount: maxDisplayCount,
}) => (
  <div>
    {isListView ? (
      <EducationList
        remainingRecommendedEducation={_.take(remainingRecommendedEducation, 5)}
        title={title}
        iconClass={iconClass}
        color={color}
        remainingRecommendedEducationCount={remainingRecommendedEducationCount}
        maxDisplayCount={maxDisplayCount}
        openDrawer={openEducationItemDrawer}
      />
    ) : (
      <CarePlanCard
        openDrawer={openEducationListDrawer}
        actionCompletionPercent={completedRecommendedEducationPercent}
        color={color}
        iconClass={iconClass}
        remainingActionCount={remainingRecommendedEducationCount}
        maxDisplayCount={maxDisplayCount}
        title={title}
        whatsNextActionTitle={whatsNextActionTitle}
      />
    )}
  </div>
);

Education.propTypes = {
  isListView: PropTypes.bool.isRequired,
  iconClass: PropTypes.string.isRequired,
  remainingRecommendedEducation: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  remainingRecommendedEducationCount: PropTypes.number.isRequired,
  completedRecommendedEducationPercent: PropTypes.number.isRequired,
  openEducationListDrawer: PropTypes.func.isRequired,
  openEducationItemDrawer: PropTypes.func.isRequired,
  whatsNextActionTitle: PropTypes.string.isRequired,
  recommendedEducationMaxDisplayCount: PropTypes.number.isRequired,
};

export default compose(
  pure,
  withRouter,
  connect(null, (dispatch) => {
    return {
      trackEducation: (contentId) =>
        dispatch(EducationActions.trackEducation(contentId)),
    };
  }),
  withTheme(),
  withDrawer,
  withRecommendedEducation,
  withProps(
    ({ remainingRecommendedEducation, drawer, theme, trackEducation }) => {
      const title = 'Learn About My Health Risks';
      const color = theme.palette.common.peopleEater;
      const openEducationItemDrawer = (contentId) => {
        const education = _.find(remainingRecommendedEducation, [
          'contentId',
          contentId,
        ]);

        trackEducation(contentId);

        drawer.open(EducationDrawerContent, { education }, { large: true });
      };
      const whatsNextActionTitle = _.get(
        _.head(remainingRecommendedEducation),
        'title',
        ''
      );
      return {
        title,
        color,
        iconClass: 'icon-Book-Bookmark',
        openEducationListDrawer: () =>
          drawer.open(EducationListDrawer, {
            title,
            color,
            selectHealthAction: openEducationItemDrawer,
          }),
        openEducationItemDrawer,
        whatsNextActionTitle,
      };
    }
  )
)(Education);
