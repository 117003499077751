import CompletionPage from './CompletionPage';
import HealthRiskAssessment from './HealthRiskAssessment';
import HealthRiskAssessmentResult from './HealthRiskAssessmentResult';
import HealthRiskAssessmentSummary from './HealthRiskAssessmentSummary';

export {
  CompletionPage,
  HealthRiskAssessment,
  HealthRiskAssessmentResult,
  HealthRiskAssessmentSummary,
};
