import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { UserInfoActions } from '@healthmine/greyhound-core/src/actions';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import {
  AppConfig,
  promiseCatch,
} from '@healthmine/greyhound-core/src/modules';
import WhatsNext from '../components/health-overview/WhatsNext';
import Header from '../components/header/Header';
import HealthOverviewPage from '../components/health-overview/HealthOverviewPage';
import { SessionActions } from '../actions';
import Radium from 'radium';
import RightPane from '../components/common/RightPane';
import ComplianceStatus from '../components/health-overview/compliance-status/ComplianceStatus';
import CoverageViewer from '../components/health-overview/CoverageViewer';
let timer = null;

class Authentication extends React.Component {
  componentWillMount() {
    this.props.actions.getUserInfoApi();

    if (
      AppConfig.effectiveConfig.incentiveType !==
      AppConstants.IncentiveType.NONE
    ) {
      this.props.actions.getUserIncentives();
    }
  }

  componentDidMount() {
    if (
      this.props.isAuthenticated &&
      AppConfig.effectiveConfig.incentiveType !==
        AppConstants.IncentiveType.NONE
    ) {
      timer = setInterval(() => {
        this.props.actions
          .getUserIncentives()
          .catch(promiseCatch(() => this.props.actions.endSession()));
      }, 30000);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.checkAuth(nextProps);
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  render() {
    const animationTime = '.3s';

    const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

    const unifiedCarePlan = AppConfig.validateFeature(
      Features.UNIFIED_CARE_PLAN
    );

    const noCoverageSelection = AppConfig.validateFeature(
      Features.NO_COVERAGE_SELECTION
    );

    const newContentPageUI =
      fiveFiveUI && this.props.location.pathname !== '/health-overview';

    const newStyles = {
      root: {
        width: '100%',
      },

      content: {
        maxWidth: '1200px',
        display: 'flex',
        margin: 'auto',
        opacity: 1,
        transition: `opacity ${animationTime} ease-in`,
      },
      body: [
        {
          flex: '0 0 800px',
          opacity: 1,
          marginTop: '20px',
        },
        newContentPageUI && {
          background: '#fff',
          borderRadius: '4px',
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
        },
      ],
      collapsed: {
        maxWidth: '1200px',
        display: 'flex',
        margin: 'auto',
        boxShadow: '0 0 20px 0px rgba(186, 190, 186, 0.3)',
        position: 'fixed',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '100px',
        opacity: 0,
        transition: `opacity ${animationTime} ease-in`,
        zIndex: -1,
      },
      noSidebar: {
        opacity: 0,
        maxWidth: '900px',
        animation: `noSidebarFade ${animationTime} ease-in ${animationTime} forwards`,
        backgroundColor: '#fff',
      },
    };

    const oldStyles = {
      root: {
        width: '100%',
      },

      content: {
        maxWidth: '1200px',
        display: 'flex',
        margin: 'auto',
        boxShadow: '0 0 20px 0px rgba(186, 190, 186, 0.3)',
        opacity: 1,
        transition: `opacity ${animationTime} ease-in`,
      },
      noSidebar: {
        opacity: 0,
        maxWidth: '900px',
        animation: `noSidebarFade ${animationTime} ease-in ${animationTime} forwards`,
      },
      body: {
        maxWidth: '900px',
        display: 'flex',
        flex: 1,
        opacity: 1,
      },
      collapsed: {
        maxWidth: '1200px',
        display: 'flex',
        margin: 'auto',
        boxShadow: '0 0 20px 0px rgba(186, 190, 186, 0.3)',
        position: 'fixed',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '100px',
        opacity: 0,
        transition: `opacity ${animationTime} ease-in`,
        zIndex: -1,
      },
    };

    const styles = fiveFiveUI ? newStyles : oldStyles;

    return (
      <div style={styles.root}>
        <Header minimal={this.props.minimalHeader} />
        <div style={this.props.main ? styles.collapsed : styles.content}>
          <div style={styles.body}>
            <HealthOverviewPage collapsed={!!this.props.children} />
            {this.props.children}
          </div>
          <RightPane sticky={!this.props.main}>
            {!noCoverageSelection && <CoverageViewer />}
            {unifiedCarePlan && <ComplianceStatus />}
            <WhatsNext />
          </RightPane>
        </div>
        {this.props.main && (
          <div
            style={[
              styles.content,
              styles.noSidebar,
              this.props.expanded ? { maxWidth: '1200px' } : {},
            ]}
          >
            {this.props.main}
          </div>
        )}
      </div>
    );
  }

  checkAuth(isAuthenticated) {
    const { inboundSSOEnabled } = AppConfig.effectiveConfig;

    if (!isAuthenticated) {
      if (inboundSSOEnabled) {
        hashHistory.replace('/sso-error');
      } else {
        hashHistory.replace('/');
      }
    }
  }
}

Authentication.propTypes = {
  actions: PropTypes.shape({
    endSession: PropTypes.func.isRequired,
    getUserInfoApi: PropTypes.func.isRequired,
    getUserIncentives: PropTypes.func.isRequired,
  }).isRequired,

  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),

  isAuthenticated: PropTypes.bool.isRequired,
  minimalHeader: PropTypes.bool,
  main: PropTypes.element,
  expanded: PropTypes.bool,
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.signIn.accessToken && !state.signIn.appLoadFailed,
  };
}

function mapDispatchToProps(dispatch) {
  const { endSession } = SessionActions;

  const { getUserInfoApi, getUserIncentives } = UserInfoActions;

  return {
    actions: bindActionCreators(
      {
        endSession,
        getUserInfoApi,
        getUserIncentives,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(Authentication)
);
