import blueButton from '../modules/BlueButton/reducer';
import drawer from './DrawerReducer';
import popupMenu from './PopupMenuReducer';
import session from './SessionReducer';
import thirdPartyCookieSupport from './ThirdPartyCookieSupportReducer';
import tutorialReducer from './TutorialReducer';
import demo from '../modules/demo';

export default {
  ...blueButton,
  ...drawer,
  ...popupMenu,
  ...session,
  ...thirdPartyCookieSupport,
  ...tutorialReducer,
  demo,
};
