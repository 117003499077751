import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import moment from 'moment';
import Dropdown from './Dropdown';
import { getStringForLocale } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const DateMarkup = ({
  minDate,
  maxDate,
  setDate,
  day,
  month,
  year,
  fullMonth,
  styles,
  isValid,
  validationError,
  disabled,
  hraLanguageCode,
  useDefaultSelect,
}) => {
  const _styles = {
    container: {
      width: 'inhert',
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '6px',
      flexDirection: 'column',
      ...styles.container,
    },
    day: {
      width: '95px',
      ...styles.day,
    },
    month: {
      width: '90px',
      ...styles.month,
    },
    year: {
      width: '132px',
      ...styles.year,
    },
    marginRight: {
      marginRight: '10px',
      ...styles.marginRight,
    },
    errorIndicator: {
      color: disabled ? Colors.lightGray : Colors.red,
      padding: '20px 0',
      margin: 0,
      ...styles.errorIndicator,
    },
  };

  const minYear = moment(minDate).year();

  const maxYear = moment(maxDate).year();

  const monthArray = _.keys(Array(12)).map((val) => {
    val = parseInt(val, 10);
    return {
      value: val + 1,

      label: fullMonth
        ? getStringForLocale(`months.${val}`, hraLanguageCode)
        : val + 1,
    };
  });

  const monthDayCount =
    month === 2
      ? year % 4 === 0
        ? 29
        : 28
      : [4, 6, 9, 11].includes(month)
        ? 30
        : 31;

  const dayArray = _.keys(Array(monthDayCount)).map((val) => {
    val = parseInt(val, 10);
    return { value: val + 1, label: val + 1 };
  });

  const yearArray = _.keys(Array(maxYear - minYear + 1)).map((val) => {
    return { value: maxYear - val, label: maxYear - val };
  });

  const updateMonth = (event) =>
    setDate({ month: useDefaultSelect ? event.target.value : event.value });

  const updateDay = (event) =>
    setDate({ day: useDefaultSelect ? event.target.value : event.value });

  const updateYear = (event) =>
    setDate({ year: useDefaultSelect ? event.target.value : event.value });

  return (
    <div style={_styles.container}>
      <div style={{ display: 'flex' }}>
        <div style={_styles.marginRight}>
          <Dropdown
            placeholder={
              getStringForLocale(
                'additionalTexts.monthLabel',
                hraLanguageCode
              ) || 'Month'
            }
            value={month}
            onAnswered={updateMonth}
            options={monthArray}
            styles={_styles.day}
            disabled={disabled}
            useDefaultSelect={useDefaultSelect}
          />
        </div>
        <div style={_styles.marginRight}>
          <Dropdown
            placeholder={
              getStringForLocale('additionalTexts.dayLabel', hraLanguageCode) ||
              'Day'
            }
            value={day}
            onAnswered={updateDay}
            options={dayArray}
            styles={_styles.month}
            disabled={disabled}
            useDefaultSelect={useDefaultSelect}
          />
        </div>
        <div>
          <Dropdown
            placeholder={
              getStringForLocale(
                'additionalTexts.yearLabel',
                hraLanguageCode
              ) || 'Year'
            }
            value={year}
            onAnswered={updateYear}
            options={yearArray}
            styles={_styles.year}
            disabled={disabled}
            useDefaultSelect={useDefaultSelect}
          />
        </div>
      </div>
      {isValid === false ? (
        <p style={_styles.errorIndicator}>{validationError}</p>
      ) : null}
    </div>
  );
};

DateMarkup.propTypes = {
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullMonth: PropTypes.bool,
  isValid: PropTypes.bool,
  validationError: PropTypes.string,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  hraLanguageCode: PropTypes.string,
  useDefaultSelect: PropTypes.bool,
};

DateMarkup.defaultProps = {
  styles: {},
};

export default Radium(DateMarkup);
