import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import moment from 'moment';
import PageLinks from '../../common/PageLinks';

const SweepstakesHistoryMarkup = ({
  sweepstakesHistory,
  currPage,
  pages,
  children,
}) => {
  const RadiumLink = Radium(Link);

  const styles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 75px 0 75px',
    },

    header: {
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.black,
      paddingBottom: '20px',
    },

    table: {
      textAlign: 'left',
      fontSize: '16px',
      width: '100%',
      borderCollapse: 'collapse',
    },

    th: {
      padding: '10px 0',
      color: Colors.mediumGray2,
      backgroundColor: '#f3f6f8',
      fontSize: '16px',
      textAlign: 'center',
    },

    td: {
      padding: '20px 0',
      color: Colors.contentText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      textAlign: 'center',
    },

    firstColumn: {
      textAlign: 'left',
      padding: '0 0 0 10px',
    },

    noContent: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '20px',
      fontWeight: 300,
      padding: '40px 75px 0 75px',
    },

    pageLinksWrapper: {
      padding: '30px 0',
    },

    detailsLink: {
      color: Colors.linkBlue,
      textDecoration: 'none',
      textAlign: 'center',
      ':hover': {
        color: Colors.hexToRgba(Colors.dark, 0.5),
      },
      ':active': {
        color: Colors.dark,
      },
    },
  };

  return sweepstakesHistory && sweepstakesHistory.length ? (
    <div style={styles.content}>
      <div style={styles.header}>My Previous Sweepstakes</div>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={[GlobalStyles.semiBold, styles.th, styles.firstColumn]}>
              Reward
            </th>
            <th style={[GlobalStyles.semiBold, styles.th]}>Close Date</th>
            <th style={[GlobalStyles.semiBold, styles.th]}>Total Entries</th>
            <th style={[GlobalStyles.semiBold, styles.th]}>My Entries</th>
            <th style={[GlobalStyles.semiBold, styles.th]}>Points Spent</th>
          </tr>
        </thead>
        <tbody>
          {sweepstakesHistory.map((item, index) => {
            return (
              <tr key={index}>
                <td style={[styles.td, styles.firstColumn]}>
                  <RadiumLink
                    to={`sweepstakes/history/${currPage}/details/${
                      item.sweepstakeId
                    }`}
                    style={styles.detailsLink}
                  >
                    {item.sweepstakeName}
                  </RadiumLink>
                </td>
                <td style={styles.td}>
                  {moment(item.closeDate).format('MM/DD/YYYY')}
                </td>
                <td style={styles.td}>{item.totalEntries}</td>
                <td style={styles.td}>{item.memberEntries}</td>
                <td style={styles.td}>{item.entryCost * item.memberEntries}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {children}
      <div style={styles.pageLinksWrapper}>
        <PageLinks
          linkBase={'/sweepstakes/history/'}
          currPage={currPage}
          pages={pages}
        />
      </div>
    </div>
  ) : (
    <div style={styles.noContent}>
      You have not entered any previous sweepstakes.
      <div style={{ marginTop: '25px' }}>
        <Link to="/sweepstakes/shop"> Enter one now! </Link>
      </div>
    </div>
  );
};

SweepstakesHistoryMarkup.propTypes = {
  sweepstakesHistory: PropTypes.array.isRequired,
  currPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  children: PropTypes.element,
};

export default Radium(SweepstakesHistoryMarkup);
