import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { MyDataActions } from '@healthmine/greyhound-core/src/actions';
import Button from './Button';

class ConnectDevice extends React.Component {
  render() {
    const { style, link } = this.props;

    const styles = {
      itemCaptionLink: {
        color: Colors.black,
        textDecoration: 'none',
        cursor: 'pointer',
        ...style,
      },
      itemCaptionButton: {
        width: '230px',
        marginTop: '20px',
        fontWeight: 600,
        ...style,
      },
    };

    return (
      <div>
        {link ? (
          <div style={styles.itemCaptionLink} onClick={this._openDevicePopUp}>
            {this.props.itemText}
          </div>
        ) : (
          <Button
            style={styles.itemCaptionButton}
            text={this.props.itemText}
            onClick={this._openDevicePopUp}
          />
        )}
      </div>
    );
  }

  @autobind
  _openDevicePopUp() {
    const { onFinished, onClosed } = this.props;
    let { deviceConnected } = this.props.actions;

    let {
      memberId,
      humanApiClientId,
      publicToken,
    } = this.props.deviceCredentials;

    let options = {
      clientUserId: memberId,
      clientId: humanApiClientId, // grab it from app settings page
      publicToken, // Leave blank for new users
      modal: 1,

      finish(err, sessionTokenObject) {
        const payload = {
          humanId: sessionTokenObject.humanId,
          sessionToken: sessionTokenObject.sessionToken,
        };
        deviceConnected(payload);
        if (onFinished) onFinished();
      },
      close() {
        if (onClosed) onClosed();
      },
      error() {
        /* Optional callback called if an error occurs when loading
          the popup. */
        // `err` has fields: `code`, `message`, `detailedMessage`
      },
    };

    window.HumanConnect.open(options);
  }
}

ConnectDevice.propTypes = {
  actions: PropTypes.object.isRequired,
  deviceCredentials: PropTypes.object.isRequired,
  itemText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  link: PropTypes.bool,
  onFinished: PropTypes.func,
  onClosed: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    deviceCredentials: state.deviceCredentials,
  };
}

function mapDispatchToProps(dispatch) {
  const { deviceConnected } = MyDataActions;
  return {
    actions: bindActionCreators({ deviceConnected }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectDevice);
