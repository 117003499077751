import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules/';

const PageLinks = ({ linkBase, linkExt, currPage, pages, pageSize }) => {
  if (pages === 1 || pages === 0) return null;
  if (currPage > pages) return null;

  let counter = 0;
  const RadiumLink = Radium(Link);

  const mainContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const icon = {
    height: '25px',
    width: '25px',
    color: Colors.dark,
    cursor: 'pointer',
    padding: '5px',
    marginLeft: '10px',
    ':hover': {
      color: Colors.linkBlue,
    },
    ':active': {
      color: Colors.lightenColor(Colors.linkBlue, 2),
    },
    textDecoration: 'none',
  };

  const disableIcon = {
    color: Colors.hexToRgba(Colors.dark, 0.3),
    cursor: 'default',
    ':hover': {
      color: Colors.hexToRgba(Colors.dark, 0.3),
    },
    ':active': {
      color: Colors.hexToRgba(Colors.dark, 0.3),
    },
  };

  const pageLink = {
    color: Colors.dark,
    cursor: 'pointer',
    textDecoration: 'none',
    padding: '5px',
    margin: '0px 10px',
    ':hover': {
      color: Colors.linkBlue,
    },
    ':active': {
      color: Colors.lightenColor(Colors.linkBlue, 2),
    },
  };

  const activePageLink = {
    fontSize: '16px',
    cursor: 'default',
    color: Colors.black,
    ':hover': {
      color: Colors.black,
    },
  };

  const leftIcon = {
    marginRight: '10px',
  };

  const rightIcon = {
    marginLeft: '10px',
  };

  const pagesList = _.keys(Array(pages)).map((val) => parseInt(val, 10) + 1);

  const sections = _.keys(Array(Math.ceil(pages / pageSize))).map(() =>
    pagesList.slice(counter, (counter += pageSize))
  );

  const currentsection = sections.filter((section) => {
    for (let i = 0; i < section.length; i++) {
      if (section[i] == currPage) return true;
    }
  });

  const piconLeft = (
    <i className="thin-0159_arrow_back_left" style={{ fontSize: '25px' }} />
  );

  const piconRight = (
    <i className="thin-0160_arrow_next_right" style={{ fontSize: '25px' }} />
  );

  return (
    <div style={mainContainer}>
      {currPage > 1 ? (
        <RadiumLink
          key="page-links-left-arrow"
          to={`${linkBase}${currPage - 1}${linkExt}`}
          style={[icon, leftIcon]}
        >
          {piconLeft}
        </RadiumLink>
      ) : (
        <div style={[icon, leftIcon, disableIcon]}>{piconLeft}</div>
      )}
      {sections[0] != currentsection[0] ? (
        <div>
          <svg height="50" width="40">
            <circle cx="10" cy="25" r="2" stroke="black" fill="black" />
            <circle cx="20" cy="25" r="2" stroke="black" fill="black" />
            <circle cx="30" cy="25" r="2" stroke="black" fill="black" />
          </svg>
        </div>
      ) : null}
      {currentsection[0] &&
        currentsection[0].map((num) => (
          <div key={`${linkBase}${num}-${linkExt}`}>
            {num === currPage ? (
              <div
                key={`page-dis-link-${num}`}
                style={[pageLink, activePageLink, GlobalStyles.bold]}
              >
                {num}
              </div>
            ) : (
              <RadiumLink
                key={`page-link-${num}`}
                to={`${linkBase}${num}${linkExt}`}
                style={[pageLink, GlobalStyles.thin]}
              >
                {num}
              </RadiumLink>
            )}
          </div>
        ))}
      {sections[sections.length - 1] != currentsection[0] ? (
        <div>
          <svg height="50" width="40">
            <circle cx="10" cy="25" r="2" stroke="black" fill="black" />
            <circle cx="20" cy="25" r="2" stroke="black" fill="black" />
            <circle cx="30" cy="25" r="2" stroke="black" fill="black" />
          </svg>
        </div>
      ) : null}
      {currPage < pages ? (
        <RadiumLink
          key="page-links-right-arrow"
          to={`${linkBase}${currPage + 1}${linkExt}`}
          style={[icon, rightIcon]}
        >
          {piconRight}
        </RadiumLink>
      ) : (
        <div style={[icon, rightIcon, disableIcon]}>{piconRight}</div>
      )}
    </div>
  );
};

PageLinks.propTypes = {
  linkBase: PropTypes.string.isRequired,
  linkExt: PropTypes.string,
  currPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
};

PageLinks.defaultProps = {
  linkExt: '',
  pageSize: 5,
};

export default Radium(PageLinks);
