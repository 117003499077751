import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { withForm } from '../../../../common/form';
import { DrawerInput, withDrawer } from '../../../../common/drawer2';
import { split, get } from 'lodash';
import { validatePresence } from '../../../../../validations';

const TrackerManualEntry = ({ form }) => (
  <DrawerInput
    {...form.fields.manualValue}
    onChange={(e) => form.updateField('manualValue', e.target.value)}
    onSubmit={form.submit}
    submitText="Confirm"
  />
);

TrackerManualEntry.propTypes = {
  tracker: PropTypes.object.isRequired,
  form: PropTypes.shape({
    isValid: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    updateField: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
  }).isRequired,
};

const validateBloodPressure = (value) =>
  split(value, '/').length !== 2
    ? 'Valid format required: systolic/diastolic, ex: 112/78.'
    : undefined;

const validateMetric = (tracker) => (value) =>
  !tracker.validate(split(value, '/')) ? tracker.errorMessage : undefined;

export default compose(
  pure,
  connect((state) => state),
  withDrawer,
  withForm(
    ({ tracker }) => {
      const isBloodPressure = tracker.type === 'BLOOD_PRESSURE';

      return {
        manualValue: {
          label: isBloodPressure
            ? 'Enter systolic/diastolic for today'
            : `Enter todays ${tracker.unit}`,
          type: 'text',
          value: '',
          validate: tracker.validate,
          validations: [
            validatePresence,
            isBloodPressure ? validateBloodPressure : () => {},
            validateMetric(tracker),
          ],
        },
      };
    },
    ({ form, tracker, drawer, dispatch }) => {
      const value = get(form, 'fields.manualValue.value');
      dispatch(tracker.saveAction(split(value, '/'))).then(drawer.close);
    }
  )
)(TrackerManualEntry);
