import React from 'react';
import InformationalAction from '../actions/InformationalAction';

const Complete = () => (
  <InformationalAction>
    Congrats!<br />You’re all done for the day!
  </InformationalAction>
);

export default Complete;
