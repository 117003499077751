import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure, withProps } from 'recompose';
import { flow, getOr, map } from 'lodash/fp';
import moment from 'moment';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import { DrawerNavigation } from '../../../../common/drawer2';
import Chips from '../../../../common/Chips';
import { selectIsMaxedOut } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';

const getConditions = flow([getOr([], 'evidence.conditions'), map('name')]);

const HealthAction = ({
  completed,
  conditions,
  onClick,
  subtitle,
  suppressChips,
  title,
}) => (
  <DrawerNavigation
    onClick={onClick}
    title={title}
    status={completed ? 'positive' : 'neutral'}
    incentivesToEarn={!completed}
    subtitle={subtitle}
  >
    {!suppressChips && <Chips color="venus" labels={conditions} />}
  </DrawerNavigation>
);

HealthAction.propTypes = {
  completed: PropTypes.bool.isRequired,
  conditions: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  subtitle: PropTypes.string.isRequired,
  suppressChips: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export const enhance = compose(
  pure,
  connect((state) => ({
    maxedOut: selectIsMaxedOut(state),
  })),
  withProps(({ healthAction, selectHealthAction, maxedOut }) => {
    const {
      id,
      title,
      completed,
      incentiveAmount,
      lastCompletedOn,
      incentiveEarningTextOverride,
    } = healthAction;

    const subtitle = completed
      ? `completed on ${moment(lastCompletedOn).format('MM/DD/YYYY')}`
      : !maxedOut
        ? incentiveEarningTextOverride
          ? incentiveEarningTextOverride
          : IncentiveSummary.getIncentiveEarningText(false, incentiveAmount)
        : '';

    const onClick = selectHealthAction ? () => selectHealthAction(id) : null;

    return {
      completed,
      onClick,
      subtitle,
      title,
      conditions: getConditions(healthAction),
    };
  })
);

export default enhance(HealthAction);
