import _ from 'lodash';
import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import Button from '../../components/common/Button';

const IntroMarkup = () => {
  const RadiumLink = Radium(Link);

  const usesLearnMore = AppConfig.validateFeature(Features.LEARN_MORE);

  const styles = {
    button: {
      padding: '10px 25px',
      width: '250px',
      marginBottom: !usesLearnMore && '30px',
    },

    footer: {
      color: Colors.black,
      fontSize: '16px',
    },

    mainFooter: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },

    learnMore: {
      textAlign: 'center',
      marginTop: '10px',
      marginBottom: '30px',
    },

    link: {
      alignItems: 'center',
      color: '#2095f3',
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: '500',
      display: 'flex',
      fontSize: '16px',

      ':hover': {
        color: Colors.darkenColor('#2095f3'),
      },

      ':active': {
        color: Colors.darkenColor('#2095f3', 2),
      },
    },

    signInContainer: {
      marginLeft: '15px',
    },
  };

  const signInFooterHtml = _.get(
    AppConfig,
    'effectiveConfig.lingo.signInFooterHtml'
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={styles.mainFooter}>
        <Button
          style={[GlobalStyles.regular, styles.button]}
          text="Let's Get Registered"
          link="/registration/basic-information"
        />
      </div>
      {usesLearnMore && (
        <div style={styles.learnMore}>
          <RadiumLink
            style={[GlobalStyles.regular, styles.link, { display: 'inline' }]}
            to="/learn-more/"
          >
            Learn More
          </RadiumLink>
        </div>
      )}
      <div style={styles.mainFooter}>
        <span style={[GlobalStyles.regular, styles.footer]}>
          Already Registered?
        </span>
        <span>
          <RadiumLink
            style={[GlobalStyles.regular, styles.link, styles.signInContainer]}
            to="/signin/"
          >
            Sign In Now
          </RadiumLink>
        </span>
      </div>

      {/* eslint-disable react/no-danger */
      signInFooterHtml ? (
        <div
          dangerouslySetInnerHTML={{
            __html: AppConfig.replaceS3(signInFooterHtml),
          }}
        />
      ) : null
      /* eslint-enable react/no-danger */
      }
    </div>
  );
};
export default Radium(IntroMarkup);
