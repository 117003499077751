import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { MessageCenterActions } from '@healthmine/greyhound-core/src/actions';
import { MessageCenterConstants } from '@healthmine/greyhound-core/src/constants';
import MessagesMarkup from './MessagesMarkup';

class Messages extends React.Component {
  static MESSAGES_PER_PAGE = 6;

  constructor() {
    super(...arguments);

    this.state = {
      selectedMessageFilter: 'all',
      viewedMessage: null,
    };
  }

  componentWillMount() {
    this.props.actions.getMessages();
  }

  render() {
    const { selectedMessageFilter, viewedMessage } = this.state;

    const currentPageNumber = parseInt(_.get(this, 'props.params.page')) || 1;

    const filteredMessagePages = this._getFilteredMessagePages();

    const pageCount = filteredMessagePages.length;

    const displayedMessages = _.get(
      filteredMessagePages,
      currentPageNumber - 1,
      []
    );

    return (
      <MessagesMarkup
        selectedMessageFilter={selectedMessageFilter}
        displayedMessages={displayedMessages}
        currentPageNumber={currentPageNumber}
        pageCount={pageCount}
        viewedMessage={viewedMessage}
        onMessageFilterChanged={this._onMessageFilterChanged}
        onViewMessage={this._onViewMessage}
        onViewedMessageClosed={this._onViewedMessageClosed}
      />
    );
  }

  _onViewMessage = (message) => {
    this.setState({
      viewedMessage: message,
    });
  };

  _onViewedMessageClosed = () => {
    this.setState({
      viewedMessage: null,
    });
  };

  _onMessageFilterChanged = (event) => {
    this.setState({
      selectedMessageFilter: event.target.value,
    });

    hashHistory.push('/messages');
  };

  _getFilteredMessagePages = () => {
    const { messages } = this.props;

    const { selectedMessageFilter } = this.state;

    return _(messages)
      .filter(
        (message) =>
          (selectedMessageFilter === 'all' &&
            message.status !== MessageCenterConstants.MessageStatus.DELETE) ||
          message.status === selectedMessageFilter
      )
      .chunk(Messages.MESSAGES_PER_PAGE)
      .value();
  };
}

Messages.propTypes = {
  params: PropTypes.shape({
    page: PropTypes.string,
  }),

  actions: PropTypes.shape({
    getMessages: PropTypes.func.isRequired,
  }).isRequired,

  messages: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  const { messages } = state.messageCenter;

  return {
    messages,
  };
}

function mapDispatchToProps(dispatch) {
  const { getMessages } = MessageCenterActions;

  return {
    actions: bindActionCreators(
      {
        getMessages,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
