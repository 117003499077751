import _ from 'lodash';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  defaultProps,
  renderComponent,
  renderNothing,
  withHandlers,
  withProps,
} from 'recompose';
import Measurements from '@healthmine/greyhound-core/src/modules/Measurements';
import ModuleTrackingMarkup from './ModuleTrackingMarkup';

export default compose(
  connect((state, ownProps) => {
    const trackingType = _.get(ownProps, 'tracking.type');

    const measurement = _.get(Measurements, ['MeasurementTypes', trackingType]);

    const todaysValue = _.invoke(measurement, 'todaysValueSelector', state);

    return {
      todaysValue,
    };
  }),

  defaultProps({
    autoFocus: false,
    setActiveHistory: _.noop,
  }),

  withProps(({ activeHistory, tracking }) => ({
    history: activeHistory === _.get(tracking, 'type'),
  })),

  withHandlers({
    toggleHistory: ({ setActiveHistory }) => () => setActiveHistory(),
  }),

  branch(
    ({ tracking }) => !!tracking,
    renderComponent(ModuleTrackingMarkup),
    renderNothing
  )
)(ModuleTrackingMarkup);
