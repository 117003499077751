import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../Typography';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  enabled: {
    cursor: 'pointer',
    transition: theme.globalStyles.transition1,
  },
  primary: {
    background: theme.palette.common.beefy,
    '&:hover': {
      background: theme.utils.colorLighten(theme.palette.common.beefy),
    },
  },
  neutral: {
    color: theme.palette.common.blueGray,
    background: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.common.formulaOne,
    },
  },
  dismiss: {
    background: theme.palette.common.cosmopolitan,
    '&:hover': {
      background: theme.utils.colorLighten(theme.palette.common.cosmopolitan),
    },
  },
  disabled: {
    background: theme.palette.common.twentyCC,
    '&:hover': {
      background: theme.palette.common.twentyCC,
    },
  },
});

const DrawerButton = ({ classes, text, type, disabled, onClick }) => {
  const className = classNames(
    classes.root,
    classes[type],
    disabled ? classes.disabled : classes.enabled
  );
  const clickHandler = disabled ? undefined : onClick;

  return (
    <div onClick={clickHandler} className={className}>
      <Typography type="h3">{text}</Typography>
    </div>
  );
};

DrawerButton.defaultProps = {
  type: 'primary',
};

DrawerButton.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'neutral', 'dismiss']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(DrawerButton);
