import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { connect } from 'react-redux';
import find from 'lodash/find';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
  withDrawer,
} from '../../../common/drawer2';
import HealthActionListDrawer from '../care-plan/drawer/HealthActionListDrawer';
import Typography from '../../../common/Typography';
import {
  WithPreventiveActions,
  WithRecommendedEducation,
} from '@healthmine/greyhound-core/src/hocs';
import { EducationActions } from '@healthmine/greyhound-core/src/actions';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import HealthActionDrawerView from '../care-plan/drawer/HealthActionDrawerView';
import EducationListDrawer from '../care-plan/drawer/EducationListDrawer';
import EducationDrawerContent from '../../health-library/EducationDrawerContent';

const styles = (theme) => ({
  complete: {
    color: theme.palette.common.grannySmith,
  },
  notComplete: {
    color: theme.palette.common.cosmopoltian,
  },
});

const PointActionDrawer = ({
  title,
  completed,
  assignedEducation,
  handlePreventiveActionClick,
  handleLearnHealthRisksClick,
  classes,
}) => {
  return (
    <DrawerContent title={title}>
      <DrawerSectionHeader title="Status" />
      <DrawerSection>
        <div style={{ textAlign: 'center' }}>
          <Typography
            type="h1"
            className={classnames(
              completed ? classes.complete : classes.notComplete
            )}
          >
            {completed ? 'completed' : 'not complete'}
          </Typography>
        </div>
      </DrawerSection>
      <DrawerSectionHeader title="Description" />
      <DrawerSection>
        <Typography>
          <p>
            Points are earned by completing various health actions. Check out
            your portal homepage under My Care Plan and click on various cards
            such as{' '}
            <Typography
              type="link"
              el="span"
              onClick={handlePreventiveActionClick}
            >
              Preventive Actions
            </Typography>
            {assignedEducation && (
              <>
                {' or '}
                <Typography
                  type="link"
                  el="span"
                  onClick={handleLearnHealthRisksClick}
                >
                  Learn About My Health Risks
                </Typography>
              </>
            )}{' '}
            for more information. There are also number of cards associated with
            tracking lifestyle behaviors that provide additional opportunities
            to help you monitor your health and earn points! Click back to your
            homepage to get started!
          </p>
          <p>
            The number of points earned is located in the top right of your
            homepage by the yellow star so you can easily track your progress!
          </p>
        </Typography>
      </DrawerSection>
    </DrawerContent>
  );
};

PointActionDrawer.propTypes = {
  title: PropTypes.string,
  completed: PropTypes.bool.isRequired,
  assignedEducation: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  pointAction: PropTypes.object,
  handlePreventiveActionClick: PropTypes.func.isRequired,
  handleLearnHealthRisksClick: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withDrawer,
  WithPreventiveActions,
  WithRecommendedEducation,
  withTheme(),
  connect(null, (dispatch) => {
    return {
      trackEducation: (contentId) =>
        dispatch(EducationActions.trackEducation(contentId)),
    };
  }),
  withProps(
    ({
      pointAction,
      drawer,
      completedPreventiveActionCount,
      preventiveActionCount,
      preventiveActions,
      remainingPreventiveActionCount,
      hraStatus,
      theme,
      remainingRecommendedEducation,
      trackEducation,
    }) => {
      const { title, completed } = pointAction;

      const assignedEducation = !AppConfig.validateFeature(
        Features.NO_ASSIGNED_EDUCATION
      );

      const handlePreventiveActionClick = () => {
        const title = 'Preventive Actions';
        const actionCompletionPercent =
          completedPreventiveActionCount / preventiveActionCount * 100;

        const completedActions = preventiveActions.filter(
          (action) => action.completed
        );

        const earnedIncentiveTotal = completedActions.reduce(
          (sum, action) => sum + action.incentiveAmount,
          0
        );

        const incentiveTotal = preventiveActions.reduce(
          (sum, action) => sum + action.incentiveAmount,
          0
        );

        const remainingIncentiveCount = incentiveTotal - earnedIncentiveTotal;

        const incentiveCompletionPercent =
          earnedIncentiveTotal / incentiveTotal * 100;

        const openHealthActionDrawer = (id) => {
          const healthAction = preventiveActions.find(
            (action) => action.id === id
          );
          drawer.open(HealthActionDrawerView, { healthAction, hraStatus });
        };

        drawer.open(HealthActionListDrawer, {
          title,
          actionCompletionPercent,
          color: theme.palette.common.notBubbleGum,
          healthActions: preventiveActions,
          incentiveCompletionPercent,
          remainingActionCount: remainingPreventiveActionCount,
          remainingIncentiveCount,
          selectHealthAction: openHealthActionDrawer,
          hraStatus,
        });
      };

      const handleLearnHealthRisksClick = () => {
        const title = 'Learn About My Health Risks';
        const color = theme.palette.common.peopleEater;
        const openEducationItemDrawer = (contentId) => {
          const education = find(remainingRecommendedEducation, [
            'contentId',
            contentId,
          ]);

          trackEducation(contentId);

          drawer.open(EducationDrawerContent, { education }, { large: true });
        };

        drawer.open(EducationListDrawer, {
          title,
          color,
          selectHealthAction: openEducationItemDrawer,
        });
      };

      return {
        title,
        completed,
        assignedEducation,
        handlePreventiveActionClick,
        handleLearnHealthRisksClick,
      };
    }
  ),
  withStyles(styles)
)(PointActionDrawer);
