import _ from 'lodash';
import { types as TokenActionTypes } from '@healthmine/greyhound-core/src/actions/TokenActions';
import { types } from '../actions/SessionActions';
import { types as CoverageTypes } from '@healthmine/greyhound-core/src/actions/CoverageActions';

const __INITIAL_SESSION_STATE__ = {
  session: {
    heartbeatAt: null,
    started: false,
    timerId: null,
  },
};

function session(state = __INITIAL_SESSION_STATE__.session, action) {
  switch (action.type) {
    case types.SESSION_END:
      sessionStorage.removeItem('signInResult');
      sessionStorage.removeItem('lastSessionActivityAt');

      return {
        ...state,
        heartbeatAt: null,
        started: false,
        timerId: null,
      };

    case types.SESSION_RECORD_HEARTBEAT: {
      const { started } = state;

      if (started) {
        const { heartbeatAt, timerId } = action;

        if (heartbeatAt) {
          sessionStorage.setItem('lastSessionActivityAt', heartbeatAt);
        }

        return {
          ...state,
          heartbeatAt,
          timerId,
        };
      } else {
        return state;
      }
    }

    case types.SESSION_START: {
      const { authData } = action;

      if (authData) {
        sessionStorage.setItem('signInResult', authData);
      }

      return {
        ...state,
        started: !!authData,
      };
    }

    case TokenActionTypes.UPDATE_ACCESS_TOKEN: {
      const accessToken = _.get(action, 'accessToken');

      if (accessToken) {
        try {
          const signInResultString = sessionStorage.getItem('signInResult');

          const signInResultJson = JSON.parse(signInResultString);

          if (signInResultJson) {
            signInResultJson.access_token = accessToken;
            sessionStorage.setItem(
              'signInResult',
              JSON.stringify(signInResultJson)
            );
          }
        } catch (e) {
          //  eslint-disable-next-line no-empty
        }
      }

      return state;
    }

    case CoverageTypes.SET_SELECTED_COVERAGE:
      localStorage.setItem('selectedMemberCoverageId', action.memberCoverageId);
      return state;

    default:
      return state;
  }
}

export default {
  session,
};
