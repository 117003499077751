import _ from 'lodash';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { BlueButtonActions } from '../../actions';
import { decodeCareEpisodeType } from './decodeCareEpisodeType';
import * as CareEpisodeTransformers from './care-episode-transformers';

const selectBlueButtonData = createSelector(
  (state) => _.get(state, 'blueButton.rawData'),

  (rawData) => {
    return _(_.get(rawData, 'entry'))
      .map('resource')
      .reduce(
        (accumulator, resource) => {
          const careEpisodeType = decodeCareEpisodeType(resource);

          const careEpisodeTransformer = _.get(
            CareEpisodeTransformers,
            careEpisodeType,
            _.identity
          );

          const transformedCareEpisode = careEpisodeTransformer(resource);

          const episodeCollection = _.get(
            accumulator,
            ['care', careEpisodeType],
            accumulator.care.other
          );

          episodeCollection.push(transformedCareEpisode);

          return accumulator;
        },
        {
          care: {
            doctor: [],
            medicalEquipment: [],
            homeHealth: [],
            hospice: [],
            inpatient: [],
            outpatient: [],
            pharmacy: [],
            skilledNursing: [],
            other: [],
          },
        }
      );
  }
);

export default compose(
  connect(
    (state) => ({
      isAuthorizedForBlueButton: !!_.get(state, 'blueButton.accessToken'),
      blueButtonPatientId: _.get(state, 'blueButton.patientId'),
      blueButtonData: selectBlueButtonData(state),
    }),

    _.curry(bindActionCreators)(BlueButtonActions)
  ),

  withProps(() => ({
    isBlueButtonEnabled: AppConfig.validateFeature(Features.BLUE_BUTTON),
  }))
);
