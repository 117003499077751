import { connect } from 'react-redux';
import React from 'react';
import { SignInActions } from '@healthmine/greyhound-core/src/actions';
import { bindActionCreators } from 'redux';
import MfaEnrollmentMarkup from '../../components/mfa/MfaTotpEnrollmentMarkup';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { SessionActions } from '../../actions';

class MFAEnrollmentPage extends React.Component {
  constructor(props) {
    super(props);
    this._completeEnrollment = this._completeEnrollment.bind(this);
    this._mfaCodeChange = this._mfaCodeChange.bind(this);
    this._onNextClick = this._onNextClick.bind(this);
    this._onBackClick = this._onBackClick.bind(this);
    this._onBackClick = this._onBackClick.bind(this);
    this.state = { mfaCode: '' };

    if (!this.props.accessToken) {
      this.props.router.replace('signin');
    }
  }

  render() {
    return (
      <MfaEnrollmentMarkup
        isLoading={this.props.isLoading}
        errorMessage={this.props.mfaErrorMessage}
        mfaEnrollmentHasBegun={this.props.mfaEnrollmentHasBegun}
        mfaEnrollmentIsComplete={this.props.mfaEnrollmentIsComplete}
        mfaCode={this.state.mfaCode}
        qrCodeUrl={this.props.mfaQrCodeUrl}
        onMfaCodeChange={this._mfaCodeChange}
        onBackClick={this._onBackClick}
        onNextClick={this._onNextClick}
        onVerifyMfaCode={this._completeEnrollment}
      />
    );
  }

  _mfaCodeChange(e) {
    this.setState({ mfaCode: e.target.value });
  }

  _onBackClick(e) {
    e.preventDefault();
    this.props.actions.resetMfaEnrollmentState();
  }

  _onNextClick(e) {
    e.preventDefault();
    this.props.actions.beginMfaEnrollment('TOTP');
  }

  _completeEnrollment(e) {
    e.preventDefault();
    const { completeMfaEnrollment, startSession } = this.props.actions;
    const memberCoverageId = localStorage.getItem('selectedMemberCoverageId');
    completeMfaEnrollment(this.state.mfaCode, memberCoverageId).then(
      (result) => {
        if (result) {
          startSession(JSON.stringify(result));
        }
      }
    );
  }
}

MFAEnrollmentPage.propTypes = {
  actions: PropTypes.shape({
    beginMfaEnrollment: PropTypes.func,
    completeMfaEnrollment: PropTypes.func,
    resetMfaEnrollmentState: PropTypes.func,
    startSession: PropTypes.func,
  }),
  accessToken: PropTypes.string,
  mfaFetch: PropTypes.bool,
  mfaEnrollmentHasBegun: PropTypes.bool,
  mfaEnrollmentIsComplete: PropTypes.bool,
  mfaErrorMessage: PropTypes.string,
  mfaQrCodeUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    accessToken,
    mfaFetch,
    mfaEnrollmentIsComplete,
    appLoading,
    mfaErrorMessage,
    mfaEnrollmentHasBegun,
    mfaQrCodeUrl,
  } = state.signIn;

  return {
    accessToken,
    mfaFetch,
    mfaEnrollmentIsComplete,
    mfaErrorMessage,
    mfaEnrollmentHasBegun,
    mfaQrCodeUrl,
    isLoading: mfaFetch || (mfaEnrollmentIsComplete && appLoading),
  };
}

function mapDispatchToProps(dispatch) {
  const { startSession } = SessionActions;
  const {
    beginMfaEnrollment,
    completeMfaEnrollment,
    resetMfaEnrollmentState,
  } = SignInActions;

  return {
    actions: bindActionCreators(
      {
        beginMfaEnrollment,
        completeMfaEnrollment,
        resetMfaEnrollmentState,
        startSession,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(MFAEnrollmentPage)
);
