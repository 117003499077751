import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

const styles = {
  frequency: {
    color: Colors.contentText,
    fontSize: '16px',
    margin: '10px 0 20px',
  },
};

const Frequency = ({ frequency, copy }) => (
  <div style={[GlobalStyles.extraLight, styles.frequency]}>
    {_.get(copy, 'frequency', '').replace('#frequency', frequency)}
  </div>
);

Frequency.propTypes = {
  frequency: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  copy: PropTypes.object,
};

export default Radium(Frequency);
