import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, pure, withHandlers, withProps, withState } from 'recompose';
import _ from 'lodash';
import _flow from 'lodash/fp/flow';
import _getOr from 'lodash/fp/getOr';
import _filter from 'lodash/fp/filter';
import _map from 'lodash/fp/map';
import moment from 'moment';
import { Grid, withTheme, withStyles } from '@material-ui/core';
import { HraActions } from '@healthmine/greyhound-core/src/actions';
import * as HraSelectors from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import {
  DrawerContent,
  DrawerSection,
  DrawerButton,
  withDrawer,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';
import Navigational from '../../../common/icons/Navigational';
import Clickable from '../../../common/Clickable';
import ProgressBar from '../../../common/widgets/ProgressBar';
import { getStringForLocale } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import BPQuestion from '../../../../../components/common/question/BPQuestion';
import PickerComponent from '../../../../../components/common/question/Picker';
import LinearScale from '../../../../../components/common/question/LinearScale';
import Freeform from '../../../../../components/common/question/Freeform';
import DateComponent from '../../../../../components/common/question/Date';
import Dropdown from '../../../../../components/common/question/Dropdown';
import {
  AppConstants,
  LanguageConstants,
} from '@healthmine/greyhound-core/src/constants';
import HraEpilogue from './HraEpilogue';
import classnames from 'classnames';
import withTrackers from '@healthmine/greyhound-core/src/hocs/withTrackers';
import MyDataActions from '@healthmine/greyhound-core/src/actions/MyDataActions';
import { getSummary } from '@healthmine/greyhound-core/src/selectors/MyDataSelector';
import CarePlanActions from '@healthmine/greyhound-core/src/actions/CarePlanActions';

const styles = () => ({
  questionProgressLabel: {
    textAlign: 'center',
  },
  questionSection: {
    padding: '20px 40px',
    boxSizing: 'initial',
  },
  noNext: {
    opacity: '0.5',
    cursor: 'not-allowed',
  },
});

const HraQuestions = ({
  classes,
  contentUI,
  onBack,
  onSubmitAnswers,
  currentQuestionIndex,
  surveyQuestionCount,
  hraLanguageCode,
  canSubmitAnswers,
}) => {
  const questionLabel =
    getStringForLocale('additionalTexts.questionLabel', hraLanguageCode) ||
    'Question';

  const questionOf =
    getStringForLocale('additionalTexts.questionOf', hraLanguageCode) || 'of';

  return (
    <DrawerContent title="Health Risk Assessment">
      <DrawerSection>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            {currentQuestionIndex > 0 ? (
              <Clickable onClick={onBack}>
                <Navigational iconClass="icon-Left-Chevron-Circle" />
              </Clickable>
            ) : (
              <div />
            )}
          </Grid>
          <Grid item>
            <Typography type="h5">
              {`${questionLabel} ${currentQuestionIndex + 1}`}
            </Typography>
          </Grid>
          <Grid item>
            {currentQuestionIndex === surveyQuestionCount - 1 ? (
              <div />
            ) : (
              <Clickable onClick={canSubmitAnswers ? onSubmitAnswers : _.noop}>
                <Navigational
                  className={classnames({
                    [classes.noNext]: !canSubmitAnswers,
                  })}
                  iconClass="icon-Right-Chevron-Circle"
                />
              </Clickable>
            )}
          </Grid>
        </Grid>
      </DrawerSection>
      <DrawerSection className={classes.questionSection} grow>
        {contentUI}
      </DrawerSection>
      <DrawerSection>
        <Typography
          className={classes.questionProgressLabel}
        >{`${questionLabel} ${currentQuestionIndex +
          1} ${questionOf} ${surveyQuestionCount}`}</Typography>
        <ProgressBar
          percentage={(currentQuestionIndex + 1) / surveyQuestionCount * 100}
        />
      </DrawerSection>
      {currentQuestionIndex === surveyQuestionCount - 1 && (
        <DrawerButton text="Submit" onClick={onSubmitAnswers} />
      )}
    </DrawerContent>
  );
};

HraQuestions.propTypes = {
  classes: PropTypes.object.isRequired,
  currentQuestions: PropTypes.array,
  currentQuestionChoices: PropTypes.arrayOf(PropTypes.object),
  currentQuestionsAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentQuestionIndex: PropTypes.number,
  surveyQuestionCount: PropTypes.number,
  hraLanguageCode: PropTypes.string,
  errorCount: PropTypes.number,
  onBack: PropTypes.func.isRequired,
  onQuestionAnswered: PropTypes.func.isRequired,
  onSubmitAnswers: PropTypes.func.isRequired,
  skipQuestion: PropTypes.func,
  contentUI: PropTypes.object,
  theme: PropTypes.object.isRequired,
  canSubmitAnswers: PropTypes.bool,
};

const legacyStyles = {
  loadingMessage: {
    fontSize: '30px',
  },

  picker: {
    largeBubble: {
      width: '76px',
      height: '76px',
      border: '1px solid #99a7b6',
    },
    textItem: {
      paddingRight: '40px',
    },
    mainPickerContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  linearScale: {
    container: {
      paddingRight: '45%',
      paddingTop: '100px',
    },

    scaleContainer: {
      borderLeftWidth: '1px solid',
      borderRightWidth: '1px solid',
    },

    bubbleContainer: {
      left: '-1px',
      right: '-1px',
      justifyContent: 'space-between',
    },

    scaleLine: {},

    label: {
      width: 'auto',
    },

    labelContainer: {
      position: 'absolute',
      top: '-15px',
      left: '40px',
      right: '35px',
    },
  },

  freeform: {
    container: {
      margin: '40px 0px',
      width: '360px',
    },
    input: {
      maxWidth: '360px',
    },
    validValueIndicator: {
      display: 'none',
    },
  },

  dropDownWidth: {
    width: '45%',
    marginTop: '40px',
  },
};

export default compose(
  pure,
  withDrawer,
  withTrackers,
  connect(
    (state) => {
      const { count: errorCount } = state.errors;

      const currentQuestionIndex = state.hra.activeQuestionIndex;

      const firstUnansweredQuestionIndex = HraSelectors.selectFirstUnansweredQuestionIndex(
        state
      );

      const questionnaire = HraSelectors.selectCurrentQuestionnaire(state);

      const filteredQuestionSteps = HraSelectors.selectFilteredQuestionSteps(
        state
      );

      const surveyQuestionCount = _.size(filteredQuestionSteps);

      const memberQuestionnaire = HraSelectors.selectMostRecentMemberQuestionnaire(
        state
      );

      const HRAStatus = HraSelectors.selectHraStatus(state);

      const hraLanguageCode = _.get(
        memberQuestionnaire,
        [
          'answers',
          AppConstants.HRAQuestionId.LANGUAGE,
          'answers',
          0,
          'answer',
        ],
        LanguageConstants.LanguageCodes.EN
      );

      const allQuestions = _(_.get(questionnaire, 'questions'))
        .map((question) => [question.id, question])
        .fromPairs()
        .value();

      const currentQuestions = _.map(
        _.get(filteredQuestionSteps, `[${currentQuestionIndex}].questions`),
        (questionId) => _.get(allQuestions, questionId)
      );

      const currentQuestionChoices = _flow(
        _getOr([], '[0].answerOptions'),
        _filter((opt) => opt.active)
      )(currentQuestions);

      const currentQuestionsAnswers = _.map(currentQuestions, (question) => ({
        id: question.id,
        answers: _.map(
          _.get(memberQuestionnaire, ['answers', question.id, 'answers']),
          'answer'
        ),
      }));

      const dataTrackerSummary = getSummary(state);
      return {
        hraLanguageCode,
        currentQuestions,
        currentQuestionChoices,
        currentQuestionsAnswers,
        currentQuestionIndex,
        surveyQuestionCount,
        firstUnansweredQuestionIndex,
        HRAStatus,
        errorCount,
        dataTrackerSummary,
        memberQuestionnaire,
      };
    },
    (dispatch) => {
      const {
        setHraLanguage,
        answer,
        save,
        next,
        previous,
        goTo,
        skip,
        complete,
        clearErrors,
      } = HraActions;

      return {
        hraActions: bindActionCreators(
          {
            setHraLanguage,
            answer,
            save,
            next,
            previous,
            goTo,
            skip,
            complete,
            clearErrors,
          },
          dispatch
        ),
        enableMyTracking: bindActionCreators(
          CarePlanActions.enableMyTracking,
          dispatch
        ),
        putWeightHistory: bindActionCreators(
          MyDataActions.putWeightHistory,
          dispatch
        ),
        putBloodPressureHistory: bindActionCreators(
          MyDataActions.putBloodPressureHistory,
          dispatch
        ),
      };
    }
  ),
  withState('isValid', 'setValid', undefined),
  withHandlers({
    onBack: ({ hraActions, currentQuestionIndex, setValid }) => () => {
      setValid(undefined);
      hraActions.clearErrors();
      if (currentQuestionIndex) {
        hraActions.previous();
      }
    },
    onQuestionAnswered: ({ hraActions, setValid }) => (
      questionId,
      answer,
      isValid
    ) => {
      setValid(isValid);
      hraActions.answer(questionId, [].concat(answer));
    },
    skipQuestion: ({ hraActions }) => (questionId) => {
      hraActions.skip(questionId);
    },
    leaveAssessment: ({ hraActions, currentQuestions }) => () => {
      hraActions.save([currentQuestions[0].id]);
    },
    onSubmitAnswers: ({
      currentQuestionIndex,
      surveyQuestionCount,
      currentQuestions,
      hraActions,
      drawer,
      setValid,
      activeTrackers,
      allowedTrackers,
      enableMyTracking,
      putWeightHistory,
      putBloodPressureHistory,
      dataTrackerSummary,
      memberQuestionnaire,
    }) => () => {
      switch (currentQuestions[0].id) {
        case 'HNA_BP': {
          const bpAnswer = _.get(
            memberQuestionnaire,
            'answers.HNA_BP.answers[0].answer',
            ''
          );
          const splitBp = bpAnswer.split('/');
          if (
            _.get(dataTrackerSummary, 'bloodPressure.value', null) !=
              bpAnswer &&
            splitBp.length == 2 &&
            allowedTrackers.includes('BLOOD_PRESSURE')
          ) {
            if (
              !_.find(
                activeTrackers,
                (tracker) => tracker.type === 'BLOOD_PRESSURE'
              )
            ) {
              enableMyTracking('BLOOD_PRESSURE').then(() =>
                putBloodPressureHistory({
                  value: { systolic: splitBp[0], diastolic: splitBp[1] },
                  source: 'manual',
                  date: moment()
                    .local()
                    .format('YYYY-MM-DD[T]HH:mm:ss'),
                })
              );
            } else {
              putBloodPressureHistory({
                value: { systolic: splitBp[0], diastolic: splitBp[1] },
                source: 'manual',
                date: moment()
                  .local()
                  .format('YYYY-MM-DD[T]HH:mm:ss'),
              });
            }
          }
          break;
        }
        case 'HNA-WEIGHT': {
          const weightAnswer = _.get(
            memberQuestionnaire,
            'answers.HNA-WEIGHT.answers[0].answer',
            ''
          );
          if (
            _.get(dataTrackerSummary, 'weight.value', null) != weightAnswer &&
            allowedTrackers.includes('WEIGHT')
          ) {
            if (
              !_.find(activeTrackers, (tracker) => tracker.type === 'WEIGHT')
            ) {
              enableMyTracking('WEIGHT').then(() =>
                putWeightHistory({
                  value: weightAnswer,
                  source: 'manual',
                  date: moment()
                    .local()
                    .format('YYYY-MM-DD[T]HH:mm:ss'),
                })
              );
            } else {
              putWeightHistory({
                value: weightAnswer,
                source: 'manual',
                date: moment()
                  .local()
                  .format('YYYY-MM-DD[T]HH:mm:ss'),
              });
            }
          }
          break;
        }
      }
      if (currentQuestionIndex === surveyQuestionCount - 1) {
        hraActions.save([currentQuestions[0].id]).then(() => {
          hraActions.complete();
          drawer.open(HraEpilogue, null, { resetStack: true });
        });
      } else {
        setValid(undefined);
        hraActions.next();
      }
    },
  }),
  withProps(
    ({
      currentQuestions,
      currentQuestionsAnswers,
      hraLanguageCode,
      onQuestionAnswered,
      currentQuestionIndex,
      surveyQuestionCount,
      skipQuestion,
      currentQuestionChoices,
      isValid,
    }) => {
      const questionNumber = currentQuestionIndex + 1;
      const question = currentQuestions[0];
      const activeAnswerOptions = _flow(
        _getOr([], 'answerOptions'),
        _filter((opt) => opt.active || opt.text),
        _map('id')
      )(question);

      const currentQuestionAnswerOptions = _.get(
        currentQuestionsAnswers,
        '[0].answers'
      );

      const questionAnswers = ['RADIO_BUTTONS', 'CHECKBOXES'].includes(
        question.type
      )
        ? _.intersection(currentQuestionAnswerOptions, activeAnswerOptions)
        : currentQuestionAnswerOptions;

      const questionConfig = getStringForLocale(
        'questionConfig',
        hraLanguageCode
      );

      const overrideConfig = question && questionConfig[question.id];

      const properties = overrideConfig && overrideConfig.properties;

      const questionType =
        (overrideConfig && overrideConfig.typeOverride) ||
        (question && question.type);

      const canSubmitAnswers = !!(questionAnswers.length && isValid !== false);

      let contentUI = null;

      if (question) {
        let questionUI = null;

        if (question.id === 'HNA_BP') {
          contentUI = (
            <BPQuestion
              key={question.id}
              onQuestionAnswered={onQuestionAnswered}
              answers={currentQuestionsAnswers}
              questionNumber={questionNumber}
              questionCount={surveyQuestionCount}
              questions={currentQuestions}
              skipQuestion={skipQuestion}
              hraLanguageCode={hraLanguageCode}
              {...properties}
            />
          );
        } else {
          switch (questionType) {
            case 'RADIO_BUTTONS':
              questionUI = (
                <PickerComponent
                  key={question.id}
                  options={currentQuestionChoices.map((answerOption) => ({
                    label:
                      answerOption.option[hraLanguageCode] ||
                      answerOption.option[LanguageConstants.LanguageCodes.EN],
                    value: answerOption.id,
                  }))}
                  onAnswered={(answers) =>
                    onQuestionAnswered(question.id, answers)
                  }
                  {...properties}
                  values={questionAnswers}
                  styles={legacyStyles.picker}
                  readOnly={
                    question &&
                    question.id === AppConstants.HRAQuestionId.GENDER &&
                    questionAnswers.length
                      ? true
                      : false
                  }
                />
              );

              break;

            case 'CHECKBOXES':
              questionUI = (
                <PickerComponent
                  key={question.id}
                  options={currentQuestionChoices.map((answerOption) => ({
                    label:
                      answerOption.option[hraLanguageCode] ||
                      answerOption.option[LanguageConstants.LanguageCodes.EN],
                    value: answerOption.id,
                  }))}
                  multiSelect
                  onAnswered={(answers) =>
                    onQuestionAnswered(question.id, answers)
                  }
                  values={questionAnswers}
                  styles={legacyStyles.picker}
                  {...properties}
                />
              );

              break;

            case 'LINEAR_SCALE':
              questionUI = (
                <LinearScale
                  key={question.id}
                  {...properties}
                  value={questionAnswers[0]}
                  options={currentQuestionChoices.map(
                    (answerOption, answerOptionIndex) => ({
                      label:
                        properties && properties.labels
                          ? properties.labels[answerOptionIndex]
                          : answerOption.option[hraLanguageCode] ||
                            answerOption.option[
                              LanguageConstants.LanguageCodes.EN
                            ],

                      value: answerOption.id,
                      score: answerOption.score,
                    })
                  )}
                  onAnswered={(answers) =>
                    onQuestionAnswered(question.id, answers)
                  }
                  styles={legacyStyles.linearScale}
                />
              );

              break;

            case 'TEXTBOX':
              questionUI = (
                <Freeform
                  key={question.id}
                  placeholder="Please enter a valid value"
                  onAnswered={(answer, isValid) =>
                    onQuestionAnswered(question.id, answer, isValid)
                  }
                  value={
                    questionAnswers && questionAnswers[0]
                      ? questionAnswers[0]
                      : ''
                  }
                  styles={legacyStyles.freeform}
                  {...properties}
                />
              );

              break;

            case 'DATE_FIELD':
              questionUI = (
                <DateComponent
                  key={question.id}
                  minDate={moment([1990, 0, 1])}
                  maxDate={moment()}
                  {...properties}
                  fullMonth
                  hraLanguageCode={hraLanguageCode}
                  value={questionAnswers[0]}
                  onAnswered={(answer, isValid) =>
                    onQuestionAnswered(question.id, answer, isValid)
                  }
                />
              );

              break;

            case 'DROPDOWN':
              questionUI = (
                <Dropdown
                  key={question.id}
                  options={currentQuestionChoices.map((answerOption) => ({
                    label:
                      answerOption.option[hraLanguageCode] ||
                      answerOption.option[LanguageConstants.LanguageCodes.EN],
                    value: answerOption.id,
                  }))}
                  onAnswered={(answer) =>
                    onQuestionAnswered(question.id, answer.value)
                  }
                  styles={legacyStyles.dropDownWidth}
                  {...properties}
                  value={questionAnswers[0]}
                />
              );

              break;
          }

          contentUI = (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Typography type="h2">
                {question.question[hraLanguageCode] ||
                  question.question[LanguageConstants.LanguageCodes.EN]}
                <sup>
                  {_.get(question, 'sources', [])
                    .map((src, index) =>
                      Array(index + 1)
                        .fill('*')
                        .join('')
                    )
                    .join(', ')}
                </sup>
              </Typography>
              <div style={{ flex: 1 }}>{questionUI}</div>
              <div>
                {_.get(question, 'sources', []).map((src, index) => (
                  <p key={src.code}>
                    {Array(index + 1)
                      .fill('*')
                      .join('')}
                    {src.source}
                  </p>
                ))}
              </div>
            </div>
          );
        }
      } else {
        contentUI = <Typography>Loading...</Typography>;
      }

      return {
        contentUI,
        questionNumber,
        canSubmitAnswers,
      };
    }
  ),
  withStyles(styles),
  withTheme()
)(HraQuestions);
