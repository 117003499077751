import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SignInActions } from '@healthmine/greyhound-core/src/actions';
import SignInMarkup from '../../components/sign-in/SignInMarkup';
import { SessionActions } from '../../actions';
import { withRouter } from 'react-router';
import _ from 'lodash';

class SignInPage extends React.Component {
  constructor(props) {
    super(props);
    this._signInHandler = this._signInHandler.bind(this);
    this._emailChange = this._emailChange.bind(this);
    this._passwordChange = this._passwordChange.bind(this);

    this.state = {
      email: '',
      password: '',
    };
  }
  render() {
    return (
      <SignInMarkup
        email={this.state.email}
        password={this.state.password}
        signIn={this.props.signIn}
        saving={this.props.saving}
        onClick={this._signInHandler}
        emailChange={this._emailChange}
        passwordChange={this._passwordChange}
      />
    );
  }
  _emailChange(e) {
    const { updateSignInEmailAddress } = this.props.actions;

    const email = e.target.value;

    this.setState({ email });
    updateSignInEmailAddress(email);
  }
  _passwordChange(e) {
    const { updateSignInPassword } = this.props.actions;

    const password = e.target.value;

    this.setState({ password });
    updateSignInPassword(password);
  }
  _signInHandler(e) {
    e.preventDefault();
    const {
      actions: { postSignIn },
      signIn,
    } = this.props;

    const memberCoverageId = localStorage.getItem('selectedMemberCoverageId');

    postSignIn(
      signIn.emailAddress.value,
      signIn.password.value,
      false,
      memberCoverageId
    ).then(this._signInSuccessHandler);
  }

  _signInSuccessHandler = (result) => {
    if (result) {
      const { payload } = result;
      const { signIn, router } = this.props;
      const { supportedMfaTypes } = signIn;

      if (
        signIn.requiresMfaEnrollment &&
        !signIn.requiresMfa &&
        supportedMfaTypes.length === 1
      ) {
        const mfaType = _.first(supportedMfaTypes).toLowerCase();
        router.push(`signin/mfa-enrollment/${mfaType}`);
      }
      // TODO: this is where a member would be navigated to their MFA options if multiple exist
      // else if (signIn.requiresMfaEnrollment && !signIn.requiresMfa)
      // router.push('signin/mfa-enrollment')
      else if (signIn.requiresMfa) {
        router.push('signin/verify-mfa');
      } else {
        this.props.actions.startSession(JSON.stringify(payload));
        this.setState({
          email: '',
          password: '',
        });
      }
    }
  };
}

SignInPage.propTypes = {
  actions: PropTypes.object.isRequired,
  saving: PropTypes.bool,
  signIn: PropTypes.object,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    saving: !!state.apiCallsInProgress,
    signIn: state.signIn,
  };
}

function mapDispatchToProps(dispatch) {
  const { startSession } = SessionActions;

  const {
    updateSignInEmailAddress,
    updateSignInPassword,
    postSignIn,
  } = SignInActions;

  return {
    actions: bindActionCreators(
      {
        startSession,
        updateSignInEmailAddress,
        updateSignInPassword,
        postSignIn,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(SignInPage)
);
