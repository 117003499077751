import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Scroll from 'react-scroll';
import moment from 'moment';
import compact from 'lodash/compact';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import * as CarePlanSelector from '@healthmine/greyhound-core/src/selectors/CarePlanSelector';
import CarePlans from '@healthmine/greyhound-core/src/modules/CarePlans';
import CarePlanActions from '@healthmine/greyhound-core/src/actions/CarePlanActions';
import CarePlanTitleMarkup from './CarePlanTitleMarkup';
import PostConfigurationMarkup from './PostConfigurationMarkup';
import TrackingStep from './steps/tracking/TrackingStep';
import FitnessStep from './steps/tracking/FitnessStep';
import ClinicalActionStep from './steps/ClinicalActionStep';
import EducationStep from './steps/EducationStep';
import DateStep from './steps/DateStep';
import Redirect from '../common/Redirect';

const config = {
  id: 'DIABETES',
  name: 'Lower My Blood Sugar',
  description: `Let's begin by helping you create a plan to manage your diabetes.`,
  duration: 365,
  iconClass: 'thin-diabetes',
  clinicalActions: [199, 2],
};

const scroller = Scroll.scroller;

const getScrollId = (index) => `CarePlanBuild-${index}`;

const styles = {
  container: {
    flex: 1,
    marginBottom: '120px',
  },
};

const Diabetes = ({
  clinicalActions,
  maxDate,
  nextStep,
  previousStep,
  saving,
  setStartDate,
  setFitness,
  setGlucose,
  startDate,
  start,
  status,
  step,
}) => {
  if (!(saving || status === AppConstants.PlanStatus.ASSIGNED)) {
    return <Redirect to={`/care-plan/diabetes`} />;
  }
  return (
    <div style={styles.container}>
      <CarePlanTitleMarkup
        continueHandler={() => nextStep(1)}
        init={step === 0}
        scrollId={getScrollId(0)}
        {...config}
      />
      <DateStep
        active={step === 1}
        duration={config.duration}
        maxDate={maxDate}
        nextStep={() => nextStep(2)}
        previousStep={() => previousStep(0)}
        scrollId={getScrollId(1)}
        startDate={startDate}
        setStartDate={setStartDate}
      />
      <ClinicalActionStep
        actions={clinicalActions}
        active={step === 2}
        nextStep={() => nextStep(3)}
        previousStep={() => previousStep(1)}
        recurring
        scrollId={getScrollId(2)}
      />
      <EducationStep
        active={step === 3}
        name="Diabetes"
        nextStep={() => nextStep(4)}
        previousStep={() => previousStep(2)}
        recurring
        scrollId={getScrollId(3)}
      />
      <TrackingStep
        active={step === 4}
        nextStep={() => nextStep(5)}
        previousStep={() => previousStep(3)}
        scrollId={getScrollId(4)}
        setTracking={setGlucose}
        type="GLUCOSE"
      />
      <FitnessStep
        active={step === 5}
        previousStep={() => previousStep(4)}
        last
        nextStep={() => nextStep(6)}
        scrollId={getScrollId(5)}
        setTracking={setFitness}
      />
      {step === 6 && (
        <PostConfigurationMarkup
          isSavingConfiguration={saving}
          backHandler={() => previousStep(5)}
          onConfigurePlan={start}
        />
      )}
    </div>
  );
};

Diabetes.propTypes = {
  clinicalActions: PropTypes.arrayOf(
    PropTypes.shape({
      shortDescription: PropTypes.string,
    })
  ).isRequired,
  maxDate: PropTypes.string.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  setFitness: PropTypes.func.isRequired,
  setGlucose: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  start: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
};

export default compose(
  connect(
    (state) => CarePlanSelector.selectBuildDiabetes(state),
    (dispatch) => {
      const { startMyGoal } = CarePlanActions;

      return {
        actions: bindActionCreators({ startMyGoal }, dispatch),
      };
    }
  ),
  withState('step', 'setStep', 0),
  withState('startDate', 'setStartDate', moment().format('YYYY-MM-DD')),
  withState('fitness', 'setFitness'),
  withState('glucose', 'setGlucose'),
  withState('saving', 'setSaving', false),
  withRouter,
  withHandlers({
    nextStep: ({ step, setStep }) => (nextStepIdx) => {
      if (nextStepIdx <= 6 && nextStepIdx !== step) {
        const previousScrollId = getScrollId(step);

        const offset = -(
          document.getElementsByName(previousScrollId)[0].offsetHeight - 70
        );

        setStep(nextStepIdx);
        if (nextStepIdx !== 6) {
          scroller.scrollTo(getScrollId(nextStepIdx), {
            offset,
            duration: 1000,
            smooth: true,
          });
        }
      }
    },
    previousStep: ({ step, setStep }) => (previousStepIndex) => {
      if (previousStepIndex >= 0 && previousStepIndex !== step) {
        setStep(previousStepIndex);
        scroller.scrollTo(getScrollId(previousStepIndex), {
          offset: -100,
          duration: 1000,
          smooth: true,
        });
      }
    },

    start: ({
      actions,
      id,
      router,
      startDate,
      setSaving,
      fitness,
      glucose,
    }) => () => {
      const request = CarePlans.requestForBuilding({
        startDate,
        tracking: compact([fitness, glucose]),
      });

      setSaving(true);
      actions
        .startMyGoal(id, request)
        .then(() => router.replace('/health-overview'))
        .catch(() => setSaving(false));
    },
  })
)(Diabetes);
