import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import ReactMarkdown from 'react-markdown';
import { compose, withStateHandlers, withProps } from 'recompose';
import SchedulingModal from '../health-overview/SchedulingModal';
import moment from 'moment';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Styles from './UnifiedCarePlanTracking.styles';
import { Element as ScrollElement } from 'react-scroll';

class HealthAction extends React.Component {
  componentWillReceiveProps(nextProps) {
    const { openedActionId } = nextProps;

    const previousOpenedActionId = this.props.openedActionId;

    if (
      openedActionId !== previousOpenedActionId &&
      (openedActionId === this.props.healthActionId ||
        previousOpenedActionId === this.props.healthActionId)
    ) {
      this.props.toggleLongDescription();
    }
  }

  render() {
    const {
      isEven,
      title,
      description,
      isSchedulingOpen,
      openScheduling,
      closeScheduling,
      completed,
      lastCompleted,
      nextDue,
      schedulingContactList,
      scrollId,
      longDescription,
      showingLongDescription,
      toggleLongDescription,
      descriptionToggleText,
      descriptionToggleIcon,
    } = this.props;
    return (
      <ScrollElement name={scrollId}>
        <div
          style={[
            Styles.actionListItem,
            isEven && Styles.evenListItem,
            completed ? { color: Colors.ice } : {},
          ]}
        >
          <div style={Styles.actionHeader}>
            <div style={Styles.actionTitle}>{title}</div>
            <div style={Styles.actionDate}>
              {completed ? (
                <>
                  <div>Completed</div>
                  {lastCompleted && (
                    <span style={Styles.date}>
                      {`Completed On: ${moment(lastCompleted).format(
                        'M/D/YY'
                      )}`}
                    </span>
                  )}
                </>
              ) : (
                <div style={{ fontSize: '16px' }}>
                  {moment(nextDue).isBefore(moment()) ? (
                    <div style={{ color: Colors.newRed }}>Past Due</div>
                  ) : (
                    <div>Due by {moment(nextDue).format('M/D/YY')}</div>
                  )}
                  <a
                    onClick={openScheduling}
                    style={{
                      color: '#1E80F0',
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    Scheduling Options{' '}
                    <i style={{ fontSize: '18px' }} className="material-icons">
                      chevron_right
                    </i>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div style={Styles.actionDescription}>{description}</div>
          {showingLongDescription && (
            <div style={Styles.actionDescription}>
              <ReactMarkdown source={longDescription.replace(/\\n/g, '\n')} />
            </div>
          )}
          <a style={Styles.viewMoreToggle} onClick={toggleLongDescription}>
            <span>{descriptionToggleText}</span>
            <i className="material-icons">{descriptionToggleIcon}</i>
          </a>
          {isSchedulingOpen && (
            <SchedulingModal
              close={closeScheduling}
              healthActionTitle={title}
              schedulingContactList={schedulingContactList}
            />
          )}
        </div>
      </ScrollElement>
    );
  }
}

HealthAction.propTypes = {
  isEven: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  openScheduling: PropTypes.func,
  closeScheduling: PropTypes.func,
  isSchedulingOpen: PropTypes.bool,
  completed: PropTypes.bool,
  lastCompleted: PropTypes.string,
  nextDue: PropTypes.string,
  schedulingContactList: PropTypes.array,
  scrollId: PropTypes.string,
  longDescription: PropTypes.string,
  showingLongDescription: PropTypes.bool,
  toggleLongDescription: PropTypes.func,
  descriptionToggleText: PropTypes.string,
  descriptionToggleIcon: PropTypes.string,
  openedActionId: PropTypes.string,
  healthActionId: PropTypes.string,
};

export default compose(
  withStateHandlers(
    {
      isSchedulingOpen: false,
      showingLongDescription: false,
    },
    {
      openScheduling: (state) => () => ({
        ...state,
        isSchedulingOpen: true,
      }),

      closeScheduling: (state) => () => ({
        ...state,
        isSchedulingOpen: false,
      }),
      toggleLongDescription: (state) => () => ({
        ...state,
        showingLongDescription: !state.showingLongDescription,
      }),
    }
  ),
  withProps(({ showingLongDescription }) => ({
    descriptionToggleText: showingLongDescription ? 'View Less' : 'View More',
    descriptionToggleIcon: showingLongDescription
      ? 'keyboard_arrow_up'
      : 'keyboard_arrow_down',
  })),
  Radium
)(HealthAction);
