import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Colors,
  GlobalStyles,
  AppConfig,
  Errors,
} from '@healthmine/greyhound-core/src/modules';
import { SignInActions } from '@healthmine/greyhound-core/src/actions';
import { SessionActions } from '../../actions';
import Button from '../../components/common/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClipboardItem from '../common/ClipboardItem';

const errorLimit = 3;

const LoginFailure = ({ count, actions, errors, impersonating }) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '280px',
      fontSize: '18px',
      color: 'red',
    },
    button: {
      width: '250px',
      padding: '10px 25px',
      marginTop: '20px',
      marginBottom: '20px',
    },
    errorMessage: {
      marginBottom: '10px',
    },
    errorCode: {
      color: Colors.bodyText,
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.errorMessage}>
        We were able to log you in but had some issues loading your data.
      </div>
      <div>
        {count < errorLimit ? 'If the issue persists, p' : 'P'}lease contact
        your customer service team
        <span>
          {errors.length === 0
            ? ' for help.'
            : ' with the following error code(s):'}
        </span>
        {errors.length > 0 && (
          <ul style={styles.errorCode}>
            {errors.map((error) => (
              <li key={error}>
                {error}
                <ClipboardItem text={error} />
              </li>
            ))}
          </ul>
        )}
      </div>
      {impersonating && count >= errorLimit ? (
        <div>Please close the window and return to your admin tool.</div>
      ) : (
        <Button
          text={count < errorLimit ? 'Try Again' : 'Logout'}
          style={[styles.button, GlobalStyles.regular]}
          onClick={
            count < errorLimit
              ? () => {
                  const memberCoverageId = localStorage.getItem(
                    'selectedMemberCoverageId'
                  );

                  actions.retry({ memberCoverageId });
                }
              : () => {
                  setTimeout(() => actions.logout(), 500);
                  if (AppConfig.effectiveConfig.inboundSSOEnabled) {
                    window.location.replace(
                      AppConfig.effectiveConfig.logoutRedirect
                    );
                  }
                }
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: Errors.getErrorMessages(state),
    count: state.errors.count,
    impersonating: !!state.signIn.impersonationEmail,
  };
};

const mapDispatchToProps = (dispatch) => {
  const retry = SignInActions.getUserData;

  const logout = SessionActions.endSession;

  return {
    actions: bindActionCreators(
      {
        retry,
        logout,
      },
      dispatch
    ),
  };
};

LoginFailure.propTypes = {
  count: PropTypes.number,
  errors: PropTypes.array,
  impersonating: PropTypes.bool,
  actions: PropTypes.shape({
    retry: PropTypes.func,
    logout: PropTypes.func,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(LoginFailure)
);
