import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { filter, isEmpty } from 'lodash/fp';
import { withTheme } from '@material-ui/core/styles';
import List, { ListHeader, ListContent } from '../../../../common/list/List';
import TrackerAction from './actions/TrackerAction';

const GoalTrackingList = ({ theme, trackers, hasTrackers }) => (
  <List>
    <ListHeader
      iconClass="icon-Line-Graph"
      color={theme.palette.common.mintJulep}
      title="Goals"
      isMute={hasTrackers}
    />
    <ListContent>
      {trackers.map((x, i) => <TrackerAction key={i} tracker={x} />)}
    </ListContent>
  </List>
);

GoalTrackingList.propTypes = {
  theme: PropTypes.object.isRequired,
  trackers: PropTypes.array.isRequired,
  hasTrackers: PropTypes.bool,
};

export default compose(
  pure,
  withTheme(),
  withProps(({ trackers }) => {
    const filtered = filter((x) => x.isAvailable || x.isActive, trackers);
    return {
      trackers: filtered,
      hasTrackers: isEmpty(filtered),
    };
  })
)(GoalTrackingList);
