import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Card from '../../common/card/Card';
import Typography from '../../common/Typography';
import { Grid } from '@material-ui/core';
import moment from 'moment';

const styles = (theme) => ({
  customCard: {
    padding: 0,
    width: '237px',
  },
  image: {
    height: '150px',
    width: '100%',
    background: '#ccc',
    borderTopLeftRadius: theme.globalStyles.borderRadius1,
    borderTopRightRadius: theme.globalStyles.borderRadius1,
  },
  cardBody: {
    padding: '20px',
  },
});

const SweepstakeCard = ({
  classes,
  title,
  closeDate,
  entries,
  imageUrl,
  selectSweepstake,
}) => {
  return (
    <Card customClass={classes.customCard} onClick={selectSweepstake}>
      <img className={classes.image} src={imageUrl} />
      <div className={classes.cardBody}>
        <Grid container direction="column" spacing={8}>
          <Grid item>
            <Typography type="h4Medium">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              Closes on: {moment(closeDate).format('MM/DD/YYYY')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>My Entries: {entries}</Typography>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

SweepstakeCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  entries: PropTypes.number.isRequired,
  closeDate: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  sweepstakeId: PropTypes.string.isRequired,
  selectSweepstake: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ onClick, sweepstakeId }) => {
    return {
      selectSweepstake: () => onClick(sweepstakeId),
    };
  })
)(SweepstakeCard);
