export default {
  root: {},

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px',
  },

  headerMainInfo: {
    display: 'flex',
    alignItems: 'center',
  },

  bubble: {
    height: '70px',
    width: '70px',
    minWidth: '70px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  carePlanIcon: {
    color: '#FFF',
    fontSize: '30px',
  },

  carePlanTitle: {
    marginLeft: '24px',
  },

  carePlanName: {
    fontSize: '24px',
    lineHeight: '30px',
    textTransform: 'uppercase',
  },

  memo: {
    fontSize: '16px',
    lineHeight: '19px',
  },

  headerStats: {},

  completeCount: {
    display: 'flex',
    alignItems: 'center',
  },

  completeCountNumber: {
    fontSize: '32px',
    lineHeight: '30px',
  },

  completeCountMemo: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    marginLeft: '.5rem',
  },

  actionListTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '24px',
  },

  actionListItem: {
    minHeight: '130px',
    padding: '24px',
    backgroundColor: '#FBFBFB',
  },

  evenListItem: {
    backgroundColor: '#F7F7F7',
  },

  actionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  actionTitle: {
    fontSize: '20px',
  },

  actionDate: {
    fontSize: '16px',
    fontWeight: '500',
  },

  actionDescription: {
    fontSize: '16px',
    marginTop: '24px',
    transition: '1s ease all',
  },

  viewMoreToggle: {
    cursor: 'pointer',
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: '#1E80F0',
    fontWeight: '500',
    userSelect: 'none',
  },

  date: {
    fontWeight: '300',
    display: 'block',
    marginTop: '7px',
  },
};
