import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Styles from './UnifiedCarePlan.styles';
import CardItem from './card-item/CardItem';
import CarePlanActionList from '../list-item/CarePlanActionListWrapper';

const UnifiedCarePlanMarkup = ({
  isGridView,
  setGridView,
  setListView,
  hasAssignedEducation,
  preventive,
  education,
  goals,
}) => {
  const CarePlanItem = isGridView ? CardItem : CarePlanActionList;

  return (
    <div style={Styles.root}>
      <div style={Styles.header}>
        <div>My Care Plan</div>
        <div>
          <a
            onClick={setListView}
            style={[
              Styles.toggleLink,
              isGridView ? {} : Styles.selectedToggleLink,
            ]}
          >
            <i className="material-icons">format_list_bulleted</i>
          </a>
          <a
            onClick={setGridView}
            style={[
              Styles.toggleLink,
              isGridView ? Styles.selectedToggleLink : {},
            ]}
          >
            <i className="material-icons">apps</i>
          </a>
        </div>
      </div>
      <div style={isGridView ? Styles.cardItemContainer : {}}>
        <CarePlanItem carePlan={preventive} />
        {goals.map((carePlan, idx) => (
          <CarePlanItem key={`care_plan_goal_${idx}`} carePlan={carePlan} />
        ))}
        {hasAssignedEducation && <CarePlanItem carePlan={education} />}
      </div>
    </div>
  );
};

UnifiedCarePlanMarkup.propTypes = {
  isGridView: PropTypes.bool,
  setGridView: PropTypes.func,
  setListView: PropTypes.func,
  hasAssignedEducation: PropTypes.bool,
  preventive: PropTypes.object,
  education: PropTypes.object,
  goals: PropTypes.array,
};

export default Radium(UnifiedCarePlanMarkup);
