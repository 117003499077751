import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { GlobalStyles, Colors } from '@healthmine/greyhound-core/src/modules';
import Icons from '@healthmine/greyhound-core/src/modules/Icons';
import ConnectDevice from '../../../common/ConnectDevice';
import Frequency from './Frequency';

const styles = {
  header: {
    color: Colors.bodyText,
    fontSize: '16px',
    margin: 0,
  },
  trackingTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '50px 0',
  },
  trackingTypeOptionsContainer: {
    display: 'flex',
  },
  trackingTypeOption: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 30px 0 10px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  trackingTypeIcon: {
    fontSize: '50px',
    color: Colors.lightGray,
    marginBottom: '10px',
  },
  trackingTypeButton: {
    color: Colors.dark,
    width: '120px',
    padding: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.dark,
    borderRadius: '4px',
    fontWeight: '400',
    cursor: 'pointer',
  },
  manualAgreement: {
    color: Colors.bodyText,
    margin: '60px 0 10px 0',
    fontSize: '16px',
    maxWidth: '380px',
  },
  connectDeviceLink: {
    fontSize: '16px',
    fontWeight: '200',
    color: Colors.highlightBlue,
    cursor: 'pointer',
    marginTop: '16px',
    textAlign: 'center',
    width: '150px',
    ...GlobalStyles.regular,
  },
};

const DeviceConfiguration = ({
  copy,
  frequency,
  manual,
  connectedClicked,
  manualClicked,
}) => (
  <div>
    <h2 style={styles.header}>{copy.unconfigured}</h2>
    <Frequency copy={copy} frequency={frequency} />
    <div style={styles.trackingTypeContainer}>
      <div style={styles.trackingTypeOptionsContainer}>
        <div>
          <div
            className={
              manual === false ? 'optionBlock selected' : 'optionBlock'
            }
            style={styles.trackingTypeOption}
          >
            <span style={styles.trackingTypeIcon}>
              <i className={Icons.LINK} />
            </span>
            <label style={styles.trackingTypeButton} onClick={connectedClicked}>
              With a Device
            </label>
          </div>
          <ConnectDevice
            link
            style={styles.connectDeviceLink}
            itemText="Connect a Device"
          />
        </div>

        <div
          className={manual === true ? 'optionBlock selected' : 'optionBlock'}
          style={styles.trackingTypeOption}
        >
          <span style={styles.trackingTypeIcon}>
            <i className={Icons.FORM} />
          </span>
          <label style={styles.trackingTypeButton} onClick={manualClicked}>
            On My Own
          </label>
        </div>
      </div>
      {manual && (
        <div style={styles.manualAgreement}>
          If you do not have a device to connect, you agree to manually check in
          with updates.
        </div>
      )}
    </div>
  </div>
);

DeviceConfiguration.propTypes = {
  copy: PropTypes.object.isRequired,
  manual: PropTypes.bool,
  manualClicked: PropTypes.func.isRequired,
  connectedClicked: PropTypes.func.isRequired,
  frequency: PropTypes.number.isRequired,
};

export default Radium(DeviceConfiguration);
