import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, withProps } from 'recompose';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 128px 36px 128px',
    alignItems: 'center',
    zIndex: '0',
    width: '750px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  sectionIndicator: {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    backgroundColor: '#2f785a',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  activeIndicator: {
    border: '4px solid white',
    backgroundColor: '#febf00',
  },
  skipping: {
    backgroundColor: '#E73B44',
  },
  doneIndicator: {
    backgroundColor: '#296D50',
  },
  activeDoneIndicator: {
    width: '58px',
    height: '58px',
    borderRadius: '29px',
    border: '4px solid #FEB600',
    marginLeft: '24px',
  },
  incomplete: {
    border: '4px solid #E41F29',
  },
  indicatorIcon: {
    height: '14px',
  },
  activeDoneIcon: {
    height: '24px',
  },
  complete: {
    border: '4px solid red',
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    visibility: 'hidden',
    position: 'absolute',
    bottom: '-24px',
    fontSize: '14px',
  },
  activeTitle: {
    visibility: 'visible',
  },
  divider: {
    '&:after': {
      content: '""',
      width: '24px',
      height: '2px',
      backgroundColor: 'white',
      display: 'inline-block',
      position: 'absolute',
      top: '14px',
      right: '-12px',
    },
  },
});

const QuestionnaireSectionProgress = ({
  classes,
  sections,
  currentSection,
  currentSectionIndex,
  showSkipWarning,
  verifying,
  isIncomplete,
}) => (
  <Grid container className={classes.root}>
    {sections.map((section, idx) => (
      <Grid
        key={section}
        item
        xs
        className={classnames(classes.section, classes.divider)}
      >
        <div
          className={classnames(classes.sectionIndicator, {
            [classes.activeIndicator]: section === currentSection && !verifying,
            [classes.skipping]: showSkipWarning && section === currentSection,
          })}
        >
          {idx > currentSectionIndex && !verifying ? (
            <img
              className={classes.indicatorIcon}
              src={require('../../../../images/new-fte/icons/locked.png')}
              role="presentation"
            />
          ) : idx < currentSectionIndex || verifying ? (
            <img
              className={classes.indicatorIcon}
              src={require('../../../../images/new-fte/icons/check.png')}
              role="presentation"
            />
          ) : null}
        </div>
        <div
          className={classnames(classes.sectionTitle, {
            [classes.activeTitle]: idx === currentSectionIndex && !verifying,
          })}
        >
          {currentSection}
        </div>
      </Grid>
    ))}
    <Grid item xs className={classes.section}>
      <div
        className={classnames(classes.sectionIndicator, classes.doneIndicator, {
          [classes.activeDoneIndicator]: verifying,
          [classes.incomplete]: isIncomplete && verifying,
        })}
      >
        <img
          className={classnames(classes.indicatorIcon, {
            [classes.activeDoneIcon]: verifying,
          })}
        />
      </div>
    </Grid>
  </Grid>
);

QuestionnaireSectionProgress.propTypes = {
  sections: PropTypes.array.isRequired,
  showSkipWarning: PropTypes.bool.isRequired,
  verifying: PropTypes.bool.isRequired,
  isIncomplete: PropTypes.bool.isRequired,
  currentSectionIndex: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  currentSection: PropTypes.string,
};

export default compose(
  withStyles(styles),
  withProps(({ sections, currentSectionIndex }) => ({
    currentSection: sections[currentSectionIndex],
  }))
)(QuestionnaireSectionProgress);
