import React from 'react';
import PageSection from '../../../common/page/PageSection';
import GridWrapper from '../../../common/GridWrapper';
import IconTitleDrawerCard from '../../../common/card/IconTitleDrawerCard';
import Support from './Support';
import OfficialRules from './OfficialRules';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';

const TopPicks = ({ hasIncentivesRuleDoc }) => {
  return (
    <PageSection title="Top Picks">
      <GridWrapper>
        <IconTitleDrawerCard
          title="Support"
          iconClass="icon-Customer-Service"
          primary
          DrawerView={Support}
          largeDrawer
        />
        {hasIncentivesRuleDoc && (
          <IconTitleDrawerCard
            iconClass="icon-Document-Scroll"
            title="Official Rules"
            primary
            DrawerView={OfficialRules}
            largeDrawer
          />
        )}
        <IconTitleDrawerCard
          iconClass="icon-Document-Scroll"
          title="Terms of Use"
          primary
          DrawerView={Terms}
          largeDrawer
        />
        <IconTitleDrawerCard
          iconClass="icon-Shield"
          title="Privacy Policy"
          primary
          DrawerView={PrivacyPolicy}
          largeDrawer
        />
      </GridWrapper>
    </PageSection>
  );
};

TopPicks.propTypes = {
  hasIncentivesRuleDoc: PropTypes.bool.isRequired,
};

export default withProps(() => ({
  hasIncentivesRuleDoc: !AppConfig.validateFeature(
    Features.NO_INCENTIVES_RULE_DOC
  ),
}))(TopPicks);
