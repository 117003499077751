import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import _ from 'lodash';
import moment from 'moment';
import { DrawerContent, withDrawer } from '../../../common/drawer2';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import { connect } from 'react-redux';
import { selectIncentiveTransactionsKeyedByTypeSelector } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../../../common/Typography';
import InfoPopup from '../../../../../components/common/InfoPopup';

const styles = () => ({
  table: {
    textAlign: 'left',
    fontSize: '16px',
    width: '100%',
    borderCollapse: 'collapse',
  },

  th: {
    padding: '10px 5px',
    color: Colors.mediumGray2,
    backgroundColor: '#f3f6f8',
    fontSize: '16px',
    textAlign: 'center',
  },

  td: {
    padding: '20px 5px',
    color: Colors.contentText,
    borderBottom: `1px solid ${Colors.gutterGray}`,
    textAlign: 'center',
  },
});

const RewardActivityList = ({ classes, title, rewardHistory }) => {
  const { lingo } = AppConfig.effectiveConfig;
  const { setCurrencyLabel } = RewardsConstants;
  const styles = {
    infoPopup: {
      popupContainer: {
        right: '12px',
      },
      popupContent: {
        borderRadius: '10px 0 10px 10px',
      },
      popupStem: {
        right: 0,
        left: 'initial',
        borderTop: '20px solid transparent',
        borderRight: `15px solid ${Colors.hexToRgba(Colors.lighterGray)}`,
        borderBottom: '0 solid transparent',
        borderLeft: '0 solid transparent',
      },
    },
    tooltip: {
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: 400,
      width: '240px',
    },
  };
  return (
    <DrawerContent title={title}>
      {rewardHistory.length === 0 ? (
        <Typography type="h4" color="grey" center>
          No redeem activity found...
        </Typography>
      ) : (
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.th} style={GlobalStyles.bold} width="25%">
                Rewards Earned
              </th>
              <th className={classes.th} style={GlobalStyles.bold} width="25%">
                Date Completed
                <InfoPopup styles={styles.infoPopup}>
                  <div style={styles.tooltip}>
                    Date represents date of service on claim or completion date
                    of health action
                  </div>
                </InfoPopup>
              </th>
              <th className={classes.th} style={GlobalStyles.bold} width="25%">
                Date Rewarded
                <InfoPopup styles={styles.infoPopup}>
                  <div style={styles.tooltip}>
                    Date Rewarded may differ from Date Completed due to claims
                    processing cycle
                  </div>
                </InfoPopup>
              </th>
              <th className={classes.th} style={GlobalStyles.bold} width="25%">
                {lingo.healthAction}
              </th>
            </tr>
          </thead>
          <tbody>
            {rewardHistory.map((item, index) => {
              const lastCompletedDate = moment(item.lastCompletedDate);
              return (
                <tr key={index}>
                  <td className={classes.td}>
                    {setCurrencyLabel(item.amount)}
                  </td>
                  <td className={classes.td}>
                    {lastCompletedDate.isValid()
                      ? lastCompletedDate.format('MM/DD/YYYY')
                      : 'Unknown Date'}
                  </td>
                  <td className={classes.td}>
                    {moment(item.lastIssuedDate).format('MM/DD/YYYY')}
                  </td>
                  <td className={classes.td}>{item.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </DrawerContent>
  );
};

RewardActivityList.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  rewardHistory: PropTypes.array,
};

export default compose(
  pure,
  withDrawer,
  connect((state) => {
    const rewardsData = selectIncentiveTransactionsKeyedByTypeSelector(state);
    const rewardHistory = _.get(rewardsData, 'REWARD', []);
    return {
      rewardHistory,
    };
  }),
  withStyles(styles)
)(RewardActivityList);
