import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Chart from './chart';
import WeeklyHistoryTable from './table/WeeklyHistoryTable';
import Sources from './Sources';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Measurements from '@healthmine/greyhound-core/src/modules/Measurements';
import { Flex, Box } from 'reflexbox';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';

const dataSelector = (state, ownProps) =>
  ownProps.measurement.dataSelector(state);

const currentWeekSelector = (state, ownProps) =>
  ownProps.measurement.weekSelector(state, ownProps.week);

const stepTotalSelector = (state) =>
  _.get(state, 'myDataSummary.steps.planYearTotal', 0);

const propSelector = createCachedSelector(
  dataSelector,
  currentWeekSelector,
  stepTotalSelector,
  (data, currentWeekData, planYearTotalSteps) => ({
    data,
    currentWeekData,
    planYearTotalSteps,
    minDate: _.head(_.head(data)),
    maxDate: _.head(_.last(data)),
  })
)((state, props) => props.measurement.dataKey);

const MeasurementHistory = ({
  measurement,
  history,
  toggleHistory,
  index,
  data,
  minDate,
  maxDate,
  week,
  currentWeekData,
  nextWeek,
  prevWeek,
  planYearTotalSteps,
  hideSources,
}) => {
  const ticks = Measurements.getWeeks(week, maxDate, 5);

  const styles = {
    toggle: {
      color: Colors.contentText,
      cursor: 'pointer',
      paddingRight: '29px',
      marginTop: 0,
      marginBottom: 0,
      textAlign: 'right',
      fontSize: '16px',
      position: 'absolute',
      right: '10px',
      bottom: '15px',
    },
    historyContent: {
      backgroundColor: Colors.light,
      transition: '0.5s ease all',
      textAlign: 'center',
      position: 'absolute',
      left: `-${(index % 3) * 300}px`,
      right: `${(index % 3) * 300 - 600}px`,
      top: '251px',
      display: 'block',
    },
  };

  const currentWeek = moment(maxDate)
    .add(week, 'w')
    .startOf('w');

  const TrackingChart = Chart[measurement.dataKey];
  return (
    <div>
      <div style={styles.toggle} onClick={toggleHistory}>
        {history ? 'Hide History' : 'View History'}
        <i className={history ? 'caret up' : 'caret'} />
      </div>
      {history && (
        <div style={styles.historyContent}>
          <Flex justify={'center'}>
            {!hideSources && (
              <Sources sourceSelector={measurement.sourceSelector} />
            )}
            <Box col={4} p={0}>
              <TrackingChart
                data={data}
                ticks={ticks}
                planYearTotalSteps={
                  measurement.dataKey == 'steps' ? planYearTotalSteps : null
                }
              />
            </Box>
            <WeeklyHistoryTable
              currentWeek={currentWeek}
              week={week}
              maxDate={maxDate}
              minDate={minDate}
              currentWeekData={currentWeekData}
              nextWeek={nextWeek}
              prevWeek={prevWeek}
              unit={measurement.unit}
            />
          </Flex>
        </div>
      )}
    </div>
  );
};

MeasurementHistory.propTypes = {
  measurement: PropTypes.object,
  history: PropTypes.bool,
  toggleHistory: PropTypes.func,
  index: PropTypes.number,
  data: PropTypes.array,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  week: PropTypes.number,
  currentWeekData: PropTypes.object,
  nextWeek: PropTypes.func,
  prevWeek: PropTypes.func,
  planYearTotalSteps: PropTypes.number,
  hideSources: PropTypes.bool,
};

export default compose(
  withState('week', 'setWeek', 0),
  withHandlers({
    nextWeek: ({ setWeek }) => () => setWeek((week) => week + 1),
    prevWeek: ({ setWeek }) => () => setWeek((week) => week - 1),
  }),
  connect((state, ownProps) => propSelector(state, ownProps)),
  Radium
)(MeasurementHistory);
