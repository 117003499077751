import { compose, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import * as CarePlanSelector from '@healthmine/greyhound-core/src/selectors/CarePlanSelector';
import EducationActionList from './list-item/EducationActionList';
import { withRouter } from 'react-router';
import _ from 'lodash';

export default compose(
  pure,
  withRouter,
  connect((state, ownProps) => {
    const carePlans = CarePlanSelector.selectGoalsForOverview(state);

    const carePlanEducationActionLists = _.map(carePlans, (carePlan) => {
      const actions = _.get(carePlan, 'actions', []);
      return _.filter(actions, ['type', 'EDUCATION']);
    });

    const zippedActionLists = _.zip(...carePlanEducationActionLists);

    const combinedZippedActions = _.concat(...zippedActionLists);

    const list = _(combinedZippedActions)
      .compact()
      .take(5)
      .map((action) => ({
        title: action.text,
        description: action.title,
        incentiveAmount: 10,
        viewEducation: () => ownProps.router.push(`/pha/${action.contentId}`),
      }))
      .value();

    return {
      list,
    };
  }),
  withHandlers({
    viewHealthLibrary: ({ router }) => () => router.push('/pha'),
  })
)(EducationActionList);
