import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  branch,
  compose,
  pure,
  renderComponent,
  renderNothing,
  withHandlers,
} from 'recompose';
import { CoverageActions } from '@healthmine/greyhound-core/src/actions';
import {
  CoverageSelectors,
  SingleCarePlanSelectors,
} from '@healthmine/greyhound-core/src/selectors';
import CompliandPeriodSelectorMarkup from './CompliancePeriodSelector.markup';

export default compose(
  pure,

  connect(
    (state) => {
      const compliancePeriodOptions = SingleCarePlanSelectors.selectCompliancePeriodFilterOptions(
        state
      );

      const selectedCompliancePeriod = CoverageSelectors.selectCurrentCompliancePeriod(
        state
      );

      return {
        compliancePeriodOptions,
        selectedCompliancePeriod,
      };
    },

    (dispatch) => {
      const { setSelectedCompliancePeriod } = CoverageActions;

      return {
        actions: bindActionCreators({ setSelectedCompliancePeriod }, dispatch),
      };
    }
  ),

  withHandlers({
    onCompliancePeriodSelected: ({ actions }) => (evt) =>
      actions.setSelectedCompliancePeriod({
        compliancePeriodId: parseInt(_.get(evt, 'target.value')),
      }),
  }),

  branch(
    ({ compliancePeriodOptions }) => !_.isEmpty(compliancePeriodOptions),
    renderComponent(CompliandPeriodSelectorMarkup),
    renderNothing
  )
)();
