import React from 'react';
import AboutIncentives from './about-incentives/AboutIncentives';
import Summary from './Summary';
import IncentivesList from './IncentivesList';
import AutomatedRewardsCard from './AutomatedRewards/AutomatedRewardsCard';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { withProps, compose, lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { withDrawer } from '../../common/drawer2';
import {
  IncentivesDrawerRouteMapping,
  getIncentivesDrawerRouteComponent,
  registerIncentivesDrawerRoute,
} from '../../common/drawer2/DrawerRouteMapping';
import { hashHistory } from 'react-router';
import PointSummary from './about-incentives/PointSummary';

const Incentives = ({ incentiveType }) => {
  return (
    <div>
      <Summary />
      <AboutIncentives incentiveType={incentiveType} />
      {incentiveType === AppConstants.IncentiveType.REWARDS && (
        <AutomatedRewardsCard />
      )}
      <IncentivesList incentiveType={incentiveType} />
    </div>
  );
};

Incentives.propTypes = {
  incentiveType: PropTypes.string.isRequired,
};

const openDrawerOnDrawerRouteMatch = (drawer, location) => {
  const matchedRoute = _.find(_.keys(IncentivesDrawerRouteMapping), (route) =>
    location.pathname.includes(route)
  );

  if (matchedRoute) {
    const drawerRoute = getIncentivesDrawerRouteComponent(matchedRoute);
    drawer.open(
      _.get(drawerRoute, 'component'),
      {},
      {
        large: _.get(drawerRoute, 'isLarge'),
        onClose: () => {
          hashHistory.replace('/rewards');
        },
      }
    );
  }
};

registerIncentivesDrawerRoute('points-summary', {
  component: PointSummary,
  isLarge: true,
});

export default compose(
  withDrawer,
  withProps(() => ({
    incentiveType: get(AppConfig, 'effectiveConfig.incentiveType', ''),
  })),
  lifecycle({
    componentDidMount() {
      const { drawer, location } = this.props;
      openDrawerOnDrawerRouteMatch(drawer, location);
    },
  })
)(Incentives);
