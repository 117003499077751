import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules/';
import Freeform from '../../common/question/Freeform';
import { compose, withHandlers, withProps } from 'recompose';
import { WithTracking } from '@healthmine/greyhound-core/src/hocs/';

const TrackingInput = ({
  measurement,
  hideIcon,
  submit,
  isValid,
  value,
  setValue,
  sideEffectOnSubmit,
  saveValue,
  canSave,
}) => {
  const styles = {
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingTop: 0,
    },
    primaryText: {
      fontSize: '30px',
      margin: '0 0 20px',
    },
    measureLabel: {
      marginLeft: '15px',
      minWidth: '100px',
      display: 'flex',
    },
    freeformStyles: {
      container: {
        width: '160px',
        //width : module.type === AppConstants.ModuleType.BLOOD_PRESSURE ? '185px' : '160px',
        margin: '0 auto 20px',
        padding: '0 0 20px',
      },
      input: {
        fontSize: '17px',
        //fontSize : mask ? '15px' : '17px',
        borderBottomColor: Colors.gutterGray,
        textAlign: 'left',
      },
      placeholder: {
        color: Colors.highlightBlue,
        fontWeight: 500,
        fontSize: '16px',
        width: '100%',
        left: 0,
        top: '10px',
        textAlign: hideIcon ? 'center' : 'left',
      },
      floatingPlaceholder: {
        display: 'none',
      },
      validValueIndicator: {
        top: '8px',
      },
      errorIndicator: {
        position: 'absolute',
      },
    },
    icon: {
      display: 'flex',
      border: `2px solid ${measurement.color}`,
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      padding: '0px',
      fontSize: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: measurement.color,
    },
    saveButton: {
      marginLeft: '10px',
      height: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',
      color: Colors.lighterGray,
      cursor: 'not-allowed',
      userSelect: 'none',
    },
    activeSaveButton: {
      cursor: 'pointer',
      color: Colors.linkBlue,
    },
  };

  return (
    <div style={styles.contentContainer}>
      {!hideIcon && (
        <div style={styles.icon}>
          <i className={measurement.icon} style={{ color: 'white' }} />
        </div>
      )}
      <div style={styles.measureLabel}>
        <Freeform
          placeholder={`Log my ${measurement.actionText ||
            measurement.dataKey}`}
          styles={styles.freeformStyles}
          onPressEnter={(e) => {
            if (submit(e) && sideEffectOnSubmit) sideEffectOnSubmit();
          }}
          moduleType={measurement.type}
          {...measurement.mask}
          validationMethod={isValid}
          value={value}
          onAnswered={setValue}
          errorWhenInactive
        />
        <a
          style={[styles.saveButton, canSave ? styles.activeSaveButton : {}]}
          onClick={saveValue}
        >
          Save
        </a>
      </div>
    </div>
  );
};

TrackingInput.propTypes = {
  measurement: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    actionText: PropTypes.string,
  }),
  hideIcon: PropTypes.bool,
  submit: PropTypes.func,
  isValid: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  sideEffectOnSubmit: PropTypes.func,
  saveValue: PropTypes.func,
  canSave: PropTypes.bool,
};

export default compose(
  WithTracking,
  withProps(({ value, isValid }) => {
    const validation = isValid(value);
    return {
      canSave: validation.isValid,
    };
  }),
  withHandlers({
    submit: ({ actions }) => (e, v) => {
      const isEnter = !e || (e && e.key === 'Enter');

      const value = !e && v ? v : e.target.value;

      if (isEnter && value) {
        actions.saveAction(value.split('/'));
        return true;
      }
      return false;
    },
    saveValue: ({ actions, sideEffectOnSubmit, value, canSave }) => () => {
      if (canSave) {
        actions.saveAction(value.split('/'));
        if (typeof sideEffectOnSubmit !== 'undefined') {
          sideEffectOnSubmit();
        }
      }
    },
  }),
  Radium
)(TrackingInput);
