import React from 'react';
import { Grid, Card, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, withProps, withHandlers } from 'recompose';
import { get } from 'lodash';

const styles = {
  root: {
    width: '100px',
    borderRadius: '8px',
    margin: '6px',
    cursor: 'pointer',
    maxHeight: '100px',
    padding: '8px',
  },
  smallCard: {
    height: '50px',
  },
  answerIcon: {
    height: '40px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '1.75rem',
  },
  answerText: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: '500',
    fontSize: '14px',
    textAlign: 'center',
    color: '#555555',
    height: '100%',
  },
  centerVertical: {
    alignItems: 'center',
  },
  active: {
    padding: '6px',
    animationName: 'activate',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  '@keyframes activate': {
    '0%': { border: '2px solid transparent', backgroundColor: 'white' },
    '10%': {
      boxShadow: 'none',
      backgroundColor: '#7AD49D',
    },
    '100%': {
      border: '2px solid #46995A',
      backgroundColor: '#7AD49D',
      boxShadow: 'none',
    },
  },
  icon: {
    maxHeight: '30px',
    maxWidth: '30px',
    marginBottom: '8px',
  },
};

const AnswerOption = ({
  classes,
  answerText,
  handleAnswerClick,
  isChosen,
  iconName,
}) => (
  <Card
    elevation={4}
    className={classnames(classes.root, {
      [classes.active]: isChosen,
      [classes.smallCard]: !iconName,
    })}
    onClick={handleAnswerClick}
  >
    {iconName && (
      <Grid item xs className={classes.answerIcon}>
        <img
          className={classes.icon}
          src={require(`../../../../../images/new-fte/icons/${iconName}.png`)}
          alt={`${iconName} Option`}
          title={`${iconName} Option`}
        />
      </Grid>
    )}
    <Grid
      item
      xs
      className={classnames(classes.answerText, {
        [classes.centerVertical]: !iconName,
      })}
    >
      {answerText}
    </Grid>
  </Card>
);

AnswerOption.propTypes = {
  iconName: PropTypes.string,
  isChosen: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  answerText: PropTypes.string.isRequired,
  handleAnswerClick: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withHandlers({
    handleAnswerClick: ({
      answer,
      updateAnswers,
      questionId,
      questionType,
      index,
    }) => () => {
      updateAnswers(questionId, answer, questionType, index);
    },
  }),
  withProps(({ answer }) => ({
    answerText: get(answer, 'option.en', ''),
  }))
)(AnswerOption);
