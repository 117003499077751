import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import SignInPage from '../containers/sign-in/SignInPage';
import Item from './common/Item';
import branch from 'recompose/branch';
import compose from 'recompose/compose';
import renderComponent from 'recompose/renderComponent';
import { connect } from 'react-redux';

/* eslint-disable react/no-danger */

const IntroMarkup = ({ children }) => {
  const subHeader = AppConfig.getLingo('portalSummarySubHeading');

  const styles = {
    mainContainer: {
      width: '100%',
      color: Colors.light,
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '50px',
    },
    mainHeader: {
      fontSize: subHeader ? '30px' : '24px',
      margin: subHeader ? '0 0 20px' : '0 0 40px',
      color: Colors.black,
    },
    subHeader: {
      fontSize: '20px',
      lineHeight: '24px',
      maxWidth: '350px',
      margin: '0 auto 30px',
      color: Colors.black,
    },
    row: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '30px',
      flexWrap: 'wrap',
    },
    smallIcon: {
      color: Colors.hexToRgba(Colors.white, 0.81),
      backgroundColor: Colors.hexToRgba(Colors.mediumGray3, 0.65),
      width: '35px',
      height: '35px',
      fontSize: '35px',
      borderRadius: '50%',
      padding: '20px',
      margin: '0 15px 20px',
    },
    green: {
      backgroundColor: Colors.darkGreen,
    },
    navyBlue: {
      backgroundColor: Colors.darkNavyblue,
    },
    darkOrange: {
      backgroundColor: Colors.darkOrange,
    },
    purple: {
      backgroundColor: Colors.darkViolet,
    },
  };

  return (
    <div style={styles.mainContainer}>
      <h1 style={[GlobalStyles.extraLight, styles.mainHeader]}>
        <div
          dangerouslySetInnerHTML={{
            __html: AppConfig.getLingo('portalSummary'),
          }}
        />
      </h1>

      {subHeader && (
        <h2 style={[GlobalStyles.extraLight, styles.subHeader]}>
          <div dangerouslySetInnerHTML={{ __html: subHeader }} />
        </h2>
      )}

      <div style={styles.row}>
        <Item
          iconStyle={{ ...styles.smallIcon, ...styles.green }}
          iconClass="thin-0702_user_profile_avatar_woman_female"
          keep
        />
        <Item
          iconStyle={{ ...styles.smallIcon, ...styles.navyBlue }}
          iconClass="thin-1033_atom_nuclear_learning_physics"
          keep
        />
        <Item
          iconStyle={{ ...styles.smallIcon, ...styles.darkOrange }}
          iconClass="thin-0102_notebook_to_do_bullets_list"
          keep
        />
        <Item
          iconStyle={{ ...styles.smallIcon, ...styles.purple }}
          iconClass="thin-0657_medal_award_rating_prize_achievement"
          keep
        />
      </div>
      {children}
    </div>
  );
};

const spinnerWhileLoading = (isLoading) =>
  branch(isLoading, renderComponent(SignInPage));

const loadingStateToProps = (state) => ({
  loading: state.signIn.appLoading || state.signIn.appLoadFailed,
});

const isLoading = spinnerWhileLoading((props) => props.loading);

IntroMarkup.propTypes = {
  children: PropTypes.object.isRequired,
};

export default compose(connect(loadingStateToProps), isLoading, Radium)(
  IntroMarkup
);
