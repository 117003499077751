import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Button from '../common/Button';

const ExplanationMarkup = ({ onOpenEHS }) => {
  const styles = {
    pageSubTitle: {
      fontSize: '16px',
      fontWeight: 400,
      marginBottom: '8px',
      marginTop: '24px',
    },

    explanationSection: {
      paddingRight: '5%',
      marginBottom: '35px',
      paddingLeft: '75px',
    },

    explanationText: {
      fontSize: '16px',
      margin: '0px',
      color: Colors.navyBlue,
    },

    submitManualResultsLink: {
      color: Colors.linkBlue,
      textDecoration: 'underline',
      cursor: 'pointer',
    },

    manualSubmitWrapper: {
      display: 'inline-block',
    },

    manualSubmitNote: {
      textAlign: 'center',
    },
  };

  return (
    <div style={styles.explanationSection}>
      <h3 style={styles.pageSubTitle}>
        What Can I Expect at a Biometric Screening?
      </h3>

      <p style={styles.explanationText}>
        You will be asked to step on a scale to collect your weight while also
        being measured for your height, which will give the technician an
        estimate of your Body Mass Index (BMI). You will then have a blood
        pressure cuff placed around your arm to test for the pressure when the
        heart beats and when it rests between beats.
        <br />
        <br />
        The tip of your finger will be pricked to obtain a couple of droplets of
        blood, which will be run through a machine that tests for total
        cholesterol, the amount of "good" (HDL) cholesterol in your blood, and
        glucose, the level of blood sugar in your body. If you are required to
        fast for your test, plan to go without food for 12 hours ahead of time.
        Drinking plenty of water ahead of time is recommended.
        <br />
        <br />
        The biometric screening is not intended to replace a doctor's visit, and
        any results obtained should be shared with your healthcare provider.
      </p>

      <h3 style={styles.pageSubTitle}>
        What if I cannot attend an onsite biometric screening event?
      </h3>

      <p style={styles.explanationText}>
        If you cannot attend an onsite biometric screening event, you have the
        option to submit results from your physician. Follow the instructions
        below to submit results.
      </p>
      <div style={styles.explanationText}>
        <ol>
          <li>
            Click on{' '}
            <a onClick={onOpenEHS} style={styles.submitManualResultsLink}>
              Physician Screening Form
            </a>{' '}
            to navigate to our partner site, eHealth Screenings.
          </li>
          <li>
            After confirming your identity, follow the instructions on the
            screen to complete the form for submission during your doctor's
            visit.
          </li>
        </ol>
      </div>
      <div style={styles.manualSubmitWrapper}>
        <Button
          text="Physician Screening Form"
          style={styles.continueButton}
          onClick={onOpenEHS}
        />
      </div>
    </div>
  );
};

ExplanationMarkup.propTypes = {
  onOpenEHS: PropTypes.func.isRequired,
};

export default Radium(ExplanationMarkup);
