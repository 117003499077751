import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { TutorialActions } from '../../actions';
import { bindActionCreators } from 'redux';
import TutorialIntro from './TutorialIntro';

const styles = {
  contentContainer: {
    display: 'flex',
    flex: 1,
    flexBasis: 'auto',
  },

  overlayContainer: {
    position: 'absolute',
    top: 0,
    height: '100%',
    left: 0,
    right: 0,
    zIndex: 900001,
    backgroundColor: 'rgba(6, 12, 27, 0.85)',
  },
  overlayCloseButton: {
    position: 'fixed',
    right: 20,
    top: 20,
    height: '40px',
    width: '40px',
    zIndex: 900009,
    transform: 'translateX(-50%)',
    cursor: 'pointer',
    stroke: Colors.white,
  },
  stopClickOverlay: {
    position: 'absolute',
    top: 0,
    height: '100%',
    left: 0,
    right: 0,
    zIndex: 900007,
  },
};

function insertBlurContainer(container) {
  //we need to create the div the first time
  container = document.createElement('div');
  container.id = 'tutorialBlurContainer';
  //insert the blur container right after the App div
  const app = document.getElementById('app');
  if (app) {
    document.body.insertBefore(container, app.nextSibling);
  }
}

function cloneAndBlurOverlay(source, container) {
  window.scrollTo(0, 0);
  document.body.style.overflow = 'hidden';

  container.innerHTML = source.cloneNode(true).innerHTML;

  container.setAttribute(
    'style',
    `position: absolute; 
                                top: 0; 
                                bottom: 0; 
                                left:0; 
                                right:0;
                                z-index: 900000; 
                                -webkit-filter: blur(6px); 
                                -moz-filter: blur(6px); 
                                -o-filter: blur(6px); 
                                filter: blur(6px); `
  );
}

function clearBlurOverlay(container) {
  container.innerHTML = '';
  container.setAttribute('style', '');
}

class TutorialContainer extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    /**
     * This block of code copies all the elements directly form the DOM to a sibling div of the APP div
     * (outside the React DOM tree and workflow)
     * and set the content to blurry. This is the blurry overlay when you view the tutorial.
     *
     */
    let source = document.getElementById('cloneThisDivForTheTutorial');

    let container = document.getElementById('tutorialBlurContainer');

    if (!container) {
      insertBlurContainer(container);
    }

    if (container && source) {
      if (this.props.tutorial.show) {
        cloneAndBlurOverlay(source, container);
      } else {
        clearBlurOverlay(container);
      }
    }
  };

  componentDidUpdate = () => {
    let source = document.getElementById('cloneThisDivForTheTutorial');

    let container = document.getElementById('tutorialBlurContainer');

    if (!container) {
      insertBlurContainer(container);
    }

    if (container && source) {
      if (this.props.tutorial.show) {
        cloneAndBlurOverlay(source, container);
      } else {
        clearBlurOverlay(container);
      }
    }
  };

  render() {
    const closeButton = (
      <svg
        style={styles.overlayCloseButton}
        onClick={() => {
          document.body.style.overflow = 'auto';
          this.props.actions.hideTutorial();
        }}
      >
        <line x1="25%" x2="75%" y1="25%" y2="75%" strokeWidth="2" />
        <line x1="25%" x2="75%" y1="75%" y2="25%" strokeWidth="2" />
      </svg>
    );
    let tutorialContent = null;

    if (this.props.tutorial.show) {
      if (this.props.tutorial.page === 'intro') {
        tutorialContent = (
          <div style={styles.overlayContainer}>
            <TutorialIntro onContinueClick={this._onContinueClick} />
            <div style={styles.overlayCloseButton} />
            {closeButton}
          </div>
        );
      } else {
        tutorialContent = (
          <div>
            <div style={styles.overlayContainer} />
            <div style={styles.stopClickOverlay} />
            <div style={styles.overlayCloseButton} />
            {closeButton}
          </div>
        );
      }
    }

    return (
      <div style={styles.contentContainer}>
        {tutorialContent}
        <div id="cloneThisDivForTheTutorial" style={styles.contentContainer}>
          {this.props.children}
        </div>
      </div>
    );
  }

  _onContinueClick = () => {
    this.props.actions.setTutorialPage('healthOverview');
  };
}

TutorialContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  tutorial: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    page: PropTypes.string.isRequired,
  }),
  actions: PropTypes.shape({
    hideTutorial: PropTypes.func.isRequired,
    setTutorialPage: PropTypes.func.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    tutorial: state.tutorial,
  };
}

function mapDispatchToProps(dispatch) {
  const { hideTutorial, setTutorialPage } = TutorialActions;

  return {
    actions: bindActionCreators(
      {
        hideTutorial,
        setTutorialPage,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(TutorialContainer)
);
