import { last } from 'lodash/fp';

export const pageConfig = (defaultProps, defaultOptions) => (
  Component,
  props,
  options
) => ({
  Component,
  props: { ...defaultProps, ...props },
  options: { ...defaultOptions, ...options },
});

export const linkPages = (drawer, components) => {
  const [head, ...tail] = components;
  if (!head) return [];

  const getPage = (p, i) => ({
    ...p,
    props: {
      ...p.props,
      onBack: () => drawer.back(),
      pageIndex: i,
      pageCount: components.length,
    },
  });
  const setupOnNext = (page, fn) => {
    if (!page) return;

    const onNext = page.props.onNext;
    page.props.onNext = () => {
      if (onNext) onNext();
      fn();
    };
  };

  const linked = tail.reduce(
    (results, item) => {
      const lastPage = last(results);
      const page = getPage(item, results.length);
      setupOnNext(lastPage, () => {
        drawer.open(page.Component, page.props, page.options);
      });
      results.push(page);
      return results;
    },
    [getPage(head, 0)]
  );

  const lastLinkedPage = last(linked);
  lastLinkedPage.props.submitLabel = 'Finish';
  setupOnNext(lastLinkedPage, () => drawer.close());

  return linked;
};
