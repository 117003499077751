import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from './Typography';
import classnames from 'classnames';

const styles = (theme) => ({
  icon: {
    fontSize: '30px',
    color: theme.palette.common.planet9,
  },
});

const Title = ({ iconClass, title, subtitle, titleClass, subtitleClass }) => {
  return (
    <Grid container alignItems="center" spacing={8}>
      <Grid item>
        <i className={iconClass} />
      </Grid>
      <Grid item xs={10}>
        {title && (
          <Typography className={titleClass} type="h2">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography className={subtitleClass}>{subtitle}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  subtitleClass: PropTypes.string,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ classes, subtitleClass, iconClass, titleClass }) => {
    return {
      iconClass: classnames(classes.icon, iconClass),
      titleClass,
      subtitleClass,
    };
  })
)(Title);
