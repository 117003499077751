import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps, withHandlers } from 'recompose';
import moment from 'moment';
import { withTheme } from '@material-ui/core/styles';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import _ from 'lodash';
import {
  DrawerContent,
  DrawerSection,
  DrawerNavigation,
  DrawerButton,
  withDrawer,
} from '../../../../common/drawer2';
import StatusWidget from '../../../../common/widgets/StatusWidget';
import HealthActionDrawerView from './HealthActionDrawerView';
import CoachingSessions from './CoachingSessions';
import { WithCoachingPrograms } from '@healthmine/greyhound-core/src/hocs';

const CoachingProgram = ({
  actionCompletionPercent,
  actionLabel,
  compliant,
  enrollmentDate,
  incentiveCompletionPercent,
  incentiveLabel,
  openCoachingSessions,
  openHealthActionDrawerView,
  openProchange,
  programName,
  remainingActionCount,
  remainingIncentiveCount,
  requiredActivityCount,
  sessionsComplianceStatus,
  sessionsComplianceSubtitle,
  theme,
}) => {
  return (
    <DrawerContent title={programName}>
      <DrawerSection>
        <StatusWidget
          color={theme.palette.primary.main}
          status={remainingActionCount}
          statusNote={`action${
            remainingActionCount !== 1 ? 's' : ''
          } remaining`}
          percentage={actionCompletionPercent}
        />
      </DrawerSection>
      <DrawerSection>
        <StatusWidget
          color={theme.palette.common.whiskeySour}
          status={remainingIncentiveCount}
          statusNote={`${incentiveLabel} available to earn`}
          percentage={incentiveCompletionPercent}
        />
      </DrawerSection>
      <DrawerNavigation
        title="Register for health coaching"
        status="positive"
        subtitle={`completed on ${moment(enrollmentDate).format('MM/DD/YYYY')}`}
        onClick={openHealthActionDrawerView}
      />
      <DrawerNavigation
        title={`Complete ${requiredActivityCount} coaching sessions`}
        status={sessionsComplianceStatus}
        subtitle={sessionsComplianceSubtitle}
        incentivesToEarn={!compliant}
        onClick={openCoachingSessions}
      />
      <DrawerSection grow />
      <DrawerButton text={actionLabel} onClick={openProchange} />
    </DrawerContent>
  );
};

CoachingProgram.propTypes = {
  actionCompletionPercent: PropTypes.number.isRequired,
  actionLabel: PropTypes.string.isRequired,
  compliant: PropTypes.bool.isRequired,
  enrollmentDate: PropTypes.string.isRequired,
  incentiveCompletionPercent: PropTypes.number.isRequired,
  incentiveLabel: PropTypes.string.isRequired,
  openCoachingSessions: PropTypes.func.isRequired,
  openHealthActionDrawerView: PropTypes.func.isRequired,
  openProchange: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired,
  remainingActionCount: PropTypes.number.isRequired,
  remainingIncentiveCount: PropTypes.number.isRequired,
  requiredActivityCount: PropTypes.number.isRequired,
  sessionsComplianceStatus: PropTypes.string.isRequired,
  sessionsComplianceSubtitle: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
  pure,
  withDrawer,
  WithCoachingPrograms,
  withProps(({ coachingPrograms, programId }) => {
    const program = _.find(coachingPrograms, { programId });
    const {
      actionCompletionPercent,
      actionLabel,
      availabilityDescription,
      compliantActivityCount,
      compliant,
      compliantActivityDate,
      enrollmentDate,
      incentiveCompletionPercent,
      programName,
      programStatusDescription,
      registrationStatusDescription,
      remainingActionCount,
      remainingIncentiveCount,
      requiredActivityCount,
      programRewardAmount,
      ssoLink,
    } = program;

    const sessionsComplianceStatus = compliant ? 'positive' : 'neutral';
    const sessionsComplianceSubtitle = compliant
      ? `completed on ${moment(compliantActivityDate).format('MM/DD/YYYY')}`
      : IncentiveSummary.getIncentiveEarningText(false, programRewardAmount);
    return {
      actionCompletionPercent,
      actionLabel,
      availabilityDescription,
      compliantActivityCount,
      compliant,
      enrollmentDate,
      incentiveCompletionPercent,
      incentiveLabel: IncentiveSummary.incentiveLabel(),
      programName,
      programStatusDescription,
      registrationStatusDescription,
      remainingActionCount,
      remainingIncentiveCount,
      requiredActivityCount,
      sessionsComplianceStatus,
      sessionsComplianceSubtitle,
      ssoLink,
    };
  }),
  withHandlers({
    openHealthActionDrawerView: ({
      drawer,
      enrollmentDate,
      registrationStatusDescription,
    }) => () => {
      const healthAction = {
        longDescription: registrationStatusDescription,
        completed: true,
        lastCompletedOn: enrollmentDate,
        incentiveAmount: 100,
        title: 'Register for health coaching',
      };
      return drawer.open(HealthActionDrawerView, { healthAction });
    },
    openCoachingSessions: ({
      availabilityDescription,
      compliantActivityCount,
      compliant,
      drawer,
      programStatusDescription,
      requiredActivityCount,
      sessionsComplianceStatus,
      sessionsComplianceTitle,
    }) => () =>
      drawer.open(CoachingSessions, {
        availabilityDescription,
        compliantActivityCount,
        compliant,
        programStatusDescription,
        requiredActivityCount,
        sessionsComplianceStatus,
        sessionsComplianceTitle,
      }),

    openProchange: ({ startSsoProchange, ssoLink, programId }) => () =>
      startSsoProchange(() => window.open(ssoLink), programId),
  }),
  withTheme()
)(CoachingProgram);
