import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  CompletionStatus,
  BiometricState,
} from '@healthmine/greyhound-core/src/constants/CarePlan';
import { selectBiometricStatus } from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';

const styles = {
  callToAction: {
    color: '#1E80F0',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
  },
};

const biometricCaption = (status) => {
  if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)) {
    return BiometricState.SCREENING_NOTSTARTED;
  }

  switch (status) {
    case CompletionStatus.COMPLETED: {
      return BiometricState.SCREENING_COMPLETED;
    }
    case CompletionStatus.STARTED: {
      return BiometricState.SCREENING_STARTED;
    }
    case CompletionStatus.NOTSTARTED: {
      return BiometricState.SCREENING_NOTSTARTED;
    }
    default: {
      return null;
    }
  }
};

const biometricRoute = (biometricStatus) => {
  const baseRoute = '/biometric-screening';
  if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO))
    return `${baseRoute}/events`;

  return biometricStatus === CompletionStatus.STARTED
    ? `${baseRoute}/appointment`
    : biometricStatus === CompletionStatus.COMPLETED
      ? `${baseRoute}/results`
      : baseRoute;
};

const Preventive = ({ biometricCaption, goToBioScreenings }) => (
  <a onClick={goToBioScreenings} style={styles.callToAction}>
    {biometricCaption}
  </a>
);

Preventive.propTypes = {
  biometricCaption: PropTypes.string,
  goToBioScreenings: PropTypes.func,
  biometricStatus: PropTypes.string,
};

export default compose(
  withRouter,
  connect((state) => {
    const biometricStatus = selectBiometricStatus(state);

    return {
      biometricCaption: biometricCaption(biometricStatus),
      biometricStatus,
    };
  }),
  withHandlers({
    goToBioScreenings: ({ router, biometricStatus }) => () =>
      router.push(biometricRoute(biometricStatus)),
  }),
  Radium
)(Preventive);
