import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import ProgressBar from './ProgressBar';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  bar: {
    maxWidth: '200px',
  },
});

const StatusWidget = ({
  classes,
  color,
  status,
  statusNote,
  percentage,
  noBar = false,
  alignment = 'center',
}) => {
  return (
    <div className={classes.root} style={{ textAlign: alignment }}>
      <Typography type="h1" color={color}>
        {status}
      </Typography>
      <Typography type="h4" secondary>
        {statusNote}
      </Typography>
      {!noBar && (
        <ProgressBar
          className={classes.bar}
          percentage={percentage}
          color={color}
        />
      )}
    </div>
  );
};

StatusWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  statusNote: PropTypes.string.isRequired,
  noBar: PropTypes.bool,
  percentage: PropTypes.number,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
};

export default withStyles(styles)(StatusWidget);
