import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { Grid, withStyles } from '@material-ui/core';
import List, {
  ListHeader,
  ListContent,
  ListItem,
} from '../../common/list2/List';
import MyNotes from './MyNotes';
import _concat from 'lodash/concat';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _reject from 'lodash/reject';
import ChecklistItem from './ChecklistItem';
import Typography from '../../common/Typography';
import {
  WithPreventiveActions,
  WithChronicActions,
} from '@healthmine/greyhound-core/src/hocs';
import HealthAction from './HealthAction';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';

const styles = (theme) => ({
  incentiveIcon: {
    fontSize: '25px',
    color: theme.palette.common.whiskeySour,
  },
});

const HealthActions = ({
  classes,
  listColor,
  healthActions,
  showIncentive,
  incentiveLabel,
}) => {
  return (
    <Grid container direction="column" spacing={8}>
      {showIncentive && (
        <Grid item>
          <Grid container alignItems="center" spacing={8}>
            <Grid item>
              <i className={`icon-Star-Circle ${classes.incentiveIcon}`} />
            </Grid>
            <Grid item>
              <Typography color="blueGray">
                {incentiveLabel} eligible
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <List disabled={!healthActions.length}>
          <ListHeader title="Health Actions to Complete" color={listColor} />
          <ListContent>
            {_map(healthActions, (action, idx) => {
              return (
                <ListItem key={`health_action_${idx}`}>
                  <ChecklistItem>
                    <HealthAction name={action.title} incentivized />
                  </ChecklistItem>
                  <MyNotes />
                </ListItem>
              );
            })}
          </ListContent>
        </List>
      </Grid>
    </Grid>
  );
};

HealthActions.propTypes = {
  classes: PropTypes.object.isRequired,
  listColor: PropTypes.string.isRequired,
  healthActions: PropTypes.array.isRequired,
  showIncentive: PropTypes.bool.isRequired,
  incentiveLabel: PropTypes.string.isRequired,
};

export default compose(
  WithPreventiveActions,
  WithChronicActions,
  withProps(({ preventiveActions, chronicActions }) => {
    const { incentiveType } = AppConfig.effectiveConfig;

    const showIncentive = incentiveType !== AppConstants.IncentiveType.NONE;

    const incentiveLabel =
      AppConfig.effectiveConfig.incentiveType ===
      AppConstants.IncentiveType.REWARDS
        ? 'Rewards'
        : 'Points';

    const docVisits = _get(
      _find(chronicActions, { code: 'DOCTORS_VISIT' }),
      'childActions',
      []
    );

    const chronicNoDoc = _reject(chronicActions, { code: 'DOCTORS_VISIT' });
    return {
      healthActions: _reject(
        _concat(chronicNoDoc, docVisits, preventiveActions),
        { completed: true }
      ),
      showIncentive,
      incentiveLabel,
    };
  }),
  withStyles(styles)
)(HealthActions);
