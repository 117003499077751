import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    padding: '20px',
    borderBottom: `1px solid ${theme.palette.common.formulaOne}`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  clickable: {
    cursor: 'pointer',
    transition: theme.globalStyles.transition1,
    '&:hover': {
      background: theme.palette.common.formulaOne,
    },
  },
  grow: {
    flexGrow: 1,
  },
  shrink: {
    flexShrink: 0,
  },
  noBorder: {
    border: 'none',
  },
  noPadding: {
    padding: 0,
  },
});

const DrawerSection = ({
  rootElement: RootElement,
  classes,
  children,
  onClick,
  className,
  grow,
  noBorder,
  noPadding,
  ...props
}) => {
  const rootClass = classNames(
    classes.root,
    className,
    grow ? classes.grow : classes.shrink,
    {
      [classes.clickable]: !!onClick,
      [classes.noBorder]: noBorder,
      [classes.noPadding]: noPadding,
    }
  );
  return (
    <RootElement {...props} className={rootClass} onClick={onClick}>
      {children}
    </RootElement>
  );
};

DrawerSection.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  grow: PropTypes.bool,
  noBorder: PropTypes.bool,
  noPadding: PropTypes.bool,
  onClick: PropTypes.func,
  rootElement: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ onClick }) => ({
    rootElement: onClick ? 'a' : 'div',
  }))
)(DrawerSection);
