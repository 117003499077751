import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { FteCommunicationDemoActions } from '@healthmine/greyhound-core/src/actions';
import { DrawerSectionHeader } from '../../common/drawer2';
import DrawerContent from '../DrawerContent';
import Picker from '../Picker';

const PREFERENCE_OPTIONS = [
  { label: 'Email', value: 'email' },
  { label: 'Text (SMS)', value: 'sms' },
  { label: 'In-App Notification', value: 'push' },
  { label: 'Phone', value: 'phone' },
];

const FREQUENCY_OPTIONS = [
  { label: 'Less than once a week', value: '1' },
  { label: 'Once a week', value: '2' },
  { label: 'Several times a week', value: '3' },
  { label: 'Once a day', value: '4' },
  { label: 'Several times a day', value: '5' },
];

export { PREFERENCE_OPTIONS, FREQUENCY_OPTIONS };

const CommunicationDrawer = ({
  color,
  onNext,
  communicationPreferences,
  toggleCommunicationPreference,
  communicationFrequency,
  setCommunicationFrequency,
  ...props
}) => (
  <DrawerContent
    {...props}
    color={color}
    submitLabel="Almost Done"
    onSubmit={onNext}
    disableSubmit={false}
  >
    <DrawerSectionHeader title="Select One or More Options" />

    <Picker
      multiple
      color={color}
      value={communicationPreferences}
      options={PREFERENCE_OPTIONS}
      onClick={toggleCommunicationPreference}
    />

    <DrawerSectionHeader title="How Often Do You Want Us to Keep You Updated?" />

    <Picker
      color={color}
      value={communicationFrequency}
      options={FREQUENCY_OPTIONS}
      onClick={setCommunicationFrequency}
    />
  </DrawerContent>
);

CommunicationDrawer.propTypes = {
  color: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  communicationPreferences: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleCommunicationPreference: PropTypes.func.isRequired,
  communicationFrequency: PropTypes.string,
  setCommunicationFrequency: PropTypes.func.isRequired,
};

export default compose(
  connect(
    (state) => state.fteCommunicationDemo,
    (dispatch) => bindActionCreators(FteCommunicationDemoActions, dispatch)
  ),

  withProps({
    color: 'mintJulep',

    hero: {
      title: 'What is the best way to contact you?',
      description: 'Help us communicate with you on your terms.',
      background: 'points',
    },
  })
)(CommunicationDrawer);
