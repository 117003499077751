import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import {
  DrawerContent,
  DrawerSection,
  withDrawer,
} from '../../../../common/drawer2';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../../../../common/Typography';
import classnames from 'classnames';
import MedicationRefillReport from './MedicationRefillReport';
import { addRefill } from '../../../../../../modules/demo/refills/actions';
import { get as _get } from 'lodash';

const medTypes = {
  LOVASTATIN: {
    isOverDue: false,
    refillText: 'Your refill is due 6/25/19',
    title: 'Statin Medication',
    subTitle: 'Lovastatin (Altoprev)',
    description: `Taking medications recommended by your physician is a critical step in
    maintaining your health. To complete this health action, refill your
    statin prescription at your local or mail order pharmacy. If you have
    any questions about your medications, please contact your physician or
    care management team.`,
  },
  QVAR: {
    isOverDue: true,
    refillText: 'A refill was due on 3/15/2019',
    title: 'Asthma Medication',
    subTitle: 'Beclometasone dipropionate (Qvar)',
    description: `Taking medications recommended by your physician is a critical step in
    maintaining your health. To complete this health action, refill your
    asthma prescription at your local or mail order pharmacy. If you have
    any questions about your medications, please contact you physician or
    care management team.`,
  },
};

const styles = (theme) => ({
  bgPurple: {
    background: theme.palette.common.venus,
    color: theme.palette.common.white,
  },
  header: {
    padding: '20px 0px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginTop30: {
    marginTop: '30px',
  },
  button: {
    padding: '10px',
    cursor: 'pointer',
    borderRadius: theme.globalStyles.borderRadius1,
  },
  refillContainer: {
    border: `1px solid ${theme.palette.common.venus}`,
    borderRadius: theme.globalStyles.borderRadius1,
  },
  refillHeader: {
    padding: '10px',
  },
  refillBody: {
    padding: '10px',
  },
  underline: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  mainButton: {
    background: theme.palette.common.planet9,
    color: theme.palette.common.white,
  },
  secondaryButton: {
    background: '#D9D9D9',
    //color: theme.palette.common.blueGray,
  },
});

const MedicationHealthAction = ({
  classes,
  openLastRefillReport,
  refillDates,
  medType,
}) => {
  const med = _get(medTypes, medType);
  return (
    <DrawerContent>
      <div className={classnames(classes.bgPurple, classes.header)}>
        {_get(med, 'isOverDue') && (
          <Typography center type="h4Medium">
            Your refill is overdue
          </Typography>
        )}
        <Typography center type="h4Medium">
          {med.refillText}
        </Typography>
        <Typography center type="h1">
          {med.title}
        </Typography>
        <Typography center>{med.subTitle}</Typography>
      </div>
      <DrawerSection>
        <Typography>{med.description}</Typography>
        <Typography className={classes.marginTop10}>
          Learn more about prescription drug coverage and potential savings
          opportunities by logging into MyAvocadoHealth.com and clicking on the
          Pharmacy tab.
        </Typography>
        <div
          className={classnames(
            classes.button,
            classes.mainButton,
            classes.marginTop30
          )}
        >
          <Typography center>Visit MyAvocado to Learn How</Typography>
        </div>
        <a className={classes.marginTop10} onClick={openLastRefillReport}>
          <Typography center className={classes.underline}>
            Already refill your prescription?
          </Typography>
        </a>
        <Typography type="h3Medium" className={classes.marginTop30}>
          Refill History
        </Typography>
        {refillDates.map(
          ({
            refillDate,
            refillSupply,
            refillName,
            refillPrescriber,
            refillAmount,
          }) => (
            <div
              className={classnames(
                classes.marginTop10,
                classes.refillContainer
              )}
              key={refillDate}
            >
              <div
                className={classnames(classes.bgPurple, classes.refillHeader)}
              >
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography type="h3Medium">{refillDate}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography type="h3Medium">$25 Rewarded</Typography>
                  </Grid>
                </Grid>
              </div>
              <div className={classnames(classes.refillBody)}>
                <div>
                  <Typography type="h3Medium">{refillName}</Typography>
                  <Typography>
                    {refillSupply} Day Supply {refillAmount}
                  </Typography>
                </div>

                <div className={classes.marginTop10}>
                  <Typography>Prescribed by:</Typography>
                  <Typography type="h3Medium">{refillPrescriber}</Typography>
                  <Typography>555-555-5555</Typography>
                </div>

                <div className={classes.marginTop10}>
                  <Typography>Filled by:</Typography>
                  <Typography type="h3Medium">CVS Pharmacy</Typography>
                  <Typography>555-555-5555</Typography>
                  <Typography>12423 Royal Lane</Typography>
                  <Typography>Dallas, TX 75729</Typography>
                </div>
              </div>
            </div>
          )
        )}
      </DrawerSection>
    </DrawerContent>
  );
};

MedicationHealthAction.propTypes = {
  classes: PropTypes.object,
  openLastRefillReport: PropTypes.func.isRequired,
  refillDates: PropTypes.array,
  medType: PropTypes.string,
};

export default compose(
  withDrawer,
  connect(
    (state, { medType }) => ({
      refillDates: state.demo.refills[medType],
    }),
    {
      addRefill,
    }
  ),
  withProps(({ drawer, addRefill, medType }) => ({
    openLastRefillReport: () =>
      drawer.open(MedicationRefillReport, {
        refillType: medType,
        addRefill,
      }),
  })),
  withStyles(styles)
)(MedicationHealthAction);
