import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { isEmpty } from 'lodash/fp';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DrawerHeaderAction from './DrawerHeaderAction';
import DrawerSection from './DrawerSection';
import withDrawer from './withDrawer';
import StatusTitle from '../StatusTitle';
import Typography from '../Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    color: theme.palette.primary.main,
  },
  title: {
    textAlign: 'center',
  },
  hide: {
    display: 'none',
  },
});

const DrawerContent = ({
  classes,
  className,
  children,
  drawer,
  title,
  titleSectionProps,
  headerAction,
  headerSectionProps,
  subtitle,
  iconClass,
  titleClass,
  subtitleClass,
  status,
  showActions,
  incentivesToEarn,
  id,
}) => {
  const { stack, back, close } = drawer;
  const lhsActionProps = isEmpty(stack)
    ? { onClick: close, label: 'Close' }
    : { onClick: back, label: 'Back' };
  return (
    <div id={id} className={classnames(classes.root, className)}>
      <DrawerSection {...headerSectionProps}>
        <Grid container justify="space-between">
          {showActions && (
            <Grid item>
              <DrawerHeaderAction {...lhsActionProps} />
            </Grid>
          )}
          <Grid item>
            {headerAction && <DrawerHeaderAction {...headerAction} />}
          </Grid>
        </Grid>
      </DrawerSection>
      {title && (
        <DrawerSection {...titleSectionProps}>
          {status ? (
            <StatusTitle
              title={title}
              subtitle={subtitle}
              status={status}
              iconClass={iconClass}
              titleClass={titleClass}
              subtitleClass={subtitleClass}
              incentivesToEarn={incentivesToEarn}
            />
          ) : (
            <Typography type="h3" className={classes.title}>
              {title}
            </Typography>
          )}
        </DrawerSection>
      )}
      {children}
    </div>
  );
};

DrawerContent.defaultProps = {
  showActions: true,
  titleSectionProps: {},
  headerSectionProps: {},
};

DrawerContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  iconClass: PropTypes.string,
  titleClass: PropTypes.string,
  subtitleClass: PropTypes.string,
  status: PropTypes.oneOf(['positive', 'neutral', 'negative']),
  drawer: PropTypes.shape({
    stack: PropTypes.array.isRequired,
    back: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  }).isRequired,
  titleSectionProps: PropTypes.object.isRequired,
  headerAction: PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.element.isRequired,
    ]),
    onClick: PropTypes.func.isRequired,
  }),
  headerSectionProps: PropTypes.object.isRequired,
  showActions: PropTypes.bool.isRequired,
  incentivesToEarn: PropTypes.bool,
  id: PropTypes.string,
};

export default compose(withDrawer, withStyles(styles))(DrawerContent);
