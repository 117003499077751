import _ from 'lodash';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import { CarePlanActionItem } from '@healthmine/greyhound-core/src/constants/prop-types';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import ActionItem from './ActionItem';
import Styles from './MyCarePlan.styles';
import CompliancePeriodSelector from './CompliancePeriodSelector';

const MyCarePlanEnrolledMarkup = ({
  coverageRelationshipCd,
  programName,
  actionItems,
  toggleDetails,
  expandedActionItemIndex,
}) => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const maxWidth = fiveFiveUI ? '800px' : '900px';

  return (
    <div style={{ maxWidth }}>
      {_.includes(
        [
          AppConstants.Relationships.SUBSCRIBER,
          AppConstants.Relationships.SPOUSE,
        ],
        coverageRelationshipCd
      ) || !AppConfig.validateFeature(Features.SUBSCRIBER_ONLY_DASHBOARD) ? (
        <div>
          <div style={Styles.title}>
            <span style={Styles.programLabel}>{programName}</span>
            <CompliancePeriodSelector />
          </div>

          <div style={Styles.actions}>
            {actionItems.map((actionItem, actionItemIndex) => (
              <ActionItem
                key={`ActionItem${actionItemIndex}`}
                actionItem={actionItem}
                actionItemIndex={actionItemIndex}
                areDetailsVisible={actionItemIndex === expandedActionItemIndex}
                toggleDetails={toggleDetails}
              />
            ))}
          </div>
        </div>
      ) : (
        <div>
          <div style={Styles.title}>Program Enrollment</div>

          <p style={Styles.message}>
            You are enrolled in the program, but are not required to complete
            health actions. Refer to the <br />
            <a href="/#/plan-information" style={Styles.button}>
              Program Information
            </a>{' '}
            for more information.
          </p>
        </div>
      )}
    </div>
  );
};

MyCarePlanEnrolledMarkup.propTypes = {
  coverageRelationshipCd: PropTypes.string,
  programName: PropTypes.string.isRequired,
  actionItems: PropTypes.arrayOf(CarePlanActionItem).isRequired,
  toggleDetails: PropTypes.func.isRequired,
  expandedActionItemIndex: PropTypes.number.isRequired,
};

export default Radium(MyCarePlanEnrolledMarkup);
