import React from 'react';
import { IndexRoute, Redirect, Route, IndexRedirect } from 'react-router';
import _ from 'lodash';

import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';

import App from './containers/App';
import IntroMarkup from './components/IntroMarkup';
import SignInPage from './containers/sign-in/SignInPage';

import VerifyMfaPage from './containers/mfa/VerifyMfaPage';
import MfaTotpEnrollmentPage from './containers/mfa/MfaTotpEnrollmentPage';

import CarePlansPage from './components/care-plans/CarePlansPage';
import CarePlanTracking from './components/care-plan-tracking/CarePlanTracking';
import UnifiedCarePlanTracking from './components/unified-care-plan-tracking/UnifiedCarePlanTracking';
import PersonalHealthAssistant from './components/pha/PersonalHealthAssistant';
import PlanInformation from './components/plan-information/PlanInformation';

import * as HRA from './components/hra';

import MyID from './components/settings/MyID';
import Prescriptions from './components/settings/Prescriptions';
import SettingsAccount from './components/settings/SettingsAccount';
import SettingsDevices from './components/settings/SettingsDevices';
import SettingsCoverage from './components/settings/SettingsCoverage';
import Messages from './components/settings/Messages';

import BuildDiabetes from './components/care-plan-build/Diabetes';
import BuildPhysicalActivity from './components/care-plan-build/PhysicalActivity';

import OnboardingContainer from './containers/onboarding/OnboardingContainer';
import OnboardingPage from './containers/onboarding/OnboardingPage';
import IntroductionContainer from './containers/onboarding/IntroductionContainer';

import * as Sweepstakes from './components/sweepstakes';
import RegisterContainer from './containers/registration/RegisterContainer';
import RegistrationIntroPage from './containers/registration/RegistrationIntroPage';
import RegistrationBasicInfoPage from './containers/registration/RegistrationBasicInfoPage';
import RegistrationOptInPage from './containers/registration/RegistrationOptInPage';
import RegistrationCreateProfilePage from './containers/registration/RegistrationCreateProfilePage';
import SelectConfirmCoverage from './components/registration/SelectConfirmCoverage';
import RetrieveAccountPage from './containers/registration/RetrieveAccountPage';
import ResetPasswordPage from './containers/registration/ResetPasswordPage';
import ReturnToLoginMarkup from './components/registration/ReturnToLoginMarkup';
import PostPasswordResetPageMarkup from './components/registration/PostPasswordResetPageMarkup';
import ProductGroupPageMarkup from './components/registration/ProductGroupPageMarkup';
import LearnMorePage from './components/registration/LearnMorePage';
import SubscriptionsPage from './components/settings/SubscriptionsPage';

import ViewDocument from './components/common/ViewDocument';

import AppointmentDetail from './containers/biometric-screening/AppointmentDetail';
import EventDetail from './containers/biometric-screening/EventDetail';
import BiometricReport from './containers/biometric-screening/BiometricReport';
import FAQPage from './components/faq/FAQPage';

import Authentication from './containers/Authentication';
import SessionTimeoutPage from './components/sign-in/SessionTimeoutPage';
import SsoErrorPage from './containers/sign-in/SsoErrorPage';

import Demofy from './leapfrog/views/pages/demofy/Demofy';

import {
  UserIsAuthenticated,
  UserIsNotAuthenticated,
  UserIsOnboard,
  UserIsNotOnboard,
  SsoDisabled,
} from './auth';
import {
  SweepstakesPage,
  RewardsPage,
  InfoPage,
  PrivacyPage,
  TermsPage,
  SettingsPage,
  MinimalHeaderAuth,
  BioMetricScreeningsPage,
  InfoPageUhcBranded,
  SettingsPageUhcBranded,
} from './containers/Pages';

import MyDataPage from './components/data/MyDataPage';

const getRouteConfig = () => {
  const biometricScreenings = AppConfig.validateFeature(
    Features.BIOMETRIC_SCREENINGS
  );

  const showIdCard = AppConfig.validateFeature(Features.ID_CARD);

  const usesConnectedDevices = !AppConfig.validateFeature(
    Features.NO_CONNECTED_DEVICES
  );

  const usesCoverageSelection = !AppConfig.validateFeature(
    Features.NO_COVERAGE_SELECTION
  );

  const usesHealthLibrary = !AppConfig.validateFeature(
    Features.NO_HEALTH_LIBRARY
  );

  const usesHra = !AppConfig.validateFeature(Features.NO_HRA);

  const usesIncentives =
    AppConfig.effectiveConfig.incentiveType !== AppConstants.IncentiveType.NONE;

  const usesOnboarding = !AppConfig.validateFeature(Features.NO_ONBOARDING);

  const usesPlanInformation = AppConfig.validateFeature(
    Features.PLAN_INFORMATION
  );

  const usesProductGroupDemo = AppConfig.validateFeature(
    Features.PRODUCT_GROUP_DEMO
  );

  const usesRegistrationOptIn = !!AppConfig.getLingo(
    'registrationOptInContentHtml'
  );

  const usesSingleCarePlan = AppConfig.validateFeature(
    Features.SINGLE_CARE_PLAN
  );

  const unifiedCarePlan = AppConfig.validateFeature(Features.UNIFIED_CARE_PLAN);

  const usesLearnMore = AppConfig.validateFeature(Features.LEARN_MORE);

  const uhcBranded = AppConfig.validateFeature(Features.UHC_BRANDED);

  const { lingo } = AppConfig.effectiveConfig;

  const isFooterLinkInternal = (caption) => {
    const footerLink = _.find(AppConfig.effectiveConfig.footerLinks, (link) =>
      new RegExp(`^${caption}`, 'i').test(link.caption)
    );

    return footerLink
      ? _.isString(footerLink.route)
        ? !/^https?:\/\//.test(footerLink.route)
        : !/^https?:\/\//.test(_.get(footerLink, 'route.noAuth'))
      : true;
  };

  return (
    <Route path="/" component={App}>
      <Route component={UserIsNotAuthenticated}>
        <Route component={RegisterContainer}>
          <Route component={SsoDisabled}>
            <Route component={IntroMarkup}>
              <IndexRoute
                component={
                  usesProductGroupDemo
                    ? ProductGroupPageMarkup
                    : RegistrationIntroPage
                }
              />
              <Route path="welcome" component={RegistrationIntroPage} />
            </Route>

            <Route path="signin" component={SignInPage} />

            <Route path="signin/verify-mfa" component={VerifyMfaPage} />
            <Route
              path="signin/mfa-enrollment/totp"
              component={MfaTotpEnrollmentPage}
            />

            {usesLearnMore && (
              <Route path="learn-more" component={LearnMorePage} />
            )}
            <Route path="registration">
              <Route component={IntroMarkup}>
                <IndexRoute component={RegistrationIntroPage} />
              </Route>
              <Route
                path="basic-information"
                component={RegistrationBasicInfoPage}
              />
              {usesRegistrationOptIn && (
                <Route path="opt-in" component={RegistrationOptInPage} />
              )}
              <Route
                path="create-profile"
                component={RegistrationCreateProfilePage}
              />
            </Route>

            <Route path="return" component={ReturnToLoginMarkup} />
            <Route path="retrieve-account" component={RetrieveAccountPage} />
          </Route>
          <Route path="reset-password" component={ResetPasswordPage} />
          <Route
            path="reset-password-success"
            component={PostPasswordResetPageMarkup}
          />
          <Route path="subscriptions" component={SubscriptionsPage} />
          <Route path="public">
            <Redirect
              from="contact"
              to="view-document"
              query={{
                title: 'Contact Us',
                documentFile: 'contact_us_en.html',
              }}
            />

            <Redirect
              from="privacy"
              to="view-document"
              query={{
                iconClass: 'thin-0043_eye_visibility_show_visible',
                title: 'Privacy Policy',
                documentFile: 'privacy_policy_en.html',
              }}
            />

            <Redirect
              from="terms"
              to="view-document"
              query={{
                title: 'Terms of Use',
                documentFile: 'terms_of_use_en.html',
              }}
            />

            <Route path="view-document" component={ViewDocument} />
          </Route>
        </Route>
      </Route>

      <Route path="sso-error" component={SsoErrorPage} />
      <Route path="timeout" component={SessionTimeoutPage} />

      <Redirect
        from="contact"
        to="view-document"
        query={{
          title: 'Contact Us',
          documentFile: 'contact_us_en.html',
        }}
      />

      <Redirect
        from="incentive-terms"
        to="view-document"
        query={{
          title: 'Sweepstakes',
          documentFile: 'incentive_terms_en.html',
        }}
      />
      <Route component={UserIsAuthenticated}>
        <Route component={Authentication}>
          <Route component={uhcBranded ? InfoPageUhcBranded : InfoPage}>
            <Route path="faq" component={FAQPage} label="FAQ" />
            {isFooterLinkInternal('privacy') && (
              <Route
                path="privacy"
                component={PrivacyPage}
                label="Privacy Policy"
              />
            )}
            {isFooterLinkInternal('terms') && (
              <Route path="terms" component={TermsPage} label="Terms of Use" />
            )}
          </Route>
          <Route path="view-document" component={ViewDocument} />
        </Route>
        {usesCoverageSelection && (
          <Route
            path="select-confirm-coverage"
            component={SelectConfirmCoverage}
          />
        )}
        <Route component={UserIsOnboard}>
          <Route component={Authentication}>
            {AppConfig.validateFeature(Features.DEMOFY) && (
              <Route path="demofy" component={Demofy} />
            )}
            <Route path={'health-overview'} />
            {usesHealthLibrary ? (
              <Route path="pha" component={PersonalHealthAssistant}>
                <Route path=":contentId" component={PersonalHealthAssistant} />
              </Route>
            ) : null}

            {usesHra && (
              <Route path="hra" component={HRA.HealthRiskAssessment} />
            )}
            {usesHra && (
              <Route path="hra-completion" component={HRA.CompletionPage} />
            )}
            {usesHra && (
              <Route
                path="hra-result"
                component={HRA.HealthRiskAssessmentResult}
              />
            )}
            {usesHra && (
              <Route
                path="hra-summary"
                component={HRA.HealthRiskAssessmentSummary}
              />
            )}

            {usesPlanInformation && (
              <Route path="plan-information" component={PlanInformation} />
            )}

            {biometricScreenings ? (
              <Route
                path="biometric-screening"
                component={BioMetricScreeningsPage}
              >
                <IndexRedirect to="events" />
                <Route
                  path="events"
                  component={EventDetail}
                  label="Schedule a Screening"
                />
                <Route
                  path="appointment"
                  component={AppointmentDetail}
                  label="View Appointments"
                />
                <Route
                  path="results"
                  component={BiometricReport}
                  label="View Results"
                />
              </Route>
            ) : (
              <Redirect from="biometric-screening*" to="health-overview" />
            )}

            {showIdCard ? (
              <Route path="id" component={MyID} />
            ) : (
              <Redirect from="id" to="health-overview" />
            )}

            <Route path="prescriptions" component={Prescriptions}>
              <Route path=":page" component={Prescriptions} />
            </Route>

            <Route
              path="settings"
              component={uhcBranded ? SettingsPageUhcBranded : SettingsPage}
            >
              <IndexRedirect to="account" />
              <Route
                path="account"
                component={SettingsAccount}
                label="Manage Account"
              />
              {usesConnectedDevices && (
                <Route
                  path="devices"
                  component={SettingsDevices}
                  label="My Devices"
                />
              )}
              {usesCoverageSelection && (
                <Route
                  path="coverage"
                  component={SettingsCoverage}
                  label={`My ${lingo.coverageLabel}`}
                />
              )}
            </Route>

            <Route path="messages" component={Messages}>
              <Route path=":page" component={Messages} />
            </Route>

            {usesIncentives && (
              <Route path="sweepstakes" component={SweepstakesPage}>
                <IndexRoute component={Sweepstakes.Shop} />
                <Route path="shop" default label="Enter to Win">
                  <IndexRoute component={Sweepstakes.Shop} />
                  <Route path=":page" component={Sweepstakes.Shop} />
                </Route>

                <Route path="entries" label="Currently Entered">
                  <IndexRoute component={Sweepstakes.Entries} />
                  <Route path=":page" component={Sweepstakes.Entries} />
                </Route>

                <Route path="summary">
                  <IndexRoute component={Sweepstakes.Summary} containerless />
                  <Route
                    path=":page"
                    component={Sweepstakes.Summary}
                    containerless
                  />
                </Route>

                <Route path="history" label="Previous">
                  <IndexRedirect to="1" />
                  <Route path=":page" component={Sweepstakes.History}>
                    <Route path="details">
                      <IndexRoute component={Sweepstakes.Details} />
                      <Route path=":id" component={Sweepstakes.Details} />
                    </Route>
                  </Route>
                </Route>

                <Redirect from="details/:id" to="history/1/details/:id" />

                <Route path="entries">
                  <IndexRoute component={Sweepstakes.Entries} />
                  <Route path=":page" component={Sweepstakes.Entries} />
                </Route>
              </Route>
            )}

            {usesIncentives && (
              <Route path="rewards" component={RewardsPage}>
                <IndexRoute component={Sweepstakes.Shop} />
                <Route path="shop" default label="Shop">
                  <IndexRoute component={Sweepstakes.Shop} />
                  <Route path=":page" component={Sweepstakes.Shop} />
                </Route>

                <Route path="history" label="History">
                  <IndexRoute component={Sweepstakes.History} />
                  <Route path=":page" component={Sweepstakes.History} />
                </Route>

                <Route path="checkout">
                  <IndexRoute component={Sweepstakes.CheckoutSummary} />
                  <Route
                    path="checkout"
                    component={Sweepstakes.CheckoutSummary}
                  />
                </Route>

                <Route path="order-summary">
                  <IndexRoute component={Sweepstakes.OrderSummary} />
                  <Route
                    path="order-summary"
                    component={Sweepstakes.OrderSummary}
                  />
                </Route>

                <Route path="shopping-cart">
                  <IndexRoute component={Sweepstakes.ShoppingCart} />
                  <Route
                    path="shopping-cart"
                    component={Sweepstakes.ShoppingCart}
                  />
                </Route>

                <Route path="automated-rewards">
                  <IndexRoute component={Sweepstakes.AutomatedRewards} />
                  <Route
                    path="automated-rewards"
                    component={Sweepstakes.AutomatedRewards}
                  />
                </Route>

                <Route path="summary">
                  <IndexRoute component={Sweepstakes.Summary} containerless />
                  <Route
                    path=":page"
                    component={Sweepstakes.Summary}
                    containerless
                  />
                </Route>
              </Route>
            )}

            {usesConnectedDevices && (
              <Route
                path="data"
                components={{ main: MyDataPage, sidebar: 'none' }}
              />
            )}
            {!usesSingleCarePlan && (
              <Route
                path="care-plan/:subject(/:actionId)"
                components={
                  unifiedCarePlan
                    ? UnifiedCarePlanTracking
                    : { main: CarePlanTracking, sidebar: 'none' }
                }
              />
            )}
            {!usesSingleCarePlan && (
              <Redirect
                from="care-plan-build/PREVENTIVE"
                to="care-plan/preventive"
              />
            )}
            {!usesSingleCarePlan && (
              <Redirect
                from="care-plan-build/PHYSICAL_ACTIVITY"
                to="care-plan-build/physical-activity"
              />
            )}
            {!usesSingleCarePlan && (
              <Route
                path="care-plan-build/physical-activity"
                components={{ main: BuildPhysicalActivity, sidebar: 'none' }}
              />
            )}
            {!usesSingleCarePlan && (
              <Route
                path="care-plan-build/diabetes"
                components={{ main: BuildDiabetes, sidebar: 'none' }}
              />
            )}
            {!usesSingleCarePlan && (
              <Route
                path="care-plans"
                components={{ main: CarePlansPage, sidebar: 'none' }}
              />
            )}
          </Route>
        </Route>
        {usesOnboarding && (
          <Route component={UserIsNotOnboard}>
            <Route component={MinimalHeaderAuth}>
              <Route
                path="introduction"
                components={{ main: OnboardingContainer, sidebar: 'none' }}
              >
                <IndexRoute component={IntroductionContainer} />
                <Route path="onboarding" component={OnboardingPage} />
              </Route>
            </Route>
          </Route>
        )}
      </Route>
    </Route>
  );
};

export { getRouteConfig };
