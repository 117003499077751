import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withProps } from 'recompose';
import _ from 'lodash';
import moment from 'moment';
import { withSweepstakes } from '@healthmine/greyhound-core/src/hocs';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
  withDrawer,
} from '../../../common/drawer2';
import { commaFormat } from '@healthmine/greyhound-core/src/utils';
import PreviousEntry from './PreviousEntry';
import StatusWidget from '../../../common/widgets/StatusWidget';
import PreviousEntryView from './PreviousEntryView';

const PreviousEntryList = ({
  closedSweepstakes,
  totalEntries,
  totalWins,
  selectSweepstake,
  title,
}) => {
  return (
    <DrawerContent title={title}>
      <DrawerSectionHeader title="Summary" />
      <DrawerSection>
        <StatusWidget
          status={commaFormat(totalEntries)}
          statusNote="your total entries"
          noBar
        />
      </DrawerSection>
      <DrawerSection>
        <StatusWidget
          status={commaFormat(totalWins)}
          statusNote="your total wins"
          noBar
        />
      </DrawerSection>
      <DrawerSectionHeader title="History" />
      {_.map(closedSweepstakes, (item, idx) => {
        const title = item.sweepstakeName;
        const status = item.memberWins > 0 ? 'positive' : 'negative';
        const id = item.sweepstakeId;
        const subtitle = `closed on ${moment(item.closeDate).format(
          'MM/DD/YYYY'
        )}`;
        return (
          <PreviousEntry
            key={`prev_entry_item_${idx}`}
            title={title}
            status={status}
            subtitle={subtitle}
            id={id}
            selectSweepstake={selectSweepstake}
          />
        );
      })}
    </DrawerContent>
  );
};

PreviousEntryList.propTypes = {
  closedSweepstakes: PropTypes.array.isRequired,
  totalEntries: PropTypes.number.isRequired,
  totalWins: PropTypes.number.isRequired,
  selectSweepstake: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default compose(
  pure,
  withSweepstakes,
  withDrawer,

  withHandlers({
    selectSweepstake: ({
      closedSweepstakes,
      getClosedSweepstakeWinners,
      drawer,
    }) => (sweepstakeId) => {
      const sweepstake = _.find(closedSweepstakes, {
        sweepstakeId,
      });
      getClosedSweepstakeWinners(sweepstakeId);
      drawer.open(PreviousEntryView, {
        title: _.get(sweepstake, 'sweepstakeName', ''),
        memberWins: _.get(sweepstake, 'memberWins', 0),
        closeDate: _.get(sweepstake, 'closeDate', ''),
        memberEntries: _.get(sweepstake, 'memberEntries', 0),
      });
    },
  }),

  withProps(({ closedSweepstakes }) => {
    const totalEntries = _.sumBy(closedSweepstakes, 'memberEntries');
    const totalWins = _.sumBy(closedSweepstakes, 'memberWins');

    return {
      totalEntries,
      totalWins,
    };
  })
)(PreviousEntryList);
