import React from 'react';
import PropTypes from 'prop-types';
import Svg from '@healthmine/greyhound-core/src/images/Svg';
import InputElement from 'react-input-mask';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { assign } from 'lodash';

const FloatingLabel = ({
  id,
  placeHolder,
  value,
  onChange,
  onBlur,
  type,
  isValid,
  required,
  mask,
  isDisabled,
  inputWrapper,
  name,
  autoComplete,
}) => {
  const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);

  const group = {
    position: 'relative',
    marginBottom: '15px',
  };

  const tick = {
    height: '25px',
    width: '20px',
    display: 'inline-block',
    position: 'absolute',
    right: 0,
    bottom: '5px',
  };

  const inputContainerError = {
    borderBottom: '2px solid red',
  };

  const errorLog = isValid === false ? inputContainerError : null;

  return (
    <div style={inputWrapper ? inputWrapper : null}>
      <div style={group}>
        {mask === undefined ? (
          <input
            className={value ? 'inputMaterial hasValue' : 'inputMaterial'}
            style={assign(errorLog, leapFrogUI && { height: '30px' })}
            id={id}
            type={type}
            value={value}
            onBlur={onBlur}
            disabled={isDisabled}
            onChange={onChange}
            required={required}
            name={name}
            autoComplete={autoComplete}
          />
        ) : (
          <InputElement
            className="inputMaterial"
            id={id}
            type={type}
            onBlur={onBlur}
            mask={mask}
            maskChar=""
            value={value}
            onChange={onChange}
            required={required}
            style={isValid === false ? inputContainerError : null}
            name={name}
            autoComplete={autoComplete}
          />
        )}
        <span className="highlight" />
        <span className="bar" />
        <label className="floating">{placeHolder}</label>
        {isValid ? <span style={tick}>{Svg.CHECKMARK}</span> : null}
      </div>
    </div>
  );
};

FloatingLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isValid: PropTypes.bool,
  required: PropTypes.bool,
  mask: PropTypes.string,
  inputWrapper: PropTypes.object,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
};
FloatingLabel.defaultProps = {
  value: '',
  type: 'text',
  required: true,
};

export default FloatingLabel;
