import React from 'react';
import TopPicks from './top-picks/TopPicks';
import Faq from './faq/FAQ';

const Help = () => {
  return (
    <div>
      <TopPicks />
      <Faq />
    </div>
  );
};

export default Help;
