import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import Checkpoint from './Checkpoint';
import Complete from './Complete';
import ActionableAction, { types } from '../actions/ActionableAction';

const InProgress = ({
  carePlan,
  selectCarePlan,
  activateTracking,
  viewEducation,
  dispatch,
}) => {
  if (carePlan.checkpoint)
    return <Checkpoint selectCarePlan={selectCarePlan} />;
  if (carePlan.actions.length === 0) return <Complete />;

  return (
    <div>
      {_.take(
        _.slice(carePlan.actions, carePlan.primaryAction ? 1 : 0).filter(
          (action) => action.manual !== false
        ),
        2
      ).map((action, index) => {
        if (action.type === types.EDUCATION) {
          return (
            <ActionableAction
              key={`action-${index}`}
              type={action.type}
              contentId={action.contentId}
              takeAction={viewEducation}
            >
              {_.get(action, 'text')}
            </ActionableAction>
          );
        } else if (/^COACHING:/.test(action.type)) {
          return (
            <ActionableAction
              key={`action-${index}`}
              type={action.type}
              takeAction={() => action.action(dispatch, window.open)}
            >
              {_.get(action, 'text')}
            </ActionableAction>
          );
        }

        return (
          <ActionableAction
            key={`action-${index}`}
            type={action.type}
            takeAction={activateTracking}
          >
            {_.get(action, 'text')}
          </ActionableAction>
        );
      })}
    </div>
  );
};

InProgress.propTypes = {
  carePlan: PropTypes.shape({
    primaryAction: PropTypes.object,
    actions: PropTypes.array,
    checkPoint: PropTypes.bool,
  }),
  dispatch: PropTypes.func.isRequired,
  selectCarePlan: PropTypes.func,
  activateTracking: PropTypes.func,
  viewEducation: PropTypes.func,
  contentId: PropTypes.string,
};

export default compose(
  connect(null, (dispatch) => ({ dispatch })),
  withRouter,
  withHandlers({
    viewEducation: ({ router }) => (contentId) =>
      router.push(`/pha/${contentId}`),
  })
)(InProgress);
