import React from 'react';
import classnames from 'classnames';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import AppTheme from '@healthmine/greyhound-core/src/modules/app-themes/LeapFrog';
import globalStyles from '../styles/GlobalStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from './common/drawer2/Drawer';
import Footer from './footer/Footer';
import Announcements from '../../components/Announcements/Announcements';

export const theme = createMuiTheme({
  globalStyles: {
    ...globalStyles,
  },
  utils: {
    ...AppTheme.utils,
  },
  palette: {
    common: {
      ...AppTheme.colors,
    },
    primary: {
      main: AppTheme.colors.planet9,
    },
    secondary: {
      main: AppTheme.colors.blueGray,
    },
    text: {
      primary: AppTheme.colors.planet9,
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: AppTheme.colors.backdropOverlay,
      },
    },
    MuiPaper: {
      root: {
        fontSize: globalStyles.baseFontSize,
        fontFamily: globalStyles.baseFont,
      },
      rounded: {
        borderRadius: globalStyles.borderRadius1,
      },
    },
    MuiInput: {
      root: {
        color: AppTheme.colors.planet9,
      },
    },
  },
});

const App = compose(
  pure,
  withStyles((theme) => ({
    root: {
      background: theme.palette.common.formulaOne,
      fontFamily: theme.globalStyles.baseFont,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.primary.main,
      fontSize: theme.globalStyles.baseFontSize,
    },
    impersonationOffset: {
      paddingTop: '30px',
    },
  })),
  connect((state) => ({
    isImpersonating: !!get('signIn.impersonationEmail', state),
  }))
)(({ children, classes, isImpersonating }) => (
  <div
    className={classnames(classes.root, {
      [classes.impersonationOffset]: isImpersonating,
    })}
  >
    <Announcements />
    <CssBaseline />
    {children}
    <Footer />
    <Drawer
      className={classnames({
        [classes.impersonationOffset]: isImpersonating,
      })}
    />
  </div>
));

/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
export default ({ children, location }) => (
  <MuiThemeProvider theme={theme}>
    <App children={children} location={location} />
  </MuiThemeProvider>
);
