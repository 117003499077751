import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Scroll from 'react-scroll';
import moment from 'moment';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import * as CarePlanSelector from '@healthmine/greyhound-core/src/selectors/CarePlanSelector';
import CarePlans from '@healthmine/greyhound-core/src/modules/CarePlans';
import CarePlanActions from '@healthmine/greyhound-core/src/actions/CarePlanActions';
import CarePlanTitleMarkup from './CarePlanTitleMarkup';
import PostConfigurationMarkup from './PostConfigurationMarkup';
import DateStep from './steps/DateStep';
import FitnessStep from './steps/tracking/FitnessStep';
import EducationStep from './steps/EducationStep';
import Redirect from '../common/Redirect';

const config = {
  id: 'PHYSICAL_ACTIVITY',
  name: 'Be More Physically Active',
  description: `Let's begin by helping you create a plan to be more physically active.`,
  duration: 84,
  iconClass: 'thin-0839_apple_watch_heart_fitness_running_tracker',
};

const scroller = Scroll.scroller;

const getScrollId = (index) => `CarePlanBuild-${index}`;

const styles = {
  container: {
    flex: 1,
    marginBottom: '120px',
  },
};

const PhysicalActivity = ({
  maxDate,
  nextStep,
  previousStep,
  saving,
  setFitness,
  setStartDate,
  startDate,
  start,
  status,
  step,
}) => {
  if (!(saving || status === AppConstants.PlanStatus.ASSIGNED)) {
    return <Redirect to={`/care-plan/physical-activity`} />;
  }
  return (
    <div style={styles.container}>
      <CarePlanTitleMarkup
        continueHandler={() => nextStep(1)}
        init={step === 0}
        scrollId={getScrollId(0)}
        {...config}
      />
      <DateStep
        active={step === 1}
        duration={config.duration}
        previousStep={() => previousStep(0)}
        maxDate={maxDate}
        nextStep={() => nextStep(2)}
        scrollId={getScrollId(1)}
        setStartDate={setStartDate}
        startDate={startDate}
      />
      <EducationStep
        active={step === 2}
        name="Physical Activity"
        nextStep={() => nextStep(3)}
        previousStep={() => previousStep(1)}
        recurring
        scrollId={getScrollId(2)}
      />
      <FitnessStep
        active={step === 3}
        previousStep={() => previousStep(2)}
        last
        nextStep={() => nextStep(4)}
        required
        scrollId={getScrollId(3)}
        setTracking={setFitness}
      />
      {step === 4 && (
        <PostConfigurationMarkup
          isSavingConfiguration={saving}
          backHandler={() => previousStep(3)}
          onConfigurePlan={start}
        />
      )}
    </div>
  );
};

PhysicalActivity.propTypes = {
  maxDate: PropTypes.string.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  setFitness: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  start: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
};

export default compose(
  connect(
    (state) => CarePlanSelector.selectBuildPhysicalActivity(state),
    (dispatch) => {
      const { startMyGoal } = CarePlanActions;

      return {
        actions: bindActionCreators({ startMyGoal }, dispatch),
      };
    }
  ),
  withState('step', 'setStep', 0),
  withState('startDate', 'setStartDate', moment().format('YYYY-MM-DD')),
  withState('fitness', 'setFitness'),
  withState('saving', 'setSaving', false),
  withRouter,
  withHandlers({
    nextStep: ({ step, setStep }) => (nextStepIdx) => {
      if (nextStepIdx <= 4 && nextStepIdx !== step) {
        const previousScrollId = getScrollId(step);

        const offset = -(
          document.getElementsByName(previousScrollId)[0].offsetHeight - 70
        );

        setStep(nextStepIdx);
        if (nextStepIdx !== 4) {
          scroller.scrollTo(getScrollId(nextStepIdx), {
            offset,
            duration: 1000,
            smooth: true,
          });
        }
      }
    },
    previousStep: ({ step, setStep }) => (previousStepIndex) => {
      if (previousStepIndex >= 0 && previousStepIndex !== step) {
        setStep(previousStepIndex);
        scroller.scrollTo(getScrollId(previousStepIndex), {
          offset: -100,
          duration: 1000,
          smooth: true,
        });
      }
    },

    start: ({ actions, id, router, startDate, setSaving, fitness }) => () => {
      const request = CarePlans.requestForBuilding({
        startDate,
        tracking: [fitness],
      });

      setSaving(true);
      actions
        .startMyGoal(id, request)
        .then(() => router.replace('/health-overview'))
        .catch(() => setSaving(false));
    },
  })
)(PhysicalActivity);
