import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, pure, withProps } from 'recompose';
import { FirstTimeExperienceConstants } from '@healthmine/greyhound-core/src/constants';
import { Grid, withStyles } from '@material-ui/core';
import DrawerContent from '../DrawerContent';
import Title from '../../common/Title';
import { DrawerSection, DrawerSectionHeader } from '../../common/drawer2';

const styles = (theme) => ({
  subTitle: {
    color: theme.palette.common.blueGray,
  },
  icon: {
    color: theme.palette.common.mintJulep,
  },
  earnings: {
    color: theme.palette.common.whiskeySour,
  },
  alignRight: {
    textAlign: 'right',
  },
});

const { title, description, items } = FirstTimeExperienceConstants.tracking;

const TrackingDrawer = ({ classes, items, onNext, ...props }) => (
  <DrawerContent {...props} onSubmit={onNext}>
    <DrawerSectionHeader title="Meet Your Trackers" />
    {items.map(({ title, subTitle, iconClass, earnings }, i) => (
      <DrawerSection key={i}>
        <Grid container>
          <Grid item xs={9}>
            <Title
              title={title}
              subtitle={subTitle}
              subtitleClass={classes.subTitle}
              iconClass={iconClass}
            />
          </Grid>
          <Grid item xs={3}>
            <div className={classes.alignRight}>
              <div className={classes.earnings}>{earnings}</div>
              <div className={classes.subTitle}>every day</div>
            </div>
          </Grid>
        </Grid>
      </DrawerSection>
    ))}
  </DrawerContent>
);

TrackingDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ classes }) => ({
    color: 'mintJulep',
    hero: { title, description, background: 'tracking' },
    items: items.map((x) => ({
      ...x,
      iconClass: classnames(`icon-${x.icon}`, classes.icon),
    })),
  }))
)(TrackingDrawer);
