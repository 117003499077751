import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  GlobalStyles,
  Colors,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';

const PageTitle = ({ title, icon, iconStyle, titleChild, uhcIcon }) => {
  const styles = {
    pageTitle: {
      color: Colors.bodyText,
      paddingLeft: '75px',
      fontSize: '20px',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    icon: {
      display: 'flex',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '50%',
      color: 'white',
      width: '76px',
      height: '76px',
      fontWeight: '100',
      fontSize: '40px',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '0 0 auto',
      ...iconStyle,
    },

    titleText: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '26px',
      margin: '0px 0px 0px 15px',
    },

    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '25px',
      justifyContent: 'space-between',
    },
  };

  const uhcBranded = AppConfig.validateFeature(Features.UHC_BRANDED);

  const showUchIcon = uhcBranded && uhcIcon;

  return (
    <div style={styles.titleWrapper}>
      <div style={[styles.pageTitle, GlobalStyles.light]}>
        <span style={styles.icon}>
          {showUchIcon ? <img src={uhcIcon} /> : <i className={icon} />}
        </span>
        <div style={styles.titleText}>{title}</div>
      </div>
      {titleChild}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleChild: PropTypes.element,
  icon: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
  uhcIcon: PropTypes.string,
};

PageTitle.defaultProps = {
  iconStyle: {
    backgroundColor: Colors.navyBlue,
  },
};

export default Radium(PageTitle);
