import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import Measurements from '@healthmine/greyhound-core/src/modules/Measurements';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import DeviceConfiguration from './DeviceConfiguration';
import Step from '../Step';

const FREQUENCY = 2;

const TrackingStep = ({
  scrollId,
  active,
  next,
  skip,
  previousStep,
  manual,
  manualClicked,
  connectedClicked,
  last,
  type,
  required,
}) => {
  const styles = {
    container: {
      marginTop: '60px',
      paddingLeft: '100px',
    },
  };

  const measurement = _.get(Measurements.MeasurementTypes, type, {});

  const copy = _.get(localizedContent.measurements, [type, 'measurement'], {});

  return (
    <Step
      scrollId={scrollId}
      active={active}
      last={last}
      nextStep={next}
      skipStep={skip}
      previousStep={previousStep}
      title={measurement.displayName}
      iconClass={measurement.icon}
      summaryLines={[`Frequency: ${FREQUENCY}x Per Week`]}
      required={!!required}
      color={measurement.color}
      valid={manual !== undefined}
    >
      <div style={styles.container}>
        <DeviceConfiguration
          copy={copy}
          manual={manual}
          frequency={FREQUENCY}
          manualClicked={manualClicked}
          connectedClicked={connectedClicked}
        />
      </div>
    </Step>
  );
};

TrackingStep.propTypes = {
  scrollId: PropTypes.string.isRequired,
  previousStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setTracking: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  last: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    Measurements.MeasurementKeys.SLEEP,
    Measurements.MeasurementKeys.NUTRITION,
    Measurements.MeasurementKeys.WEIGHT,
    Measurements.MeasurementKeys.GLUCOSE,
    Measurements.MeasurementKeys.BLOOD_PRESSURE,
  ]).isRequired,

  manual: PropTypes.bool,
  setManual: PropTypes.func,
  manualClicked: PropTypes.func,
  connectedClicked: PropTypes.func,
  next: PropTypes.func,
  skip: PropTypes.func,
};

export default compose(
  withState('manual', 'setManual', undefined),
  withHandlers({
    manualClicked: ({ setManual }) => () => setManual(true),
    connectedClicked: ({ setManual }) => () => setManual(false),
    next: ({ nextStep, setTracking, type }) => () => {
      setTracking(type);
      nextStep();
    },
    skip: ({ nextStep, setTracking }) => () => {
      setTracking(null);
      nextStep();
    },
  }),
  Radium
)(TrackingStep);
