import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hashHistory } from 'react-router';
import autobind from 'autobind-decorator';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import { rewardsSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import AutomatedRewardsMarkup from './AutomatedRewardsMarkup';

const PRODUCTS_PER_ROW = 3;

class AutomatedRewards extends React.Component {
  constructor(props) {
    super(...arguments);

    const { address } = props;

    this.state = {
      editAddress: false,
      values: [],
      cardSelected: {},
      address,
      deliveryMethod: null,
      addressError: '',
      rewardSelectionComplete: false,
      requestFailed: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.editAddress) this.setState({ address: nextProps.address });
  }

  render() {
    const { rewards, name, emailAddress, registration = false } = this.props;

    const { address, values, deliveryMethod } = this.state;

    const allProducts = this._getProducts(rewards);
    return (
      <AutomatedRewardsMarkup
        products={allProducts}
        address={address}
        name={name}
        editAddress={this._toggleAddress}
        isEditable={this.state.editAddress}
        changeAddress={this._changeAddress}
        onOptionSelected={this._onOptionSelected}
        selectedOption={values}
        selectedCard={this.state.cardSelected}
        updateAddress={this._updateAddress}
        addressError={this.state.addressError}
        updateRewardsPreference={this._updateRewardsPreference}
        onDeliveryMethodSelected={this._onDeliveryMethodSelected}
        deliveryMethod={deliveryMethod}
        emailAddress={emailAddress}
        automatedModalOpen={this.props.modalOpen}
        toggleAutomatedModal={this.onRequestCancel}
        rewardSelectionComplete={this.state.rewardSelectionComplete}
        requestFailed={this.state.requestFailed}
        registration={registration}
      />
    );
  }

  @autobind
  _getProducts(products) {
    let productsCopy = [...products];

    let productsArr = [];

    while (productsCopy.length > 0) {
      productsArr.push(productsCopy.splice(0, PRODUCTS_PER_ROW));
    }
    return productsArr;
  }

  @autobind
  _toggleAddress() {
    const editAddress = !this.state.editAddress;
    if (editAddress === false) {
      this.setState({ address: this.props.address, addressError: '' });
    }
    return this.setState({ editAddress });
  }

  @autobind
  _changeAddress(event) {
    const currAddress = this.state.address;

    const address = { ...currAddress, [event.target.id]: event.target.value };
    this.setState({ address, requestFailed: false });
  }

  @autobind
  _updateRewardsPreference() {
    const productId = this.state.cardSelected.productId;

    const deliveryMethod = this.state.deliveryMethod;
    this.props.actions
      .updateRewardsPreference(productId, deliveryMethod)
      .then(() => {
        if (this.props.registration) {
          return hashHistory.push('/health-overview');
        }
        this.setState({ rewardSelectionComplete: true });
      })
      .catch(() => {
        this.setState({ requestFailed: true });
      });
  }

  @autobind
  _updateAddress() {
    const address = this.state.address || {};

    if (!address.streetAddr1 || !address.city || !address.zip) {
      this.setState({
        addressError: `${!address.streetAddr1 ? '"Street address line 1"' : ''}
          ${!address.streetAddr1 && (!address.city || !address.zip) ? ', ' : ''}
          ${!address.city ? '"City"' : ''}
          ${!address.city && !address.zip ? ', ' : ''}
          ${!address.zip ? '"ZIP Code" ' : ''}
          can not be empty.`,
      });
    } else {
      this.props.actions.updateAddressLocally(address);
      this.setState({
        editAddress: false,
        addressError: '',
        requestFailed: false,
      });
    }
  }

  @autobind
  _onOptionSelected(value, card) {
    let deliveryMethod = null;

    if (card.isPhysical !== card.isDigital) {
      deliveryMethod = card.isDigital
        ? RewardsConstants.RewardDeliveryForm.DIGITAL
        : RewardsConstants.RewardDeliveryForm.PHYSICAL;
    }

    this.setState({
      values: [value],
      cardSelected: card,
      deliveryMethod,
      requestFailed: false,
    });
  }

  @autobind
  _onDeliveryMethodSelected(e) {
    this.setState({
      deliveryMethod: e.target.value,
      editAddress: false,
      requestFailed: false,
    });
  }

  @autobind
  onRequestCancel() {
    this.props.toggleModal();
    this.setState({ rewardSelectionComplete: false, requestFailed: false });
  }
}

AutomatedRewards.propTypes = {
  actions: PropTypes.object.isRequired,
  address: PropTypes.object,
  name: PropTypes.string,
  modalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  rewards: PropTypes.array,
  emailAddress: PropTypes.string,
  registration: PropTypes.bool,
};

function mapStateToProps(state) {
  const rewards = rewardsSelector(state);

  const address = state.rewards.preference;

  const name = `${state.userInfo.firstName} ${state.userInfo.lastName}`;

  const emailAddress = state.userInfo.emailAddress;

  return {
    address,
    rewards,
    name,
    emailAddress,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getRewards,
    getRewardsPreference,
    updateAddressLocally,
    updateRewardsPreference,
  } = RewardsActions;

  return {
    actions: bindActionCreators(
      {
        getRewards,
        getRewardsPreference,
        updateAddressLocally,
        updateRewardsPreference,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedRewards);
