import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { HelloUserMarkup, TheJourneyMarkup } from '../../components/onboarding';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';

class IntroductionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onFirstPage: true,
    };
  }
  render() {
    let page = (
      <HelloUserMarkup
        onContinueClick={this._onContinueClick}
        firstName={this.props.firstName}
      />
    );

    if (!this.state.onFirstPage) {
      page = <TheJourneyMarkup onGetStartedClick={this._onGetStartedClick} />;
    }

    return <div>{page}</div>;
  }

  _onContinueClick = () => {
    if (AppConfig.validateFeature(Features.NO_INTRODUCTION)) {
      this.props.router.push('/introduction/onboarding');
    } else {
      this.setState({ onFirstPage: false });
    }
  };

  _onGetStartedClick = () => {
    this.props.router.push('/introduction/onboarding');
  };
}

IntroductionContainer.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  firstName: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    firstName: state.userInfo.firstName,
  };
}

export default compose(connect(mapStateToProps), withRouter)(
  IntroductionContainer
);
