import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import moment from 'moment';

const BiometricEventMarkup = ({
  event,
  index,
  activeId,
  activeLat,
  activeLng,
  onHover,
  onLeave,
  onClick,
}) => {
  const styles = {
    eventItem: {
      fontSize: '16px',
      padding: '10px',
      marginBottom: '20px',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: Colors.spacerGray,
      },
    },

    eventItemActive: {
      backgroundColor: Colors.spacerGray,
    },

    eventSection: {
      color: Colors.navyBlue,
      marginBottom: '5px',
      fontSize: '16px',
    },

    eventLink: {
      color: Colors.linkBlue,
      fontWeight: 500,
      fontSize: '16px',
      marginTop: '5px',
      display: 'flex',
      alignItems: 'center',
    },

    eventIcon: {
      fontSize: '20px',
      paddingRight: '10px',
    },
  };

  const startTime = moment(event.startTime);

  const endTime = moment(event.endTime);

  const isActive =
    (!activeId || event.eventId === activeId) &&
    event.latitude === activeLat &&
    event.longitude === activeLng;

  return (
    <div
      style={[styles.eventItem, isActive ? styles.eventItemActive : null]}
      onMouseOver={() =>
        onHover(event.eventId, event.latitude, event.longitude)
      }
      onMouseLeave={onLeave}
      onClick={onClick}
    >
      <span>{`${index + 1}. ${event.location}`}</span>
      <br />
      {!!event.roomNumber && (
        <div style={styles.eventSection}>
          <span>Room: {event.roomNumber}</span>
        </div>
      )}
      <div style={styles.eventSection}>
        <span>{event.address}</span>
        <br />
        <span>{`${event.city}, ${event.state} ${event.zipCode}`}</span>
      </div>
      <span>Day & Time:</span>
      <br />
      <span style={styles.eventSection}>{`${startTime.format(
        'l'
      )}, ${_formatDate(startTime)} - ${_formatDate(endTime)}`}</span>
      <br />
      <div style={styles.eventLink}>
        <span style={styles.eventIcon}>
          <i className="thin-0575_out_exit_emergency_logout" />
        </span>
        <span>Book Now</span>
      </div>
    </div>
  );
};

function _formatDate(date) {
  return `${date.format('h:mm')}${date.format('a')[0]}`;
}

BiometricEventMarkup.propTypes = {
  event: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  activeId: PropTypes.number,
  activeLat: PropTypes.number,
  activeLng: PropTypes.number,
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
  onClick: PropTypes.func,
};

export default Radium(BiometricEventMarkup);
