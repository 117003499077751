import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import SummaryMarkup from './SummaryMarkup';
import {
  selectAllIncentiveTransactionSummary,
  selectCurrentIncentiveSummary,
} from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import { hashHistory } from 'react-router';
import { selectAvailableCompliancePeriods } from '@healthmine/greyhound-core/src/selectors/CoverageSelector';
import _ from 'lodash';

const PRODUCTS_PER_PAGE = 10;

class Summary extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      selectedStrategyId: 0,
    };
  }

  render = () => {
    const selectedStrategyId =
      this.state.selectedStrategyId ||
      _.get(this, 'props.compliancePeriods[0].employerIncentiveStrategyId');

    const { rewardsSummary, incentiveSummary } = this.props;

    const pageParam = parseInt(this.props.params.page);

    const currPage = pageParam ? pageParam : 1;

    const filteredSummary = _.filter(
      rewardsSummary,
      (transaction) => transaction.strategyId === selectedStrategyId
    );

    const summary = filteredSummary.slice(
      (currPage - 1) * PRODUCTS_PER_PAGE,
      currPage * PRODUCTS_PER_PAGE
    );

    const pages = Math.ceil(filteredSummary.length / PRODUCTS_PER_PAGE);

    const totalPoints = _.get(incentiveSummary, 'totalPoints', 0);

    return (
      <SummaryMarkup
        summary={summary}
        currPage={currPage}
        pages={pages}
        total={totalPoints}
        compliancePeriods={this.props.compliancePeriods}
        selectedStrategyId={selectedStrategyId}
        onSelection={this._filterHistory}
      />
    );
  };

  _filterHistory = (filter) => {
    //filter the history transactions
    this.setState({
      selectedStrategyId: parseInt(filter.target.value),
    });
    hashHistory.replace(
      `/${AppConfig.effectiveConfig.incentiveType.toLowerCase()}/summary/1`
    );
  };
}

Summary.propTypes = {
  params: PropTypes.object,
  rewardsSummary: PropTypes.array,
  incentiveSummary: PropTypes.object,
  compliancePeriods: PropTypes.array,
};

function mapStateToProps(state) {
  const incentiveSummary = selectCurrentIncentiveSummary(state);

  const rewardsSummary = selectAllIncentiveTransactionSummary(state);

  const compliancePeriods = selectAvailableCompliancePeriods(state);

  return {
    rewardsSummary,
    compliancePeriods,
    incentiveSummary,
  };
}

export default connect(mapStateToProps)(Summary);
