import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  ErrorConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import BottomNavButtons from '../../components/common/BottomNavButtons';
import FloatingLabel from '../common/FloatingLabel';
import Loading from '../sign-in/Loading';

const CreateProfileMarkup = ({
  formState,
  actions,
  errors,
  onClickHandler,
  validateField,
  validatedFields,
  registrationIsInProgress,
}) => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);
  const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);
  const enableSMSOptIn = AppConfig.validateFeature(Features.SMS_OPT_IN);
  const enableContactPreference = AppConfig.validateFeature(
    Features.CONTACT_PREFERENCE
  );

  const clientName = AppConfig.effectiveConfig.clientName;

  const newStyles = {
    mainContainer: {
      fontSize: '16px',
      display: 'flex',
      justifyContent: 'center',
    },

    card: {
      background: '#fff',
      padding: '40px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 6px 0px',
      marginBottom: '24px',
    },

    inputWrapper: {
      clear: 'both',
      marginTop: '10px',
    },

    bottomLinks: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '60px',
      marginBottom: '60px',
    },

    inputContainer: {
      float: 'left',
      marginRight: '10px',
      marginTop: '20px',
      marginBottom: '10px',
      position: 'relative',
    },

    mainHeader: {
      fontSize: '24px',
      margin: '0 0 5px',
      color: Colors.black,
    },

    subHeaderText: {
      margin: '0 0 20px',
      color: Colors.black,
      maxWidth: '520px',
      fontSize: '16px',
    },

    imageSpacing: {
      marginRight: '20px',
      marginTop: '20px',
    },

    iconImage: {
      fontSize: '35px',
      color: Colors.white,
      backgroundColor: Colors.bodyText,
      borderRadius: '50%',
      padding: '20px',
    },

    title: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '30px',
    },

    formContainer: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
    },

    helpText: {
      color: 'red',
      fontSize: '16px',
      display: 'block',
      position: 'absolute',
      top: '45px',
    },

    para: {
      maxWidth: '594px',
      paddingTop: '0px',
      margin: '20px 0px',
      lineHeight: '15px',
      fontSize: '16px',
    },

    errorMessageWrapper: {
      color: 'red',
      fontWeight: '600',
      fontSize: '16px',
      marginTop: '40px',
    },
    errorMessage: {},

    agreement: {
      marginBottom: '-20px',
      fontSize: '16px',
      textAlign: 'center',
    },

    loadingContainer: {
      width: '100%',
      margin: '-65px 0',
    },

    phoneNumber: {
      width: '70%',
    },

    phoneWrapper: {
      display: 'flex',
      maxWidth: '330px',
    },

    phoneType: {
      width: '30%',
      position: 'relative',
    },

    phoneTypeSelect: {
      WebkitAppearance: 'none',
      border: '0px none',
      borderLeft: `1px solid ${Colors.lightGray3}`,
      borderBottom: `1px solid ${Colors.lightGray3}`,
      height: '39px',
      color: '#222',
      padding: '0 10px',
      width: '100%',
      cursor: 'pointer',
    },

    selectIcon: {
      color: Colors.lightGray3,
      position: 'absolute',
      top: '50%',
      right: '0',
      marginTop: '-18px',
      pointerEvents: 'none',
    },
  };

  const oldStyles = {
    mainContainer: {
      width: leapFrogUI ? 'initial' : '1000px',
      maxWidth: '100%',
      color: Colors.darkText,
      backgroundColor: Colors.light,
      padding: leapFrogUI ? '30px' : '10px 10px 10px 200px',
      marginTop: '10px',
      boxSizing: leapFrogUI ? 'initial' : 'border-box',
      borderRadius: leapFrogUI ? '6px' : '0',
      border: leapFrogUI ? `1px solid ${Colors.mediumGray3}` : 'none',

      '@media screen and (max-width: 760px) and (min-width: 481px)': {
        padding: '10px 10px 10px 100px',
      },

      '@media screen and (max-width: 480px)': {
        padding: '10px 40px',
      },
    },

    inputWrapper: {
      clear: 'both',
      marginTop: '10px',
    },

    bottomLinks: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '60px',
      marginBottom: '60px',
    },

    inputContainer: {
      float: 'left',
      marginRight: '10px',
      marginTop: '20px',
      marginBottom: '10px',
      position: 'relative',
    },

    mainHeader: {
      fontSize: '24px',
      margin: '0 0 5px',
      color: Colors.black,
    },

    subHeaderText: {
      margin: '0 0 20px',
      color: Colors.black,
      maxWidth: '520px',
      fontSize: '16px',
    },

    imageSpacing: {
      marginRight: '20px',
      marginTop: '20px',
    },

    iconImage: {
      fontSize: '35px',
      color: Colors.white,
      backgroundColor: Colors.bodyText,
      borderRadius: '50%',
      padding: '20px',
    },

    title: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '30px',
    },

    formContainer: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
    },

    helpText: {
      color: 'red',
      fontSize: '16px',
      display: 'block',
      position: 'absolute',
      top: '45px',
    },

    para: {
      maxWidth: '594px',
      paddingTop: '0px',
      margin: '20px 0px',
      lineHeight: '15px',
      fontSize: '14px',
      letterSpacing: '0.025em',
    },

    errorMessageWrapper: {
      color: 'red',
      fontWeight: '600',
      fontSize: '16px',
      marginTop: '40px',
    },
    errorMessage: {},

    agreement: {
      marginBottom: '-20px',
      fontSize: '16px',
      textAlign: 'center',
    },

    loadingContainer: {
      width: '100%',
      margin: '-65px 0',
    },

    phoneNumber: {
      width: '70%',
    },

    hcscPhoneNumber: {
      width: '100%',
    },

    hcscCellNumber: {
      width: '100%',
    },

    phoneWrapper: {
      display: 'flex',
      maxWidth: '330px',
    },

    hcscPhoneWrapper: {
      maxWidth: '330px',
    },

    phoneType: {
      width: '30%',
      position: 'relative',
    },

    phoneTypeSelect: {
      WebkitAppearance: 'none',
      borderRadius: '0px',
      backgroundColor: '#fff',
      borderTop: '0px none',
      borderRight: '0px none',
      borderLeft: `1px solid ${Colors.lightGray3}`,
      borderBottom: `1px solid ${Colors.lightGray3}`,
      height: '39px',
      color: '#222',
      padding: '0 10px',
      width: '100%',
      cursor: 'pointer',
      boxSizing: 'border-box',
    },

    optInLabel: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      letterSpacing: '0.025em',
    },

    checkboxInput: {
      marginRight: '7px',
      border: `1px solid ${Colors.lightGray2}`,
    },

    disabledOptIn: {
      opacity: '0.35',
      width: '100%',
      flexShrink: 0,
      clear: 'both',
    },

    enabledOptIn: {
      opacity: '1',
      width: '100%',
      flexShrink: 0,
      clear: 'both',
    },

    groupLabel: {
      marginTop: '0px',
      color: Colors.mediumGray2,
      marginBottom: '12px',
      fontSize: '15px',
      letterSpacing: '0.025em',
    },

    contactPreferenceWrapper: {
      width: '100%',
    },

    contactPreferenceContainer: {
      float: 'none',
      width: '100%',
    },

    contactPreference: {
      width: '330px',
      position: 'relative',
    },

    contactPreferenceSelect: {
      WebkitAppearance: 'none',
      borderRadius: '0px',
      backgroundColor: '#fff',
      border: `1px solid ${Colors.lightGray3}`,
      height: '42px',
      color: '#222',
      padding: '0 10px',
      width: '100%',
      cursor: 'pointer',
      boxSizing: 'border-box',
    },

    contactPreferenceHelpText: {
      top: '80px',
      fontSize: '14px',
      lineHeight: '1.5em',
      whiteSpace: 'pre-wrap',
    },

    selectIcon: {
      color: Colors.lightGray3,
      position: 'absolute',
      top: '50%',
      right: '0',
      marginTop: '-18px',
      pointerEvents: 'none',
    },

    contactPreferenceSelectIcon: {
      color: Colors.lightGray3,
      position: 'absolute',
      top: '50%',
      right: '5px',
      marginTop: '-12px',
      pointerEvents: 'none',
    },
  };

  const styles = fiveFiveUI ? newStyles : oldStyles;

  const {
    emailAddress,
    emailAddressConfirm,
    password,
    passwordConfirm,
    phoneNumber,
    phoneType,
    cellNumber,
    smsOptIn,
    contactPreference,
  } = formState;

  const isEmailValidated = validatedFields.indexOf('emailAddress') > -1;

  const isConfirmEmailValidated =
    validatedFields.indexOf('confirmEmailAddress') > -1;

  const isPhoneNumberValidated = validatedFields.indexOf('phoneNumber') > -1;

  const isCellNumberValidated = validatedFields.indexOf('cellNumber') > -1;

  const isContactPreferenceValidated =
    validatedFields.indexOf('contactPreference') > -1;

  const isPasswordValidated = validatedFields.indexOf('password') > -1;

  const isConfirmPasswordValidated =
    validatedFields.indexOf('confirmPassword') > -1;

  let formIsValid =
    emailAddress.isValid &&
    emailAddressConfirm.isValid &&
    (!phoneNumber.value || phoneNumber.value === '' || phoneNumber.isValid) &&
    password.isValid &&
    passwordConfirm.isValid;

  if (enableSMSOptIn) {
    formIsValid =
      formIsValid &&
      (!cellNumber.value || cellNumber.value === '' || cellNumber.isValid);
  }

  if (enableContactPreference) {
    formIsValid = formIsValid && contactPreference.isValid;
  }

  const updateEmail = (event) => actions.updateEmailAddress(event.target.value);
  const validateEmailAddress = () => validateField('emailAddress');

  const updateConfirmEmail = (event) =>
    actions.updateEmailAddressConfirm(event.target.value);
  const validateConfirmEmailAddress = () =>
    validateField('confirmEmailAddress');

  const updatePassword = (event) => actions.updatePassword(event.target.value);
  const validatePassword = () => validateField('password');

  const updateConfirmPassword = (event) =>
    actions.updatePasswordConfirm(event.target.value);
  const validateConfirmPassword = () => validateField('confirmPassword');

  const updatePhoneNumber = (event) => {
    actions.updatePhoneNumber((event.target.value || '').replace(/\D/g, ''));
    actions.updateContactPreference(contactPreference.value);
  };
  const validatePhoneNumber = () => validateField('phoneNumber');

  const updateCellNumber = (event) => {
    actions.updateCellNumber((event.target.value || '').replace(/\D/g, ''));
    actions.updateSmsOptIn(smsOptIn.value);
    actions.updateContactPreference(contactPreference.value);
  };
  const validateCellNumber = () => validateField('cellNumber');

  const updateSmsOptIn = (event) => {
    actions.updateSmsOptIn(event.target.value);
    actions.updateContactPreference(contactPreference.value);
  };

  const updatePhoneType = (event) =>
    actions.updatePhoneType(event.target.value || '');

  const updateContactPreference = (event) => {
    actions.updateContactPreference(event.target.value);
    validateField('contactPreference');
  };

  const termsFooterLink = _.find(
    AppConfig.effectiveConfig.footerLinks,
    (link) => /^terms/i.test(link.caption)
  );

  const privacyFooterLink = _.find(
    AppConfig.effectiveConfig.footerLinks,
    (link) => /^privacy/i.test(link.caption)
  );

  const getLinkHref = (link, defaultHref) =>
    (link
      ? _.isString(link.route)
        ? link.route
        : _.get(link, 'route.noAuth')
      : '') || defaultHref;

  const termsHref = getLinkHref(termsFooterLink, '/public/terms');

  const privacyHref = getLinkHref(privacyFooterLink, '/public/privacy');

  return (
    <div style={styles.mainContainer}>
      <div style={styles.card}>
        <div style={styles.title}>
          <span style={styles.imageSpacing}>
            <i
              className="thin-0699_user_profile_avatar_man_male"
              style={styles.iconImage}
            />
          </span>
          <span>
            <h2 style={[styles.mainHeader, GlobalStyles.extraLight]}>
              Create Your Profile
            </h2>
            <h3 style={[styles.subHeaderText, GlobalStyles.regular]}>
              Confirm your information below and choose a password.
            </h3>
          </span>
        </div>
        <div style={styles.formContainer}>
          <div
            style={
              isEmailValidated === false || emailAddress.isValid !== false
                ? styles.inputContainer
                : [styles.inputContainer, { marginBottom: '20px' }]
            }
          >
            <FloatingLabel
              onChange={updateEmail}
              placeHolder={'Email Address'}
              value={emailAddress.value}
              isValid={isEmailValidated ? emailAddress.isValid : null}
              onBlur={validateEmailAddress}
            />
            {isEmailValidated &&
              (emailAddress.isValid === false ? (
                <span style={styles.helpText}>
                  {emailAddress.error
                    ? emailAddress.error
                    : 'Email should not be empty.'}
                </span>
              ) : null)}
          </div>
          <div
            style={
              isConfirmEmailValidated === false ||
              emailAddressConfirm.isValid !== false
                ? styles.inputContainer
                : [styles.inputContainer, { marginBottom: '20px' }]
            }
          >
            <FloatingLabel
              onChange={updateConfirmEmail}
              placeHolder={'Confirm Email Address'}
              value={emailAddressConfirm.value}
              isValid={
                isConfirmEmailValidated ? emailAddressConfirm.isValid : null
              }
              onBlur={validateConfirmEmailAddress}
            />
            {isConfirmEmailValidated &&
              (emailAddressConfirm.isValid === false ? (
                <span style={styles.helpText}>
                  {emailAddressConfirm.error
                    ? emailAddressConfirm.error
                    : 'Confirm Email should not be empty.'}
                </span>
              ) : null)}
          </div>
          {enableSMSOptIn ? (
            <div>
              <div style={styles.inputWrapper}>
                <div
                  style={
                    isPhoneNumberValidated === false ||
                    phoneNumber.isValid !== false
                      ? [styles.inputContainer, styles.hcscPhoneWrapper]
                      : [
                          styles.inputContainer,
                          styles.hcscPhoneWrapper,
                          { marginBottom: '40px' },
                        ]
                  }
                >
                  <FloatingLabel
                    mask={'(999)-999-9999'}
                    onChange={updatePhoneNumber}
                    placeHolder={'Home Phone (optional)'}
                    value={phoneNumber.value}
                    isValid={
                      isPhoneNumberValidated ? phoneNumber.isValid : null
                    }
                    onBlur={validatePhoneNumber}
                    inputWrapper={styles.hcscPhoneNumber}
                    autoComplete="new-password"
                  />
                  {isPhoneNumberValidated &&
                    (phoneNumber.isValid === false ? (
                      <span style={styles.helpText}>{phoneNumber.error}</span>
                    ) : null)}
                </div>
              </div>
              <div style={styles.inputWrapper}>
                <div
                  style={
                    isCellNumberValidated === false ||
                    cellNumber.isValid !== false
                      ? [styles.inputContainer, styles.hcscPhoneWrapper]
                      : [
                          styles.inputContainer,
                          styles.hcscPhoneWrapper,
                          { marginBottom: '40px' },
                        ]
                  }
                >
                  <FloatingLabel
                    mask={'(999)-999-9999'}
                    onChange={updateCellNumber}
                    placeHolder={'Cell Phone (optional)'}
                    value={cellNumber.value}
                    isValid={isCellNumberValidated ? cellNumber.isValid : null}
                    onBlur={validateCellNumber}
                    inputWrapper={styles.hcscCellNumber}
                    autoComplete="new-password"
                  />
                  {isCellNumberValidated &&
                    (cellNumber.isValid === false ? (
                      <span style={styles.helpText}>{cellNumber.error}</span>
                    ) : null)}
                </div>
                <div
                  style={[
                    (isCellNumberValidated === false ||
                      cellNumber.isValid !== false) &&
                    !_.isEmpty(cellNumber.value)
                      ? styles.enabledOptIn
                      : styles.disabledOptIn,
                    isCellNumberValidated === false ||
                    cellNumber.isValid !== false
                      ? { marginBottom: '0px' }
                      : { marginTop: '50px' },
                  ]}
                >
                  <label style={styles.optInLabel}>
                    <input
                      type="checkbox"
                      name="textOptIn"
                      value="1"
                      style={styles.checkboxInput}
                      onChange={updateSmsOptIn}
                      disabled={
                        (isCellNumberValidated === false ||
                          cellNumber.isValid !== false) &&
                        !_.isEmpty(cellNumber.value)
                          ? false
                          : 'disabled'
                      }
                      checked={
                        (isCellNumberValidated === false ||
                          cellNumber.isValid !== false) &&
                        !_.isEmpty(cellNumber.value) &&
                        smsOptIn.value === true
                          ? 'checked'
                          : false
                      }
                    />
                    <span>
                      I would like to receive text message notifications from{' '}
                      {clientName}.
                    </span>
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div style={styles.inputWrapper}>
                <div
                  style={
                    isPhoneNumberValidated === false ||
                    phoneNumber.isValid !== false
                      ? [styles.inputContainer, styles.phoneWrapper]
                      : [
                          styles.inputContainer,
                          styles.phoneWrapper,
                          { marginBottom: '20px' },
                        ]
                  }
                >
                  <FloatingLabel
                    mask={'(999)-999-9999'}
                    onChange={updatePhoneNumber}
                    placeHolder={'Phone Number (optional)'}
                    value={phoneNumber.value}
                    isValid={
                      isPhoneNumberValidated ? phoneNumber.isValid : null
                    }
                    onBlur={validatePhoneNumber}
                    inputWrapper={styles.phoneNumber}
                  />
                  <div style={styles.phoneType}>
                    <select
                      style={styles.phoneTypeSelect}
                      onChange={updatePhoneType}
                      name="phoneType"
                      value={phoneType.value}
                      autoComplete="new-password"
                    >
                      <option value="home">Home</option>
                      <option value="cell">Cell</option>
                      <option value="work">Work</option>
                    </select>
                    <i className="material-icons" style={styles.selectIcon}>
                      keyboard_arrow_down
                    </i>
                  </div>
                  {isPhoneNumberValidated &&
                    (phoneNumber.isValid === false ? (
                      <span style={[styles.helpText, styles.phoneNumberError]}>
                        {phoneNumber.error}
                      </span>
                    ) : null)}
                </div>
              </div>
            </div>
          )}

          {enableContactPreference ? (
            <div style={[styles.inputWrapper, styles.contactPreferenceWrapper]}>
              <div
                style={
                  isContactPreferenceValidated === false ||
                  contactPreference.isValid !== false
                    ? [styles.inputContainer, styles.contactPreferenceContainer]
                    : [
                        styles.inputContainer,
                        styles.contactPreferenceContainer,
                        { marginBottom: '50px' },
                      ]
                }
              >
                <p style={styles.groupLabel}>
                  How do you prefer to be contacted?
                </p>
                <div style={styles.contactPreferenceWrapper}>
                  <div style={styles.contactPreference}>
                    <select
                      style={styles.contactPreferenceSelect}
                      onChange={updateContactPreference}
                      name="contactPreference"
                      value={contactPreference.value}
                    >
                      <option value="email">Email</option>
                      <option value="phone">Phone</option>
                      <option value="sms">SMS/Text Message</option>
                      <option value="mail">Mail</option>
                    </select>
                    <i
                      className="material-icons"
                      style={styles.contactPreferenceSelectIcon}
                    >
                      keyboard_arrow_down
                    </i>
                  </div>
                </div>
                {isContactPreferenceValidated &&
                  (contactPreference.isValid === false ? (
                    <span
                      style={[
                        styles.helpText,
                        styles.contactPreferenceHelpText,
                      ]}
                    >
                      {contactPreference.error}
                    </span>
                  ) : null)}
              </div>
            </div>
          ) : null}

          <div style={[GlobalStyles.regular, styles.para]}>
            <p style={styles.groupLabel}>Password</p>
            {
              localizedContent.errorMessages[
                ErrorConstants.ErrorType.INVALID_PASSWORD_FORMAT
              ]
            }
          </div>

          <div
            style={
              isPasswordValidated === false || password.isValid !== false
                ? styles.inputContainer
                : [styles.inputContainer, { marginBottom: '20px' }]
            }
          >
            <FloatingLabel
              onChange={updatePassword}
              placeHolder={'Password'}
              type={'password'}
              value={password.value}
              isValid={isPasswordValidated ? password.isValid : null}
              onBlur={validatePassword}
              autoComplete="new-password"
            />
            {isPasswordValidated &&
              (password.isValid === false ? (
                <span style={styles.helpText}>
                  {password.error
                    ? password.error
                    : 'Password should not be empty.'}
                </span>
              ) : null)}
          </div>
          <div style={styles.inputWrapper}>
            <div
              style={
                isConfirmPasswordValidated === false ||
                passwordConfirm.isValid !== false
                  ? styles.inputContainer
                  : [styles.inputContainer, { marginBottom: '20px' }]
              }
            >
              <FloatingLabel
                onChange={updateConfirmPassword}
                placeHolder={'Confirm Password'}
                type={'password'}
                value={passwordConfirm.value}
                isValid={
                  isConfirmPasswordValidated ? passwordConfirm.isValid : null
                }
                onBlur={validateConfirmPassword}
              />
              {isConfirmPasswordValidated &&
                (passwordConfirm.isValid === false ? (
                  <span style={styles.helpText}>
                    {passwordConfirm.error
                      ? passwordConfirm.error
                      : 'Confirm Password should not be empty.'}
                  </span>
                ) : null)}
            </div>
          </div>

          {errors.length > 0 && (
            <div style={styles.errorMessageWrapper}>
              {errors.map((val, index) => {
                return (
                  <p key={index} style={[GlobalStyles.regular]}>
                    {val}
                  </p>
                );
              })}
            </div>
          )}

          {formIsValid ? (
            <p style={styles.agreement}>
              {/* eslint-disable max-len */}
              By clicking "Confirm," I agree to{' '}
              {AppConfig.getLingo('termsAndPrivacyPossessive')}{' '}
              <a href={`#${termsHref}`} target="_blank">
                Terms of Use
              </a>{' '}
              and{' '}
              <a href={`#${privacyHref}`} target="_blank">
                Privacy Policy
              </a>.
              {/* eslint-enable max-len */}
            </p>
          ) : null}

          <div style={styles.bottomLinks}>
            <BottomNavButtons
              backLink={
                AppConfig.getLingo('registrationOptInContentHtml')
                  ? '/registration/opt-in'
                  : '/registration/basic-information'
              }
              onClickForw={onClickHandler}
              disabled={!formIsValid || registrationIsInProgress}
              forwText={'Confirm'}
            />
          </div>

          {registrationIsInProgress ? (
            <div style={styles.loadingContainer}>
              <Loading />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

CreateProfileMarkup.propTypes = {
  formState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  onClickHandler: PropTypes.func,
  validateField: PropTypes.func,
  validatedFields: PropTypes.array,
  registrationIsInProgress: PropTypes.bool.isRequired,
};

export default Radium(CreateProfileMarkup);
