import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure, withProps } from 'recompose';
import { pick } from 'lodash/fp';
import { Button, withStyles } from '@material-ui/core';
import { FteActions } from '@healthmine/greyhound-core/src/actions';
import DrawerContent from '../DrawerContent';
import Typography from '../../common/Typography';
import { DrawerSection } from '../../common/drawer2';

const styles = (theme) => ({
  content: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.peopleEater,
    backgroundImage: `url(${require('../../../../images/background/liftoff.png')})`,
    backgroundSize: 'cover',
  },
  centered: {
    margin: 'auto 0',
    padding: '70px',
    textAlign: 'center',
  },
  description: {
    margin: '20px 0',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.beefy,
    padding: '10px 140px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.beefy,
    },
  },
  link: {
    color: theme.palette.common.white,
  },
});

const LiftoffDrawer = ({ classes, onNext, ...props }) => (
  <DrawerContent {...props} hideStepper onSubmit={onNext}>
    <DrawerSection className={classes.content} grow noPadding noBorder>
      <div className={classes.centered}>
        <Typography type="h1">Prepare for liftoff!</Typography>
        <Typography type="h3Medium" className={classes.description}>
          Before we launch your new member portal, it's important to get a quick
          update so we can build the most accurate Care Plan for you.
        </Typography>
        <div>
          <Button variant="flat" className={classes.button} onClick={onNext}>
            {"Let's go!"}
          </Button>
        </div>
      </div>
    </DrawerSection>
  </DrawerContent>
);

LiftoffDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  connect(null, (dispatch) => ({
    actions: bindActionCreators(pick(['completeFTE'], FteActions), dispatch),
  })),
  withProps(() => ({
    color: 'peopleEater',
  }))
)(LiftoffDrawer);
