import React from 'react';
import PropTypes from 'prop-types';
import { hashHistory } from 'react-router';
import { connect } from 'react-redux';
import SsoErrorMarkup from '../../components/sign-in/SsoErrorMarkup';

class SsoErrorPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.checkAuth(this.props.isAuthenticated);
    document.body.style.backgroundColor = '#F5F5F5';
  }

  componentWillReceiveProps(nextProps) {
    this.checkAuth(nextProps.isAuthenticated);
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    return <SsoErrorMarkup />;
  }

  checkAuth(isAuthenticated) {
    if (isAuthenticated) {
      hashHistory.replace('/health-overview');
    }
  }
}

SsoErrorPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.signIn.accessToken,
  };
}

export default connect(mapStateToProps)(SsoErrorPage);
