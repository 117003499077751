import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  checkWrapper: {
    flex: '0 0 50px',
  },
  checkCircle: {
    width: '30px',
    height: '30px',
    marginRight: '20px',
    border: `2px dashed ${theme.palette.common.twentyCC}`,
    borderRadius: '50%',
  },
  itemContentWrapper: {
    flexGrow: 1,
  },
});

const ChecklistItem = ({ children, classes }) => {
  return (
    <Grid container alignItems="center">
      <Grid item className={classes.checkWrapper}>
        <div className={classes.checkCircle} />
      </Grid>
      <Grid item className={classes.itemContentWrapper}>
        {children}
      </Grid>
    </Grid>
  );
};

ChecklistItem.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChecklistItem);
