import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Icons from '@healthmine/greyhound-core/src/modules/Icons';
import Step from './Step';
import RecurringEducationStep from './RecurringEducationStep';

const EducationStep = ({
  name,
  scrollId,
  previousStep,
  nextStep,
  active,
  last,
}) => {
  const styles = {
    container: {
      marginTop: '60px',
      paddingLeft: '100px',
    },
    description: {
      color: Colors.contentText,
      fontSize: '16px',
      margin: '20px 0 20px',
    },
    directive: {
      color: Colors.bodyText,
      margin: 0,
      fontSize: '16px',
    },
  };

  return (
    <Step
      scrollId={scrollId}
      iconClass={Icons.BOOK}
      title={'Education'}
      previousStep={previousStep}
      nextStep={nextStep}
      active={active}
      required
      last={last}
      summaryLines={[`Type: ${name} Series`, 'Duration: Monthly']}
      valid
    >
      <div style={styles.container}>
        <RecurringEducationStep name={`${name} Series`} />
      </div>
    </Step>
  );
};

EducationStep.propTypes = {
  name: PropTypes.string.isRequired,
  last: PropTypes.bool,
  scrollId: PropTypes.string.isRequired,
  previousStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Radium(EducationStep);
