import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DrawerContent, DrawerSection } from '../../../common/drawer2';
import { Link } from 'react-router';
import Typography from '../../../common/Typography';

const styles = (theme) => ({
  group: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: '40px',
  },
  title: {
    marginTop: '20px',
    fontSize: '18px',
    fontWeight: '500',
  },
  statement: {
    marginTop: '10px',
  },
  check: {
    color: theme.palette.common.grannySmith,
  },
  star: {
    color: theme.palette.common.whiskeySour,
  },
  certificate: {
    color: theme.palette.common.beefy,
  },
  gray: {
    color: theme.palette.secondary.main,
  },
});

const HowToWin = ({ classes, title }) => {
  return (
    <DrawerContent title={title}>
      <DrawerSection grow>
        <div className={classes.group}>
          <i className={`icon-Checkmark ${classes.icon} ${classes.check}`} />
          <div className={classes.title}>Complete Actions</div>
          <div className={classes.statement}>
            Check out your care plan located on your{' '}
            <Link to="/health-overview">
              <Typography type="link">homepage</Typography>
            </Link>. Every care plan contains an important set of actions.
          </div>
        </div>
        <div className={classes.group}>
          <i className={`icon-Star-Circle ${classes.icon} ${classes.star}`} />
          <div className={classes.title}>Earn Points</div>
          <div className={classes.statement}>
            Earn points for completing each action, plus other actions like
            logging in every day.
          </div>
        </div>
        <div className={classes.group}>
          <i
            className={`icon-Certificate ${classes.icon} ${
              classes.certificate
            }`}
          />
          <div className={classes.title}>Enter Sweepstakes</div>
          <div className={classes.statement}>
            Spend your points on any sweepstakes you like for a chance to win!
          </div>
          <div className={`${classes.statement} ${classes.gray}`}>
            (Remember, you are entering for a chance to win, the odds of winning
            depend on the number of entries).
          </div>
        </div>
      </DrawerSection>
    </DrawerContent>
  );
};

HowToWin.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(HowToWin);
