import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import {
  DrawerSection,
  DrawerSectionHeader,
  DrawerContent,
  withDrawer,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';
import { map, reduce, concat, last } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  amount: {
    textAlign: 'right',
  },
});

const HowToEarnPointsDrawer = ({
  classes,
  tracker,
  incentiveBreakdown,
  singleEntry,
}) => {
  const { pointsDescription, unit } = tracker;
  return (
    <DrawerContent title="How to Earn Points">
      <DrawerSection>
        <Typography>{pointsDescription}</Typography>
      </DrawerSection>
      <DrawerSectionHeader title="Point Structure" />
      {map(incentiveBreakdown, (incentive, i) => {
        return (
          <DrawerSection key={`incentive-${i}`}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography type="h3">{incentive.threshold}</Typography>
                <Typography color="blueGray">
                  {singleEntry ? 'entry' : unit}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.amount}>
                <Typography type="h3">{incentive.amount} pts</Typography>
                <Typography color="blueGray">each day tracked</Typography>
              </Grid>
            </Grid>
          </DrawerSection>
        );
      })}
    </DrawerContent>
  );
};

HowToEarnPointsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  tracker: PropTypes.object.isRequired,
  incentiveBreakdown: PropTypes.array.isRequired,
  singleEntry: PropTypes.bool.isRequired,
};

export default compose(
  pure,
  withDrawer,
  withStyles(styles),
  withProps(({ tracker }) => {
    const { incentives } = tracker;
    const singleEntry = incentives.length === 1;

    const incentiveBreakdown = reduce(
      incentives || [],
      (breakdown, current, index) => {
        const previous = last(breakdown);
        const next = incentives[index + 1];
        const result = {
          threshold: singleEntry
            ? current.threshold
            : `${current.threshold} ${next ? `- ${next.threshold - 1}` : '+'}`,
          amount: previous ? previous.amount + current.amount : current.amount,
        };

        return concat(breakdown, result);
      },
      []
    );

    return {
      incentiveBreakdown,
      singleEntry,
    };
  })
)(HowToEarnPointsDrawer);
