import React from 'react';
import { Grid, withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const styles = () => ({
  root: {
    backgroundColor: '#E73B44',
    borderRadius: '20px',
    border: '7px solid #E41F29',
    fontFamily: 'Rubik',
    padding: '46px',
    paddingBottom: '32px',
    color: 'white',
    zIndex: '2',
    width: '750px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '38px',
    marginBottom: '16px',
    textAlign: 'center',
  },
  paragraph: {
    fontSize: '16px',
    marginBottom: '12px',
    textAlign: 'center',
    fontWeight: '300',
  },
  button: {
    backgroundColor: '#FEB600',
    fontSize: '26px',
    color: 'white',
    borderRadius: '8px',
    padding: '14px 16px',
    textTransform: 'uppercase',
    fontWeight: '500',
    fontFamily: 'Rubik',
    '&:hover': {
      backgroundColor: '#FEB600',
    },
  },
  takeMeBack: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonIconLeft: {
    fontSize: '18px',
    marginRight: '16px',
  },
  continueLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '36px',
    fontSize: '20px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonIconRight: {
    marginLeft: '16px',
    fontSize: '12px',
  },
  continueLinkText: {
    textTransform: 'uppercase',
    paddingBottom: '4px',
    borderBottom: '2px solid white',
  },
});

const SkipWarningBox = ({ classes, doneWithSkipWarning }) => (
  <Grid item container direction="column" className={classes.root}>
    <Grid item className={classnames(classes.centered, classes.title)}>
      Are you sure you want to skip this question?
    </Grid>
    <Grid item className={classnames(classes.centered, classes.paragraph)}>
      <p>
        By skipping questions, you will miss out on earning <b>$15</b> for
        completing the survey.
      </p>
    </Grid>
    <div className={classes.takeMeBack}>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => doneWithSkipWarning(true)}
      >
        <span className={classes.buttonIconLeft}>◄</span>
        Take me back
      </Button>
    </div>
    <a
      className={classes.continueLink}
      onClick={() => doneWithSkipWarning(false)}
    >
      <span className={classes.continueLinkText}>Yes, I Understand</span>
      <span className={classes.buttonIconRight}>►</span>
    </a>
  </Grid>
);

SkipWarningBox.propTypes = {
  doneWithSkipWarning: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SkipWarningBox);
