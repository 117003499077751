import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import * as BiometricScreeningSelectors from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';
import { faqCategorySelector } from '@healthmine/greyhound-core/src/selectors/FaqSelector';
import FAQPageMarkup from './FAQPageMarkup';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';

class FAQPage extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      showWelcomeBackModal: false,
      showThirdPartyCookieWarning: false,
      visibility: {},
    };
  }

  render() {
    const { faqs } = this.props;

    const {
      category,
      visibility,
      showWelcomeBackModal,
      showThirdPartyCookieWarning,
      animating,
    } = this.state;

    const activeCategory =
      (category && (visibility[category] || animating)
        ? category
        : _(visibility)
            .pickBy()
            .keys()
            .min()) || 0;

    const footerHtml = _.get(AppConfig, 'effectiveConfig.lingo.faqFooterHtml');

    const includeLogo = !AppConfig.validateFeature(
      Features.NO_LOGO_IN_FAQ_FOOTER
    );

    return (
      <FAQPageMarkup
        faqs={faqs}
        footerHtml={footerHtml}
        includeLogo={includeLogo}
        onContentClick={this._onContentClick}
        showWelcomeBackModal={showWelcomeBackModal}
        onWelcomeBackModalClosed={this._onWelcomeBackModalClosed}
        showThirdPartyCookieWarning={showThirdPartyCookieWarning}
        onThirdPartyCookieWarningClosed={this._onThirdPartyCookieWarningClosed}
        activeCategory={parseInt(activeCategory)}
        setVisibility={this._setVisibility}
        setAnimating={this._setAnimating}
      />
    );
  }

  @autobind
  _onContentClick(evt) {
    const url = _.get(evt, 'target.href');

    if (/BiometricScreeningManual\.aspx/i.test(url)) {
      const { ssoUrl, thirdPartyCookiesUnsupported } = this.props;

      evt.preventDefault();

      if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)) {
        this.setState({
          showWelcomeBackModal: true,
        });
      } else if (thirdPartyCookiesUnsupported) {
        this.setState({
          showThirdPartyCookieWarning: true,
        });
      } else {
        window.open(ssoUrl);

        this.setState({
          showWelcomeBackModal: true,
        });
      }
    }
  }

  _setVisibility = (category, isVisible) => {
    this.setState({
      visibility: { ...this.state.visibility, [category]: isVisible },
    });
  };

  _setAnimating = (animating, category) => {
    if (parseInt(category) >= 0) {
      this.setState({ category });
      setTimeout(() => this.setState({ animating }), 1000);
    } else {
      this.setState({ animating });
    }
  };

  _onThirdPartyCookieWarningClosed = () => {
    this.setState({
      showThirdPartyCookieWarning: false,
    });
  };

  _onWelcomeBackModalClosed = () => {
    this.setState({
      showWelcomeBackModal: false,
    });
  };
}

FAQPage.propTypes = {
  ssoUrl: PropTypes.string.isRequired,
  faqs: PropTypes.array.isRequired,
  thirdPartyCookiesUnsupported: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const ssoUrl = BiometricScreeningSelectors.selectSsoUrl(state);

  const { detected, supported } = state.thirdPartyCookieSupport;

  return {
    ssoUrl,
    faqs: faqCategorySelector(state),
    thirdPartyCookiesUnsupported: !!(detected && !supported),
  };
}

export default connect(mapStateToProps)(FAQPage);
