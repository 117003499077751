import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import LoginForm from './LoginForm';
import LoginFailure from './LoginFailure';
import Loading from './Loading';
import { Features } from '@healthmine/greyhound-core/src/constants';

const SignIn = ({
  onClick,
  saving,
  signIn,
  email,
  password,
  emailChange,
  passwordChange,
}) => {
  const { appLoading, appLoadFailed, accessToken } = signIn;

  const valuesForState = (loadingValue, failedValue, nonAuthValue) =>
    accessToken
      ? appLoading
        ? loadingValue
        : appLoadFailed
          ? failedValue
          : nonAuthValue
      : nonAuthValue;

  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const newStyles = {
    formContainer: {
      width: '422px',
      maxWidth: '100%',
      boxSizing: 'border-box',
      color: Colors.bodyText,
      backgroundColor: Colors.light,
      margin: '40px auto',
      padding: '30px',
      minHeight: '300px',
      borderRadius: '4px',
      boxShadow: '0 2px 6px 0 rgba(0,0,0,0.2)',
    },
  };

  const styles = {
    formContainer: {
      width: '422px',
      maxWidth: '100%',
      boxSizing: 'border-box',
      color: Colors.bodyText,
      backgroundColor: Colors.light,
      margin: '40px auto',
      border: `1px solid ${Colors.mediumGray3}`,
      padding: '30px',
      borderRadius: '6px',
      minHeight: '300px',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
    },
    mainHeader: {
      fontSize: '24px',
      margin: '0 0 10px',
      color: valuesForState(Colors.bodyText, 'red', Colors.bodyText),
    },
  };

  const title = valuesForState(
    `Loading ${AppConfig.effectiveConfig.clientDisplayName}!`,
    'Something Went Wrong',
    'Sign in Below'
  );

  const Form = (
    <LoginForm
      onClick={onClick}
      saving={saving}
      email={email}
      password={password}
      emailChange={emailChange}
      passwordChange={passwordChange}
      signIn={signIn}
    />
  );

  const Body = valuesForState(
    <Loading />,
    <LoginFailure />,
    appLoading ? <Loading /> : Form
  );

  return (
    <div>
      <div style={styles.title}>
        <span>
          <h2 style={[GlobalStyles.extraLight, styles.mainHeader]}>{title}</h2>
        </span>
      </div>
      <div style={fiveFiveUI ? newStyles.formContainer : styles.formContainer}>
        {Body}
      </div>
    </div>
  );
};

SignIn.propTypes = {
  onClick: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  signIn: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  emailChange: PropTypes.func.isRequired,
  passwordChange: PropTypes.func.isRequired,
};

export default Radium(SignIn);
