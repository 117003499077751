import { compose, pure, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import HeroCardMarkup from './HeroCard.markup';
import { withRouter } from 'react-router';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { CoverageSelectors } from '@healthmine/greyhound-core/src/selectors';
import _ from 'lodash';
import moment from 'moment';

export default compose(
  pure,
  withRouter,
  connect((state) => {
    const preventiveClinicalActions = _.get(
      state,
      'carePlanGoals.subjects.PREVENTIVE.clinical.actions',
      []
    );

    const preventiveVisitAction = _.find(
      preventiveClinicalActions,
      (action) => {
        return _.includes(
          [
            AppConstants.HealthActions.COMPLETE_PREVENTIVE_VISIT_ANNUAL,
            AppConstants.HealthActions.COMPLETE_PREVENTIVE_VISIT,
          ],
          action.healthActionCode
        );
      },
      {}
    );

    const myAction = _.get(
      state,
      ['myClinicalActions', preventiveVisitAction.healthActionId],
      {}
    );

    const completions = _.get(myAction, 'completions', []);

    const lastCompleted = _.head(
      _.orderBy(completions, 'completionDate', 'desc')
    );

    const currentCompliancePeriod = CoverageSelectors.selectCurrentCompliancePeriod(
      state
    );

    const complianceStartDate = _.get(currentCompliancePeriod, 'startDate');

    const complianceEndDate = _.get(currentCompliancePeriod, 'endDate');

    const isActionComplete =
      !_.isUndefined(lastCompleted) &&
      moment(lastCompleted).isBetween(
        complianceStartDate,
        complianceEndDate,
        null,
        []
      );
    return {
      preventiveVisitAction,
      isActionComplete,
      lastCompleted,
    };
  }),
  withProps(({ preventiveVisitAction, isActionComplete, lastCompleted }) => {
    const { incentiveType, incentiveCurrency } = AppConfig.effectiveConfig;

    const incentiveAmount = _.get(preventiveVisitAction, 'incentiveAmount');

    const completedText = isActionComplete
      ? `Completed on ${moment(lastCompleted).format('MM/DD/YYYY')}`
      : '';

    const incentiveText = isActionComplete
      ? incentiveType === AppConstants.IncentiveType.NONE
        ? ''
        : incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS
          ? `You earned $${incentiveAmount}!`
          : `You earned ${incentiveAmount} points!`
      : incentiveType === AppConstants.IncentiveType.NONE
        ? ''
        : incentiveCurrency === AppConstants.IncentiveCurrency.DOLLARS
          ? `Earn $${incentiveAmount}`
          : `Earn ${incentiveAmount} points`;
    return {
      iconClass: 'thin-0825_stetoscope_doctor_hospital_ill',
      title: 'Preventive Visit',
      completedText,
      incentiveText,
      actionComplete: isActionComplete,
    };
  }),
  withHandlers(() => ({
    onCardClick: ({ router }) => () => router.push('/care-plan/preventive'),
  }))
)(HeroCardMarkup);
