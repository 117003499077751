require('normalize.css');
require('styles/App.css');
require('styles/picons-thin/picons-thin-font.css');
require('styles/material-icons/material-icons.css');
require('styles/ios-edge-glyph/ios-edge-glyph.css');
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  GlobalStyles,
  AppConfig,
  Colors,
} from '@healthmine/greyhound-core/src/modules';
import LoadingBar from '../components/LoadingBar';
import DesignGrid from '../components/DesignGrid';
import Impersonation from '../components/Impersonation';
import Announcements from '../components/Announcements/Announcements';
import ThirdPartyCookieCheck from '../components/common/ThirdPartyCookieCheck';
import Radium, { StyleRoot } from 'radium';
import Footer from '../components/common/Footer';
import TutorialContainer from './tutorial/TutorialContainer';
import { TutorialActions } from '../actions';
import { Features } from '@healthmine/greyhound-core/src/constants';
import GinaModal from '../components/common/GinaModal';
import '../styles/uhc-sans/uhc-sans-font.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      devMode: false,
    };
  }
  render() {
    const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);
    const uhcBranded = AppConfig.validateFeature(Features.UHC_BRANDED);

    const {
      apiCallsInProgress,
      username,
      impersonationEmail,
      appLoading,
      isLoggedIn,
      onboardingComplete,
    } = this.props;

    const Styles = {
      root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: fiveFiveUI ? Colors.backdropGray : '',
        fontFamily: uhcBranded
          ? '"UHCSansDigital", sans-serif'
          : fiveFiveUI
            ? '"Rubik", sans-serif'
            : '"acumin-pro", sans-serif',
      },
    };
    return (
      <StyleRoot className="index" style={[GlobalStyles.light, Styles.root]}>
        {!appLoading && apiCallsInProgress > 0 ? <LoadingBar /> : null}
        {process.env.NODE_ENV === 'development' && (
          <DesignGrid
            toggleDev={this._toggleDevMode}
            showGrid={this.state.devMode}
          />
        )}
        <Impersonation
          username={username}
          impersonationEmail={impersonationEmail}
        />
        <ThirdPartyCookieCheck />
        <Announcements />
        <TutorialContainer>{this.props.children}</TutorialContainer>

        {onboardingComplete ? <GinaModal /> : null}

        <Footer
          isLoggedIn={isLoggedIn}
          isOnboard={onboardingComplete}
          showTutorial={AppConfig.validateFeature(Features.TUTORIAL)}
          onTutorialClick={() =>
            this.props.actions.setTutorialPage('healthOverview')
          }
        />
      </StyleRoot>
    );
  }

  _toggleDevMode = () => {
    this.setState({ devMode: !this.state.devMode });
  };
}

App.propTypes = {
  actions: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  apiCallsInProgress: PropTypes.number,
  username: PropTypes.string,
  impersonationEmail: PropTypes.string,
  appLoading: PropTypes.bool,
  onboardingComplete: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};

function mapStateToProps(state) {
  const { firstName, lastName } = state.userInfo;

  const {
    impersonationEmail,
    accessToken,
    appLoading,
    appLoadFailed,
  } = state.signIn;

  const { completed: onboardingComplete } = state.onBoarding;

  return {
    appLoading,
    apiCallsInProgress: state.apiCallsInProgress,
    username: `${firstName} ${lastName}`,
    impersonationEmail,
    isLoggedIn: !!accessToken && !appLoadFailed,
    onboardingComplete,
  };
}

function mapDispatchToProps(dispatch) {
  const { setTutorialPage } = TutorialActions;
  return {
    actions: bindActionCreators({ setTutorialPage }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(App));
