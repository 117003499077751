import React from 'react';
import Radium from 'radium';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import Page from '../common/Page';
import OffsetCloseButton from '../common/OffsetCloseButton';
import MyDataBody from './MyDataBody';

const MyDataPage = () => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const styles = {
    titleIcon: {
      backgroundColor: Colors.bodyText,
    },
    container: {
      borderTop: `1px solid ${Colors.gutterGray}`,
      backgroundColor: fiveFiveUI ? '#fff' : '',
    },
  };

  return (
    <Page
      title={'My Data'}
      icon={'thin-0385_graph_pie_chart_statistics'}
      iconStyle={styles.titleIcon}
    >
      <div style={styles.container}>
        <OffsetCloseButton />
        <MyDataBody />
      </div>
    </Page>
  );
};

export default Radium(MyDataPage);
