import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules/';

class Item extends React.Component {
  constructor(props) {
    super(props);

    //The state and functions are only used for the
    //individual element hover actions to avoid using external css

    this.state = {
      isActive: false,
    };

    this._onMouseOver = this._onMouseOver.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
  }

  render() {
    const {
      iconClass,
      itemStyle,
      onClick,
      iconStyle,
      iconInnerStyle,
      text,
      innerTitle,
      keep,
      textActive,
      wide,
      connected,
      points,
      footer,
      styles,
      selected,
      pointDescription,
    } = this.props;

    const _styles = {
      item: {
        display: 'block',
        textAlign: 'center',
        position: 'relative',
        ...styles.item,
      },
      itemRightMargin: {
        marginRight: '15px',
        ...styles.itemRightMargin,
      },
      icon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        fontSize: '40px',
        padding: '18px',
        margin: '0 auto',
        borderRadius: '50%',
        ...styles.icon,
      },
      words: {
        display: 'block',
        height: '80px',
        width: '80px',
        padding: '10px',
        borderRadius: '50%',
        margin: '0px 20px 0px 0px',
        ...styles.words,
      },
      textItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
        color: Colors.lightGray,
        ...styles.textItem,
      },
      innerLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '80px',
        width: '80px',
        fontSize: '16px',
        ...styles.innerLabel,
      },
      underSvg: {
        fontSize: '30px',
        textAlign: 'center',
        ...styles.underSvg,
      },
      label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        paddingTop: '10px',
        cursor: 'pointer',
        borderRadius: '10px',
        color: Colors.bodyText,
        ...styles.label,
      },
      wideLabel: {
        width: '160px',
        ...styles.wideLabel,
      },
      smallerIcon: {
        zIndex: '2',
        position: 'absolute',
        left: '5px',
        top: '-5px',
        fontSize: '16px',
        width: '14px',
        height: '14px',
        padding: '5px',
        borderRadius: '50%',
        backgroundColor: Colors.light,
        color: connected ? Colors.lightGreen : Colors.mediumGray3,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: connected ? Colors.lightGreen : Colors.gutterGray,
        ...styles.smallerIcon,
      },
      pointsStyle: {
        display: 'inline-block',
        paddingTop: '15px',
        fontSize: '16px',
        color: Colors.mediumBlue,
        ...styles.pointsStyle,
      },
      footerStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        color: Colors.lightGray,
        ...styles.footerStyle,
      },
      pointDescriptionStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        color: Colors.lightGray,
        fontWeight: 'bold',
        ...styles.pointDescriptionStyle,
      },
      extraSmall: {
        fontSize: '50%',
        ...styles.extraSmall,
      },
      hoverBubble: {
        backgroundColor: Colors.lightGray2,
        border: `2px solid ${Colors.lightGray2}`,
        color: Colors.white,
      },
      hoverLabel: {
        fontWeight: '600',
      },
    };

    const { isActive } = this.state;

    return (
      <div
        className="item"
        onClick={onClick}
        onMouseOver={this._onMouseOver}
        onMouseLeave={this._onMouseLeave}
        style={[
          iconClass
            ? wide
              ? _styles.item
              : { ..._styles.item, ..._styles.itemRightMargin }
            : _styles.textItem,
          itemStyle,
        ]}
      >
        <div
          className={innerTitle || keep ? null : 'item-icon'}
          style={[
            iconClass ? _styles.icon : _styles.words,
            selected || keep
              ? iconStyle
              : isActive
                ? _styles.hoverBubble
                : null,
          ]}
        >
          {iconClass ? (
            <i className={iconClass} style={iconInnerStyle} />
          ) : (
            <span
              className="item-text"
              style={[
                _styles.innerLabel,
                GlobalStyles.light,
                selected ? textActive : isActive ? _styles.hoverLabel : null,
              ]}
            >
              {text}
            </span>
          )}
          {innerTitle && <span style={_styles.underSvg}>{innerTitle}</span>}
        </div>
        {iconClass &&
          text && (
            <span
              className="item-text"
              style={[
                _styles.label,
                wide ? _styles.wideLabel : {},
                GlobalStyles.light,
                selected ? textActive : isActive ? _styles.hoverLabel : null,
              ]}
            >
              {text}
            </span>
          )}
        {pointDescription && (
          <span style={_styles.pointDescriptionStyle}>{pointDescription}</span>
        )}
        {points && (
          <span className="footer-text" style={_styles.pointsStyle}>
            {points} <small style={_styles.extraSmall}>Pts.</small>
          </span>
        )}
        {footer && <span style={_styles.footerStyle}>{footer}</span>}
      </div>
    );
  }

  _onMouseOver() {
    this.setState({
      isActive: true,
    });
  }

  _onMouseLeave() {
    this.setState({
      isActive: false,
    });
  }
}

Item.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string,
  innerTitle: PropTypes.string,
  itemStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  iconInnerStyle: PropTypes.object,
  pointDescription: PropTypes.string,
  points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  footer: PropTypes.string,
  onClick: PropTypes.func,
  keep: PropTypes.bool,
  textActive: PropTypes.object,
  wide: PropTypes.bool,
  connected: PropTypes.bool,
  styles: PropTypes.object,
  selected: PropTypes.bool,
};

Item.defaultProps = {
  backColor: Colors.lighterGray,
  styles: {},
};

export default Radium(Item);
