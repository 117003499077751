import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules/';
import Modal from 'react-modal';

const styles = {
  modalStyles: {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
      zIndex: 1010,
    },
    content: {
      minWidth: '300px',
      right: 'initial',
      bottom: 'initial',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: '#fff',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '15px',
      outline: 'none',
      padding: '50px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    fontSize: '25px',
    color: Colors.black,
    cursor: 'pointer',
  },
  modalContent: {
    boxSizing: 'border-box',
    flex: '0 0 300px',
    minWidth: '300px',
    maxHeight: '500px',
    position: 'relative',
    fontSize: '16px',
  },
  title: {
    fontSize: '18px',
    textAlign: 'center',
  },
  healthActionTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    marginTop: '10px',
  },
  planContactContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  instruction: {
    textAlign: 'center',
  },
  contactOptions: {},
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    fontSize: '16px',
  },
  contactIcon: {
    marginRight: '10px',
    color: 'rgb(155, 155, 155)',
    fontSize: '25px',
  },
  contactLink: {
    color: Colors.linkBlue,
    textDecoration: 'none',
  },
  noApptRequired: {
    textAlign: 'center',
  },
  note: {
    color: Colors.lightSlateGray,
    textAlign: 'center',
    marginTop: '10px',
  },
};

const SchedulingModal = ({
  close,
  healthActionTitle,
  schedulingContactList,
}) => {
  return (
    <Modal isOpen contentLabel={''} style={styles.modalStyles}>
      <a onClick={close} style={styles.closeButton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>
      <div style={styles.modalContent}>
        <div style={styles.title}>
          <div>Scheduling Options</div>
          <div style={styles.healthActionTitle}>{healthActionTitle}</div>
        </div>
        {schedulingContactList.map(
          (
            { instruction, name, phone, url, urlText, note, noApptRequired },
            idx
          ) => {
            return (
              <div
                key={`contact_item_${idx}`}
                style={styles.planContactContainer}
              >
                {instruction !== '' && (
                  <div style={styles.instruction}>{instruction}</div>
                )}

                <div style={styles.contactOptions}>
                  {phone !== '' && (
                    <div style={styles.contactItem}>
                      <i style={styles.contactIcon} className="material-icons">
                        phone
                      </i>
                      <span>
                        Call {name} {phone}
                      </span>
                    </div>
                  )}
                  {url !== '' && (
                    <div style={styles.contactItem}>
                      <i style={styles.contactIcon} className="material-icons">
                        computer
                      </i>
                      <a style={styles.contactLink} href={url} target="_blank">
                        {urlText ? urlText : 'Schedule Online'}
                      </a>
                    </div>
                  )}
                  {noApptRequired && (
                    <div style={styles.noApptRequired}>
                      No appointment required
                    </div>
                  )}
                  {note && <div style={styles.note}>{note}</div>}
                </div>
              </div>
            );
          }
        )}
      </div>
    </Modal>
  );
};

SchedulingModal.propTypes = {
  close: PropTypes.func,
  healthActionTitle: PropTypes.string.isRequired,
  schedulingContactList: PropTypes.array,
};

export default Radium(SchedulingModal);
