import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../../../../common/Typography';

const styles = () => ({
  root: {
    marginTop: '20px',
  },
});

const MaxEarnLingo = ({ classes, maxAmount }) => (
  <Typography className={classes.root}>
    Earn up to ${maxAmount} per year for completing health actions.
  </Typography>
);

MaxEarnLingo.propTypes = {
  classes: PropTypes.object.isRequired,
  maxAmount: PropTypes.number.isRequired,
};

export default withStyles(styles)(MaxEarnLingo);
