import _ from 'lodash';
import { connect } from 'react-redux';
import { branch, compose, pure, renderComponent } from 'recompose';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  CoverageSelectors,
  SingleCarePlanSelectors,
} from '@healthmine/greyhound-core/src/selectors';
import MyCarePlanEnrolled from './MyCarePlan.Enrolled';
import MyCarePlanNotEnrolledMarkup from './MyCarePlan.NotEnrolled.markup';

export default compose(
  pure,

  connect((state) => {
    const currentCoverage = CoverageSelectors.selectCurrentCoverage(state);

    const employerGroupNumber = _.get(
      currentCoverage,
      'coverage.employerGroup.groupNumber'
    );

    const programName = AppConfig.getLingo(
      `programName_${employerGroupNumber}`,
      AppConfig.getLingo('programName')
    );

    const enrolledCarePlan = SingleCarePlanSelectors.selectCurrentEnrolledCarePlan(
      state
    );

    return {
      coverageRelationshipCd: currentCoverage.relationshipCd,
      programName,

      isEnrolled: !!enrolledCarePlan,

      actionItems: enrolledCarePlan ? enrolledCarePlan.actionItems : undefined,
    };
  }),

  branch(
    ({ isEnrolled }) => isEnrolled,
    renderComponent(MyCarePlanEnrolled),
    renderComponent(MyCarePlanNotEnrolledMarkup)
  )
)();
