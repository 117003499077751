import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, pure } from 'recompose';
import { pick } from 'lodash/fp';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import DrawerActions from '../../../../actions/DrawerActions';

const styles = (theme) => ({
  root: {
    width: '400px',
    maxWidth: '100vw',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
    height: '100vh',
    overflowY: 'scroll',
  },
  large: {
    width: '800px',
  },
});

const HMDrawer = ({
  classes,
  className,
  actions: { closeDrawer },
  drawer: { stack, isLarge, disableBackdropClose },
}) => {
  const [current] = stack;

  return (
    <Drawer
      anchor="right"
      open={!!current}
      ModalProps={{
        onBackdropClick: !disableBackdropClose ? closeDrawer : undefined,
      }}
    >
      <div
        className={classnames(classes.root, className, {
          [classes.large]: isLarge,
        })}
      >
        {current && <current.Component {...current.props} />}
      </div>
    </Drawer>
  );
};

HMDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    closeDrawer: PropTypes.func.isRequired,
  }).isRequired,
  drawer: PropTypes.shape({
    stack: PropTypes.arrayOf(
      PropTypes.shape({
        Component: PropTypes.any.isRequired,
        props: PropTypes.object,
      })
    ).isRequired,
    isLarge: PropTypes.bool.isRequired,
    disableBackdropClose: PropTypes.bool.isRequired,
  }).isRequired,
};

export default compose(
  pure,
  connect(pick(['drawer']), (dispatch) => ({
    actions: bindActionCreators(DrawerActions, dispatch),
  })),
  withStyles(styles)
)(HMDrawer);
