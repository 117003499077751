import _isUndefined from 'lodash/fp/isUndefined';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure, withState, withHandlers } from 'recompose';
import { Features } from '@healthmine/greyhound-core/src/constants';
import WelcomeBackModal from '../../../../../components/common/WelcomeBackModal';
import ThirdPartyCookieWarning from '../../../../../components/common/ThirdPartyCookieWarning';
import LeavingOurSiteModal from '../../../../../components/common/LeavingOurSiteModal';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { selectSsoUrl } from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';

const BiometricModals = ({
  showModals,
  cookiesUnsupported,
  isDemo,
  hasLeft,
  onOk,
  onCancel,
}) => {
  if (!showModals) return null;
  if (isDemo || hasLeft) return <WelcomeBackModal onClose={onCancel} />;
  if (cookiesUnsupported) return <ThirdPartyCookieWarning onClose={onCancel} />;
  return <LeavingOurSiteModal onOK={onOk} onCancel={onCancel} />;
};

BiometricModals.propTypes = {
  showModals: PropTypes.bool.isRequired,
  cookiesUnsupported: PropTypes.bool.isRequired,
  ssoUrl: PropTypes.string.isRequired,
  isDemo: PropTypes.bool.isRequired,
  hasLeft: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  screeningType: PropTypes.number,
};

BiometricModals.defaultProps = {
  isDemo: AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO),
};

export default compose(
  pure,
  connect((state) => {
    const { detected, supported } = state.thirdPartyCookieSupport;
    const ssoUrl = selectSsoUrl(state);
    const cookiesUnsupported = detected && !supported;

    return {
      ssoUrl,
      cookiesUnsupported,
    };
  }),
  withState('hasLeft', 'setHasLeft', false),
  withHandlers({
    onCancel: ({ onClose, setHasLeft }) => () =>
      setHasLeft(false, () => onClose()),
    onOk: ({ ssoUrl, setHasLeft, screeningType }) => () =>
      setHasLeft(true, () => {
        const screeningTypeParam = _isUndefined(screeningType)
          ? ''
          : `&screeningType=${screeningType}`;

        window.open(`${ssoUrl}${screeningTypeParam}`);
      }),
  })
)(BiometricModals);
