import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Button from '../common/Button';

const DismissCarePlanMarkup = ({ carePlan, onCarePlanDismissed }) => {
  const styles = {
    modalStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },

      content: {
        display: 'flex',
        minWidth: '425px',
        maxWidth: '425px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: Colors.white,
        outline: 'none',
        padding: '20px 40px',

        WebkitOverflowScrolling: 'touch',
      },
    },

    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      fontWeight: '300',
    },

    title: {
      color: Colors.bodyText,
      fontSize: '20px',
      fontWeight: '400',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      paddingBottom: '10px',
    },

    paragraph: {
      color: Colors.contentText,
      fontSize: '16px',
      fontWeight: '200',
      marginTop: '10px',
      marginBottom: '0px',
      lineHeight: 1.2,
    },

    question: {
      color: Colors.dark,
    },

    emphasized: {
      fontWeight: '600',
    },

    buttonContainer: {
      width: '80%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '30px auto 0px',
    },

    dismissButton: {
      flex: 3,
      marginBottom: '0px',
      backgroundColor: Colors.bodyText,
      borderColor: Colors.bodyText,
      padding: '12px 0px',
      fontSize: '16px',
      fontWeight: '400',

      ':hover': {
        backgroundColor: Colors.lightenColor(Colors.bodyText),
      },

      ':active': {
        backgroundColor: Colors.bodyText,
      },
    },

    cancelLink: {
      textAlign: 'center',
      flex: 1,
      fontSize: '16px',
      fontWeight: '400',
      cursor: 'pointer',
    },
  };

  return (
    <Modal
      isOpen
      contentLabel="CarePlanDismiss"
      style={styles.modalStyles}
      onRequestClose={() => onCarePlanDismissed()}
    >
      <a onClick={() => onCarePlanDismissed()} style={styles.closeButton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>

      <div style={styles.content}>
        <div style={styles.title}>Dismiss Care Plan</div>

        <p style={[styles.paragraph, styles.question]}>
          Are you sure you want to remove the{' '}
          <span style={styles.emphasized}>{carePlan.shortName}</span> Care Plan?
        </p>

        <p style={styles.paragraph}>
          By dismissing an unconfigured Care Plan, you are removing it from your
          active goals and missing out on the opportunity to earn more points.
          You can configure this Care Plan at a later time by selecting it from
          the list shown on your{' '}
          <span style={styles.emphasized}>Add/Change Care Plans</span> page.
        </p>

        <div style={styles.buttonContainer}>
          <Button
            style={styles.dismissButton}
            text="Dismiss Care Plan"
            onClick={() => onCarePlanDismissed(carePlan)}
          />

          <a style={styles.cancelLink} onClick={() => onCarePlanDismissed()}>
            Cancel
          </a>
        </div>
      </div>
    </Modal>
  );
};

DismissCarePlanMarkup.propTypes = {
  carePlan: PropTypes.object,
  onCarePlanDismissed: PropTypes.func.isRequired,
};

export default Radium(DismissCarePlanMarkup);
