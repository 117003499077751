import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { hashHistory } from 'react-router';
import _ from 'lodash';
import PrescriptionsMarkup from './PrescriptionsMarkup';

const PRODUCTS_PER_PAGE = 10;

class Prescriptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: 'startDate',
    };
  }

  render() {
    const { sortBy } = this.state;

    const { prescriptionHistory, params } = this.props;

    const sortedHistory = this._getSortedHistory(prescriptionHistory);

    const page = params.page ? parseInt(params.page) : 1;

    const pages = sortedHistory.length
      ? Math.ceil(sortedHistory.length / PRODUCTS_PER_PAGE)
      : 0;

    const displayHistory = sortedHistory.slice(
      (page - 1) * PRODUCTS_PER_PAGE,
      page * PRODUCTS_PER_PAGE
    );

    return (
      <PrescriptionsMarkup
        prescriptionsHistory={displayHistory}
        currPage={page}
        pages={pages}
        sortBy={sortBy}
        sortHistory={this._sortHistory}
      />
    );
  }

  @autobind
  _sortHistory(e) {
    this.setState({
      sortBy: e.target.value,
    });

    hashHistory.push('/prescriptions');
  }

  @autobind
  _getSortedHistory(history) {
    const { sortBy } = this.state;

    switch (sortBy) {
      case 'name':
        return _.orderBy(history, 'name');
      default:
        return _.orderBy(history, 'startDate', 'desc');
    }
  }
}

Prescriptions.propTypes = {
  params: PropTypes.object,
  prescriptionHistory: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    prescriptionHistory: state.prescriptions.history,
  };
}

export default connect(mapStateToProps)(Prescriptions);
