import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router';
import {
  AppConfig,
  GlobalStyles,
  Colors,
} from '@healthmine/greyhound-core/src/modules';
import Button from '../../components/common/Button';
import FloatingLabel from '../common/FloatingLabel';

const LoginForm = ({
  onClick,
  saving,
  email,
  password,
  emailChange,
  passwordChange,
  signIn,
}) => {
  const styles = {
    inputContainer: {
      marginBottom: '30px',
      boxSizing: 'initial',
    },
    helpMessage: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    helpMessageLink: {
      textDecoration: 'none',
      color: Colors.linkBlue,
    },
    incorrectPass: {
      display: 'flex',
      justifyContent: 'space-around',
      color: 'red',
      marginTop: '25px',
      fontSize: '16px',
    },
    hidden: {
      display: 'none',
    },
    button: {
      width: '100px',
      padding: '10px 25px',
      marginBottom: 0,
    },
    orLabel: {
      fontSize: '16px',
      color: Colors.dark,
      margin: '0 10px',
    },
    bottomContainer: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '45px',
    },
    signInContainer: {
      alignItems: 'center',
      color: Colors.dark,
      cursor: 'pointer',
      textDecoration: 'none',
      display: 'inline-block',
      fontSize: '16px',
      ':hover': {
        color: Colors.darkenColor('#2095f3'),
      },
      ':active': {
        color: Colors.darkenColor('#2095f3', 2),
      },
    },
  };

  const RadiumLink = Radium(Link);

  const signInFooterHtml = _.get(
    AppConfig,
    'effectiveConfig.lingo.signInFooterHtml'
  );

  return (
    <form onSubmit={onClick} autoComplete="off">
      <input type="email" style={{ display: 'none', opacity: 0, height: 0 }} />
      <input
        type="password"
        style={{ display: 'none', opacity: 0, height: 0 }}
      />

      <div style={styles.inputContainer}>
        <FloatingLabel
          type="email"
          placeHolder="Email"
          isDisabled={saving}
          value={email}
          onChange={emailChange}
          id="username"
          name="username"
        />
      </div>
      <div style={styles.inputContainer}>
        <FloatingLabel
          type="password"
          placeHolder="Password"
          isDisabled={saving}
          value={password}
          onChange={passwordChange}
          id="password"
          name="password"
        />
      </div>
      <div style={[GlobalStyles.regular, styles.helpMessage]}>
        <RadiumLink style={styles.helpMessageLink} to="/retrieve-account/">
          Need help logging in?
        </RadiumLink>
      </div>
      <div style={[styles.incorrectPass, signIn.didError ? {} : styles.hidden]}>
        <span>{signIn.errorMessage}</span>
      </div>
      <div style={styles.bottomContainer}>
        <Button
          text="Login"
          style={[styles.button, GlobalStyles.regular]}
          disabled={saving}
        />
        <span style={styles.orLabel}>or</span>
        <RadiumLink
          style={[styles.signInContainer, GlobalStyles.regular]}
          to="/registration/basic-information"
        >
          Register Now
        </RadiumLink>
      </div>

      {/* eslint-disable react/no-danger */
      signInFooterHtml ? (
        <div
          dangerouslySetInnerHTML={{
            __html: AppConfig.replaceS3(signInFooterHtml),
          }}
        />
      ) : null
      /* eslint-enable react/no-danger */
      }
    </form>
  );
};

LoginForm.propTypes = {
  onClick: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  signIn: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  emailChange: PropTypes.func.isRequired,
  passwordChange: PropTypes.func.isRequired,
};

export default Radium(LoginForm);
