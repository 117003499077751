import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const AppointmentStatusHeaderMarkup = ({ appointmentStatus }) => {
  const styles = {
    statusHeader: {
      fontSize: '16px',
      fontWeight: 500,
    },
  };

  const displayText =
    appointmentStatus === 'INPAST'
      ? 'Biometric Screening Complete'
      : appointmentStatus === 'INFUTURE'
        ? 'Biometric Screening Scheduled'
        : null;

  return displayText && <h2 style={styles.statusHeader}>{displayText}</h2>;
};

AppointmentStatusHeaderMarkup.propTypes = {
  appointmentStatus: PropTypes.string.isRequired,
};

export default Radium(AppointmentStatusHeaderMarkup);
