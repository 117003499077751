import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import {
  DrawerButton,
  DrawerContent,
  DrawerNavigation,
  DrawerSectionHeader,
  DrawerSection,
  withDrawer,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';
import HowToEarnPointsDrawer from './HowToEarnPoints';
import TrackerDrawer from './tracker';
import ManageDevice from './ManageDevice';
import StopTracking from './StopTracking';
import { lowerCase } from 'lodash';
import { selectDataSources } from '@healthmine/greyhound-core/src/selectors/MyDataSelector';
import createCachedSelector from 're-reselect';
import CarePlanActions from '@healthmine/greyhound-core/src/actions/CarePlanActions';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';

const Link = (link) => (
  <a href={link.href} target="_blank">
    {link.children}
  </a>
);

const styles = () => ({
  breakWord: {
    wordBreak: 'break-word',
  },
});

const sourceSelector = (state, props) => {
  const sources = selectDataSources(state);
  return props.tracker.sourceSelector(sources);
};

const propSelector = createCachedSelector(sourceSelector, (sources) => ({
  hasSources: !!sources.filter(({ name }) => name !== 'manual').length,
}))((state, props) => props.tracker.dataKey);

const StartTrackingDrawer = ({
  classes,
  tracker,
  howToEarnPoints,
  startTracking,
  stopTracking,
  hasSources,
}) => {
  const { displayName, verboseDescription } = tracker;

  return (
    <DrawerContent
      title={`Start Tracking My ${displayName}`}
      headerAction={{
        label: 'Remove tracker',
        color: 'cosmopolitan',
        onClick: stopTracking,
      }}
    >
      <DrawerSection>
        <Typography className={classes.breakWord}>
          <ReactMarkdown
            source={verboseDescription}
            renderers={{ link: Link }}
          />
        </Typography>
      </DrawerSection>
      <DrawerSectionHeader title="Points" />
      <DrawerNavigation
        title={`How I earn points for tracking ${lowerCase(displayName)}`}
        onClick={howToEarnPoints}
      />
      <DrawerSectionHeader title="Configure Device" />
      <ManageDevice tracker={tracker} />
      <DrawerSection grow />
      <DrawerButton
        text={hasSources ? 'Start Tracking With Device' : 'Track Manually'}
        onClick={startTracking}
      />
    </DrawerContent>
  );
};

StartTrackingDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
  tracker: PropTypes.object.isRequired,
  howToEarnPoints: PropTypes.func.isRequired,
  startTracking: PropTypes.func.isRequired,
  stopTracking: PropTypes.func.isRequired,
  hasSources: PropTypes.bool,
};

export default compose(
  pure,
  withDrawer,
  withStyles(styles),
  connect(
    (state, props) => propSelector(state, props),
    (dispatch) => ({
      actions: bindActionCreators(
        {
          enableMyTracking: CarePlanActions.enableMyTracking,
        },
        dispatch
      ),
    })
  ),
  withHandlers({
    howToEarnPoints: ({ drawer, tracker }) => () => {
      drawer.open(HowToEarnPointsDrawer, { tracker });
    },
    startTracking: ({ drawer, tracker, actions }) => () => {
      actions
        .enableMyTracking(tracker.type)
        .then(drawer.open(TrackerDrawer, { tracker }));
    },
    stopTracking: ({ drawer, tracker }) => () => {
      drawer.open(StopTracking, { tracker });
    },
  })
)(StartTrackingDrawer);
