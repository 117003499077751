import _ from 'lodash';
import { connect } from 'react-redux';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { selectGoalForTracking } from '@healthmine/greyhound-core/src/selectors/CarePlanSelector';
import * as HraSelectors from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import HealthRiskAssessmentSummaryMarkup from './HealthRiskAssessmentSummaryMarkup';

export default connect((state) => {
  const memberQuestionnaire = _.find(
    HraSelectors.selectMemberQuestionnaires(state),
    ['status', AppConstants.QuestionnaireStatus.COMPLETED]
  );

  const assessmentDate = _.get(memberQuestionnaire, 'endTimestamp');

  const healthRisks = _.get(state, 'healthRisks.riskLevels');

  const overallRiskLevel = _.get(state, 'healthRisks.overallRiskLevel');

  const preventiveGoal = selectGoalForTracking(state, 'PREVENTIVE');

  const recommendations = _.map(
    _.get(preventiveGoal, 'actions'),
    (healthActionObject) => ({
      healthArea: healthActionObject.shortDescription,
      recommendationText: healthActionObject.subTitle,
    })
  );

  return {
    healthRisks,
    recommendations,
    assessmentDate,
    overallRiskLevel,
  };
})(HealthRiskAssessmentSummaryMarkup);
