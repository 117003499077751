import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import InputElement from 'react-input-mask';
import Button from '../common/Button';
import ChangePassword from './ChangePassword';
import OptOut from './OptOut';

const SettingsAccountMarkup = ({
  inboundSSOEnabled,
  emailAddress,
  mobileNumber,
  street,
  city,
  state,
  zipCode,
  editPassword,
  toggleEditPassword,
  updateMobileNumber,
  saveSettings,
  isFormValid,
  saveSuccess,
  saveFailed,
}) => {
  const styles = {
    container: {
      padding: '0 75px',
    },
    formField: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '80px',
    },
    formLabel: {
      flex: '1 0 33.34%',
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.bodyText,
    },
    formInput: {
      flex: '2 0 66.66%',
      position: 'relative',
    },
    input: {
      fontSize: '16px',
      lineHeight: '15px',
    },
    disabledInput: {
      borderBottom: 'none',
      backgroundColor: Colors.light,
      color: Colors.contentText,
    },
    password: {
      display: 'inline-block',
      width: '200px',
    },
    link: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.highlightBlue,
      cursor: 'pointer',
    },
    error: {
      color: 'red',
      fontSize: '16px',
      display: 'block',
      position: 'absolute',
      top: '45px',
    },
    button: {
      minWidth: '200px',
      marginBottom: 0,
      padding: '8px 20px',
    },
    formFooter: {
      display: 'flex',
      alignItems: 'center',
      margin: '30px 0',
    },
    successMessage: {
      padding: '5px 10px',
      color: Colors.navyBlue,
    },
    errorMessage: {
      padding: '5px 10px',
      color: Colors.heatherRed,
    },
  };

  return (
    <div style={styles.container}>
      {!inboundSSOEnabled ? (
        <div style={styles.formField}>
          <label style={styles.formLabel}>Email Address:</label>
          <div style={styles.formInput}>
            <input
              type="email"
              className="inputMaterial"
              style={[styles.input, styles.disabledInput]}
              value={emailAddress}
              onChange={() => {}}
              disabled
            />
          </div>
        </div>
      ) : null}

      {!inboundSSOEnabled ? (
        <div style={styles.formField}>
          <label style={styles.formLabel}>Password:</label>
          <div style={styles.formInput}>
            <input
              type="password"
              className="inputMaterial"
              style={[styles.input, styles.disabledInput, styles.password]}
              value="............"
              onChange={() => {}}
              disabled
            />
            <span style={styles.link} onClick={toggleEditPassword}>
              Edit
            </span>

            {editPassword && (
              <ChangePassword
                editPassword={editPassword}
                toggleEditPassword={toggleEditPassword}
              />
            )}
          </div>
        </div>
      ) : null}

      <div style={styles.formField}>
        <label style={styles.formLabel}>Mobile Number:</label>
        <div style={styles.formInput}>
          <InputElement
            type="text"
            className="inputMaterial"
            style={styles.input}
            value={mobileNumber.value}
            onChange={updateMobileNumber}
            mask={'(999)-999-9999'}
            maskChar=""
          />
          {mobileNumber.error && (
            <span style={styles.error}>{mobileNumber.error}</span>
          )}
        </div>
      </div>
      <div style={styles.formField}>
        <label style={styles.formLabel}>Street:</label>
        <div style={styles.formInput}>
          <input
            type="text"
            className="inputMaterial"
            style={[styles.input, styles.disabledInput]}
            value={street}
            onChange={() => {}}
            disabled
          />
        </div>
      </div>
      <div style={styles.formField}>
        <label style={styles.formLabel}>City:</label>
        <div style={styles.formInput}>
          <input
            type="text"
            className="inputMaterial"
            style={[styles.input, styles.disabledInput]}
            value={city}
            onChange={() => {}}
            disabled
          />
        </div>
      </div>
      <div style={styles.formField}>
        <label style={styles.formLabel}>State:</label>
        <div style={styles.formInput}>
          <input
            type="text"
            className="inputMaterial"
            style={[styles.input, styles.disabledInput]}
            value={state}
            onChange={() => {}}
            disabled
          />
        </div>
      </div>
      <div style={styles.formField}>
        <label style={styles.formLabel}>ZIP Code:</label>
        <div style={styles.formInput}>
          <input
            type="text"
            className="inputMaterial"
            style={[styles.input, styles.disabledInput]}
            value={zipCode}
            onChange={() => {}}
            disabled
          />
        </div>
      </div>

      <div style={styles.formFooter}>
        <Button
          text="Save"
          style={styles.button}
          disabled={!isFormValid}
          onClick={isFormValid ? saveSettings : null}
        />

        {saveSuccess && (
          <span style={styles.successMessage}>
            Settings saved successfully!
          </span>
        )}

        {saveFailed && (
          <span style={styles.errorMessage}>Failed to save Settings!</span>
        )}
      </div>
      <OptOut />
    </div>
  );
};

SettingsAccountMarkup.propTypes = {
  inboundSSOEnabled: PropTypes.bool.isRequired,
  emailAddress: PropTypes.string,
  mobileNumber: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string,
  }),
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  editPassword: PropTypes.bool.isRequired,
  toggleEditPassword: PropTypes.func.isRequired,
  updateMobileNumber: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool,
  saveSuccess: PropTypes.bool,
  saveFailed: PropTypes.bool,
};

SettingsAccountMarkup.defaultProps = {
  emailAddress: '',
  mobileNumber: '',
  street: '',
  city: '',
  state: '',
  zipCode: '',
};

export default Radium(SettingsAccountMarkup);
