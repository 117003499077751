import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure, withProps, lifecycle } from 'recompose';
import _ from 'lodash';
import { faqSelector } from '@healthmine/greyhound-core/src/selectors/FaqSelector';
import PageSection from '../../../common/page/PageSection';
import GridWrapper from '../../../common/GridWrapper';
import IconTitleCard from '../../../common/card/IconTitleCard';
import { withDrawer } from '../../../common/drawer2';
import CategoryDrawerFAQs from './CategoryDrawerFAQs';
import { hashHistory, withRouter } from 'react-router';

const FAQ = ({ faqs, selectCategory }) => {
  return (
    <PageSection title="Frequently Asked Questions">
      <GridWrapper>
        {_.map(faqs, (faq, idx) => {
          return (
            <IconTitleCard
              key={`faq_item_${idx}`}
              title={faq.category}
              iconClass={faq.iconClass}
              onClick={() => selectCategory(faq)}
            />
          );
        })}
      </GridWrapper>
    </PageSection>
  );
};

const noSpaces = (word) => _.replace(word, / /g, '');

const openDrawerOnQueryStringMatch = (drawer, location, faqCategory) => {
  if (
    _.get(location, 'query.category') ===
    noSpaces(_.get(faqCategory, 'category'))
  ) {
    drawer.open(
      CategoryDrawerFAQs,
      {
        title: _.get(faqCategory, 'category', ''),
        faqs: _.get(faqCategory, 'questions', []),
      },
      {
        large: true,
        onClose: () => {
          hashHistory.replace('/help');
        },
      }
    );
  }
};

FAQ.propTypes = {
  faqs: PropTypes.array.isRequired,
  selectCategory: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withRouter,
  withDrawer,
  connect((state) => ({
    faqs: faqSelector(state),
  })),
  withProps(({ faqs, location }) => ({
    selectCategory: (faqCategory) => {
      hashHistory.push({
        pathname: '/help',
        search: `?category=${noSpaces(faqCategory.category)}`,
      });
    },
    currentFAQCategory: _.find(
      faqs,
      (faq) =>
        _.get(location, 'query.category', '') === `${noSpaces(faq.category)}`
    ),
  })),
  lifecycle({
    componentDidMount() {
      const { drawer, location, currentFAQCategory } = this.props;
      openDrawerOnQueryStringMatch(drawer, location, currentFAQCategory);
    },
    componentDidUpdate(prevProps) {
      const { drawer, location, currentFAQCategory } = this.props;
      if (
        _.get(location, 'query.category') !==
        _.get(prevProps, 'location.query.category')
      ) {
        openDrawerOnQueryStringMatch(drawer, location, currentFAQCategory);
      }
    },
  })
)(FAQ);
