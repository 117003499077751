import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import _ from 'lodash';
import styles from '../../../common/list/ListItem.styles';
import classnames from 'classnames';
import { DrawerContent, DrawerNavigation } from '../../../common/drawer2';
import BiometricScreening from './BiometricScreening';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';

const WhatsNextDrawer = ({ classes, nudgeActions }) => (
  <DrawerContent title="What's Next">
    {_.map(nudgeActions, (item, idx) => {
      const isCompleted = !!_.get(item, 'healthAction.completed');

      return (
        <div key={`nudge_action_${idx}`} className={classes.actionItemRoot}>
          {item.type === AppConstants.NudgeActionRoutes.BIOMETRIC_SCREENING ? (
            <BiometricScreening isDrawerNav incentiveAmount={item.points} />
          ) : (
            <DrawerNavigation
              onClick={item.onClick}
              status={isCompleted ? 'positive' : 'neutral'}
              title={item.title}
              subtitle={
                item.pointsOverrideText
                  ? item.pointsOverrideText
                  : item.points
                    ? IncentiveSummary.getIncentiveEarningText(
                        isCompleted,
                        item.points
                      )
                    : undefined
              }
              subtitleClass={classnames(
                classes.incentive,
                classes.incentiveToBeEarned
              )}
            />
          )}
        </div>
      );
    })}
  </DrawerContent>
);

WhatsNextDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  nudgeActions: PropTypes.array.isRequired,
};

export default withStyles({ ...styles, actionItemRoot: { flex: '0 0 auto' } })(
  WhatsNextDrawer
);
