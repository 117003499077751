import React from 'react';
import PageSection from '../../../common/page/PageSection';
import GridWrapper from '../../../common/GridWrapper';
import IconTitleDrawerCard from '../../../common/card/IconTitleDrawerCard';
import HowToWin from './HowToWin';
import OfficialRules from '../../help/top-picks/OfficialRules';
import PreviousEntryList from './PreviousEntryList';
import PointSummary from './PointSummary';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import RedeemActivityList from './RedeemActivityList';
import RewardActivityList from './RewardActivityList';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import CheckoutSummary from '../ShoppingCart/CheckoutSummary';
import { connect } from 'react-redux';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { compose, withProps } from 'recompose';

const AboutIncentives = ({
  incentiveType,
  cartItemCount,
  hasIncentivesRuleDoc,
}) => {
  return (
    <div>
      <PageSection title={incentiveType}>
        {incentiveType === AppConstants.IncentiveType.SWEEPSTAKES ? (
          <GridWrapper>
            <IconTitleDrawerCard
              title="What are Sweepstakes?"
              iconClass="icon-Certificate"
              DrawerView={HowToWin}
              primary
            />
            {hasIncentivesRuleDoc && (
              <IconTitleDrawerCard
                iconClass="icon-Document-Scroll"
                title="Sweepstakes Rules"
                DrawerView={OfficialRules}
                largeDrawer
                primary
              />
            )}
            <IconTitleDrawerCard
              iconClass="icon-Left-Chevrons-Square"
              title="Previous Entries"
              DrawerView={PreviousEntryList}
              primary
            />
            <IconTitleDrawerCard
              iconClass="icon-Paragraph-Justify-Center"
              title="Points Summary"
              DrawerView={PointSummary}
              largeDrawer
              primary
            />
          </GridWrapper>
        ) : (
          <GridWrapper>
            <IconTitleDrawerCard
              title="Shopping Cart"
              iconClass="icon-Cart"
              DrawerView={CheckoutSummary}
              largeDrawer
              primary
              route="/rewards/shopping-cart"
              onCloseRoute="/rewards"
              badgeNumber={cartItemCount}
            />
            <IconTitleDrawerCard
              iconClass="icon-Left-Chevrons-Square"
              title="Redeem Activity"
              DrawerView={RedeemActivityList}
              largeDrawer
              primary
            />
            <IconTitleDrawerCard
              iconClass="icon-Paragraph-Justify-Center"
              title="Rewards Activity"
              DrawerView={RewardActivityList}
              largeDrawer
              primary
            />
          </GridWrapper>
        )}
      </PageSection>
    </div>
  );
};

AboutIncentives.propTypes = {
  incentiveType: PropTypes.string,
  cartItemCount: PropTypes.number.isRequired,
  hasIncentivesRuleDoc: PropTypes.bool.isRequired,
};

export default compose(
  connect((state) => ({
    cartItemCount: get(state, 'rewards.shoppingCart.length', 0),
  })),
  withProps(() => ({
    hasIncentivesRuleDoc: !AppConfig.validateFeature(
      Features.NO_INCENTIVES_RULE_DOC
    ),
  }))
)(AboutIncentives);
