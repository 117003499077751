import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const styles = {
  attributeSelectorComponent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '30px',
  },
  attributeLabel: {
    color: Colors.contentText,
    fontSize: '16px',
    marginBottom: '10px',
  },
  attributeSelector: {
    height: '34px',
    width: '100px',
    fontSize: '16px',
    color: Colors.darkGray,
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: '6px',
    marginRight: '20px',
    paddingLeft: '5px',
  },
  configuredAttributeValue: {
    color: Colors.bodyText,
    fontSize: '18px',
  },
};

const AttributeSelector = ({
  label,
  attributes,
  template = '',
  value,
  valueChanged,
}) => (
  <div style={styles.attributeSelectorComponent}>
    <div style={styles.attributeLabel}>{label}</div>
    {attributes && attributes.length > 1 ? (
      <select
        style={styles.attributeSelector}
        value={value}
        onChange={valueChanged}
      >
        {attributes.map((attribute, index) => (
          <option key={`attribute-${index}`} value={attribute}>
            {_.capitalize(attribute)}
            {template}
          </option>
        ))}
      </select>
    ) : (
      <div style={styles.configuredAttributeValue}>
        {value}
        {template}
      </div>
    )}
  </div>
);

AttributeSelector.propTypes = {
  label: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  valueChanged: PropTypes.func,
  template: PropTypes.string,
};

export default Radium(AttributeSelector);
