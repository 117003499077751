import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure, withProps } from 'recompose';
import moment from 'moment';
import _ from 'lodash';
import {
  DrawerContent,
  DrawerSection,
  DrawerSectionHeader,
} from '../../../common/drawer2';
import StatusWidget from '../../../common/widgets/StatusWidget';
import Typography from '../../../common/Typography';

const PreviousEntryView = ({
  title,
  status,
  subtitle,
  memberEntries,
  memberWins,
  winners,
}) => {
  return (
    <DrawerContent title={title} status={status} subtitle={subtitle}>
      <DrawerSection>
        <StatusWidget status={memberEntries} statusNote="total entries" noBar />
      </DrawerSection>
      <DrawerSection>
        <StatusWidget status={memberWins} statusNote="how many you won" noBar />
      </DrawerSection>
      <DrawerSectionHeader title="Winners" />
      {_.map(winners, (winnerName, idx) => {
        return (
          <DrawerSection key={`entry_winner_${idx}`}>
            <Typography secondary>{winnerName}</Typography>
          </DrawerSection>
        );
      })}
    </DrawerContent>
  );
};

PreviousEntryView.propTypes = {
  closeDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['positive', 'negative']).isRequired,
  subtitle: PropTypes.string.isRequired,
  memberEntries: PropTypes.number.isRequired,
  memberWins: PropTypes.number.isRequired,
  winners: PropTypes.array.isRequired,
};

export default compose(
  pure,
  connect((state) => ({
    winners: state.sweepstakes.winners,
  })),
  withProps(({ memberWins, closeDate }) => {
    return {
      status: memberWins > 0 ? 'positive' : 'negative',
      subtitle: `closed on ${moment(closeDate).format('MM/DD/YYYY')}`,
    };
  })
)(PreviousEntryView);
