import React from 'react';
import PropTypes from 'prop-types';
import { AppConfig, Assets } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import _ from 'lodash';

const HealthActionFormLink = ({ style, actionCode = null }) => {
  const toggle = AppConfig.validateFeature(Features.CREDIT_FORM);

  const { incentiveType, lingo } = AppConfig.effectiveConfig;

  const healthActionLingo = _.get(lingo, 'healthAction', 'Health Action');

  const isReward = incentiveType === AppConstants.IncentiveType.REWARDS;

  const incentiveText = isReward ? 'award' : 'points';
  let carePlanCheck = true;
  if (actionCode) {
    carePlanCheck = [
      AppConstants.HealthActions.COMPLETE_PREVENTIVE_VISIT_ANNUAL,
      AppConstants.HealthActions.COMPLETE_PREVENTIVE_VISIT,
      AppConstants.HealthActions.CERVICAL_CANCER_SCREENING_COMPLIED,
      AppConstants.HealthActions.CERVICAL_CANCER_SCREENING_EXEMPTED,
      AppConstants.HealthActions.CERVICAL_CANCER_SCREENING_OTHER,
      AppConstants.HealthActions.CERVICAL_CANCER_SCREENING,
      AppConstants.HealthActions.PAP_TESTING,
      AppConstants.HealthActions.PAP_TESTING_HPV,
      AppConstants.HealthActions.COLORECTAL_CANCER_SCREENING,
      AppConstants.HealthActions.COLORECTAL_CANCER_SCREENING_COMPLIED,
      AppConstants.HealthActions.COLORECTAL_CANCER_SCREENING_EXEMPTED,
      AppConstants.HealthActions.BREAST_CANCER_SCREENING_COMPLIED,
      AppConstants.HealthActions.BREAST_CANCER_SCREENING_EXEMPTED,
      AppConstants.HealthActions.BREAST_CANCER_SCREENING_OTHER,
      AppConstants.HealthActions.MAMMOGRAPHY,
      AppConstants.HealthActions.FLU_SHOT,
      AppConstants.HealthActions.RETINAL_EXAM,
      AppConstants.HealthActions.BONE_DENSITY_SCREENING,
      AppConstants.HealthActions.FALL_RISK_ASSESSMENT,
      AppConstants.HealthActions.KIDNEY_FUNCTION_TESTS,
      AppConstants.HealthActions.A1C,
    ].includes(actionCode);
  }
  return toggle && carePlanCheck ? (
    <p style={style}>
      {/* eslint-disable max-len */
      healthActionLingo === 'Healthy Action' ? (
        <span>
          Please allow 30-60 days following the date of service for your claims
          data to process and reflect your {incentiveText} before submitting the{' '}
          <a href={Assets.getAssetURL('ha_credit_form.pdf')} target="_blank">
            {healthActionLingo} Credit Form
          </a>.
        </span>
      ) : (
        <span>
          Please allow 30-60 days following the date of service for your claims
          data to process and reflect your {incentiveText} before submitting the{' '}
          {healthActionLingo} Credit Form. The form can be accessed in the
          general section of the <a href="/#/faq">FAQs</a>.
        </span>
      )
      /* eslint-enable max-len */
      }
    </p>
  ) : (
    <div />
  );
};

HealthActionFormLink.propTypes = {
  style: PropTypes.object,
  actionCode: PropTypes.string,
};

export default HealthActionFormLink;
