import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import EducationAction from './EducationAction';
import Styles from './ActionList.styles';

const EducationActionList = ({ list, viewHealthLibrary }) => {
  const emptyList = list.length < 1;
  return (
    <div style={Styles.root}>
      <div
        style={[
          Styles.header,
          { borderBottom: !emptyList ? '1px solid' : 'none' },
          { backgroundColor: emptyList ? '#eaeaea' : '' },
        ]}
      >
        <a style={Styles.headerInfo} onClick={viewHealthLibrary}>
          <div style={[Styles.bubble, { backgroundColor: '#6217CA' }]}>
            <i
              className="thin-0007_book_reading_read_bookmark"
              style={Styles.icon}
            />
          </div>
          <div style={Styles.titleWrapper}>
            <div style={Styles.title}>Health Library</div>
            <div style={Styles.subTitle}>Learn More About My Health Risks</div>
          </div>
        </a>
        {emptyList && (
          <a style={Styles.CTA} onClick={viewHealthLibrary}>
            View Health Library
          </a>
        )}
      </div>
      {_.map(list, (item, idx) => {
        return (
          <EducationAction
            key={`education_item_${idx}`}
            title={item.title}
            description={item.description}
            incentiveAmount={item.incentiveAmount}
            url={item.url}
            isEven={(idx + 1) % 2 === 0}
            viewEducation={item.viewEducation}
          />
        );
      })}
    </div>
  );
};

EducationActionList.propTypes = {
  list: PropTypes.array,
  viewHealthLibrary: PropTypes.func,
};

export default Radium(EducationActionList);
