import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { PopupMenuActions } from '../../actions';
import HeaderMenuMarkup from './HeaderMenuMarkup';

class HeaderMenu extends React.Component {
  static MENU_VISIBILITY_DELAY = 250;

  constructor() {
    super(...arguments);

    this.state = {
      showHoverState: false,
      showMenu: false,
    };
  }

  render() {
    const {
      menuId,
      menuType,
      primaryRoute,
      title,
      subTitle,
      iconClassName,
      iconBubbleContent,
      activeRule,
      customStyles,
      children,
      tutorial,
      uhcIcon,
    } = this.props;

    const currentLocation = _.get(this.props, 'location.pathname', '');

    const isActive = activeRule
      ? activeRule.test(currentLocation)
      : _.startsWith(currentLocation, primaryRoute);

    const isDropdownMenu = !!_.size(children);

    return (
      <HeaderMenuMarkup
        menuId={menuId}
        menuType={menuType}
        isDropdownMenu={isDropdownMenu}
        title={title}
        subTitle={subTitle}
        iconClassName={iconClassName}
        uhcIcon={uhcIcon}
        iconBubbleContent={iconBubbleContent}
        customStyles={customStyles}
        showActiveState={isActive}
        showHoverState={this.state.showHoverState}
        showMenu={this.state.showMenu}
        onHoverStateChange={this._onHoverStateChange}
        onCaptionClick={this._onCaptionClick}
        onMenuClick={this._onMenuClick}
        tutorial={tutorial}
      >
        {children}
      </HeaderMenuMarkup>
    );
  }

  _onHoverStateChange = (showHoverState) => {
    if (!_.get(this.props.tutorial, 'show', false)) {
      if (showHoverState) {
        const { popupMenuVisible } = this.props;

        clearTimeout(this.hideMenuTimeout);

        if (popupMenuVisible) {
          this.showMenuTimeout = setTimeout(
            this._showMenu,
            HeaderMenu.MENU_VISIBILITY_DELAY
          );
        } else {
          this._showMenu();
        }
      } else {
        clearTimeout(this.showMenuTimeout);
        this.hideMenuTimeout = setTimeout(
          this._hideMenu,
          HeaderMenu.MENU_VISIBILITY_DELAY
        );
      }

      this.setState({
        showHoverState,
      });
    }
  };

  _showMenu = () => {
    this.props.actions.setPopupMenuVisibility(true);

    this.setState({
      showMenu: true,
    });
  };

  _hideMenu = () => {
    this.props.actions.setPopupMenuVisibility(false);

    this.setState({
      showMenu: false,
    });
  };

  _onCaptionClick = () => {
    const { primaryRoute, router } = this.props;

    if (primaryRoute) {
      router.push(primaryRoute);
      this._onMenuClick();
    }
  };

  _onMenuClick = () => {
    clearTimeout(this.hideMenuTimeout);
    clearTimeout(this.showMenuTimeout);

    this.setState({
      showHoverState: false,
      showMenu: false,
    });
  };
}

HeaderMenu.propTypes = {
  menuId: PropTypes.string.isRequired,
  menuType: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  primaryRoute: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  iconClassName: PropTypes.string,
  iconBubbleContent: PropTypes.string,
  activeRule: PropTypes.instanceOf(RegExp),
  customStyles: PropTypes.object,
  router: PropTypes.object.isRequired,
  uhcIcon: PropTypes.string,

  actions: PropTypes.shape({
    setPopupMenuVisibility: PropTypes.func.isRequired,
  }).isRequired,

  popupMenuVisible: PropTypes.bool.isRequired,
  location: PropTypes.object,

  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  tutorial: PropTypes.object,
};

HeaderMenu.defaultProps = {
  title: '',
  subTitle: '',
  iconClassName: '',
  iconBubbleContent: '',
  customStyles: {},
};

function mapStateToProps(state) {
  const { visible: popupMenuVisible } = state.popupMenu;

  return {
    popupMenuVisible,
    location: state.routing.locationBeforeTransitions,
  };
}

function mapDispatchToProps(dispatch) {
  const { setPopupMenuVisibility } = PopupMenuActions;

  return {
    actions: bindActionCreators({ setPopupMenuVisibility }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(HeaderMenu)
);
