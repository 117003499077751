import { withRouter } from 'react-router';
import { compose, flattenProp, withHandlers, withProps } from 'recompose';
import CardItemMarkup from './CardItem.markup';
import _ from 'lodash';

export default compose(
  withRouter,
  flattenProp('carePlan'),
  withHandlers({
    selectCarePlan: ({ router, carePlan }) => () => router.push(carePlan.route),
  }),
  withProps(({ pastDueActions, requiredSoonActions, requiredActions }) => {
    const nextAction = _.get(
      pastDueActions,
      0,
      _.get(requiredSoonActions, 0, _.get(requiredActions, 0, ''))
    );
    return {
      nextAction: _.get(nextAction, 'title', ''),
    };
  })
)(CardItemMarkup);
