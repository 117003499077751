import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory, withRouter, hashHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { HraActions } from '@healthmine/greyhound-core/src/actions';
import {
  AppConstants,
  LanguageConstants,
} from '@healthmine/greyhound-core/src/constants';
import * as HraSelectors from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import HealthRiskAssessmentPrologueMarkup from './HealthRiskAssessmentPrologueMarkup';
import HealthRiskAssessmentSurveyMarkup from './HealthRiskAssessmentSurveyMarkup';
import Page from '../common/Page';

const QUESTIONNAIRE_STATE_PROLOGUE = 1;

const QUESTIONNAIRE_STATE_SURVEY = 2;

class HealthRiskAssessment extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      questionnaireState: QUESTIONNAIRE_STATE_PROLOGUE,
      modalOpen: false,
      disableNext: false,
      isTermsModalVisible: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      _.get(nextProps, 'currentQuestions[0].id') !==
      _.get(this.props, 'currentQuestions[0].id')
    ) {
      this.setState({
        isValid: undefined,
        disableNext: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.actions.clearErrors();
  };

  render = () => {
    return (
      <Page
        title={AppConfig.effectiveConfig.lingo.healthRiskAssessment}
        icon={'thin-0378_analytics_presentation_statistics_graph'}
        iconStyle={{ backgroundColor: '#7770ff' }}
      >
        {this._renderQuestionnaireStateUI()}
      </Page>
    );
  };

  _renderQuestionnaireStateUI = () => {
    const {
      HRAStatus,
      currentQuestions,
      currentQuestionChoices,
      currentQuestionsAnswers,
      currentQuestionIndex,
      surveyQuestionCount,
      hraLanguageCode,
      errorCount,
    } = this.props;

    switch (this.state.questionnaireState) {
      case QUESTIONNAIRE_STATE_PROLOGUE:
        return (
          <HealthRiskAssessmentPrologueMarkup
            onBack={this._onBack}
            HRAStatus={HRAStatus}
            setHraLanguage={this._setHraLanguage}
            errorCount={errorCount}
            toggleTermsModalVisibility={this._toggleTermsModalVisibility}
            isTermsModalVisible={this.state.isTermsModalVisible}
          />
        );

      case QUESTIONNAIRE_STATE_SURVEY: {
        return (
          <HealthRiskAssessmentSurveyMarkup
            questions={currentQuestions}
            answerOptions={currentQuestionChoices}
            questionsAnswers={currentQuestionsAnswers || []}
            questionNumber={currentQuestionIndex + 1}
            questionCount={surveyQuestionCount}
            isValid={this.state.isValid}
            onBack={this._onBack}
            onQuestionAnswered={this._onQuestionAnswered}
            onSubmitAnswers={this._onContinue}
            modalOpen={this.state.modalOpen}
            toggleModal={this._toggleModal}
            leaveAssesment={this._leaveAssesment}
            skipQuestion={this._skipQuestion}
            hraLanguageCode={hraLanguageCode}
            errorCount={errorCount}
            disableNext={this.state.disableNext}
          />
        );
      }
    }
  };

  _onBack = () => {
    this.props.actions.clearErrors();

    switch (this.state.questionnaireState) {
      case QUESTIONNAIRE_STATE_PROLOGUE:
        browserHistory.goBack();
        break;

      case QUESTIONNAIRE_STATE_SURVEY:
        if (!this.props.currentQuestionIndex) {
          this.setState({
            questionnaireState: QUESTIONNAIRE_STATE_PROLOGUE,
          });
        } else {
          this.props.actions.previous();
        }

        break;
    }
  };

  _onQuestionAnswered = (questionId, answer, isValid) => {
    this.setState({
      isValid,
      disableNext: false,
    });

    this.props.actions.answer(questionId, [].concat(answer));
  };

  _skipQuestion = (questionId) => {
    this.props.actions.skip(questionId);
  };

  _onContinue = () => {
    switch (this.state.questionnaireState) {
      case QUESTIONNAIRE_STATE_PROLOGUE: {
        const { firstUnansweredQuestionIndex } = this.props;

        if (firstUnansweredQuestionIndex) {
          this.props.actions.goTo(firstUnansweredQuestionIndex);
        }

        this._handleRouteChangeListener();
        this.setState({
          questionnaireState: QUESTIONNAIRE_STATE_SURVEY,
        });

        break;
      }

      case QUESTIONNAIRE_STATE_SURVEY:
        this.setState({
          disableNext: true,
        });

        if (
          this.props.currentQuestionIndex ===
          this.props.surveyQuestionCount - 1
        ) {
          this.props.actions
            .save([this.props.currentQuestions[0].id])
            .then(() => this.props.actions.complete())
            .then(() =>
              this.setState({ shouldLeave: true }, () =>
                hashHistory.replace('/hra-completion')
              )
            );
        } else {
          this.props.actions.next();
        }

        break;
    }
  };

  _handleRouteChangeListener = () => {
    const { router, route } = this.props;

    router.setRouteLeaveHook(
      route,

      (currentRoute) => {
        if (
          this.state.questionnaireState !== QUESTIONNAIRE_STATE_SURVEY ||
          this.state.shouldLeave
        ) {
          return true;
        } else {
          this.setState({
            modalOpen: !this.state.modalOpen,
            pathname: currentRoute.pathname,
          });

          return false;
        }
      }
    );
  };

  _setHraLanguage = (languageCode) => {
    this.props.actions
      .setHraLanguage(languageCode)
      .then(() => this._onContinue());
  };

  _toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  _leaveAssesment = () => {
    this.props.actions.save([this.props.currentQuestions[0].id]);

    this.setState(
      {
        shouldLeave: true,
      },

      () => hashHistory.push(this.state.pathname)
    );
  };

  _toggleTermsModalVisibility = () => {
    this.setState({
      isTermsModalVisible: !this.state.isTermsModalVisible,
    });
  };
}

function mapStateToProps(state) {
  const { count: errorCount } = state.errors;

  const currentQuestionIndex = state.hra.activeQuestionIndex;

  const firstUnansweredQuestionIndex = HraSelectors.selectFirstUnansweredQuestionIndex(
    state
  );

  const questionnaire = HraSelectors.selectCurrentQuestionnaire(state);

  const filteredQuestionSteps = HraSelectors.selectFilteredQuestionSteps(state);

  const surveyQuestionCount = _.size(filteredQuestionSteps);

  const memberQuestionnaire = HraSelectors.selectMostRecentMemberQuestionnaire(
    state
  );

  const HRAStatus = HraSelectors.selectHraStatus(state);

  const hraLanguageCode = _.get(
    memberQuestionnaire,
    ['answers', AppConstants.HRAQuestionId.LANGUAGE, 'answers', 0, 'answer'],
    LanguageConstants.LanguageCodes.EN
  );

  const allQuestions = _(_.get(questionnaire, 'questions'))
    .map((question) => [question.id, question])
    .fromPairs()
    .value();

  const currentQuestions = _.map(
    _.get(filteredQuestionSteps, `[${currentQuestionIndex}].questions`),
    (questionId) => _.get(allQuestions, questionId)
  );

  const currentQuestionChoices = _.filter(
    _.get(currentQuestions, '[0].answerOptions'),
    'active'
  );

  const activeAnswerOptions = _.map(currentQuestionChoices, 'id');
  const currentQuestionsAnswers = _.map(currentQuestions, (question) => {
    const questionAnswers = _.map(
      _.get(memberQuestionnaire, ['answers', question.id, 'answers']),
      'answer'
    );
    return {
      id: question.id,
      answers: ['RADIO_BUTTONS', 'CHECKBOXES'].includes(question.type)
        ? _.intersection(activeAnswerOptions, questionAnswers)
        : questionAnswers,
    };
  });
  return {
    hraLanguageCode,
    currentQuestions,
    currentQuestionChoices,
    currentQuestionsAnswers,
    currentQuestionIndex,
    surveyQuestionCount,
    firstUnansweredQuestionIndex,
    HRAStatus,
    errorCount,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    setHraLanguage,
    answer,
    save,
    next,
    previous,
    goTo,
    skip,
    complete,
    clearErrors,
  } = HraActions;

  return {
    actions: bindActionCreators(
      {
        setHraLanguage,
        answer,
        save,
        next,
        previous,
        goTo,
        skip,
        complete,
        clearErrors,
      },
      dispatch
    ),
  };
}

HealthRiskAssessment.propTypes = {
  currentQuestions: PropTypes.array,
  currentQuestionChoices: PropTypes.arrayOf(PropTypes.object),
  currentQuestionsAnswers: PropTypes.array,
  currentQuestionIndex: PropTypes.number.isRequired,
  surveyQuestionCount: PropTypes.number.isRequired,
  firstUnansweredQuestionIndex: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  HRAStatus: PropTypes.string,
  router: PropTypes.object,
  route: PropTypes.object,
  hraLanguageCode: PropTypes.string,
  errorCount: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(HealthRiskAssessment)
);
