import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';

const styles = {
  closeButton: {
    position: 'fixed',
    left: 'calc(50% + 450px)',
    transform: 'translateX(-50%)',
    height: '38px',
    width: '38px',
    cursor: 'pointer',
    stroke: Colors.white,
    fill: Colors.bodyText,
    flex: 0,
    zIndex: 1000,
  },
};

const OffsetCloseButton = ({
  onClick,
  router,
  impersonating,
  stylesOverride,
}) => {
  return (
    <svg
      style={[
        styles.closeButton,
        { top: impersonating ? '155px' : '125px' },
        stylesOverride,
      ]}
      onClick={onClick || router.goBack}
    >
      <circle cx="19" cy="19" r="19" strokeWidth="0.5" />
      <line x1="25%" x2="75%" y1="25%" y2="75%" strokeWidth="1" />
      <line x1="25%" x2="75%" y1="75%" y2="25%" strokeWidth="1" />
    </svg>
  );
};

OffsetCloseButton.propTypes = {
  onClick: PropTypes.func,
  router: PropTypes.object.isRequired,
  impersonating: PropTypes.bool,
  stylesOverride: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    impersonating: !!state.signIn.impersonationEmail,
  };
}
export default compose(connect(mapStateToProps), withRouter, Radium)(
  OffsetCloseButton
);
