import React from 'react';
import HeroBanner from './HeroBanner';
import WhatsNext from './whats-next/WhatsNext';
import CarePlan from './care-plan/CarePlan';
import { hashHistory } from 'react-router';
import { compose, pure, lifecycle, withProps } from 'recompose';
import {
  HealthOverviewDrawerRouteMapping,
  getHealthOverviewDrawerRouteComponent,
} from '../../common/drawer2/DrawerRouteMapping';
import _ from 'lodash';
import { withDrawer } from '../../common/drawer2';
import PropTypes from 'prop-types';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import AccountSwitcher from '../../common/AccountSwitcher';
import SweepstakesBar from './SweepstakesBar';

const HealthOverview = ({ hasHeroBanner, hasWhatsNext }) => {
  return (
    <div>
      <AccountSwitcher />
      {hasHeroBanner && <HeroBanner secondaryText="Member Rewards Program" />}
      <SweepstakesBar />
      {hasWhatsNext && <WhatsNext />}
      <CarePlan />
    </div>
  );
};

const openDrawerOnDrawerRouteMatch = (drawer, location) => {
  const matchedRoute = _.find(
    _.keys(HealthOverviewDrawerRouteMapping),
    (route) => location.pathname.includes(route)
  );

  if (matchedRoute) {
    drawer.close();
    const drawerRoute = getHealthOverviewDrawerRouteComponent(matchedRoute);
    drawer.open(_.get(drawerRoute, 'component'), null, {
      large: _.get(drawerRoute, 'isLarge'),
      onClose: () => hashHistory.replace('/health-overview'),
    });
  }
};

HealthOverview.propTypes = {
  hasHeroBanner: PropTypes.bool.isRequired,
  hasWhatsNext: PropTypes.bool.isRequired,
};

export default compose(
  pure,
  withDrawer,
  withProps(() => ({
    hasHeroBanner: AppConfig.validateFeature(Features.DASHBOARD_HERO_IMAGE),
    hasWhatsNext: !AppConfig.validateFeature(Features.NO_WHATS_NEXT),
  })),
  lifecycle({
    componentDidMount() {
      const { drawer, location } = this.props;
      openDrawerOnDrawerRouteMatch(drawer, location);
    },
  })
)(HealthOverview);
