import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Radium from 'radium';
import compose from 'recompose/compose';
import { WithTracking } from '@healthmine/greyhound-core/src/hocs/';

const styles = {
  weekDays: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '10px 30px',
    fontSize: '16px',
    fontWeight: 600,
    minWidth: '200px',
  },
  light: {
    color: Colors.lightGray,
    fontWeight: '400',
  },
  tick: {
    width: '18px',
    color: Colors.highlightBlue,
  },
  tickIcon: {
    fontSize: '18px',
    fontWeight: 600,
    fill: Colors.highlightBlue,
  },
};

const isValueRecorded = (value) =>
  value !== -1 && (_.isArray(value) ? value[0] : value) >= 1;

const styleForValue = (value) =>
  value === -1 ? styles.light : isValueRecorded(value) ? styles.tick : null;

const WeeklySummary = ({ weeklySummary }) => (
  <div style={styles.weekDays}>
    {localizedContent.days.map((day, index) => (
      <span style={styleForValue(weeklySummary[index])} key={day}>
        {isValueRecorded(weeklySummary[index]) ? (
          <i className="material-icons" style={styles.tickIcon}>
            done
          </i>
        ) : (
          day.charAt(0)
        )}
      </span>
    ))}
  </div>
);

WeeklySummary.propTypes = {
  weeklySummary: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)])
  ),
  measurement: PropTypes.object,
};

export default compose(WithTracking, Radium)(WeeklySummary);
