import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import IconTitleCard from './IconTitleCard';
import { withDrawer } from '../drawer2';
import { withRouter } from 'react-router';

const IconTitleDrawerCard = ({
  iconClass,
  title,
  primary,
  openDrawer,
  badgeNumber,
}) => {
  return (
    <IconTitleCard
      iconClass={iconClass}
      title={title}
      primary={primary}
      onClick={openDrawer}
      badgeNumber={badgeNumber}
    />
  );
};

IconTitleDrawerCard.propTypes = {
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  openDrawer: PropTypes.func.isRequired,
  DrawerView: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  largeDrawer: PropTypes.bool,
  route: PropTypes.string,
  onCloseRoute: PropTypes.string,
  badgeNumber: PropTypes.number,
};

export default compose(
  pure,
  withDrawer,
  withRouter,
  withProps(
    ({
      drawer,
      title,
      largeDrawer,
      DrawerView,
      router,
      route,
      onCloseRoute,
    }) => ({
      openDrawer: () => {
        drawer.open(
          DrawerView,
          { title },
          {
            large: largeDrawer,
            onClose: () => {
              if (onCloseRoute) router.push(onCloseRoute);
            },
          }
        );
        if (route) router.push(route);
      },
    })
  )
)(IconTitleDrawerCard);
