import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Typography from '../../common/Typography';

const styles = (theme) => ({
  main: {
    flexGrow: 1,
  },
  incentiveIcon: {
    fontSize: '25px',
    color: theme.palette.common.whiskeySour,
  },
});

const HealthAction = ({ name, incentivized, classes }) => {
  return (
    <Grid container alignItems="center">
      <Grid item className={classes.main}>
        <Typography type="h2">{name}</Typography>
      </Grid>
      {incentivized && (
        <Grid item>
          <i className={`icon-Star-Circle ${classes.incentiveIcon}`} />
        </Grid>
      )}
    </Grid>
  );
};

HealthAction.propTypes = {
  name: PropTypes.string.isRequired,
  incentivized: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HealthAction);
