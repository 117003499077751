import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    backgroundColor: 'white',
    '&:not(:last-child)': {
      borderBottom: '3px solid rgba(0,0,0,0.1)',
    },
    padding: '14px 44px',
  },
};

const ListItem = ({ children, classes, onClick }) => (
  <div
    onClick={onClick}
    className={classnames(classes.root, 'avoid-page-break')}
  >
    {children}
  </div>
);

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(ListItem);
