import { compose, pure, withStateHandlers } from 'recompose';
import MyCarePlanEnrolledMarkup from './MyCarePlan.Enrolled.markup';

export default compose(
  pure,

  withStateHandlers(
    {
      expandedActionItemIndex: -1,
    },

    {
      toggleDetails: ({ expandedActionItemIndex }) => (actionItemIndex) => ({
        expandedActionItemIndex:
          actionItemIndex === expandedActionItemIndex ? -1 : actionItemIndex,
      }),
    }
  )
)(MyCarePlanEnrolledMarkup);
