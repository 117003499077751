import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, pure } from 'recompose';
import { Grid } from '@material-ui/core';
import { withTheme, withStyles } from '@material-ui/core/styles';
import AppTheme from '@healthmine/greyhound-core/src/modules/app-themes/LeapFrog';
import { ListContext } from './List';

const styles = () => ({
  root: {
    backgroundColor: '#f4f6f7',
    padding: '30px',
    borderRadius: '4px 4px 0 0',
  },
  title: {
    fontSize: '18px',
    marginRight: '8px',
  },
  subtitle: {},
  subtext: {
    color: 'grey',
    marginTop: '8px',
  },
  icon: {
    fontSize: '30px',
    color: 'white',
  },
  iconBackground: {
    padding: '1px',
    backgroundColor: AppTheme.colors.blueGray,
    borderRadius: '50%',
  },
  pointer: {
    cursor: 'pointer',
  },
});

const ListHeader = ({ classes, color, title, subtitle, subtext }) => {
  const { state, dispatch } = useContext(ListContext);
  return (
    <Grid
      onClick={state.disabled ? undefined : () => dispatch({ type: 'toggle' })}
      container
      justify="space-between"
      alignItems="center"
      className={classnames(classes.root, 'print-titles', 'avoid-page-break', {
        [classes.pointer]: !state.disabled,
      })}
      style={{
        borderTop: `8px solid ${color || 'grey'}`,
      }}
    >
      <Grid item xs>
        <span className={classes.title}>{title}</span>
        {subtitle && <span className={classes.subtitle}>({subtitle})</span>}
        {subtext && <div className={classes.subtext}>{subtext}</div>}
      </Grid>

      <Grid item className="print-hidden">
        <Grid container alignItems="center">
          {!state.disabled && (
            <Grid item className={classes.iconBackground}>
              <i
                className={classnames(
                  state.isOpen
                    ? 'icon-Up-Chevron-Circle'
                    : 'icon-Down-Chevron-Circle',
                  classes.icon
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ListHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  color: PropTypes.string,
  iconClass: PropTypes.string,
  isMute: PropTypes.bool,
  noBubbleIcon: PropTypes.bool,
  noMargin: PropTypes.bool,
  remainingCount: PropTypes.number,
  remainingCountNote: PropTypes.string,
  rightLinks: PropTypes.arrayOf(
    PropTypes.shape({
      iconClass: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  subtitle: PropTypes.string,
  subtext: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default compose(pure, withTheme(), withStyles(styles))(ListHeader);
