import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules/';
import Modal from 'react-modal';

const HRAModal = ({ isOpen, closeHandler, leaveAssesment }) => {
  const styles = {
    customStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },

      content: {
        minWidth: '300px',
        minHeight: '200px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: '#fff',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        outline: 'none',
        padding: '50px',
      },
    },
    icon: {
      display: 'flex',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '50%',
      color: 'white',
      width: '55px',
      height: '55px',
      fontWeight: '100',
      fontSize: '25px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#7770ff',
      marginRight: '10px',
    },
    descriptionText: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '26px',
      margin: '8px 0px 0px 15px',
    },
    pageTitle: {
      color: Colors.bodyText,
      fontSize: '20px',
      fontWeight: '400',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    closebutton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      minHeight: '200px',
    },
    pageContent: {
      paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      width: '400px',
      fontSize: '18px',
    },

    imageContainer: {
      height: '150px',
      width: 'auto',
      margin: '80px',
    },
    nextLinkIcon: {
      fontSize: '30px',
      marginLeft: '10px',
    },

    pageFooter: {
      fontSize: '16px',
      fontWeight: '400',
      display: 'flex',
      marginTop: '50px',
      width: '80%',
      justifyContent: 'space-between',
    },

    backLinkContainer: {
      display: 'flex',
      alignItems: 'center',
      color: Colors.mediumGray4,
      fontWeight: '300',
    },

    nextLinkContainer: {
      display: 'flex',
      alignItems: 'center',
      color: Colors.bodyText,
    },

    footerLink: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  };

  const { lingo } = AppConfig.effectiveConfig;

  return (
    <Modal contentLabel={''} isOpen={isOpen} style={styles.customStyles}>
      <a onClick={() => closeHandler(true)} style={styles.closebutton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>
      <div style={[styles.modalContent, GlobalStyles.light]}>
        <div style={styles.pageTitle}>
          <span style={styles.icon}>
            <i className="thin-0378_analytics_presentation_statistics_graph" />
          </span>
          <span>{lingo.healthRiskAssessment}</span>
        </div>

        <div style={styles.pageContent}>
          <div>You can suspend your {lingo.assessment}.</div>
          <div>Your progress is saved so you can finish later.</div>
        </div>

        <div style={styles.pageFooter}>
          <div style={styles.backLinkContainer}>
            <span style={styles.footerLink} onClick={leaveAssesment}>
              Save &amp; Close
            </span>
          </div>

          <div style={styles.nextLinkContainer}>
            <span style={styles.footerLink} onClick={closeHandler}>
              Continue {lingo.assessment}
              <i
                className="thin-0160_arrow_next_right"
                style={styles.nextLinkIcon}
              />
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

HRAModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  leaveAssesment: PropTypes.func,
};

HRAModal.defaultProps = {
  isOpen: false,
};

export default Radium(HRAModal);
