import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import Button from '../common/Button';
import AppointmentStatusHeaderMarkup from './AppointmentStatusHeaderMarkup';
import ThirdPartyCookieWarning from '../common/ThirdPartyCookieWarning';
import WelcomeBackModal from '../common/WelcomeBackModal';
import moment from 'moment';

const AppointmentDetailMarkup = ({
  appointmentStatus,
  resultStatus,
  appointment,
  onResultsClick,
  onAppointmentCancel,
  onOpenEHS,
  showWelcomeBackModal,
  onWelcomeBackModalClosed,
  showThirdPartyCookieWarning,
  onThirdPartyCookieWarningClosed,
}) => {
  const styles = {
    container: {
      paddingLeft: '75px',
      marginTop: '30px',
      borderBottom: `1px solid ${Colors.lighterGray}`,
    },
    check: {
      backgroundColor: Colors.white,
      color: Colors.lightGreen,
      marginTop: '30px',
      marginBottom: '30px',
    },
    appointmentSection: {
      fontSize: '16px',
      marginTop: '30px',
      marginBottom: '30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '170px',
    },
    button: {
      width: '220px',
      padding: '12px',
    },
    eventSection: {
      color: Colors.navyBlue,
      fontSize: '16px',
    },
    noMsgContainer: {
      borderBottom: `1px solid ${Colors.lighterGray}`,
      marginLeft: '75px',
      marginRight: '75px',
    },
    noResultsMsg: {
      fontSize: '22px',
      color: Colors.lightGray,
      fontWeight: 400,
      marginTop: '30px',
    },
    cancelApt: {
      color: Colors.navyBlue,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: '16px',
    },
    cancelDiv: {
      justifyContent: 'center',
      alignItems: 'center',
      width: '200px',
      display: 'flex',
    },
  };

  const appointmentDate = appointment && moment(appointment.startTime);

  const statusIcon =
    appointmentStatus === 'INFUTURE' ? (
      <i
        className="thin-0154_ok_successful_check"
        style={{ fontSize: '180px', padding: '10px' }}
      />
    ) : (
      <i
        className="thin-0100_to_do_list_reminder_done"
        style={{ fontSize: '180px', padding: '20px' }}
      />
    );

  const statusBody =
    appointmentStatus === 'INFUTURE' ? (
      <div>
        <Button
          style={[styles.button, GlobalStyles.regular, { width: '200px' }]}
          text="View My Appointment"
          onClick={onOpenEHS}
        />
        <br />
        <div style={styles.cancelDiv}>
          <span onClick={onAppointmentCancel} style={styles.cancelApt}>
            Cancel My Appointment
          </span>
        </div>
      </div>
    ) : resultStatus === true ? (
      <Button
        style={[styles.button, GlobalStyles.regular]}
        text="View My Results"
        onClick={onResultsClick}
      />
    ) : (
      <h3 style={styles.noResultsMsg}>
        Doctors are evaluating your data, we will let you know when your results
        are ready.
      </h3>
    );

  return appointment ? (
    <div style={styles.container}>
      {showWelcomeBackModal ? (
        <WelcomeBackModal onClose={onWelcomeBackModalClosed} />
      ) : showThirdPartyCookieWarning ? (
        <ThirdPartyCookieWarning onClose={onThirdPartyCookieWarningClosed} />
      ) : null}
      <AppointmentStatusHeaderMarkup appointmentStatus={appointmentStatus} />
      <div>
        <div style={styles.check}>{statusIcon}</div>
        {statusBody}
      </div>
      <div style={[styles.appointmentSection, GlobalStyles.light]}>
        <div>
          <span>Location:</span>
          <br />
          <div style={styles.eventSection}>
            <span>{appointment.event.address}</span>
            <br />
            <span>{`${appointment.event.city}, ${appointment.event.state} ${
              appointment.event.zipCode
            }`}</span>
          </div>
        </div>
        <div>
          <span style={{ marginTop: '20px' }}>Day:</span>
          <br />
          <span style={styles.eventSection}>
            {appointmentDate.format('dddd, MMMM D, YYYY')}
          </span>
          <br />
        </div>
        <div>
          <span style={{ marginTop: '20px' }}>Time:</span>
          <br />
          <span style={styles.eventSection}>
            {appointmentDate.format('hh:mm a')}
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div style={styles.noMsgContainer}>
      <h2 style={styles.noResultsMsg}>
        You Don't Have Any Appointments At This Time
      </h2>
    </div>
  );
};

AppointmentDetailMarkup.propTypes = {
  appointmentStatus: PropTypes.string.isRequired,
  resultStatus: PropTypes.bool.isRequired,
  appointment: PropTypes.object,
  onResultsClick: PropTypes.func.isRequired,
  onAppointmentCancel: PropTypes.func.isRequired,
  onOpenEHS: PropTypes.func.isRequired,
  showWelcomeBackModal: PropTypes.bool.isRequired,
  onWelcomeBackModalClosed: PropTypes.func.isRequired,
  showThirdPartyCookieWarning: PropTypes.bool.isRequired,
  onThirdPartyCookieWarningClosed: PropTypes.func.isRequired,
};

export default Radium(AppointmentDetailMarkup);
