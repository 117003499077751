import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FteActions } from '@healthmine/greyhound-core/src/actions';
import HowYouFeelMarkup from '../../components/onboarding/HowYouFeelMarkup';
import _ from 'lodash';

class HowYouFeelContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }
  render() {
    const { conditions } = this.props.medConditions;

    const {
      medConditionsAnswerChoices,
      medConditions,
      questionText,
    } = this.props;

    const { addMedCond } = this.props.actions;

    return (
      <HowYouFeelMarkup
        medConditions={conditions}
        onClick={addMedCond}
        errors={this.state.errors}
        scrollDown={this._continueClickHander}
        scrollUp={this._scrollUp}
        medConditionsAnswers={
          medConditions.healthAnswer.questionAnswerOptionId[0]
        }
        medConditionsAnswerChoices={medConditionsAnswerChoices}
        question={questionText}
        skip={this._scrollDown}
      />
    );
  }
  _scrollUp = () => {
    this.props.scrollUp(this.props.step - 1);
  };

  _scrollDown = () => {
    this.props.scrollDown(this.props.step + 1);
  };

  _optionIsSelected = () => {
    let isSelected = true;

    let errors = [];
    if (this.props.medConditions.isValid !== true) {
      errors = [...errors, 'Please select one of the option'];
      isSelected = false;
    }
    this.setState({ errors });
    return isSelected;
  };

  _continueClickHander = () => {
    if (this._optionIsSelected() == false) {
      return;
    }
    const { medConditions } = this.props;

    const { postHealthAnswers } = this.props.actions;
    postHealthAnswers([medConditions.healthAnswer]).then(this._scrollDown);
  };
}

HowYouFeelContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  scrollDown: PropTypes.func.isRequired,
  scrollUp: PropTypes.func.isRequired,
  vis: PropTypes.bool,
  colorful: PropTypes.bool,
  step: PropTypes.number.isRequired,
  medConditions: PropTypes.object,
  medConditionsAnswerChoices: PropTypes.array,
  questionText: PropTypes.string,
};

function mapStateToProps(state) {
  const { medConditions, questionnaire } = state.onBoarding;

  const questions = _.get(questionnaire, 'questions');

  const medConditionsQuestion = _.find(questions, [
    'id',
    medConditions.healthAnswer.questionId,
  ]);

  const questionText = _.get(medConditionsQuestion, 'question.en');

  const medConditionsAnswerChoices = _.filter(
    _.get(medConditionsQuestion, 'answerOptions'),
    'active'
  );

  return {
    medConditions,
    medConditionsAnswerChoices,
    questionText,
  };
}

function mapDispatchToProps(dispatch) {
  const { addMedCond, postHealthAnswers } = FteActions;
  return {
    actions: bindActionCreators({ addMedCond, postHealthAnswers }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(HowYouFeelContainer)
);
