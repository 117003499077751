import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { BiometricReport } from '@healthmine/greyhound-core/src/constants/prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Dropdown from '../common/question/Dropdown';
import moment from 'moment';

const BiometricReportHeader = ({
  reportOptions,
  report,
  currentIndex,
  onReportChange,
  userInfo,
  clientMemberId,
}) => {
  const styles = {
    reportDate: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    reportDateLabel: {
      color: Colors.contentText,
      fontSize: '20px',
      flex: 1,
    },
    reportDatePicker: {
      flex: 1.25,
    },
    reportHeader: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
      marginBottom: '40px',
      paddingRight: '80px',
    },
    headerRow: {
      display: 'flex',
      fontSize: '16px',
      color: Colors.mediumGray,
      paddingBottom: '3px',
    },
    headerLabel: {
      flex: 1,
      fontWeight: 600,
    },
    headerValue: {
      flex: 2,
    },
    noResultsMsg: {
      fontSize: '22px',
      color: Colors.lightGray,
      fontWeight: 400,
    },
  };

  const age = userInfo && moment().diff(userInfo.dateOfBirth, 'years');

  return report && report.results ? (
    <div>
      <div style={styles.reportDate}>
        <div style={styles.reportDateLabel}>Screening Date:</div>
        <div style={styles.reportDatePicker}>
          <Dropdown
            options={reportOptions}
            value={currentIndex}
            onAnswered={(selectedReport) =>
              onReportChange(selectedReport.value)
            }
          />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={styles.reportHeader}>
        <div style={styles.headerRow}>
          <span style={styles.headerLabel}>Name: </span>
          <span style={styles.headerValue}>{`${userInfo.firstName} ${
            userInfo.lastName
          }`}</span>
          <span style={styles.headerLabel}>Gender: </span>
          <span style={styles.headerValue}>{userInfo.gender}</span>
        </div>
        <div style={styles.headerRow}>
          <span style={styles.headerLabel}>Age: </span>
          <span style={styles.headerValue}>{age}</span>
          <span style={styles.headerLabel}>Patient Code: </span>
          <span style={styles.headerValue}>{clientMemberId}</span>
        </div>
        <div style={styles.headerRow}>
          <span style={styles.headerLabel}>Drawn Date: </span>
          <span style={styles.headerValue}>
            {moment.utc(report.serviceDate).format('l')}
          </span>
          <span style={styles.headerLabel}>Report Date: </span>
          <span style={styles.headerValue}>
            {moment.utc(report.createdDate).format('l')}
          </span>
        </div>
        <div style={styles.headerRow}>
          <span style={styles.headerLabel}>Fasted: </span>
          <span style={styles.headerValue}>
            {report.numberOfHoursFasted > 0 ? 'Y' : 'N'}
          </span>
          <span style={styles.headerLabel} />
          <span style={styles.headerValue} />
        </div>
        <div style={[styles.headerRow, { marginTop: '20px' }]}>
          <span style={styles.headerLabel}>Height: </span>
          <span style={styles.headerValue}>
            {_getResultString(report.height)}
          </span>
          <span style={styles.headerLabel} />
          <span style={styles.headerValue} />
        </div>
        <div style={styles.headerRow}>
          <span style={styles.headerLabel}>Weight: </span>
          <span style={styles.headerValue}>
            {_getResultString(report.weight)}
          </span>
          <span style={styles.headerLabel} />
          <span style={styles.headerValue} />
        </div>
      </div>
    </div>
  ) : (
    <h2 style={styles.noResultsMsg}>No Biometric Results Found</h2>
  );
};

function _getResultString(measure) {
  return measure ? `${measure.result} ${measure.unit}` : '';
}

BiometricReportHeader.propTypes = {
  reportOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),

  report: BiometricReport,
  currentIndex: PropTypes.number,
  onReportChange: PropTypes.func.isRequired,
  userInfo: PropTypes.object,
  clientMemberId: PropTypes.string,
};

export default Radium(BiometricReportHeader);
