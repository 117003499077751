import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import PageTitle from './PageTitle';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';

const Page = ({ children, title, icon, iconStyle, titleChild, uhcIcon }) => {
  const fiveFiveUI = AppConfig.validateFeature(Features.FIVE_FIVE_UI);

  const styles = {
    page: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 0 40px',
      maxWidth: !fiveFiveUI ? '900px' : 'none',
      flex: '1',
      alignSelf: 'stretch',
      width: !fiveFiveUI ? '900px' : 'auto',
    },
  };

  return (
    <div style={styles.page}>
      <PageTitle
        title={title}
        icon={icon}
        uhcIcon={uhcIcon}
        iconStyle={iconStyle}
        titleChild={titleChild}
      />
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
  titleChild: PropTypes.node,
  uhcIcon: PropTypes.string,
};

export default Radium(Page);
