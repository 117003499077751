import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, withStateHandlers } from 'recompose';
import OneOffInput from './OneOffInput';
import { get, round, inRange, toNumber } from 'lodash';

const styles = () => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    fontSize: '18px',
    fontWeight: '400',
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
  },
  errorContainer: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  error: {
    color: 'red',
  },
});

const WeightAnswer = ({ classes, isValid, weight, onWeightChange }) => (
  <div className={classes.root}>
    <OneOffInput
      value={weight || ''}
      valid={isValid}
      label="lbs"
      placeholder="- - -"
      onChange={(e) => onWeightChange(e.target.value)}
    />
    {!isValid && (
      <div className={classnames(classes.errorContainer, classes.error)}>
        <h4>Must be between 40 and 700 lbs.</h4>
      </div>
    )}
  </div>
);

WeightAnswer.propTypes = {
  classes: PropTypes.object.isRequired,
  weight: PropTypes.number,
  onWeightChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  withStateHandlers(
    ({ currentAnswers, questionId }) => {
      const myWeightAnswer = get(currentAnswers, questionId);
      const weight = toNumber(get(myWeightAnswer, '[0].answer', 0));
      return {
        weight: weight === 0 ? null : weight,
        isValid: true,
      };
    },
    {
      onWeightChange: (___, { updateAnswers, questionId, markAsValid }) => (
        value
      ) => {
        const weight = round(value < 0 ? null : value);
        const isValid = inRange(weight, 40, 800) || weight === null;
        if (isValid) {
          markAsValid(questionId, isValid);
          updateAnswers(questionId, weight, 'TEXTBOX');
        }
        return {
          weight,
          isValid,
        };
      },
    }
  )
)(WeightAnswer);
