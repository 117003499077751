import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeAppReducer } from '@healthmine/greyhound-core/src/reducers';
import GoogleTagManager from '@healthmine/greyhound-core/src/middleware/GoogleTagManager';
import { persistState } from 'redux-devtools';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { hashHistory } from 'react-router';
import webReducers from '../reducers';

export default function configureStore() {
  const reducers = composeAppReducer({
    ...webReducers,
    routing: routerReducer,
  });
  const enhancers = [
    applyMiddleware(routerMiddleware(hashHistory), thunk, GoogleTagManager),
    persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/)),
  ];
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducers, composeEnhancers(...enhancers));

  if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
      module.hot.accept(() => {
        const nextRootReducer = composeAppReducer({
          ...webReducers,
          routing: routerReducer,
        });

        store.replaceReducer(nextRootReducer);
      });
    }
  }

  return store;
}
