import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../common/list/ListItem.styles';
import { DrawerSection } from '../../../common/drawer2';
import moment from 'moment';
import Typography from '../../../common/Typography';
import Navigational from '../../../common/icons/Navigational';
import { compose, withProps } from 'recompose';

import { Grid } from '@material-ui/core';
import classnames from 'classnames';
import { commaFormat } from '@healthmine/greyhound-core/src/utils';

const PointSummaryItem = ({
  classes,
  title,
  lastCompleted,
  amount,
  endDate,
  isGroup,
  selectGroup,
  onClick,
}) => {
  return (
    <DrawerSection onClick={isGroup && selectGroup ? onClick : null}>
      <Grid container wrap="nowrap" justify="space-between">
        <Grid xs={8} item>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item>
              <i className={classnames(classes.checkbox, 'icon-Checkmark')} />
            </Grid>
            <Grid item xs>
              <Typography title={title} type="h2">
                {title}
              </Typography>
              <Typography secondary>
                last completed on {moment(lastCompleted).format('MM/DD/YYYY')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={4} item>
          <Grid
            container
            wrap="nowrap"
            direction="row"
            justify="flex-end"
            spacing={16}
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography type="h4Medium">
                    {commaFormat(amount)} pts earned
                  </Typography>
                </Grid>
                <Grid style={{ textAlign: 'right' }} item>
                  {isGroup ? (
                    <Typography secondary>plan year total</Typography>
                  ) : (
                    <Typography secondary>
                      expires on {moment(endDate).format('MM/DD/YYYY')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {isGroup ? (
              <Grid item>
                <Navigational iconClass="icon-Right-Chevron-Circle" />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </DrawerSection>
  );
};

PointSummaryItem.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  lastCompleted: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  endDate: PropTypes.string.isRequired,
  isGroup: PropTypes.bool.isRequired,
  selectGroup: PropTypes.func,
  onClick: PropTypes.func,
  dataType: PropTypes.string,
  strategyId: PropTypes.number,
};

export default compose(
  withStyles(styles),
  withProps(({ selectGroup, dataType, strategyId, title }) => ({
    onClick: () => selectGroup(dataType, strategyId, title),
  }))
)(PointSummaryItem);
