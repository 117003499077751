import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import classnames from 'classnames';

const Navigational = ({ iconClass, className }) => {
  return (
    <Typography secondary type="h2">
      <i className={classnames(iconClass, className)} />
    </Typography>
  );
};

Navigational.propTypes = {
  iconClass: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Navigational;
