import React from 'react';
import PropTypes from 'prop-types';
import ViewDocument from '../../../common/ViewDocument';
import { DrawerContent } from '../../../common/drawer2';

const Terms = () => {
  return (
    <DrawerContent>
      <ViewDocument documentFile="terms_of_use_en.html" drawerFrame />
    </DrawerContent>
  );
};

Terms.propTypes = {
  title: PropTypes.string,
};

export default Terms;
