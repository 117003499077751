import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  root: {
    display: 'flex',
    flex: 1,
    marginLeft: '50px',
    color: Colors.bodyText,
    fontSize: '16px',
    alignItems: 'center',
    justifyContent: 'center',
  },

  select: {
    display: 'inline-block',
    fontSize: '16px',
    height: '34px',
    color: Colors.lightGray3,
    borderRadius: '5px',
    paddingLeft: '5px',
    margin: '0px 10px',
  },
};
