import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
import HealthRiskActions from '@healthmine/greyhound-core/src/actions/HealthRiskActions';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import * as HraSelectors from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import {
  DrawerContent,
  DrawerSection,
  withDrawer,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';
import { withStyles, Grid } from '@material-ui/core';
import HraHealthRisk from './HraHealthRisk';
import Navigational from '../../../common/icons/Navigational';
import AppTheme from '@healthmine/greyhound-core/src/modules/app-themes/LeapFrog';

const riskIconColorMapping = {
  'No Elevated Risk': {
    icon: 'icon-Laughing-Face',
    color: 'blueGray',
  },
  'Needs Attention': {
    icon: 'icon-Sad-Face',
    color: 'whiskeySour',
  },
  'Very Low Risk': {
    icon: 'icon-Smiley-Face',
    color: 'planet9',
  },
  'Low Risk': {
    icon: 'icon-Happy-Face',
    color: 'grannySmith',
  },
  'Moderate Risk': {
    icon: 'icon-Straight-Face',
    color: 'mimosa',
  },
  'High Risk': {
    icon: 'icon-Sad-Face',
    color: 'cosmopolitan',
  },
  'No Risk': {
    icon: 'icon-Laughing-Face',
    color: 'blueGray',
  },
  'At Risk': {
    icon: 'icon-Straight-Face',
    color: 'mimosa',
  },
};

const overallRiskMap = {
  low: {
    text: 'Low Overall Risk',
    color: AppTheme.colors.grannySmith,
    description:
      // eslint-disable-next-line max-len
      'Great job, keep up the good work! Your results show little to no increased risk to your health. Please take a moment to review each area and share the good news with your doctor during your next appointment.',
  },
  moderate: {
    text: 'Moderate Overall Risk',
    color: AppTheme.colors.mimosa,
    description:
      // eslint-disable-next-line max-len
      'Your results show some areas could be affecting your health. Please take a moment to review each area and share with your doctor during your next appointment. Taking simple steps today can lead to a healthier tomorrow.',
  },
  high: {
    text: 'Elevated Overall Risk',
    color: AppTheme.colors.cosmopolitan,
    description:
      // eslint-disable-next-line max-len
      'Your results show five or more areas could be affecting your health. Please take a moment to review each area and share with your doctor during your next appointment. Taking simple steps today can lead to a healthier tomorrow.',
  },
  none: {
    text: 'No Overall Risk',
    color: AppTheme.colors.planet9,
    description:
      // eslint-disable-next-line max-len
      'Great job, keep up the good work! Your results show little to no increased risk to your health. Please take a moment to review each area and share the good news with your doctor during your next appointment.',
  },
  ['Needs Attention']: {
    text: 'Elevated Overall Risk',
    color: AppTheme.colors.whiskeySour,
    description:
      // eslint-disable-next-line max-len
      'Your results show some areas could be affecting your health. Please take a moment to review each area and share with your doctor during your next appointment. Taking simple steps today can lead to a healthier tomorrow.',
  },
  ['No Elevated Risk']: {
    text: 'No Elevated Overall Risk',
    color: AppTheme.colors.planet9,
    description:
      // eslint-disable-next-line max-len
      'Great job, keep up the good work! Your results show little to no increased risk to your health. Please take a moment to review each area and share the good news with your doctor during your next appointment.',
  },
};

const styles = () => ({
  assessmentDate: {
    textAlign: 'center',
  },
  overallRisk: {
    marginBottom: '8px',
  },
});

const HraSummary = ({
  assessmentDate,
  classes,
  healthRisks,
  openHealthRisk,
  overallRiskLevel,
}) => {
  return (
    <DrawerContent title="Health Risk Assessment Summary">
      <DrawerSection>
        <Typography
          className={classes.overallRisk}
          center
          type="h2"
          color={overallRiskMap[overallRiskLevel].color}
        >
          {overallRiskMap[overallRiskLevel].text}
        </Typography>
        <Typography center type="base" color="black">
          {overallRiskMap[overallRiskLevel].description}
        </Typography>
      </DrawerSection>
      <DrawerSection>
        <Typography className={classes.assessmentDate}>
          Assessment Date: {moment(assessmentDate).format('MM/DD/YYYY')}
        </Typography>
      </DrawerSection>
      {_.map(healthRisks, (healthRisk, idx) => {
        return (
          <DrawerSection
            key={`health_risk_${idx}`}
            onClick={() => openHealthRisk(healthRisk)}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={10}>
                <Grid container alignItems="center" spacing={8}>
                  <Grid item>
                    <Typography type="h2" color={healthRisk.color}>
                      <i className={healthRisk.icon} />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography type="h2">{healthRisk.name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Navigational iconClass="icon-Right-Chevron-Circle" />
              </Grid>
            </Grid>
          </DrawerSection>
        );
      })}
    </DrawerContent>
  );
};

HraSummary.propTypes = {
  assessmentDate: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  healthRisks: PropTypes.array.isRequired,
  openHealthRisk: PropTypes.func.isRequired,
  overallRiskLevel: PropTypes.oneOf(['none', 'low', 'moderate', 'high']),
};

export default compose(
  pure,
  withDrawer,
  withHandlers({
    openHealthRisk: ({ drawer }) => (healthRisk) =>
      drawer.open(HraHealthRisk, {
        healthRisk,
      }),
  }),
  connect(
    (state) => {
      const memberQuestionnaire = _.find(
        HraSelectors.selectMemberQuestionnaires(state),
        ['status', AppConstants.QuestionnaireStatus.COMPLETED]
      );

      const riskLevels = _.get(state, 'healthRisks.riskLevels');

      const overallRiskLevel = _.get(state, 'healthRisks.overallRiskLevel');

      const healthRisks = _.map(riskLevels, (healthRisk) => {
        const { color, icon } = _.get(riskIconColorMapping, healthRisk.level);
        return {
          ...healthRisk,
          icon,
          color,
        };
      });

      return {
        healthRisks,
        overallRiskLevel,
        assessmentDate: _.get(memberQuestionnaire, 'endTimestamp'),
      };
    },
    (dispatch) =>
      bindActionCreators(
        _.pick(HealthRiskActions, ['viewHealthRisks']),
        dispatch
      )
  ),
  lifecycle({
    componentDidMount() {
      this.props.viewHealthRisks();
    },
  }),
  withStyles(styles)
)(HraSummary);
