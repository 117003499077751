import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  compose,
  pure,
  setPropTypes,
  withHandlers,
  withStateHandlers,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { HraActions } from '@healthmine/greyhound-core/src/actions';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import * as HraSelectors from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import SatisfactionSurveyMarkup from './SatisfactionSurvey.markup';

export default compose(
  pure,

  setPropTypes({
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(_.values(AppConstants.QuestionType)),
        question: PropTypes.objectOf(PropTypes.string).isRequired,

        answerOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            option: PropTypes.objectOf(PropTypes.string).isRequired,
            score: PropTypes.number.isRequired,
            displayOrder: PropTypes.number.isRequired,
          })
        ),
      })
    ),
  }),

  connect(
    (state, ownProps) => {
      const questionnaire = _.get(state.hra, 'satisfactionQuestionnaire');

      const allQuestions = _(_.get(questionnaire, 'questions'))
        .map((question) => [question.id, question])
        .fromPairs()
        .value();

      const filteredQuestionSteps = HraSelectors.selectFilteredQuestionSteps(
        state,
        AppConstants.QuestionnaireType.HRA_SAT
      );

      const questions = _.map(filteredQuestionSteps, (step) =>
        _.get(allQuestions, _.get(step, 'questions[0]'))
      );

      return {
        questions: ownProps.questions
          ? _.size(ownProps.questions) !== _.size(questions)
            ? questions
            : ownProps.questions
          : questions,
      };
    },

    (dispatch) => {
      const { submitSatisfactionSurvey } = HraActions;

      return {
        actions: bindActionCreators(
          {
            submitSatisfactionSurvey,
          },
          dispatch
        ),
      };
    }
  ),

  withStateHandlers(
    {
      isSurveyOpen: null,
      canSubmit: false,
      hasSubmitted: false,
    },

    {
      toggleSurveyVisibility: (state) => () => {
        const resetQuestions = _(state)
          .pickBy((val, key) => /^QuestionAnswer_/.test(key))
          .mapValues(() => undefined)
          .value();

        return {
          ...state,

          isSurveyOpen: !state.isSurveyOpen,
          canSubmit: false,

          ...(state.isSurveyOpen ? resetQuestions : {}),
        };
      },

      enableSubmission: (state) => () => ({
        ...state,
        canSubmit: true,
      }),

      disableSubmission: (state) => () => ({
        ...state,
        canSubmit: false,
      }),

      setQuestionAnswer: (state) => (questionId, optionValue) => ({
        ...state,
        [`QuestionAnswer_${questionId}`]: optionValue,
      }),

      toggleSubmissionIndicator: (state) => () => ({
        ...state,
        hasSubmitted: true,
      }),
    }
  ),

  withHandlers({
    onQuestionAnswered: ({
      questions,
      setQuestionAnswer,
      enableSubmission,
      disableSubmission,
      ...props
    }) => (questionId, optionValue) => {
      const areRequiredQuestionsAnswered = _(questions)
        .reject('optional')
        .map((question) => [
          question.id,
          _.get(props, `QuestionAnswer_${question.id}`),
        ])
        .fromPairs()
        .set(questionId, optionValue)
        .every();

      setQuestionAnswer(questionId, optionValue);

      if (areRequiredQuestionsAnswered) {
        enableSubmission();
      } else {
        disableSubmission();
      }
    },

    onSubmitSurvey: ({
      questions,
      actions: { submitSatisfactionSurvey },
      toggleSurveyVisibility,
      toggleSubmissionIndicator,
      disableSubmission,
      ...props
    }) => () => {
      const answers = _(questions)
        .map((question) => [
          question.id,
          { answers: [_.get(props, `QuestionAnswer_${question.id}`)] },
        ])
        .filter((questionPair) => _.get(questionPair, '[1].answers[0]'))
        .fromPairs()
        .value();

      disableSubmission();

      submitSatisfactionSurvey(answers).then(() => {
        toggleSurveyVisibility();
        toggleSubmissionIndicator();
      });
    },
  })
)((props) => <SatisfactionSurveyMarkup {...props} />);
