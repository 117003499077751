import React from 'react';
import PropTypes from 'prop-types';
import {
  GlobalStyles,
  Colors,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import Radium from 'radium';
import ShrinkingIcon from '../common/ShrinkingIcon';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '100px',
  },
  shrinkingIconPadding: {
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  healthIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    animation: 'introFadeIn 1s linear forwards',
    animationDelay: '0s',
    opacity: 0,
  },
  carePlanIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    animation: 'introFadeIn 1s linear forwards',
    animationDelay: '1s',
    opacity: 0,
  },
  rewardsIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    animation: 'introFadeIn 1s linear forwards',
    animationDelay: '2s',
    opacity: 0,
  },
  titleContainer: {
    fontSize: '30px',
    ...GlobalStyles.extraLight,
  },
  textContainer: {
    ...GlobalStyles.regular,
    fontSize: '16px',
    maxWidth: '300px',
    textAlign: 'center',
    marginTop: '20px',
  },
  vr: {
    width: '1px',
    height: '300px',
    backgroundColor: '#d4d4d4',
    margin: '0 25px 0 25px',
  },
  letsGetStartedContainer: {
    marginTop: '50px',
    display: 'flex',
    width: '300px',
    height: '50px',
    color: Colors.white,
    backgroundColor: '#2196f3',
    textAlign: 'center',
    fontSize: '18px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    ...GlobalStyles.regular,
  },
};

const TheJourneyMarkup = ({ onGetStartedClick }) => {
  const { incentiveType } = AppConfig.effectiveConfig;
  //sweeps or rewards

  const isReward = incentiveType === AppConstants.IncentiveType.REWARDS;

  const rewardsText = isReward
    ? 'Completing healthy actions within your care plans earns you free gift cards.' //rewards
    : 'Completing actions within your care plans earns you points and the chance to win free gift cards.'; //sweepstakes

  return (
    <div style={styles.container}>
      <div style={styles.iconOuterContainer}>
        <div key={'one'} style={styles.healthIconContainer}>
          <div style={styles.shrinkingIconPadding}>
            <ShrinkingIcon
              style={{ marginTop: '20px' }}
              keyFramesName={'fadeInShrinkingIcon'}
              iconColor={Colors.white}
              iconBackgroundColor={'#ff5f8f'}
              iconClassName={'thin-0825_stetoscope_doctor_hospital_ill'}
              fixedHeight={150}
              duration={'.5s'}
              finalIconSize={90}
              easeFunction={'linear'}
            />
          </div>
          <div style={styles.titleContainer}>Understand My Health</div>
          <div style={styles.textContainer}>
            Refer to this section anytime to learn what you can do to maintain
            or improve your health.
          </div>
        </div>

        <div style={styles.vr} />

        <div key={'two'} style={styles.carePlanIconContainer}>
          <div style={styles.shrinkingIconPadding}>
            <ShrinkingIcon
              style={{ marginTop: '20px' }}
              iconColor={Colors.white}
              keyFramesName={'fadeInShrinkingIcon'}
              iconBackgroundColor={Colors.darkGreen}
              iconClassName={'thin-0255_paste_clipboard'}
              fixedHeight={150}
              duration={'.5s'}
              delay={'1s'}
              finalIconSize={90}
              easeFunction={'linear'}
            />
          </div>
          <div style={styles.titleContainer}>Follow My Care Plans</div>
          <div style={styles.textContainer}>
            Next, you'll discover a series of plans created just for YOU. Plans
            contain actions you can take to get or stay healthy and achieve your
            goals.
          </div>
        </div>

        <div style={styles.vr} />

        <div key={'three'} style={styles.rewardsIconContainer}>
          <div style={styles.shrinkingIconPadding}>
            <ShrinkingIcon
              style={{ marginTop: '20px' }}
              iconColor={Colors.white}
              keyFramesName={'fadeInShrinkingIcon'}
              iconBackgroundColor={Colors.orange}
              iconClassName={'thin-0670_diamond_prize_award_jewellery_ring'}
              fixedHeight={150}
              finalIconSize={90}
              duration={'.5s'}
              delay={'2s'}
              easeFunction={'linear'}
            />
          </div>
          <div style={styles.titleContainer}>Earn Fun Rewards</div>
          <div style={styles.textContainer}>{rewardsText}</div>
        </div>
      </div>
      <div style={styles.letsGetStartedContainer} onClick={onGetStartedClick}>
        <div>{"Let's get Started"}</div>
      </div>
    </div>
  );
};

TheJourneyMarkup.propTypes = {
  onGetStartedClick: PropTypes.func.isRequired,
};

export default Radium(TheJourneyMarkup);
