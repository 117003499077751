import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withDemoOverrides } from '@healthmine/greyhound-core/src/hocs';
import { withStyles } from '@material-ui/core';
import { setDependent } from '../../../modules/demo/dependents/actions';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';

const styles = (theme) => ({
  root: {
    borderBottom: `2px solid ${theme.globalStyles.colors.planet9}`,
    paddingBottom: '10px',
    marginBottom: '15px',
    position: 'relative',
  },
  title: {
    fontSize: '24px',
    fontWeight: '500',
  },
  toggle: {
    marginLeft: '10px',
    fontSize: '20px',
    top: '-1px',
    position: 'relative',
    cursor: 'pointer',
  },
  dropdown: {
    position: 'absolute',
    top: 'calc(100% - 25px)',
    left: '0',
    background: 'white',
    padding: '10px',
    borderRadius: '4px',
    listStyleType: 'none',
    fontSize: '18px',
    fontWeight: '500',
    width: '200px',
    boxShadow: theme.globalStyles.shadow2,
    lineHeight: '1.5',
    zIndex: '2',
  },
  dropdownItem: {
    cursor: 'pointer',
    userSelect: 'none',
  },
});

const AccountSwitcher = ({
  classes,
  currentUser,
  demoAvocado,
  dependent,
  people,
  setDependent,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handlePersonSelect = (person) => {
    switch (person) {
      case 'Anthony':
        demoAvocado('DEPENDENT_SWITCHING_ANTHONY');
        break;

      case 'Sofia':
        demoAvocado('DEPENDENT_SWITCHING_SOFIA');
        break;

      default:
        demoAvocado('NONE');
    }

    setDependent(person);
    setIsDropdownVisible(false);
  };

  if (
    !AppConfig.validateFeature(Features.MED_ADHERENCE_TRACKER) ||
    (AppConfig.validateFeature(Features.MED_ADHERENCE_TRACKER) &&
      currentUser !== 'healthmine.test+maria@gmail.com')
  )
    return null;

  return (
    <div className={classes.root}>
      <span className={classes.title}>{dependent}</span>
      <span
        className={classes.toggle}
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
      >
        <span className="icon-Down-Chevron" />
      </span>
      {isDropdownVisible && (
        <ul className={classes.dropdown}>
          {people.map((person) => (
            <li
              className={classes.dropdownItem}
              key={person}
              onClick={() => handlePersonSelect(person)}
            >
              {person}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

AccountSwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.string.isRequired,
  people: PropTypes.array.isRequired,
  dependent: PropTypes.string.isRequired,
  setDependent: PropTypes.func.isRequired,
  demoAvocado: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  people: state.demo.dependents.people,
  dependent: state.demo.dependents.selected,
  currentUser: state.userInfo.emailAddress,
});

export default compose(
  withStyles(styles),
  withDemoOverrides,
  connect(mapStateToProps, { setDependent })
)(AccountSwitcher);
