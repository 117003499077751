import React from 'react';
import PropTypes from 'prop-types';
import List, {
  ListHeader,
  ListContent,
  ListItem,
  ListFooter,
} from '../../common/list2/List';
import MyNotes from './MyNotes';
import Question from './Question';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import _isNull from 'lodash/isNull';
import ChecklistItem from './ChecklistItem';
import { withDoctorChecklist } from '@healthmine/greyhound-core/src/hocs';

class Questions extends React.Component {
  state = {
    addingNew: false,
    questionEditIdx: -1,
  };

  render() {
    const { myDoctorQuestions, listColor } = this.props;
    const { addingNew, questionEditIdx } = this.state;
    const questions = _isNull(myDoctorQuestions) ? [] : myDoctorQuestions;
    const questionList = addingNew
      ? [...questions, { question: '' }]
      : questions;

    return (
      <List disabled={questionList.length === 0}>
        <ListHeader title="Questions" color={listColor} />
        <ListContent
          isEmpty={
            !questionList.length &&
            (_isNull(myDoctorQuestions) || !myDoctorQuestions.length)
          }
          emptyMessage="Click 'Add New' below to add a question."
        >
          {_map(questionList, ({ id, question }, idx) => (
            <ListItem color={listColor} key={`doctor_question_${id}`}>
              <ChecklistItem>
                <Question
                  question={question}
                  id={id}
                  deleteQuestion={
                    addingNew ? this.cancelAdd : this.deleteQuestion
                  }
                  editQuestion={
                    !addingNew && questionEditIdx === -1
                      ? this.editQuestion
                      : undefined
                  }
                  saveQuestion={
                    addingNew ? this.saveNewQuestion : this.saveQuestion
                  }
                  editing={idx === questionEditIdx}
                  hideEdit={questionEditIdx !== -1}
                />
              </ChecklistItem>
              <MyNotes />
            </ListItem>
          ))}
        </ListContent>
        <ListFooter actionText="Add New" onClick={this.addNew} />
      </List>
    );
  }

  addNew = () => {
    this.setState({
      addingNew: true,
      questionEditIdx: this.props.myDoctorQuestions.length,
    });
  };

  cancelAdd = () => {
    this.setState({
      addingNew: false,
      questionEditIdx: -1,
    });
  };

  deleteQuestion = (id) => {
    this.props.deleteDoctorQuestion(id);
    this.setState({
      questionEditIdx: -1,
    });
  };

  editQuestion = (id) => {
    this.setState({
      questionEditIdx: _findIndex(this.props.myDoctorQuestions, { id }),
    });
  };

  saveNewQuestion = (id, question) => {
    if (question === '') {
      this.cancelAdd();
    } else {
      this.props.createDoctorQuestion({ question });
    }

    this.setState({
      addingNew: false,
      questionEditIdx: -1,
    });
  };

  saveQuestion = (id, value) => {
    const doctorQuestion = _find(this.props.myDoctorQuestions, { id });
    if (!!doctorQuestion && value !== '' && value !== doctorQuestion.question) {
      this.props.updateDoctorQuestion({ ...doctorQuestion, question: value });
    }

    this.setState({
      questionEditIdx: -1,
    });
  };
}

Questions.propTypes = {
  listColor: PropTypes.string,
  myDoctorQuestions: PropTypes.array,
  createDoctorQuestion: PropTypes.func.isRequired,
  updateDoctorQuestion: PropTypes.func.isRequired,
  deleteDoctorQuestion: PropTypes.func.isRequired,
};

export default withDoctorChecklist(Questions);
