import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hashHistory } from 'react-router';
import { EducationActions } from '@healthmine/greyhound-core/src/actions';
import { selectMyAssignedEducation } from '@healthmine/greyhound-core/src/selectors/EducationSelector';
import PersonalHealthAssistantMarkup from './PersonalHealthAssistantMarkup';
import _ from 'lodash';
import { EDUCATION_TYPES } from '@healthmine/greyhound-core/src/constants/Education';

class PersonalHealthAssistant extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: '',
      frameLink: '',
      contentType: '',
      renderSuggestions: true,
    };
  }

  componentWillMount() {
    this._processFrameLink(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.params &&
      nextProps.params.contentId !== this.props.params.contentId
    ) {
      this._processFrameLink(nextProps);
    }
  }

  componentWillUnmount() {
    this.props.actions.clearSearchResults();
  }

  render() {
    const { expanded, renderSuggestions, frameLink, contentType } = this.state;

    const {
      search,
      searchKeyword,
      resultKeyword,
      type,
      loading,
      searchSize,
      hasAssignedEducation,
      suggestions,
      assignedEducation,
    } = this.props;

    const {
      _setSearchKeyword,
      _searchCurrentKeyword,
      _setFrameLink,
      _resetFrameLink,
      _resetSearch,
      _toggleExpansion,
      _enableSuggestions,
      _searchForKeyword,
    } = this;

    return (
      <PersonalHealthAssistantMarkup
        search={search}
        searchKeyword={searchKeyword}
        resultKeyword={resultKeyword}
        type={type}
        loading={loading}
        searchSize={searchSize}
        setSearchKeyword={_setSearchKeyword}
        searchCurrentKeyword={_searchCurrentKeyword}
        searchForKeyword={_searchForKeyword}
        frameLink={frameLink}
        contentType={contentType}
        setFrameLink={_setFrameLink}
        resetFrameLink={_resetFrameLink}
        resetSearch={_resetSearch}
        expanded={expanded}
        toggleExpansion={_toggleExpansion}
        renderSuggestions={renderSuggestions}
        enableSuggestions={_enableSuggestions}
        hasAssignedEducation={hasAssignedEducation}
        assignedEducation={assignedEducation}
        suggestions={suggestions}
      />
    );
  }

  _resetSearch = () => {
    this._setSearchKeyword('');
    this.props.actions.setEducationType('assigned');
  };

  _setSearchKeyword = (search) => {
    this.props.actions.setSearchKeyword(search);
  };

  _searchForKeyword = (value) => {
    this.props.actions.setSearchKeyword(value);

    const keyword = /^[A-Z0-9_:\-.]+$/.test(value) ? value : _.camelCase(value);

    if (this.props.suggestions[keyword]) {
      this.props.actions.getSuggestedContent(keyword);
      this.props.actions.setEducationType('search');
    } else {
      this._search(value);
    }
  };

  _searchCurrentKeyword = (e) => {
    e.preventDefault();
    this._search(this.props.searchKeyword);
  };

  _search = (keyword) => {
    this.setState({ renderSuggestions: false });
    this.props.actions.setEducationType('search');

    this.props.actions
      .getEducation(keyword)
      .then(() => hashHistory.push(`/pha/${encodeURIComponent(keyword)}`));
  };

  _enableSuggestions = () => {
    this.setState({ renderSuggestions: true });
  };

  _toggleExpansion = (type) => {
    const expanded = this.state.expanded;
    this.setState({
      expanded: expanded === type ? '' : type,
    });
  };

  _processFrameLink = (props) => {
    const { params, assignedEducation } = props;

    const { contentId } = params;

    if (contentId && assignedEducation) {
      const assignedItem = _.flatMapDeep(assignedEducation, (subject) =>
        subject.contentTypes.map((type) => type.content)
      ).find((item) => item.contentId === contentId);

      if (assignedItem) {
        this._setFrameLink(assignedItem);
      }
    }
  };

  _setFrameLink = (item) => {
    this.setState({
      frameLink: item.url,
      contentType: item.contentType,
    });
    this.props.actions.trackEducation(item.contentId);
  };

  _resetFrameLink = () => {
    const { searchKeyword } = this.props;
    this.setState(
      {
        frameLink: '',
        contentType: '',
      },
      () => {
        hashHistory.push(`/pha/${encodeURIComponent(searchKeyword)}`);
      }
    );
  };
}

const educationShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  completionDate: PropTypes.string,
});

PersonalHealthAssistant.propTypes = {
  params: PropTypes.object,
  actions: PropTypes.object.isRequired,
  planEducation: PropTypes.object,
  planInfo: PropTypes.object,
  search: PropTypes.object,
  searchKeyword: PropTypes.string,
  getSuggestedContent: PropTypes.func,
  resultKeyword: PropTypes.string,
  type: PropTypes.oneOf(['assigned', 'search', '']).isRequired,
  loading: PropTypes.bool,
  searchSize: PropTypes.number,
  hasAssignedEducation: PropTypes.bool,
  assignedEducation: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      contentTypes: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          type: PropTypes.oneOf(EDUCATION_TYPES).isRequired,
          length: PropTypes.number.isRequired,
          previewContent: PropTypes.arrayOf(educationShape).isRequired,
          content: PropTypes.arrayOf(educationShape).isRequired,
        })
      ),
    })
  ),
  suggestions: PropTypes.shape({
    //TODO: ADD PROP TYPES
  }),
};

function mapStateToProps(state) {
  const { education } = state;

  const {
    search,
    searchKeyword,
    type,
    loading,
    resultKeyword,
    suggestions,
  } = education;

  const searchSize = _(search)
    .values()
    .flatMap()
    .size();

  const assignedEducation = selectMyAssignedEducation(state);

  const hasAssignedEducation = !_.isEmpty(assignedEducation);

  return {
    search,
    searchKeyword,
    resultKeyword,
    type,
    loading,
    searchSize,
    assignedEducation,
    hasAssignedEducation,
    suggestions,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getEducation,
    setSearchKeyword,
    getSuggestedContent,
    setEducationType,
    trackEducation,
    clearSearchResults,
  } = EducationActions;

  return {
    actions: bindActionCreators(
      {
        getEducation,
        setSearchKeyword,
        getSuggestedContent,
        setEducationType,
        trackEducation,
        clearSearchResults,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  PersonalHealthAssistant
);
