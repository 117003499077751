import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import History from './MeasurementHistory';
import { Flex } from 'reflexbox';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

const MeasurementBody = ({
  toggleHistory,
  history,
  index,
  measurement,
  hideSources,
}) => {
  return (
    <Flex>
      <History
        toggleHistory={toggleHistory}
        history={history}
        index={index}
        measurement={measurement}
        hideSources={hideSources}
      />
    </Flex>
  );
};

MeasurementBody.propTypes = {
  history: PropTypes.bool,
  toggleHistory: PropTypes.func,
  index: PropTypes.number,
  measurement: PropTypes.object,
  data: PropTypes.object,
  type: PropTypes.string,
  hideSources: PropTypes.bool,
};

export default compose(connect(null, null), Radium)(MeasurementBody);
