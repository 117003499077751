import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import moment from 'moment';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import SmartLink from './SmartLink';

const Footer = ({ isLoggedIn, onTutorialClick, isOnboard, showTutorial }) => {
  const currentYear = moment().year();

  const styles = {
    subMenu: {
      display: 'inline-flex',
      flexDirection: 'column',
      paddingRight: '10px',
      paddingLeft: '10px',
      color: Colors.contentText,
      textDecoration: 'none',
      fontSize: '16px',
    },

    caption: {
      lineHeight: '1.5',
    },

    firstFooterLink: {
      paddingLeft: '0px',
    },

    copyRight: {
      color: Colors.contentText,
      fontSize: '16px',
    },

    footerLinks: {
      marginTop: '10px',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',

      '@media screen and (max-width: 1200px)': {
        padding: '0 10px',
        boxSizing: 'border-box',
      },

      '@media screen and (max-width: 520px)': {
        flexFlow: 'column',
        alignItems: 'center',
        lineHeight: '20px',
        textAlign: 'center',
      },
    },

    alignDiv: {
      minHeight: '30px',
      width: '1200px',
      maxWidth: '100%',
      position: 'relative',
      padding: '5px 0px 10px 0px',
      bottom: '0px',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'transparent',
      zIndex: '1000',
    },

    spanColor: {
      color: Colors.contentText,
    },

    footerStatements: {
      margin: '60px auto 0px',
    },

    footerStatement: {
      fontSize: '16px',
      color: Colors.contentText,
      marginTop: '0px',
      marginBottom: '15px',
    },
  };

  const { footerStatements, assetsRootUrl } = AppConfig.effectiveConfig;

  const normalizedAssetsRootUrl = `${assetsRootUrl}${
    !_.endsWith(assetsRootUrl, '/') ? '/' : ''
  }`;

  let footerStatementsUI = null;

  if (footerStatements && footerStatements.length) {
    footerStatementsUI = (
      <div style={styles.footerStatements}>
        {footerStatements.map((footerStatement, footerStatementIndex) => (
          <p
            key={`FooterStatement${footerStatementIndex}`}
            style={styles.footerStatement}
          >
            {footerStatement}
          </p>
        ))}
      </div>
    );
  }

  const logoutRedirect = _.get(AppConfig, 'effectiveConfig.logoutRedirect', '');

  const footerLinks = [
    {
      caption: 'Home',
      route: {
        auth: '/',
        noAuth: logoutRedirect ? logoutRedirect : '/',
      },
    },
    ..._.get(AppConfig, 'effectiveConfig.footerLinks', {}),
  ];

  return (
    <div style={styles.alignDiv}>
      <div>
        <span style={styles.copyRight}>
          Powered by Healthmine, Inc. &copy; {currentYear}
        </span>
      </div>
      <div style={styles.footerLinks}>
        <div>
          {footerLinks
            .filter((footerLink) => {
              const noAuthRoute = _.get(footerLink, 'route.noAuth');

              const authRoute = _.get(footerLink, 'route.auth');

              const defaultRoute = _.isString(footerLink.route)
                ? footerLink.route
                : null;

              return !!(
                defaultRoute ||
                (isLoggedIn && authRoute) ||
                (!isLoggedIn && noAuthRoute)
              );
            })
            .map((footerLink, footerLinkIndex, footerLinks) => {
              const route = (_.isString(footerLink.route)
                ? footerLink.route
                : isLoggedIn
                  ? _.get(footerLink, 'route.auth')
                  : _.get(footerLink, 'route.noAuth')
              ).replace(/^s3:\/\//i, normalizedAssetsRootUrl);

              const openInNewWindow = _.get(footerLink, 'openInNewWindow');

              const linkElem = (
                <SmartLink
                  key={`FooterLink${footerLinkIndex}`}
                  to={route}
                  target={openInNewWindow ? '_blank' : '_self'}
                  style={[
                    styles.subMenu,
                    !footerLinkIndex ? styles.firstFooterLink : null,
                  ]}
                >
                  {footerLink.caption
                    .split(':__:')
                    .map((caption, captionIndex) => (
                      <span
                        key={`FooterCaption${footerLinkIndex}_${captionIndex}`}
                        style={styles.caption}
                      >
                        {caption}
                      </span>
                    ))}
                </SmartLink>
              );

              return footerLinkIndex < footerLinks.length - 1
                ? [
                    linkElem,
                    <span
                      key={`FooterLinkSeparator${footerLinkIndex}`}
                      style={styles.spanColor}
                    >
                      &middot;
                    </span>,
                  ]
                : linkElem;
            })}
          {isLoggedIn && isOnboard && showTutorial ? (
            <span>
              <span style={styles.spanColor}>&middot;</span>
              <div
                style={[styles.subMenu, { cursor: 'pointer' }]}
                onClick={() => {
                  onTutorialClick();
                }}
              >
                Tutorial
              </div>
            </span>
          ) : null}
        </div>
      </div>

      {footerStatementsUI}
    </div>
  );
};

Footer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  onTutorialClick: PropTypes.func.isRequired,
  isOnboard: PropTypes.bool,
  showTutorial: PropTypes.bool,
};

export default Radium(Footer);
