import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../common/list/ListItem.styles';
import { DrawerNavigation } from '../../../common/drawer2';

const PreviousEntry = ({ title, subtitle, status, onClick }) => {
  return (
    <DrawerNavigation
      onClick={onClick}
      title={title}
      status={status}
      subtitle={subtitle}
    />
  );
};

PreviousEntry.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selectSweepstake: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  withProps(({ selectSweepstake, id }) => ({
    onClick: () => selectSweepstake(id),
  }))
)(PreviousEntry);
