import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from './card/Card';
import Input from '@material-ui/core/Input';

const styles = (theme) => ({
  customCard: {},
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '30px',
    color: theme.palette.secondary.main,
    marginRight: '20px',
  },
  inputRoot: {
    fontSize: '18px',
  },
});

const SearchBar = ({
  classes,
  value,
  placeholder = 'Search',
  onChange,
  onBlur,
  onFocus,
}) => {
  return (
    <Card customClass={classes.customCard}>
      <div className={classes.root}>
        <i className={`icon-Search ${classes.icon}`} />
        <Input
          type="search"
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          margin="none"
          fullWidth
          disableUnderline
          classes={{ root: classes.inputRoot }}
        />
      </div>
    </Card>
  );
};

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default withStyles(styles)(SearchBar);
