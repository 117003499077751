import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules/';
import Item from './Item';

const ItemRow = ({ arr, startIndex, active, onClick, wide, styles }) => {
  const row = {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    color: Colors.dark,
    fontSize: '16px',
    marginBottom: '25px',
    ...styles.row,
  };

  const item = {
    cursor: 'pointer',
    ...styles.item,
    /* Had to put icon background-color change inside app.css; not capable in Radium currently */
  };

  const itemIconActive = {
    color: Colors.light,
    backgroundColor: Colors.mediumGray3,
    ...styles.itemIconActive,
  };

  return (
    <div style={row}>
      {arr.map((resource, i) => {
        const isSelected =
          active.indexOf(i + startIndex) >= 0 ||
          active.indexOf(resource.questionAnswerOptionId) >= 0
            ? true
            : false;
        return (
          <div key={`${resource.label}-${i}`}>
            {/*eslint-disable react/jsx-no-bind*/}
            <Item
              iconClass={resource.iconClass}
              text={resource.label}
              pointDescription={resource.pointDescription}
              itemStyle={item}
              styles={styles}
              iconStyle={isSelected ? itemIconActive : {}}
              onClick={() =>
                onClick(i + startIndex, resource.questionAnswerOptionId)
              }
              textActive={isSelected ? GlobalStyles.semiBold : {}}
              wide={wide}
              points={resource.points}
              footer={resource.footer}
              selected={isSelected}
            />
            {/*eslint-enable react/jsx-no-bind*/}
          </div>
        );
      })}
    </div>
  );
};

ItemRow.propTypes = {
  arr: PropTypes.array.isRequired,
  startIndex: PropTypes.number.isRequired,
  active: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  wide: PropTypes.bool,
  styles: PropTypes.object,
};

ItemRow.defaultProps = {
  styles: {},
};

export default Radium(ItemRow);
