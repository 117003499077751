import React from 'react';
import { IndexRedirect, Route, Redirect } from 'react-router';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import LeapFrogApp from './views/LeapFrogApp';
import HealthOverview from './views/pages/health-overview/HealthOverview';
import Incentives from './views/pages/incentives/Incentives';
import HealthRecord from './views/pages/health-record/HealthRecord';
import DoctorChecklist from './views/pages/doctor-checklist/DoctorChecklist';
import HealthLibrary from './views/pages/health-library/HealthLibrary';
import Messages from './views/pages/messages/Messages';
import HealthPrograms from './views/pages/health-programs/HealthPrograms';
import Help from './views/pages/help/Help';
import Demofy from './views/pages/demofy/Demofy';
import {
  UserIsAuthenticated,
  UserIsNotAuthenticated,
  SsoDisabled,
} from '../auth';
import RegisterContainer from '../containers/registration/RegisterContainer';
import SignInPage from '../containers/sign-in/SignInPage';
import SsoErrorPage from '../containers/sign-in/SsoErrorPage';
import ResetPasswordPage from '../containers/registration/ResetPasswordPage';
import PostPasswordResetPageMarkup from '../components/registration/PostPasswordResetPageMarkup';
import SessionTimeoutPage from '../components/sign-in/SessionTimeoutPage';
import Authentication from './views/Authentication';
import PublicDocument from './views/public/PublicDocument';
import RegistrationBasicInfoPage from '../containers/registration/RegistrationBasicInfoPage';
import RegistrationCreateProfilePage from '../containers/registration/RegistrationCreateProfilePage';
import ReturnToLoginMarkup from '../components/registration/ReturnToLoginMarkup';
import RetrieveAccountPage from '../containers/registration/RetrieveAccountPage';
import SubscriptionsPage from '../components/settings/SubscriptionsPage';
import AutoReward from '../components/registration/AutoReward';

import {
  HealthOverviewDrawerRouteMapping,
  IncentivesDrawerRouteMapping,
} from './views/common/drawer2/DrawerRouteMapping';
import _ from 'lodash';

const getLeapFrogRouteConfig = () => {
  return (
    <Route path="/" component={LeapFrogApp}>
      <Route component={UserIsNotAuthenticated}>
        <Route component={RegisterContainer}>
          <Route component={SsoDisabled}>
            <Route path="signin" component={SignInPage} />
            <Route path="registration">
              <Route
                path="basic-information"
                component={RegistrationBasicInfoPage}
              />
              <Route
                path="create-profile"
                component={RegistrationCreateProfilePage}
              />
            </Route>
            <Route path="return" component={ReturnToLoginMarkup} />
            <Route path="retrieve-account" component={RetrieveAccountPage} />
          </Route>
          <Route path="reset-password" component={ResetPasswordPage} />
          <Route
            path="reset-password-success"
            component={PostPasswordResetPageMarkup}
          />
          <Route path="subscriptions" component={SubscriptionsPage} />
          <Route path="public">
            <Redirect
              from="contact"
              to="view-document"
              query={{
                iconClass: 'icon-Customer-Service',
                title: 'Contact Us',
                documentFile: 'contact_us_en.html',
              }}
            />

            <Redirect
              from="privacy"
              to="view-document"
              query={{
                iconClass: 'icon-Shield',
                title: 'Privacy Policy',
                documentFile: 'privacy_policy_en.html',
              }}
            />

            <Redirect
              from="terms"
              to="view-document"
              query={{
                iconClass: 'icon-Document-Scroll',
                title: 'Terms of Use',
                documentFile: 'terms_of_use_en.html',
              }}
            />

            <Route path="view-document" component={PublicDocument} />
          </Route>
        </Route>
      </Route>
      <Route path="sso-error" component={SsoErrorPage} />
      <Route path="timeout" component={SessionTimeoutPage} />
      <Route component={UserIsAuthenticated}>
        <Route path="auto-reward" component={AutoReward} />
        <Route component={Authentication}>
          <IndexRedirect to="health-overview" />
          <Route path="health-overview" component={HealthOverview}>
            {_.keys(HealthOverviewDrawerRouteMapping).map((path, idx) => (
              <Route path={path} key={idx} />
            ))}
          </Route>
          <Route path="rewards" component={Incentives}>
            {_.keys(IncentivesDrawerRouteMapping).map((path, idx) => (
              <Route path={path} key={idx} />
            ))}
          </Route>
          <Route path="sweepstakes" component={Incentives}>
            {_.keys(IncentivesDrawerRouteMapping).map((path, idx) => (
              <Route path={path} key={idx} />
            ))}
          </Route>
          <Redirect from="/sweepstakes/shop" to="sweepstakes" />
          {!AppConfig.validateFeature(Features.NO_HEALTH_RECORD) && (
            <Route path="health-record" component={HealthRecord} />
          )}
          {AppConfig.validateFeature(Features.DOCTOR_CHECKLIST) && (
            <Route path="doctor-checklist" component={DoctorChecklist} />
          )}
          <Route path="health-programs" component={HealthPrograms} />
          <Route path="health-library" component={HealthLibrary}>
            <Route path=":searchWord" component={HealthLibrary} />
          </Route>
          <Route path="pha" component={HealthLibrary}>
            <Route path=":searchWord" component={HealthLibrary} />
          </Route>
          <Route path="messages" component={Messages} />
          <Route path="help" component={Help} />

          {AppConfig.validateFeature(Features.DEMOFY) && (
            <Route path="demofy" component={Demofy} />
          )}

          <Redirect from="faq" to="help" />
          <Redirect from="contact" to="help" />
          <Redirect from="privacy" to="help" />
          <Redirect from="terms" to="help" />
          <Redirect from="incentive-terms" to="help" />
        </Route>
      </Route>
    </Route>
  );
};

export { getLeapFrogRouteConfig };
