import { combineReducers } from 'redux';
import dependents from './dependents/reducer';
import medications from './medications/reducer';
import refills from './refills/reducer';
import provider from './provider/reducer';

export default combineReducers({
  dependents,
  medications,
  refills,
  provider,
});
