import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withProps } from 'recompose';
import _ from 'lodash';
import {
  withDrawer,
  DrawerContent,
  DrawerSectionHeader,
  DrawerNavigation,
  DrawerSection,
  DrawerButton,
} from '../../../../common/drawer2';
import Typography from '../../../../common/Typography';
import CoachingHowToEarn from './CoachingHowToEarn';
import { WithCoachingPrograms } from '@healthmine/greyhound-core/src/hocs';

const CoachingEnroll = ({
  openCoachingHowToEarn,
  openProchange,
  programName,
  registrationStatusDescription,
}) => {
  return (
    <DrawerContent title={programName}>
      <DrawerSection>
        <Typography type="body">{registrationStatusDescription}</Typography>
      </DrawerSection>
      <DrawerSectionHeader title="Points" />
      <DrawerNavigation
        title="How to earn points"
        onClick={openCoachingHowToEarn}
      />
      <DrawerSection grow />
      <DrawerButton text="Get Started" onClick={openProchange} />
    </DrawerContent>
  );
};

CoachingEnroll.propTypes = {
  openCoachingHowToEarn: PropTypes.func.isRequired,
  openProchange: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired,
  registrationStatusDescription: PropTypes.string.isRequired,
};

export default compose(
  pure,
  withDrawer,
  WithCoachingPrograms,
  withProps(({ coachingPrograms, programId }) => {
    const program = _.find(coachingPrograms, { programId });
    const {
      enrollmentRewardAmount,
      programName,
      registrationStatusDescription,
      programRewardAmount,
      requiredActivityCount,
      ssoLink,
    } = program;
    return {
      enrollmentRewardAmount,
      programName,
      registrationStatusDescription,
      programRewardAmount,
      requiredActivityCount,
      ssoLink,
    };
  }),
  withHandlers({
    openCoachingHowToEarn: ({
      drawer,
      enrollmentRewardAmount,
      programRewardAmount,
      requiredActivityCount,
    }) => () =>
      drawer.open(CoachingHowToEarn, {
        enrollmentRewardAmount,
        programRewardAmount,
        requiredActivityCount,
      }),

    openProchange: ({ programId, ssoLink, startSsoProchange }) => () =>
      startSsoProchange(() => window.open(ssoLink), programId),
  })
)(CoachingEnroll);
