import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

export default {
  root: {
    ...GlobalStyles.light,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.light,
    backgroundColor: Colors.white,
    maxWidth: '100%',
    padding: '40px',
    backgroundImage: `url(${require('../../images/SingleCarePlanHeroImage.jpg')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },

  section: {
    flex: '1 0 33.34%',
    fontSize: '16px',
    lineHeight: '17px',
  },

  employerGroupLogo: {
    maxWidth: '100%',
  },

  welcomeStatement: {
    color: Colors.darkBlue,
    padding: '24px',
  },

  completionContainer: {
    color: Colors.blackPearl,
    padding: '24px',
  },

  measurementContainer: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: '500px',
  },

  measurement: {
    fontSize: '36px',
    lineHeight: '43px',
  },
};
