import Radium from 'radium';
import React from 'react';
import Item from '../common/Item';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import PropTypes from 'prop-types';
import FloatingLabel from '../common/FloatingLabel';
import Loading from '../sign-in/Loading';
import BottomNavButtons from '../common/BottomNavButtons';
import { Link } from 'react-router';

const MfaEnrollmentMarkup = ({
  isLoading,
  mfaEnrollmentHasBegun,
  mfaEnrollmentIsComplete,
  mfaCode,
  errorMessage,
  onMfaCodeChange,
  onBackClick,
  onNextClick,
  qrCodeUrl,
  onVerifyMfaCode,
}) => {
  const RadiumLink = Radium(Link);
  const styles = {
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    loadingBox: {
      border: '1px solid',
      borderRadius: '6px',
      width: '300px',
      height: '300px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loadingText: {
      fontSize: '24px',
      margin: '0 0 40px',
      color: Colors.bodyText,
      textAlign: 'center',
    },
    content: {
      margin: '40px auto',
      alignItems: 'center',
      maxWidth: '600px',
    },
    headerCard: {
      alignItems: 'center',
      flexDirection: 'row',
      display: 'flex',
    },
    headerCardTitle: { margin: '0px', fontWeight: 'normal', fontSize: '22px' },
    headerCardText: { marginTop: '8px', fontSize: '14px' },
    headerCardIcon: {
      color: Colors.hexToRgba(Colors.white, 0.81),
      backgroundColor: Colors.black,
      width: '35px',
      height: '35px',
      fontSize: '35px',
      borderRadius: '50%',
      padding: '20px',
      margin: '0 15px 20px',
    },
    stepContainer: {
      marginTop: '16px',
    },
    stepHeader: {
      fontWeight: 'normal',
      fontSize: '22px',
      margin: '0px',
    },
    stepBody: {
      color: Colors.lightSlateGray,
    },
    linkText: {
      color: Colors.linkBlue,
      textDecoration: 'none',
    },
    bottomLinks: {
      marginTop: '100px',
    },
    image: {
      margin: '20px 0',
      width: '200px',
      height: '200px',
    },
    or: {
      margin: '0',
    },
    secret: {
      margin: '20px 0',
      backgroundColor: Colors.lighterGray,
      fontWeight: 'bold',
      padding: '26px 20px',
      borderRadius: '10px',
      wordBreak: 'break-all',
    },
    copyButton: {
      marginBottom: '40px',
      color: Colors.linkBlue,
      textDecoration: 'none',
      display: 'flex',
      cursor: 'pointer',
    },
    errorMessage: {
      color: Colors.red,
    },
  };

  const aTag = (href, text) => (
    <a style={styles.linkText} href={href} target="_blank">
      {text}
    </a>
  );

  const microsoftLinks = {
    android: aTag(
      'https://go.microsoft.com/fwlink/p/?linkid=2168850&clcid=0x409&culture=en-us&country=us',
      'Android'
    ),
    ios: aTag(
      'https://go.microsoft.com/fwlink/p/?linkid=2168643&clcid=0x409&culture=en-us&country=us',
      'iOS'
    ),
  };

  const googleLinks = {
    android: aTag(
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
      'Android'
    ),
    ios: aTag(
      'https://apps.apple.com/app/google-authenticator/id388497605',
      'iOS'
    ),
  };

  function getParameterByName(url, name) {
    name = name.replace(/[[\]]/g, '\\$&');
    let regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    let results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const secret = getParameterByName(qrCodeUrl, 'chl');
  function copySecret() {
    navigator.clipboard.writeText(secret);
  }

  return (
    <div style={styles.content}>
      {isLoading &&
        mfaEnrollmentIsComplete && (
          <h2 style={[GlobalStyles.extraLight, styles.loadingText]}>{`Loading ${
            AppConfig.effectiveConfig.clientDisplayName
          }!`}</h2>
        )}
      {!isLoading && (
        <div style={styles.headerCard}>
          <Item
            iconStyle={styles.headerCardIcon}
            iconClass="thin-0640_security_lock_protected"
            keep
          />
          <div>
            <h1 style={styles.headerCardTitle}>Two-Step Verification Set Up</h1>
            <p style={styles.headerCardText}>
              Protects your account by adding an extra layer of security.
            </p>
          </div>
        </div>
      )}

      {isLoading && (
        <div style={styles.loadingContainer}>
          <div style={styles.loadingBox}>
            <Loading />
          </div>
        </div>
      )}

      {!isLoading &&
        !mfaEnrollmentHasBegun && (
          <>
            <div style={styles.stepContainer}>
              <h2 style={styles.stepHeader}>
                Step 1: Download an authentication app
              </h2>
              <p style={styles.stepBody}>
                We recommend downloading Google Authenticator (
                {googleLinks.ios} or {googleLinks.android}) or Microsoft
                Authenticator ({microsoftLinks.ios} or {microsoftLinks.android})
                if you don't have one installed.
              </p>
            </div>
            {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}
            <div style={styles.bottomLinks}>
              <BottomNavButtons backDisabled onClickForw={onNextClick} />
            </div>
          </>
        )}

      {!isLoading &&
        mfaEnrollmentHasBegun &&
        !mfaEnrollmentIsComplete && (
          <div style={styles.stepContainer}>
            <h2 style={styles.stepHeader}>
              Step 2: Scan this barcode/QR code or copy the key
            </h2>
            <p style={styles.stepBody}>
              Scan this barcode/QR code in the authentication app or copy the
              key and paste it in the authentication app.
            </p>
            <img
              style={styles.image}
              src={qrCodeUrl}
              alt={`QR code`}
              title={`QR code`}
            />
            <p style={styles.or}>OR</p>
            <p style={styles.secret}>{secret}</p>
            <RadiumLink
              onClick={copySecret}
              style={[
                styles.copyButton,
                GlobalStyles.regular,
                styles.linkbtn,
                styles.marginTop,
              ]}
            >
              Copy Key
            </RadiumLink>
            <h2 style={styles.stepHeader}>Step 3: Enter Code</h2>
            <p style={styles.stepBody}>
              Enter the 6-digit code generated by your authentication app.
            </p>
            <FloatingLabel
              type="number"
              value={mfaCode}
              onChange={onMfaCodeChange}
              id="mfaCode"
              name="mfaCode"
            />
            {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}
            <div style={styles.bottomLinks}>
              <BottomNavButtons
                onClickForw={onVerifyMfaCode}
                onClickBack={onBackClick}
                forwDisabled={!mfaCode}
              />
            </div>
          </div>
        )}
    </div>
  );
};

MfaEnrollmentMarkup.propTypes = {
  isLoading: PropTypes.bool,
  mfaCode: PropTypes.string,
  errorMessage: PropTypes.string,
  mfaEnrollmentHasBegun: PropTypes.bool,
  mfaEnrollmentIsComplete: PropTypes.bool,
  onMfaCodeChange: PropTypes.func,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onVerifyMfaCode: PropTypes.func,
  qrCodeUrl: PropTypes.string,
};

export default Radium(MfaEnrollmentMarkup);
