import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AppConstants,
  RewardsConstants,
} from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  selectCurrentIncentiveSummary,
  selectAvailablePoints,
  selectIncentiveTransactionSummary,
} from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import HeaderMenu from './HeaderMenu';
import IncentiveHistoryMenuMarkup from './IncentiveHistoryMenuMarkup';

class IncentiveHistoryMenu extends React.Component {
  render() {
    const { rewardsSummary, availablePoints, totalPoints } = this.props;

    const { incentiveType } = AppConfig.effectiveConfig;

    const { setCurrencyLabel } = RewardsConstants;

    const isReward = incentiveType === AppConstants.IncentiveType.REWARDS;

    const baseRoute =
      incentiveType === AppConstants.IncentiveType.REWARDS
        ? 'rewards'
        : 'sweepstakes';

    const captionTitle = isReward ? 'Rewards' : 'Points';

    const captionSubTitle = isReward
      ? setCurrencyLabel(availablePoints)
      : (availablePoints || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const displayedTransactions = rewardsSummary.slice(0, 4);

    return (
      <HeaderMenu
        menuId="IncentiveHistoryMenu"
        menuType="secondary"
        primaryRoute={`/${baseRoute}/summary/`}
        title={captionTitle}
        subTitle={captionSubTitle.toString()}
        activeRule={/\/(sweepstakes|rewards)\/summary/}
        customStyles={{
          captionContainer: {
            width: 'auto',
            marginRight: '5px',
            padding: '0px 10px',
          },
        }}
      >
        <IncentiveHistoryMenuMarkup
          summaryList={displayedTransactions}
          incentiveType={incentiveType}
          availablePoints={availablePoints}
          totalPoints={totalPoints}
        />
      </HeaderMenu>
    );
  }
}

IncentiveHistoryMenu.propTypes = {
  rewardsSummary: PropTypes.array.isRequired,
  totalPoints: PropTypes.number.isRequired,
  availablePoints: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const pointsSummary = selectCurrentIncentiveSummary(state);
  return {
    rewardsSummary: selectIncentiveTransactionSummary(state),
    availablePoints: selectAvailablePoints(state),
    totalPoints: pointsSummary.totalPoints,
  };
}

export default connect(mapStateToProps, null)(IncentiveHistoryMenu);
