import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { AppConfig, Assets } from '@healthmine/greyhound-core/src/modules';
import _get from 'lodash/get';

const LogoMarkup = ({ enableLink }) => {
  const { usesSecondaryLogo } = AppConfig.effectiveConfig;

  const logoAlt = `${_get(
    AppConfig,
    'effectiveConfig.clientDisplayName',
    'Health Plan'
  )} Logo`;

  const secondaryAlt = `${_get(
    AppConfig,
    'effectiveConfig.clientDisplayName',
    'Health Plan'
  )} Logo`;

  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    link: {
      display: 'flex',
      alignItems: 'center',
    },

    logo: {
      maxHeight: '40px',
      maxWidth: '200px',
    },

    logoSpacer: {
      width: '10px',
    },
  };

  let logoUI = [
    <img
      key="PrimaryLogo"
      src={Assets.getAssetURL('logo_primary.png')}
      style={styles.logo}
      alt={logoAlt}
      title={logoAlt}
    />,

    usesSecondaryLogo ? (
      <div key="LogoSpacer" style={styles.logoSpacer} />
    ) : null,

    usesSecondaryLogo ? (
      <img
        key="SecondaryLogo"
        src={Assets.getAssetURL('logo_secondary.png')}
        style={styles.logo}
        alt={secondaryAlt}
        title={secondaryAlt}
      />
    ) : null,
  ];

  if (enableLink) {
    logoUI = (
      <Link to="/" style={styles.link}>
        {logoUI}
      </Link>
    );
  }

  return <div style={styles.root}>{logoUI}</div>;
};

LogoMarkup.propTypes = {
  enableLink: PropTypes.bool,
};

LogoMarkup.defaultProps = {
  enableLink: true,
};

export default Radium(LogoMarkup);
