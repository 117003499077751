import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const ShrinkingIcon = ({
  iconClassName,
  style,
  iconColor = '#FFFFFF',
  iconBackgroundColor = 'rgb(33, 150, 243)',
  fixedHeight = 150,
  finalIconSize = 90,
  duration = '1s',
  easeFunction = 'linear',
  delay = '0s',
  keyFramesName = 'shrinkingIcon',
}) => {
  const styles = {
    fixedContainer: {
      height: fixedHeight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...style,
    },
    iconContainer: {
      color: iconColor,
      backgroundColor: iconBackgroundColor,
      borderRadius: '50%',
      padding: '30px',
      maxHeight: fixedHeight,
    },
    icon: {
      fontSize: finalIconSize,
      animation: `${keyFramesName} ${duration} ${delay} ${easeFunction} forwards`,
    },
  };

  return (
    <div style={styles.fixedContainer}>
      <div style={styles.iconContainer}>
        <i className={iconClassName} style={styles.icon} />
      </div>
    </div>
  );
};

ShrinkingIcon.propTypes = {
  iconClassName: PropTypes.string.isRequired,

  iconColor: PropTypes.string,
  iconBackgroundColor: PropTypes.string,
  keyFramesName: PropTypes.string,
  fixedHeight: PropTypes.number,
  duration: PropTypes.string,
  easeFunction: PropTypes.string,
  finalIconSize: PropTypes.number,
  style: PropTypes.object,
  delay: PropTypes.string,
};

export default Radium(ShrinkingIcon);
