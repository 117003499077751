import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const ProgressLine = ({ success }) => {
  const line = {
    height: '100px',
    borderLeftWidth: '1px',
    borderLeftStyle: 'solid',
    borderLeftColor: Colors.mediumGray3,
    marginLeft: '453px',
  };

  const check = {
    position: 'relative',
    top: '38px',
    left: '-12px',
    width: '24px',
    height: '24px',
    backgroundColor: Colors.light,
    color: Colors.lightGreen,
  };

  const piconCheck = (
    <i className="thin-0154_ok_successful_check" style={{ fontSize: '24px' }} />
  );

  return (
    <div style={line}>
      {success ? <div style={check}>{piconCheck}</div> : null}
    </div>
  );
};

ProgressLine.propTypes = {
  success: PropTypes.bool,
};

export default Radium(ProgressLine);
