import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';
import { shoppingCartSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import ShoppingCartMarkup from './ShoppingCartMarkup';
import { selectAvailablePoints } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';

class ShoppingCart extends React.Component {
  constructor() {
    super(...arguments);

    const { shoppingCartItems } = this.props;

    this.state = {
      shoppingCartItems,
      updatedItems: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      shoppingCartItems: nextProps.shoppingCartItems,
    });
  }

  render() {
    const { shoppingCartItems } = this.state;

    return (
      <ShoppingCartMarkup
        shoppingCartItems={shoppingCartItems}
        removeFromCart={this._removeFromCart}
        updateDenomination={this._updateDenomination}
        updateQuantity={this._updateQuantity}
        updateDelivery={this._updateDelivery}
        updateCart={this._updateCart}
        points={this.props.points}
      />
    );
  }

  _removeFromCart = (indexToRemove) => {
    const updatedItems = this.state.updatedItems.filter(
      (item, index) => index !== indexToRemove
    );

    this.props.actions.removeFromCart(indexToRemove);
    this.setState({ updatedItems });
  };

  _updateCart = () => {
    const itemsToUpdate = this.state.updatedItems
      .map((index) => {
        const item = this.state.shoppingCartItems[index];

        if (!item) {
          return null;
        }
        return {
          index,
          lineItem: {
            denomination: item.denomination,
            quantity: item.quantity,
            method: item.method,
          },
        };
      })
      .filter((item) => item);

    this.props.actions.updateCart(itemsToUpdate);
    this.props.router.push('/rewards/checkout/');
  };

  _updateDenomination = (shoppingCartItem, newDenomination, index) => {
    const shoppingCartItems = this.state.shoppingCartItems.map((item) => {
      if (item.productId === shoppingCartItem.productId) {
        item.denomination = newDenomination;
      }

      return item;
    });

    const currUpdatedItems = this.state.updatedItems;

    const updatedItems =
      currUpdatedItems.indexOf(index) === -1
        ? [...currUpdatedItems, index]
        : currUpdatedItems;

    this.setState({
      shoppingCartItems,
      updatedItems,
    });
  };

  _updateQuantity = (shoppingCartItem, newQuantity, index) => {
    const shoppingCartItems = this.state.shoppingCartItems.map((item) => {
      if (item.productId === shoppingCartItem.productId) {
        item.quantity = newQuantity;
      }

      return item;
    });

    const currUpdatedItems = this.state.updatedItems;

    const updatedItems =
      currUpdatedItems.indexOf(index) === -1
        ? [...currUpdatedItems, index]
        : currUpdatedItems;

    this.setState({
      shoppingCartItems,
      updatedItems,
    });
  };

  _updateDelivery = (shoppingCartItem, newMethod, index) => {
    const shoppingCartItems = this.state.shoppingCartItems.map((item) => {
      if (item.productId === shoppingCartItem.productId) {
        item.method = newMethod;
      }

      return item;
    });

    const currUpdatedItems = this.state.updatedItems;

    const updatedItems =
      currUpdatedItems.indexOf(index) === -1
        ? [...currUpdatedItems, index]
        : currUpdatedItems;

    this.setState({
      shoppingCartItems,
      updatedItems,
    });
  };
}

ShoppingCart.propTypes = {
  actions: PropTypes.shape({
    removeFromCart: PropTypes.func.isRequired,
    updateCart: PropTypes.func.isRequired,
    getRewards: PropTypes.func.isRequired,
  }).isRequired,

  shoppingCartItems: PropTypes.array,
  points: PropTypes.number,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const cartItems = shoppingCartSelector(state);

  const shoppingCartItems = cartItems.map((item) => {
    const deliveryOptions = [];

    if (item.isDigital) {
      deliveryOptions.push({
        label: 'Email',
        value: RewardsConstants.RewardDeliveryForm.DIGITAL,
      });
    }

    if (item.isPhysical) {
      deliveryOptions.push({
        label: 'Standard Mail',
        value: RewardsConstants.RewardDeliveryForm.PHYSICAL,
      });
    }

    return {
      ...item,
      deliveryOptions,
    };
  });

  return {
    shoppingCartItems,
    points: selectAvailablePoints(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { removeFromCart, updateCart, getRewards } = RewardsActions;

  return {
    actions: bindActionCreators(
      {
        removeFromCart,
        updateCart,
        getRewards,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(ShoppingCart)
);
