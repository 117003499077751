import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import BottomNav from '../../components/onboarding/BottomNav';
import ConnectDevice from '../common/ConnectDevice';

const ConnectDeviceMarkup = ({ onFinishClick, scrollUp }) => {
  const styles = {
    mainContainer: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      border: `1px solid ${Colors.lightGray}`,
      borderRadius: '5px',
      margin: '30px 0 80px',
      padding: '0 30px',
    },
    image: {
      maxWidth: '100%',
    },
    para: {
      textAlign: 'center',
      margin: '0 0 20px',
      color: Colors.dark,
      maxWidth: '100%',
    },
    btn: {
      marginBottom: '30px',
      padding: '8px 20px',
    },
    agreement: {
      marginTop: '0px',
      marginBottom: '0px',
      fontSize: '16px',
      textAlign: 'center',
    },
  };

  const { inboundSSOEnabled } = AppConfig.effectiveConfig;

  return (
    <div>
      <div style={[styles.mainContainer, GlobalStyles.regular]}>
        <img
          style={styles.image}
          src={require('../../images/ConnectDeviceSources.png')}
          alt="Connected Device Options"
          title="Connected Device Options"
        />
        <p style={styles.para}>
          <strong>Connecting a device</strong> not only allows us to better
          configure your health, but also measure specific weaknesses and
          strengths that you struggle with.
        </p>
        <p style={styles.para}>
          We will take the data that is provided by Human API and can integrate
          your Steps, Calories, Weight, Blood Pressure, and A1C to name a few.
        </p>
        <ConnectDevice style={styles.btn} itemText="Connect" />
      </div>

      {inboundSSOEnabled ? (
        <p style={styles.agreement}>
          By clicking "Finish!", I agree to HealthMine&rsquo;s&nbsp;
          <a href="#/public/terms" target="_blank">
            Terms of Use
          </a>{' '}
          and{' '}
          <a href="#/public/privacy" target="_blank">
            Privacy Policy
          </a>.
        </p>
      ) : null}

      <BottomNav
        nextText="Finish!"
        scrollDown={onFinishClick}
        scrollUp={scrollUp}
      />
    </div>
  );
};

ConnectDeviceMarkup.propTypes = {
  onFinishClick: PropTypes.func.isRequired,
  scrollUp: PropTypes.func.isRequired,
};

export default Radium(ConnectDeviceMarkup);
