import { types } from './actions';

const INITIAL_STATE = {
  accessToken: undefined,
  patientId: undefined,
  rawData: undefined,
};

const blueButton = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.BLUE_BUTTON_CLEAR:
      return { ...INITIAL_STATE };

    case types.BLUE_BUTTON_SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      };

    case types.BLUE_BUTTON_SET_PATIENT_ID:
      return {
        ...state,
        patientId: action.patientId,
      };

    case types.BLUE_BUTTON_SET_RAW_DATA:
      return {
        ...state,
        rawData: action.rawData,
      };

    case types.BLUE_BUTTON_START_AUTH:
      return {
        ...state,
        accessToken: undefined,
      };

    default:
      return state;
  }
};

export default {
  blueButton,
};
