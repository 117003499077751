import _ from 'lodash';

const format = (drugName) => {
  if (!drugName) return '';
  const halves = drugName.toUpperCase().split(' - ');
  const dashLocation = drugName.indexOf(' - ');
  return dashLocation === -1
    ? drugName
    : drugName.substr(
        0,
        halves[0] === halves[1] ? dashLocation : drugName.length
      );
};

export const pharmacy = (resource) => {
  const prescriptionItem = _.get(resource, 'item[0]');

  const fillNum = _.get(
    _.find(_.get(prescriptionItem, 'quantity.extension'), {
      url: 'https://bluebutton.cms.gov/resources/variables/fill_num',
    }),
    'valueQuantity.value'
  );

  const daysSupply = _.get(
    _.find(_.get(prescriptionItem, 'quantity.extension'), {
      url: 'https://bluebutton.cms.gov/resources/variables/days_suply_num',
    }),
    'valueQuantity.value'
  );

  const drugName = _.get(
    _.find(_.get(prescriptionItem, 'service.coding'), {
      system: 'http://hl7.org/fhir/sid/ndc',
    }),
    'display',
    'Unknown'
  );

  const ndc = _.get(
    _.find(_.get(prescriptionItem, 'service.coding'), {
      system: 'http://hl7.org/fhir/sid/ndc',
    }),
    'code'
  );

  const dateOfService = _.get(prescriptionItem, 'servicedDate');

  return {
    fillNum,
    daysSupply,
    ndc,
    drugName: format(drugName),
    dateOfService,
    prescriptionRAW: prescriptionItem,
  };
};
