import React from 'react';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { Flex, Box } from 'reflexbox';
import ConnectDevice from '../common/ConnectDevice';

const AddDevice = () => {
  const styles = {
    container: {
      borderBottom: `1px solid ${Colors.gutterGray}`,
      borderRight: `1px solid ${Colors.gutterGray}`,
      height: '220px',
      maxHeight: '220px',
    },
    icon: {
      fontSize: '100px',
      color: Colors.linkBlue,
      ':hover': {
        color: Colors.lightenColor(Colors.linkBlue, 0.5),
      },
    },
    label: {
      fontSize: '16px',
      color: Colors.bodyText,
      fontWeight: 400,
    },
  };
  return (
    <Flex
      col={4}
      justify={'center'}
      align={'center'}
      column
      style={styles.container}
    >
      <ConnectDevice
        link
        itemText={<i className="thin-0151_plus_add_new" style={styles.icon} />}
      />
      <Box p={2} style={styles.label}>
        Add or Remove Devices
      </Box>
    </Flex>
  );
};

export default Radium(AddDevice);
