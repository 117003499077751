import { compose, pure, withHandlers, withProps, flattenProp } from 'recompose';
import { withRouter } from 'react-router';
import _ from 'lodash';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import CarePlanActionListWrapper from './CarePlanActionListWrapper.markup';

export default compose(
  pure,
  withRouter,
  flattenProp('carePlan'),
  withHandlers({
    openTracking: ({ router, route }) => () => router.push(route),
    viewEducation: ({ router }) => (contentId) =>
      router.push(`/pha/${contentId}`),
  }),
  withProps(
    ({
      pastDueActions,
      requiredSoonActions,
      requiredActions,
      optionalActions,
      completedActions,
      clinicalActions,
      status,
      checkpoint,
      subject,
      trackingActions,
      actions,
    }) => {
      const unifiedCarePlan = AppConfig.validateFeature(
        Features.UNIFIED_CARE_PLAN
      );

      const isAssignedStatus = status === AppConstants.PlanStatus.ASSIGNED;

      const carePlanActions = unifiedCarePlan
        ? []
        : subject === 'PREVENTIVE'
          ? actions
          : _.concat(
              clinicalActions ? clinicalActions : [],
              trackingActions ? trackingActions : []
            );

      return {
        carePlanActions,
        noBorder:
          (_.isEmpty(pastDueActions) &&
            _.isEmpty(requiredSoonActions) &&
            _.isEmpty(requiredActions) &&
            _.isEmpty(optionalActions) &&
            _.isEmpty(completedActions) &&
            _.isEmpty(carePlanActions)) ||
          isAssignedStatus ||
          checkpoint,
      };
    }
  )
)(CarePlanActionListWrapper);
