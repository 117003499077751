import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import CurrentRewardMarkup from './CurrentRewardMarkup';
import _ from 'lodash';

class CurrentReward extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelModalOpen: false,
      automatedModalOpen: false,
    };
  }

  render() {
    return (
      <div>
        <CurrentRewardMarkup
          isAutomated={this.props.isAutomated}
          toggleAutomatedModal={this._toggleAutomatedModal}
          toggleCancelModal={this._toggleCancelModal}
          cancelModalOpen={this.state.cancelModalOpen}
          automatedModalOpen={this.state.automatedModalOpen}
          automatedReward={this.props.automatedReward}
        />
      </div>
    );
  }

  _toggleCancelModal = () => {
    this.setState({ cancelModalOpen: !this.state.cancelModalOpen });
  };

  _toggleAutomatedModal = () => {
    this.setState({ automatedModalOpen: !this.state.automatedModalOpen });
  };
}

CurrentReward.propTypes = {
  actions: PropTypes.object.isRequired,
  isAutomated: PropTypes.bool.isRequired,
  automatedReward: PropTypes.object,
};

function mapStateToProps(state) {
  const preference = state.rewards.preference;

  const rewards = state.rewards.rewards;

  const isAutomated =
    preference &&
    preference.productId !== null &&
    preference.productId !== undefined
      ? true
      : false;

  const preferredDeliveryMethod = _.get(preference, 'delivery');

  const automatedReward = isAutomated
    ? _.find(rewards, {
        productId: preference.productId,
        deliveryForm: preferredDeliveryMethod,
      })
    : undefined;

  return { isAutomated, automatedReward };
}

function mapDispatchToProps(dispatch) {
  const { getRewardsPreference } = RewardsActions;

  return {
    actions: bindActionCreators({ getRewardsPreference }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentReward);
