import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { pick } from 'lodash/fp';
import {
  DrawerButton,
  DrawerContent,
  withDrawer,
  DrawerSection,
} from './drawer2';
import BlueButtonActions from '../../../modules/BlueButton/actions';
import withBlueButton from '../../../modules/BlueButton/withBlueButton';
import Clickable from './Clickable';
import { withStyles } from '@material-ui/core';
import Typography from './Typography';
import PrivacyPolicy from '../pages/help/top-picks/PrivacyPolicy';
import Terms from '../pages/help/top-picks/Terms';

const BlueButtonImg = require('../../../images/BlueButton.png');

const styles = {
  link: { textDecoration: 'underline' },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '8px',
    '& img': {
      height: '80px',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
  },
};

const EditBlueButtonDrawerContent = ({
  classes,
  clearBlueButtonData,
  isAuthorizedForBlueButton,
  startBlueButtonAuth,
  openPrivacyPolicy,
  openTermsOfUse,
}) => (
  <DrawerContent title="Blue Button">
    {isAuthorizedForBlueButton ? (
      <DrawerSection>
        You have agreed to sharing your Blue Button 2.0 data through your
        Medicare Government Agency, CMS. By choosing to not share this data, you
        will have a less customized experience to maintain or improve your
        health.
      </DrawerSection>
    ) : (
      <DrawerSection>
        We need your permission to accept your Medicare data. We will use your
        data to help personalize your health action plan and make your
        information easy to understand in your Health Record. Please review our
        policies below.
      </DrawerSection>
    )}
    <DrawerSection>
      <Clickable underline onClick={openPrivacyPolicy}>
        <Typography secondary className={classes.footerLink}>
          Privacy Policy
        </Typography>
      </Clickable>
      <Clickable underline onClick={openTermsOfUse}>
        <Typography secondary className={classes.footerLink}>
          Terms of Use
        </Typography>
      </Clickable>
    </DrawerSection>

    <DrawerSection grow />
    {!isAuthorizedForBlueButton && (
      <DrawerSection>
        Click below to approve and start earning rewards for healthy living.
      </DrawerSection>
    )}
    {isAuthorizedForBlueButton ? (
      <DrawerButton
        type="dismiss"
        onClick={clearBlueButtonData}
        text="Stop sharing my data"
      />
    ) : (
      <button onClick={startBlueButtonAuth} className={classes.button}>
        <img src={BlueButtonImg} alt="Blue Button" title="Blue Button" />
      </button>
    )}
  </DrawerContent>
);

EditBlueButtonDrawerContent.propTypes = {
  clearBlueButtonData: PropTypes.func.isRequired,
  isAuthorizedForBlueButton: PropTypes.bool.isRequired,
  startBlueButtonAuth: PropTypes.func.isRequired,
  openTermsOfUse: PropTypes.func.isRequired,
  openPrivacyPolicy: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(
  pure,
  withStyles(styles),
  withDrawer,
  withBlueButton,
  connect(null, (dispatch) => ({
    actions: bindActionCreators(
      pick(['clearBlueButtonData'], BlueButtonActions),
      dispatch
    ),
  })),
  withHandlers(({ drawer, onBlueButtonClear, actions }) => ({
    clearBlueButtonData: () => {
      actions.clearBlueButtonData();
      onBlueButtonClear();
    },
    openPrivacyPolicy: () => drawer.open(PrivacyPolicy, null, { large: true }),
    openTermsOfUse: () => drawer.open(Terms, null, { large: true }),
  }))
)(EditBlueButtonDrawerContent);
