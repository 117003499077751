import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure, withProps, withHandlers } from 'recompose';
import { mergeAll, pick } from 'lodash/fp';
import { WithCoverageSelection } from '@healthmine/greyhound-core/src/hocs';
import { MyDataActions } from '@healthmine/greyhound-core/src/actions';
import {
  DrawerContent,
  DrawerNavigation,
  DrawerSectionHeader,
  DrawerSelect,
  withDrawer,
} from './drawer2';
import EditProfileDrawerContent from './EditProfileDrawerContent';
import { SessionActions } from '../../../actions';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import withBlueButton from '../../../modules/BlueButton/withBlueButton';
import BlueButtonActions from '../../../modules/BlueButton/actions';
import EditBlueButtonDrawerContent from './EditBlueButtonDrawerContent';

const AccountProfileDrawerContent = ({
  coverageOptions,
  selectedMemberCoverageId,
  setSelectedCoverage,
  drawer,
  onProfileSubmit,
  openHumanConnect,
  logoutAction,
  isBlueButtonEnabled,
  onBlueButtonClear,
}) => {
  const usesConnectedDevices = !AppConfig.validateFeature(
    Features.NO_CONNECTED_DEVICES
  );

  return (
    <DrawerContent title="Account Profile" headerAction={logoutAction}>
      {coverageOptions.length > 1 && (
        <div>
          <DrawerSectionHeader title="Coverage" />
          <DrawerSelect
            value={selectedMemberCoverageId}
            options={coverageOptions}
            onChange={setSelectedCoverage}
            subLabel="Coverage"
          />
        </div>
      )}
      <DrawerSectionHeader title="Manage Account" />
      {usesConnectedDevices && (
        <DrawerNavigation title="Devices" onClick={() => openHumanConnect()} />
      )}
      <DrawerNavigation
        title="Edit profile"
        onClick={() =>
          drawer.open(EditProfileDrawerContent, { onSubmit: onProfileSubmit })
        }
      />
      {isBlueButtonEnabled && (
        <DrawerNavigation
          title="Blue Button data"
          onClick={() =>
            drawer.open(EditBlueButtonDrawerContent, {
              onBlueButtonClear,
            })
          }
        />
      )}
    </DrawerContent>
  );
};

AccountProfileDrawerContent.propTypes = {
  coverageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  drawer: PropTypes.object.isRequired,
  selectedMemberCoverageId: PropTypes.number.isRequired,
  setSelectedCoverage: PropTypes.func.isRequired,
  onProfileSubmit: PropTypes.func.isRequired,
  openHumanConnect: PropTypes.func.isRequired,
  logoutAction: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    deviceConnected: PropTypes.func.isRequired,
    endSession: PropTypes.func.isRequired,
  }).isRequired,
  isBlueButtonEnabled: PropTypes.bool.isRequired,
  onBlueButtonClear: PropTypes.func.isRequired,
};

export default compose(
  pure,
  withBlueButton,
  withDrawer,
  WithCoverageSelection,
  connect(pick(['deviceCredentials']), (dispatch) => ({
    actions: bindActionCreators(
      mergeAll([
        pick(['deviceConnected'], MyDataActions),
        pick(['endSession'], SessionActions),
        pick(['clearBlueButtonData'], BlueButtonActions),
      ]),
      dispatch
    ),
  })),
  withProps(({ actions, memberCoverages }) => ({
    coverageOptions: memberCoverages.map(
      ({ memberCoverageId, coverageName, relationshipLabel }) => ({
        value: memberCoverageId,
        label: coverageName,
        subLabel: relationshipLabel,
      })
    ),
    logoutAction: {
      label: 'Logout',
      color: 'cosmopolitan',
      onClick: () => {
        actions.clearBlueButtonData();
        actions.endSession();
      },
    },
  })),
  withHandlers({
    onProfileSubmit: ({ drawer }) => () => drawer.back(),
    openHumanConnect: ({ actions, deviceCredentials }) => () => {
      const { deviceConnected } = actions;
      const { memberId, humanApiClientId, publicToken } = deviceCredentials;
      const options = {
        clientUserId: memberId,
        clientId: humanApiClientId, // grab it from app settings page
        publicToken, // Leave blank for new users
        modal: 1,
        finish(err, sessionTokenObject) {
          const payload = pick(['humanId', 'sessionToken'], sessionTokenObject);
          deviceConnected(payload);
        },
      };

      window.HumanConnect.open(options);
    },
    onBlueButtonClear: ({ drawer }) => () => drawer.back(),
  })
)(AccountProfileDrawerContent);
