import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconContainer: {
    marginTop: '211px',
    width: '75px',
    height: '75px',
    backgroundColor: 'rgb(33, 150, 243)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  icon: {
    fontSize: '35px',
    color: Colors.white,
  },
  titleContainer: {
    marginTop: '20px',
    color: Colors.white,
    fontSize: '38px',
  },
  textContainer: {
    marginTop: '20px',
    color: Colors.white,
    textAlign: 'center',
    fontSize: '24px',
  },
  continueContainer: {
    marginTop: '20px',
    fontSize: '20px',
    color: Colors.linkBlue,
    cursor: 'pointer',
  },
};

const TutorialIntro = ({ onContinueClick }) => {
  return (
    <div style={styles.container}>
      <span style={styles.iconContainer}>
        <i className="thin-0720_user_location_position" style={styles.icon} />
      </span>
      <div style={[GlobalStyles.extraLight, styles.titleContainer]}>
        Houston, we have liftoff!
      </div>
      <div style={[GlobalStyles.regular, styles.textContainer]}>
        Consider this page your health overview--your launching point for all
        the
        <br />
        important things. Let's get you acquainted.
      </div>
      <div style={styles.continueContainer} onClick={onContinueClick}>
        Continue
      </div>
    </div>
  );
};

TutorialIntro.propTypes = {
  onContinueClick: PropTypes.func.isRequired,
};

export default Radium(TutorialIntro);
