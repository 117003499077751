import moment from 'moment';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import striptags from 'striptags';
import { MessageCenterConstants } from '@healthmine/greyhound-core/src/constants';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

const MessageModalMarkup = ({ message, closeModal, setMessageState }) => {
  const styles = {
    modal: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },

      content: {
        minWidth: '350px',
        minHeight: '200px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: Colors.white,
        outline: 'none',
        padding: '50px 80px 30px',

        WebkitOverflowScrolling: 'touch',
      },
    },

    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    infoLine: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '17px',
      fontWeight: 500,
      color: Colors.navyBlue,
      padding: '15px 0px 15px 0px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
      marginBottom: '10px',
    },

    infoLineText: {
      display: 'flex',
      paddingRight: '20px',
    },

    messageDate: {
      display: 'flex',
      whiteSpace: 'nowrap',
    },

    messageContent: {
      color: Colors.bodyText,
      fontSize: '16px',
      lineHeight: '1.5',
      padding: '15px 0 0',
      maxHeight: '300px',
      overflowX: 'hidden',
      overflowY: 'auto',
    },

    statusLink: {
      display: 'inline-block',
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.highlightBlue,
      padding: '20px 0',
      cursor: 'pointer',
      float: 'right',
    },
  };

  return (
    <Modal
      isOpen
      contentLabel="MessageModal"
      onRequestClose={() => closeModal()}
      style={styles.modal}
    >
      <div>
        <a onClick={() => closeModal()} style={styles.closeButton}>
          <i className="thin-0153_delete_exit_remove_close" />
        </a>
      </div>

      <div style={[GlobalStyles.light, styles.modalContent]}>
        <label style={styles.infoLine}>
          <span style={styles.infoLineText}>{message.subject}</span>
          <span style={styles.messageDate}>
            {moment().diff(moment(message.sentTime), 'd') <= 1
              ? moment(message.sentTime).fromNow()
              : moment(message.sentTime).format('MM/DD/YYYY')}
          </span>
        </label>

        {/* eslint-disable react/no-danger */}
        <div
          style={styles.messageContent}
          dangerouslySetInnerHTML={{
            __html: striptags(message.body, ['p', 'a', 'br']),
          }}
        />
        {/* eslint-enable react/no-danger */}

        <span
          style={styles.statusLink}
          onClick={() =>
            setMessageState(
              message.status === MessageCenterConstants.MessageStatus.DELETE
                ? MessageCenterConstants.MessageStatus.READ
                : MessageCenterConstants.MessageStatus.DELETE,

              true
            )
          }
        >
          {message.status === MessageCenterConstants.MessageStatus.DELETE
            ? 'Restore'
            : 'Delete'}
        </span>
      </div>
    </Modal>
  );
};

MessageModalMarkup.propTypes = {
  message: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  setMessageState: PropTypes.func.isRequired,
};

export default Radium(MessageModalMarkup);
