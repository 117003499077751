import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  GlobalStyles,
  Colors,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import CarePlans from '@healthmine/greyhound-core/src/modules/CarePlans';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import moment from 'moment';

const IncentiveSummary = ({ carePlan }) => {
  const styles = {
    container: {
      minHeight: '200px',
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
    },
    titleSection: {
      marginBottom: '40px',
      display: 'flex',
    },
    sectionHeader: {
      fontSize: '16px',
      color: Colors.bodyText,
      paddingBottom: '10px',
    },
    pointRow: {
      fontSize: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '8px',
    },
    pointLabel: {
      color: Colors.contentText,
    },

    pointValueContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingRight: '0px',
      position: 'relative',
    },

    column: {
      paddingLeft: '20px',
      paddingRight: '20px',
      flex: 1,
      flexDirection: 'column',
    },
    placeholder: {
      visibility: 'hidden',
    },
    disclaimer: {
      color: Colors.contentText,
      textAlign: 'center',
      fontSize: '16px',
      maxWidth: '400px',
      alignSelf: 'center',
    },

    completionBadge: {
      position: 'absolute',
      top: '0px',
      right: '-25px',
      display: 'flex',
      height: '14px',
      width: '14px',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Colors.linkBlue,
      border: '1px solid #fff',
    },

    completionBadgeIcon: {
      backgroundColor: 'transparent',
      color: Colors.white,
      fontSize: '16px',
    },
  };

  const { incentiveType, lingo } = AppConfig.effectiveConfig;

  const currentDate = moment();

  const currentYear = currentDate.year();

  const primaryTitle = `How to earn ${
    incentiveType === AppConstants.IncentiveType.REWARDS ? 'rewards' : 'points'
  }`;

  const profileItems = CarePlans.getPointsProfile(carePlan);

  const groupedItems = _([..._.get(profileItems, 'goal.requirements', [])])
    .concat(_.get(profileItems, 'clinical.requirements'))
    .filter()
    .reduce(
      (groups, item, index) => {
        groups[index % 2].push(item);
        return groups;
      },
      [[], []]
    );

  return (
    <div style={[GlobalStyles.regular, styles.container]}>
      <div style={styles.titleSection}>
        {_.map(groupedItems, (items, itemsIndex) => (
          <div key={`ConciseColumn_${itemsIndex}`} style={styles.column}>
            {itemsIndex === 0 ? (
              <div style={styles.sectionHeader}>{primaryTitle}</div>
            ) : (
              <div style={[styles.pointRow, styles.placeholder]}>
                placeholder
              </div>
            )}

            {_.map(items, (item, itemIndex) => (
              <div
                key={`pointProfileItem_${itemsIndex}_${itemIndex}`}
                style={styles.pointRow}
              >
                <span style={styles.pointLabel}>{item.description}:</span>

                <span style={styles.pointValueContainer}>
                  {item.pointsDisplay}
                  {item.isCompleted ? (
                    <div style={styles.completionBadge}>
                      <i
                        className="material-icons"
                        style={styles.completionBadgeIcon}
                      >
                        done
                      </i>
                    </div>
                  ) : null}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
      {incentiveType === AppConstants.IncentiveType.REWARDS && (
        <div style={styles.disclaimer}>
          {`For your ${currentYear} Annual Wellness Visit, you’ll earn $50. (It’s worth $25, if you've already earned $75 towards your $100 annual maximum).`}
          <br />
          {`All other ${
            lingo.healthAction
          }s will earn you a $25 gift card, up to the maximum $100 annual amount.`}
        </div>
      )}
    </div>
  );
};

IncentiveSummary.propTypes = {
  carePlan: PropTypes.object,
};

export default Radium(IncentiveSummary);
