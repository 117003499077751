import React from 'react';
import PropTypes from 'prop-types';
import List, { ListHeader, ListContent } from '../../../common/list2/List';
import MedicationItem from './MedicationItem';
import { compose, withProps } from 'recompose';
import _ from 'lodash';

const MedicationList = ({ medications, medicationCount }) => {
  return (
    <List disabled={medicationCount === 0}>
      <ListHeader
        iconClass="icon-Medicine-Pills"
        title="Medications &amp; Supplements"
        color="#6074ee"
        subtitle={
          medicationCount !== 0 ? `${medicationCount} total` : undefined
        }
      />
      <ListContent
        isEmpty={!medicationCount}
        emptyMessage="No medications found."
      >
        {medications.map(
          ({ name, startDate, drugStrength, daysSupply, bb }, idx) => (
            <MedicationItem
              key={idx}
              drugName={name}
              dateOfService={startDate}
              drugStrength={drugStrength}
              daysSupply={daysSupply}
              bb={bb}
            />
          )
        )}
      </ListContent>
    </List>
  );
};

MedicationList.propTypes = {
  medications: PropTypes.array.isRequired,
  medicationCount: PropTypes.number.isRequired,
};

export default compose(
  withProps(({ medications }) => ({
    medicationCount: _.get(medications, 'length', 0),
  }))
)(MedicationList);
