import { connect } from 'react-redux';
import { compose, pure, withStateHandlers, withProps } from 'recompose';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { selectUnifiedCarePlan } from '@healthmine/greyhound-core/src/selectors/UnifiedCarePlanSelectors';
import UnifiedCarePlanMarkup from './UnifiedCarePlan.markup';
import _ from 'lodash';

export default compose(
  pure,
  connect(selectUnifiedCarePlan),
  withStateHandlers(
    {
      isGridView: false,
    },
    {
      setGridView: () => () => ({
        isGridView: true,
      }),

      setListView: () => () => ({
        isGridView: false,
      }),
    }
  ),
  withProps(({ education }) => {
    return {
      hasAssignedEducation:
        !AppConfig.validateFeature(Features.NO_ASSIGNED_EDUCATION) &&
        !_.isEmpty(education),
    };
  })
)(UnifiedCarePlanMarkup);
