import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import moment from 'moment';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import { DrawerNavigation } from '../../../common/drawer2';

const HealthAction = ({
  completed,
  onClick,
  subtitle,
  title,
  displayStatus,
}) => (
  <DrawerNavigation
    onClick={onClick}
    title={title}
    status={displayStatus}
    incentivesToEarn={!completed}
    subtitle={subtitle}
  />
);

HealthAction.propTypes = {
  completed: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  displayStatus: PropTypes.string,
};

export const enhance = compose(
  pure,
  withProps(({ healthAction, selectHealthAction }) => {
    const {
      id,
      title,
      completed,
      incentiveAmount,
      lastCompletedOn,
      displayStatus,
    } = healthAction;

    let subtitle;
    if (displayStatus !== 'negative') {
      subtitle = completed
        ? `completed on ${moment(lastCompletedOn).format('MM/DD/YYYY')}`
        : IncentiveSummary.getIncentiveEarningText(false, incentiveAmount);
    }

    const onClick = selectHealthAction ? () => selectHealthAction(id) : null;

    return {
      completed,
      onClick,
      subtitle,
      title,
      displayStatus,
    };
  })
);

export default enhance(HealthAction);
