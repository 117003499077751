import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const HealthOverviewMenuMarkup = () => {
  const styles = {
    menuOptionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '15px',
    },

    menuOptionContainer: {
      ':hover': {
        backgroundColor: Colors.gutterGray,
      },
    },

    menuOption: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: Colors.bodyText,
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '16px',
      textDecoration: 'none',
      padding: '8px 20px',
      cursor: 'pointer',
    },

    separator: {
      border: 'none',
      backgroundColor: Colors.gutterGray,
      height: '2px',
      margin: '10px 20px',
    },
  };

  return (
    <div style={styles.menuOptionsContainer}>
      <div key="DataLink" style={styles.menuOptionContainer}>
        <Link to="/data" style={styles.menuOption}>
          My Data
        </Link>
      </div>

      <hr style={styles.separator} />

      <div key="CarePlansLink" style={styles.menuOptionContainer}>
        <Link to="/care-plans" style={styles.menuOption}>
          Add/Change Care Plan
        </Link>
      </div>
    </div>
  );
};

export default Radium(HealthOverviewMenuMarkup);
