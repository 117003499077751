import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '../../common/Typography';
import classnames from 'classnames';
import { Link } from 'react-router';
import { compose, withProps } from 'recompose';
import { withSweepstakes } from '@healthmine/greyhound-core/src/hocs';
import { selectCurrentIncentiveSummary } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import { commaFormat } from '@healthmine/greyhound-core/src/utils';

const PointsBar = ({ classes, totalPoints, enteredTotal, openTotal }) => (
  <Grid
    className={classes.root}
    container
    alignItems="center"
    justify="space-between"
  >
    <Grid item xs={12} md="auto">
      <Grid
        container
        alignItems="center"
        spacing={8}
        className={classes.innerContainer}
      >
        <Grid item>
          <i className={classnames(classes.icon, 'icon-Star-Circle')} />
        </Grid>
        <Grid item>
          <Typography type="h1">{totalPoints} Pts</Typography>
          <Typography type="h3">Total Earned</Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md="auto" className={classes.sectionDivided}>
      <Grid container spacing={8} className={classes.innerContainer}>
        <Grid item>
          <Typography type="h3Medium">
            {enteredTotal} of {openTotal}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>Sweepstakes Entered</Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      item
      xs={12}
      md="auto"
      className={classnames(classes.sectionDivided, classes.buttonCenter)}
    >
      <Link className={classes.viewButton} to="/sweepstakes">
        <Grid container alignItems="center" spacing={8}>
          <Grid item>
            <Typography type="h3Medium">View Sweepstakes</Typography>
          </Grid>
          <Grid item>
            <i className="icon-Right-Arrow" />
          </Grid>
        </Grid>
      </Link>
    </Grid>
  </Grid>
);

PointsBar.propTypes = {
  classes: PropTypes.object.isRequired,
  totalPoints: PropTypes.string.isRequired,
  enteredTotal: PropTypes.number.isRequired,
  openTotal: PropTypes.number.isRequired,
};

const styles = (theme) => ({
  root: {
    borderRadius: theme.globalStyles.borderRadiusFull,
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.globalStyles.borderRadius1,
    },
    boxShadow: theme.globalStyles.shadow2,
    padding: '20px',
    backgroundColor: '#fff',
    marginBottom: '30px',
  },
  icon: {
    fontSize: '60px',
  },
  viewButton: {
    display: 'inline-block',
    color: '#fff',
    backgroundColor: theme.palette.common.planet9,
    padding: '15px 30px',
    borderRadius: theme.globalStyles.borderRadiusFull,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.utils.colorLighten(theme.palette.common.planet9),
    },
    textDecoration: 'none',
  },
  sectionDivided: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
  innerContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  buttonCenter: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
});

export default compose(
  withStyles(styles),
  withSweepstakes,
  connect((state) => {
    const pointsSummary = selectCurrentIncentiveSummary(state);
    return {
      totalPoints: pointsSummary.totalPoints,
    };
  }),
  withProps(({ enteredSweepstakes, openSweepstakes, totalPoints }) => ({
    enteredTotal: enteredSweepstakes.length,
    openTotal: openSweepstakes.length,
    totalPoints: commaFormat(totalPoints),
  }))
)(PointsBar);
