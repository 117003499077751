import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import striptags from 'striptags';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import { EDUCATION_TYPES } from '@healthmine/greyhound-core/src/constants/Education';

const AssignedEducation = ({
  assignedEducation,
  expanded,
  setFrameLink,
  toggleExpansion,
}) => {
  const styles = {
    assignedContentWrapper: {
      padding: '20px 0 0',
    },
    planTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      padding: '20px 0 10px',
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.bodyText,
      borderBottom: `1px solid ${Colors.lighterGray}`,
    },

    planIcon: {
      backgroundColor: Colors.lightGreen,
      position: 'relative',
      display: 'flex',
      borderRadius: '50%',
      width: '34px',
      height: '34px',
      flex: '0 0 auto',
      fontSize: '17px',
      alignItems: 'center',
      justifyContent: 'center',
      color: Colors.white,
      marginLeft: '5px',
      marginRight: '20px',
    },

    planName: {
      paddingRight: '15px',
    },
    planCounts: {
      fontSize: '16px',
      color: Colors.contentText,
      fontWeight: 300,
    },
    planCount: {
      paddingRight: '8px',
    },
    contentType: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.bodyText,
    },
    contentItem: {
      padding: '0 0 15px',
      cursor: 'pointer',
    },
    contentDescription: {
      color: Colors.contentText,
      margin: '5px 0',
    },
    link: {
      color: Colors.black,
      textDecoration: 'none',
    },

    completedEducation: {
      color: Colors.white,
      padding: '2px',
      marginLeft: '8px',
      fontWeight: '600',
      fontSize: '16px',
      backgroundColor: Colors.linkBlue,
      borderRadius: '50%',
    },

    backLink: {
      color: Colors.highlightBlue,
      cursor: 'pointer',
      marginBottom: '30px',
      flex: 1,
    },
    expandLink: {
      fontSize: '16px',
      color: Colors.highlightBlue,
      cursor: 'pointer',
      marginTop: 0,
      marginBottom: '30px',
    },
  };
  return (
    <div>
      {assignedEducation.map(({ icon, name, subject, contentTypes }) => (
        <div key={subject} style={styles.assignedContentWrapper}>
          <div style={styles.planTitle}>
            <span style={styles.planIcon}>
              <i className={icon} />
            </span>
            <span style={styles.planName}>{name}</span>
            <span style={styles.planCounts}>
              {contentTypes.map(({ type, label, length }) => (
                <span key={`header-${type}`} style={styles.planCount}>
                  {label} ({length})
                </span>
              ))}
            </span>
          </div>
          <div>
            {contentTypes.map(
              ({ type, label, content, previewContent, length }) => (
                <div key={type} style={styles.assignedContentWrapper}>
                  <p style={styles.contentType}>{label}</p>
                  {(expanded === type ? content : previewContent).map(
                    (item, index) => (
                      <div
                        key={index}
                        style={styles.contentItem}
                        onClick={() => setFrameLink(item)}
                      >
                        <span style={styles.link}>{item.title}</span>
                        {item.completionDate ? (
                          <i
                            className="material-icons"
                            style={styles.completedEducation}
                          >
                            done
                          </i>
                        ) : (
                          ''
                        )}
                        <p style={styles.contentDescription}>
                          {striptags(item.preview)}
                        </p>
                      </div>
                    )
                  )}
                  {length > 2 ? (
                    <p
                      style={styles.expandLink}
                      onClick={() => toggleExpansion(type)}
                    >
                      {`View ${
                        expanded === type ? 'less' : 'all'
                      } ${name} Education Items >`}
                    </p>
                  ) : null}
                </div>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const educationShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  completionDate: PropTypes.string,
});

AssignedEducation.propTypes = {
  expanded: PropTypes.string,
  setFrameLink: PropTypes.func.isRequired,
  toggleExpansion: PropTypes.func.isRequired,
  assignedEducation: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      contentTypes: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          type: PropTypes.oneOf(EDUCATION_TYPES).isRequired,
          length: PropTypes.number.isRequired,
          previewContent: PropTypes.arrayOf(educationShape).isRequired,
          content: PropTypes.arrayOf(educationShape).isRequired,
        })
      ),
    })
  ),
};

export default Radium(AssignedEducation);
