import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  compose,
  pure,
  setPropTypes,
  withHandlers,
  withStateHandlers,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { CarePlanActions } from '@healthmine/greyhound-core/src/actions';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import * as CarePlanSelectors from '@healthmine/greyhound-core/src/selectors/CarePlanSelector';
import CarePlansPageMarkup from './CarePlansPageMarkup';

export default compose(
  pure,
  withRouter,

  connect(
    (state) => {
      const allGoals = _.get(state, 'carePlanGoals.subjects');

      const myGoals = CarePlanSelectors.selectMyCurrentGoals(state);

      const assignableCarePlans = CarePlanSelectors.selectSelfAssignableGoals(
        state
      );

      const myCarePlans = _(myGoals)
        .filter((myGoal) => myGoal.status !== AppConstants.PlanStatus.DISMISSED)
        .map((myGoal) => ({ ...myGoal, ..._.get(allGoals, myGoal.subject) }))
        .value();

      return {
        myCarePlans,
        assignableCarePlans,
      };
    },

    (dispatch) => {
      const { assignMeThisGoal, dismissMyGoal } = CarePlanActions;

      return {
        actions: bindActionCreators(
          {
            assignMeThisGoal,
            dismissMyGoal,
          },
          dispatch
        ),
      };
    }
  ),

  withStateHandlers(
    {
      carePlanBeingDismissed: null,
      carePlanBeingViewed: null,
    },

    {
      setCarePlanBeingDismissed: (state) => (carePlan) => ({
        ...state,
        carePlanBeingDismissed: carePlan,
      }),

      onTogglePlanDetails: (state) => (carePlan) => ({
        ...state,

        carePlanBeingViewed:
          state.carePlanBeingViewed === carePlan ? null : carePlan,
      }),
    }
  ),

  withHandlers({
    onCreateUserPlan: ({ router, actions: { assignMeThisGoal } }) => (
      carePlan
    ) => {
      assignMeThisGoal(carePlan.subject).then(() =>
        router.push(`/care-plan-build/${carePlan.subject}`)
      );
    },

    onDismissAssignedPlan: ({ setCarePlanBeingDismissed }) =>
      setCarePlanBeingDismissed,

    onCarePlanDismissed: ({
      setCarePlanBeingDismissed,
      actions: { dismissMyGoal },
    }) => (carePlan) => {
      if (carePlan) {
        dismissMyGoal(carePlan.id);
      }

      setCarePlanBeingDismissed(null);
    },
  }),

  setPropTypes({
    assignableCarePlans: PropTypes.arrayOf(PropTypes.object),
    myCarePlans: PropTypes.arrayOf(PropTypes.object),

    actions: PropTypes.shape({
      assignMeThisGoal: PropTypes.func.isRequired,
      dismissMyGoal: PropTypes.func.isRequired,
    }).isRequired,

    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  })
)(CarePlansPageMarkup);
