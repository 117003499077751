import { connect } from 'react-redux';
import React from 'react';
import { SignInActions } from '@healthmine/greyhound-core/src/actions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import VerifyMfaMarkup from '../../components/mfa/VerifyMfaMarkup';
import { withRouter } from 'react-router';
import { SessionActions } from '../../actions';

class VerifyMfaPage extends React.Component {
  constructor(props) {
    super(props);
    this._verifyMfaCode = this._verifyMfaCode.bind(this);
    this._mfaCodeChange = this._mfaCodeChange.bind(this);
    this.state = {
      mfaCode: '',
      isVerified: false,
    };

    if (!this.props.accessToken) {
      this.props.router.replace('signin');
    }
  }

  render() {
    return (
      <VerifyMfaMarkup
        isLoading={this.props.mfaFetch}
        isVerified={this.state.isVerified}
        mfaCode={this.state.mfaCode}
        onMfaCodeChange={this._mfaCodeChange}
        onVerifyMfaCode={this._verifyMfaCode}
        errorMessage={this.props.mfaErrorMessage}
      />
    );
  }

  _mfaCodeChange(e) {
    this.setState({ mfaCode: e.target.value });
  }

  _verifyMfaCode(e) {
    e.preventDefault();
    const { verifyMfa } = this.props.actions;
    verifyMfa(this.state.mfaCode).then(this.onVerifyMfaProcessed);
  }

  onVerifyMfaProcessed = (verifyMfaResult) => {
    if (verifyMfaResult) {
      const {
        actions: { startSession },
      } = this.props;
      const memberCoverageId = localStorage.getItem('selectedMemberCoverageId');

      this.setState({
        isVerified: true,
      });

      this.props.actions.postSignInUserData({ memberCoverageId }).then(() => {
        startSession(JSON.stringify(verifyMfaResult));
        this.setState({
          email: '',
          password: '',
        });
      });
    }
  };
}

VerifyMfaPage.propTypes = {
  actions: PropTypes.shape({
    verifyMfa: PropTypes.func,
    postSignInUserData: PropTypes.func,
    startSession: PropTypes.func,
  }),
  accessToken: PropTypes.string,
  mfaFetch: PropTypes.bool,
  mfaErrorMessage: PropTypes.string,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    accessToken: state.signIn.accessToken,
    mfaFetch: state.signIn.mfaFetch,
    mfaErrorMessage: state.signIn.mfaErrorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  const { startSession } = SessionActions;
  const { verifyMfa, postSignInUserData } = SignInActions;

  return {
    actions: bindActionCreators(
      {
        verifyMfa,
        postSignInUserData,
        startSession,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(VerifyMfaPage)
);
