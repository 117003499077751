import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Radium from 'radium';
import BiometricScreeningConstants from '@healthmine/greyhound-core/src/constants/BiometricScreening';
import { BiometricReportResult } from '@healthmine/greyhound-core/src/constants/prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import BiometricReportRow from './BiometricReportRow';

const {
  MeasurementRiskLevel,
  MeasurementRiskLevelColor,
} = BiometricScreeningConstants;

const styles = {
  descriptionText: {
    color: Colors.bodyText,
    fontSize: '16px',
  },

  resultTableHeading: {
    color: Colors.white,
    padding: '10px 20px',
  },

  columnHeaderContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  columnIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '18px',
    height: '18px',
    border: '2px solid white',
    borderRadius: '50%',
    marginRight: '8px',
  },

  columnIcon: {
    fontSize: '16px',
    fontWeight: 'bold',
  },

  columnHeaderCaptionContainer: {
    whiteSpace: 'pre',
    fontSize: '16px',
  },

  descriptionColumnHeader: {
    backgroundColor: Colors.lightGray,
  },

  resultColumnHeader: {
    backgroundColor: Colors.gutterGray,
    color: Colors.bodyText,
  },

  lowRiskColumnHeader: {
    backgroundColor: MeasurementRiskLevelColor[MeasurementRiskLevel.LOW],
  },

  modRiskColumnHeader: {
    backgroundColor: MeasurementRiskLevelColor[MeasurementRiskLevel.MODERATE],
  },

  highRiskColumnHeader: {
    backgroundColor: MeasurementRiskLevelColor[MeasurementRiskLevel.HIGH],
  },
};

const BiometricReportTable = ({ testResults }) =>
  !_.isEmpty(testResults) && (
    <div>
      <p style={styles.descriptionText}>
        These values are based on data collected during your participation in
        the biometric health screening. Please consult your doctor if you have
        questions regarding your results.
      </p>

      <p style={styles.descriptionText}>
        To determine how you were awarded points for your Health Outcomes,
        reference the Health Outcomes section of the <a href="#faq">FAQ</a>.
      </p>

      <table style={styles.resultTable} cellPadding="0" cellSpacing="0">
        <thead>
          <tr>
            <td
              style={[
                styles.resultTableHeading,
                styles.descriptionColumnHeader,
              ]}
            >
              <span style={styles.columnHeaderCaptionContainer}>
                {'TEST\nDESCRIPTION'}
              </span>
            </td>

            <td style={[styles.resultTableHeading, styles.resultColumnHeader]}>
              <span style={styles.columnHeaderCaptionContainer}>
                {'YOUR\nRESULT'}
              </span>
            </td>

            <td style={[styles.resultTableHeading, styles.lowRiskColumnHeader]}>
              <div style={styles.columnHeaderContentContainer}>
                <span style={styles.columnIconContainer}>
                  <i className="material-icons" style={styles.columnIcon}>
                    done
                  </i>
                </span>

                <span style={styles.columnHeaderCaptionContainer}>
                  {'LOW\nRISK'}
                </span>
              </div>
            </td>

            <td style={[styles.resultTableHeading, styles.modRiskColumnHeader]}>
              <div style={styles.columnHeaderContentContainer}>
                <span style={styles.columnIconContainer}>
                  <i className="material-icons" style={styles.columnIcon}>
                    remove
                  </i>
                </span>

                <span style={styles.columnHeaderCaptionContainer}>
                  {'MOD\nRISK'}
                </span>
              </div>
            </td>

            <td
              style={[styles.resultTableHeading, styles.highRiskColumnHeader]}
            >
              <div style={styles.columnHeaderContentContainer}>
                <span style={styles.columnIconContainer}>
                  <span style={styles.columnIcon}>!</span>
                </span>

                <span style={styles.columnHeaderCaptionContainer}>
                  {'HIGH\nRISK'}
                </span>
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          {testResults.map((testResult, testResultIndex) => (
            <BiometricReportRow
              key={`BiometricReportRow_${testResultIndex}`}
              testResult={testResult}
            />
          ))}
        </tbody>
      </table>
    </div>
  );

BiometricReportTable.propTypes = {
  testResults: PropTypes.arrayOf(BiometricReportResult).isRequired,
};

export default Radium(BiometricReportTable);
