import React from 'react';
import PropTypes from 'prop-types';
import { DrawerNavigation } from '../../../common/drawer2';

const CustomAction = ({ title, completed, showMore }) => (
  <DrawerNavigation
    onClick={showMore}
    title={title}
    status={completed ? 'positive' : 'neutral'}
  />
);

CustomAction.propTypes = {
  title: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  showMore: PropTypes.func.isRequired,
};

export default CustomAction;
