import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { BiometricReport } from '@healthmine/greyhound-core/src/constants/prop-types';
import BiometricReportHeader from './BiometricReportHeader';
import BiometricReportTable from './BiometricReportTable';

const styles = {
  container: {
    paddingLeft: '75px',
    paddingRight: '75px',
  },

  biometricReport: {
    marginTop: '30px',
  },
};

const BiometricReportMarkup = ({
  reportOptions,
  selectedReport,
  selectedReportIndex,
  userInfo,
  clientMemberId,
  onReportSelected,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.biometricReport}>
        <BiometricReportHeader
          reportOptions={reportOptions}
          report={selectedReport}
          currentIndex={selectedReportIndex}
          onReportChange={onReportSelected}
          userInfo={userInfo}
          clientMemberId={clientMemberId}
        />

        <BiometricReportTable testResults={_.get(selectedReport, 'results')} />
      </div>
    </div>
  );
};

BiometricReportMarkup.propTypes = {
  reportOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),

  selectedReport: BiometricReport,
  selectedReportIndex: PropTypes.number.isRequired,
  userInfo: PropTypes.object.isRequired,
  clientMemberId: PropTypes.string,
  onReportSelected: PropTypes.func.isRequired,
};

export default Radium(BiometricReportMarkup);
