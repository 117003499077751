import React from 'react';
import PropTypes from 'prop-types';
import { DrawerContent, DrawerSection } from '../../../../common/drawer2';
import Typography from '../../../../common/Typography';
import { Grid } from '@material-ui/core';

const CoachingHowToEarn = ({
  enrollmentRewardAmount,
  programRewardAmount,
  requiredActivityCount,
}) => {
  return (
    <DrawerContent title="How to Earn Points">
      <DrawerSection>
        {/* eslint-disable max-len */}
        <Typography type="body">{`Every month you have the opportunity to complete a coaching session. Starting your first session earns you ${enrollmentRewardAmount} points. After you complete 3 coaching sessions, you’ll earn ${programRewardAmount} points!`}</Typography>
        {/* eslint-enable max-len */}
      </DrawerSection>
      <DrawerSection>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography type="h3" secondary>
              Register for health coaching
            </Typography>
          </Grid>
          <Grid item>
            <Typography type="h3">+{enrollmentRewardAmount} pts</Typography>
          </Grid>
        </Grid>
      </DrawerSection>
      <DrawerSection>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography type="h3" secondary>
              {`Complete ${requiredActivityCount} coaching sessions`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography type="h3">+{programRewardAmount} pts</Typography>
          </Grid>
        </Grid>
      </DrawerSection>
    </DrawerContent>
  );
};

CoachingHowToEarn.propTypes = {
  programRewardAmount: PropTypes.number.isRequired,
  enrollmentRewardAmount: PropTypes.number.isRequired,
  requiredActivityCount: PropTypes.number.isRequired,
};

export default CoachingHowToEarn;
