import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, pure, withProps } from 'recompose';
import moment from 'moment';
import _ from 'lodash';
import Typography from '../common/Typography';
import { withDrawer } from '../common/drawer2';
import Terms from '../pages/help/top-picks/Terms';
import PrivacyPolicy from '../pages/help/top-picks/PrivacyPolicy';
import Support from '../pages/help/top-picks/Support';
import Clickable from '../common/Clickable';
import { Grid, withStyles } from '@material-ui/core';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';

const styles = {
  root: {
    padding: '40px',
  },
  footerLink: {
    textDecoration: 'underline',
  },
};

const Footer = ({ links, classes }) => (
  <div className={classes.root}>
    <Grid container alignItems="center" spacing={8}>
      <Grid item>
        <Typography secondary>
          Powered by Healthmine, Inc. &copy; {moment().year()}
        </Typography>
      </Grid>
      {_.map(links, (link, idx) => {
        return (
          <Grid item key={`footer_link_${idx}`}>
            <Clickable onClick={link.onClick} underline>
              <Typography secondary className={classes.footerLink}>
                {link.title}
              </Typography>
            </Clickable>
          </Grid>
        );
      })}
    </Grid>
  </div>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

export default compose(
  pure,
  connect((state) => ({
    isSignedIn: !!state.signIn.accessToken && !state.signIn.appLoadFailed,
  })),
  withDrawer,
  withRouter,
  withProps(({ drawer, isSignedIn, router }) => ({
    links: [
      {
        title: 'Home',
        onClick: () =>
          isSignedIn
            ? router.push('/')
            : (window.location.href = _.get(
                AppConfig,
                'effectiveConfig.logoutRedirect',
                '/'
              )),
      },
      {
        title: 'Terms of Use',
        onClick: () =>
          isSignedIn
            ? drawer.open(Terms, null, { large: true })
            : router.push('/public/terms'),
      },
      {
        title: 'Privacy Policy',
        onClick: () =>
          isSignedIn
            ? drawer.open(PrivacyPolicy, null, { large: true })
            : router.push('public/privacy'),
      },
      {
        title: 'Support',
        onClick: () =>
          isSignedIn
            ? drawer.open(Support, null, { large: true })
            : router.push('public/contact'),
      },
    ],
  })),
  withStyles(styles)
)(Footer);
