import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Styles from './ComplianceStatus.styles';

const ComplianceStatusMarkup = ({
  heading,
  title,
  titleColor,
  description,
}) => {
  return (
    <div>
      <div style={Styles.sectionHeading}>{heading}</div>
      <div style={Styles.complianceBox}>
        <div style={[Styles.title, { color: titleColor }]}>{title}</div>
        <div style={Styles.description}>{description}</div>
      </div>
    </div>
  );
};

ComplianceStatusMarkup.propTypes = {
  heading: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Radium(ComplianceStatusMarkup);
