import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import {
  CoverageSelectors,
  SingleCarePlanSelectors,
} from '@healthmine/greyhound-core/src/selectors';
import EmployerGroupBannerMarkup from './EmployerGroupBanner.markup';

export default compose(
  pure,

  connect((state) => {
    const currentCoverage = CoverageSelectors.selectCurrentCoverage(state);

    const coverageRelationshipCd = _.get(currentCoverage, 'relationshipCd');

    const employerGroup = _.get(currentCoverage, 'coverage.employerGroup');

    const employerGroupName = _.get(employerGroup, 'name');

    const employerGroupLogo = _.get(employerGroup, 'logoUrl');

    const programName = _.get(employerGroup, 'attributes.PROGRAM_NAME', '');

    const employerGroupLogoUrl = employerGroupLogo
      ? `s3://employer_group_assets/${employerGroupLogo}`
      : '';

    const enrolledCarePlan = SingleCarePlanSelectors.selectCurrentEnrolledCarePlan(
      state
    );

    const actionItemCount = _.size(_.get(enrolledCarePlan, 'actionItems'));

    const completedActionItemCount = _.get(
      enrolledCarePlan,
      'completedActionItemCount',
      0
    );

    const showProgress = !!(
      enrolledCarePlan &&
      _.includes(
        [
          AppConstants.Relationships.SUBSCRIBER,
          AppConstants.Relationships.SPOUSE,
        ],
        coverageRelationshipCd
      )
    );

    return {
      employerGroupName,
      programName,
      actionItemCount,
      completedActionItemCount,
      employerGroupLogoUrl,
      showProgress,
    };
  })
)(EmployerGroupBannerMarkup);
