import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { withDrawer } from '../../../../../common/drawer2';
import StartTracking from '../../../tracking/StartTracking';
import {
  ListItem,
  ListItemStyles as styles,
} from '../../../../../common/list/List';
import StatusTitle from '../../../../../common/StatusTitle';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import classnames from 'classnames';

const PrescribedTrackerAction = ({ classes, tracker, drawer }) => {
  return (
    <ListItem onClick={() => drawer.open(StartTracking, { tracker })}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={10}>
          <StatusTitle
            status="neutral"
            title={`Add a ${tracker.displayName} Tracker`}
          />
        </Grid>
        <Grid item>
          <div className={classes.incentiveConditionsWrapper}>
            <div
              className={classnames(
                classes.incentive,
                classes.incentiveToBeEarned
              )}
            >
              {`Earn up to ${IncentiveSummary.formatPoints(
                tracker.maxDailyRewardAmount
              )} daily`}
            </div>
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
};

PrescribedTrackerAction.propTypes = {
  classes: PropTypes.object.isRequired,
  tracker: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
};

export default compose(pure, withDrawer, withStyles(styles))(
  PrescribedTrackerAction
);
