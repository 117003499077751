import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle } from 'recompose';
import {
  withHealthPrograms,
  withNewContentViewing,
} from '@healthmine/greyhound-core/src/hocs';
import { ActivityConstants } from '@healthmine/greyhound-core/src/constants';
import { withStyles } from '@material-ui/core/styles';
import PageSection from '../../common/page/PageSection';
import _map from 'lodash/map';
import Card from '../../common/card/Card';
import Typography from '../../common/Typography';

const styles = () => ({
  customCard: {
    marginTop: '15px',
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '25px',
    height: '25px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'black',
    color: 'white',
    fontSize: '15px',
    marginRight: '10px',
  },
  section: {
    marginTop: '15px',
  },
});

const HealthPrograms = ({ programs, classes }) => (
  <PageSection title="Programs">
    {_map(
      programs,
      ({
        customerProgramId,
        title,
        description,
        icon,
        siteUrl,
        iconBubbleColor,
      }) => {
        return (
          <Card
            customClass={classes.customCard}
            key={`health_program_${customerProgramId}`}
          >
            <div className={classes.cardTitle}>
              <i
                className={`icon-${icon} ${classes.icon}`}
                style={{ backgroundColor: iconBubbleColor }}
              />
              <Typography type="h3Medium">{title}</Typography>
            </div>
            <div className={classes.section}>
              <Typography>{description}</Typography>
            </div>
            <div className={classes.section}>
              {siteUrl ? (
                <a
                  href={siteUrl}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                >
                  <Typography type="link">
                    <span style={{ textDecoration: 'underline' }}>
                      Learn More
                    </span>{' '}
                    <i className="icon-Right-Arrow" />
                  </Typography>
                </a>
              ) : (
                <Typography>
                  Contact your Human Resources team to learn more.
                </Typography>
              )}
            </div>
          </Card>
        );
      }
    )}
  </PageSection>
);

HealthPrograms.propTypes = {
  programs: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withNewContentViewing,
  lifecycle({
    componentDidMount() {
      const { seenNewContent, actions } = this.props;
      const { createNewContentViewing } = actions;

      const contentType =
        ActivityConstants.NewContentViewingTypes.MEMBER_HEALTH_PROGRAMS;

      if (!seenNewContent(contentType)) {
        createNewContentViewing(contentType);
      }
    },
  }),
  withStyles(styles),
  withHealthPrograms
)(HealthPrograms);
