import _ from 'lodash';

export const inpatient = (resource) => {
  const diagnoses = _.map(
    _.filter(_.get(resource, 'diagnosis'), (diagnosis) =>
      _.get(diagnosis, 'diagnosisCodeableConcept.coding[0].display')
    ),
    (diagnosis) => ({
      display: _.get(diagnosis, 'diagnosisCodeableConcept.coding[0].display'),
      primary: _.some(_.get(diagnosis, 'type'), (diagnosisType) =>
        _.some(_.get(diagnosisType, 'coding'), {
          system:
            'https://bluebutton.cms.gov/resources/codesystem/diagnosis-type',
          code: 'principal',
        })
      ),
      admitting: _.some(_.get(diagnosis, 'type'), (diagnosisType) =>
        _.some(_.get(diagnosisType, 'coding'), {
          system:
            'https://bluebutton.cms.gov/resources/codesystem/diagnosis-type',
          code: 'admitting',
        })
      ),
    })
  );

  const procedures = _.map(_.get(resource, 'procedure'), (procedure) => ({
    date: _.get(procedure, 'date'),
    display: _.get(procedure, 'procedureCodeableConcept.coding[0].display'),
  }));

  const hospitalization = _.get(resource, 'hospitalization');

  return {
    diagnoses,
    procedures,
    hospitalization,
  };
};
