import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Card from '../../../../common/card/Card';
import StatusWidget from '../../../../common/widgets/StatusWidget';
import Overlay from './Overlay';
import { displayMaxCount } from '@healthmine/greyhound-core/src/utils';

const styles = (theme) => ({
  customCard: {
    width: '237px',
    height: '230px',
    position: 'relative',
  },
  bubble: {
    height: '60px',
    width: '60px',
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '30px',
  },
  title: {
    marginTop: '10px',
    flexGrow: 1,
  },
});

const CarePlanCard = ({
  classes,
  remainingActionCount,
  actionCompletionPercent,
  title,
  iconClass,
  color,
  isOverlayVisible,
  showOverlay,
  hideOverlay,
  openDrawer,
  whatsNextActionTitle,
  maxDisplayCount,
}) => {
  return (
    <Card
      customClass={classes.customCard}
      column
      onMouseOver={showOverlay}
      onMouseOut={hideOverlay}
      onClick={openDrawer}
    >
      <div className={classes.bubble} style={{ backgroundColor: color }}>
        <i className={`${iconClass} ${classes.icon}`} />
      </div>
      <div className={classes.title}>{title}</div>
      <div style={{ width: '100%' }}>
        <StatusWidget
          color={color}
          status={displayMaxCount(remainingActionCount, maxDisplayCount)}
          statusNote={`action${
            remainingActionCount !== 1 ? 's' : ''
          } remaining`}
          percentage={actionCompletionPercent}
        />
      </div>
      {remainingActionCount > 0 && (
        <Overlay
          visible={isOverlayVisible}
          color={color}
          iconClass={iconClass}
          actionTitle={whatsNextActionTitle}
        />
      )}
    </Card>
  );
};

CarePlanCard.propTypes = {
  classes: PropTypes.object.isRequired,
  remainingActionCount: PropTypes.number.isRequired,
  actionCompletionPercent: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isOverlayVisible: PropTypes.bool.isRequired,
  showOverlay: PropTypes.func.isRequired,
  hideOverlay: PropTypes.func.isRequired,
  openDrawer: PropTypes.func,
  whatsNextActionTitle: PropTypes.string.isRequired,
  maxDisplayCount: PropTypes.number,
};

export default compose(
  pure,
  withStateHandlers(
    {
      isOverlayVisible: false,
    },
    {
      showOverlay: (state) => () => {
        return {
          ...state,
          isOverlayVisible: true,
        };
      },
      hideOverlay: (state) => () => {
        return {
          ...state,
          isOverlayVisible: false,
        };
      },
    }
  ),
  withStyles(styles)
)(CarePlanCard);
