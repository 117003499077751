import moment from 'moment';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {
  AppConstants,
  RewardsConstants,
} from '@healthmine/greyhound-core/src/constants';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const IncentiveHistoryMenuMarkup = ({
  summaryList,
  incentiveType,
  availablePoints,
  totalPoints,
}) => {
  const RadiumLink = Radium(Link);

  const styles = {
    menuHeader: {
      margin: '0px 15px',
      paddingLeft: '10px',
      paddingBottom: '10px',
      paddingTop: '10px',
      fontSize: '16px',
      fontWeight: '500',
      color: Colors.bodyText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },

    transactionContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      paddingTop: '12px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },

    transactionContent: {
      flex: 1,
      paddingLeft: '20px',
      paddingBottom: '15px',
      overflow: 'hidden',
      fontWeight: '300',
      lineHeight: '12px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },

    transactionDescription: {
      fontSize: '16px',
      color: Colors.bodyText,
      paddingBottom: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    transactionFooter: {
      marginTop: '5px',
      display: 'flex',
      justifyContent: 'space-between',
    },

    transactionDate: {
      fontSize: '16px',
      color: Colors.contentText,
    },

    transactionPoints: {
      fontSize: '16px',
      color: Colors.bodyText,
    },

    fullSummaryLink: {
      display: 'block',
      padding: '20px 0 10px',
      fontSize: '16px',
      fontWeight: '500',
      textAlign: 'center',
      color: Colors.highlightBlue,
      cursor: 'pointer',
      textDecoration: 'none',
    },

    noTransactionsLabel: {
      fontSize: '16px',
      padding: '15px 0px',
      color: Colors.contentText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      textAlign: 'center',
    },
    summary: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#f3f6f8',
      paddingTop: '15px',
    },
    summaryContent: {
      display: 'flex',
      flexGrow: 1,
    },
    summaryContentLeft: {
      paddingRight: '10px',
      borderRight: `1px solid ${Colors.gutterGray}`,
    },
    summaryContentRight: {
      paddingLeft: '10px',
    },
    summaryTitle: {
      color: Colors.navyBlue,
      fontSize: '16px',
      fontWeight: 'bold',
      paddingBottom: '5px',
    },
    summaryDescription: {
      fontSize: '16px',
      lineHeight: '14px',
      color: Colors.bodyText,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };

  const { setCurrencyLabel } = RewardsConstants;

  const isReward = incentiveType === AppConstants.IncentiveType.REWARDS;

  const incentiveText = isReward ? 'Rewards' : 'Points';

  const baseRoute =
    incentiveType === AppConstants.IncentiveType.REWARDS
      ? 'rewards'
      : 'sweepstakes';

  return (
    <div>
      {isReward ? null : (
        <div style={[styles.summary, styles.transactionContent]}>
          <div style={styles.summaryContentLeft}>
            <div style={styles.summaryTitle}>Plan Year Total</div>
            <div style={styles.summaryDescription}>
              {totalPoints.toLocaleString()}
            </div>
          </div>
          <div style={styles.summaryContentRight}>
            <div style={styles.summaryTitle}>Spent</div>
            <div style={styles.summaryDescription}>
              {(totalPoints - availablePoints).toLocaleString()}
            </div>
          </div>
        </div>
      )}

      <div style={styles.menuHeader}>Recent Activity</div>

      {summaryList.length > 0 ? (
        summaryList.map((item, index) => (
          <div key={`${index}`} style={styles.transactionContainer}>
            <div style={styles.transactionContent}>
              <div style={styles.transactionDescription}>
                {item.dataType === 'MODULE_DATA' ||
                item.dataType === 'PLAN_DATA'
                  ? item.name
                  : item.description}
              </div>

              <div style={styles.transactionFooter}>
                <span style={styles.transactionDate}>
                  {moment(item.lastCompletedDate || item.lastIssuedDate).format(
                    'MM-DD-YYYY'
                  )}
                </span>

                <span style={styles.transactionPoints}>
                  {isReward
                    ? setCurrencyLabel(item.amount, true)
                    : `${item.amount} point${item.amount > 1 ? 's' : ''}`}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div style={styles.noTransactionsLabel}>No actions completed</div>
      )}

      <RadiumLink to={`/${baseRoute}/summary/`} style={styles.fullSummaryLink}>
        Full {incentiveText} Earned History
      </RadiumLink>
    </div>
  );
};

IncentiveHistoryMenuMarkup.propTypes = {
  summaryList: PropTypes.array,
  incentiveType: PropTypes.string,
  availablePoints: PropTypes.number.isRequired,
  totalPoints: PropTypes.number.isRequired,
};

export default Radium(IncentiveHistoryMenuMarkup);
