import _ from 'lodash';
import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';
import Scroll from 'react-scroll';
import { bindActionCreators } from 'redux';
import { FteActions } from '@healthmine/greyhound-core/src/actions';
import { Features } from '@healthmine/greyhound-core/src/constants';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import { AgreementSelectors } from '@healthmine/greyhound-core/src/selectors';
import { TutorialActions } from '../../actions/';
import ConnectDeviceMarkup from '../../components/onboarding/ConnectDeviceMarkup';
import ProgressLine from '../../components/onboarding/ProgressLine';
import Header from '../../components/onboarding/Header';
import GinaModal from '../../components/common/GinaModal';
import AboutYourselfContainer from './AboutYourselfContainer';
import HealthGoalsContainer from './HealthGoalsContainer';
import HowYouFeelContainer from './HowYouFeelContainer';
import RoutineContainer from './RoutineContainer';
import StepContainer from './StepContainer';

const scroller = Scroll.scroller;

const scrollDestinations = [
  'dashboardSection',
  'aboutYourselfSection',
  'routineSection',
  'howYouFeelSection',
  'healthGoalsSection',
  'connectDeviceSection',
];

const sectionsContainer = {
  backgroundColor: Colors.light,
  padding: '100px 0px 300px',
  width: '1200px',
  margin: '0 auto',
  borderLeft: `1px solid ${Colors.gutterGray}`,
  borderRight: `1px solid ${Colors.gutterGray}`,
  borderBottom: `1px solid ${Colors.gutterGray}`,
  boxShadow: '0 0 20px 0px rgba(186, 190, 186, 0.3)',
};

class OnboardingPage extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      step: 0,
    };
  }

  componentDidMount() {
    if (!this.props.hasPendingGinaAgreement) {
      this.timeoutId = setTimeout(this._initializeScroller, 2000);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      _.get(this, 'props.hasPendingGinaAgreement') &&
      !_.get(nextProps, 'hasPendingGinaAgreement')
    ) {
      this.setState({ step: 1 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    if (step !== prevState.step && step < 6) {
      this._scrollTo();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    const { step } = this.state;

    return (
      <div style={[sectionsContainer, GlobalStyles.light]}>
        <GinaModal
          onMemberAgreementCreated={
            step === 0 ? this._scrollDown : this._scrollTo
          }
        />

        <Header scrollDown={step === 0 ? this._scrollDown : this._scrollTo} />

        <StepContainer
          sectionName="aboutYourselfSection"
          title="Tell Us About Yourself"
          iconClass="thin-0702_user_profile_avatar_woman_female"
          backColor={Colors.lightGreen}
          vis={step === 1}
          colorful={step >= 1}
        >
          <AboutYourselfContainer
            step={1}
            scrollDown={this._scrollDown}
            scrollUp={this._scrollUp}
          />
        </StepContainer>

        <ProgressLine success={step > 1} />

        <StepContainer
          sectionName="routineSection"
          title="What Is Your Routine?"
          iconClass="thin-0102_notebook_to_do_bullets_list"
          backColor={Colors.orange}
          vis={step === 2}
          colorful={step >= 2}
        >
          <RoutineContainer
            step={2}
            scrollDown={this._scrollDown}
            scrollUp={this._scrollUp}
          />
        </StepContainer>

        <ProgressLine success={step > 2} />

        <StepContainer
          sectionName="howYouFeelSection"
          title="How Do You Feel?"
          iconClass="thin-0825_stetoscope_doctor_hospital_ill"
          backColor={Colors.pink}
          vis={step === 3}
          colorful={step >= 3}
        >
          <HowYouFeelContainer
            step={3}
            scrollDown={this._scrollDown}
            scrollUp={this._scrollUp}
          />
        </StepContainer>

        <ProgressLine success={step > 3} />

        <StepContainer
          sectionName="healthGoalsSection"
          title="What Are Your Health Goals?"
          iconClass="thin-0657_medal_award_rating_prize_achievement"
          backColor={Colors.purple}
          vis={step === 4}
          colorful={step >= 4}
        >
          <HealthGoalsContainer
            step={4}
            scrollDown={this._scrollDown}
            scrollUp={this._scrollUp}
            vis={step === 1}
            colorful={step >= 1}
            onFinishClick={this._finishOnboarding}
          />
        </StepContainer>

        {!AppConfig.validateFeature(Features.NO_CONNECTED_DEVICES) && (
          <div>
            <ProgressLine success={step > 4} />

            <StepContainer
              sectionName="connectDeviceSection"
              title="Let's Connect A Device"
              iconClass="thin-0260_link_url_chain_hyperlink"
              backColor={Colors.mediumBlue}
              vis={step === 5}
              colorful={step >= 5}
            >
              <ConnectDeviceMarkup
                onFinishClick={this._finishOnboarding}
                scrollUp={() => this._scrollUp(4)} //handle the scroller override directly cause this has no container
                vis={step === 5}
                colorful={step === 5}
              />
            </StepContainer>
          </div>
        )}
      </div>
    );
  }

  _scrollTo = () => {
    const { step } = this.state;
    setTimeout(
      () =>
        scroller.scrollTo(scrollDestinations[step], {
          duration: 1000,
          offset: -110,
          smooth: true,
          isDynamic: true,
        }),
      step > 1 ? 1000 : 0
    );
  };

  _initializeScroller = () => {
    this.setState({ step: 1 });
  };

  _scrollDown = (i) => {
    this.setState({ step: i });
  };
  _scrollUp = (i) => {
    this.setState({ step: i });
  };

  _finishOnboarding = () => {
    this.props.actions.completeFTE();

    if (AppConfig.validateFeature(Features.TUTORIAL))
      setTimeout(this.props.actions.showTutorial, 750);
  };
}

OnboardingPage.propTypes = {
  actions: PropTypes.object.isRequired,
  hasPendingGinaAgreement: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const hasPendingGinaAgreement = !!AgreementSelectors.selectPendingGinaAgreement(
    state
  );

  return {
    hasPendingGinaAgreement,
  };
}

function mapDispatchToProps(dispatch) {
  const { completeFTE } = FteActions;

  const { showTutorial } = TutorialActions;

  return {
    actions: bindActionCreators(
      {
        completeFTE,
        showTutorial,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(OnboardingPage)
);
