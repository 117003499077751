import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { hashHistory } from 'react-router';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Modal from 'react-modal';

const OrderFailureModal = ({ isOpen, closeHandler, serviceFailed }) => {
  const styles = {
    customStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },
      content: {
        minWidth: '510px',
        minHight: '165px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: '#fff',
        borderRadius: '10px',
        outline: 'none',
        padding: '40px 50px',
      },
    },

    closebutton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },

    modalContent: {
      minHeight: '100px',
    },

    modalBody: {
      fontSize: '16px',
      color: Colors.contentText,
      lineHeight: '16px',
    },

    actionLabel: {
      color: Colors.linkBlue,
      cursor: 'pointer',
    },

    errorLabel: {
      color: Colors.red,
    },
  };

  return (
    <Modal isOpen={isOpen} contentLabel={''} style={styles.customStyles}>
      <a onClick={() => closeHandler()} style={styles.closebutton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>
      <div style={styles.modalContent}>
        {serviceFailed ? (
          <div style={styles.modalBody}>
            Whoops! <span style={styles.errorLabel}>Something went wrong.</span>{' '}
            Check back in a few minutes and retry your submission.
          </div>
        ) : (
          <div style={styles.modalBody}>
            It looks like you{' '}
            <span style={styles.errorLabel}>
              do not have enough money to check out.
            </span>{' '}
            Check out our
            <span
              style={styles.actionLabel}
              onClick={() => hashHistory.push('/faq/')}
            >
              {' '}
              FAQ
            </span>{' '}
            page to find out how you can earn more money.
          </div>
        )}
      </div>
    </Modal>
  );
};

OrderFailureModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  serviceFailed: PropTypes.bool,
};

OrderFailureModal.defaultProps = {
  isOpen: false,
  serviceFailed: false,
};

export default Radium(OrderFailureModal);
