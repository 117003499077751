import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import Radium from 'radium';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';

const types = {
  EDUCATION: 'EDUCATION',
  DIRECT: 'DIRECT',
};

const styles = {
  actionableAction: {
    fontSize: '16px',
    fontWeight: '200',
    marginBottom: '15px',
    color: Colors.link.normal,
    cursor: 'pointer',

    ':hover': {
      color: Colors.link.hover,
    },
  },
};

const ActionableAction = ({ children, onClick }) => (
  <div onClick={onClick} style={styles.actionableAction}>
    {children}
  </div>
);

ActionableAction.propTypes = {
  children: PropTypes.node.isRequired,
  contentId: PropTypes.string,
  type: PropTypes.string,
  takeAction: PropTypes.func,
  onClick: PropTypes.func,
};
export { types };

export default compose(
  withHandlers({
    onClick: ({ type = types.DIRECT, takeAction, contentId }) => () => {
      if (takeAction) {
        switch (type) {
          case types.DIRECT:
            takeAction();
            break;
          case types.EDUCATION:
            takeAction(contentId);
            break;
          default:
            takeAction(type);
        }
      }
    },
  }),
  Radium
)(ActionableAction);
