import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
} from '../../../common/drawer2';
import Typography from '../../../common/Typography';

const styles = (theme) => ({
  complete: {
    color: theme.palette.common.grannySmith,
  },
  notComplete: {
    color: theme.palette.common.cosmopoltian,
  },
});

const CustomActionDrawer = ({ title, description, completed, classes }) => {
  return (
    <DrawerContent title={title}>
      <DrawerSectionHeader title="Status" />
      <DrawerSection>
        <div style={{ textAlign: 'center' }}>
          <Typography
            type="h1"
            className={classnames(
              completed ? classes.complete : classes.notComplete
            )}
          >
            {completed ? 'completed' : 'not complete'}
          </Typography>
        </div>
      </DrawerSection>
      <DrawerSectionHeader title="Description" />
      <DrawerSection>
        <Typography>{description}</Typography>
      </DrawerSection>
    </DrawerContent>
  );
};

CustomActionDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomActionDrawer);
