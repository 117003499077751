import React from 'react';
import { withStyles, Checkbox, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  DrawerSection,
  DrawerSectionHeader,
  DrawerNavigation,
  withDrawer,
} from '../../../../common/drawer2';
import { compose, withProps } from 'recompose';
import _ from 'lodash';
import MedConfigureDrawer from './MedConfigureDrawer';
import Typography from '../../../../common/Typography';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import MedicationTrackerActions from '../../../../../../modules/demo/medications/actions.js';
import MedDetailDrawer from './MedDetailDrawer';

const styles = (theme) => ({
  checkboxRow: {
    display: 'flex',
    height: '80px',
    padding: '12px 20px 12px 20px',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.common.formulaOne}`,
    cursor: 'pointer',
    transition: 'background-color 400ms ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.common.formulaOne,
    },
  },
  medName: {
    flexGrow: '1',
  },
  incentiveText: {
    color: theme.palette.common.whiskeySour,
  },
});
const MedTrackerDrawer = ({
  classes,
  noneConfigured,
  unconfiguredMeds,
  configuredMedsForToday,
  configuredMedsForOther,
  onConfigureMedication,
  onViewMedicationDetails,
  onTodayCheck,
  medsTakenToday,
}) => (
  <DrawerContent
    title={noneConfigured ? 'Start Tracking My Medication' : 'My Medications'}
  >
    <DrawerSection>
      <Typography>
        Taking your medications regularly, as prescribed by your doctor, is one
        of the best ways you can stay healthy.
      </Typography>
      <br />
      {noneConfigured ? (
        <Typography>
          Get started by clicking on each medication below.
        </Typography>
      ) : (
        <Typography>
          Click each box below if you took the medication today.
        </Typography>
      )}
    </DrawerSection>

    {_.size(configuredMedsForToday) > 0 && (
      <DrawerSectionHeader title="Today" />
    )}
    {_.map(configuredMedsForToday, (med, idx) => (
      <Grid
        key={`configured-med-today-${idx}`}
        container
        className={classes.checkboxRow}
        onClick={() => onViewMedicationDetails(med)}
      >
        <Grid item xs={2}>
          <Checkbox
            checked={_.get(medsTakenToday, med.name)}
            onClick={(e) => e.stopPropagation()}
            onChange={() => onTodayCheck(med)}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography type="h2" className={classes.medName}>
            {med.name}
          </Typography>
          <Typography className={classes.incentiveText}>earn 5 pts</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography secondary type="h2">
            <i className="icon-Right-Chevron-Circle" />
          </Typography>
        </Grid>
      </Grid>
    ))}
    {_.size(configuredMedsForOther) > 0 && (
      <DrawerSectionHeader title="Not Taking Today" />
    )}
    {_.map(configuredMedsForOther, (med, idx) => (
      <DrawerNavigation
        key={`configured-med-other-${idx}`}
        onClick={() => onViewMedicationDetails(med)}
        title={med.name}
      />
    ))}
    {_.size(unconfiguredMeds) > 0 && (
      <DrawerSectionHeader title="Configure Medication" />
    )}
    {_.map(unconfiguredMeds, (med, idx) => (
      <DrawerNavigation
        key={`unconfigured-med-${idx}`}
        onClick={() => onConfigureMedication(med)}
        title={med.name}
      />
    ))}
  </DrawerContent>
);

MedTrackerDrawer.propTypes = {
  noneConfigured: PropTypes.bool.isRequired,
  // from withStyles
  classes: PropTypes.object.isRequired,
  // from withProps
  unconfiguredMeds: PropTypes.array.isRequired,
  configuredMedsForToday: PropTypes.array.isRequired,
  configuredMedsForOther: PropTypes.array.isRequired,
  onConfigureMedication: PropTypes.func.isRequired,
  onViewMedicationDetails: PropTypes.func.isRequired,
  onTodayCheck: PropTypes.func.isRequired,
  medsTakenToday: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withDrawer,
  connect(
    (state) => ({
      medicationTracker: _.get(state, 'demo.medications'),
    }),
    (dispatch) => {
      const { toggleHistoryDate } = MedicationTrackerActions;
      return {
        actions: bindActionCreators(
          {
            toggleHistoryDate,
          },
          dispatch
        ),
      };
    }
  ),
  withProps(({ drawer, medicationTracker, actions }) => {
    const noneConfigured = _.every(medicationTracker, (med) =>
      _.isEmpty(med.schedule)
    );

    const meds = _.map(medicationTracker, (med, name) => ({
      name,
      ...med,
    }));

    const unconfiguredMeds = _.filter(meds, (med) => _.isEmpty(med.schedule));
    const configuredMedsForToday = _.map(
      _.filter(
        meds,
        (med) =>
          !_.isEmpty(med.schedule) &&
          med.schedule[
            moment()
              .format('dddd')
              .toLowerCase()
          ] === true
      ),
      (med) => ({
        ...med,
        takenToday: _.find(med.history, (date) => moment().isSame(date, 'd')),
      })
    );
    const configuredMedsForOther = _.map(
      _.filter(
        meds,
        (med) =>
          !_.isEmpty(med.schedule) &&
          med.schedule[
            moment()
              .format('dddd')
              .toLowerCase()
          ] === false
      ),
      (med) => ({
        ...med,
        takenToday: _.find(med.history, (date) => moment().isSame(date, 'd')),
      })
    );

    const onConfigureMedication = (medication) => {
      drawer.open(MedConfigureDrawer, { medication }, {});
    };

    const onTodayCheck = (medication, date) => {
      const { toggleHistoryDate } = actions;
      toggleHistoryDate(medication.name, date ? date : new Date());
    };

    const onViewMedicationDetails = (medication) =>
      drawer.open(
        MedDetailDrawer,
        {
          medicationName: medication.name,
          onConfigureMedication,
          onDateCheck: onTodayCheck,
        },
        {}
      );

    const medsTakenToday = _.mapValues(
      medicationTracker,
      (med) => !!_.find(med.history, (date) => date.isSame(new Date(), 'd'))
    );

    return {
      unconfiguredMeds,
      configuredMedsForToday,
      configuredMedsForOther,
      onConfigureMedication,
      onViewMedicationDetails,
      noneConfigured,
      onTodayCheck,
      medsTakenToday,
    };
  })
)(MedTrackerDrawer);
