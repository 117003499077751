import React from 'react';
import ViewDocument from '../../../common/ViewDocument';
import { DrawerContent } from '../../../common/drawer2';

const PrivacyPolicy = () => {
  return (
    <DrawerContent>
      <ViewDocument documentFile="privacy_policy_en.html" drawerFrame />
    </DrawerContent>
  );
};

export default PrivacyPolicy;
