import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import {
  withDrawer,
  DrawerContent,
  DrawerSection,
  DrawerNavigation,
} from '../../../common/drawer2';
import { withRouter } from 'react-router';
import Typography from '../../../common/Typography';
import HraAnswers from './HraAnswers';
import HraSummary from './HraSummary';
import HraIntro from './HraIntro';
import { withStyles } from '@material-ui/core';
import TypographyStyles from '../../../../styles/Typography';

const HraResults = ({
  openHraAnswers,
  openHraSummary,
  openHraIntro,
  visitMemberHealthPrograms,
  classes,
}) => {
  return (
    <DrawerContent title="Health Risk Assessment">
      <DrawerSection>
        <Typography type="body">
          This short assessment contains a series of questions designed to help
          you learn where you are in your health journey. Completing this vital
          assessment helps you answer questions about your health, know your
          risks, and get the tools you need to stay healthy.
        </Typography>
        <Typography type="body" className={classes.healthProgramText}>
          To learn more about staying healthy, visit the{' '}
          <a onClick={visitMemberHealthPrograms} className={classes.link}>
            Member Health Programs
          </a>{' '}
          page!
        </Typography>
      </DrawerSection>
      <DrawerNavigation title="View Answers" onClick={openHraAnswers} />
      <DrawerNavigation title="View Summary" onClick={openHraSummary} />
      <DrawerNavigation
        title="Re-take Health Risk Assessment"
        onClick={openHraIntro}
      />
    </DrawerContent>
  );
};

HraResults.propTypes = {
  openHraAnswers: PropTypes.func.isRequired,
  openHraSummary: PropTypes.func.isRequired,
  openHraIntro: PropTypes.func.isRequired,
  visitMemberHealthPrograms: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(
  pure,
  withRouter,
  withDrawer,
  withHandlers({
    openHraAnswers: ({ drawer }) => () =>
      drawer.open(HraAnswers, null, { large: true }),
    openHraSummary: ({ drawer }) => () => drawer.open(HraSummary),
    openHraIntro: ({ drawer }) => () =>
      drawer.open(HraIntro, null, { large: true }),
    visitMemberHealthPrograms: ({ drawer, router }) => () => {
      drawer.close();
      router.push('/health-programs');
    },
  }),
  withStyles({
    link: TypographyStyles.link,
    healthProgramText: {
      marginTop: '15px',
    },
  })
)(HraResults);
