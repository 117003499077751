import React from 'react';
import Radium, { StyleRoot } from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const LoadingBar = () => {
  const pulseKeyframes = Radium.keyframes({
    '0%': { width: '10%' },
    '50%': { width: '95%' },
    '100%': { width: '10%' },
  });

  const mainContainer = {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    zIndex: '10000',
  };

  const loadingBar = {
    height: '5px',
    background: Colors.lightenColor(Colors.dark, 3),
    animation: 'x 3s ease-in-out 0s infinite',
    animationName: pulseKeyframes,
    margin: '0 auto',
  };
  return (
    <StyleRoot style={mainContainer}>
      <div style={loadingBar} />
    </StyleRoot>
  );
};

export default LoadingBar;
