import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const __SURVEY_WINDOW_HEIGHT__ = 420;

const surveyAvailableAnimation = Radium.keyframes({
  from: {
    bottom: `-${__SURVEY_WINDOW_HEIGHT__}px`,
  },

  to: {
    bottom: `-${__SURVEY_WINDOW_HEIGHT__ - 50}px`,
  },
});

const surveyOpenAnimation = Radium.keyframes({
  from: {
    bottom: `-${__SURVEY_WINDOW_HEIGHT__ - 50}px`,
  },

  to: {
    bottom: '0px',
  },
});

const surveyCloseAnimation = Radium.keyframes({
  from: {
    bottom: '0px',
  },

  to: {
    bottom: `-${__SURVEY_WINDOW_HEIGHT__ - 50}px`,
  },
});

export default {
  surveyContainer: {
    position: 'absolute',
    bottom: `-${__SURVEY_WINDOW_HEIGHT__}px`,
    left: 0,
    right: 0,
    height: `${__SURVEY_WINDOW_HEIGHT__}px`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f0f0f0',
  },

  availableSurvey: {
    animation: 'survey-available .5s ease 0s 1 normal forwards',
    animationName: surveyAvailableAnimation,
  },

  openedSurvey: {
    animation: 'survey-open .5s ease 0s 1 normal forwards',
    animationName: surveyOpenAnimation,
  },

  closedSurvey: {
    animation: 'survey-close .5s ease 0s 1 normal forwards',
    animationName: surveyCloseAnimation,
  },

  collapseExpandButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    minHeight: '50px',
    maxHeight: '50px',
    backgroundColor: '#f0f0f0',
  },

  collapseExpandButtonCaption: {
    color: Colors.bodyText,
    fontSize: '20px',
    fontWeight: '200',
  },

  collapseExpandButtonChevron: {
    position: 'absolute',
    top: '5px',
    right: '10px',
    fontSize: '40px',
    color: '#c1c1c1',
  },

  submittedIndicator: {
    position: 'absolute',
    top: '12px',
    right: '10px',
    fontSize: '27px',
    color: Colors.white,
    backgroundColor: Colors.highlightBlue,
    borderRadius: '50%',
  },

  surveyContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: Colors.white,
    padding: '20px 0 0 70px',
  },

  surveyIntro: {
    color: Colors.bodyColor,
    fontSize: '18px',
    paddingRight: '50%',
  },

  questionsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },

  questionColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '25px 70px 30px 0',
  },

  submitButton: {
    height: '50px',
    fontSize: '17px',
    marginBottom: 0,
    backgroundColor: Colors.darkBlue,
  },

  disabledSubmitButton: {
    backgroundColor: '#c1c1c1',
  },
};
