import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SweepstakesActions } from '@healthmine/greyhound-core/src/actions';
import ProductModalMarkup from './ProductModalMarkup';
import _ from 'lodash';
import { selectAvailablePoints } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';

class ProductModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 1,
      isEntering: false,
      successfullyEntered: false,
    };
  }

  componentWillUnmount() {
    this.props.actions.clearEnterStatus();
  }

  render() {
    const { quantity } = this.state;

    const { product, points } = this.props;

    const { productId, toggleProduct, successfullyEntered } = this.props;

    const quantityLimit = 10;

    const maxPossible = Math.floor(points / product.entryCost);

    const maxQuantity =
      maxPossible < quantityLimit ? maxPossible : quantityLimit;

    const quantityOptions = maxQuantity > 0 ? _.range(1, maxQuantity + 1) : [];

    return (
      <ProductModalMarkup
        product={product}
        points={points}
        successfullyEntered={successfullyEntered}
        isEntering={this.state.isEntering}
        isOpen={!!productId}
        closeHandler={toggleProduct}
        quantity={quantity}
        quantityOptions={quantityOptions}
        selectQuantity={this._selectQuantity}
        enterSweepstake={this._enterSweepstake}
      />
    );
  }

  _selectQuantity = (e) => {
    this.setState({
      quantity: parseInt(e.target.value),
    });
  };

  _enterSweepstake = () => {
    if (!this.state.quantity) return;

    this.setState({ isEntering: true });
    const product = this.props.product;

    const entry = {
      sweepstakeId: product.sweepstakeId,
      count: this.state.quantity,
    };

    this.props.actions
      .enterSweepstake(entry)
      .then(() => this.setState({ isEntering: false }));
  };
}

ProductModal.propTypes = {
  actions: PropTypes.object.isRequired,
  params: PropTypes.object,
  productId: PropTypes.string,
  toggleProduct: PropTypes.func.isRequired,
  product: PropTypes.object,
  points: PropTypes.number,
  successfullyEntered: PropTypes.bool,
};

function mapStateToProps(state, props) {
  const prodId = props.productId ? props.productId : props.params.id;

  const product = state.sweepstakes.entities[prodId];

  return {
    product,
    points: selectAvailablePoints(state),
    successfullyEntered: state.sweepstakes.successfullyEntered,
  };
}

function mapDispatchToProps(dispatch) {
  const { enterSweepstake, clearEnterStatus } = SweepstakesActions;

  return {
    actions: bindActionCreators(
      {
        enterSweepstake,
        clearEnterStatus,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
