import _ from 'lodash';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, pure, withHandlers } from 'recompose';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';

const ProductGroupMarkup = ({ onProductGroupSelected }) => {
  const { productGroupConfigs } = AppConfig.effectiveConfig;

  const productGroups = _(AppConfig.effectiveConfig.productGroups)
    .map((productGroupId) => ({
      id: productGroupId,
      ..._.get(productGroupConfigs, productGroupId),
    }))
    .value();

  const styles = {
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      maxWidth: '100%',
      color: Colors.darkText,
      backgroundColor: Colors.light,
      padding: '10px',
      textAlign: 'center',
    },

    productGroupContainer: {
      display: 'flex',
      borderStyle: 'solid',
      borderColor: Colors.navyBlue,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      borderRadius: 1,
      borderWidth: 2,
      height: '150px',
      width: '350px',
      cursor: 'pointer',
    },

    logo: {
      width: '300px',
      height: '90px',
    },
  };

  return (
    <div style={styles.mainContainer}>
      {productGroups.map((productGroup, productGroupIndex) => (
        <div
          style={styles.productGroupContainer}
          key={productGroupIndex}
          onClick={() => onProductGroupSelected(productGroup.id)}
        >
          <img
            src={`${productGroup.assetsRootUrl}/logo_secondary.png`}
            style={styles.logo}
          />
        </div>
      ))}
    </div>
  );
};

ProductGroupMarkup.handlerMap = {
  onProductGroupSelected: ({ router }) => (productGroupId) => {
    AppConfig.setConfig(
      _.get(window, 'healthmine.appConfig', {}),
      productGroupId
    );
    sessionStorage.setItem('productGroup', productGroupId);
    router.push('/welcome');
  },
};

ProductGroupMarkup.propTypes = {
  onProductGroupSelected: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withHandlers(ProductGroupMarkup.handlerMap),
  pure,
  Radium
)(ProductGroupMarkup);
