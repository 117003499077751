import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Colors,
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import {
  Features,
  FteConstants,
} from '@healthmine/greyhound-core/src/constants';
import ItemRow from '../common/ItemRow';
import BottomNav from '../../components/onboarding/BottomNav';

const RoutineMarkup = ({
  fitnessAnswer,
  goodFoodAnswer,
  badFoodAnswer,
  onClickFitness,
  onClickGoodFood,
  onClickBadFood,
  errors,
  scrollDown,
  scrollUp,
  goodFoodAnswerChoices,
  badFoodAnswerChoices,
  skip,
}) => {
  const question = {
    fontSize: '16px',
    fontWeight: '400',
    margin: '50px 0 30px',
  };

  const error = {
    lineHeight: '25px',
    color: Colors.red,
  };

  const emptyBox = {
    paddingBottom: '25px',
  };

  const itemRowStyles = {
    icon: {
      padding: '18px',
      border: `2px solid ${Colors.mediumGray3}`,
      backgroundColor: Colors.light,
    },
    label: {
      fontSize: '16px',
    },
    words: {
      width: '60px',
      height: '60px',
      padding: '5px',
    },
    innerLabel: {
      width: '60px',
      height: '60px',
      fontSize: '16px',
    },
  };

  const { FitnessResources } = FteConstants;

  const goodFoodAnswers = goodFoodAnswerChoices
    ? goodFoodAnswerChoices.map((answer) => {
        return { label: answer.option.en, questionAnswerOptionId: answer.id };
      })
    : [];

  const badFoodAnswers = badFoodAnswerChoices
    ? badFoodAnswerChoices.map((answer) => {
        return { label: answer.option.en, questionAnswerOptionId: answer.id };
      })
    : [];

  return (
    <div>
      <h3 style={question}>How Many Times Per Week Do You Exercise?</h3>
      <ItemRow
        arr={FitnessResources.slice(0, 4)}
        startIndex={0}
        active={[fitnessAnswer]}
        onClick={onClickFitness}
        styles={itemRowStyles}
      />

      <h3 style={question}>How often do you eat greens and whole grains?</h3>
      <ItemRow
        arr={goodFoodAnswers.slice(0, 5)}
        startIndex={0}
        active={[goodFoodAnswer]}
        onClick={onClickGoodFood}
        styles={itemRowStyles}
      />

      <h3 style={question}>How often do you eat fatty and fried foods?</h3>
      <ItemRow
        arr={badFoodAnswers.slice(0, 5)}
        startIndex={0}
        active={[badFoodAnswer]}
        onClick={onClickBadFood}
        styles={itemRowStyles}
      />

      {errors.length ? (
        <span style={[GlobalStyles.regular, error]}>{errors[0]}</span>
      ) : (
        <div style={emptyBox} />
      )}

      <BottomNav
        scrollDown={scrollDown}
        scrollUp={scrollUp}
        skip={
          AppConfig.validateFeature(Features.OPTIONAL_ONBOARDING) ? skip : null
        }
      />
    </div>
  );
};

RoutineMarkup.propTypes = {
  fitnessAnswer: PropTypes.string.isRequired,
  goodFoodAnswer: PropTypes.string.isRequired,
  badFoodAnswer: PropTypes.string.isRequired,
  onClickFitness: PropTypes.func.isRequired,
  onClickGoodFood: PropTypes.func.isRequired,
  onClickBadFood: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  scrollDown: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  scrollUp: PropTypes.func.isRequired,
  goodFoodAnswerChoices: PropTypes.array,
  badFoodAnswerChoices: PropTypes.array,
};

RoutineMarkup.defaultProps = {
  goodFoodAnswer: '',
  badFoodAnswer: '',
  fitnessAnswer: '',
};

export default Radium(RoutineMarkup);
