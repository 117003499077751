import moment from 'moment';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, hashHistory, applyRouterMiddleware } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { useScroll as reactScroll } from 'react-router-scroll';
import _ from 'lodash';
import configureStore from './store/';
import { getRouteConfig } from './Routes';
import { getLeapFrogRouteConfig } from './leapfrog/Routes';
import { Features } from '@healthmine/greyhound-core/src/constants';
import 'isomorphic-fetch';

import {
  SignInActions,
  AppInfoActions,
} from '@healthmine/greyhound-core/src/actions';
import {
  AppConfig,
  promiseCatch,
  validateToken,
  GoogleTagManager,
} from '@healthmine/greyhound-core/src/modules';

require('map.prototype.tojson');
import AnalyticsEventPump from './modules/AnalyticsEventPump';

window.Promise = require('bluebird');
Promise.config({
  longStackTraces: true,
  warnings: false,
});
import { BlueButtonActions, SessionActions } from './actions';
let pageRendered = false;

AppConfig.setConfig(
  _.get(window, 'healthmine.appConfig', {}),
  sessionStorage.getItem('productGroup')
);

GoogleTagManager.setAnalyticsId(
  _.get(AppConfig, 'effectiveConfig.googleAnalyticsTrackerId', '')
);
GoogleTagManager.setRollupAnalyticsId(
  _.get(AppConfig, 'effectiveConfig.googleAnalyticsRollupTrackerId', '')
);
AnalyticsEventPump.initialize();

const store = configureStore();
const getStandardizedSignInResult = () => {
  try {
    const signInResultString = sessionStorage.getItem('signInResult');
    const signInResultJson = JSON.parse(signInResultString);
    const accessToken = _.get(signInResultJson, 'access_token');
    const jwt = _.get(signInResultJson, 'jwt');

    /*************************************************************************
     *  'access_token' comes from MemberManagement service, 'jwt' comes from
     *  MemberPortalWebHost during inbound-SSO/impersonation scenarios.
     *  Since most of the existing code assumes 'access_token', we standardize
     *  the payload to have that value, even if it duplicates 'jwt'.
     ************************************************************************/
    if (!accessToken && jwt) {
      signInResultJson.access_token = jwt;
      sessionStorage.setItem('signInResult', JSON.stringify(signInResultJson));
    }

    return signInResultJson;
  } catch (e) {
    return null;
  }
};
const storedSignInResult = getStandardizedSignInResult();
const history = syncHistoryWithStore(hashHistory, store);
const directSignIn = (signInResult) => {
  const clientMemberId = _.get(
    signInResult,
    'impersonatedMember.impersonatedClientMemberId',
    _.get(signInResult, 'clientMemberId', '')
  );
  const memberCoverageId = localStorage.getItem('selectedMemberCoverageId');

  store.dispatch(SignInActions.setSignInResult(signInResult));
  store.dispatch(SignInActions.appLoadBegin());

  return store
    .dispatch(
      SignInActions.getUserData({
        clientMemberId,
        memberCoverageId,
        impersonatedMember: signInResult.impersonatedMember,
      })
    )
    .then(() => {
      store.dispatch(SessionActions.startSession(JSON.stringify(signInResult)));
      renderPage();
    });
};
const isSessionActive = () => {
  const timeoutMinutes = _.get(
    AppConfig,
    'effectiveConfig.inactiveSessionDurationMinutes',
    15
  );
  const lastSessionActivityAt = parseInt(
    sessionStorage.getItem('lastSessionActivityAt')
  );

  return (
    timeoutMinutes === -1 ||
    !lastSessionActivityAt ||
    moment(lastSessionActivityAt).isSameOrAfter(
      moment().subtract(timeoutMinutes, 'minutes')
    )
  );
};
const removeSignInResult = () => {
  store.dispatch(SessionActions.endSession(false));

  if (!pageRendered) {
    renderPage();
  }
};
const renderPage = () => {
  const leapFrogUI = AppConfig.validateFeature(Features.LEAP_FROG_UI);
  const routeConfig = leapFrogUI ? getLeapFrogRouteConfig() : getRouteConfig();

  render(
    <Provider store={store}>
      <Router
        history={history}
        routes={routeConfig}
        render={applyRouterMiddleware(reactScroll())}
      />
    </Provider>,
    document.getElementById('app')
  );
  pageRendered = true;
};

GoogleTagManager.logLocationChange(window.location.hash.match(/#([^?]+)/)[1]);

history.listen((location) =>
  GoogleTagManager.logLocationChange(location.pathname)
);

if (AppConfig.validateFeature(Features.BLUE_BUTTON)) {
  BlueButtonActions.initializeBlueButton(window.location, store.dispatch);
}

store.dispatch(AppInfoActions.setPlatform('web'));

if (storedSignInResult) {
  if (isSessionActive() && validateToken(storedSignInResult.access_token)) {
    directSignIn(storedSignInResult).catch(promiseCatch(removeSignInResult));
  } else {
    removeSignInResult();
    renderPage();
  }
} else {
  renderPage();
}
