import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';

const SimpleCardIcon = (props) => {
  const styles = {
    icon: {
      display: 'flex',
      border: `2px solid ${props.color}`,
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      fontSize: '25px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: `${props.color}`,
      position: 'relative',
    },
    iconStyle: {
      color: Colors.white,
    },
    badge: {
      display: 'flex',
      position: 'absolute',
      top: '-4px',
      right: '-4px',
      height: '15px',
      width: '15px',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Colors.linkBlue,
    },

    badgeIcon: {
      backgroundColor: 'transparent',
      color: Colors.white,
      fontSize: '16px',
    },
  };

  return (
    <span style={styles.icon}>
      <i className={props.icon} style={styles.iconStyle} />
      {props.showBadge && (
        <div style={styles.badge}>
          <i className="material-icons" style={styles.badgeIcon}>
            done
          </i>
        </div>
      )}
    </span>
  );
};

SimpleCardIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  showBadge: PropTypes.bool,
};

export default Radium(SimpleCardIcon);
