import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class Redirect extends React.PureComponent {
  componentWillMount() {
    this.props.router.replace(this.props.to);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return null;
  }
}

Redirect.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func,
  }),
  to: PropTypes.string.isRequired,
};

export default withRouter(Redirect);
