import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { Grid } from '@material-ui/core';
import EmptyList from './EmpytList';
import { withStyles } from '@material-ui/core/styles';
import { ListContext } from './List';

const styles = () => ({
  root: {},
});

const ListContent = ({ classes, children, isEmpty, emptyMessage }) => {
  const { state } = useContext(ListContext);

  if (isEmpty) {
    return <EmptyList>{emptyMessage}</EmptyList>;
  }

  return (
    state.isOpen && (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    )
  );
};

ListContent.defaultProps = {
  emptyMessage: 'No items to show',
};

ListContent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  isEmpty: PropTypes.bool,
  emptyMessage: PropTypes.string,
};

export default compose(pure, withStyles(styles))(ListContent);
