import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose, pure, withProps } from 'recompose';
import { pick } from 'lodash/fp';
import { selectIncentiveRequirement } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import { selectWhatsNextActions } from '@healthmine/greyhound-core/src/selectors/WhatsNextSelectors';
import PageSection from '../../../common/page/PageSection';
import BiometricScreening from './BiometricScreening';
import {
  CoachingActions,
  EducationActions,
  MyDataActions,
  HraActions,
} from '@healthmine/greyhound-core/src/actions';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { LanguageConstants } from '@healthmine/greyhound-core/src/constants';
import WhatsNextDrawer from './WhatsNextDrawer';
import { withDrawer } from '../../../common/drawer2';
import WhatsNextCard from './WhatsNextCard';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { withRouter } from 'react-router';
import EducationDrawerContent from '../../health-library/EducationDrawerContent';
import HealthActionDrawerView from '../care-plan/drawer/HealthActionDrawerView';
import HraIntro from '../hra/HraIntro';
import HraQuestions from '../hra/HraQuestions';
import HraLeave from '../hra/HraLeave';
import { HraSelectors } from '@healthmine/greyhound-core/src/selectors';
import HraSummary from '../hra/HraSummary';
import EmployerIncentiveDrawer from '../incentive-requirements/IncentiveRequirementDrawer';

const WhatsNext = ({ sectionCTA, nudgeActions }) => {
  return (
    <PageSection title="What's Next" sectionCTA={sectionCTA}>
      <Grid container spacing={16}>
        {_.map(_.take(nudgeActions, 4), (item, idx) => {
          const iconClass = `icon-${item.icon}`;
          return (
            <Grid item key={`whats_next_card_${idx}`}>
              {item.type ===
              AppConstants.NudgeActionRoutes.BIOMETRIC_SCREENING ? (
                <BiometricScreening
                  iconClass={iconClass}
                  incentiveAmount={item.points}
                />
              ) : (
                <WhatsNextCard
                  title={item.title}
                  iconClass={iconClass}
                  completedText={item.completedText}
                  completedSubText={item.completedSubText}
                  goldenBackground={
                    item.type ===
                    AppConstants.NudgeActionRoutes.EMPLOYER_INCENTIVE
                  }
                  incentiveText={
                    item.pointsOverrideText
                      ? item.pointsOverrideText
                      : item.points
                        ? IncentiveSummary.getIncentiveEarningText(
                            !!_.get(item, 'healthAction.completed'),
                            item.points
                          )
                        : undefined
                  }
                  onClick={item.onClick}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </PageSection>
  );
};

WhatsNext.propTypes = {
  sectionCTA: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  nudgeActions: PropTypes.array.isRequired,
};

export default compose(
  pure,
  connect(
    (state) => {
      const incentiveRequirement = selectIncentiveRequirement(state);
      return {
        whatsNextActions: selectWhatsNextActions(state),
        isIncentiveRequirementComplete: _.get(
          incentiveRequirement,
          'isCompleted'
        ),
        deviceCredentials: state.deviceCredentials,
        hraStatus: HraSelectors.selectHraStatus(state),
        summaryViewedOn: _.get(state, 'healthRisks.viewedOn'),
      };
    },

    (dispatch) => {
      return {
        setHraLanguage: (languageCode) =>
          dispatch(HraActions.setHraLanguage(languageCode)),
        startSsoProchange: (navigation, program) =>
          dispatch(CoachingActions.startSsoProchange(navigation, program)),
        trackEducation: (contentId) =>
          dispatch(EducationActions.trackEducation(contentId)),
        deviceConnected: (payload) =>
          dispatch(MyDataActions.deviceConnected(payload)),
      };
    }
  ),
  withRouter,
  withDrawer,
  withProps(
    ({
      startSsoProchange,
      drawer,
      whatsNextActions,
      router,
      trackEducation,
      deviceCredentials,
      deviceConnected,
      hraStatus,
      setHraLanguage,
      summaryViewedOn,
      isIncentiveRequirementComplete,
    }) => {
      const nudgeActions = _.map(whatsNextActions, (item) => {
        if (
          item.type === AppConstants.NudgeActionRoutes.HRA &&
          item.healthAction &&
          item.healthAction.lastCompletedOn
        ) {
          item.completedText = 'Completed On:';
          item.completedSubText = `(${moment(
            item.healthAction.lastCompletedOn
          ).format('MM/DD/YYYY')})`;
        }

        if (item.type === AppConstants.NudgeActionRoutes.EMPLOYER_INCENTIVE) {
          item.completedSubText = IncentiveSummary.getIncentiveRequirementCardSubtext(
            isIncentiveRequirementComplete,
            item.healthAction.dueDate
          );
        }

        return {
          ...item,
          onClick: () => {
            switch (item.type) {
              case AppConstants.NudgeActionRoutes.SHOP: {
                drawer.close();
                return router.push('/sweepstakes');
              }
              case AppConstants.NudgeActionRoutes.COACHING: {
                return startSsoProchange(
                  () => window.open(item.coachingUrl),
                  item.actionId
                );
              }
              case AppConstants.NudgeActionRoutes.EMPLOYER_INCENTIVE: {
                return drawer.open(EmployerIncentiveDrawer, {
                  incentiveConfig: item.healthAction,
                });
              }
              case AppConstants.NudgeActionRoutes.EDUCATION: {
                trackEducation(item.actionId);
                return drawer.open(
                  EducationDrawerContent,
                  {
                    education: {
                      title: item.title,
                      url: item.educationUrl,
                    },
                  },
                  { large: true }
                );
              }
              case AppConstants.NudgeActionRoutes.CLINICAL: {
                return drawer.open(HealthActionDrawerView, {
                  healthAction: item.healthAction,
                });
              }
              case AppConstants.NudgeActionRoutes.HRA: {
                if (hraStatus === AppConstants.QuestionnaireStatus.STARTED) {
                  return setHraLanguage(
                    LanguageConstants.LanguageCodes.EN
                  ).then(() => {
                    drawer.open(HraQuestions, null, {
                      resetStack: true,
                      large: true,
                      disableBackdropClose: true,
                      closeConfirmation: HraLeave,
                    });
                  });
                } else if (
                  !summaryViewedOn &&
                  hraStatus === AppConstants.QuestionnaireStatus.COMPLETED
                ) {
                  return drawer.open(HraSummary);
                } else {
                  return drawer.open(HraIntro, null, { large: true });
                }
              }
              case AppConstants.NudgeActionRoutes.CONNECT_DEVICE: {
                const {
                  memberId,
                  humanApiClientId,
                  publicToken,
                } = deviceCredentials;
                const options = {
                  clientUserId: memberId,
                  clientId: humanApiClientId, // grab it from app settings page
                  publicToken, // Leave blank for new users
                  modal: 1,
                  finish(err, sessionTokenObject) {
                    const payload = pick(
                      ['humanId', 'sessionToken'],
                      sessionTokenObject
                    );
                    deviceConnected(payload);
                  },
                };

                return window.HumanConnect.open(options);
              }
            }
          },
        };
      });

      const sectionCTA =
        nudgeActions.length > 4
          ? {
              text: 'View All',
              onClick: () => drawer.open(WhatsNextDrawer, { nudgeActions }),
            }
          : undefined;

      return {
        sectionCTA,
        nudgeActions,
      };
    }
  )
)(WhatsNext);
