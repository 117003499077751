import React from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import {
  Assets,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';

const MyIDMarkup = ({ name, age, groupId, subscriberId }) => {
  const styles = {
    root: {
      padding: '0 68px 80px',
    },
    container: {
      display: 'flex',
      padding: '40px 0',
      alignItems: 'center',
    },
    marginIcon: {
      width: '40px',
      height: '40px',
      fontSize: '40px',
      padding: '18px',
      color: Colors.light,
      backgroundColor: Colors.lightGray,
      borderRadius: '50%',
      marginRight: '15px',
    },
    pageTitle: {
      color: Colors.bodyText,
      fontSize: '28px',
    },
    content: {
      maxWidth: '300px',
    },
    image: {
      display: 'block',
      maxWidth: '260px',
    },
    primary: {
      padding: '15px 0 25px',
    },
    label: {
      margin: '15px 0 10px',
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.navyBlue,
    },
    value: {
      margin: '5px 0',
      fontSize: '16px',
      color: Colors.contentText,
    },
  };

  return (
    <StyleRoot style={[styles.root, GlobalStyles.light]}>
      <div style={styles.container}>
        <div style={styles.marginIcon}>
          <i className="thin-0715_contact_archive_identity_card_photo_user_profile" />
        </div>
        <div style={styles.pageTitle}>My I.D.</div>
      </div>

      <div style={styles.content}>
        <img
          style={styles.image}
          src={Assets.getAssetURL('logo_primary.png')}
        />
        <div style={styles.primary}>
          <h5 style={styles.label}>Name:</h5>
          <p style={styles.value}>{name}</p>
          <h5 style={styles.label}>Age:</h5>
          <p style={styles.value}>{age}</p>
          {subscriberId ? <h5 style={styles.label}>ID #:</h5> : null}
          {subscriberId ? <p style={styles.value}>{subscriberId}</p> : null}
          {groupId ? <h5 style={styles.label}>Group #:</h5> : null}
          {groupId ? <p style={styles.value}>{groupId}</p> : null}
        </div>
      </div>
    </StyleRoot>
  );
};

MyIDMarkup.propTypes = {
  name: PropTypes.string,
  age: PropTypes.number,
  groupId: PropTypes.string,
  subscriberId: PropTypes.string,
};

export default Radium(MyIDMarkup);
