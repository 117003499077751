import React from 'react';
import { withStyles, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Questionnaire } from '../common/questionnaire';
import { QuestionnaireActions } from '@healthmine/greyhound-core/src/actions';
import { bindActionCreators } from 'redux';
import fp from 'lodash/fp';

const styles = () => ({
  modalRoot: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
const FteFollowupModal = ({
  classes,
  fteFollowup,
  dependencyAnswers,
  fteFollowupAnswers,
  actions,
  firstName,
  onClose,
  completeFteFollowup,
  showModal,
  sectionImages,
}) => (
  <Modal
    open={showModal}
    onClose={onClose}
    disableBackdropClick
    className={classes.modalRoot}
  >
    <div className={classes.root}>
      <Questionnaire
        questionnaire={fteFollowup}
        dependencyAnswers={dependencyAnswers}
        savedAnswers={fteFollowupAnswers || {}}
        submitAnswer={actions.postQuestionnaireAnswers}
        completeQuestionnaire={completeFteFollowup}
        isSkippable={false}
        onQuestionnaireExit={onClose}
        isExitable
        showProgressBar
        sectionImages={sectionImages}
        questionnaireText={{
          welcome: {
            title: 'Your Feedback Matters',
            description:
              // eslint-disable-next-line
              "This isn't just another survey to complete. We're invested in your health, and committed to building you the best experience. By answering just a few more questions, we can help make sure your personalized health plan meets all of your needs and goals.",
            cta: 'Finish Now!',
          },
          exit: {
            title: 'Leave Now?',
            description:
              // eslint-disable-next-line
              'By pressing "Confirm", you are leaving the health survey. If you are sure this is what you want to do, then press "Confirm" and your progress will be saved so you can finish later. Otherwise you can select "Nevermind" in the bottom right corner.',
            backCta: 'Confirm',
            forwardCta: 'Nevermind',
          },
          done: {
            incomplete: {
              title: 'So close!',
              description:
                // eslint-disable-next-line
                'Looks like you skipped a question or two along the way. In order to receive your $15 reward you must answer every question. You can go back now and answer any questions by pressing "Back" or press "Done" to submit an incomplete survey and lose the opportunity to earn $15.',
            },
            complete: {
              title: `Great job ${firstName}!`,
              description:
                // eslint-disable-next-line
                'Thank you for taking the time to complete this important survey. From time to time, we may ask you some more questions as your health evolves. For now, keep up the great work!',
            },
          },
        }}
      />
    </div>
  </Modal>
);

FteFollowupModal.propTypes = {
  classes: PropTypes.object.isRequired,
  fteFollowup: PropTypes.object.isRequired,
  dependencyAnswers: PropTypes.object.isRequired,
  fteFollowupAnswers: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  firstName: PropTypes.string,
  completeFteFollowup: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  sectionImages: PropTypes.object.isRequired,
};

export default compose(
  connect(
    (state) => ({
      firstName: _.get(state, 'userInfo.firstName'),
      fteFollowup: _.get(state, 'questionnaires.FTE_SURVEY', {}),
      fteFollowupAnswers: _.get(
        state,
        'questionnaires.FTE_SURVEY.memberQuestionnaire.answers',
        {}
      ),
      fteAnswers: _.get(
        state,
        'questionnaires.FTE.memberQuestionnaire.answers',
        {}
      ),
      memberQuestionnaireId: _.get(
        state,
        'questionnaires.FTE_SURVEY.memberQuestionnaire.id'
      ),
    }),
    (dispatch) => {
      const {
        postQuestionnaireAnswers,
        completeQuestionnaire,
      } = QuestionnaireActions;
      return {
        actions: bindActionCreators(
          {
            postQuestionnaireAnswers,
            completeQuestionnaire,
          },
          dispatch
        ),
      };
    }
  ),
  withProps(
    ({
      fteFollowupAnswers,
      fteAnswers,
      onClose,
      actions,
      memberQuestionnaireId,
      fteFollowup,
    }) => {
      const dependencyAnswers = _.assign(
        _.clone(fteFollowupAnswers),
        fteAnswers
      );
      const showModal = fp.flow(
        fp.get('questionnaire.questions'),
        fp.any(
          (question) =>
            fp.flow(
              fp.get('questionDependency'),
              fp.some((dependency) =>
                fp.flow(
                  fp.get(dependency.dependsOnQuestionId),
                  fp.some(
                    (answer) =>
                      _.get(answer, 'answer') ===
                      _.get(dependency, 'dependsOnQaOption')
                  )
                )(dependencyAnswers)
              )
            )(question) || !_.has(question, 'questionDependency')
        )
      )(fteFollowup);

      return {
        dependencyAnswers,
        showModal,
        sectionImages: {
          avatar: 'new-fte/avatar.png',
        },
        completeFteFollowup: () => {
          const { completeQuestionnaire } = actions;
          completeQuestionnaire(memberQuestionnaireId, 'FTE_SURVEY').then(
            () => {
              onClose(true);
            }
          );
        },
      };
    }
  ),
  withStyles(styles)
)(FteFollowupModal);
