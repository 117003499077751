import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules/';

const BottomLinks = ({ onClick, link, text, disabled, hideIcon }) => {
  const RadiumLink = Radium(Link);

  const styles = {
    linkbtn: {
      color: Colors.black,
      textDecoration: 'none',
      display: 'flex',
      fontSize: '16px',
      marginTop: '-2px',
      cursor: 'pointer',
    },
    icon: {
      fontSize: '20px',
      marginLeft: '6px',
      marginTop: '-1px',
    },
    btn: {
      background: 'none',
      border: 'none',
      outline: 'none',
      marginLeft: '105px',
    },
    marginTop: {
      color: Colors.contentText,
      marginTop: '1px',
    },
    disableBtn: {
      display: 'flex',
      fontSize: '16px',
      marginTop: '-2px',
      color: Colors.hexToRgba(Colors.dark, 0.5),
      cursor: 'not-allowed',
      ':hover': {
        color: Colors.hexToRgba(Colors.dark, 0.5),
      },
      ':active': {
        color: Colors.hexToRgba(Colors.dark, 0.5),
      },
    },
  };

  if (link) {
    return (
      <RadiumLink
        style={[GlobalStyles.regular, styles.linkbtn, styles.marginTop]}
        to={link}
        onClick={onClick}
      >
        {text}
      </RadiumLink>
    );
  } else {
    return (
      <button
        type="submit"
        style={
          disabled === true || disabled === undefined
            ? [GlobalStyles.regular, styles.disableBtn, styles.btn]
            : [GlobalStyles.regular, styles.linkbtn, styles.btn]
        }
        onClick={onClick}
        disabled={disabled === true || disabled === undefined}
      >
        {text}
        {!hideIcon && (
          <span style={styles.icon}>
            <i className="thin-0160_arrow_next_right" />
          </span>
        )}
      </button>
    );
  }
};

BottomLinks.propTypes = {
  onClick: PropTypes.func,
  link: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  hideIcon: PropTypes.bool,
};

BottomLinks.defaultProps = {
  text: 'Next',
  hideIcon: false,
};

export default Radium(BottomLinks);
