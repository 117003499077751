import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import Scroll from 'react-scroll';
import Styles from './Header.styles';

const HeaderMarkup = ({ firstName, scrollDown }) => (
  <Scroll.Element name="dashboardSection">
    <div style={Styles.mainContainer}>
      <span style={Styles.icon} onClick={scrollDown}>
        <i className="thin-1033_atom_nuclear_learning_physics" />
      </span>
      <p style={Styles.welcomeText}>
        Welcome {firstName}, let's start by getting to know you.
      </p>
    </div>
  </Scroll.Element>
);

HeaderMarkup.propTypes = {
  firstName: PropTypes.string.isRequired,
  scrollDown: PropTypes.func.isRequired,
};

export default Radium(HeaderMarkup);
