import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { withOptOut } from '@healthmine/greyhound-core/src/hocs';
import { Colors, AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { compose, withState } from 'recompose';
import Button from '../common/Button';
import Modal from '../common/Modal';

/* eslint-disable react/no-danger */

const styles = {
  optOutNotice: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  title: {
    size: '',
  },
  question: {},
  text: {
    size: '14px',
  },
  button: {
    minWidth: '200px',
    marginBottom: 0,
    padding: '8px 20px',
    backgroundColor: Colors.newRed,
    ':hover': {
      backgroundColor: Colors.hexToRgba(Colors.newRed, 0.85),
    },
  },
  modalButton: {
    marginBottom: 0,
    padding: '8px 20px',
    backgroundColor: Colors.newRed,
    marginRight: '10px',
    ':hover': {
      backgroundColor: Colors.hexToRgba(Colors.newRed, 0.85),
    },
  },
  cancelButton: {
    marginBottom: 0,
    padding: '8px 20px',
  },
};

const OptOut = ({
  isOptOutActive,
  optOut,
  showModal = false,
  setShowModal,
}) => {
  if (!AppConfig.validateFeature(Features.OPT_OUT)) {
    return null;
  }

  const optOutNotice = AppConfig.getLingo('optOutNotice');

  const optOutConfirm = AppConfig.getLingo('optOutConfirm');

  return (
    <div>
      {isOptOutActive ? (
        <div
          style={styles.optOutNotice}
          dangerouslySetInnerHTML={{ __html: optOutNotice }}
        />
      ) : (
        <div>
          <Button
            text="Opt Out of Program"
            style={styles.button}
            onClick={() => setShowModal(true)}
          />
        </div>
      )}
      {showModal && (
        <Modal
          onCancel={() => setShowModal(false)}
          title={'Opt Out of Program'}
        >
          <div style={{ fontSize: '16px', marginTop: '20px' }}>
            <div dangerouslySetInnerHTML={{ __html: optOutConfirm }} />
            <div style={{ marginTop: '20px' }}>
              <Button
                style={styles.modalButton}
                onClick={() => {
                  optOut();
                  setShowModal(false);
                }}
                text={'Opt Out of Program'}
              />
              <Button
                style={styles.cancelButton}
                onClick={() => setShowModal(false)}
                text={'Cancel'}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

OptOut.propTypes = {
  isOptOutActive: PropTypes.bool.isRequired,
  optOut: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default compose(
  withOptOut,
  withState('showModal', 'setShowModal', false),
  Radium
)(OptOut);
