import React from 'react';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  DrawerSectionHeader,
  DrawerSection,
} from '../../../common/drawer2';
import ReactMarkdown from 'react-markdown';
import Typography from '../../../common/Typography';
import { Grid } from '@material-ui/core';

const HraHealthRisk = ({ healthRisk }) => {
  return (
    <DrawerContent title={healthRisk.name}>
      <DrawerSection>
        <Grid container justify="center" alignItems="center" spacing={8}>
          <Grid item>
            <Typography type="h1" color={healthRisk.color}>
              <i className={healthRisk.icon} />
            </Typography>
          </Grid>
          <Grid item>
            <Typography type="h3" color={healthRisk.color}>
              {healthRisk.level}
            </Typography>
          </Grid>
        </Grid>
      </DrawerSection>
      {healthRisk.description && (
        <div>
          <DrawerSectionHeader title="Description" />
          <DrawerSection>
            <Typography type="body">
              <ReactMarkdown source={healthRisk.description} />
            </Typography>
          </DrawerSection>
        </div>
      )}
      {healthRisk.instructions && (
        <div>
          <DrawerSectionHeader title="Next Steps" />
          <DrawerSection>
            <Typography type="body">
              <ReactMarkdown source={healthRisk.instructions} />
            </Typography>
          </DrawerSection>
        </div>
      )}
    </DrawerContent>
  );
};

HraHealthRisk.propTypes = {
  healthRisk: PropTypes.object.isRequired,
};

export default HraHealthRisk;
