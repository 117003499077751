import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  root: {
    position: 'relative',
  },

  lineContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '22px',
    margin: '10px 10px 0',
  },

  line: {
    flex: 1,
    backgroundColor: '#99A7B5',
    height: '1px',
  },

  bubbleContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'space-between',
  },

  bubble: {
    height: '22px',
    width: '22px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#99A7B5',
    backgroundColor: Colors.white,
  },

  selectedBubble: {
    backgroundColor: '#224D68',
    borderColor: '#224D68',
  },

  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '5px',
  },

  label: {
    color: '#99A7B5',
    fontSize: '16px',
    width: 'calc((100% - 60px) / 3)',
    wordSpacing: 'calc(100vw)',
  },
};
