import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import EventDetailMarkup from '../../components/biometric-screening/EventDetailMarkup';
import ExplanationMarkup from '../../components/biometric-screening/ExplanationMarkup';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import * as BiometricScreeningSelectors from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';
import { selectCurrentCoverage } from '@healthmine/greyhound-core/src/selectors/CoverageSelector';
import LeavingOurSiteModal from '../../components/common/LeavingOurSiteModal';

const DEFAULT_PAGE_SIZE = 3;

class EventDetail extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      pageNumber: 1,
      activeId: null,
      activeLat: null,
      activeLng: null,
      showWelcomeBackModal: false,
      showThirdPartyCookieWarning: false,
      showLeavingSiteModal: false,
    };
  }

  render() {
    const {
      pageNumber,
      activeId,
      activeLat,
      activeLng,
      showWelcomeBackModal,
      showThirdPartyCookieWarning,
      showLeavingSiteModal,
    } = this.state;

    const { events, coverageName } = this.props;

    const showNext = events.length > DEFAULT_PAGE_SIZE * this.state.pageNumber;

    const showPrev = this.state.pageNumber > 1;

    return (
      <div>
        <ExplanationMarkup onOpenEHS={this._onManualSubmissionFormEHS} />

        <EventDetailMarkup
          pageNumber={pageNumber}
          pageSize={DEFAULT_PAGE_SIZE}
          events={events}
          showNext={showNext}
          showPrev={showPrev}
          activeId={activeId}
          activeLat={activeLat}
          activeLng={activeLng}
          onHover={this._onHover}
          onLeave={this._onLeave}
          onNext={this._nextPage}
          onPrev={this._prevPage}
          onOpenEHS={this._onScheduleAppointmentEHS}
          showWelcomeBackModal={showWelcomeBackModal}
          showLeavingSiteModal={showLeavingSiteModal}
          onWelcomeBackModalClosed={this._onWelcomeBackModalClosed}
          showThirdPartyCookieWarning={showThirdPartyCookieWarning}
          onThirdPartyCookieWarningClosed={
            this._onThirdPartyCookieWarningClosed
          }
          coverageName={coverageName}
        />
        {showLeavingSiteModal ? (
          <LeavingOurSiteModal
            onOK={this._linkToEhs}
            onCancel={this._stayOnPage}
            customStatement={this.state.leavingSiteModalMessage}
          />
        ) : null}
      </div>
    );
  }

  @autobind
  _onHover(id, lat, lng) {
    this.setState({
      activeId: id,
      activeLat: lat,
      activeLng: lng,
    });
  }

  @autobind
  _onLeave() {
    this.setState({
      activeId: null,
      activeLat: null,
      activeLng: null,
    });
  }

  @autobind
  _nextPage() {
    if (this.props.events.length <= DEFAULT_PAGE_SIZE * this.state.pageNumber)
      return;

    const pageNumber = this.state.pageNumber + 1;

    this.setState({
      pageNumber,
    });
  }

  @autobind
  _prevPage() {
    if (this.state.pageNumber <= 1) return;

    const pageNumber = this.state.pageNumber - 1;

    this.setState({
      pageNumber,
    });
  }

  _onManualSubmissionFormEHS = (evt) => {
    if (!this._thirdPartyCookie(evt)) {
      this.setState({
        showLeavingSiteModal: true,
        leavingSiteModalMessage: '',
      });
    }
  };

  _onScheduleAppointmentEHS = (evt) => {
    if (!this._thirdPartyCookie(evt)) {
      this.setState({
        showLeavingSiteModal: true,
        leavingSiteModalMessage: `You're just a few clicks away from scheduling your biometric screening for ${
          this.props.coverageName
        }!`,
      });
    }
  };

  _thirdPartyCookie = (evt) => {
    const { thirdPartyCookiesUnsupported } = this.props;

    evt.preventDefault();

    if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)) {
      this.setState({
        showWelcomeBackModal: true,
      });
      return true;
    } else if (thirdPartyCookiesUnsupported) {
      this.setState({
        showThirdPartyCookieWarning: true,
      });
      return true;
    }
    return false;
  };

  _linkToEhs = () => {
    const { ssoUrl } = this.props;

    window.open(ssoUrl);

    this.setState({
      showWelcomeBackModal: true,
      showLeavingSiteModal: false,
    });
  };

  _stayOnPage = () => {
    this.setState({ showLeavingSiteModal: false });
  };

  _onThirdPartyCookieWarningClosed = () => {
    this.setState({
      showThirdPartyCookieWarning: false,
    });
  };

  _onWelcomeBackModalClosed = () => {
    this.setState({
      showWelcomeBackModal: false,
    });
  };
}

EventDetail.propTypes = {
  ssoUrl: PropTypes.string.isRequired,
  thirdPartyCookiesUnsupported: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  coverageName: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const ssoUrl = BiometricScreeningSelectors.selectSsoUrl(state);

  const coverage = selectCurrentCoverage(state);

  const { detected, supported } = state.thirdPartyCookieSupport;

  return {
    ssoUrl,
    events: state.biometricScreening.events,
    thirdPartyCookiesUnsupported: !!(detected && !supported),
    coverageName: coverage.coverageName,
  };
}

export default connect(mapStateToProps)(EventDetail);
