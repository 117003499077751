import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import _ from 'lodash';
import { withSweepstakes } from '@healthmine/greyhound-core/src/hocs';
import PageSection from '../../common/page/PageSection';
import GridWrapper from '../../common/GridWrapper';
import SweepstakeCard from './SweepstakeCard';
import GiftCardCard from './GiftCardCard';
import GiftCardDrawer from './GiftCardDrawer';
import List, { ListHeader } from '../../common/list/List';
import { withDrawer } from '../../common/drawer2';
import {
  AppConstants,
  RewardsConstants,
} from '@healthmine/greyhound-core/src/constants';
import { connect } from 'react-redux';
import { rewardsSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import RewardsProductDrawer from './ShoppingCart/RewardProductDrawer';

const IncentivesList = ({
  openSweepstakes,
  onSweepstakesCardClick,
  onRewardsCardClick,
  incentiveType,
  rewards,
}) => {
  return (
    <PageSection
      title={
        incentiveType === AppConstants.IncentiveType.SWEEPSTAKES
          ? 'SWEEPSTAKES'
          : 'GIFT CARDS'
      }
    >
      {incentiveType === AppConstants.IncentiveType.SWEEPSTAKES ? (
        openSweepstakes.length > 0 ? (
          <GridWrapper>
            {_.map(openSweepstakes, (item, idx) => {
              return (
                <SweepstakeCard
                  key={`sweepstake_item_${idx}`}
                  title={item.sweepstakeName}
                  imageUrl={item.imageUrl}
                  entries={item.memberEntries}
                  closeDate={item.closeDate}
                  onClick={onSweepstakesCardClick}
                  sweepstakeId={item.sweepstakeId}
                />
              );
            })}
          </GridWrapper>
        ) : (
          <List>
            <ListHeader
              iconClass="icon-Star-Circle"
              title="No open sweepstakes"
              noBubbleIcon
              isMute
            />
          </List>
        )
      ) : rewards.length > 0 ? (
        <GridWrapper>
          {_.map(rewards, (item, idx) => {
            const denominations = item.denominations;
            const priceRange =
              denominations && denominations.length > 1
                ? `${RewardsConstants.setCurrencyLabel(
                    denominations[0]
                  )} - ${RewardsConstants.setCurrencyLabel(
                    denominations[denominations.length - 1]
                  )}`
                : `${RewardsConstants.setCurrencyLabel(denominations[0])}`;

            return (
              <GiftCardCard
                key={`rewards_giftcard_${idx}`}
                imageUrl={item.imageUrl}
                title={item.name}
                isDigital={item.isDigital}
                isPhysical={item.isPhysical}
                productId={item.productId}
                onClick={onRewardsCardClick}
                priceRange={priceRange}
              />
            );
          })}
        </GridWrapper>
      ) : (
        <List>
          <ListHeader
            iconClass="icon-Star-Circle"
            title="No available giftcards"
            noBubbleIcon
            isMute
          />
        </List>
      )}
    </PageSection>
  );
};

IncentivesList.propTypes = {
  openSweepstakes: PropTypes.array.isRequired,
  onSweepstakesCardClick: PropTypes.func.isRequired,
  onRewardsCardClick: PropTypes.func.isRequired,
  incentiveType: PropTypes.string.isRequired,
  rewards: PropTypes.array,
};

export default compose(
  pure,
  withSweepstakes,
  withDrawer,
  connect((state) => ({
    rewards: rewardsSelector(state),
  })),
  withProps(({ drawer }) => {
    const onSweepstakesCardClick = (sweepstakeId) => {
      drawer.open(GiftCardDrawer, { sweepstakeId });
    };
    const onRewardsCardClick = (productId) => {
      drawer.open(
        RewardsProductDrawer,
        { productId, toggleProduct: () => drawer.close() },
        { large: true }
      );
    };
    return {
      onSweepstakesCardClick,
      onRewardsCardClick,
    };
  })
)(IncentivesList);
