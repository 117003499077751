import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  title: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '20px',
    paddingLeft: '50px',
    paddingBottom: '20px',
    fontSize: '20px',
    color: Colors.navyBlue,
    backgroundColor: '#f3f6f8',
  },

  programLabel: {
    display: 'flex',
    alignItems: 'center',
  },

  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
  },

  button: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'rgb(14, 150, 219)',
  },

  message: {
    fontSize: '16px',
    color: Colors.bodyText,
    textAlign: 'center',
    marginTop: '30px',
  },
};
