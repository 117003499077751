import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { pick, get } from 'lodash/fp';
import { UserInfoActions } from '@healthmine/greyhound-core/src/actions';
import {
  DrawerButton,
  DrawerContent,
  DrawerInput,
  DrawerSection,
} from './drawer2';
import { withForm } from './form';
import { validatePhone, validatePresence } from '../../validations';

const EditProfileDrawerContent = ({
  form: { fields, isValid, submit, updateField },
}) => (
  <DrawerContent title="Profile">
    <DrawerInput
      {...fields.mobileNumber}
      onChange={(e) => updateField('mobileNumber', e.target.value)}
    />
    <DrawerSection grow />
    <DrawerButton onClick={submit} disabled={!isValid} text="Save changes" />
  </DrawerContent>
);

EditProfileDrawerContent.propTypes = {
  form: PropTypes.shape({
    isValid: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    updateField: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default compose(
  pure,
  connect(pick(['userInfo']), (dispatch) => ({
    actions: bindActionCreators(
      pick(['updateCellPhoneNumber'], UserInfoActions),
      dispatch
    ),
  })),
  withForm(
    ({ userInfo }) => ({
      mobileNumber: {
        label: 'Mobile Number',
        value: userInfo.mobileNumber,
        type: 'text',
        validations: [validatePresence, validatePhone],
      },
    }),
    ({ form, actions, onSubmit }) => {
      const mobileNumber = get('fields.mobileNumber.value', form);
      actions.updateCellPhoneNumber(mobileNumber);
      onSubmit();
    }
  )
)(EditProfileDrawerContent);
