import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  AppConfig,
  Colors,
  GlobalStyles,
} from '@healthmine/greyhound-core/src/modules';
import {
  FteConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import ItemRow from '../common/ItemRow';
import BottomNav from '../../components/onboarding/BottomNav';
import _ from 'lodash';

const HealthGoalMarkup = ({
  healthGoals,
  healthGoalsAnswerChoices,
  onClick,
  errors,
  scrollDown,
  scrollUp,
  onFinishClick,
  skip,
}) => {
  const question = {
    fontSize: '16px',
    fontWeight: 400,
    margin: '50px 0 40px',
  };

  const note = {
    color: Colors.contentText,
    marginLeft: '10px',
  };

  const error = {
    color: Colors.red,
    lineHeight: '25px',
  };

  const emptyBox = {
    paddingBottom: '25px',
  };

  const itemRowStyles = {
    icon: {
      padding: '18px',
      border: `2px solid ${Colors.mediumGray3}`,
      backgroundColor: Colors.light,
    },
    label: {
      fontSize: '16px',
    },
    wideLabel: {
      width: '120px',
    },
  };

  const { GoalResources } = FteConstants;

  const healthGoalsAnswer = healthGoalsAnswerChoices
    ? healthGoalsAnswerChoices
        .filter((answer) => _.has(GoalResources, answer.id))
        .map((answer) => ({
          ...GoalResources[answer.id],
          questionAnswerOptionId: answer.id,
        }))
    : [];

  const midPointIndex = Math.ceil(healthGoalsAnswer.length / 2);

  return (
    <div>
      <h2 style={question}>
        Your Aspirations
        <span style={note}>(Choose up to 3)</span>
      </h2>

      <ItemRow
        arr={healthGoalsAnswer.slice(0, midPointIndex)}
        startIndex={0}
        active={healthGoals}
        onClick={onClick}
        wide
        styles={itemRowStyles}
      />

      <ItemRow
        arr={healthGoalsAnswer.slice(midPointIndex)}
        startIndex={midPointIndex}
        active={healthGoals}
        onClick={onClick}
        wide
        styles={itemRowStyles}
      />

      {errors.length ? (
        <span style={[GlobalStyles.regular, error]}>{errors[0]}</span>
      ) : (
        <div style={emptyBox} />
      )}

      {AppConfig.validateFeature(Features.NO_CONNECTED_DEVICES) ? (
        <BottomNav
          nextText="Finish!"
          scrollDown={onFinishClick}
          scrollUp={scrollUp}
          skip={
            AppConfig.validateFeature(Features.OPTIONAL_ONBOARDING)
              ? skip
              : null
          }
        />
      ) : (
        <BottomNav scrollDown={scrollDown} scrollUp={scrollUp} />
      )}
    </div>
  );
};

HealthGoalMarkup.propTypes = {
  healthGoals: PropTypes.array.isRequired,
  healthGoalsAnswerChoices: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  scrollUp: PropTypes.func.isRequired,
  scrollDown: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  onFinishClick: PropTypes.func.isRequired,
};

export default Radium(HealthGoalMarkup);
