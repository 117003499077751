import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import * as HraSelectors from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import HealthRiskAssessmentResultMarkup from './HealthRiskAssessmentResultMarkup';

class HealthRiskAssessmentResult extends React.Component {
  static propTypes = {
    assessments: PropTypes.arrayOf(PropTypes.object),
    selectedAssessmentIndex: PropTypes.number.isRequired,
    onAssessmentChanged: PropTypes.func.isRequired,
    setSelectedAssessmentIndex: PropTypes.func.isRequired,
  };

  render() {
    const {
      assessments,
      selectedAssessmentIndex,
      onAssessmentChanged,
    } = this.props;

    const questionAnswers = _.get(
      assessments,
      `[${selectedAssessmentIndex}].answeredQuestions`
    );

    const assessmentOptions = _.map(
      assessments,
      (assessment, assessmentIndex) => ({
        label: moment(assessment.endTimestamp).format('MMMM D, Y'),
        value: assessmentIndex,
      })
    );

    return (
      <HealthRiskAssessmentResultMarkup
        assessments={assessmentOptions}
        selectedAssessmentIndex={selectedAssessmentIndex}
        onAssessmentChanged={onAssessmentChanged}
        questionAnswers={questionAnswers}
      />
    );
  }
}

export default compose(
  withStateHandlers(
    {
      selectedAssessmentIndex: 0,
    },

    {
      setSelectedAssessmentIndex: (state) => (selectedAssessmentIndex) => ({
        ...state,
        selectedAssessmentIndex,
      }),
    }
  ),

  connect((state) => {
    const assessments = HraSelectors.selectCompletedMemberQuestionnaireAnswers(
      state
    );

    return {
      assessments,
    };
  }),

  withHandlers({
    onAssessmentChanged: ({ setSelectedAssessmentIndex }) => (
      selectedAssessmentIndex
    ) => setSelectedAssessmentIndex(selectedAssessmentIndex),
  })
)(HealthRiskAssessmentResult);
