import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors } from '@healthmine/greyhound-core/src/modules';
import striptags from 'striptags';
const EducationGroupMarkup = ({
  title,
  items,
  expanded,
  setFrameLink,
  toggleExpansion,
}) => {
  const styles = {
    contentType: {
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.bodyText,
    },
    contentItem: {
      padding: '0 0 15px',
      cursor: 'pointer',
    },
    contentDescription: {
      color: Colors.contentText,
      margin: '5px 0',
    },
    link: {
      color: Colors.black,
      textDecoration: 'none',
    },
    expandLink: {
      fontSize: '16px',
      color: Colors.highlightBlue,
      cursor: 'pointer',
      marginTop: 0,
      marginBottom: '30px',
    },
  };

  const limit = expanded === title ? items.length : 2;

  return (
    <div>
      <p style={styles.contentType}>{title}</p>
      {items.slice(0, limit).map((item, index) => (
        <div
          key={index}
          style={styles.contentItem}
          onClick={() => setFrameLink(item)}
        >
          <span style={styles.link}>{item.title}</span>
          <p style={styles.contentDescription}>{striptags(item.preview)}</p>
        </div>
      ))}
      {items.length > 2 && (
        <p style={styles.expandLink} onClick={() => toggleExpansion(title)}>
          {expanded == title ? `< View less ${title}` : `View all ${title} >`}
        </p>
      )}
    </div>
  );
};

EducationGroupMarkup.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  expanded: PropTypes.string,
  setFrameLink: PropTypes.func,
  toggleExpansion: PropTypes.func,
};

export default Radium(EducationGroupMarkup);
