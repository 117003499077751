import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  compose,
  pure,
  withProps,
  withStateHandlers,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { selectAllIncentiveTransactionSummary } from '@healthmine/greyhound-core/src/selectors/IncentiveSelector';
import { selectAvailableCompliancePeriods } from '@healthmine/greyhound-core/src/selectors/CoverageSelector';
import { sweepStakeHealthActionGroupOverride } from '@healthmine/greyhound-core/src/constants/Sweepstakes';
import PointSummaryItem from './PointSummaryItem';
import {
  DrawerContent,
  DrawerSelect,
  DrawerSection,
  withDrawer,
} from '../../../common/drawer2';
import moment from 'moment';
import Typography from '../../../common/Typography';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router';

const PointSummary = ({
  summary,
  selectedStrategyId,
  selectStrategyId,
  strategyOptions,
  isSelectedStrategyCurrentPlanYear,
  selectGroup,
  excludePlanYearDropdown,
}) => {
  return (
    <DrawerContent title="Points Summary">
      {excludePlanYearDropdown ? (
        ''
      ) : (
        <DrawerSelect
          value={selectedStrategyId}
          options={strategyOptions}
          onChange={selectStrategyId}
          subLabel="plan year"
        />
      )}

      {summary.length > 0 ? (
        _.map(summary, (item, idx) => {
          return (
            <PointSummaryItem
              key={`point_summary_item_${idx}`}
              title={
                (item.isGroup
                  ? item.description.split(':')[0]
                  : item.description) || ''
              }
              lastCompleted={
                item.mostRecentCompletionDate ||
                item.lastCompletedDate ||
                item.lastIssuedDate
              }
              amount={item.amount}
              endDate={item.endDate}
              isGroup={!!item.isGroup}
              selectGroup={selectGroup}
              dataType={item.dataType}
              strategyId={item.strategyId}
            />
          );
        })
      ) : (
        <DrawerSection>
          <Grid container justify="center">
            <Grid item>
              {isSelectedStrategyCurrentPlanYear ? (
                <Typography secondary>
                  You haven't earned any points. Go to the{' '}
                  <Link to="/health-overview">
                    <Typography type="link">Health Overview</Typography>
                  </Link>{' '}
                  to start earning!
                </Typography>
              ) : (
                <Typography secondary>
                  You don't have any points for this plan year.
                </Typography>
              )}
            </Grid>
          </Grid>
        </DrawerSection>
      )}
    </DrawerContent>
  );
};

PointSummary.propTypes = {
  summary: PropTypes.array,
  strategyOptions: PropTypes.array,
  selectedStrategyId: PropTypes.number,
  selectStrategyId: PropTypes.func,
  isSelectedStrategyCurrentPlanYear: PropTypes.bool,
  selectGroup: PropTypes.func,
  excludePlanYearDropdown: PropTypes.bool,
};

export default compose(
  pure,
  withDrawer,
  connect((state) => {
    const rewardsSummary = selectAllIncentiveTransactionSummary(state);
    const compliancePeriods = selectAvailableCompliancePeriods(state);

    return {
      rewardsSummary,
      compliancePeriods,
    };
  }),
  withStateHandlers(
    ({ compliancePeriods }) => ({
      selectedStrategyId: _.get(
        compliancePeriods,
        '[0].employerIncentiveStrategyId'
      ),
    }),
    {
      selectStrategyId: () => (strategyId) => ({
        selectedStrategyId: strategyId,
      }),
    }
  ),
  withProps(({ rewardsSummary, compliancePeriods, selectedStrategyId }) => {
    const filterStrategyIdFunc = (transaction) =>
      transaction.strategyId === selectedStrategyId;
    const summary = _.chain(rewardsSummary)
      .filter(filterStrategyIdFunc)
      .map((t) => {
        if (t.isGroup)
          t.allTransactions = _.filter(t.allTransactions, filterStrategyIdFunc);
        return t;
      })
      .value();

    const selectedStrategyCompliancePeriod = _.find(compliancePeriods, {
      employerIncentiveStrategyId: selectedStrategyId,
    });
    const isSelectedStrategyCurrentPlanYear = moment().isBetween(
      _.get(selectedStrategyCompliancePeriod, 'startDate'),
      _.get(selectedStrategyCompliancePeriod, 'endDate'),
      'day',
      '[]'
    );

    const strategyOptions = _.map(compliancePeriods, (item) => {
      const id = item.employerIncentiveStrategyId;
      const startDate = moment(item.startDate).format('MM/DD/YYYY');
      const endDate = moment(item.endDate).format('MM/DD/YYYY');
      return {
        value: id,
        label: `${startDate} - ${endDate}`,
      };
    });

    return {
      summary,
      strategyOptions,
      isSelectedStrategyCurrentPlanYear,
    };
  }),
  withHandlers({
    selectGroup: (props) => (dataType, strategyId, groupTitle) => {
      const { rewardsSummary, drawer } = props;

      if (
        sweepStakeHealthActionGroupOverride
          .map((h) => h.title)
          .includes(groupTitle)
      ) {
        const DESummary = _.chain(rewardsSummary)
          .find(
            (transaction) =>
              transaction.description === groupTitle &&
              transaction.strategyId === strategyId
          )
          .get('allTransactions')
          .value();

        return drawer.open(
          PointSummary,
          {
            ...props,
            title: groupTitle,
            summary: DESummary,
            excludePlanYearDropdown: true,
          },
          { large: true }
        );
      }

      const title =
        dataType.split(':').length > 1
          ? `${_.chain(dataType.split(':')[1])
              .lowerCase()
              .startCase()
              .value()} Tracking Summary`
          : `${_.capitalize(dataType)} Tracking Summary`;
      const summary = _.chain(rewardsSummary)
        .find(
          (transaction) =>
            transaction.dataType === dataType &&
            transaction.strategyId === strategyId
        )
        .get('allTransactions')
        .value();

      drawer.open(
        PointSummary,
        {
          ...props,
          title,
          summary,
          excludePlanYearDropdown: true,
        },
        { large: true }
      );
    },
  })
)(PointSummary);
