import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { GlobalStyles, Colors } from '@healthmine/greyhound-core/src/modules';
import Page from './Page';
import { Link } from 'react-router';

const PageWithSubNav = ({
  children,
  route,
  location,
  title,
  icon,
  iconStyle,
  lineColor,
  lineWidth,
  activeColor,
  uhcIcon,
}) => {
  const RadiumLink = Radium(Link);

  const styles = {
    subNavContainer: {
      alignSelf: 'flex-start',
      display: 'flex',
      listStyleType: 'none',
      paddingLeft: '75px',
      borderBottom: `${lineWidth}px solid ${lineColor}`,
      margin: '0px',
    },
    navItem: {
      ...GlobalStyles.light,
      fontSize: '16px',
      padding: '5px 20px 5px 10px',
      margin: `0px 0px ${lineWidth * -1}px 0px`,
      borderBottom: `${lineWidth}px solid ${lineColor}`,
    },
    activeItem: {
      borderBottom: `${lineWidth}px solid ${activeColor}`,
    },
    link: {
      color: Colors.bodyText,
      cursor: 'pointer',
      textDecoration: 'none',
    },
  };

  const isRouteActive = (childRoute) =>
    location.pathname.endsWith(route.path)
      ? childRoute.default
      : location.pathname.includes(childRoute.path);

  return children.props.route.containerless ? (
    children
  ) : (
    <Page title={title} icon={icon} uhcIcon={uhcIcon} iconStyle={iconStyle}>
      {route.childRoutes && (
        <ul style={styles.subNavContainer}>
          {route.childRoutes
            .filter((childRoute) => childRoute.label)
            .map((childRoute, _) => (
              <li
                style={[
                  styles.navItem,
                  isRouteActive(childRoute) ? styles.activeItem : {},
                ]}
                key={_}
              >
                <RadiumLink
                  to={`${route.path ? `${route.path}/` : ''}${childRoute.path}`}
                  style={styles.link}
                >
                  {childRoute.label}
                </RadiumLink>
              </li>
            ))}
        </ul>
      )}
      {children}
    </Page>
  );
};

PageWithSubNav.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
  lineColor: PropTypes.string,
  lineWidth: PropTypes.number,
  activeColor: PropTypes.string,
  uhcIcon: PropTypes.string,
};

PageWithSubNav.defaultProps = {
  lineColor: Colors.gutterGray,
  lineWidth: 2.5,
  activeColor: Colors.linkBlue,
};

export default Radium(PageWithSubNav);
