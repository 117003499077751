import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  AppConstants,
  Features,
} from '@healthmine/greyhound-core/src/constants';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import {
  CompletionStatus,
  HRAState,
  BiometricState,
} from '@healthmine/greyhound-core/src/constants/CarePlan';
import { selectHraStatus } from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import { selectBiometricStatus } from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';
import ActionableAction, { types } from '../actions/ActionableAction';

const hraCaption = (status) => {
  if (!status) return null;

  const { lingo } = AppConfig.effectiveConfig;
  switch (status) {
    case AppConstants.QuestionnaireStatus.COMPLETED: {
      return HRAState.hraCompleted(lingo);
    }
    case AppConstants.QuestionnaireStatus.STARTED: {
      return HRAState.hraStarted(lingo);
    }
    case AppConstants.QuestionnaireStatus.NOT_STARTED: {
      return HRAState.hraStateNotStarted(lingo);
    }
    default:
      return null;
  }
};

const biometricCaption = (status) => {
  if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO)) {
    return BiometricState.SCREENING_NOTSTARTED;
  }

  switch (status) {
    case CompletionStatus.COMPLETED: {
      return BiometricState.SCREENING_COMPLETED;
    }
    case CompletionStatus.STARTED: {
      return BiometricState.SCREENING_STARTED;
    }
    case CompletionStatus.NOTSTARTED: {
      return BiometricState.SCREENING_NOTSTARTED;
    }
    default: {
      return null;
    }
  }
};

const hraRoute = (hraStatus) =>
  hraStatus === CompletionStatus.COMPLETED ? '/hra-result' : '/hra';

const biometricRoute = (biometricStatus) => {
  const baseRoute = '/biometric-screening';
  if (AppConfig.validateFeature(Features.BIOMETRIC_SCREENINGS_DEMO))
    return `${baseRoute}/events`;

  return biometricStatus === CompletionStatus.STARTED
    ? `${baseRoute}/appointment`
    : biometricStatus === CompletionStatus.COMPLETED
      ? `${baseRoute}/results`
      : baseRoute;
};

const Preventive = ({
  carePlan,
  hraCaption,
  biometricCaption,
  goToHra,
  goToBioScreenings,
}) => (
  <div>
    <ActionableAction type={types.DIRECT} takeAction={goToHra}>
      {hraCaption}
    </ActionableAction>
    {carePlan.biometricScreening && (
      <ActionableAction type={types.DIRECT} takeAction={goToBioScreenings}>
        {biometricCaption}
      </ActionableAction>
    )}
  </div>
);

Preventive.propTypes = {
  carePlan: PropTypes.shape({
    biometricScreening: PropTypes.bool,
  }),
  hraCaption: PropTypes.string,
  biometricCaption: PropTypes.string,
  goToHra: PropTypes.func,
  goToBioScreenings: PropTypes.func,
  hraStatus: PropTypes.string,
  biometricStatus: PropTypes.string,
};

export default compose(
  withRouter,
  connect((state) => {
    const hraStatus = selectHraStatus(state);

    const biometricStatus = selectBiometricStatus(state);

    return {
      hraCaption: hraCaption(hraStatus),
      biometricCaption: biometricCaption(biometricStatus),
      hraStatus,
      biometricStatus,
    };
  }),
  withHandlers({
    goToHra: ({ router, hraStatus }) => () => router.push(hraRoute(hraStatus)),
    goToBioScreenings: ({ router, biometricStatus }) => () =>
      router.push(biometricRoute(biometricStatus)),
  }),
  Radium
)(Preventive);
