import _ from 'lodash';

export const doctor = (resource) => {
  const isReferral = _.some(_.get(resource, 'contained'), {
    resourceType: 'ReferralRequest',
  });
  const primaryProvider = _.find(
    _.get(resource, 'careTeam'),
    (careTeamMember) => {
      return _.some(_.get(careTeamMember, 'role.coding'), {
        system: 'http://hl7.org/fhir/claimcareteamrole',
        code: 'primary',
      });
    }
  );

  /****************
   * MEDICAL CARE *
   ****************/
  const medicalCareItems = _.filter(_.get(resource, 'item'), (item) =>
    _.some(_.get(item, 'category.coding'), {
      system:
        'https://bluebutton.cms.gov/resources/variables/line_cms_type_srvc_cd',
      code: '1',
    })
  );
  const medicalCare = _.map(medicalCareItems, (item) => {
    return {
      careType: _.get(
        _.find(_.get(item, 'extension'), {
          url: 'https://bluebutton.cms.gov/resources/variables/betos_cd',
        }),
        'valueCoding.display',
        'Unknown'
      ),
      dateOfService: _.get(item, 'servicedPeriod.start'),
    };
  });

  /****************
   *     LABS     *
   ****************/
  const diagnosticLabItems = _.filter(_.get(resource, 'item'), (item) =>
    _.some(_.get(item, 'category.coding'), {
      system:
        'https://bluebutton.cms.gov/resources/variables/line_cms_type_srvc_cd',
      code: '5',
    })
  );
  const diagnosticLabs = _.map(diagnosticLabItems, (item) => {
    return {
      labType: _.get(
        _.find(_.get(item, 'extension'), {
          url: 'https://bluebutton.cms.gov/resources/variables/betos_cd',
        }),
        'valueCoding.display',
        'Unknown'
      ),
      dateOfService: _.get(item, 'servicedPeriod.start'),
    };
  });

  /****************
   *   SURGERY    *
   ****************/
  const surgeryItems = _.filter(_.get(resource, 'item'), (item) =>
    _.some(_.get(item, 'category.coding'), {
      system:
        'https://bluebutton.cms.gov/resources/variables/line_cms_type_srvc_cd',
      code: '2',
    })
  );
  const surgeries = _.map(surgeryItems, (item) => {
    return {
      surgeryType: _.get(
        _.find(_.get(item, 'extension'), {
          url: 'https://bluebutton.cms.gov/resources/variables/betos_cd',
        }),
        'valueCoding.display',
        'Unknown'
      ),
      dateOfService: _.get(item, 'servicedPeriod.start'),
    };
  });

  return {
    diagnoses: _.map(
      _.filter(_.get(resource, 'diagnosis'), (diagnosis) =>
        _.get(diagnosis, 'diagnosisCodeableConcept.coding[0].display')
      ),
      (diagnosis) => ({
        display: _.get(diagnosis, 'diagnosisCodeableConcept.coding[0].display'),
        primary: _.some(_.get(diagnosis, 'type'), (diagnosisType) =>
          _.some(_.get(diagnosisType, 'coding'), {
            system:
              'https://bluebutton.cms.gov/resources/codesystem/diagnosis-type',
            code: 'principal',
          })
        ),
        admitting: _.some(_.get(diagnosis, 'type'), (diagnosisType) =>
          _.some(_.get(diagnosisType, 'coding'), {
            system:
              'https://bluebutton.cms.gov/resources/codesystem/diagnosis-type',
            code: 'admitting',
          })
        ),
      })
    ),
    labs: diagnosticLabs,
    medicalCare,
    surgeries,
    isReferral,
    primaryProviderRAW: primaryProvider,
  };
};
