import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { compose, pure, withStateHandlers, withProps } from 'recompose';
import SchedulingModal from '../SchedulingModal';
import moment from 'moment';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import HRALInk from './HRALink';
import BiometricLink from './BiometricLink';
import { connect } from 'react-redux';
import _ from 'lodash';
import { selectHraStatus } from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import { selectBiometricStatus } from '@healthmine/greyhound-core/src/selectors/BiometricScreeningSelector';
import AppConstants from '@healthmine/greyhound-core/src/constants/App';
import { CompletionStatus } from '@healthmine/greyhound-core/src/constants/CarePlan';

const styles = {
  root: {
    minHeight: '75px',
    padding: '12px',
    display: 'flex',
    fontSize: '16px',
    lineHeight: '17px',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
  even: {
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
  title: {
    width: '200px',
    paddingRight: '24px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  description: {
    width: '400px',
    flexGrow: '1',
    paddingRight: '24px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  col3: {
    fontSize: '16px',
    fontWeight: '500',
    width: '175px',
    overflow: 'hidden',
  },
  date: {
    fontWeight: '300',
    display: 'block',
    marginTop: '7px',
  },
};

const HealthAction = ({
  isEven,
  title,
  description,
  openTracking,
  isSchedulingOpen,
  openScheduling,
  closeScheduling,
  completed,
  lastCompleted,
  pastDue,
  nextDue,
  schedulingContactList,
  incentiveAmount,
  showDueDate,
  CTA,
}) => {
  const unifiedCarePlan = AppConfig.validateFeature(Features.UNIFIED_CARE_PLAN);

  return (
    <div
      style={[
        styles.root,
        completed ? { color: Colors.ice } : {},
        isEven && styles.even,
      ]}
    >
      <div
        style={[styles.title, unifiedCarePlan ? { cursor: 'pointer' } : {}]}
        onClick={openTracking}
      >
        <div style={{ fontWeight: '500' }}>{title}</div>
        {completed &&
          !unifiedCarePlan && (
            <div style={{ textTransform: 'uppercase' }}>Completed</div>
          )}
      </div>
      <div
        style={[
          styles.description,
          unifiedCarePlan ? { cursor: 'pointer' } : {},
        ]}
        onClick={openTracking}
      >
        {description}
      </div>
      <div style={styles.col3}>
        {showDueDate ? (
          completed ? (
            <>
              <div>Completed</div>
              {lastCompleted && (
                <span style={styles.date}>
                  {`Completed On: ${moment(lastCompleted).format('M/D/YY')}`}
                </span>
              )}
            </>
          ) : (
            <div style={{ fontSize: '16px' }}>
              {pastDue ? (
                <div style={{ color: Colors.newRed }}>Past Due</div>
              ) : (
                <div>Due by {moment(nextDue).format('M/D/YY')}</div>
              )}
              <a
                onClick={openScheduling}
                style={{
                  color: '#1E80F0',
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                Scheduling Options{' '}
                <i style={{ fontSize: '18px' }} className="material-icons">
                  chevron_right
                </i>
              </a>
            </div>
          )
        ) : (
          incentiveAmount && (
            <div>
              {completed ? 'You earned' : 'Earn'} {incentiveAmount}{' '}
              {incentiveAmount === 1 ? 'point' : 'points'}
              {completed && '!'}
            </div>
          )
        )}
        {CTA}
      </div>
      {isSchedulingOpen &&
        schedulingContactList && (
          <SchedulingModal
            close={closeScheduling}
            healthActionTitle={title}
            schedulingContactList={schedulingContactList}
          />
        )}
    </div>
  );
};

HealthAction.propTypes = {
  isEven: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  openTracking: PropTypes.func,
  openScheduling: PropTypes.func,
  closeScheduling: PropTypes.func,
  isSchedulingOpen: PropTypes.bool,
  completed: PropTypes.bool,
  lastCompleted: PropTypes.string,
  pastDue: PropTypes.bool,
  nextDue: PropTypes.string,
  schedulingContactList: PropTypes.array,
  incentiveAmount: PropTypes.number,
  showDueDate: PropTypes.bool,
  CTA: PropTypes.node,
};

export default compose(
  pure,
  connect((state) => {
    const HRAStatus = selectHraStatus(state);

    const biometricStatus = selectBiometricStatus(state);

    return {
      HRAStatus,
      biometricStatus,
    };
  }),
  withStateHandlers(
    {
      isSchedulingOpen: false,
    },
    {
      openScheduling: () => () => ({
        isSchedulingOpen: true,
      }),

      closeScheduling: () => () => ({
        isSchedulingOpen: false,
      }),
    }
  ),
  withProps(({ action, HRAStatus, biometricStatus }) => {
    const unifiedCarePlan = AppConfig.validateFeature(
      Features.UNIFIED_CARE_PLAN
    );
    return {
      title: unifiedCarePlan ? action.title : action.shortDescription,
      description: unifiedCarePlan ? action.description : action.subTitle,
      nextDue: action.nextDue,
      completed: unifiedCarePlan
        ? action.completed
        : !_.isEmpty(action.completions) ||
          (action.healthActionCode ===
            AppConstants.HealthActions.COMPLETE_HEALTH_QUESTIONNAIRE &&
            HRAStatus === CompletionStatus.COMPLETED) ||
          (action.healthActionCode ===
            AppConstants.HealthActions.COMPLETE_BIOMETRIC_TESTING &&
            biometricStatus === CompletionStatus.COMPLETED),
      lastCompleted: action.lastCompleted,
      schedulingContactList: action.schedulingContactList,
      incentiveAmount: action.incentiveAmount,
      CTA:
        action.healthActionCode === 'COMPLETE_HEALTH_QUESTIONNAIRE' ? (
          <HRALInk />
        ) : (
          action.healthActionCode === 'COMPLETE_BIOMETRIC_TESTING' && (
            <BiometricLink />
          )
        ),
    };
  }),
  Radium
)(HealthAction);
