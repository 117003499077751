import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  GlobalStyles,
  AppConfig,
} from '@healthmine/greyhound-core/src/modules';
import Button from '../../common/Button';
import {
  AppConstants,
  RewardsConstants,
} from '@healthmine/greyhound-core/src/constants';
import InfoPopup from '../../common/InfoPopup';
import OrderSummary from '../shop/OrderSummary';
import styles from './AutomatedRewards.styles';
import Footer from './Footer';

const AutomatedRewardsMarkup = ({
  products,
  selectedOption,
  address,
  editAddress,
  isEditable,
  changeAddress,
  name,
  onOptionSelected,
  updateAddress,
  addressError,
  updateRewardsPreference,
  onDeliveryMethodSelected,
  deliveryMethod,
  emailAddress,
  selectedCard,
  toggleAutomatedModal,
  rewardSelectionComplete,
  requestFailed,
  registration,
}) => {
  const hasSelectedItem = selectedOption.length > 0;

  const imageUrl = hasSelectedItem
    ? selectedCard.imageUrl
    : require('../../../images/rewards.png');

  const { autoRewardsInfo } = AppConfig.effectiveConfig.lingo;

  return (
    <div>
      <div style={styles.pageTitle}>
        <span style={[GlobalStyles.light, styles.titleText]}>
          Automated Rewards
        </span>
      </div>
      {!rewardSelectionComplete && (
        <div style={styles.pageDescription}>
          <div style={styles.balance}>
            {!!autoRewardsInfo && (
              <div style={[GlobalStyles.regular, styles.description]}>
                {autoRewardsInfo}
              </div>
            )}
            <p style={[GlobalStyles.regular, styles.rewardsTitleText]}>
              This card will automatically be used until:
            </p>
            <ul
              style={[
                GlobalStyles.regular,
                styles.description,
                styles.detailsDate,
              ]}
            >
              <li>You have redeemed all of your Rewards for the year</li>
              <li>You change your gift card selection</li>
              <li>You choose to discontinue the Automated Rewards Option</li>
            </ul>
          </div>
          <div style={styles.automatedRewards}>
            <span style={styles.rewardsTitleText}>Current Selection:</span>
            <div style={styles.currentReward}>
              <img
                style={styles.rewardImage}
                src={imageUrl}
                alt={`${selectedCard.name} Card`}
                title={`${selectedCard.name} Card`}
              />
              {hasSelectedItem ? (
                <span style={[GlobalStyles.regular, styles.selectedCardName]}>
                  {selectedCard.name}
                </span>
              ) : null}
            </div>
            {!hasSelectedItem && (
              <label style={styles.actionLabel}>Choose a Card Below</label>
            )}
          </div>
        </div>
      )}

      {rewardSelectionComplete ? (
        <OrderSummary />
      ) : products && products.length ? (
        <div style={styles.content}>
          <div style={styles.header}>
            <label style={[GlobalStyles.regular, styles.headerLabel]}>
              1. Choose a Gift Card
            </label>
          </div>
          <div style={styles.productContainer}>
            {products.map((row, rowIndex) => (
              <div
                key={rowIndex}
                style={row.length < 3 ? styles.modifiedRow : styles.productRow}
              >
                {row.map((card, index) => (
                  <div key={index} style={styles.productRow}>
                    <div
                      key={card.productId}
                      style={styles.cardContainer}
                      onClick={() => onOptionSelected(card.productId, card)}
                    >
                      <div
                        style={
                          selectedOption.indexOf(card.productId) > -1
                            ? styles.selectedBubble
                            : styles.smallBubble
                        }
                      />
                      <img
                        src={card.imageUrl}
                        style={styles.cardImage}
                        alt={`${card.name} Card`}
                        title={`${card.name} Card`}
                      />

                      <div
                        style={[GlobalStyles.regular, styles.cardInfoContainer]}
                      >
                        <label style={styles.cardName}>{card.name}</label>

                        <div
                          style={[GlobalStyles.light, styles.deliveryMethods]}
                        >
                          {card.isDigital ? (
                            <span
                              style={[
                                styles.deliveryMethod,
                                styles.digitalDeliveryMethod,
                              ]}
                            >
                              email
                            </span>
                          ) : null}

                          {card.isPhysical ? (
                            <span
                              style={[
                                styles.deliveryMethod,
                                styles.physicalDeliveryMethod,
                              ]}
                            >
                              standard mail
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div style={styles.header}>
            <label style={[GlobalStyles.regular, styles.headerLabel]}>
              2. Choose a Delivery Method
              {hasSelectedItem ? (
                <InfoPopup styles={styles.deliveryMethodInfoPopup}>
                  <div style={styles.deliveryMethodInfoPopupContent}>
                    {selectedCard.isPhysical && selectedCard.isDigital
                      ? `Select email delivery for the quickest access to your rewards (same day delivery).
                      Check your Message Center on the Healthy Rewards portal within 12 hours for your e-code(s) and redemption instructions.
                      If you choose standard mail, please allow 7-10 business days for delivery of your gift card(s).`
                      : selectedCard.isDigital === 'Email'
                        ? 'Check your Message Center on the Healthy Rewards portal within 12 hours for your e-code(s) and redemption instructions.'
                        : 'Please allow for 7-10 business days for delivery of your gift card(s).'}
                  </div>
                </InfoPopup>
              ) : null}
            </label>
          </div>
          {hasSelectedItem ? (
            selectedCard.isDigital && selectedCard.isPhysical ? (
              <div style={styles.deliveryMethodContainer}>
                <label
                  style={[GlobalStyles.light, styles.deliveryMethodOption]}
                >
                  <input
                    type="radio"
                    name="DeliveryMethod"
                    value={RewardsConstants.RewardDeliveryForm.DIGITAL}
                    onChange={onDeliveryMethodSelected}
                    style={styles.deliveryMethodOptionRadioButton}
                    checked={
                      deliveryMethod ===
                      RewardsConstants.RewardDeliveryForm.DIGITAL
                    }
                  />
                  Email
                </label>

                <label
                  style={[GlobalStyles.light, styles.deliveryMethodOption]}
                >
                  <input
                    type="radio"
                    name="DeliveryMethod"
                    value={RewardsConstants.RewardDeliveryForm.PHYSICAL}
                    onChange={onDeliveryMethodSelected}
                    style={styles.deliveryMethodOptionRadioButton}
                    checked={
                      deliveryMethod ===
                      RewardsConstants.RewardDeliveryForm.PHYSICAL
                    }
                  />
                  Standard Mail
                </label>
              </div>
            ) : (
              <label style={styles.staticConfigurationValue}>
                {selectedCard.isDigital ? 'Email' : 'Standard Mail'}
              </label>
            )
          ) : null}

          <div style={styles.header}>
            <label style={[GlobalStyles.regular, styles.headerLabel]}>
              {deliveryMethod === RewardsConstants.RewardDeliveryForm.DIGITAL
                ? '3. Confirm Your Email Address'
                : '3. Confirm Your Shipping Address'}
            </label>
            {!isEditable &&
            hasSelectedItem &&
            deliveryMethod === RewardsConstants.RewardDeliveryForm.PHYSICAL ? (
              <button
                onClick={editAddress}
                style={[
                  GlobalStyles.regular,
                  styles.editBtn,
                  { marginLeft: '80px' },
                ]}
              >
                Edit
              </button>
            ) : null}
          </div>
          {selectedOption > 0 &&
          deliveryMethod === RewardsConstants.RewardDeliveryForm.PHYSICAL ? (
            <div style={{ marginTop: '20px' }}>
              <div style={styles.inputContainer}>
                <label
                  htmlFor="name"
                  style={[GlobalStyles.regular, styles.inputLabel]}
                >
                  Name:{' '}
                </label>
                <label style={[GlobalStyles.regular, styles.inputValue]}>
                  {name}
                </label>
              </div>
              <div style={styles.inputContainer}>
                <label
                  htmlFor="street"
                  style={[
                    GlobalStyles.regular,
                    isEditable ? styles.editableLabel : styles.inputLabel,
                  ]}
                >
                  Street:{' '}
                </label>
                {isEditable ? (
                  <input
                    type="text"
                    id="streetAddr1"
                    style={styles.editableInput}
                    value={address && address.streetAddr1}
                    onChange={changeAddress}
                  />
                ) : (
                  <label style={[GlobalStyles.regular, styles.inputValue]}>
                    {address && address.streetAddr1}
                  </label>
                )}
              </div>
              <div
                style={[
                  styles.inputContainer,
                  {
                    display:
                      (address && address.streetAddr2) || isEditable
                        ? 'block'
                        : 'none',
                  },
                ]}
              >
                <label
                  htmlFor="street"
                  style={[
                    GlobalStyles.regular,
                    isEditable ? styles.editableLabel : styles.inputLabel,
                  ]}
                />
                {isEditable ? (
                  <input
                    type="text"
                    id="streetAddr2"
                    style={styles.editableInput}
                    value={
                      address !== undefined && address.streetAddr2 !== null
                        ? address.streetAddr2
                        : ''
                    }
                    onChange={changeAddress}
                  />
                ) : (
                  <label style={[GlobalStyles.regular, styles.inputValue]}>
                    {address && address.streetAddr2}
                  </label>
                )}
              </div>
              <div style={styles.inputContainer}>
                <label
                  htmlFor="city"
                  style={[
                    GlobalStyles.regular,
                    isEditable ? styles.editableLabel : styles.inputLabel,
                  ]}
                >
                  City:{' '}
                </label>
                {isEditable ? (
                  <input
                    type="text"
                    id="city"
                    style={styles.editableInput}
                    value={address && address.city}
                    onChange={changeAddress}
                  />
                ) : (
                  <label style={[GlobalStyles.regular, styles.inputValue]}>
                    {address && address.city}
                  </label>
                )}
              </div>
              <div style={styles.inputContainer}>
                <label
                  htmlFor="state"
                  style={[
                    GlobalStyles.regular,
                    isEditable ? styles.editableLabel : styles.inputLabel,
                  ]}
                >
                  State :{' '}
                </label>
                {isEditable ? (
                  <select
                    value={address && address.state}
                    style={[styles.select, { marginLeft: '20px' }]}
                    id="state"
                    onChange={changeAddress}
                  >
                    {AppConstants.States.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <label style={[GlobalStyles.regular, styles.inputValue]}>
                    {address && address.state}
                  </label>
                )}
              </div>
              <div style={styles.inputContainer}>
                <label
                  htmlFor="zipcode"
                  style={[
                    GlobalStyles.regular,
                    isEditable ? styles.editableLabel : styles.inputLabel,
                  ]}
                >
                  ZIP Code :{' '}
                </label>
                {isEditable ? (
                  <input
                    type="text"
                    id="zip"
                    style={styles.editableInput}
                    value={address && address.zip}
                    onChange={changeAddress}
                  />
                ) : (
                  <label style={[GlobalStyles.regular, styles.inputValue]}>
                    {address && address.zip}
                  </label>
                )}
              </div>
            </div>
          ) : selectedOption > 0 &&
          deliveryMethod === RewardsConstants.RewardDeliveryForm.DIGITAL ? (
            <div style={styles.inputContainer}>
              <label
                htmlFor="name"
                style={[GlobalStyles.regular, styles.inputLabel]}
              >
                Email:{' '}
              </label>
              <label
                style={[
                  GlobalStyles.light,
                  styles.inputLabel,
                  { fontSize: '16px', fontWeight: '200', width: '250px' },
                ]}
              >
                {emailAddress}
              </label>
            </div>
          ) : null}

          {hasSelectedItem && isEditable ? (
            <div style={styles.footerContent}>
              {addressError && <p style={styles.error}>{addressError}</p>}
              <Button
                style={[
                  styles.button,
                  GlobalStyles.regular,
                  { marginRight: '20px' },
                ]}
                text="Update"
                onClick={updateAddress}
              />
              <Button
                style={[styles.button, GlobalStyles.regular]}
                text="Cancel"
                onClick={editAddress}
              />
            </div>
          ) : null}

          {!isEditable &&
            (registration ? (
              <div style={styles.registrationFooter}>
                <Button
                  text="Sign Up For Auto Rewards"
                  disabled={!hasSelectedItem || !deliveryMethod}
                  onClick={updateRewardsPreference}
                />
              </div>
            ) : (
              <Footer
                canSubmit={hasSelectedItem && !!deliveryMethod}
                updateRewardsPreference={updateRewardsPreference}
                toggleAutomatedModal={toggleAutomatedModal}
                requestFailed={requestFailed}
              />
            ))}
        </div>
      ) : (
        <div style={styles.noContent}>
          No rewards at this time. Check back later!
        </div>
      )}
    </div>
  );
};

AutomatedRewardsMarkup.propTypes = {
  products: PropTypes.array.isRequired,
  address: PropTypes.object,
  name: PropTypes.string,
  deliveryMethod: PropTypes.string,
  emailAddress: PropTypes.string,
  editAddress: PropTypes.func,
  isEditable: PropTypes.bool,
  changeAddress: PropTypes.func,
  updateAddress: PropTypes.func,
  addressError: PropTypes.string,
  onDeliveryMethodSelected: PropTypes.func,
  updateRewardsPreference: PropTypes.func,
  onOptionSelected: PropTypes.func,
  selectedOption: PropTypes.array,
  selectedCard: PropTypes.object,
  automatedModalOpen: PropTypes.bool,
  toggleAutomatedModal: PropTypes.func,
  rewardSelectionComplete: PropTypes.bool,
  requestFailed: PropTypes.bool,
  registration: PropTypes.bool,
};

export default Radium(AutomatedRewardsMarkup);
