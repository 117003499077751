import { types } from '../actions/PopupMenuActions';

const __INITIAL_POPUP_MENU_STATE__ = {
  popupMenu: {
    visible: false,
  },
};

function popupMenu(state = __INITIAL_POPUP_MENU_STATE__.popupMenu, action) {
  switch (action.type) {
    case types.POPUP_MENU_SET_VISIBILITY:
      return {
        ...state,
        visible: action.visible,
      };

    default:
      return state;
  }
}

export default {
  popupMenu,
};
