import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { localizedContent } from '@healthmine/greyhound-core/src/constants/LocalizedContent';
import _ from 'lodash';
import ConfigurableAction from '../actions/ConfigurableAction';
import InformationalAction from '../actions/InformationalAction';

const Assigned = ({ carePlan, selectCarePlan }) => (
  <div>
    <InformationalAction>
      {_.get(localizedContent.plans[carePlan.subject], 'introduction', '')}
    </InformationalAction>
    <ConfigurableAction onClick={selectCarePlan} />
  </div>
);

Assigned.propTypes = {
  carePlan: PropTypes.shape({
    subject: PropTypes.string,
  }),
  selectCarePlan: PropTypes.func,
};

export default Radium(Assigned);
