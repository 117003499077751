import { isEmpty, isArray } from 'lodash/fp';
import { isMobilePhone, trim } from 'validator';

export const validatePhone = (value, { label }) => {
  if (!value || isMobilePhone(value, 'en-US')) return;
  return `${label} has to be 10 digits`;
};

export const validatePresence = (value, { label }) => {
  if (isArray(value) ? !isEmpty(value) : trim(value)) return;
  return `${label} is required`;
};

export const validateRange = (start, end) => (rawValue, { label }) => {
  const value = Number.parseInt(rawValue);
  if (value >= start && value <= end) return;
  return `${label} has to be between ${start}-${end}`;
};
