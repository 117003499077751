import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Button from '../../common/Button';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import { compose, withHandlers } from 'recompose';

const Footer = ({
  canSubmit,
  cancel,
  updateRewardsPreference,
  requestFailed,
}) => (
  <div style={styles.root}>
    {canSubmit ? (
      <div>
        <Button
          style={[styles.button, GlobalStyles.regular]}
          text="Sign Up For Auto Rewards"
          onClick={updateRewardsPreference}
        />
        {requestFailed && (
          <span style={styles.errorLabel}>
            Something went wrong. Check back in a few minutes and retry your
            submission.
          </span>
        )}
      </div>
    ) : (
      <Button
        style={[styles.button, GlobalStyles.regular]}
        text="Cancel"
        onClick={cancel}
      />
    )}
  </div>
);

Footer.propTypes = {
  cancel: PropTypes.func,
  canSubmit: PropTypes.bool,
  toggleAutomatedModal: PropTypes.func,
  updateRewardsPreference: PropTypes.func,
  requestFailed: PropTypes.bool,
};

const styles = {
  root: {
    marginTop: '40px',
  },
  button: {
    width: '180px',
    color: Colors.white,
    backgroundColor: Colors.dark,
    padding: '8px',
    marginBottom: '0px',
    outline: 'none',
    fontSize: '16px',
  },
  errorLabel: {
    marginLeft: '15px',
    color: Colors.red,
  },
};

export default compose(
  withHandlers({
    cancel: ({ toggleAutomatedModal }) => () => toggleAutomatedModal(),
  }),
  Radium
)(Footer);
