import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { flow, compact, uniq } from 'lodash/fp';
import { withStyles } from '@material-ui/core';
import Chip from './Chip';

const styles = () => ({
  root: {
    overflow: 'hidden',
  },
});

const Chips = ({ classes, labels, color }) => (
  <div className={classes.root}>
    {labels.map((x) => <Chip color={color} key={x} label={x} />)}
  </div>
);

Chips.propTypes = {
  classes: PropTypes.object.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string,
};

export default compose(
  pure,
  withProps(({ labels }) => ({
    labels: flow([uniq, compact])(labels),
  })),
  withStyles(styles)
)(Chips);
