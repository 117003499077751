import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, pure } from 'recompose';
import { Grid } from '@material-ui/core';
import { withTheme, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Typography from '../Typography';
import StatusWidget from '../widgets/StatusWidget';
import { displayMaxCount } from '@healthmine/greyhound-core/src/utils';

const styles = (theme) => ({
  iconWrapper: {
    marginRight: '20px',
  },
  bubble: {
    height: '60px',
    width: '60px',
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: '50%',
    padding: '15px',
  },
  icon: {
    fontSize: '30px',
  },
  mute: {
    margin: 0,
    color: theme.palette.secondary.light,
  },
  rightLinks: {
    alignItems: 'center',
  },
  rightLinksIcon: {
    marginLeft: '20px',
    fontSize: '25px',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    transition: theme.globalStyles.transition1,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
});

const ListHeader = (props) => {
  const {
    classes,
    theme,
    color,
    subtext,
    iconClass,
    noBubbleIcon,
    remainingCount,
    remainingCountNote,
    rightLinks,
    title,
    onClick,
    isMute,
    maxDisplayCount,
  } = props;
  const rootClass = classnames({ [classes.mute]: isMute });
  const iconWrapperProps = noBubbleIcon
    ? { className: classes.iconWrapper }
    : {
        className: classnames(classes.iconWrapper, classes.bubble),
        style: {
          color: '#FFF',
          backgroundColor: isMute ? theme.palette.secondary.light : color,
        },
      };

  return (
    <Grid
      onClick={onClick}
      container
      justify="space-between"
      alignItems="center"
      className={rootClass}
    >
      <Grid item xs={9}>
        <Grid container alignItems="center">
          <Grid item>
            <div {...iconWrapperProps}>
              <i className={classnames(iconClass, classes.icon)} />
            </div>
          </Grid>
          <Grid item>
            <Typography type="h3">{title}</Typography>
            {subtext && (
              <Typography className={classes.mute} type="h4">
                {subtext}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!!remainingCount && (
        <Grid item xs={3}>
          <StatusWidget
            color={color}
            status={displayMaxCount(remainingCount, maxDisplayCount)}
            statusNote={remainingCountNote}
            noBar
            alignment="right"
          />
          {rightLinks && (
            <div className={classes.rightLinks}>
              {_.map(rightLinks, (item, idx) => {
                return (
                  <a
                    key={`right-link-icon-${idx}`}
                    onClick={item.onClick}
                    className={`${item.iconClass} ${classes.rightLinksIcon}`}
                  />
                );
              })}
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};

ListHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  color: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  isMute: PropTypes.bool,
  noBubbleIcon: PropTypes.bool,
  noMargin: PropTypes.bool,
  remainingCount: PropTypes.number,
  remainingCountNote: PropTypes.string,
  rightLinks: PropTypes.arrayOf(
    PropTypes.shape({
      iconClass: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  subtext: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  maxDisplayCount: PropTypes.number,
};

ListHeader.defaultProps = {
  onClick: () => {},
};

export default compose(pure, withTheme(), withStyles(styles))(ListHeader);
