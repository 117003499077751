import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Card from '../../../../common/card/Card';
import Typography from '../../../../common/Typography';
import Chip from '../../../../common/Chip';
import Frequency from '../../../../common/Frequency';
import StartTracking from '../../tracking/StartTracking';
import Tracker from '../../tracking/tracker';
import { withDrawer } from '../../../../common/drawer2';
import { getWeeklySummary } from '../../tracking/utils';
import createCachedSelector from 're-reselect';
import { head, last, sum, has } from 'lodash';
import moment from 'moment';
import { commaFormat } from '@healthmine/greyhound-core/src/utils';
import { selectNewestSharedAnswers } from '@healthmine/greyhound-core/src/selectors/HraSelectors';

const styles = () => ({
  customCard: {
    width: '237px',
    height: '230px',
    position: 'relative',
  },
  measurmentUnit: {
    marginBottom: '5px',
  },
  title: {
    marginTop: '10px',
    flexGrow: 1,
  },
  newChip: {
    position: 'absolute',
    left: '20px',
  },
  frequencyWrapper: {
    width: '100%',
  },
  setupMessage: {
    width: '100%',
  },
});
const propSelector = createCachedSelector(
  (state, props) => props.tracker.weekSelector(state, 0),
  (state, props) => props.tracker.dataSelector(state),
  (latestWeekData, data) => ({
    latestWeekData,
    minDate: head(head(data)),
    maxDate: head(last(data)),
  })
)((state, props) => props.tracker.dataKey);

const pluralize = (string, value) => (value === 1 ? string : `${string}s`);

const TrackerCard = ({
  classes,
  drawer,
  tracker,
  latestWeekData,
  sharedAnswers,
}) => {
  const {
    unit,
    displayName,
    setupMessage,
    isActive,
    isAvailable,
    type,
  } = tracker;
  let { currentValue } = tracker;
  if (type === 'WEIGHT') {
    currentValue =
      sharedAnswers.HNA_WEIGHT && has(sharedAnswers.HNA_WEIGHT, 'value')
        ? sharedAnswers.HNA_WEIGHT.value
        : currentValue;
  } else if (type === 'BLOOD_PRESSURE') {
    currentValue =
      sharedAnswers.HNA_BP && has(sharedAnswers.HNA_BP, 'value')
        ? sharedAnswers.HNA_BP.value !== 'undefined/undefined'
          ? sharedAnswers.HNA_BP.value !== 'NA'
            ? sharedAnswers.HNA_BP.value
            : '---'
          : '---'
        : currentValue;
  }
  const value = isActive
    ? currentValue
      ? type === 'BLOOD_PRESSURE'
        ? currentValue
        : commaFormat(currentValue)
      : '---'
    : 0;
  const isNew = isAvailable && !currentValue;
  const trackerDrawer = isActive ? Tracker : StartTracking;
  const title = isActive
    ? `My ${displayName}`
    : `Start Tracking My ${displayName}`;
  const latestWeek = moment().startOf('w');
  const latestWeekSummary = getWeeklySummary(latestWeek, latestWeekData);
  const daysTracked = latestWeekSummary.map(({ value }) => !!value);
  const trackingCount = sum(daysTracked);

  return (
    <Card
      customClass={classes.customCard}
      onClick={() => drawer.open(trackerDrawer, { tracker })}
      column
    >
      {isNew && (
        <Chip label="NEW" className={classes.newChip} color="grannySmith" />
      )}
      <Typography type="h1" color={isActive ? 'mintJulep' : 'blueGray'}>
        {value}
      </Typography>
      <Typography className={classes.measurmentUnit} type="h2" color="blueGray">
        {unit}
      </Typography>
      <Typography type="h4" className={classes.title}>
        {title}
      </Typography>
      {isActive && (
        <div className={classes.frequencyWrapper}>
          <Frequency
            heading={`Tracked ${trackingCount} ${pluralize(
              'time',
              trackingCount
            )} this week`}
            frequencies={daysTracked}
          />
        </div>
      )}
      {!isActive && (
        <Typography className={classes.setupMessage} color="blueGray">
          {setupMessage}
        </Typography>
      )}
    </Card>
  );
};

TrackerCard.propTypes = {
  classes: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
  tracker: PropTypes.object.isRequired,
  latestWeekData: PropTypes.object.isRequired,
  sharedAnswers: PropTypes.object,
};

export default compose(
  pure,
  withDrawer,
  withStyles(styles),
  connect((state, props) => {
    const sharedAnswers = selectNewestSharedAnswers(state);
    return {
      ...propSelector(state, props),
      sharedAnswers,
    };
  })
)(TrackerCard);
