import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PageSection from '../../../common/page/PageSection';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import _, { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { RewardsActions } from '@healthmine/greyhound-core/src/actions';
import { rewardsSelector } from '@healthmine/greyhound-core/src/selectors/RewardsSelectors';
import { withDrawer } from '../../../common/drawer2';
import AutomatedRewardsDrawer from './AutomatedRewardsDrawer';
import CancelAutomatedRewardsDrawer from './CancelAutomatedRewardsDrawer';
import Card from '../../../common/card/Card';
import { Grid } from '@material-ui/core';
import Typography from '../../../common/Typography';
import classnames from 'classnames';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: '20px',
  },
  customCard: {
    padding: '4px',
    width: '237px',
  },
  image: {
    width: '100%',
    background: '#ccc',
    borderTopLeftRadius: theme.globalStyles.borderRadius1,
    borderTopRightRadius: theme.globalStyles.borderRadius1,
  },
  cardBody: {
    padding: '20px',
  },
  changeLink: {
    color: Colors.linkBlue,
  },
  cancelLink: {
    color: Colors.red,
  },
  separator: {
    margin: '0 4px',
  },
  clickable: {
    cursor: 'pointer',
  },
  actionLabel: {
    color: Colors.navyBlue,
  },
});

const AutomatedRewardsCard = ({
  classes,
  openAutomatedRewardsDrawer,
  openCancelAutomatedRewardsDrawer,
  automatedReward,
  isAutomated,
}) => {
  const imageUrl =
    isAutomated === true
      ? get(automatedReward, 'imageUrl')
      : require('../../../../../images/rewards.png');
  return (
    <PageSection title="Automated Rewards">
      <Card customClass={classes.customCard}>
        <img
          className={classnames(classes.image, classes.clickable)}
          src={imageUrl}
          onClick={openAutomatedRewardsDrawer}
          alt="Choose a card"
          title="Choose a card"
        />
        <div className={classes.cardBody}>
          <Grid container direction="column" spacing={8}>
            <Grid item>
              {isAutomated ? (
                <div>
                  <Typography
                    inline
                    className={classnames(
                      classes.changeLink,
                      classes.clickable
                    )}
                    onClick={openAutomatedRewardsDrawer}
                  >
                    Change
                  </Typography>
                  <Typography inline className={classes.separator}>
                    or
                  </Typography>
                  <Typography
                    inline
                    className={classnames(
                      classes.cancelLink,
                      classes.clickable
                    )}
                    onClick={openCancelAutomatedRewardsDrawer}
                  >
                    Cancel Selection
                  </Typography>
                </div>
              ) : (
                <Typography
                  onClick={openAutomatedRewardsDrawer}
                  className={classnames(classes.clickable, classes.actionLabel)}
                >
                  Choose a card
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      </Card>
    </PageSection>
  );
};

AutomatedRewardsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  openAutomatedRewardsDrawer: PropTypes.func.isRequired,
  openCancelAutomatedRewardsDrawer: PropTypes.func.isRequired,
  automatedReward: PropTypes.object,
  isAutomated: PropTypes.bool,
};

export default compose(
  pure,
  withDrawer,
  connect(
    (state) => {
      const preference = state.rewards.preference;
      const rewards = rewardsSelector(state);
      const rewardsFlat = get(state, 'rewards.rewards');
      const isAutomated =
        preference &&
        preference.productId !== null &&
        preference.productId !== undefined
          ? true
          : false;
      const preferredDeliveryMethod = _.get(preference, 'delivery');
      const automatedReward = isAutomated
        ? _.find(rewardsFlat, {
            productId: preference.productId,
            deliveryForm: preferredDeliveryMethod,
          })
        : undefined;

      return { rewards, isAutomated, automatedReward };
    },
    (dispatch) => {
      const { getRewardsPreference } = RewardsActions;

      return {
        actions: bindActionCreators({ getRewardsPreference }, dispatch),
      };
    }
  ),
  withStyles(styles),
  withProps(({ drawer }) => ({
    incentiveCurrency: get(
      AppConfig,
      'effectiveConfig.incentiveCurrency',
      'points'
    ).toLowerCase(),
    openAutomatedRewardsDrawer: () => {
      drawer.close();
      drawer.open(AutomatedRewardsDrawer, {}, { large: true });
    },
    openCancelAutomatedRewardsDrawer: () =>
      drawer.open(CancelAutomatedRewardsDrawer),
  }))
)(AutomatedRewardsCard);
