import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  questionText: {
    color: Colors.bodyColor,
    fontSize: '16px',
    marginBottom: '10px',
  },

  questionContainer: {
    padding: '0 15px',
  },
};
