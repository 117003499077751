import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import Icons from '@healthmine/greyhound-core/src/modules/Icons';
import Step from './Step';

const ClinicalActionStep = ({
  actions,
  scrollId,
  previousStep,
  nextStep,
  active,
  last,
}) => {
  const styles = {
    container: {
      marginTop: '60px',
      paddingLeft: '100px',
    },
    description: {
      color: Colors.dark,
      margin: '0 0 40px',
      fontSize: '16px',
    },
    listContainer: {
      display: 'flex',
    },
    listImage: {
      margin: 'auto 0',
      fontSize: '105px',
    },
    actionList: {
      color: Colors.dark,
      fontSize: '22px',
      margin: 'auto 0 auto 10px',
      width: '100%',
    },
    actionListItem: {
      margin: '10px 0px',
    },
  };

  return (
    <Step
      scrollId={scrollId}
      iconClass={Icons.STETHOSCOPE}
      title={'Clinical'}
      previousStep={previousStep}
      nextStep={nextStep}
      active={active}
      required
      last={last}
      summaryLines={actions.map((action) => action.shortDescription)}
      valid
    >
      <div style={styles.container}>
        <h6 style={styles.description}>
          These are activities that will greatly benefit and inform you in
          regards to your condition.
        </h6>

        <div style={styles.listContainer}>
          <i className={Icons.BULLET_LIST} style={styles.listImage} />

          <ol style={[styles.actionList, GlobalStyles.light]}>
            {actions.map((action, actionIndex) => (
              <li
                key={`ClinicalAction${actionIndex}`}
                style={styles.actionListItem}
              >
                {action.shortDescription}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </Step>
  );
};

ClinicalActionStep.propTypes = {
  scrollId: PropTypes.string.isRequired,
  last: PropTypes.bool,
  previousStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      shortDescription: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Radium(ClinicalActionStep);
