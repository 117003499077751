import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { DrawerSection } from '../../../../common/drawer2';
import { get } from 'lodash';
import createCachedSelector from 're-reselect';
import Typography from '../../../../common/Typography';

const stepTotalSelector = (state) =>
  get(state, 'myDataSummary.steps.planYearTotal', 0);
const propSelector = createCachedSelector(
  stepTotalSelector,
  (planYearTotalSteps) => ({ planYearTotalSteps })
)((state, props) => props.tracker.dataKey);

const TrackerTotalSteps = ({ planYearTotalSteps }) => (
  <DrawerSection>
    <Typography type="h4Medium">{planYearTotalSteps} steps</Typography>
    <Typography type="h4" color="twentyCC">
      Plan Year Total
    </Typography>
  </DrawerSection>
);

TrackerTotalSteps.propTypes = {
  planYearTotalSteps: PropTypes.number,
  tracker: PropTypes.object.isRequired,
};

export default compose(
  pure,
  connect((state, props) => propSelector(state, props))
)(TrackerTotalSteps);
