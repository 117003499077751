import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DrawerSection from './DrawerSection';

const styles = (theme) => ({
  title: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});

const DrawerSectionHeader = ({ classes, title }) => (
  <DrawerSection>
    <div className={classes.title}>{title}</div>
  </DrawerSection>
);

DrawerSectionHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(DrawerSectionHeader);
