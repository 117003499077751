import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  compose,
  pure,
  lifecycle,
  withState,
  withHandlers,
  withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import PageSection from '../../common/page/PageSection';
import SearchBar from '../../common/SearchBar';
import ChipFilter from '../../common/ChipFilter';
import EducationList from './EducationList';
import { WithRecommendedEducation } from '@healthmine/greyhound-core/src/hocs';
import {
  EDUCATION_METADATA,
  SUGGESTED_SEARCHES,
} from '@healthmine/greyhound-core/src/constants/Education';
import { EducationActions } from '@healthmine/greyhound-core/src/actions';
import Grid from '@material-ui/core/Grid';
import Autosuggest from 'react-autosuggest';
import Typography from '../../common/Typography';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { selectSuggested } from '@healthmine/greyhound-core/src/selectors/EducationSelector';
import AccountSwitcher from '../../common/AccountSwitcher';

const styles = (theme) => ({
  container: {
    position: 'relative',
  },
  suggestionsList: {
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
  },
  suggestionsContainer: {
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
});
const chips = _.map(EDUCATION_METADATA, ({ title: display }, value) => ({
  display,
  value,
}));
const HealthLibrary = ({
  actions,
  classes,
  results,
  searchString,
  updateSearchString,
  loading,
  searchEducation,
  filterClick,
  selectedFilters,
  showSuggestions,
  setEnabledSuggestions,
}) => {
  return (
    <>
      <AccountSwitcher />
      <PageSection title="Health Library">
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                searchEducation();
                setEnabledSuggestions(false);
              }}
            >
              <Autosuggest
                focusInputOnSuggestionClick={false}
                getSuggestionValue={(suggestion) => suggestion}
                onSuggestionsClearRequested={() => true}
                onSuggestionsFetchRequested={() => true}
                shouldRenderSuggestions={() => showSuggestions}
                suggestions={SUGGESTED_SEARCHES}
                theme={classes}
                renderSuggestion={(suggestion) => (
                  <Typography>{suggestion}</Typography>
                )}
                renderInputComponent={(inputProps) => (
                  <SearchBar {...inputProps} />
                )}
                inputProps={{
                  value: searchString,
                  onChange: (e, { newValue, method }) => {
                    setEnabledSuggestions(method !== 'click');
                    updateSearchString(
                      newValue || e.target.value,
                      searchEducation
                    );
                  },
                  onBlur: (e, { highlightedSuggestion }) => {
                    const newSearchString =
                      highlightedSuggestion || e.target.value;
                    if (newSearchString) {
                      updateSearchString(newSearchString, searchEducation);
                    }
                    setEnabledSuggestions(true);
                  },
                  onFocus: (e) => {
                    updateSearchString(e.target.value, searchEducation);
                    setEnabledSuggestions(true);
                  },
                  placeholder: 'Search health library',
                }}
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            <ChipFilter
              filters={chips}
              filterClick={filterClick}
              selectedFilters={selectedFilters}
            />
          </Grid>
          <Grid item xs={12}>
            {!loading &&
              _.chain(EDUCATION_METADATA)
                .map(({ title, icon }, key) => ({
                  key,
                  title,
                  icon,
                  list: results[key] || results[key.toUpperCase()] || [],
                }))
                .filter(({ key }) => _.includes(selectedFilters, key))
                .orderBy(({ list }) => list.length, 'desc')
                .map(({ title, icon, list, key }) => (
                  <EducationList
                    key={key}
                    educationItems={list}
                    title={title}
                    iconClass={icon}
                    trackEducation={actions.trackEducation}
                  />
                ))
                .value()}
          </Grid>
        </Grid>
      </PageSection>
    </>
  );
};

HealthLibrary.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  searchString: PropTypes.string.isRequired,
  searchEducation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateSearchString: PropTypes.func.isRequired,
  filterClick: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  results: PropTypes.object.isRequired,
  showSuggestions: PropTypes.bool.isRequired,
  setEnabledSuggestions: PropTypes.func.isRequired,
  params: PropTypes.object,
};

const getSearchResults = (search) =>
  _.transform(search, (result, value, key) => {
    return _.merge(result, {
      [key]: value,
    });
  });

const mapStateToProps = (
  state,
  { groupedRecommendedEducation, hasSearchParam }
) => {
  const { education } = state;
  const { search, searchKeyword, suggestions, loading, type } = education;

  const searchResults = _.has(suggestions, searchKeyword)
    ? getSearchResults(suggestions[searchKeyword])
    : getSearchResults(search);

  const assignedEducation = !AppConfig.validateFeature(
    Features.NO_ASSIGNED_EDUCATION
  );

  const results =
    hasSearchParam || type === 'search'
      ? searchResults
      : assignedEducation
        ? groupedRecommendedEducation
        : selectSuggested(state);

  return {
    search,
    suggestions,
    loading,
    results,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    _.pick(EducationActions, [
      'getEducation',
      'setSearchKeyword',
      'getSuggestedContent',
      'setEducationType',
      'trackEducation',
      'clearSearchResults',
    ]),
    dispatch
  ),
});

const debouncedSearch = _.debounce(
  ({ actions, searchString, suggestions, updateHasSearchParam }) => {
    if (_.isEmpty(searchString)) {
      updateHasSearchParam();
      return actions.setEducationType('assigned');
    }
    const suggestionKey = /^[A-Z0-9_:\-.]+$/.test(searchString)
      ? searchString
      : _.camelCase(searchString);

    if (_.has(suggestions, suggestionKey)) {
      Promise.all([
        actions.setEducationType('search'),
        actions.setSearchKeyword(suggestionKey),
      ]).then(actions.getSuggestedContent(suggestionKey));
    } else {
      Promise.all([
        actions.setEducationType('search'),
        actions.setSearchKeyword(searchString),
      ]).then(actions.getEducation(searchString));
    }
  },
  500
);

export default compose(
  pure,
  WithRecommendedEducation,
  withStateHandlers(
    (props) => ({
      hasSearchParam: _.has(props.params, 'searchWord'),
      selectedFilters: _.map(chips, (chip) => chip.value),
      showSuggestions: true,
    }),
    {
      updateHasSearchParam: () => () => ({ hasSearchParam: false }),
      setSelectedFilters: () => (selectedFilters) => ({ selectedFilters }),
      setEnabledSuggestions: () => (setting) => ({ showSuggestions: setting }),
    }
  ),
  connect(mapStateToProps, mapDispatchToProps),
  // We have to leave this withState because setSearchString is used with
  // a callback which is not supported by withStateHandlers
  withState('searchString', 'setSearchString', ''),
  withHandlers({
    updateSearchString: ({ setSearchString, searchString }) => (
      keyword,
      callback
    ) => {
      if (keyword === searchString) return;
      setSearchString(keyword, callback);
    },
    searchEducation: (props) => () => debouncedSearch(props),
    filterClick: ({ selectedFilters, setSelectedFilters }) => (value) => {
      if (_.find(selectedFilters, (filter) => filter === value)) {
        return setSelectedFilters(_.without(selectedFilters, value));
      }

      return setSelectedFilters(_.concat(selectedFilters, [value]));
    },
  }),
  lifecycle({
    componentDidMount() {
      const { params, searchEducation, updateSearchString } = this.props;
      if (params.searchWord) {
        updateSearchString(params.searchWord, searchEducation);
      }
    },
    componentWillUnmount() {
      this.props.actions.setEducationType('assigned');
    },
  }),
  withStyles(styles)
)(HealthLibrary);
