import React from 'react';
import PropTypes from 'prop-types';
import EducationTrackingMarkup from './EducationTrackingMarkup';

class EducationTracking extends React.Component {
  render() {
    return <EducationTrackingMarkup {...this.props} />;
  }
}

EducationTracking.propTypes = {
  iconColor: PropTypes.string.isRequired,
  bubbleColor: PropTypes.string.isRequired,
  bubbleFilled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  badge: PropTypes.oneOf(['verified', 'alert']),
};

export default EducationTracking;
