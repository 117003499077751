import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: theme.globalStyles.transition1,
    '&:hover': {
      color: theme.palette.common.beefy,
    },
  },
  label: {
    marginLeft: '10px',
  },
});

const DrawerHeaderAction = ({ theme, classes, onClick, label, color }) => (
  <a className={classes.root} onClick={onClick}>
    <div
      className={classes.label}
      style={{ color: theme.palette.common[color] || color }}
    >
      {label}
    </div>
  </a>
);

DrawerHeaderAction.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]),
  color: PropTypes.string,
};

export default compose(pure, withStyles(styles), withTheme())(
  DrawerHeaderAction
);
