import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  textarea: {
    width: '100%',
    height: '140px',
  },
});

const TextBoxAnswer = ({ classes, textAnswer, updateTextAnswer, question }) => (
  <div className={classes.root}>
    <textarea
      className={classes.textarea}
      value={textAnswer}
      placeholder="Enter text here..."
      onChange={(event) => {
        updateTextAnswer(event, question.id);
      }}
    />
  </div>
);

TextBoxAnswer.propTypes = {
  question: PropTypes.object.isRequired,
  textAnswer: PropTypes.string,
  updateTextAnswer: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextBoxAnswer);
