import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { withRoutineForm } from '@healthmine/greyhound-core/src/hocs';
import DrawerContent from '../DrawerContent';
import Picker from '../Picker';
import { DrawerSectionHeader } from '../../common/drawer2';

const maybeError = (field) => (field.valid ? null : 'Selection is required');

const RoutineDrawer = ({
  color,
  physicalFitnessField,
  goodFoodChoiceField,
  badFoodChoiceField,
  submit,
  skip,
  disableSubmit,
  ...props
}) => (
  <DrawerContent
    {...props}
    color={color}
    submitLabel="Keep Going"
    onSubmit={submit}
    onSkip={skip}
    disableSubmit={disableSubmit}
  >
    <DrawerSectionHeader title="How many times a week do you excercise?" />
    <Picker
      color={color}
      value={physicalFitnessField.value}
      error={maybeError(physicalFitnessField)}
      options={physicalFitnessField.options}
      onClick={physicalFitnessField.update}
    />
    <DrawerSectionHeader title="How often do you eat greens and whole grains?" />
    <Picker
      color={color}
      value={goodFoodChoiceField.value}
      error={maybeError(goodFoodChoiceField)}
      options={goodFoodChoiceField.options}
      onClick={goodFoodChoiceField.update}
    />
    <DrawerSectionHeader title="How often do you eat fat and fried foods?" />
    <Picker
      color={color}
      value={badFoodChoiceField.value}
      error={maybeError(badFoodChoiceField)}
      options={badFoodChoiceField.options}
      onClick={badFoodChoiceField.update}
    />
  </DrawerContent>
);

RoutineDrawer.propTypes = {
  color: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  physicalFitnessField: PropTypes.object.isRequired,
  goodFoodChoiceField: PropTypes.object.isRequired,
  badFoodChoiceField: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
};

export default compose(
  pure,
  withRoutineForm(({ onNext }) => onNext),
  withProps(({ title, description }) => ({
    color: 'beefy',
    hero: { title, description, background: 'routine' },
  }))
)(RoutineDrawer);
