import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { AppConfig, Colors } from '@healthmine/greyhound-core/src/modules';
import ConnectDevice from '../common/ConnectDevice';
import _ from 'lodash';
import Callout from '../tutorial/callout';
import { Features } from '@healthmine/greyhound-core/src/constants';
import AgendaItem from './AgendaItem';
import moment from 'moment';
import { Subjects } from '@healthmine/greyhound-core/src/constants/CarePlan';

const WhatsNextMarkup = ({ actions, onActionActivated, tutorial }) => {
  const showTutorial = !!(_.get(tutorial, 'page', null) == 'healthOverview');

  const barWidth = 299;

  const unifiedCarePlan = AppConfig.validateFeature(Features.UNIFIED_CARE_PLAN);

  const styles = {
    tutorialBorder: {
      border: showTutorial ? 'solid 3px rgb(33, 150, 243)' : null,
    },
    sectionHeading: {
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '22px',
      textTransform: 'uppercase',
      marginBottom: '12px',
    },
    flexContainer: {
      display: 'flex',
      flex: 1,
      maxWidth: '300px',
      backgroundColor: '#f3f6f8',
      borderLeft: `1px solid ${Colors.gutterGray}`,
    },
    root: {
      width: barWidth,
      maxWidth: barWidth,
      zIndex: showTutorial ? null : 1000,
    },

    body: {
      position: 'relative',
      padding: '40px 50px 20px 20px',
      backgroundColor: showTutorial ? Colors.white : '',
      filter: showTutorial ? 'opacity(0.3)' : null,
    },

    title: {
      fontSize: '18px',
      marginBottom: '30px',
    },

    item: {
      display: 'flex',
      flexDirection: 'row',
    },

    itemMarker: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '15px',
      minWidth: '15px',
      marginRight: '20px',
    },

    itemMarkerLine: {
      flex: 1,
      backgroundColor: Colors.lightGray,
      width: '1px',
    },

    itemContent: {
      marginBottom: '45px',
      marginTop: '-2px',
    },

    itemCaption: {
      fontSize: '16px',
    },

    itemCaptionLink: {
      color: Colors.black,
      textDecoration: 'none',
      cursor: 'pointer',
    },

    activeItemCaption: {
      fontWeight: 'bold',
    },

    itemPoints: {
      color: Colors.navyBlue,
      fontWeight: 'bold',
      marginTop: '2px',
    },

    tutorialContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      zIndex: '9999999999999',
      left: '-140px',
      top: '400px',
      width: '300',
    },
    tutorialArc: {
      fill: 'transparent',
      stroke: 'rgb(33, 150, 243)',
      strokeWidth: 3,
      width: 300,
      height: 60,
    },
    tutorialCopyHeader: {
      color: 'rgb(33, 150, 243)',
      fontSize: '24px',
    },
    tutorialCopyText: {
      width: '250px',
      fontSize: '18px',
      color: '#c9c9c9',
    },
  };

  const orderedActions = _.orderBy(
    actions,
    ['dueDate', 'description'],
    ['asc', 'asc']
  );

  if (unifiedCarePlan) {
    return (
      <div>
        <div style={styles.sectionHeading}>What’s Next</div>
        <div>
          {orderedActions.map(
            ({ description, dueDate, actionRoute, agendaActionId }, idx) => (
              <AgendaItem
                key={`agenda_item_${idx}`}
                message={description}
                isPastDue={
                  !_.isUndefined(dueDate) && moment(dueDate).isBefore(moment())
                }
                route={`care-plan/${
                  actionRoute === Subjects.COACHING
                    ? 'coaching'
                    : `preventive/${agendaActionId}`
                }`}
              />
            )
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div style={styles.title}>What’s Next</div>
        {actions.map((action, actionIndex) => (
          <div
            key={`WhatsNextItem${actionIndex}`}
            className="itemMarkerCircleParent"
          >
            <div style={styles.item}>
              <div style={styles.itemMarker}>
                <div className="itemMarkerCircle" />
                {actionIndex < actions.length - 1 ? (
                  <div style={styles.itemMarkerLine} />
                ) : null}
              </div>

              <div style={styles.itemContent}>
                <div
                  style={[
                    styles.itemCaption,
                    !actionIndex ? styles.activeItemCaption : null,
                  ]}
                >
                  {action.isActionable ? (
                    action.actionId === 'CONNECT_DEVICE' ? (
                      <ConnectDevice
                        link
                        style={styles.itemCaptionLink}
                        itemText={action.description}
                      />
                    ) : (
                      <div
                        style={styles.itemCaptionLink}
                        onClick={() => onActionActivated(action)}
                      >
                        {action.description}
                      </div>
                    )
                  ) : (
                    action.description
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showTutorial ? (
        <Callout
          path={'M75 60 C 75 60, 75 15, 155 15'}
          position={{ top: '400px', left: '-175px' }}
          title={"What's Next"}
          text={
            "If you're ever unsure what to do, just look here for a helpful hint."
          }
          direction={{ flexDirection: 'column' }}
          arrowEnd={'markerEnd'}
        />
      ) : null}
    </div>
  );
};

WhatsNextMarkup.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      actionRoute: PropTypes.string.isRequired,
      actionId: PropTypes.string.isRequired,
      isActionable: PropTypes.bool.isRequired,
      userPlanId: PropTypes.number,
    })
  ).isRequired,
  onActionActivated: PropTypes.func.isRequired,
  tutorial: PropTypes.object,
};

export default Radium(WhatsNextMarkup);
