import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { DrawerNavigation, withDrawer } from '../../../../common/drawer2';
import StartTracking from '../../tracking/StartTracking';
import { IncentiveSummary } from '@healthmine/greyhound-core/src/modules';
import { lowerCase } from 'lodash';

const PrescribedTrackerAction = ({ tracker, drawer }) => {
  const points = IncentiveSummary.formatPoints(tracker.maxDailyRewardAmount);
  return (
    <DrawerNavigation
      status="neutral"
      incentivesToEarn
      title={`Add a ${tracker.displayName} Tracker`}
      onClick={() => drawer.open(StartTracking, { tracker })}
      subtitle={`Earn up to ${lowerCase(points)} daily`}
    />
  );
};

PrescribedTrackerAction.propTypes = {
  tracker: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
};

export default compose(pure, withDrawer)(PrescribedTrackerAction);
