import { Colors } from '@healthmine/greyhound-core/src/modules';

export default {
  root: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.2)',
    height: '350px',
    boxSizing: 'border-box',
    padding: '40px',
    width: '390px',
    flexBasis: '390px',
    marginBottom: '20px',
    cursor: 'pointer',
    transition: 'box-shadow .2s ease-in-out',
    ':hover': {
      boxShadow: '0 4px 12px 0 rgba(0,0,0,0.4)',
    },
  },
  header: {
    display: 'flex',
    marginBottom: '20px',
  },
  bubble: {
    height: '80px',
    width: '80px',
    minWidth: '80px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: '#FFF',
    fontSize: '35px',
  },
  titleWrapper: {
    marginLeft: '10px',
  },
  title: {
    fontSize: '17px',
    lineHeight: '22px',
    textTransform: 'uppercase',
  },
  completion: {
    fontSize: '32px',
    lineHeight: '38px',
  },
  completeLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    marginLeft: '5px',
  },
  body: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '300',
  },
  subTitle: {
    marginBottom: '20px',
  },
  nextLabel: {
    color: Colors.ice,
  },
};
