import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { GlobalStyles, Colors } from '@healthmine/greyhound-core/src/modules';
import moment from 'moment';

const EntriesMarkup = ({ enteredProducts, wonProducts }) => {
  const styles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '75px',
      paddingRight: '75px',
    },

    header: {
      fontSize: '16px',
      fontWeight: 600,
      color: Colors.black,
      paddingBottom: '20px',
    },

    table: {
      textAlign: 'left',
      fontSize: '16px',
      width: '100%',
      borderCollapse: 'collapse',
    },

    th: {
      paddintTop: '10px',
      paddingBottom: '10px',
      color: Colors.mediumGray2,
      backgroundColor: '#f3f6f8',
      fontSize: '16px',
      textAlign: 'center',
    },

    td: {
      paddingTop: '20px',
      paddingBottom: '20px',
      color: Colors.contentText,
      borderBottom: `1px solid ${Colors.gutterGray}`,
      textAlign: 'center',
    },

    firstColumn: {
      textAlign: 'left',
      paddingLeft: '10px',
    },

    noContent: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '16px',
      fontWeight: 300,
      paddingBottom: '20px',
    },
  };
  return (
    <div>
      <div style={styles.content}>
        <div style={styles.header}>Won Sweepstakes</div>

        {wonProducts && wonProducts.length ? (
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={[GlobalStyles.bold, styles.th, styles.firstColumn]}>
                  Sweepstake
                </th>
                <th style={[GlobalStyles.bold, styles.th]}>Close Date</th>
                <th style={[GlobalStyles.bold, styles.th]}>Amount Won</th>
              </tr>
            </thead>
            <tbody>
              {wonProducts.map((item, index) => {
                return (
                  <tr key={index}>
                    <td style={[styles.td, styles.firstColumn]}>
                      {item.sweepstakeName}
                    </td>
                    <td style={styles.td}>
                      {moment(item.closeDate).format('MM/DD/YYYY')}
                    </td>
                    <td style={styles.td}>
                      ${item.memberWins * item.entryCost}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div style={styles.noContent}>
            You have not won any sweepstakes yet!
          </div>
        )}
      </div>
      <div style={styles.content}>
        <div style={styles.header}>Entered Sweepstakes</div>

        {enteredProducts && enteredProducts.length ? (
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={[GlobalStyles.bold, styles.th, styles.firstColumn]}>
                  Sweepstake
                </th>
                <th style={[GlobalStyles.bold, styles.th]}>Close Date</th>
                <th style={[GlobalStyles.bold, styles.th]}>My Entries</th>
                <th style={[GlobalStyles.bold, styles.th]}>Points Spent</th>
              </tr>
            </thead>
            <tbody>
              {enteredProducts.map((item, index) => {
                return (
                  <tr key={index}>
                    <td style={[styles.td, styles.firstColumn]}>
                      {item.sweepstakeName}
                    </td>
                    <td style={styles.td}>
                      {moment(item.closeDate).format('MM/DD/YYYY')}
                    </td>
                    <td style={styles.td}>{item.memberEntries}</td>
                    <td style={styles.td}>
                      {item.entryCost * item.memberEntries}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div style={styles.noContent}>
            You are not currently entered into any sweepstakes!
          </div>
        )}
      </div>
    </div>
  );
};

EntriesMarkup.propTypes = {
  enteredProducts: PropTypes.array,
  wonProducts: PropTypes.array,
};

export default Radium(EntriesMarkup);
