import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose, pure, withProps } from 'recompose';
import { find, getOr, map, size } from 'lodash/fp';
import { withStyles } from '@material-ui/core';
import { Features } from '@healthmine/greyhound-core/src/constants';
import { withReviewForm } from '@healthmine/greyhound-core/src/hocs';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';
import DrawerContent from '../DrawerContent';
import { DrawerSection, DrawerSectionHeader } from '../../common/drawer2';
import Title from '../../common/Title';
import { PREFERENCE_OPTIONS, FREQUENCY_OPTIONS } from './CommunicationDrawer';

const EMPTY_OBJECT = {};

const styles = (theme) => ({
  subTitle: {
    color: theme.palette.common.planet9,
  },
  notBubbleGum: {
    color: theme.palette.common.notBubbleGum,
  },
  beefy: {
    color: theme.palette.common.beefy,
  },
  venus: {
    color: theme.palette.common.venus,
  },
});

const getTitle = getOr('', 'answer.label');
const getHeightTitle = (feet, inches) => {
  if (getTitle(feet) && getTitle(inches)) {
    return `${getTitle(feet)}' ${getTitle(inches)}"`;
  } else {
    return '';
  }
};
const getSubTitle = (title, subTitle) => (title.length ? subTitle : '');

const getCommunicationPreferenceTitle = (value) =>
  getOr('', 'label', find({ value }, PREFERENCE_OPTIONS));

const getCommunicationFrequencyTitle = (value) =>
  getOr('', 'label', find({ value }, FREQUENCY_OPTIONS));

const titleSection = (classes, { key, title, subTitle, ...props }) => {
  if (!title || title.length === 0) {
    title = 'No Information Provided';
    subTitle = '';
  }
  return (
    <DrawerSection key={key}>
      <Title
        title={title}
        subTitle={subTitle}
        {...props}
        subtitleClass={classes.subTitle}
      />
    </DrawerSection>
  );
};

const ReviewDrawer = ({
  classes,
  submit,
  height,
  weight,
  physicalFitness,
  goodFoodChoice,
  badFoodChoice,
  medConditions,
  communicationPreferences,
  communicationFrequency,
  ...props
}) => (
  <DrawerContent {...props} submitLabel="Finish" onSubmit={submit}>
    <DrawerSectionHeader title="About You" />
    {titleSection(classes, height)}
    {titleSection(classes, weight)}
    <DrawerSectionHeader title="Routine" />
    {titleSection(classes, physicalFitness)}
    {titleSection(classes, goodFoodChoice)}
    {titleSection(classes, badFoodChoice)}
    <DrawerSectionHeader title="Health Risks & Conditions" />
    {medConditions.map((x, i) => titleSection(classes, { ...x, key: i }))}
    {size(communicationPreferences) ? (
      <div>
        <DrawerSectionHeader title="Communication Preferences" />
        {communicationPreferences.map((x, i) =>
          titleSection(classes, { ...x, key: i })
        )}
      </div>
    ) : null}
    {communicationFrequency ? (
      <div>
        <DrawerSectionHeader title="Communication Frequency" />
        {titleSection(classes, communicationFrequency)}
      </div>
    ) : null}
  </DrawerContent>
);

ReviewDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  height: PropTypes.object.isRequired,
  weight: PropTypes.object.isRequired,
  physicalFitness: PropTypes.object.isRequired,
  goodFoodChoice: PropTypes.object.isRequired,
  badFoodChoice: PropTypes.object.isRequired,
  medConditions: PropTypes.array.isRequired,
  communicationPreferences: PropTypes.array.isRequired,
  communicationFrequency: PropTypes.object,
};

export default compose(
  pure,
  withStyles(styles),
  withReviewForm(({ onNext }) => onNext),
  connect(
    (state) =>
      AppConfig.validateFeature(Features.FTE_COMMUNICATION_DEMO)
        ? state.fteCommunicationDemo
        : EMPTY_OBJECT
  ),
  withProps(
    ({
      classes,
      title,
      feet,
      inches,
      weight,
      physicalFitness,
      goodFoodChoice,
      badFoodChoice,
      medConditions,
      communicationPreferences,
      communicationFrequency,
    }) => ({
      color: 'blueGray',
      hero: {
        title,
        description: 'Select "Previous" to go back and edit any information',
        background: 'review',
      },
      height: {
        title: getHeightTitle(feet, inches),
        subtitle: getSubTitle(getHeightTitle(feet, inches), 'height'),
        iconClass: classnames(classes.notBubbleGum, 'icon-Tape-Measure'),
      },
      weight: {
        title: getTitle(weight),
        subtitle: getSubTitle(getTitle(weight), 'weight'),
        iconClass: classnames(classes.notBubbleGum, 'icon-Weight-Scale'),
      },
      physicalFitness: {
        title: getTitle(physicalFitness),
        subtitle: getSubTitle(getTitle(physicalFitness), 'exercise'),
        iconClass: classnames(classes.beefy, 'icon-Muscle-Strength'),
      },
      goodFoodChoice: {
        title: getTitle(goodFoodChoice),
        subtitle: getSubTitle(
          getTitle(goodFoodChoice),
          'greens and whole grains'
        ),
        iconClass: classnames(classes.beefy, 'icon-Salad'),
      },
      badFoodChoice: {
        title: getTitle(badFoodChoice),
        subtitle: getSubTitle(getTitle(badFoodChoice), 'fatty and fried foods'),
        iconClass: classnames(classes.beefy, 'icon-Fast-Food'),
      },
      medConditions: map(
        (x) => ({
          title: x.label,
          iconClass: classnames(classes.venus, 'icon-Hands-Heart'),
        }),
        medConditions.answer ? medConditions.answer.value : null
      ),
      communicationPreferences: map(
        (pref) => ({
          title: getCommunicationPreferenceTitle(pref),
        }),
        communicationPreferences
      ),
      communicationFrequency: communicationFrequency
        ? {
            title: getCommunicationFrequencyTitle(communicationFrequency),
          }
        : null,
    })
  )
)(ReviewDrawer);
