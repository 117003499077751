import PropTypes from 'prop-types';
import React from 'react';
import { compose, pure, setPropTypes } from 'recompose';
import Styles from './BubbleRange.styles';

export default compose(
  setPropTypes({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        option: PropTypes.objectOf(PropTypes.string).isRequired,
      })
    ),

    selectedOptionValue: PropTypes.string,

    onSelectionChanged: PropTypes.func.isRequired,
  }),

  pure
)(({ options, selectedOptionValue, onSelectionChanged }) => (
  <div style={Styles.root}>
    <div style={Styles.lineContainer}>
      <div style={Styles.line} />
    </div>

    <div style={Styles.bubbleContainer}>
      {options.map((option) => (
        <div
          key={`Option${option.id}`}
          onClick={() => onSelectionChanged(option.id)}
          style={{
            ...Styles.bubble,

            ...(option.id === selectedOptionValue ? Styles.selectedBubble : {}),
          }}
        />
      ))}
    </div>

    <div style={Styles.labelContainer}>
      {options.map((option, optionIndex) => {
        return !(optionIndex % 2) ? (
          <span
            key={`OptionLabel${optionIndex}`}
            style={{
              ...Styles.label,

              textAlign: !optionIndex
                ? 'left'
                : optionIndex === options.length - 1
                  ? 'right'
                  : 'center',
            }}
          >
            {option.option.en}
          </span>
        ) : null;
      })}
    </div>
  </div>
));
