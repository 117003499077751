import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, pure, setPropTypes } from 'recompose';
import { AppConstants } from '@healthmine/greyhound-core/src/constants';
import BubbleRange from './BubbleRange';
import TextArea from './TextArea';
import Styles from './Question.styles';

export default compose(
  setPropTypes({
    question: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(_.values(AppConstants.QuestionType)),
      question: PropTypes.objectOf(PropTypes.string).isRequired,

      answerOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          option: PropTypes.objectOf(PropTypes.string).isRequired,
          score: PropTypes.number.isRequired,
          displayOrder: PropTypes.number.isRequired,
        })
      ),
    }),

    questionNumber: PropTypes.number.isRequired,

    value: PropTypes.any,

    onValueChanged: PropTypes.func.isRequired,
  }),

  pure
)(({ question, value, questionNumber, onValueChanged }) => (
  <div>
    <span style={Styles.questionText}>
      {questionNumber}. {question.question.en}
    </span>

    <div style={Styles.questionContainer}>
      {question.type === AppConstants.QuestionType.LINEAR_SCALE ? (
        <BubbleRange
          options={question.answerOptions}
          selectedOptionValue={value}
          onSelectionChanged={(optionValue) =>
            onValueChanged(question.id, optionValue)
          }
        />
      ) : (
        <TextArea
          value={value}
          onValueChanged={(newValue) => onValueChanged(question.id, newValue)}
        />
      )}
    </div>
  </div>
));
