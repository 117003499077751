import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';

const BottomNav = ({
  showBack = true,
  nextText,
  scrollDown,
  scrollUp,
  skip,
}) => {
  const nav = {
    width: '164px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '30px',
  };

  const navSkip = {
    width: '70%',
  };

  const back = {
    color: Colors.linkBlue,
    opacity: '.75',
    cursor: 'pointer',
    ':hover': {
      color: Colors.darkenColor(Colors.linkBlue),
    },
    ':active': {
      color: Colors.darkenColor(Colors.linkBlue, 2),
    },
  };

  const next = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '184px',
    cursor: 'pointer',
    color: Colors.bodyText,
    ':hover': {
      color: Colors.darkenColor(Colors.bodyText),
    },
    ':active': {
      color: Colors.darkenColor(Colors.bodyText, 2),
    },
  };

  const icon = {
    display: 'inline-block',
    transform: 'rotate(90deg)',
  };

  const optional = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    color: Colors.linkBlue,
    opacity: '.75',
    cursor: 'pointer',
  };

  const piconRight = (
    <i className="thin-0160_arrow_next_right" style={{ fontSize: '24px' }} />
  );

  return (
    <div
      style={
        skip ? [nav, navSkip, GlobalStyles.light] : [nav, GlobalStyles.light]
      }
    >
      <span
        key="bottom-nav-back"
        style={back}
        onClick={showBack ? scrollUp : null}
      >
        {showBack ? 'Back' : ''}
      </span>
      <div key="bottom-nav-next" style={next} onClick={scrollDown}>
        <span>{nextText} &nbsp;</span>
        <div style={icon}>{piconRight}</div>
      </div>
      {skip ? (
        <div style={optional}>
          <span onClick={skip}>Skip</span>
        </div>
      ) : null}
    </div>
  );
};

BottomNav.propTypes = {
  showBack: PropTypes.bool,
  nextText: PropTypes.string,
  scrollDown: PropTypes.func,
  scrollUp: PropTypes.func,
  skip: PropTypes.func,
};

BottomNav.defaultProps = {
  nextText: 'Next',
};

export default Radium(BottomNav);
