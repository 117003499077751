import React from 'react';
import PropTypes from 'prop-types';
import DropdownMarkup from './DropdownMarkup';

class Dropdown extends React.Component {
  render() {
    return (
      <DropdownMarkup
        placeholder={this.props.placeholder}
        value={this.props.value}
        options={this.props.options}
        onAnswered={this.props.onAnswered}
        styles={this.props.styles}
        disabled={this.props.disabled}
        useDefaultSelect={this.props.useDefaultSelect}
      />
    );
  }
}

Dropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  onAnswered: PropTypes.func.isRequired,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  useDefaultSelect: PropTypes.bool,
};

export default Dropdown;
