import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, Assets } from '@healthmine/greyhound-core/src/modules/';
import Modal from 'react-modal';

const ReportComplianceModal = ({ close }) => {
  const styles = {
    modalStyles: {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Colors.hexToRgba(Colors.black, 0.2),
        zIndex: 1010,
      },
      content: {
        minWidth: '300px',
        minHeight: '200px',
        right: 'initial',
        bottom: 'initial',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: '#fff',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        outline: 'none',
        padding: '50px',
      },
    },
    closeButton: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontSize: '25px',
      color: Colors.black,
      cursor: 'pointer',
    },
    modalContent: {
      padding: '10px',
      boxSizing: 'border-box',
      flex: '0 0 300px',
      minWidth: '300px',
      height: '250px',
      maxHeight: '250px',
      position: 'relative',
      color: 'rgb(124, 138, 151)',
      lineHeight: '1.3',
      fontSize: '16px',
    },
    title: {
      fontSize: '22px',
      fontWeight: '400',
      color: 'rgb(27, 35, 46)',
      borderBottom: '2px solid rgb(230, 231, 232)',
      paddingBottom: '15px',
    },
  };

  return (
    <Modal isOpen contentLabel={''} style={styles.modalStyles}>
      <a onClick={close} style={styles.closeButton}>
        <i className="thin-0153_delete_exit_remove_close" />
      </a>
      <div style={styles.modalContent}>
        <div style={styles.title}>Health Action Notification Form</div>
        <p>
          Please allow 30-60 days following the date of service for your claims
          data to process and reflect your compliance before submitting the{' '}
          <a href={Assets.getAssetURL('ha_credit_form.pdf')} target="_blank">
            Health Action Notification Form
          </a>.
        </p>
        <p>
          If 60 days have passed and you still do not show compliance for an
          action, you can complete the&nbsp;
          <a href={Assets.getAssetURL('ha_credit_form.pdf')} target="_blank">
            Health Action Notification Form
          </a>{' '}
          and follow the instructions to submit it for review and verification.
        </p>
      </div>
    </Modal>
  );
};

ReportComplianceModal.propTypes = {
  close: PropTypes.func,
};

export default Radium(ReportComplianceModal);
