import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import * as HraSelectors from '@healthmine/greyhound-core/src/selectors/HraSelectors';
import { compose, withHandlers, withStateHandlers, withProps } from 'recompose';
import {
  DrawerContent,
  DrawerSection,
  DrawerSelect,
} from '../../../common/drawer2';
import { Grid, withStyles } from '@material-ui/core';
import Typography from '../../../common/Typography';

const styles = () => ({
  answer: {
    textAlign: 'right',
  },
});

const HraAnswers = ({
  classes,
  selectedAssessmentIndex,
  assessmentOptions,
  onAssessmentChanged,
  questionAnswers,
}) => {
  return (
    <DrawerContent title="Health Risk Assessment Answers">
      <DrawerSelect
        value={selectedAssessmentIndex}
        options={assessmentOptions}
        onChange={onAssessmentChanged}
        subLabel="Assessment Date"
      />
      {_.map(
        questionAnswers,
        (question, i) =>
          question.answer && (
            <DrawerSection key={i}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={8}
              >
                <Grid item xs={8}>
                  <Typography type="h3" secondary>
                    {question.question}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    type="h3"
                    color="twentyCC"
                    className={classes.answer}
                  >
                    {question.answer}
                  </Typography>
                </Grid>
              </Grid>
            </DrawerSection>
          )
      )}
    </DrawerContent>
  );
};

HraAnswers.propTypes = {
  assessmentOptions: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired,
  selectedAssessmentIndex: PropTypes.number.isRequired,
  onAssessmentChanged: PropTypes.func.isRequired,
  questionAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default compose(
  withStateHandlers(
    {
      selectedAssessmentIndex: 0,
    },

    {
      setSelectedAssessmentIndex: () => (selectedAssessmentIndex) => ({
        selectedAssessmentIndex,
      }),
    }
  ),

  connect((state) => {
    const assessments = HraSelectors.selectCompletedMemberQuestionnaireAnswers(
      state
    );

    return {
      assessments,
    };
  }),

  withHandlers({
    onAssessmentChanged: ({ setSelectedAssessmentIndex }) => (
      selectedAssessmentIndex
    ) => setSelectedAssessmentIndex(selectedAssessmentIndex),
  }),

  withProps(({ assessments, selectedAssessmentIndex }) => {
    const questionAnswers = _.get(
      assessments,
      `[${selectedAssessmentIndex}].answeredQuestions`
    );

    const assessmentOptions = _.map(
      assessments,
      (assessment, assessmentIndex) => ({
        label: moment(assessment.endTimestamp).format('MMMM D, Y'),
        value: assessmentIndex,
      })
    );

    return {
      assessmentOptions,
      questionAnswers,
    };
  }),

  withStyles(styles)
)(HraAnswers);
