import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import moment from 'moment';
import { replace } from 'react-router-redux';
import Rx from 'rx-lite';
import {
  SignInActions,
  TokenActions,
} from '@healthmine/greyhound-core/src/actions';
import { AppConfig } from '@healthmine/greyhound-core/src/modules';

export const types = {
  SESSION_END: 'web/session/end',
  SESSION_RECORD_HEARTBEAT: 'web/session/heartbeat',
  SESSION_START: 'web/session/start',
};

let _mouseMovementObservable = null;

let _tokenRenewalTimeout = null;

// eslint-disable-next-line no-unused-vars
function endSession(redirect: boolean = true, isTimeout: boolean = false) {
  clearTimeout(_tokenRenewalTimeout);

  return (dispatch, getState) => {
    const state = getState();

    const timerId = _.get(state, 'session.timerId');

    clearTimeout(timerId);

    dispatch({
      type: types.SESSION_END,
    });

    dispatch(SignInActions.logout());

    if (isTimeout) {
      dispatch(replace('/timeout'));
    }
  };
}

function recordSessionHeartbeat() {
  return (dispatch, getState) => {
    const state = getState();

    const started = _.get(state, 'session.started');

    if (started) {
      const timeoutMinutes = _.get(
        AppConfig,
        'effectiveConfig.inactiveSessionDurationMinutes',
        15
      );

      const heartbeatAt = moment().valueOf();

      let timerId = _.get(state, 'session.timerId');

      if (timeoutMinutes !== -1) {
        clearTimeout(timerId);
        timerId = setTimeout(
          () => dispatch(endSession(true, true)),
          timeoutMinutes * 60 * 1000
        );
      }

      dispatch({
        type: types.SESSION_RECORD_HEARTBEAT,
        heartbeatAt,
        timerId,
      });
    }
  };
}

function startSession(authData: string) {
  return (dispatch, getState) => {
    if (!_mouseMovementObservable) {
      _mouseMovementObservable = Rx.Observable.fromEvent(document, 'mousemove');
      _mouseMovementObservable
        .debounce(1000)
        .subscribe(() => dispatch(recordSessionHeartbeat()));
    }

    dispatch({
      type: types.SESSION_START,
      authData,
    });

    dispatch(recordSessionHeartbeat());

    _renewJwt(dispatch, getState);
  };
}

function _renewJwt(dispatch, getState) {
  const state = getState();

  const accessToken = _.get(state, 'signIn.accessToken');

  const decodedAccessToken = accessToken && jwtDecode(accessToken);

  const tokenExpiration =
    decodedAccessToken && moment.unix(decodedAccessToken.exp);

  if (tokenExpiration) {
    const now = moment();

    const tokenRenewalDelay = tokenExpiration.diff(now) - 300000;

    _tokenRenewalTimeout = setTimeout(
      () => {
        dispatch(TokenActions.renewJwt()).then(() =>
          _renewJwt(dispatch, getState)
        );
      },

      tokenRenewalDelay
    );
  }
}

export default {
  endSession,
  recordSessionHeartbeat,
  startSession,
};
