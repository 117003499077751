import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import styles from './HeroCard.styles';

const HeroCardMarkup = ({
  iconClass,
  title,
  incentiveText,
  onCardClick,
  actionComplete,
  completedText,
}) => {
  return (
    <a
      style={[
        styles.root,
        !_.isUndefined(onCardClick) ? { cursor: 'pointer' } : {},
        actionComplete ? { backgroundColor: '#4268A4' } : {},
      ]}
      onClick={onCardClick}
    >
      <i style={styles.icon} className={iconClass} />
      <div style={styles.title}>{title}</div>
      <div style={styles.completedText}>{completedText}</div>
      <div style={styles.incentiveAmount}>{incentiveText}</div>
    </a>
  );
};

HeroCardMarkup.propTypes = {
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  completedText: PropTypes.string,
  incentiveText: PropTypes.string.isRequired,
  onCardClick: PropTypes.func,
  actionComplete: PropTypes.bool,
};

export default Radium(HeroCardMarkup);
