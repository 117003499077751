import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Colors, GlobalStyles } from '@healthmine/greyhound-core/src/modules';
import { RewardsConstants } from '@healthmine/greyhound-core/src/constants';

const RewardCard = ({ reward, toggleProduct }) => {
  const styles = {
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '16px',
      fontWeight: 500,
      color: Colors.mediumGray2,
      paddingBottom: '10px',
      borderBottom: `1px solid ${Colors.gutterGray}`,
    },
    productRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    product: {
      width: '168px',
      display: 'flex',
      flexDirection: 'column',
      padding: '50px 0 0',
      cursor: 'pointer',
      position: 'relative',
    },

    image: {
      maxWidth: '168px',
      maxHeight: '105px',
    },

    imageOverlay: {
      position: 'absolute',
      top: '50px',
      left: '0px',
      width: '168px',
      height: '105px',
      background: 'rgba(255,255,255,0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0,
      ':hover': {
        opacity: 1,
      },
    },

    overlayIcon: {
      fontSize: '28px',
    },

    cardName: {
      marginTop: '10px',
      color: Colors.black,
      fontSize: '16px',
      fontWeight: 500,
      cursor: 'pointer',
    },

    deliveryMethods: {
      display: 'flex',
      flexDirection: 'row',
      margin: '5px 0px 10px',
      flexWrap: 'wrap',
    },

    deliveryMethod: {
      textTransform: 'uppercase',
      fontSize: '16px',
      color: Colors.white,
      lineHeight: '16px',
      marginRight: '5px',
      padding: '1px 2px 1px 3px',
      display: 'block',
      marginBottom: '2px',
    },

    digitalDeliveryMethod: {
      backgroundColor: Colors.lightGreen,
    },

    physicalDeliveryMethod: {
      backgroundColor: Colors.orange,
    },

    costRange: {
      fontWeight: 500,
      fontSize: '16px',
    },
  };

  const { setCurrencyLabel } = RewardsConstants;

  const denominations = reward.denominations;

  const priceRange =
    denominations && denominations.length > 1
      ? `${setCurrencyLabel(denominations[0])} - ${setCurrencyLabel(
          denominations[denominations.length - 1]
        )}`
      : `${setCurrencyLabel(denominations[0])}`;

  return (
    <div
      onClick={() => toggleProduct(reward.productId)}
      style={[GlobalStyles.regular, styles.product]}
    >
      <img
        style={styles.image}
        src={`${reward.imageUrl}`}
        alt={`${reward.name} Card`}
        title={`${reward.name} Card`}
      />
      <div key={reward.productId} style={styles.imageOverlay}>
        <i className="thin-0033_search_find_zoom" style={styles.overlayIcon} />
      </div>

      <label style={styles.cardName}>{reward.name}</label>

      <div style={[GlobalStyles.light, styles.deliveryMethods]}>
        {reward.isDigital ? (
          <div style={[styles.deliveryMethod, styles.digitalDeliveryMethod]}>
            email
          </div>
        ) : null}

        {reward.isPhysical ? (
          <div style={[styles.deliveryMethod, styles.physicalDeliveryMethod]}>
            standard mail
          </div>
        ) : null}
      </div>

      <span style={styles.costRange}>Cost Range: {priceRange}</span>
    </div>
  );
};

RewardCard.propTypes = {
  reward: PropTypes.object,
  toggleProduct: PropTypes.func,
};

export default Radium(RewardCard);
