import { types } from '../actions/DrawerActions';
import { assign, tail, get } from 'lodash/fp';

export const INITIAL_STATE = {
  stack: [],
  isLarge: false,
  disableBackdropClose: false,
  onClose: undefined,
};

const HANDLERS = {
  [types.BACK_DRAWER]: (state) => {
    const nextStack = tail(state.stack);
    return assign(state, {
      stack: nextStack,
      isLarge: !!get('[0].options.large', nextStack),
      disableBackdropClose: !!get('[0]options.disableBackdropClose', nextStack),
      onClose: get('[0]options.onClose', nextStack),
    });
  },
  [types.CLOSE_DRAWER]: (state) => {
    const item = get('stack[0]', state);
    const closeConfirmation = get('options.closeConfirmation', item);
    if (closeConfirmation) {
      return assign(state, {
        stack: [{ Component: closeConfirmation }].concat(state.stack),
        isLarge: !!get('options.large', item),
        disableBackdropClose: true,
        onClose: undefined,
      });
    }

    return assign(state, { stack: INITIAL_STATE.stack });
  },
  [types.OPEN_DRAWER]: (state, item) => {
    return assign(state, {
      stack: get('options.resetStack', item)
        ? [item]
        : [item].concat(state.stack),
      isLarge: !!get('options.large', item),
      disableBackdropClose: !!get('options.disableBackdropClose', item),
      onClose: get('options.onClose', item),
    });
  },
};

const drawer = (state = INITIAL_STATE, { type, ...rest }) => {
  const handler = HANDLERS[type];
  return handler ? handler(state, rest) : state;
};

export default {
  drawer,
};
